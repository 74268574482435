import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    LinearProgress,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Dinero from 'dinero.js';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

export const PROPOSAL_STATE = {
    IN_PROGRESS: 0,
    IN_AUDIT: 1,
    FINISHED: 2,
};

const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardActions: {
        marginTop: 'auto',
    },
    progressText: {
        textAlign: 'right',
        fontSize: 'smaller',
    },
}));

const ProposalDisplay = (props) => {
    const { data, state } = props;

    const classes = useStyles();

    const isBlocked = parseInt(moment().format('YYYY')) > 2023;

    const buttonText = React.useMemo(() => {
        switch (state) {
            case PROPOSAL_STATE.IN_PROGRESS:
                return 'Bearbeiten';
            default:
                return 'Anzeigen';
        }
    }, [state]);

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                {data.length === 0 && (
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Keine Anträge vorhanden
                        </Typography>
                    </Grid>
                )}
                {data.map((d) => (
                    <Grid item xs={12} sm={6} md={4} key={d.id}>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent>
                                <Typography variant="subtitle1" component="h2">
                                    Antrag
                                </Typography>
                                {d.proposalId && (
                                    <Typography variant="h5" component="h3">
                                        {d.proposalId}
                                    </Typography>
                                )}
                                {state === PROPOSAL_STATE.IN_PROGRESS && (
                                    <>
                                        <LinearProgress
                                            aria-label="Fortschritt"
                                            value={
                                                (parseInt(d.currentStep, 10) /
                                                    12) *
                                                100
                                            }
                                            variant="determinate"
                                        />
                                        <Typography
                                            color="textSecondary"
                                            className={classes.progressText}
                                        >
                                            Fortschritt
                                        </Typography>
                                    </>
                                )}
                                <Typography
                                    gutterBottom={
                                        state !== PROPOSAL_STATE.IN_PROGRESS
                                    }
                                >
                                    {d.companyName}
                                </Typography>
                                {state === PROPOSAL_STATE.IN_AUDIT && (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            Beantragte Fördersumme
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {new Dinero({
                                                amount: parseInt(
                                                    d.sumDijFunding,
                                                    10
                                                ),
                                            }).toFormat()}
                                        </Typography>
                                    </>
                                )}
                                {state === PROPOSAL_STATE.FINISHED && (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            Status
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {d.revisionStatus[
                                                'final_approved'
                                            ] ? (
                                                <Typography
                                                    style={{
                                                        color: green[500],
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CheckIcon
                                                        style={{
                                                            marginRight: 4,
                                                        }}
                                                    />
                                                    Genehmigt
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    style={{
                                                        color: red[500],
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <NotInterestedIcon
                                                        style={{
                                                            marginRight: 4,
                                                        }}
                                                    />
                                                    Abgelehnt
                                                </Typography>
                                            )}
                                        </Typography>
                                    </>
                                )}
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                {d.currentStep === '-1' ? (
                                    <Alert severity="warning">
                                        Sie haben diesen Antrag widerrufen. Eine
                                        erneute Bearbeitung ist nicht möglich.
                                    </Alert>
                                ) : isBlocked &&
                                  state === PROPOSAL_STATE.IN_PROGRESS ? (
                                    <Alert severity="warning">
                                        Aufgrund der Befristung der
                                        Förderrichtlinie bis zum 31.12.2023 ist
                                        eine Bearbeitung Ihres Antrages nicht
                                        mehr möglich.
                                    </Alert>
                                ) : (
                                    <Button
                                        component={Link}
                                        to={`/${
                                            state === PROPOSAL_STATE.IN_PROGRESS
                                                ? 'antrag'
                                                : 'uebersicht'
                                        }/${d.id}`}
                                        endIcon={
                                            state ===
                                            PROPOSAL_STATE.IN_PROGRESS ? (
                                                <EditIcon />
                                            ) : (
                                                <SearchIcon />
                                            )
                                        }
                                    >
                                        {buttonText}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

ProposalDisplay.propTypes = {
    data: PropTypes.array,
    state: PropTypes.number,
};

export default ProposalDisplay;
