import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { mutateFetch, saveMutator } from './CompanyLocationMutators';

export function fetchCompanyLocationData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/step_2`
                );
                resolve(mutateFetch(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die Unternehmensortdaten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveCompanyLocationData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_2`,
                saveMutator(data)
            );
            resolve(Response.Success('Daten erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error(
                    'Die Unternehmensortdaten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
