export const pages = [
    {
        label: 'Startseite',
        href: '/',
    },
    {
        label: 'Bewerbung Antragskontingent',
        href: '/los',
        match: ['/los'],
    },
    {
        label: 'Antragsübersicht',
        href: '/antrag',
        match: ['/antrag', '/eingereicht', '/uebersicht'],
    },
    {
        label: 'Verwendungsnachweis',
        href: '/vn',
        match: ['/vn'],
    },
    {
        label: 'Abmelden',
        href: '/abmelden',
    },
];
