export const muiLocalization = {
    textLabels: {
        body: {
            noMatch: 'Es wurden keine Einträge gefunden',
            toolTip: 'Sortieren',
            columnHeaderTooltip: (column) => `Sortieren nach ${column.label}`,
        },
        pagination: {
            next: 'Weiter',
            previous: 'Zurück',
            rowsPerPage: 'Einträge pro Seite:',
            displayRows: 'von',
        },
        toolbar: {
            search: 'Suchen',
            downloadCsv: 'CSV herunterladen',
            print: 'Drucken',
            viewColumns: 'Spalten anzeigen',
            filterTable: 'Filter',
        },
        filter: {
            all: 'Alle',
            title: 'FILTER',
            reset: 'Zurücksetzen',
        },
        viewColumns: {
            title: 'Spalten anzeigen',
            titleAria: 'Spalten anzeigen/verstecken',
        },
        selectedRows: {
            text: 'Reihe(n) ausgewählt',
            delete: 'Löschen',
            deleteAria: 'Ausgewählte Spalten löschen',
        },
    },
};
