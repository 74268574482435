import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth } from '../../../Stores/Auth.store';
import PrivateRouteLogin from './PrivateRouteLogin';

const PrivateRoute = (props) => {
    const { path, children } = props;

    const user = useAuth((state) => state.user);

    return (
        <>
            {!user ? (
                <PrivateRouteLogin />
            ) : (
                <Route path={path}>{children}</Route>
            )}
        </>
    );
};

PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default PrivateRoute;
