import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TextBlock from '../../../TextBlock/TextBlock';

const ProofTextField = (props) => {
    const {
        name,
        className,
        texts,
        max,
        register,
        defaultValue,
        error,
        ...rest
    } = props;

    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (evt) => {
        if (max && evt.target.value.length > max) {
            setValue(evt.target.value.slice(0, max));
            return;
        }
        setValue(evt.target.value);
    };

    return (
        <div className={className}>
            {texts.label && (
                <TextBlock
                    textBlock={texts.label}
                    paragraphRootComponent={texts.label.paragraphRootComponent}
                    paragraphProps={texts.label.paragraphProps}
                />
            )}
            <TextField
                fullWidth
                onChange={max && handleChange}
                helperText={
                    max
                        ? `${value.length}/${max}${
                              !!error.message ? ' - ' + error.message : ''
                          }`
                        : `${!!error.message ? error.message : ''}`
                }
                defaultValue={defaultValue}
                name={name}
                inputRef={register(texts.error)}
                error={!!error.message}
                {...rest}
            />
        </div>
    );
};

ProofTextField.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    texts: PropTypes.object,
    defaultValue: PropTypes.string,
    register: PropTypes.func,
};

ProofTextField.defaultProps = {
    defaultValue: '',
    texts: {},
    error: {},
};

export default ProofTextField;
