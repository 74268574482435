import { useMutation } from 'react-query';
import {
    patchVerification,
    verificationTransition,
} from '../../lib/api/Verification/VerificationActions';

export default function useVerificationPatch(options = {}) {
    return useMutation(({ data, id, success, transition, usePut = false }) => {
        if (usePut) {
            return verificationTransition(id, transition);
        }
        return patchVerification({ data, id, success, transition });
    }, options);
}
