import React from 'react';
import PropTypes from 'prop-types';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import moment from 'moment';
import TextBlock from '../../TextBlock/TextBlock';
import Alert from '@material-ui/lab/Alert';

const NotDrawn = (props) => {
    const { rafflePot, isProposalPage } = props;

    const block = React.useMemo(
        () =>
            rt.notDrawn(
                moment(rafflePot.raffleBegin).format('LLL'),
                isProposalPage
            ),
        [rafflePot, isProposalPage]
    );

    return (
        <Alert severity="info">
            <TextBlock textBlock={block} />
        </Alert>
    );
};

NotDrawn.propTypes = {
    rafflePot: PropTypes.object,
    isProposalPage: PropTypes.bool,
};

export default NotDrawn;
