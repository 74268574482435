import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { texts as trans } from '../../texts/Components/RegisterTexts';

const useStyles = makeStyles({
    root: {
        color: '#fff',
        zIndex: 999,
    },
    header: {
        marginBottom: '2rem',
    },
});

const RegisterValidateDone = ({ success, error, loading }) => {
    const classes = useStyles();

    const renderLoading = () => (
        <React.Fragment>
            <Typography variant="h3" className={classes.header}>
                {trans.validate.loading.headline}
            </Typography>
            <Typography>{trans.validate.loading.content}</Typography>
        </React.Fragment>
    );

    const renderSuccess = () => (
        <React.Fragment>
            <Typography variant="h3" className={classes.header}>
                {trans.validate.success.headline}
            </Typography>
            <Typography>{trans.validate.success.content}</Typography>
        </React.Fragment>
    );

    const renderError = () => (
        <React.Fragment>
            <Typography variant="h3" className={classes.header}>
                {trans.validate.error.headline}
            </Typography>
            <Typography>{trans.validate.error.content}</Typography>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {loading ? (
                <React.Fragment>
                    <Backdrop open={loading} className={classes.root}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {renderLoading()}
                </React.Fragment>
            ) : _.isEmpty(error) ? (
                renderSuccess()
            ) : (
                renderError()
            )}
        </React.Fragment>
    );
};

RegisterValidateDone.prototype = {
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.object,
};

export default RegisterValidateDone;
