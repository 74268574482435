export const texts = {
    title: 'De-minimis-Beihilfe',
    titleTooltip: {
        paragraphs: [
            {
                type: 'p',
                text: 'Die Förderung der Investitionen im Rahmen von Digital Jetzt stellt für das begünstigte Unternehmen eine De-minimis-Beihilfe nach den Vorschriften der Europäischen Union dar (VO (EU) Nr. 1407/2013 der Europäischen Kommission).',
            },
            {
                type: 'p',
                text: 'De-minimis-Beihilfen sind an die Einhaltung bestimmter Bedingungen geknüpft, um sicherzustellen, dass durch die Beihilfe-bedingte Begünstigung bestimmter Unternehmen oder Wirtschaftszweige der Wettbewerb nicht verfälscht wird. Der Gesamtbetrag der einem einzigen Unternehmen von einem Mitgliedstaat gewährten De-minimis-Beihilfen darf in einem Zeitraum von drei Steuerjahren 200.000 Euro nicht übersteigen.',
            },
            {
                type: 'p',
                text: 'Der Gesamtbetrag der De-minimis-Beihilfen, die einem einzigen Unternehmen, das im [i]gewerblichen Straßengüterverkehr[i] tätig ist, von einem Mitgliedstaat gewährt werden, darf in einem Zeitraum von drei Steuerjahren 100.000 Euro nicht übersteigen (VERORDNUNG (EU) Nr. 1407/2013 DER KOMMISSION vom 18. Dezember 2013).',
            },
        ],
    },
    hint: 'Hinweis: Der Gesamtbetrag, der einem Unternehmen (einschließlich seiner verbundenen Unternehmen) von einem Mitgliedstaat der EU gewährten De-minimis Beihilfen darf innerhalb eines fließenden Zeitraums von drei Steuerjahren (dem laufenden und den beiden vorangegangenen Steuerjahren) maximal den Betrag von 200.000,00 EUR (100.000,00 EUR im gewerblichen Straßengüterverkehr) ergeben.',
    hint2: {
        paragraphs: [
            {
                type: 'p',
                text: 'Hinweis: Der Gesamtbetrag der De-minimis-Beihilfen, die einem einzigen Unternehmen, das im gewerblichen Straßengüterverkehr tätig ist, von einem Mitgliedstaat gewährt werden, darf in einem Zeitraum von drei Steuerjahren 100.000 Euro nicht übersteigen (VERORDNUNG (EU) Nr. 1407/2013 DER KOMMISSION vom 18. Dezember 2013).',
            },
        ],
    },
    leadingText:
        'Das antragstellende Unternehmen (ggf. zuzüglich der verbundenen Unternehmen ) hat vor Beginn der Laufzeit des hier vorliegenden Projekts:',
    fields: {
        linkedCompanies: {
            text: (link) => ({
                paragraphs: [
                    {
                        type: 'p',
                        text: `Es werden sämtliche De-minimis-Beihilfen berücksichtigt, die der Antragsteller selbst sowie sämtliche mit dem Antragsteller verbundene Unternehmen erhalten haben. Die Informationen aus dem „[a|${link}]Infoblatt Für die De-minimis-Erklärung[a]“ wurden beachtet.`,
                    },
                ],
            }),
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            rules: {
                required:
                    'Es muss angegeben werden, ob Sie alle De-minimis-Beihilfen berücksichtigt haben.',
                validate: (value) =>
                    value === 'true' ||
                    'Sie müssen bestätigen, dass Sie alle De-minimis-Beihilfen berücksichtigt haben',
            },
            hint: 'De-minimis-Beihilfen, die einem verbundenen Unternehmen gewährt oder von diesem beantragt wurden, sind zwingend mit anzugeben.',
        },
        received: {
            text: 'De-minimis-Behilfen erhalten',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            error: 'Es muss angegeben werden, ob Sie De-minimis-Beihilfen erhalten haben.',
        },
        requested: {
            text: 'De-minimis-Beihilfen beantragt. Eine Bewilligung steht noch aus.',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            error: 'Es muss angegeben werden, ob Sie De-minimis-Beihilfen beantragt haben.',
        },
        goDigitalFunded: {
            text: 'Wurde oder wird Ihr Unternehmen bereits über das Förderprogramm "Go-Digital" gefördert?',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            error: 'Es muss angegeben werden, ob Sie bereits das Förderprogramm "Go-Digital" gefördert wurden.',
        },
        roadTransport: {
            text: 'Ist das antragstellende Unternehmen dem gewerblichen Straßengüterverkehr zuzuordnen? Für Unternehmen des gewerblichen Straßengüterverkehrs gilt eine Gesamtsumme der von einem Mitgliedstaat der EU gewährten De-minimis Beihilfen innerhalb eines fließenden Zeitraums von drei Steuerjahren (dem laufenden und den beiden vorangegangenen Steuerjahren) von maximal 100.000 Euro.',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            error: 'Sie müssen auswählen, ob das antragsstellende Unternehmen dem gewerblichen Straßengüterverkehr zuzuordnen ist.',
        },
        isValid: {
            error: 'Das Restkontingent Ihrer De-Minimis Beihilfen der letzten drei Jahre unterschreitet die Mindestfördersumme für Digital Jetzt. Das Fortsetzen Ihrer Antragstellung ist nicht möglich.',
            error2: 'Ihre De-Minimis Beihilfen der letzten drei Jahre sind ausgeschöpft. Das Fortsetzen Ihrer Antragstellung ist nicht möglich.',
        },
    },
    calculator: {
        topHint:
            'Es handelt sich um notwendige Angaben in einem fließenden Zeitraum von drei Steuerjahren (laufendes Jahr der Antragstellung und die beiden vorangegangenen Steuerjahre).',
        a: '(A) Erhaltene und bewilligte Beihilfen',
        b: '(B) Beantragte Beihilfen - noch nicht bewilligt',
        bSub: 'Bitte informieren Sie den DLR Projektträger unverzüglich, wenn die hier genannten beantragten Fördermittel bewilligt werden, da dies gegebenenfalls Einfluss auf die Förderung in "Digital Jetzt" hat.',
        sub1: 'Antragstellendes Unternehmen / Beihilfeempfänger',
        sub2: 'Verbundenes Unternehmen / Beihilfeempfänger',
        chooseChildCompaniesReceived:
            'Bitte wählen Sie alle verbundenen Unternehmen aus, die eine Beihilfe erhalten haben.',
        chooseChildCompaniesRequested:
            'Bitte wählen Sie alle verbundenen Unternehmen aus, die eine Beihilfe beantragt haben.',
        emptyList1: 'Noch keine Beihilfen eingetragen',
        buttonAppend1: 'Neuen Eintrag hinzufügen',
        fields: {
            fundingAmount: {
                label: 'Fördersumme',
                error: 'Bitte geben Sie die Fördersumme in Zahlen ohne 1000er-Trennzeichen und mit zwei Dezimalstellen an.',
                tooltip:
                    'Bitte geben Sie die Fördersumme in Zahlen ohne 1000er-Trennzeichen und mit zwei Dezimalstellen an.',
            },
            investmentAmount: {
                label: 'Subventionswert',
                error: 'Bitte geben Sie den Subventionswert in Zahlen ohne 1000er-Trennzeichen und mit zwei Dezimalstellen an.',
                title: 'Sie finden die Höhe des Subventionswertes auf Ihrer aktuellen De-Minimis Bescheinigung.',
            },
            fkz: {
                label: 'Förderkennzeichen/-programm',
                error: 'Bitte geben Sie das Förderkennzeichen/-programm an.',
            },
            provider: {
                label: 'Beihilfegeber',
                error: 'Bitte geben Sie den Beihilfegeber an.',
            },
            type: {
                label: 'Form', //der Beihilfe
                items: [
                    { value: 'Zuschuss', label: 'Zuschuss' },
                    { value: 'Darlehen', label: 'Darlehen' },
                    { value: 'Bürgschaft', label: 'Bürgschaft' },
                ],
            },
        },
        sum: {
            parseSucces: (sum) =>
                `Der Gesamtbetrag, der dem Unternehmen von einem Mitgliedstaat der EU gewährten De-minimis-Beihilfen, hat innerhalb eines fließenden Zeitraums von drei Steuerjahren den Betrag von ${sum} unterschritten. Eine Förderung in Form von Beihilfen ist bis zu folgender Höhe möglich.`,
            parseSuccessUnder3000: (sum) =>
                `Der Gesamtbetrag, der dem Unternehmen von einem Mitgliedstaat der EU gewährten De-minimis-Beihilfen, hat innerhalb eines fließenden Zeitraums von drei Steuerjahren den Betrag von ${sum} unterschritten. Die mögliche Fördersumme unterschreitet jedoch die Mindestfördersumme in "Digital Jetzt". Wir weisen Sie deshalb darauf hin, dass eine Antragstellung im laufenden Steuerjahr nicht möglich ist.`,
            parseNoSucces: (sum, diff) =>
                `Der Gesamtbetrag, der dem Unternehmen von einem Mitgliedstaat der EU gewährten De-minimis-Beihilfen, hat innerhalb eines fließenden Zeitraums von drei Steuerjahren den Betrag von ${sum} um folgenden Betrag ${diff} überschritten. Wir weisen Sie deshalb darauf hin, dass eine Antragstellung im laufenden Steuerjahr nicht möglich ist.`,
            parsePossble: (sum) => `Mögliche Fördersumme: ${sum}`,
        },
        checkbox: {
            title: 'Regelungen bei Subventionen',
            error: 'Bitte bestätigen Sie diese Angabe.',
            ackFraud:
                'Dem  antragstellenden Unternehmen ist hiermit bekannt, dass die nach dieser Richtlinie gewährten Zuwendungen Subventionen im Sinne des § 264 des Strafgesetzbuchs sind. Ich werde hiermit bereits vor der Bewilligung auf die Strafbarkeit des Subventionsbetrugs hingewiesen.',
            ackDisclosure:
                'Das antragstellende Unternehmen wird hiermit bereits vor der Bewilligung auf Mitteilungspflichten nach § 3 des Subventionsgesetzes hingewiesen. (Besondere Offenbarungspflichten gemäß § 3 Subventionsgesetz in Verbindung mit Nrn. 1 – 2 der Anlage / Teil (B)).',
            ackRegulation:
                'Das antragstellende Unternehmen wird hiermit bereits vor der Bewilligung auf die im konkreten Fall subventionserheblichen Tatsachen entsprechend Verwaltungsvorschrift Nummer 3.4.6 zu § 44 BHO  hingewiesen.',
            ackDeadline:
                'Das antragstellende Unternehmen bestätigt, dass ihm bekannt ist, dass es die De-minimis-Erklärung und die De-minimis-Bescheinigung zehn Jahre aufzubewahren und auf Anforderung der Europäischen Kommission, der Bundesregierung oder der bewilligenden Stelle innerhalb von einer Woche oder einer in der Anforderung festgesetzten längeren Frist vorzulegen hat. Wird diese Erklärung innerhalb der Frist nicht vorgelegt, entfällt rückwirkend die Bewilligungsvoraussetzung und die Beihilfe zuzüglich Zinsen wird zurückgefordert.',
            ackMinimiProof:
                'Das antragstellende Unternehmen bestätigt, dass ihm bekannt ist, dass die De-minimis-Erklärung und die De-minimis-Bescheinigung bei zukünftigen Beantragungen als Nachweis für die vergangenen De-minimis-Beihilfen vorzulegen  sind.',
        },
    },
};
