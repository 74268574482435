import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { downloadFile } from '../Files/FileActions';
import { handleError } from '../../../util/Error/HandleError';

export function fetchFinalizeInfo(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(`/proposals/${proposalId}`);
                resolve(data);
            } catch (e) {
                const status = e.response?.status;
                if (status > 500) {
                    resolve({ proposalPdf: undefined });
                    return;
                }

                reject(
                    handleError(e, {
                        generic:
                            'Es konnte nicht überprüft werden, ob das Unterschriftsblatt bereit zum Download ist.',
                        422: 'Sie haben keine Berechtigung, auf den Antrag zuzugreifen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
                        401: 'Sie haben keine Berechtigung, auf den Antrag zuzugreifen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
                        403: 'Sie haben keine Berechtigung, auf den Antrag zuzugreifen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
                        404: 'Der angegebene Antrag konnte nicht gefunden werden.',
                        500: 'Ihr Dokument steht noch nicht zur Verfügung. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                    })
                );
            }
        });
    };
}

export function saveFinalize(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        const { additional, values } = data;
        try {
            if (additional.action === 'delete') {
                await api().delete(additional.media.slice(4));
                await api().delete(additional.id.slice(4));
            }

            if (additional.action === 'patch') {
                await api().patch(
                    additional.media.slice(4),
                    {
                        keywords: ['proposal', 'final'],
                    },
                    {
                        headers: {
                            'content-type': 'application/merge-patch+json',
                        },
                    }
                );
            }
        } catch (e) {}

        try {
            await api().put(`/action/proposal/${proposalId}/step_11`, {
                ...values,
            });
            resolve(
                Response.Success('Ihr Antrag wurde erfolgreich eingereicht')
            );
        } catch (e) {
            reject(
                Response.Error('Der Antrag konnte nicht eingereicht werden', e)
            );
        }
    });
}

export function downloadPdf(proposalId, downloadCallback) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: proposal } = await api().get(
                `/proposals/${proposalId}`
            );

            if (!proposal.proposalPdf) {
                reject(
                    Response.Error(
                        'Das Unterschriftenblatt konnte nicht gefunden werden',
                        {}
                    )
                );
            }

            const { data: pdf } = await api().get(
                `${proposal.proposalPdf.slice(4)}`
            );

            const response = await downloadFile(
                pdf.downloadUrl,
                pdf.fileName,
                pdf.fileSize,
                downloadCallback
            );

            resolve({ response, file: pdf });
        } catch (e) {
            reject(Response.Error('Datei konnte nicht geladen werden', e));
        }
    });
}
