import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import { texts as dzt } from '../../../texts/Components/DropzoneTexts';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    dropzone: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: (props) =>
            props.isDragActive
                ? 'rgba(52,128,142,0.7)'
                : 'rgba(52,128,142,0.15)',
        borderRadius: '1rem',
        border: '2px dashed rgba(52,128,142,1)',
    },
}));

const Dropzone = (props) => {
    const {
        onFileLoadComplete,
        maxFileCount,
        acceptedFileTypes,
        maxFileSize,
        currentFileCount,
        onReject,
    } = props;

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const filesToUpload = acceptedFiles.length;

            if (
                maxFileCount > 0 &&
                filesToUpload > maxFileCount - currentFileCount
            ) {
                acceptedFiles.splice(maxFileCount - currentFileCount);
            }

            acceptedFiles.forEach((file) => {
                const fileObject = {
                    fileName: file.name,
                    mimeType: file.type,
                    fileSize: file.size,
                    refId: _.uniqueId('file_'),
                };

                onFileLoadComplete(fileObject, file);
            });
        },
        [onFileLoadComplete, currentFileCount, maxFileCount]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        onDropRejected: onReject,
        disabled: currentFileCount >= maxFileCount && maxFileCount > 0,
        maxSize: maxFileSize * 1024 * 1024,
        multiple: maxFileCount !== 1,
        accept: acceptedFileTypes,
    });

    const classes = useStyles({ isDragActive });

    return (
        <>
            <Box
                className={classes.dropzone}
                {...getRootProps()}
                style={{
                    opacity: getInputProps().onClick === null ? '0.2' : '1',
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography>{dzt.dropzone.onDrag(maxFileCount)}</Typography>
                ) : (
                    <Typography>{dzt.dropzone.drop(maxFileCount)}</Typography>
                )}
            </Box>
            <Typography variant="caption" style={{ paddingLeft: 16 }}>
                {dzt.dropzone.caption(
                    maxFileSize,
                    maxFileCount,
                    acceptedFileTypes
                )}
            </Typography>
        </>
    );
};

Dropzone.propTypes = {
    onFileLoadComplete: PropTypes.func.isRequired,
    maxFileSize: PropTypes.number,
    acceptedFileTypes: PropTypes.array,
    maxFileCount: PropTypes.number,
    currentFileCount: PropTypes.number,
    onReject: PropTypes.func,
};

Dropzone.defaultProps = {
    maxFileSize: 5,
    acceptedFileTypes: ['.pdf'],
    maxFileCount: 0,
    currentFileCount: 0,
};

export default Dropzone;
