import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Outline from '../../Partials/Info/Outline';
import { step1Texts } from '../../../texts/Verification/Step1Texts';
import { CircularProgress } from '@material-ui/core';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import FormFooter from './FormFooter';
import { texts as dft } from '../../../texts/Forms/Digi3FormTexts';
import Typography from '@material-ui/core/Typography';
import { step3Texts } from '../../../texts/Verification/Step3Texts';
import QuestionStep3 from './QuestionStep3';
import _ from 'lodash';

const Step3 = (props) => {
    const {
        data,
        formData,
        isLoading,
        displayOnly,
        id,
        onDirtyChange,
        handleStepChange,
    } = props;

    const {
        errors,
        register,
        unregister,
        handleSubmit,
        reset,
        formState,
        setError,
    } = useForm({
        defaultValues: {
            ...formData.digiPlan,
        },
    });

    const { isDirty } = formState;

    React.useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange]);

    const { enqueueSnackbar } = useSnackbar();

    const handleDataReset = (d) => {
        reset({ ...d.digiPlan });
    };

    const handleDigiPlanError = (data) => {
        if (data) {
            data.violations.forEach((violation) => {
                if (violation.propertyPath.includes('digiPlan.fields')) {
                    const violationPos = violation.message.match(/'(.*?)'/);
                    if (violationPos?.[1]) {
                        const violationPropertyPath =
                            violation.propertyPath.replace(
                                'digiPlan.fields',
                                violationPos[1]
                            );
                        setError(violationPropertyPath, {
                            type: 'manual',
                            message: step3Texts.errorQuestion,
                        });
                    }
                }
            });
        }
    };

    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
        const { data } = error.data().response;
        handleDigiPlanError(data);
    };
    const handleSuccess = (data) => {
        const d = data.data();
        handleDataReset(d);
        enqueueSnackbar(data.message(), { variant: 'success' });
    };

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onError: handleError,
        onSuccess: handleSuccess,
    });

    const submitCallback = (values) => {
        mutate({
            data: {
                ...formData,
                digiPlan: {
                    fields: [
                        ...Object.keys(values).map((el, id) => values[el]),
                    ],
                },
            },
            id,
            success: 'Erfolgreich gespeichert.',
        });
    };

    React.useEffect(() => {
        if (isLoading) return;
        reset({
            ...formData.digiPlan,
        });
    }, [isLoading, formData, reset]);

    if (isLoading) return <FullscreenCircularLoader />;
    const hasOriginalData = !_.isEmpty(data.digi3);

    return (
        <form onSubmit={handleSubmit(submitCallback)}>
            <Outline subtitle={step1Texts.title}>
                {hasOriginalData && (
                    <Typography
                        className="subHead"
                        component="h6"
                        variant="h6"
                        gutterBottom
                    >
                        {step3Texts.subhead}
                    </Typography>
                )}
                <Typography variant="body1" gutterBottom>
                    {hasOriginalData
                        ? step3Texts.desc
                        : step3Texts.descNoOriginal}
                </Typography>
            </Outline>

            <QuestionStep3
                name="digiPlan"
                originalQuestions={dft.questions}
                questions={step3Texts.questions}
                originalDefaultValue={data.digi3}
                defaultValue={formData.digiPlan?.fields}
                unregister={unregister}
                register={register}
                errors={errors}
                notEditable={displayOnly}
            />

            {!displayOnly && (
                <FormFooter
                    displayText={Object.keys(errors).length > 0}
                    text={step3Texts.errorText}
                    gutterTop
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                    >
                        Speichern
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                        onClick={() => handleStepChange(3)}
                        style={{ marginLeft: 8 }}
                    >
                        Weiter
                    </Button>
                </FormFooter>
            )}
        </form>
    );
};

Step3.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

Step3.defaultProps = {
    data: {},
};

export default Step3;
