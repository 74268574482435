import React from 'react';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: '100%',
        top: '0',
        backgroundColor: theme.palette.error.main, //'#212327',
        color: 'white',
        padding: '1rem 0',
        zIndex: theme.zIndex.drawer + 1,
    },
    container: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: 'white',
        whiteSpace: 'normal',
    },
    button: {
        color: 'inherit',
    },
}));

const InfoTopSlideIn = (props) => {
    const { clear, show, message, retry, loading, ...rest } = props;
    const classes = useStyles();
    return (
        <Slide in={show} direction="down" mountOnEnter unmountOnExit>
            <div {...rest} className={classes.root}>
                <Container>
                    <div className={classes.container}>
                        <Typography>{message}</Typography>
                        <div
                            style={{ display: 'inline', whiteSpace: 'nowrap' }}
                        >
                            {loading && (
                                <CircularProgress
                                    style={{ verticalAlign: 'middle' }}
                                    size="1rem"
                                    color="inherit"
                                />
                            )}
                            {retry && (
                                <Button
                                    disabled={loading}
                                    onClick={retry}
                                    className={classes.button}
                                >
                                    Neu Laden
                                </Button>
                            )}
                            {clear && (
                                <IconButton
                                    onClick={clear}
                                    className={classes.button}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </Slide>
    );
};

InfoTopSlideIn.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
    retry: PropTypes.func,
    clear: PropTypes.func,
    loading: PropTypes.bool,
};

export default InfoTopSlideIn;
