export function parseFuncString(string) {
    switch (string) {
        case '0':
            return '0';
        case '1':
            return '1';
        case '2':
            return '2';
        default:
            return 'null';
    }
}
