import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import axios from 'axios';

const dummyAsync = (data, timeout = 2000) =>
    new Promise((resolve, reject) => setTimeout(() => resolve(data), timeout));

export function approveProposal(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            if (data.canSubmit === 'false') {
                await axios.get('/oauth/check/proposal');
            }
        } catch (e) {
            reject(
                Response.Error(
                    'Der Antrag kann aktuell nicht eingereicht werden. Bitte beachten Sie die oben stehende Meldung.',
                    {
                        response: {
                            data: {
                                violations: [
                                    {
                                        propertyPath: 'invalid',
                                        message:
                                            'Der Antrag kann aktuell nicht eingereicht werden. Bitte beachten Sie die oben stehende Meldung.',
                                    },
                                ],
                            },
                        },
                    }
                )
            );
        }
        try {
            await dummyAsync(data);
            resolve(
                Response.Success('Antrag erfolgreich überprüft', { hide: true })
            );
        } catch (e) {
            reject(Response.Error('Konnte Antrag nicht abschließen', e));
        }
    });
}

export function fetchStep(step, mutator) {
    return (proposalId) => {
        return () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await api().get(
                        `/action/proposal/${proposalId}/${step}`
                    );
                    resolve(mutator(data));
                } catch (e) {
                    reject(Response.Error('Fehler', e));
                }
            });
        };
    };
}

export function fetchMutliStep(steps, mutator) {
    return (proposalId) => {
        return () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const promises = steps.map((step) =>
                        api().get(`/action/proposal/${proposalId}/${step}`)
                    );
                    const results = await Promise.all(promises);
                    resolve(mutator(results));
                } catch (e) {
                    reject(Response.Error('Fehler', e));
                }
            });
        };
    };
}
