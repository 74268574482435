import { registerApi } from '../AxiosConfig/Config';
import { authenticateClient } from '../Client/ClientAuth';
import { saveMutator } from './RegisterMutator';
import axios from 'axios';
import { Response } from '../../../util/DataTypes/Response';
import { handleError } from '../../../util/Error/HandleError';

export function register(values) {
    return new Promise((resolve, reject) => {
        authenticateClient()
            .then((token) => {
                registerApi
                    .post('/action/account-register', saveMutator(values), {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        //Fehler beim register
                        reject(
                            handleError(error, {
                                generic:
                                    'Bei der Registrierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
                                500: 'Bei der Registrierung ist ein interner Fehler aufgetreten. Bitte versuchen Sie es erneut.',
                                504: 'Bei der Registrierung gab es eine Zeitüberschreitung. Bitte versuchen Sie es erneut.',
                                503: 'Bei der Registrierung gab es eine Zeitüberschreitung. Bitte versuchen Sie es erneut.',
                                502: 'Bei der Registrierung gab es eine Zeitüberschreitung. Bitte versuchen Sie es erneut.',
                                406: 'Das Kontingent für Neu-Registrierungen ist erschöpft. Es kann kein neuer Nutzer angelegt werden.',
                                422: 'Die angegebenen Daten sind fehlerhaft. Bitte überprüfen Sie Ihre Eingaben.',
                            })
                        );
                    });
            })
            .catch((error) => {
                //Client Auth fehler
                reject(
                    handleError(error, {
                        generic:
                            'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später erneut.',
                    })
                );
            });
    });
}

export const validateAccount = async (confirmToken) => {
    return new Promise((resolve, reject) => {
        authenticateClient()
            .then((token) => {
                registerApi
                    .post(
                        '/action/account-confirm',
                        { token: confirmToken },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export function isRegisterOpen() {
    return new Promise(async (resolve, reject) => {
        try {
            await axios.get('/oauth/check/register');
            resolve(true);
        } catch (e) {
            if (e?.response) {
                //Error from BE received
                const { response } = e;
                if (response.status === 406) {
                    resolve(false);
                    return;
                }
            }
            reject(
                Response.Error(
                    'Die Überprüfung der Registrierung ist aufgrund der hohen Auslastung fehlgeschlagen. Bitte laden Sie die Seite erneut. Sollte diese Fehlermeldung wiederholt erscheinen, versuchen Sie es bitte zu einem späteren Zeitpunkt wieder'
                )
            );
        }
    });
}
