import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container } from '@material-ui/core';
import { theme } from '../Theme/Styles';

const useStyles = makeStyles({
    background: {
        backgroundColor: theme.palette.primary.light,
        width: '100%',
        padding: '1rem 0',
    },
    content: {
        padding: '1rem 2rem',
        minHeight: '60vh',
    },
});

const Content = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.background} role="main">
            <Container>
                <Paper elevation={0} square className={classes.content}>
                    {children}
                </Paper>
            </Container>
        </div>
    );
};

export default Content;
