import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    noBorder: {
        '& td': {
            borderBottom: 0,
        },
    },
});

const Row = (props) => {
    const { col1, col2, col3, noBorder, color } = props;
    const classes = useStyles();

    const getColspan = React.useCallback(() => {
        if (!col2 && !col3) return 3;
        if (!col2 && col3) return 2;
        return 1;
    }, [col2, col3]);

    return (
        <TableRow
            className={noBorder ? classes.noBorder : ''}
            style={{ backgroundColor: color }}
        >
            <TableCell colSpan={getColspan()}>{col1}</TableCell>
            {col2 && <TableCell>{col2}</TableCell>}
            {col3 && (
                <TableCell style={{ minWidth: '240px', maxWidth: '300px' }}>
                    {col3}
                </TableCell>
            )}
        </TableRow>
    );
};

Row.propTypes = {
    col1: PropTypes.any,
    col2: PropTypes.any,
    col3: PropTypes.any,
    noBorder: PropTypes.bool,
    color: PropTypes.string,
};

Row.defaultProps = {
    noBorder: false,
    color: '#fff',
};

export default Row;
