import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from '../Digi1/CustomTooltip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import blueGrey from '@material-ui/core/colors/blueGrey';

const useStyles = makeStyles((theme) => ({
    control: {
        width: '100%',
    },
    question: {
        width: 'calc(100%/6)',
        minWidth: '100px',
        flexBasis: '150px',
        textAlign: 'center',
    },
    label: {
        padding: theme.spacing(2),
        backgroundColor: '#E8E8E8',
        color: 'black',
        display: 'block',
        lineHeight: '1.5rem',
    },
    buttons: {
        padding: theme.spacing(2),
    },
    button: {
        '&$disabled': {
            color: blueGrey[700],
        },
    },
    disabled: {},
}));

const QuestionDigi3 = (props) => {
    const {
        answers,
        error,
        name,
        text,
        tooltip,
        defaultValue,
        register,
        unregister,
        isShown,
        required,
        notEditable,
        disabled,
    } = props;
    const classes = useStyles();

    return (
        <FormControl className={classes.control} error={!!error[name]}>
            <FormLabel className={classes.label}>
                {text} {tooltip && <CustomTooltip>{tooltip}</CustomTooltip>}
            </FormLabel>

            <RadioGroup
                name={name}
                defaultValue={defaultValue}
                className={classes.buttons}
            >
                {error[name] && (
                    <FormHelperText>{error[name].message}</FormHelperText>
                )}
                <Grid container justify="space-around">
                    {answers.map((answer) => (
                        <Grid
                            item
                            className={classes.question}
                            key={`${text}_${answer.label}`}
                        >
                            <FormControlLabel
                                value={answer.value}
                                labelPlacement="bottom"
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.button,
                                            disabled: classes.disabled,
                                        }}
                                        color="primary"
                                        disabled={disabled || notEditable}
                                        inputRef={
                                            isShown
                                                ? register({
                                                      required: required,
                                                  })
                                                : unregister(name)
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        {answer.label} {answer.tooltip && ' '}
                                        {answer.tooltip && (
                                            <CustomTooltip>
                                                {answer.tooltip}
                                            </CustomTooltip>
                                        )}
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
        </FormControl>
    );
};

QuestionDigi3.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func,
    text: PropTypes.string,
    answers: PropTypes.array.isRequired,
    tooltip: PropTypes.string,
    defaultValue: PropTypes.string,
    isShown: PropTypes.bool,
    unregister: PropTypes.func,
    error: PropTypes.object,
    required: PropTypes.string,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
};

QuestionDigi3.defaultProps = {
    notEditable: false,
};

export default QuestionDigi3;
