import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { texts as oft } from '../../../../../texts/Forms/OverviewFormTexts';
import { texts as dft } from '../../../../../texts/Forms/Digi2FormTexts';

const Digi2Overview = (props) => {
    const { data } = props;

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {data.moduleOne && data.moduleTwo
                    ? oft.digi2.static.moduleMulti
                    : oft.digi2.static.module}
            </Typography>

            <Typography gutterBottom component="ul">
                {data.moduleOne && (
                    <Typography component={'li'}>Modul 1</Typography>
                )}
                {data.moduleTwo && (
                    <Typography component={'li'}>Modul 2</Typography>
                )}
            </Typography>

            <Typography variant="h6">{oft.digi2.static.description}</Typography>
            <Typography gutterBottom>{data.title}</Typography>

            <Typography variant="h6">{oft.digi2.static.time}</Typography>
            <Typography gutterBottom>
                {data.startDate.format('DD.MM.yyyy')} -{' '}
                {data.endDate.format('DD.MM.yyyy')}
            </Typography>

            {data.extendedExecution && (
                <>
                    <Typography variant="h6">
                        {oft.digi2.static.extended}
                    </Typography>
                    <Typography gutterBottom>
                        {data.extendedExecution}
                    </Typography>
                </>
            )}

            {data.executionNotStarted && (
                <Typography variant="h6" gutterBottom>
                    {oft.digi2.static.confirm}
                </Typography>
            )}

            <Typography variant="h6">{oft.digi2.static.targets}</Typography>
            <Typography component="ul" gutterBottom>
                {data.targetsNew.map((target) => (
                    <Typography key={target} component="li">
                        {target}
                    </Typography>
                ))}
            </Typography>

            {data.explainCommon && (
                <>
                    <Typography variant="h6">
                        {dft.question1.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.explainCommon}
                    </Typography>
                </>
            )}
            {data.explainApplication && (
                <>
                    <Typography variant="h6">
                        {dft.question2.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.explainApplication}
                    </Typography>
                </>
            )}
            {data.explainImprovements && (
                <>
                    <Typography variant="h6">
                        {dft.question3.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.explainImprovements}
                    </Typography>
                </>
            )}

            {data.valueChainRole && (
                <>
                    <Typography variant="h6">
                        {dft.valueChain1.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.valueChainRole}
                    </Typography>
                </>
            )}

            {data.valueChainImprove && (
                <>
                    <Typography variant="h6">
                        {dft.valueChain2.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.valueChainImprove}
                    </Typography>
                </>
            )}

            {data.securityTechnologies && (
                <>
                    <Typography variant="h6">
                        {dft.questionSecurityA.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.securityTechnologies}
                    </Typography>
                </>
            )}

            {data.securityTargets && (
                <>
                    <Typography variant="h6">
                        {dft.questionSecurityB.title.slice(3)}
                    </Typography>
                    <Typography
                        style={{ whiteSpace: 'break-spaces' }}
                        gutterBottom
                    >
                        {data.securityTargets}
                    </Typography>
                </>
            )}
        </>
    );
};

Digi2Overview.propTypes = {
    data: PropTypes.object,
};

export default Digi2Overview;
