import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { texts as ctf } from '../../../texts/Forms/CompanyTypeFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import RadioButtons from '../../Partials/Form/RadioButtons';
import DialogWithLogout from '../../Partials/Dialog/DialogWithLogout';
import RHFTextField from '../../Partials/Form/RHFTextField';
import { getLegalList } from '../../../lib/api/Legal/LegalActions';
import RHFAutocomplete from '../../Partials/Form/RHFAutocomplete';
import TextInput from '../../Partials/Form/TextInput';
import { getSectorKeyList } from '../../../lib/api/SectorKey/SectorKeyActions';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import { fetchUploadedFilesFor } from '../../../lib/api/Files/FileActions';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import { useParams } from 'react-router-dom';
import { TYPE } from '../../../util/DataTypes/Response';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import TextBlock from '../../TextBlock/TextBlock';
import LinkOtherPage from '../../TextBlock/components/LinkOtherPage';
import infoPdf from '../../../static/files/DJ_Infoblatt_verb_Unternehmen.pdf';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
    mtHalf: {
        marginTop: theme.spacing(2),
    },
    marginBottom: {
        marginBottom: theme.spacing(4),
    },
    marginTopBottom: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    link: {
        '& a': {
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
    },
}));

const CompanyTypeForm = (props) => {
    const { defaultValues, saveResponse, formId, saving, onSubmit } = props;
    const { proposalId } = useParams();
    const classes = useStyles();
    const {
        control,
        setError,
        handleSubmit,
        errors,
        watch,
        getValues,
        register,
        setValue,
        unregister,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });

    const [filesUploaded, setFilesUploaded] = React.useState(0);
    const [dateFounded, setDateFounded] = React.useState(null);
    const handleDateChange = React.useCallback(
        (date) => {
            setDateFounded(date);
            setValue('dateFounded', date.format('yyyy-MM-DD'));
        },
        [setDateFounded, setValue]
    );

    const handleFilesCallback = (files) => {
        setFilesUploaded(files.length);
    };

    React.useEffect(() => {
        setDateFounded(moment(defaultValues.dateFounded));
    }, [defaultValues]);

    const [isCommunalCompanyError, setIsCommunalCompanyError] =
        React.useState(null);
    const isCommunalCompany = watch('isCommunalCompany');
    React.useEffect(() => {
        setIsCommunalCompanyError(isCommunalCompany === 'true' ? true : null);
    }, [isCommunalCompany, setIsCommunalCompanyError]);

    const employeesCount = watch('employeesCount');
    const [employeesCountError, setEmployeesCountError] = React.useState(null);

    const handleEmployeesCountError = useCallback(() => {
        let obj = _.find(ctf.employeesCount.types, {
            value: employeesCount,
        });
        if (obj.hasOwnProperty('info')) {
            setEmployeesCountError(obj.info);
        }
    }, [employeesCount, setEmployeesCountError]);

    React.useEffect(() => {
        if (employeesCount) {
            handleEmployeesCountError();
        }
    }, [employeesCount, handleEmployeesCountError]);

    const hasHRGNumber = watch('hasHRGNumber');
    const hasTradeRegistered = watch('hasTradeRegistered');
    const isCraftCompany = watch('isCraftCompany');
    const isFreelance = watch('isFreelance');
    const isFoundedLast2Y = watch('isFoundedLast2Y');
    const legalForm = watch('legalForm');
    const employeeCheck = watch('employeeCheck');

    React.useEffect(() => {
        if (isFoundedLast2Y === 'true') {
            register(
                { name: 'dateFounded' },
                { required: ctf.dateFounded.error }
            );
            return;
        }
        unregister('dateFounded');
    }, [isFoundedLast2Y, register, unregister]);

    function isOther(watchedValue) {
        if (!watchedValue) return false;
        return watchedValue.isOther;
    }

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                let violationPropertyPath = violation.propertyPath.replace(
                    'companyType.',
                    ''
                );
                setError(violationPropertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    const handleSubmitCallback = (values) => {
        if (values.isCommunalCompany === 'true') {
            setIsCommunalCompanyError(true);
            return;
        }
        if (values.employeesCount === '0' || values.employeesCount === '500') {
            handleEmployeesCountError();
            return;
        }

        if (values.isFoundedLast2Y === 'true' && filesUploaded <= 0) {
            return;
        }

        values.id = defaultValues.id;
        onSubmit(values);
    };

    const handleChange = () => {};

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                {ctf.title}
            </Typography>
            <form
                id={formId}
                onSubmit={(e) => {
                    clearErrors();
                    handleSubmit(handleSubmitCallback)(e);
                }}
            >
                <Typography component="h2" variant="h5" gutterBottom>
                    {ctf.isCommunalCompany.title}
                </Typography>

                <RadioButtons
                    groupLabel={ctf.isCommunalCompany.label}
                    name="isCommunalCompany"
                    control={control}
                    buttons={[
                        { label: 'Ja', value: 'true' },
                        { label: 'Nein', value: 'false' },
                    ]}
                    disabled={saving}
                    ariaLabel={ctf.isCommunalCompany.ariaLabel}
                    defaultValue={defaultValues.isCommunalCompany}
                    rules={{ required: ctf.isCommunalCompany.error }}
                    errors={errors.isCommunalCompany}
                />

                <Typography
                    component="h2"
                    variant="h5"
                    className={classes.mt}
                    gutterBottom
                >
                    {ctf.corporateForm.title}
                </Typography>

                <Typography variant="body1" gutterBottom>
                    {ctf.corporateForm.label}
                </Typography>

                <CheckboxWithInfoText
                    name="hasHRGNumber"
                    checked={hasHRGNumber}
                    register={register}
                    defaultChecked={defaultValues.hasHRGNumber}
                    label={ctf.corporateForm.hasHRGNumber.label}
                    onChange={handleChange}
                    ariaLabel={ctf.corporateForm.hasHRGNumber.ariaLabel}
                    rules={{
                        required: ctf.corporateForm.hasHRGNumber.error,
                    }}
                    errors={errors.hasHRGNumber}
                    disabled={saving}
                />

                {hasHRGNumber && (
                    <>
                        <RHFTextField
                            label={ctf.corporateForm.hRGNumber.label}
                            control={control}
                            name="hRGNumber"
                            disabled={saving}
                            validation={{
                                required: ctf.corporateForm.hRGNumber.error,
                            }}
                            error={errors.hRGNumber}
                        />
                    </>
                )}

                <CheckboxWithInfoText
                    name="hasTradeRegistered"
                    checked={hasTradeRegistered}
                    register={register}
                    defaultChecked={defaultValues.hasTradeRegistered}
                    label={ctf.corporateForm.hasTradeRegistered.label}
                    onChange={handleChange}
                    ariaLabel={ctf.corporateForm.hasTradeRegistered.ariaLabel}
                    rules={{
                        required: ctf.corporateForm.hasTradeRegistered.error,
                    }}
                    errors={errors.hasTradeRegistered}
                    disabled={saving}
                />

                {hasTradeRegistered && (
                    <>
                        <RHFTextField
                            label={ctf.corporateForm.tradeLicenceNumber.label}
                            control={control}
                            name="tradeLicenceNumber"
                            disabled={saving}
                            validation={{
                                required:
                                    ctf.corporateForm.tradeLicenceNumber.error,
                            }}
                            error={errors.tradeLicenceNumber}
                        />
                    </>
                )}

                <CheckboxWithInfoText
                    name="isCraftCompany"
                    checked={isCraftCompany}
                    register={register}
                    defaultChecked={defaultValues.isCraftCompany}
                    label={ctf.corporateForm.isCraftCompany.label}
                    onChange={handleChange}
                    ariaLabel={ctf.corporateForm.isCraftCompany.ariaLabel}
                    rules={{
                        required: ctf.corporateForm.isCraftCompany.error,
                    }}
                    errors={errors.isCraftCompany}
                    disabled={saving}
                />

                {isCraftCompany && (
                    <>
                        <RHFTextField
                            label={ctf.corporateForm.craftNumber.label}
                            control={control}
                            name="craftNumber"
                            disabled={saving}
                            validation={{
                                required: ctf.corporateForm.craftNumber.error,
                            }}
                            error={errors.craftNumber}
                        />
                    </>
                )}

                <CheckboxWithInfoText
                    name="isFreelance"
                    checked={isFreelance}
                    register={register}
                    defaultChecked={defaultValues.isFreelance}
                    label={ctf.corporateForm.isFreelance.label}
                    onChange={handleChange}
                    ariaLabel={ctf.corporateForm.isFreelance.ariaLabel}
                    rules={{
                        required: ctf.corporateForm.isFreelance.error,
                    }}
                    errors={errors.isFreelance}
                    disabled={saving}
                />

                {isFreelance && (
                    <>
                        <RHFTextField
                            label={ctf.corporateForm.taxNumber.label}
                            control={control}
                            name="taxNumber"
                            disabled={saving}
                            validation={{
                                required: ctf.corporateForm.taxNumber.error,
                            }}
                            error={errors.taxNumber}
                        />
                    </>
                )}
                <Typography
                    component="h2"
                    variant="h5"
                    className={classes.mt}
                    gutterBottom
                >
                    {ctf.legalForm.title}
                </Typography>

                <Typography variant="body1" gutterBottom>
                    {ctf.legalForm.subhead}
                </Typography>

                <RHFAutocomplete
                    control={control}
                    asyncFunc={getLegalList}
                    getValues={getValues}
                    defaultValue={defaultValues.legalForm || null}
                    label={ctf.legalForm.label}
                    name="legalForm"
                    error={errors.legalForm}
                    validation={{
                        required: ctf.legalForm.error,
                    }}
                    disabled={saving}
                />

                {legalForm?.name ===
                    'Gesellschaft bürgerlichen Rechts (GbR)' && (
                    <Alert severity="info">{ctf.legalForm.hint}</Alert>
                )}

                {isOther(legalForm) && (
                    <TextInput
                        label={ctf.otherLegalForm.label}
                        register={register}
                        name="otherLegalForm"
                        validation={{
                            required: ctf.otherLegalForm.error,
                        }}
                        defaultValue={defaultValues.otherLegalForm}
                        fullWidth
                        error={errors.otherLegalForm}
                        gutter={2}
                        disabled={saving}
                    />
                )}

                <Typography
                    component="h2"
                    variant="h5"
                    className={classes.mt}
                    gutterBottom
                >
                    {ctf.isFoundedLast2Y.title}
                </Typography>

                <RadioButtons
                    groupLabel={ctf.isFoundedLast2Y.label}
                    name="isFoundedLast2Y"
                    control={control}
                    buttons={[
                        { label: 'Ja', value: 'true' },
                        { label: 'Nein', value: 'false' },
                    ]}
                    disabled={saving}
                    ariaLabel={ctf.isFoundedLast2Y.ariaLabel}
                    defaultValue={defaultValues.isFoundedLast2Y}
                    rules={{ required: ctf.isFoundedLast2Y.error }}
                    errors={errors.isFoundedLast2Y}
                />

                {isFoundedLast2Y === 'true' && (
                    <>
                        <div>
                            <Typography
                                component="span"
                                variant="h5"
                                gutterBottom
                            >
                                {ctf.dateFounded.label}
                            </Typography>

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    style={{ marginLeft: '16px' }}
                                    value={dateFounded}
                                    placeholder="01.01.2020"
                                    onChange={handleDateChange}
                                    format="DD.MM.yyyy"
                                    cancelLabel="Abbrechen"
                                    error={
                                        errors.dateFounded?.message !==
                                        undefined
                                    }
                                    helperText={errors.dateFounded?.message}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <Typography
                            component="h2"
                            variant="h5"
                            className={classes.mt}
                        >
                            {ctf.uploadBalanceSheet.label}
                        </Typography>

                        <DropzoneContainer
                            prefetchFiles={fetchUploadedFilesFor(
                                proposalId,
                                'uploadBalanceSheet'
                            )}
                            keywords={['uploadBalanceSheet']}
                            linkedResource={proposalId}
                            onFilesChanged={handleFilesCallback}
                        />
                        {filesUploaded === 0 && (
                            <Typography style={{ color: 'red' }}>
                                Sie müssen mindestens eine Datei hochladen
                            </Typography>
                        )}
                    </>
                )}

                <Typography component="h2" variant="h5" className={classes.mt}>
                    {ctf.sectorKey.title}
                </Typography>

                <RHFAutocomplete
                    control={control}
                    asyncFunc={getSectorKeyList}
                    getValues={getValues}
                    label={ctf.sectorKey.label}
                    name="sectorKey"
                    error={errors.sectorKey}
                    defaultValue={defaultValues.sectorKey || null}
                    validation={{
                        required: ctf.sectorKey.error,
                    }}
                    disabled={saving}
                />

                <Typography
                    component="h2"
                    variant="h5"
                    className={classes.mt}
                    gutterBottom
                >
                    {ctf.isDeductInputTax.title}
                </Typography>

                <RadioButtons
                    groupLabel={ctf.isDeductInputTax.label}
                    name="isDeductInputTax"
                    control={control}
                    buttons={[
                        {
                            label: 'Ja',
                            value: 'true',
                            info: ctf.isDeductInputTax.info,
                        },
                        { label: 'Nein', value: 'false' },
                    ]}
                    disabled={saving}
                    ariaLabel={ctf.isDeductInputTax.ariaLabel}
                    defaultValue={defaultValues.isDeductInputTax}
                    rules={{ required: ctf.isDeductInputTax.error }}
                    errors={errors.isDeductInputTax}
                />

                <Typography
                    component="h2"
                    variant="h5"
                    className={classes.mt}
                    gutterBottom
                >
                    {ctf.employeesCount.title}
                </Typography>

                <div className={classes.link}>
                    <TextBlock textBlock={ctf.employeesCount.desc(infoPdf)} />
                </div>

                <Alert className={classes.mt} severity="info">
                    {ctf.employeesCount.hint1}
                </Alert>
                <Alert className={classes.mtHalf} severity="info">
                    {ctf.employeesCount.hint2}
                </Alert>
                <Alert className={classes.mtHalf} severity="info">
                    {ctf.employeesCount.hint3}
                </Alert>
                <div className={`${classes.mt} ${classes.link}`}>
                    <TextBlock
                        textBlock={ctf.employeeCheck.desc(infoPdf)}
                        parserOptions={{
                            components: { a: { component: LinkOtherPage } },
                        }}
                    />
                    <RadioButtons
                        name={'employeeCheck'}
                        control={control}
                        buttons={[
                            { label: 'Ja', value: 'true' },
                            { label: 'Nein', value: 'false' },
                        ]}
                        disabled={saving}
                        defaultValue={defaultValues.employeeCheck}
                        rules={ctf.employeeCheck.error}
                        errors={errors.employeeCheck}
                    />

                    {employeeCheck === 'false' && (
                        <Alert severity="info">{ctf.employeeCheck.alert}</Alert>
                    )}
                </div>

                <RadioButtons
                    groupLabel={ctf.employeesCount.label}
                    name="employeesCount"
                    control={control}
                    buttons={ctf.employeesCount.types.map((el) => ({
                        label: el.title,
                        value: el.value,
                        info: el.info,
                    }))}
                    disabled={saving}
                    className={classes.marginTopBottom}
                    ariaLabel={ctf.employeesCount.ariaLabel}
                    rules={{ required: ctf.employeesCount.error }}
                    errors={errors.employeesCount}
                />
            </form>

            {isCommunalCompanyError && (
                <DialogWithLogout
                    title={ctf.noCommunalCompany.title}
                    content={ctf.noCommunalCompany.body}
                    closeCallback={() => {
                        setIsCommunalCompanyError(null);
                    }}
                    isOpen={true}
                />
            )}

            {employeesCountError && (
                <DialogWithLogout
                    title={ctf.employeesCount.valueError}
                    content={employeesCountError}
                    closeCallback={() => {
                        setEmployeesCountError(null);
                    }}
                    isOpen={true}
                />
            )}
        </>
    );
};

CompanyTypeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

CompanyTypeForm.defaultProps = {
    formId: 'wizardForm',
    saving: false,
    error: null,
    defaultValues: {},
};

export default CompanyTypeForm;
