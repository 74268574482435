import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

const ProofTable = (props) => {
    const { className, renderTable, ...rest } = props;

    return (
        <Paper elevation={0} square className={className}>
            <TableContainer>{renderTable({ ...rest })}</TableContainer>
        </Paper>
    );
};

ProofTable.propTypes = {
    className: PropTypes.string,
    index: PropTypes.number,
    renderTable: PropTypes.func,
    deductInputTax: PropTypes.bool,
    register: PropTypes.func,
    control: PropTypes.object,
};

export default ProofTable;
