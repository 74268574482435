import React from 'react';
import Typography from '@material-ui/core/Typography';
import { texts as dbt } from '../../texts/Dashboard/DashboardTexts';
import Alert from '@material-ui/lab/Alert';
import TextBlock from '../TextBlock/TextBlock';
import LinkSameSite from '../TextBlock/components/LinkSameSite';
import RaffleUpcomingWrapper from '../Raffle/Upcoming/RaffleUpcomingWrapper';
import moment from 'moment/moment';

const DashboardAuth = (props) => {
    const is2024 = parseInt(moment().format('YYYY')) > 2023;
    const block = is2024 ? dbt.authDashboard2024 : dbt.authDashboard;

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                {dbt.static.headlineLoggedIn}
            </Typography>
            <Alert severity="info">
                <TextBlock
                    headlineVariant="h6"
                    textBlock={block}
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkSameSite,
                                props: {},
                            },
                        },
                    }}
                />
            </Alert>
            {!is2024 && <RaffleUpcomingWrapper />}
        </>
    );
};

export default DashboardAuth;
