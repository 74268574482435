import React from 'react';
import PropTypes from 'prop-types';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import RenderTableHead from '../Components/RenderTableHead';
import TableBody from '@material-ui/core/TableBody';
import RenderEmptyRow from '../Components/RenderEmptyRow';
import TableFooter from '@material-ui/core/TableFooter';
import RenderTableFooter from '../Components/RenderTableFooter';
import ActivityTableRowEdit from './ActivityTableRowEdit';
import ActivityTableRow from './ActivityTableRow';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';
import { strToFloat } from '../../../../util/Money/MoneyFunctions';

const ActivityTable = (props) => {
    const { deductInputTax, control, register, setSum } = props;

    const columns = React.useMemo(
        () =>
            deductInputTax
                ? [
                      ...fpt.proof.activityList.columns,
                      ...fpt.proof.activityList.columnsNet,
                  ]
                : [
                      ...fpt.proof.activityList.columns,
                      ...fpt.proof.activityList.columnsBru,
                  ],

        [deductInputTax]
    );

    const [editIndex, setEditIndex] = React.useState(-1);
    const [disabledAdd, setDisableAdd] = React.useState(false);
    const [isAddingNew, setIsAddingNew] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const { append, fields, remove } = useFieldArray({
        control,
        name: 'activityList',
    });

    const priceLabel = deductInputTax ? 'netPrice' : 'price';

    const calculateTotal = React.useCallback(
        (activities) => {
            return activities.reduce((total, activity) => {
                const amount = parseFloat(activity.amount);
                const price = parseInt(activity[priceLabel]);

                return total + Math.round(amount * price);
            }, 0);
        },
        [priceLabel]
    );

    React.useEffect(
        () => setTotal(calculateTotal(fields)),
        [fields, priceLabel, calculateTotal]
    );

    React.useEffect(() => {
        setSum(total);
    }, [total, setSum]);

    const handleAddNew = React.useCallback(() => {
        setDisableAdd(true);
        setIsAddingNew(true);
    }, [setIsAddingNew]);

    const handleAddCancel = React.useCallback(() => {
        setDisableAdd(false);
        setIsAddingNew(false);
    }, [setDisableAdd, setIsAddingNew]);

    const handleAddSave = React.useCallback(
        (data) => {
            data.amount = strToFloat(data.amount);
            append({ id: uuid4(), ...data });
            handleAddCancel();
        },
        [handleAddCancel, append]
    );

    const handleEditStart = React.useCallback(
        (index) => {
            setEditIndex(index);
        },
        [setEditIndex]
    );

    const handleEditCancel = React.useCallback(() => {
        setEditIndex(-1);
    }, [setEditIndex]);

    const handleEditSave = React.useCallback(
        (data, activityIndex) => {
            fields[activityIndex] = { ...fields[activityIndex], ...data };
            fields[activityIndex].amount = strToFloat(
                fields[activityIndex].amount
            );
            setTotal(calculateTotal(fields));
            setEditIndex(-1);
        },
        [fields, setEditIndex, setTotal, calculateTotal]
    );

    const handleRemoveDevice = React.useCallback(
        (activityIndex) => {
            remove(activityIndex);
        },
        [remove]
    );

    return (
        <Table size="small">
            <TableHead>{RenderTableHead({ columns })}</TableHead>
            <TableBody>
                {fields.length === 0 && <RenderEmptyRow colSpan={6} />}
                {fields.map((activity, activityIndex) =>
                    editIndex === activityIndex ? (
                        <ActivityTableRowEdit
                            key={activity.id}
                            defaultValues={activity}
                            index={activityIndex}
                            deductInputTax={deductInputTax}
                            saveCallback={handleEditSave}
                            cancelCallback={handleEditCancel}
                        />
                    ) : (
                        <ActivityTableRow
                            key={activity.id}
                            defaultValues={activity}
                            index={activityIndex}
                            deductInputTax={deductInputTax}
                            editCallback={handleEditStart}
                            deleteCallback={handleRemoveDevice}
                            register={register}
                        />
                    )
                )}

                {isAddingNew && (
                    <ActivityTableRowEdit
                        index={fields.length}
                        cancelCallback={handleAddCancel}
                        deductInputTax={deductInputTax}
                        saveCallback={handleAddSave}
                    />
                )}
            </TableBody>
            <TableFooter>
                {RenderTableFooter({
                    disabled: disabledAdd,
                    handleAddNew,
                    total: total,
                })}
            </TableFooter>
        </Table>
    );
};

ActivityTable.propTypes = {
    deductInputTax: PropTypes.bool,
    control: PropTypes.object,
    register: PropTypes.func,
    setSum: PropTypes.func,
};

export default ActivityTable;
