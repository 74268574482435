import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextBlock from '../../../TextBlock/TextBlock';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';

const ProofRadioGroup = (props) => {
    const { texts, name, className, register, defaultValue, error } = props;

    return (
        <FormControl
            className={className}
            component="fieldset"
            fullWidth
            error={!!error.message}
        >
            {texts.label && (
                <TextBlock
                    textBlock={texts.label}
                    paragraphRootComponent={texts.label.paragraphRootComponent}
                    paragraphProps={texts.label.paragraphProps}
                />
            )}
            <RadioGroup defaultValue={defaultValue} name={name}>
                {texts.fields.map((field) => (
                    <FormControlLabel
                        key={field.key}
                        label={<TextBlock textBlock={field.label} />}
                        value={field.value}
                        defaultValue={defaultValue}
                        control={
                            <Radio
                                color="primary"
                                inputRef={register(texts.error)}
                            />
                        }
                    />
                ))}
            </RadioGroup>
            {!!error.message && (
                <FormHelperText>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

ProofRadioGroup.propTypes = {
    texts: PropTypes.object,
    name: PropTypes.string,
    className: PropTypes.string,
    register: PropTypes.func,
    defaultValue: PropTypes.string,
    error: PropTypes.object,
};

ProofRadioGroup.defaultProps = {
    defaultValue: '',
    error: {},
};

export default ProofRadioGroup;
