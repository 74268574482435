import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import { Collapse } from '@material-ui/core';

const FormFooter = (props) => {
    const { children, text, displayText, gutterTop } = props;

    return (
        <Grid
            container
            spacing={2}
            style={{ marginTop: gutterTop ? 16 : 0 }}
            alignItems="center"
        >
            <Grid item>{children}</Grid>
            <Grid item>
                <Collapse in={displayText}>
                    <Typography style={{ color: red[500] }}>{text}</Typography>
                </Collapse>
            </Grid>
        </Grid>
    );
};

FormFooter.propTypes = {
    children: PropTypes.node,
    displayText: PropTypes.bool,
    text: PropTypes.string,
    gutterTop: PropTypes.bool,
};

export default FormFooter;
