import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import MinimisTextField from './MinimisTextField';
import { texts as mft } from '../../../texts/Forms/MinimisFormTexts';
import MinimisSelect from './MinimisSelect';
import {
    centToString,
    isMoneyString,
    stringToCents,
} from '../../../util/Money/MoneyFunctions';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    removeBorder: {
        '& td': {
            borderBottom: 0,
        },
    },
    input: {
        width: '100%',
    },
    cell: {
        verticalAlign: 'top',
    },
});

const DateField = (props) => (
    <TextField {...props} size="small" variant="outlined" fullWidth />
);

const MinimisTableEditableRow = (props) => {
    const { values, onAbort, onSave, year, showDate } = props;
    const classes = useStyles();
    const [errors, setErrors] = React.useState({
        fundingAmount: false,
        type: false,
        fkz: false,
        provider: false,
        executionDate: false,
        investmentAmount: false,
    });

    const [selectValue, setSelectValue] = React.useState(values.type);
    const [executionDate, setExecutionDate] = React.useState(
        values.executionDate || null
    );
    const fundingAmountRef = React.useRef();
    const fkzRef = React.useRef();
    const providerRef = React.useRef();
    const investmentAmountRef = React.useRef();

    const validation = (values) => {
        const err = {};
        if (!isMoneyString(values.fundingAmount)) {
            err.fundingAmount = true;
        }
        if (!isMoneyString(values.investmentAmount)) {
            err.investmentAmount = true;
        }

        if (values.fkz === '') {
            err.fkz = true;
        }

        if (values.provider === '') {
            err.provider = true;
        }

        if (values.type === undefined) {
            err.type = true;
        }

        if (showDate) {
            if (values.executionDate < moment(`${year}-01-01`).startOf('day')) {
                err.executionDate = true;
            }
            if (values.executionDate > moment(`${year}-12-31`).endOf('day')) {
                err.executionDate = true;
            }
        }

        if (Object.keys(err).length > 0) {
            setErrors((state) => ({ ...state, ...err }));
            return false;
        }
        return true;
    };

    const removeError = (key) => () => {
        setErrors((state) => ({ ...state, [key]: false }));
    };

    const handleSave = () => {
        const obj = {
            fundingAmount: fundingAmountRef.current.value,
            fkz: fkzRef.current.value,
            provider: providerRef.current.value,
            type: selectValue,
            executionDate: executionDate,
            investmentAmount: investmentAmountRef.current.value,
        };

        if (!validation(obj)) {
            return;
        }
        obj.fundingAmount = centToString(stringToCents(obj.fundingAmount));
        obj.investmentAmount = centToString(
            stringToCents(obj.investmentAmount)
        );
        onSave(obj);
    };

    return (
        <>
            <TableRow className={classes.removeBorder}>
                <TableCell colSpan={100}>
                    <fieldset style={{ borderColor: 'black' }}>
                        <legend>Neue De-Minimi-Beihilfe hinzufügen </legend>
                        <Grid container spacing={3}>
                            {showDate && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <KeyboardDatePicker
                                        mask="__.__.____"
                                        clearable
                                        format="DD.MM.yyyy"
                                        placeholder={`01.01.${year}`}
                                        value={executionDate}
                                        onChange={(date) => {
                                            setErrors({
                                                ...errors,
                                                executionDate: date
                                                    ? !date.isValid()
                                                    : false,
                                            });
                                            setExecutionDate(date);
                                        }}
                                        minDate={moment(`${year}-01-01`)}
                                        maxDate={moment(`${year}-12-31`)}
                                        invalidDateMessage={'Datum ungültig'}
                                        minDateMessage={`Datum darf nicht vor dem Jahr ${year} liegen`}
                                        maxDateMessage={`Datum darf nicht nach dem Jahr ${year} liegen`}
                                        TextFieldComponent={DateField}
                                        KeyboardButtonProps={{ size: 'small' }}
                                        cancelLabel="Abbrechen"
                                        clearLabel="Löschen"
                                        okLabel="Speichern"
                                        todayLabel="Heute"
                                        error={errors.executionDate}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6} md={4}>
                                <MinimisTextField
                                    ref={providerRef}
                                    value={values.provider}
                                    label={mft.calculator.fields.provider.label}
                                    error={errors.provider}
                                    removeError={removeError('provider')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MinimisTextField
                                    ref={fkzRef}
                                    value={values.fkz}
                                    label={mft.calculator.fields.fkz.label}
                                    error={errors.fkz}
                                    removeError={removeError('fkz')}
                                    style={{ minWidth: 220 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MinimisSelect
                                    label={mft.calculator.fields.type.label}
                                    value={selectValue}
                                    items={mft.calculator.fields.type.items}
                                    onChange={setSelectValue}
                                    error={errors.type}
                                    removeError={removeError('type')}
                                    style={{ maxWidth: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MinimisTextField
                                    ref={fundingAmountRef}
                                    value={values.fundingAmount}
                                    label={
                                        mft.calculator.fields.fundingAmount
                                            .label
                                    }
                                    error={errors.fundingAmount}
                                    removeError={removeError('fundingAmount')}
                                    placeholder="0,00"
                                    endAdornment="€"
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MinimisTextField
                                    ref={investmentAmountRef}
                                    value={values.investmentAmount}
                                    label={
                                        mft.calculator.fields.investmentAmount
                                            .label
                                    }
                                    error={errors.investmentAmount}
                                    removeError={removeError(
                                        'investmentAmount'
                                    )}
                                    placeholder="0,00"
                                    endAdornment="€"
                                    className={classes.input}
                                    title={
                                        mft.calculator.fields.investmentAmount
                                            .title
                                    }
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={7}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Speichern
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '8px' }}
                        onClick={onAbort}
                    >
                        Abbrechen
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

MinimisTableEditableRow.propTypes = {
    values: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onAbort: PropTypes.func.isRequired,
    year: PropTypes.number,
};

MinimisTableEditableRow.defaultProps = {
    values: {},
};

export default MinimisTableEditableRow;
