import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const TextfieldDigi2 = (props) => {
    const {
        defaultValue,
        max,
        error,
        register,
        Label,
        validation,
        name,
        setCallback,
        ...rest
    } = props;

    const [value, setValue] = React.useState(defaultValue || '');

    const handleChange = (evt) => {
        if (evt.target.value.length > max) {
            setValue(evt.target.value.slice(0, max));
            if (setCallback) setCallback(evt.target.value !== '');
            return;
        }
        setValue(evt.target.value);
        if (setCallback) setCallback(evt.target.value !== '');
    };

    const renderHelperText = (error) => {
        return `${value.length}/${max} Zeichen${error ? ' - ' + error : ''}`;
    };

    return (
        <>
            {Label && <label htmlFor={`${name}_id`}>{Label}</label>}
            <TextField
                id={`${name}_id`}
                name={name}
                inputRef={register(validation)}
                fullWidth
                onChange={handleChange}
                error={!!error.message}
                helperText={renderHelperText(error.message)}
                value={value}
                {...rest}
            />
        </>
    );
};

TextfieldDigi2.propTypes = {
    max: PropTypes.number,
    defaultValue: PropTypes.string,
    register: PropTypes.func,
    error: PropTypes.object,
    Label: PropTypes.any,
    validation: PropTypes.object,
    name: PropTypes.string,
    setCallback: PropTypes.func,
};

TextfieldDigi2.defaultProps = {
    max: 200,
    defaultValue: '',
    validation: {},
    error: {},
};

export default TextfieldDigi2;
