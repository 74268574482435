import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ProofRadioGroup from '../Components/ProofRadioGroup';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProofTextField from '../Components/ProofTextField';
import DropzoneContainer from '../../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../../lib/api/Files/FileActions';
import TextBlock from '../../../TextBlock/TextBlock';
import ProofTable from '../Components/ProofTable';
import DeviceTable from './DeviceTable';
import ProofCheckbox from '../Components/ProofCheckbox';
import ActivityTable from './ActivityTable';
import ProofTotal from '../Components/ProofTotal';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { validateBasicEquipment } from '../../../../util/Validation/FinancingPlanValidation';
import { validateStringMin } from '../../../../util/Validation/ValidateStringMin';

const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(3),
    },
    heading: {
        marginTop: theme.spacing(4),
    },
}));

const ProofEdit = (props) => {
    const {
        closeCallback,
        index,
        deductInputTax,
        moduleOne,
        moduleTwo,
        defaultValues,
        saveCallback,
        proposalId,
    } = props;
    const classes = useStyles();

    const {
        register,
        control,
        errors,
        handleSubmit,
        getValues,
        trigger,
        watch,
    } = useForm({
        defaultValues: defaultValues,
    });

    const [files, setFiles] = React.useState({ device: [], certificate: [] });

    const handleFileChange = (which) => (data) => {
        setFiles((state) => ({ ...state, [which]: data }));
    };

    React.useEffect(() => {
        trigger(['devices', 'certs']).then();
    }, [files, trigger]);

    const [sumDevice, setSumDevice] = React.useState(
        defaultValues.sumDevice || 0
    );
    const [sumActivity, setSumActivity] = React.useState(
        defaultValues.sumActivity || 0
    );

    const submitCallback = (values) => {
        if (!values.deviceList) values.deviceList = [];
        if (!values.activityList) values.activityList = [];

        saveCallback({ ...values, files }, index);
    };

    const [proofSavable, setProofSavable] = React.useState(false);

    const deviceList = watch('deviceList');
    const activityList = watch('activityList');
    React.useEffect(() => {
        const moduleOneFilled = moduleOne && deviceList?.length > 0;
        const moduleTwoFilled =
            moduleTwo &&
            activityList?.length > 0 &&
            files?.certificate?.length > 0;

        setProofSavable(moduleOneFilled || moduleTwoFilled);
    }, [
        deviceList,
        activityList,
        setProofSavable,
        moduleOne,
        moduleTwo,
        files,
    ]);

    return (
        <form onSubmit={handleSubmit(submitCallback)}>
            <Typography variant="h5" className={classes.heading}>
                {fpt.static.editTitle}
                {index + 1}
            </Typography>

            <ProofRadioGroup
                texts={fpt.parser.proofType}
                name={'proofType'}
                className={classes.field}
                register={register}
                defaultValue={defaultValues.proofType}
                error={errors.proofType}
            />

            <ProofTextField
                className={classes.field}
                texts={fpt.parser.proofDescription}
                name="proofDescription"
                register={register}
                defaultValue={defaultValues.proofDescription}
                error={errors.proofDescription}
            />

            <ProofTextField
                className={classes.field}
                texts={fpt.parser.proofProvider}
                name="proofProvider"
                register={register}
                defaultValue={defaultValues.proofProvider}
                error={errors.proofProvider}
            />

            <Typography variant={'h6'} className={classes.field}>
                {fpt.static.uploadOfProof}
            </Typography>

            <DropzoneContainer
                prefetchFiles={fetchUploadedFilesFor(defaultValues.id, [
                    proposalId,
                    'financeplan',
                    'device',
                ])}
                keywords={[proposalId, 'financeplan', 'device', 'tmp']}
                linkedResource={defaultValues.id}
                onFilesChanged={handleFileChange('device')}
                acceptedFileTypes={['.pdf']}
                maxFileSize={18}
            />
            {errors.devices && (
                <Typography style={{ color: '#f44336' }}>
                    {errors.devices.message}
                </Typography>
            )}
            <input
                type="hidden"
                name="devices"
                value={files.device.length}
                ref={register({
                    validate: (value) =>
                        validateStringMin(value, 1, fpt.proof.fileCount.device),
                })}
            />

            {moduleOne && (
                <>
                    <Typography
                        variant="h5"
                        className={classes.field}
                        gutterBottom
                    >
                        {fpt.proof.moduleOne.title}
                    </Typography>
                    <TextBlock
                        textBlock={fpt.parser.deviceList}
                        headlineVariant="h6"
                    />

                    <TextBlock
                        textBlock={
                            deductInputTax
                                ? fpt.parser.sumTax
                                : fpt.parser.sumNoTax
                        }
                    />

                    <ProofTable
                        className={classes.field}
                        index={index}
                        renderTable={DeviceTable}
                        register={register}
                        control={control}
                        setSum={setSumDevice}
                        deductInputTax={deductInputTax}
                    />

                    <ProofCheckbox
                        label={fpt.proof.confirm.label}
                        name="isNotBasicEquipment"
                        className={classes.field}
                        error={errors.isNotBasicEquipment}
                        register={register}
                        defaultChecked={defaultValues.isNotBasicEquipment}
                        validation={{
                            validate: (value) => {
                                const devices = getValues('deviceList[0].id');
                                return validateBasicEquipment(
                                    value,
                                    devices !== undefined,
                                    fpt.proof.confirm.error.required
                                );
                            },
                        }}
                    />

                    <div className={classes.field}>
                        <TextBlock textBlock={fpt.parser.notSame} />

                        <ProofTextField
                            max={600}
                            className={classes.field}
                            name="deviceNotSame"
                            variant="outlined"
                            register={register}
                            defaultValue={defaultValues.deviceNotSame}
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 600 }}
                        />
                    </div>
                </>
            )}

            {moduleTwo && (
                <>
                    <Typography
                        className={classes.field}
                        variant="h5"
                        gutterBottom
                    >
                        {fpt.proof.moduleTwo.title}
                    </Typography>

                    <TextBlock textBlock={fpt.parser.certificate} />
                    <Typography variant={'h6'} className={classes.field}>
                        {fpt.static.uploadOfProof2}
                    </Typography>
                    <DropzoneContainer
                        prefetchFiles={fetchUploadedFilesFor(defaultValues.id, [
                            proposalId,
                            'financeplan',
                            'certificate',
                        ])}
                        keywords={[
                            proposalId,
                            'financeplan',
                            'certificate',
                            'tmp',
                        ]}
                        linkedResource={defaultValues.id}
                        onFilesChanged={handleFileChange('certificate')}
                        acceptedFileTypes={['.pdf']}
                        maxFileSize={18}
                    />
                    {errors.certificate && (
                        <Typography style={{ color: '#f44336' }}>
                            {errors.certificate.message}
                        </Typography>
                    )}
                    <input
                        type="hidden"
                        name="certificate"
                        value={files.certificate.length}
                        ref={register({
                            validate: (value) => {
                                const { activityList } = getValues();

                                if (!activityList || activityList.length === 0)
                                    return true;

                                return validateStringMin(
                                    value,
                                    1,
                                    fpt.proof.fileCount.certificate
                                );
                            },
                        })}
                    />

                    <div style={{ marginTop: 16 }}>
                        <TextBlock
                            textBlock={
                                deductInputTax
                                    ? fpt.parser.sumTax
                                    : fpt.parser.sumNoTax
                            }
                        />
                    </div>

                    <ProofTable
                        className={classes.field}
                        index={index}
                        renderTable={ActivityTable}
                        control={control}
                        register={register}
                        setSum={setSumActivity}
                        deductInputTax={deductInputTax}
                    />

                    <div className={classes.field}>
                        <TextBlock textBlock={fpt.parser.notSame} />

                        <ProofTextField
                            max={600}
                            index={index}
                            className={classes.field}
                            register={register}
                            defaultValue={defaultValues.activityNotSame}
                            name="activityNotSame"
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 600 }}
                        />
                    </div>
                </>
            )}

            <ProofTotal
                className={classes.field}
                deductInputTax={deductInputTax}
                sumDevice={sumDevice}
                sumActivity={sumActivity}
                register={register}
            />

            <div className={classes.field} style={{ paddingBottom: '32px' }}>
                <div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!proofSavable}
                    >
                        Nachweis speichern
                    </Button>
                    <Button
                        onClick={() => closeCallback()}
                        style={{ marginLeft: '16px' }}
                        variant="outlined"
                    >
                        Abbrechen
                    </Button>
                </div>
                <Typography style={{ marginTop: '8px' }} gutterBottom>
                    Bedenken Sie, dass alle ungespeicherten Informationen
                    verloren gehen, wenn Sie auf abbrechen drücken.
                </Typography>
            </div>
        </form>
    );
};

ProofEdit.propTypes = {
    closeCallback: PropTypes.func,
    deductInputTax: PropTypes.bool,
    index: PropTypes.number,
    moduleOne: PropTypes.bool,
    moduleTwo: PropTypes.bool,
    defaultValues: PropTypes.object,
    saveCallback: PropTypes.func,
    proposalId: PropTypes.string,
};

export default ProofEdit;
