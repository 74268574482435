import uniqid from 'uniqid';
import Typography from '@material-ui/core/Typography';

export const texts = {
    title: 'Finanzierungsplan',
    introduction: {
        text1: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'In Ihrem Finanzierungsplan erfassen Sie alle Ausgaben und Investitionsgegenstände, für die Sie eine Förderung beantragen. Stellen Sie sicher, dass hier nur die Ausgaben und Investitionsgegenstände erfasst werden, die in Bezug zu den im Digitalisierungsplan dargestellten Digitalisierungsvorhaben stehen.',
                },
            ],
        },
        text2: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Es können nur [tooltip|0]zuwendungsfähige Ausgaben / Investitionen[tooltip] berücksichtigt werden. Für diese ist als Nachweis eine Preisermittlung vorzulegen (Art: ein schriftliches Angebot / ein Kostenvoranschlag eines Anbieters, eine Kostenermittlung).',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'Zuwendungsfähig sind Ausgaben für:',
                                },
                                {
                                    type: 'list',
                                    text: '[li]konkret zu benennende digitale Technologien mit grundlegend neuen Funktionen und damit verbundene Prozesse und Implementierungen (Umsetzung).[li][ul][li]Hardware[li][li]Software, Updates soweit neue Funktionen damit verbunden sind[li][li]Für die Dauer Vorhabenlaufzeit auch[li][ul][li]Ausgaben für Hardware, die über Mietkauf oder Leasing finanziert werden[li][li]Lizenzkosten und Systemservice-Gebühren für Software[li][ul]Bitte lassen Sie die Ausgaben im Nachweis (Angebot, eigene Kostenermittlung) vom IT-Dienstleister entsprechend auf die geplante Laufzeit aufschlüsseln.[ul][li]Qualifizierungsmaßnahmen erbracht von zertifizierten Anbietern[li]',
                                },
                                {
                                    type: 'p',
                                    text: 'Die Investitionen',
                                },
                                {
                                    type: 'list',
                                    text: '[li]dürfen ausschließlich dem Vorhabenzweck dienen und müssen in Bezug zu den gewählten Maßnahmen stehen;[li]',
                                },
                                {
                                    type: 'p',
                                    text: '[li]müssen im Vorhaben verwendet werden.[li]',
                                },
                                {
                                    type: 'p',
                                    text: 'Folgende Ausgaben werden [u]nicht[u] gefördert und sind aus Eigenmitteln zu finanzieren:',
                                },
                                {
                                    type: 'list',
                                    text: '[li]Ausgaben für erstmalige Standardinvestitionen in Informations- und Kommunikationstechnologie,[li][ul][li]die [u]nicht[u] unmittelbar dem Vorhabenzweck dienen und[li][li]die der erstmaligen Grundausstattung zuzurechnen sind.[li][ul]',
                                },
                                {
                                    type: 'list',
                                    text: '[li]Ausgaben für Ersatz- oder Routine-Investitionen wie  beispielsweise :[li][ul][li]Standard-Hardware (Computer mit bisheriger Leistung für wachsende Mitarbeiteranzahl)[li][li]Standard-Software  (bspw. herkömmliche Bürosoftware, E-Mail-Archivierung, Exchange Server) und deren Updates ohne grundlegende neue Funktionen; [li][ul]',
                                },
                                {
                                    type: 'list',
                                    text: '[li]Ausgaben für Leistungen wie Beratung, Planung / Konzeptionierung und Strukturierung des Projekts,[li][ul][li][u]die im Vorfeld[u] der Antragstellung erbracht werden, und / oder[li][li]die bereits über die Förderung „go digital“ finanziert worden sind.[li][ul]',
                                },
                                {
                                    type: 'list',
                                    text: '[li]Ausgaben für[li][ul][li]Leistungen von Unternehmen, die mit dem antragstellenden Unternehmen verbunden sind (z. B in einem Konzern oder als Tochterunternehmen);[li][li]eigene Entwicklungskapazitäten für Innovationen;[li][li]eigenes Personal;[li][li]Reisen der eigenen Mitarbeitenden,[li][li]Verwaltung und Maßnahmen, denen eine gesetzliche Verpflichtung zugrunde liegt (wie das Erstellen eines Beiblatts zu Beteiligungsverhältnissen, Angaben zu den Besitz- und Beteiligungsverhältnissen (verbundene Unternehmen / Partnerunternehmen); Unternehmen De-minimis-Bescheinigung.)[li][ul]',
                                },
                                {
                                    type: 'p',
                                    text: 'Ausgaben für Dienstleistungen zur Erbringung der oben genannten [u]nicht[u] zuwendungsfähigen Investitionen in den geplanten Maßnahmen;',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'p',
                    text: 'Aufträge sind an [tooltip|0]externe Anbieter[tooltip] zu vergeben. Aus den Eingaben der Positionen aus allen Nachweisen wird eine „Gesamtliste der Gegenstände“ in Summe generiert. Diese enthält Anzahl, Bezeichnung, Einzelpreis und Gesamtpreis aller „Investitions-Gegenstände“ / Qualifizierungsmaßnahmen.',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'In der Umsetzung der Vorhaben sind Aufträge nur an externe fachkundige und leistungsfähige Anbieter nach wettbewerblichen Gesichtspunkten zu wirtschaftlichen Bedingungen zu vergeben. Verbundene Unternehmen und Partnerunternehmen sind als intern zu bewerten.',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    parser: {
        proofType: {
            label: {
                paragraphs: [
                    {
                        type: 'p',
                        text: '(1) Art des Nachweises',
                    },
                ],
                paragraphProps: {
                    variant: 'h6',
                    component: Typography,
                },
            },
            error: {
                required:
                    'Bitte geben Sie an, um welche Art es sich bei dem Nachweis handelt',
            },
            fields: [
                {
                    key: uniqid(),
                    value: '0',
                    label: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Angebot / Einfacher Kostenvoranschlag',
                            },
                        ],
                    },
                },
                {
                    key: uniqid(),
                    value: '1',
                    label: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: '[tooltip|0]Kostenermittlung[tooltip] auf Basis eigener Recherchen',
                                tooltips: [
                                    {
                                        interactive: true,
                                        paragraphs: [
                                            {
                                                type: 'p',
                                                text: 'Eine Kostenermittlung für Anschaffungsgegenstände / Investitionen ist über eine eigene Recherche möglich bis zu einem Wert von maximal 5.000,00 Euro .',
                                            },
                                            {
                                                type: 'p',
                                                text: 'Die Ergebnisse sind über einen Auszug bspw. einer Internetseite und die Bezeichnung der Quelle zu dokumentieren und hochzuladen.',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
        },
        hasModuleTwo: {
            title: 'Enthält der vorliegende hier bearbeitete Nachweis noch Ausgaben bezogen auf Modul 2 Qualifizierungsmaßnahmen?',
            error: {
                required:
                    'Bitte wählen Sie aus, ob der vorliegende Nachweis noch Ausgaben bezogen auf Modul 2 enthält',
            },
            fields: [
                {
                    key: uniqid(),
                    value: 'true',
                    label: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Ja',
                            },
                        ],
                    },
                },
                {
                    key: uniqid(),
                    value: 'false',
                    label: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Nein',
                            },
                        ],
                    },
                },
            ],
        },
        proofDescription: {
            error: {
                required:
                    'Es muss eine Bezeichnung für den Nachweis angegeben werden',
            },
            label: {
                paragraphs: [
                    {
                        type: 'p',
                        text: '(2) [tooltip|0]Bezeichnung[tooltip] des Nachweises',
                        tooltips: [
                            {
                                interactive: false,
                                paragraphs: [
                                    {
                                        type: 'p',
                                        text: 'Geben Sie dem Angebot bitte ein aussagefähige Bezeichung auch in Bezug auf Ihr Digitalisierungsprojekt.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                paragraphProps: {
                    variant: 'h6',
                    component: Typography,
                },
            },
        },
        proofProvider: {
            error: {
                required: 'Bitte geben Sie den Namen des Anbieters an.',
            },
            label: {
                paragraphs: [
                    {
                        type: 'p',
                        text: '(3) Name des Anbieters',
                    },
                ],
                paragraphProps: {
                    variant: 'h6',
                    component: Typography,
                },
            },
        },
        financeData: {
            headline: 'Finanzierungsplan',
            paragraphs: [
                {
                    type: 'p',
                    text: '[u]So erstellen Sie Ihren Finanzierungsplan[u]:',
                },
                {
                    type: 'p',
                    text: 'Der Aufbau des Finanzierungsplans basiert auf [b]einzelnen Nachweisen[b] (bspw. Angeboten).',
                },
                {
                    type: 'p',
                    text: 'Es wird empfohlen, die einzureichenden Nachweise / Angebote durch die externen Anbieter so aufbereiten zu lassen, dass dabei:',
                },
                {
                    type: 'list',
                    text: '[ul][li]ausschließlich zuwendungsfähige Ausgaben berücksichtigt werden;[li][li]die Angebote nach Modulen 1 und 2 getrennt erstellt werden;[li][li]Lizenzkosten und Systemservicegebühren auf die geplante Laufzeit aufgeschlüsselt sind (die maximale Laufzeit umfasst 12 Monate);[li][ul]',
                },
            ],
        },
        financeData2: {
            paragraphs: [
                {
                    type: 'p',
                    text: '[u]Die Eingabe der Nachweise folgt für jeden Nachweis diesen Schritten:[u]',
                },
                {
                    type: 'p',
                    text: 'Über den Button „[b]Neuen Nachweis hinzufügen[b]“ wird ein Nachweis hinzugefügt:',
                },
                {
                    type: 'list',
                    text: '[ul][li]Geben Sie die Art des Nachweises (ein schriftliches Angebot / ein Kostenvoranschlag eines Anbieters, eine Kostenermittlung) an[li] [li]Geben Sie eine aussagekräftige Bezeichnung des Nachweises an[li] [li]Nennen Sie den Namen des Anbieters / Dienstleisters.[li] [li]Laden Sie den Nachweis (insgesamt sind mehrere Nachweise hochladbar) über den Button „Neuen Nachweis hinzufügen“ hoch. Es kann immer nur ein Nachweis einzeln hochgeladen werden. Wenn der Nachweis hochgeladen ist, erscheint der Dateiname im Antragstool. Bei Änderungen kann der Nachweis auch wieder gelöscht / erneut hochgeladen werden.[li] [li]Zuordnung der Positionen im Nachweis zu Modul 1, falls zutreffend[li] [li]Zuordnung der Positionen im Nachweis zu Modul 2, falls zutreffend[li][ul]',
                },
                {
                    type: 'p',
                    text: 'Über den Button „[b]Nachweis speichern[b]“ wird dieser Nachweis abgeschlossen.',
                },
                {
                    type: 'p',
                    text: 'Ein neuer Nachweis kann nun nach dem gleichen Vorgehen erfasst werden.',
                },
            ],
        },
        deviceList: {
            headline: '',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Es ist notwendig, dass die Positionen im Nachweis in die Eingabemaske Finanzdaten übertragen werden.',
                },
                {
                    type: 'p',
                    text: 'Positionen in den Nachweisen können unter folgenden Bedingungen zusammengefasst werden: Falls ein „Investitions-Gegenstand“ (Modul 1) im Nachweis in mehrere Einzelpositionen aufgegliedert ist und diese zusammenhängend genutzt werden, können Sie die Teilsummen addieren und als einen in sich zusammenhängenden „Investitions-Gegenstand“ / als eine Qualifizierungsmaßnahme benennen und eingeben.',
                },
            ],
        },
        notSame: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Falls Sie von der Gesamtsumme des Nachweises abweichen wollen, bitten wir im Folgenden um eine Erläuterung. Bitte nennen Sie dabei die Positionen im Nachweis, bei denen Sie abweichen.',
                },
            ],
        },
        certificate: {
            paragraphs: [
                {
                    type: 'p',
                    text:
                        'Der Weiterbildungsanbieter für die Investitionen in Modul 2 muss durch seine räumliche, \n' +
                        'technische und personelle Ausstattung eine erfolgreiche Weiterbildung erwarten lassen\n' +
                        ' und es muss eine mehrjährige Stetigkeit im Angebot vorhanden sein.[br] \n' +
                        '[br]' +
                        'Weiterhin muss das Qualitätsniveau der Weiterbildungsanbieter der Qualifizierungsmaßnahmen durch eine [tooltip|0]Zertifizierung[tooltip] oder durch gesetzliche Anerkennung oder Nachweise für die Qualitätssicherung des Angebotes nachweisbar sein.[br]\n' +
                        '[br]' +
                        'Die offiziellen Anerkennungs- und Zertifizierungsverfahren finden sich unter: \n',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'Zuwendungsfähig sind lediglich Nachweise zertifizierter Weiterbildungsanbieter. Das Qualitätsniveau der Anbieter von Qualifizierungsmaßnahmen muss durch eine Zertifizierung nach der ISO 9001 Norm oder eine Akkreditierung nach AZAV (Akkreditierungs- und Zulassungsverordnung Arbeitsförderung) belegt sein.',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'p',
                    text: '[a]https://www.bmwk.de/Redaktion/DE/FAQ/Digital-Jetzt/faq-digital-jetzt[a]',
                },
                {
                    type: 'p',
                    text: 'Alternativ ist die Zertifizierung durch Eigenrecherche des antragstellenden Unternehmens in einem Screenshot oder mit einer Selbsterklärung des Anbieters zu dokumentieren und hochzuladen.',
                },
                {
                    type: 'p',
                    text: 'Es ist notwendig, dass die einzelnen Qualifizierungsmaßnahmen im Nachweis in die Eingabemaske Finanzdaten übertragen werden. Falls eine Qualifizierungsmaßnahme im Nachweis in mehrere Einzelpositionen aufgegliedert ist und diese [tooltip|0]zusammenhängen[tooltip], können Sie die Teilsummen addieren und als eine in sich zusammenhängende Qualifizierungsmaßnahme benennen und eingeben. Sollten Sie die Eingabe / Übertragung der Positionen in laufende Nummern nicht vornehmen, wird diese nachgefordert. Wir weisen darauf hin, dass dies die Bearbeitungszeit Ihres Antrags verlängert.',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'Ein Beispiel für die Aufgliederung eines Investitions-Gegenstands in Teilpositionen ist bei Qualifizierungsangeboten die Trennung zwischen Honorar der Dozenten, Unterrichtsmaterialien und Tagungspauschalen. Diese Positionen können zu einer Gesamtsumme addiert werden.',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        sumTax: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Ihr Unternehmen ist vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Netto-Werte an.',
                },
            ],
        },
        sumNoTax: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Ihr Unternehmen ist [u]NICHT[u] vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Brutto-Werte an.',
                },
            ],
        },
        saveInfo: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Bitte bestätigen Sie alle Änderungen mit "Speichern". Bedenken Sie bitte, dass alle ungespeicherten Informationen verloren gehen, wenn Sie auf Abbrechen klicken.',
                },
            ],
        },
    },
    display: {
        helperText:
            'Klicken Sie auf die Nummer eines Nachweises, um diesen zu bearbeiten.',
        columns: [
            {
                label: '',
                key: uniqid(),
                style: {
                    width: '1%',
                    whiteSpace: 'nowrap',
                },
            },
            {
                label: 'Nummer',
                key: uniqid(),
                style: {
                    width: '1%',
                    whiteSpace: 'nowrap',
                },
            },
            {
                label: 'Beschreibung',
                key: uniqid(),
            },
        ],
        columnsNet: {
            label: 'Summe (netto)',
            key: uniqid(),
            style: {
                width: '150px',
                textAlign: 'right',
            },
        },
        columnsBru: {
            label: 'Summe (brutto)',
            key: uniqid(),
            style: {
                width: '150px',
                textAlign: 'right',
            },
        },
    },
    proof: {
        title: 'Nachweis',
        proofType: {
            title: '1. Art des Nachweises',
            error: {
                required:
                    'Bitte geben Sie an, um welche Art es sich bei dem Nachweis handelt.',
            },
            fields: [
                {
                    label: 'Angebot / Einfacher Kostenvoranschlag',
                    key: uniqid(),
                    value: '0',
                },
                {
                    label: 'Kostenermittlung auf Basis eigener Recherchen',
                    key: uniqid(),
                    value: '1',
                },
            ],
            labels: [
                {
                    text: 'Angebot / Einfacher Kostenvoranschlag',
                },
                {
                    text: 'Kostenermittlung auf Basis eigener Recherchen',
                    tooltips: [
                        {
                            text: 'Eine Kostenermittlung für Anschaffungsgegenstände / Investitionen ist über eine eigene Recherche möglich bis zu einem Wert von maximal 5.000,00  Euro.',
                        },
                        {
                            text: 'Die Ergebnisse sind über einen Auszug bspw. einer Internetseite und die Bezeichnung der Quelle zu dokumentieren und hochzuladen.',
                        },
                    ],
                },
            ],
        },
        proofDescription: {
            title: '2. Bezeichnung des Nachweises',
            tooltip:
                'Geben Sie dem Angebot bitte eine aussagefähige Bezeichung auch in Bezug auf Ihr Digitalisierungsprojekt.',
            error: {
                required:
                    'Bitte geben Sie eine Bezeichnung für den Nachweis an.',
            },
        },
        proofProvider: {
            title: '3. Name des Anbieters',
            error: {
                required: 'Bitte geben Sie den Namen des Anbieters an.',
            },
        },
        fileUpload: {
            title: '3. Hochladen des Nachweises',
            error: {
                required: 'Bitte laden Sie einen Nachweis hoch.',
            },
        },
        moduleOne: {
            title: '(5) Investition in Hard- und Software inklusive Implementierungsleistungen (Modul 1)',
        },
        deviceList: {
            isDeductInputTaxText:
                'Ihr Unternehmen ist vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Netto-Werte an.',
            isNotDecutInputTaxText:
                'Ihr Unternehmen ist NICHT vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Brutto-Werte an.',
            columns: [
                {
                    label: '',
                    key: uniqid(),
                    style: {
                        width: '1%',
                        whiteSpace: 'nowrap',
                    },
                },
                {
                    label: 'Laufende Nummer',
                    key: uniqid(),
                    style: {
                        width: '1%',
                        whiteSpace: 'nowrap',
                    },
                },
                {
                    label: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Bezeichnung des [tooltip|0]Investitionsgegenstands[tooltip]',
                                tooltips: [
                                    {
                                        interactive: true,
                                        paragraphs: [
                                            {
                                                type: 'p',
                                                text: 'Falls ein „Investitions-Gegenstand“ im Nachweis in mehrere Einzelpositionen aufgegliedert ist und diese zusammenhängend genutzt werden, können Sie die Teilsummen addieren und als einen in sich zusammenhängenden Investitionsgegenstand benennen und eingeben.',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    key: uniqid(),
                    style: {
                        maxWidth: 600,
                    },
                    placeholder: 'Bezeichnung des Investitionsgegenstands',
                },
                {
                    label: 'Anzahl',
                    key: uniqid(),
                    style: {
                        width: 150,
                    },
                },
            ],
            columnsOther: {
                posProof:
                    '(1) Bei zusammengefassten Positionen geben Sie die Nummern aus dem Nachweis bitte hier ein. Der beantragte Betrag muss im Nachweis nachvollziehbar sein.',
                description:
                    '(2) Erläuterung der Notwendigkeit für das geplante Digitalisierungsvorhaben',
                combination:
                    'Es handelt sich um die Zusammenfassung zusammenhängend zu nutzender Gegenstände.',
            },
            columnsNet: [
                {
                    label: 'Stückpreis (netto)',
                    key: uniqid(),
                    style: {
                        width: 150,
                    },
                },
                {
                    label: 'Summe (netto)',
                    key: uniqid(),
                    style: {
                        width: 150,
                        textAlign: 'right',
                    },
                },
            ],
            columnsBru: [
                {
                    label: 'Stückpreis (brutto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                    },
                },
                {
                    label: 'Summe (brutto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                        textAlign: 'right',
                    },
                },
            ],
        },
        confirm: {
            label: 'Die geplanten Investitionen sind NICHT der Grundausstattung zuzurechnen.',
            error: {
                required:
                    'Bitte bestätigen Sie, dass die geplanten Investition nicht zur Grundausstattung gehören',
            },
        },
        contains: {
            fields: [
                {
                    label: 'Ja',
                    key: uniqid(),
                    value: 'true',
                },
                {
                    label: 'Nein',
                    key: uniqid(),
                    value: 'false',
                },
            ],
            label: 'Enthält der vorliegende hier bearbeitete Nachweis noch Ausgaben bezogen auf Modul 2 Qualifizierungsmaßnahmen?',
            required:
                'Bitte wählen Sie aus, ob der vorliegende Nachweis noch Ausgaben bezogen auf Modul 2 enthält.',
        },
        moduleTwo: {
            title: '(6) Investition in Qualifizierungsmaßnahmen (Modul 2)',
            isDeductInputTaxText:
                'Ihr Unternehmen ist vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Netto-Werte an.',
            isNotDecutInputTaxText:
                'Ihr Unternehmen ist NICHT vorsteuerabzugsberechtigt. Entsprechend geben Sie im Weiteren Brutto-Werte an.',
        },
        activityList: {
            columns: [
                {
                    label: '',
                    key: uniqid(),
                    style: {
                        width: '1%',
                        whiteSpace: 'nowrap',
                    },
                },
                {
                    label: 'Maßnahme',
                    key: uniqid(),
                    style: {
                        width: '1%',
                        whiteSpace: 'nowrap',
                    },
                },
                {
                    label: 'Bezeichnung Qualifizierung',
                    key: uniqid(),
                    style: {
                        maxWidth: 600,
                    },
                },
                {
                    label: 'Anzahl',
                    key: uniqid(),
                    style: {
                        width: 150,
                    },
                },
            ],
            columnsNet: [
                {
                    label: 'Stückpreis (netto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                    },
                },
                {
                    label: 'Summe (netto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                        textAlign: 'right',
                    },
                },
            ],
            columnsBru: [
                {
                    label: 'Stückpreis (brutto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                    },
                },
                {
                    label: 'Summe (brutto)',
                    key: uniqid(),
                    style: {
                        width: 200,
                        textAlign: 'right',
                    },
                },
            ],
            columnsOther: {
                posProof:
                    '(1) Bei zusammengefassten Positionen geben Sie die Nummern aus dem Nachweis bitte hier ein - somit ist ein Abgleich möglich.',
                explanation:
                    '(2) Erläuterung des Zusammenhangs mit den Digitalisierungszielen',
                combination:
                    'Es handelt sich um die Zusammenfassung mehrerer Positionen, die zu einer Qualifizierungsmaßnahme gehören.',
                duration: 'Dauer / Unterrichtseinheiten in Std.',
                participants: 'Anzahl Teilnehmende',
            },
        },
        sum: {
            tax: 'Ihr Unternehmen ist vorsteuerabzugsberechtigt. Entsprechend geben sie im Weiteren Netto-Werte ein.',
            notTax: 'Ihr Unternehmen ist NICHT vorsteuerabzugsberechtigt. Entsprechend geben sie im Weiteren Brutto-Werte ein.',
            sum: 'Gesamtsumme des Nachweises',
        },
        fileCount: {
            device: 'Bitte laden Sie einen Nachweis hoch',
            activity: 'Bitte laden Sie ein Zertifikat hoch',
            certificate:
                'Bitte beachten Sie: \n' +
                '"Das Qualitätsniveau der Weiterbildungsanbieter muss durch eine Zertifizierung oder durch gesetzliche Anerkennung oder Nachweise für die Qualitätssicherung des Angebotes nachgewiesen werden. Bitte schauen Sie dazu in die entsprechende FAQ und laden Sie hier die Nachweise hoch.',
        },
    },
    static: {
        editTitle: 'Nachweis #',
        uploadOfProof: '(4) Hochladen des Nachweises',
        uploadOfProof2:
            'Hier bitte den Zertifizierungsnachweis des Qualifizierungsanbieters hochladen',
    },
};
