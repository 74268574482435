import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const MinimisCheckbox = (props) => {
    const { defaultChecked, label, register, validation, name, error } = props;

    return (
        <FormControl
            error={error !== undefined && error !== null}
            style={{ marginBottom: '16px' }}
        >
            <FormControlLabel
                name={name}
                label={label}
                control={
                    <Checkbox
                        inputRef={register(validation)}
                        color="primary"
                        defaultChecked={defaultChecked}
                    />
                }
            />
            {error?.message && (
                <FormHelperText>{error?.message}</FormHelperText>
            )}
        </FormControl>
    );
};

MinimisCheckbox.propTypes = {
    register: PropTypes.func.isRequired,
    validation: PropTypes.object,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    error: PropTypes.object,
};

MinimisCheckbox.defaultProps = {
    defaultChecked: false,
};

export default MinimisCheckbox;
