import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useOutlineStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

const Outline = (props) => {
    const { title, subtitle, children, gutterTop } = props;

    const classes = useOutlineStyles();

    return (
        <Card variant="outlined" style={{ marginTop: gutterTop ? 16 : 0 }}>
            <CardContent className={classes.content}>
                {title && (
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom={!subtitle}
                        component="p"
                    >
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography variant="h6" component="h2" gutterBottom>
                        {subtitle}
                    </Typography>
                )}
                {children}
            </CardContent>
        </Card>
    );
};

Outline.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node,
    gutterTop: PropTypes.bool,
    asH1: PropTypes.bool,
};

export default Outline;
