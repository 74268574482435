import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useLoginButtonStyles = makeStyles(() => ({
    root: {
        color: 'white',
        padding: '0.5rem 1rem',
        marginRight: '1rem',
        '&:last-child': {
            marginRight: '0',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
    },
}));

const LoginButton = (props) => {
    const classes = useLoginButtonStyles();
    const { isLoading, onClick } = props;
    return (
        <>
            <Button
                className={classes.root}
                onClick={onClick}
                disabled={isLoading}
            >
                Anmelden
            </Button>
        </>
    );
};

LoginButton.propTypes = {
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default LoginButton;
