import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const ReloadAction = (reloadFunc, closeFunc) =>
    function (key) {
        return (
            <>
                <Button
                    onClick={() => {
                        reloadFunc();
                        closeFunc(key);
                    }}
                    size="small"
                >
                    Neuladen
                </Button>
                <IconButton
                    onClick={() => {
                        closeFunc(key);
                    }}
                    aria-label="Schließen"
                    size="small"
                >
                    <ClearIcon />
                </IconButton>
            </>
        );
    };

ReloadAction.propTypes = {
    reloadCallback: PropTypes.func.isRequired,
    key: PropTypes.string.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
};

export default ReloadAction;
