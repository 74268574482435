import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { columns } from './Columns';
import { verificationLocale } from './VerificationLocale';
import FullscreenCircularLoader from '../Partials/Loader/FullscreenCircularLoader';
import { LinearProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import ButtonWithLoader from '../Partials/Button/ButtonWithLoader';
import VerificationError from './VerificationError';
import { Alert } from '@material-ui/lab';
import TextBlock from '../TextBlock/TextBlock';
import { overviewTexts } from '../../texts/Verification/OverviewTexts';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    list: {
        '& ul': {
            padding: 0,
        },
    },
}));

const VerificationTable = (props) => {
    const { data, isLoading, isFetching, refetch, error } = props;
    const cols = React.useMemo(() => columns(data), [data]);

    const [activeSearch, setActiveSearch] = React.useState(false);

    const classes = useStyles();

    if (isLoading) return <FullscreenCircularLoader />;

    if (error)
        return (
            <VerificationError
                isLoading={isFetching}
                refetch={refetch}
                error={error}
            />
        );

    const options = {
        ...verificationLocale(activeSearch),
        download: false,
        print: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        filter: false,
        onSearchChange: (searchString) => setActiveSearch(!!searchString),
    };

    return (
        <div>
            <Alert severity="info" className={classes.list}>
                <TextBlock
                    textBlock={overviewTexts.body}
                    parserOptions={{
                        components: {
                            ul: {
                                props: {
                                    component: 'ol',
                                },
                            },
                        },
                    }}
                />
            </Alert>
            <div style={{ textAlign: 'right' }}>
                <ButtonWithLoader
                    func={refetch}
                    icon={<RefreshIcon />}
                    isLoading={isFetching}
                />
            </div>
            {isFetching && <LinearProgress />}
            <MUIDataTable
                title={'Verwendungsnachweise'}
                data={data || []}
                columns={cols}
                options={options}
            />
        </div>
    );
};

VerificationTable.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    refetch: PropTypes.func,
    error: PropTypes.object,
};

export default VerificationTable;
