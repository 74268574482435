import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProposalEditContainer from '../../container/Proposal/ProposalEditContainer';
import ProposalOverview from '../../components/Proposal/Overview/ProposalOverview';
import RaffleStateContainer from '../../container/Raffle/RaffleStateContainer';

const ProposalRoutes = () => {
    return (
        <Switch>
            <Route path="/antrag" exact>
                <RaffleStateContainer displayForProposal>
                    <ProposalOverview />
                </RaffleStateContainer>
            </Route>
            <Route path="/antrag/:proposalId">
                <RaffleStateContainer displayForProposal>
                    <ProposalEditContainer />
                </RaffleStateContainer>
            </Route>
        </Switch>
    );
};

export default ProposalRoutes;
