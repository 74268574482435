import React from 'react';
import PropTypes from 'prop-types';
import { texts as dzt } from '../../texts/Components/DropzoneTexts';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { deleteFile, uploadFile } from '../../lib/api/Files/FileActions';
import Dropzone from '../../components/Partials/Dropzone/Dropzone';
import DropzoneTableRow from '../../components/Partials/Dropzone/DropzoneTableRow';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import { getMimeType } from '../../util/Helper/File';

const useStyles = makeStyles((theme) => ({
    table: {
        position: 'relative',
    },
    overlay: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.8)',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const DropzoneContainer = (props) => {
    const {
        fileUploadFunc,
        fileDeleteFunc,
        acceptedFileTypes,
        maxFileCount,
        maxFileSize,
        prefetchFiles,
        keywords,
        linkedResource,
        onFilesChanged,
        disabled,
        listViewMode,
    } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const mountedRef = React.useRef(true);

    const [files, setFiles] = useImmer([]);
    const [progress, setProgress] = useImmer({});
    const [isPrefetching, setPrefetching] = React.useState(
        prefetchFiles !== null
    );

    const updateProgress = (refId) => {
        return function (progress) {
            if (!mountedRef.current) return;
            setProgress((draft) => {
                draft[refId] = progress < 100 ? progress : 100;
            });
        };
    };

    const handleNewFile = async (file, blob) => {
        if (files.some((f) => f.fileName === file.fileName)) {
            enqueueSnackbar('Dateiname bereits vorhanden', {
                variant: 'warning',
            });
            return;
        }

        if (file.fileSize === 0) {
            enqueueSnackbar(`Die Datei "${file.fileName}" hat keinen Inhalt.`, {
                variant: 'error',
            });
            return;
        }

        let mimeType = null;

        try {
            mimeType = await getMimeType(blob);
        } catch {}

        if (!mimeType || !acceptedFileTypes.includes(mimeType?.extension)) {
            enqueueSnackbar(
                `Die Datei "${file.fileName}" hat ein ungültiges Dateiformat.`,
                {
                    variant: 'error',
                }
            );
            return;
        }

        setFiles((draft) => {
            draft.push({
                fileName: file.fileName,
                fileSize: file.fileSize,
                fileType: file.mimeType,
                refId: file.refId,
                uploadedAt: file.uploadedAt,
            });
        });

        setProgress((draft) => {
            draft[file.refId] = 0;
        });

        fileUploadFunc(blob, updateProgress(file.refId), {
            linkedResource: linkedResource,
            keywords: keywords,
        })
            .then((response) => {
                if (!mountedRef.current) return;
                setFiles((draft) => {
                    const idx = draft.findIndex(
                        (item) => item.refId === file.refId
                    );
                    draft[idx] = { ...draft[idx], ...response };
                });
                setProgress((draft) => {
                    delete draft[file.refId];
                });

                enqueueSnackbar('Datei erfolgreich hochgeladen', {
                    variant: 'success',
                });
            })
            .catch((err) => {
                if (!mountedRef.current) return;
                setFiles((draft) => {
                    const idx = draft.findIndex(
                        (item) => item.refId === file.refId
                    );
                    draft.splice(idx, 1);
                });
                enqueueSnackbar(err.message(), { variant: 'error' });
            });
    };

    const deleteFunc = (refId, index) => () => {
        setFiles((draft) => {
            const index = files.findIndex((d) => d.refId === refId);
            draft[index].isDeleting = true;
        });
        fileDeleteFunc(files[index]['@id'])
            .then(() => {
                if (!mountedRef.current) return;
                setFiles((draft) => {
                    const index = draft.findIndex((d) => d.refId === refId);
                    draft.splice(index, 1);
                });
                enqueueSnackbar('Datei erfolgreich gelöscht', {
                    variant: 'success',
                });
            })
            .catch((err) => {
                if (!mountedRef.current) return;
                setFiles((draft) => {
                    const index = files.findIndex((d) => d.refId === refId);
                    draft[index].isDeleting = false;
                });
                enqueueSnackbar(err.message(), { variant: 'error' });
            });
    };

    const handleRejected = React.useCallback(
        (files) => {
            files.forEach((file) => {
                let toBig = false;
                let wrongType = false;
                let message = '';

                file.errors.forEach((error) => {
                    switch (error.code) {
                        case 'file-too-large':
                            toBig = true;
                            break;
                        case 'file-invalid-type':
                            wrongType = true;
                            break;
                        default:
                            message = `${file.file.name} kann nicht hochgeladen werden`;
                    }
                });

                if (toBig) {
                    message = `${file.file.name} ist zu groß`;
                }

                if (wrongType) {
                    message = `Der Dateityp von ${file.file.name} wird nicht unterstützt`;
                }

                if (toBig && wrongType) {
                    message = `${file.file.name} ist zu groß und der Dateityp wird nicht unterstützt`;
                }

                enqueueSnackbar(message, { variant: 'info' });
            });
        },
        [enqueueSnackbar]
    );

    const handleReload = React.useCallback(() => {
        setFiles((draft) => []);
        setPrefetching(true);
        prefetchFiles()
            .then((data) => {
                if (!mountedRef.current) return;
                setFiles((draft) => {
                    draft.push(
                        ...data.map((el) => ({
                            ...el,
                            refId: _.uniqueId('file_'),
                        }))
                    );
                });
            })
            .catch((err) => {
                if (!mountedRef.current) return;
                enqueueSnackbar(err.message(), { variant: 'error' });
            })
            .finally(() => {
                if (!mountedRef.current) return;
                setPrefetching(false);
            });
    }, [prefetchFiles, setFiles, enqueueSnackbar, setPrefetching]);

    React.useEffect(
        () => () => {
            mountedRef.current = false;
        },
        [mountedRef]
    );

    React.useEffect(() => {
        handleReload();
    }, []); //eslint-disable-line

    React.useEffect(() => {
        if (onFilesChanged) onFilesChanged(files);
    }, [files]); //eslint-disable-line

    return (
        <>
            <Paper square elevation={0} style={{ width: '100%' }}>
                {!disabled && (
                    <div style={{ padding: '2rem' }}>
                        <Dropzone
                            onFileLoadComplete={handleNewFile}
                            onReject={handleRejected}
                            maxFileCount={maxFileCount}
                            currentFileCount={files.length}
                            maxFileSize={maxFileSize}
                            acceptedFileTypes={acceptedFileTypes}
                        />
                    </div>
                )}
                <TableContainer className={classes.table}>
                    <Table
                        size="small"
                        aria-label={dzt.container.table.ariaLabel}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 1, padding: 6 }}>
                                    {!listViewMode && (
                                        <IconButton
                                            size="small"
                                            onClick={handleReload}
                                            aria-label="Dateiliste neu laden"
                                            role="button"
                                        >
                                            <CachedIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {dzt.container.table.cell[0]}
                                </TableCell>
                                <TableCell align="right">
                                    {dzt.container.table.cell[2]}
                                </TableCell>
                                <TableCell align="right">
                                    {dzt.container.table.cell[3]}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        {dzt.container.table.emptyLabel}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                files.map((file, index) => (
                                    <DropzoneTableRow
                                        key={file.refId}
                                        file={file}
                                        disabled={disabled}
                                        progress={
                                            progress[file.refId] || undefined
                                        }
                                        deleteFile={deleteFunc(
                                            file.refId,
                                            index
                                        )}
                                        listViewMode={listViewMode}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                    {isPrefetching && (
                        <div className={classes.overlay}>
                            <CircularProgress color={'primary'} size="1rem" />
                        </div>
                    )}
                </TableContainer>
            </Paper>
        </>
    );
};

DropzoneContainer.propTypes = {
    maxFileSize: PropTypes.number,
    acceptedFileTypes: PropTypes.array,
    maxFileCount: PropTypes.number,
    prefetchFiles: PropTypes.func.isRequired,
    linkedResource: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    keywords: PropTypes.arrayOf(PropTypes.string),
    onFilesChanged: PropTypes.func,
    fileUploadFunc: PropTypes.func,
    fileDeleteFunc: PropTypes.func,
    disabled: PropTypes.bool,
    listViewMode: PropTypes.bool,
};

DropzoneContainer.defaultProps = {
    maxFileSize: 5,
    acceptedFileTypes: ['.pdf'],
    fileUploadFunc: uploadFile,
    fileDeleteFunc: deleteFile,
    maxFileCount: 0,
    disabled: false,
    listViewMode: false,
};

export default DropzoneContainer;
