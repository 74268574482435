export const texts = {
    title: 'Digitalisierungsplan - Nachhaltige Wirkung der Investition',
    subhead:
        'Nachhaltige Wirkung der Investition (erwartete Effekte hinsichtlich der mittel- und langfristigen Unternehmensentwicklung)',
    desc:
        'Stellen Sie im Folgenden bitte dar, wie sich die geplante Investition aus heutiger Sicht voraussichtlich mittel- und langfristig auf Ihr Unternehmen (u.a. Wettbewerbs- und Innovationsfähigkeit und Marktposition) auswirken wird. \n' +
        'Wir benötigen diese Informationen, um abschätzen zu können, ob durch die Investition nachhaltig wirksame Effekte auf die Entwicklung des Unternehmens zu erwarten sind.\n' +
        'Bitte beachten Sie: Diese Angaben sollen lediglich Anhaltspunkte geben, wie die geplanten Investitionen in die mittel- bzw. langfristige Unternehmensstrategie eingebettet werden.',
    abilityEffects: {
        title: '1. Auswirkungen auf Wettbewerbs- und Innovationsfähigkeit des Unternehmens',
        desc: '',
        competitivenes: {
            label: 'Wie verbessert sich durch die geplante Investition voraussichtlich die Wettbewerbsfähigkeit bzw. die Wettbewerbssituation des Unternehmens (z.B. Position im Wettbewerb, Erschließung neuer Märkte oder Kundensegmente)?',
            maxLength: 1000,
            error: 'Bitte füllen Sie dieses Feld aus.',
        },
        innovativeCap: {
            label: 'Wie verbessert sich durch die geplante Investition voraussichtlich die Innovationsfähigkeit des Unternehmens?',
            maxLength: 1000,
            error: 'Bitte füllen Sie dieses Feld aus.',
        },
        otherExpEffects: {
            label: 'Sonstige voraussichtlich zu erwartende Effekte',
            maxLength: 1000,
            error: 'Bitte füllen Sie dieses Feld aus.',
        },
    },
    expectedResults: {
        title: 'Weitere erwartete wirtschaftliche Effekte',
        desc: 'Bitte schätzen Sie die voraussichtlichen wirtschaftlichen Effekte, die Sie als Resultat der geplanten Investition erwarten, anhand einer Skala ein.',
        info: 'Bitte beachten Sie: Die Angaben sind freiwillig und dienen lediglich einer unverbindlichen Abschätzung der erwarteten und nach Abschluss der Maßnahme erzielten Effekte. Sie werden bei der Erstellung Ihres Verwendungsnachweises nicht aufgefordert, die erzielten Effekte zu belegen. Die Förderfähigkeit ihrer Ausgaben ist unabhängig von den erzielten wirtschaftlichen Effekten. ',
    },
    question: {
        required: 'Bitte beantworten Sie diese Frage.',
    },
    questions: [
        {
            name: '0',
            text: 'Erwartete Umsatzsteigerungen in den nächsten zwei Jahren (nach Umsetzung der Investition)',
            answers: [
                {
                    label: 'Keine Zunahme',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        {
            name: '1',
            text: 'Erwartetes Beschäftigungswachstum in den nächsten zwei Jahren (nach Umsetzung der Investition)',
            answers: [
                {
                    label: 'Keine Zunahme',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        {
            name: '2',
            text: 'Erwartete Kosteneinsparungen in den nächsten zwei Jahren (nach Umsetzung der Investition)',
            answers: [
                {
                    label: 'Keine Einsparungen',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        {
            name: '3',
            text: 'Erwartete Produktivitätssteigerungen in den nächsten zwei Jahren (nach Umsetzung der Investition)',
            answers: [
                {
                    label: 'Keine Steigerung',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
    ],
};
