export function createDownloadLinkForFileResponse(response, fileName) {
    if (!response || !fileName) return;

    const type = response.headers['content-type'];
    const blob = new Blob([response.data], {
        type: type,
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    link.remove();
}
