import React from 'react';
import { useAppState } from '../../../Stores/AppState.store';
import RaffleCard from './RaffleCard';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const RaffleUpcomingWrapper = (props) => {
    const pots = useAppState((state) => state.rafflePots);

    const rafflePots = React.useMemo(() => [...pots].reverse(), [pots]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    component="h2"
                    style={{ marginTop: '16px' }}
                >
                    Überblick der nächsten Zeiträume
                </Typography>
            </Grid>

            {rafflePots.length === 0 ? (
                <Grid item xs={12}>
                    Keine weiteren Ziehungen geplant
                </Grid>
            ) : (
                rafflePots.slice(0, 3).map((pot, id) => (
                    <Grid item xs={12} sm={6} md={4} key={`pot_${id}`}>
                        <RaffleCard pot={pot} />
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default RaffleUpcomingWrapper;
