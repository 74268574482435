import _ from 'lodash';

export function filterProposals(data) {
    if (!data) {
        return {
            inProgress: [],
            inAudit: [],
            finished: [],
        };
    }

    return data.reduce(
        (prev, cur) => {
            if (!cur.submittedAt) {
                prev.inProgress.push(cur);
                return prev;
            }
            if (
                _.intersection(
                    ['final_denied', 'final_approved'],
                    Object.keys(cur.revisionStatus || {})
                ).length > 0
            ) {
                prev.finished.push(cur);
                return prev;
            }
            prev.inAudit.push(cur);
            return prev;
        },
        {
            inProgress: [],
            inAudit: [],
            finished: [],
        }
    );
}
