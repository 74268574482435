import React from 'react';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import { fetchUploadedFilesFor } from '../../../../../lib/api/Files/FileActions';
import uniqid from 'uniqid';
import { downloadFile } from '../../../../../lib/api/Files/FileActions';
import DownloadIcon from '@material-ui/icons/GetApp';
import { createDownloadLinkForFileResponse } from '../../../../../util/Helper/CreateDownloadLinkForFileResponse';

const FileTreeItem = (props) => {
    const { proofId } = props;

    const [files, setFiles] = React.useState([]);
    const [isLoading, setIsloading] = React.useState(false);

    const handleClick = () => {
        if (isLoading) return;
        if (files.length > 0) return;
        setIsloading(true);
        fetchUploadedFilesFor(proofId, ['financeplan'])()
            .then((files) => {
                if (files.length > 0)
                    setFiles(
                        files.map((file) => {
                            file.id = file['@id'];
                            return file;
                        })
                    );
                else
                    setFiles([
                        {
                            id: uniqid(),
                            fileName: 'Keine Datei(en) hochgeladen',
                        },
                    ]);
            })
            .catch(() => {})
            .finally(() => setIsloading(false));
    };

    const getLabel = () => {
        if (isLoading) return 'Dateien - Lädt...';
        if (files.length === 0) return 'Dateien anzeigen (klicken um zu laden)';
        return 'Dateien';
    };

    const handleDownload = async (file) => {
        try {
            const response = await downloadFile(
                file.downloadUrl,
                file.fileName,
                file.fileSize,
                () => {}
            );
            createDownloadLinkForFileResponse(response, file.fileName);
        } catch (e) {}
    };

    return (
        <TreeItem
            nodeId={`${proofId}_files`}
            label={getLabel()}
            onLabelClick={handleClick}
        >
            {files.map((file) => (
                <TreeItem
                    key={file.id}
                    nodeId={file.id}
                    label={file.fileName}
                    endIcon={<DownloadIcon />}
                    onClick={() => {
                        handleDownload(file);
                    }}
                />
            ))}
        </TreeItem>
    );
};

FileTreeItem.propTypes = {
    proofId: PropTypes.string,
};

export default FileTreeItem;
