import React from 'react';
import { Route, Switch } from 'react-router-dom';
import VerificationContainer from '../../container/Verification/VerificationContainer';
import VerificationEditContainer from '../../container/Verification/VerificationEditContainer';

const VerificationRoutes = (props) => {
    return (
        <Switch>
            <Route path="/vn" exact component={VerificationContainer} />
            <Route path="/vn/:id/bearbeiten">
                <VerificationEditContainer />
            </Route>
            <Route path="/vn/:id/anzeigen">
                <VerificationEditContainer displayOnly />
            </Route>
        </Switch>
    );
};

export default VerificationRoutes;
