import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { step5Texts } from '../../../texts/Verification/Step5Texts';
import { step6Texts } from '../../../texts/Verification/Step6Texts';
import { CircularProgress } from '@material-ui/core';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import FormFooter from './FormFooter';
import { client } from '../../../QueryClient';
import VNOverviewContainer from './VNOverviewContainer';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { verificationConfig } from '../../Verification/Edit/VerificationConfig';
import { verificationTransition } from '../../../lib/api/Verification/VerificationActions';
import Outline from '../../Partials/Info/Outline';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextBlock from '../../TextBlock/TextBlock';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

const Step6 = (props) => {
    const {
        data,
        formData,
        isLoading,
        displayOnly,
        id,
        onDirtyChange,
        handleStepChange,
    } = props;

    const { errors, handleSubmit, reset, formState, register, watch } = useForm(
        {
            defaultValues: data,
        }
    );

    const { isDirty } = formState;

    React.useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange]);

    const { enqueueSnackbar } = useSnackbar();

    const handleDataReset = (d) => {
        reset();
    };

    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
    };
    const handleSuccess = (data) => {
        const d = data.data();
        handleDataReset(d);
        enqueueSnackbar(data.message(), { variant: 'success' });
        handleStepChange(6);
    };

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onError: handleError,
        onSuccess: handleSuccess,
    });

    const submitCallback = (values) => {
        mutate({
            data: {
                ...formData,
                ...values,
            },
            transition: 'review',
            id,
            success: 'Erfolgreich gespeichert.',
        });
    };

    const handleBackButton = () => {
        return verificationTransition(id, 'back_to_affirmable').then(() =>
            handleStepChange(4)
        );
    };

    const stepData = client.getQueryData('verification', id);

    React.useEffect(() => {
        if (isLoading) return;
        reset();
    }, [isLoading, formData, reset]);

    const isFinalized = watch('isFinalized');

    if (isLoading) return <FullscreenCircularLoader />;

    const error = _.get(errors, 'isFinalized');

    return (
        <>
            <Outline subtitle={step6Texts.title}>
                <Grid container>
                    <Grid item xs={12}>
                        <p>{step6Texts.subtitle}</p>
                        <VNOverviewContainer
                            stepData={stepData}
                            Ui={() => <Step1 {...props} displayOnly={true} />}
                            headline={verificationConfig[0].label}
                        />

                        <VNOverviewContainer
                            stepData={stepData}
                            Ui={() => <Step2 {...props} displayOnly={true} />}
                            headline={verificationConfig[1].label}
                        />

                        <VNOverviewContainer
                            stepData={stepData}
                            Ui={() => <Step3 {...props} displayOnly={true} />}
                            headline={verificationConfig[2].label}
                        />

                        <VNOverviewContainer
                            stepData={stepData}
                            Ui={() => <Step4 {...props} displayOnly={true} />}
                            headline={verificationConfig[3].label}
                        />

                        <VNOverviewContainer
                            stepData={stepData}
                            Ui={() => <Step5 {...props} displayOnly={true} />}
                            headline={verificationConfig[4].label}
                        />
                    </Grid>
                </Grid>
            </Outline>
            <form onSubmit={handleSubmit(submitCallback)}>
                <Alert
                    severity="warning"
                    icon={false}
                    style={{ marginTop: 12 }}
                >
                    <FormControlLabel
                        style={{ alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                style={{ marginTop: '-4px' }}
                                color="primary"
                                inputRef={register(
                                    step6Texts.isFinalized.rules
                                )}
                                defaultChecked={
                                    formData?.currentStep === 'submittable' ||
                                    displayOnly
                                }
                                disabled={displayOnly}
                            />
                        }
                        label={<TextBlock textBlock={step6Texts.isFinalized} />}
                        name="isFinalized"
                    />
                    {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                    )}
                </Alert>

                {!displayOnly && (
                    <FormFooter
                        displayText={Object.keys(errors).length > 0}
                        text={step5Texts.errorText}
                        gutterTop
                    >
                        <Button
                            style={{ marginRight: 16 }}
                            variant="outlined"
                            color="primary"
                            disabled={isSaving}
                            onClick={handleBackButton}
                            disableElevation
                        >
                            Zurück
                            {isSaving && (
                                <CircularProgress
                                    style={{ marginLeft: 8 }}
                                    size={'1rem'}
                                />
                            )}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaving || !isFinalized}
                            disableElevation
                        >
                            Übermitteln
                            {isSaving && (
                                <CircularProgress
                                    style={{ marginLeft: 8 }}
                                    size={'1rem'}
                                />
                            )}
                        </Button>
                    </FormFooter>
                )}
            </form>
        </>
    );
};

Step6.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

Step6.defaultProps = {
    data: {},
};

export default Step6;
