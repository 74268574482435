import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import ValueChainCompanies from './ValueChainCompanies';
import DisplayResponseTop from '../../Partials/Display/DisplayResponseTop';
import DisplayResponsePopup from '../../Partials/Display/DisplayResponsePopup';
import { TYPE } from '../../../util/DataTypes/Response';
import { texts as vcft } from '../../../texts/ValueChainFormTexts';
import RadioButtons from '../../Partials/Form/RadioButtons';
import { isDefined } from '../../../util/Validation/ValidateDefined';
import { useAppState } from '../../../Stores/AppState.store';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
    mtx2: {
        marginTop: theme.spacing(8),
    },
    mb1: {
        marginBottom: theme.spacing(1),
    },
    mb2: {
        marginBottom: theme.spacing(2),
    },
}));

const ValueChainForm = (props) => {
    const classes = useStyles();
    const { defaultValues, onSubmit, formId, response, saveResponse, saving } =
        props;

    const {
        register,
        errors,
        setError,
        handleSubmit,
        watch,
        control,
        reset,
        clearErrors,
    } = useForm();

    const { raffles, allRafflePots } = useAppState((state) => ({
        allRafflePots: state.allRafflePots,
        raffles: state.raffles,
    }));

    const [showIsValueChainInfoText, setShowIsValueChainInfoText] =
        React.useState(false);

    const isValueChainButtons = React.useMemo(() => {
        return JSON.parse(JSON.stringify(vcft.fields.isValueChain.buttons));
    }, []);

    React.useEffect(() => {
        const now = moment();
        const rafflePotInSubmitPhase = allRafflePots.find(
            (rP) =>
                now.isSameOrAfter(moment(rP.submitBegin)) &&
                now.isSameOrBefore(moment(rP.submitEnd))
        );
        const raffleIsValueChain = raffles.some(
            (r) =>
                r.rafflePot.id === rafflePotInSubmitPhase.id &&
                r.isValueChain === true
        );

        if (raffleIsValueChain) {
            defaultValues.isValueChain = 'true';
            isValueChainButtons[0].disabled = true;
            isValueChainButtons[0].label = `${isValueChainButtons[0].label} (nicht möglich)`;
            setShowIsValueChainInfoText(true);
        }
    }, [
        raffles,
        allRafflePots,
        isValueChainButtons,
        defaultValues.isValueChain,
    ]);

    React.useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                //propertyPath , message
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    const isValueChain = watch('isValueChain');
    const isLead = watch('isLead');

    return (
        <>
            <DisplayResponseTop response={response} onlyError scroll />
            <DisplayResponsePopup response={response} onlySuccess />

            <form
                onSubmit={(e) => {
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                }}
                id={formId}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    {vcft.title}
                </Typography>
                <Typography component="p" variant="h6" gutterBottom>
                    {vcft.subhead}
                </Typography>

                <RadioButtons
                    className={classes.mt}
                    groupLabel={vcft.fields.isValueChain.label}
                    name="isValueChain"
                    control={control}
                    buttons={isValueChainButtons}
                    disabled={saving}
                    ariaLabel={vcft.fields.isValueChain.label}
                    defaultValue={defaultValues.isValueChain}
                    rules={{ required: vcft.fields.isValueChain.error }}
                    errors={errors.isValueChain}
                />

                {showIsValueChainInfoText && (
                    <Alert severity="info" className={classes.mb1}>
                        Ihr Antragskontingent wurde im Zusammenhang mit einer
                        Wertschöpfungskette vergeben.
                    </Alert>
                )}

                <Alert severity="info" className={classes.mb2}>
                    {vcft.hints.wskHint}
                </Alert>

                {isValueChain === 'true' && (
                    <>
                        {
                            <RadioButtons
                                row
                                className={classes.mt}
                                groupLabel={vcft.fields.isLead.label}
                                name="isLead"
                                control={control}
                                buttons={vcft.fields.isLead.buttons}
                                disabled={saving}
                                ariaLabel={vcft.fields.isLead.label}
                                defaultValue={defaultValues.isLead}
                                rules={{ required: vcft.fields.isLead.error }}
                                errors={errors.isLead}
                            />
                        }

                        <Divider className={classes.mb2} />

                        {!isDefined(isLead) || isLead === 'false' ? (
                            <TextField
                                className={classes.mb2}
                                name="leadCode"
                                inputRef={register({
                                    required: vcft.fields.leadCode.error,
                                })}
                                label={vcft.fields.leadCode.label}
                                inputProps={{
                                    'aria-label':
                                        vcft.fields.leadCode.ariaLabel,
                                }}
                                fullWidth
                                error={!_.isEmpty(errors.leadCode)}
                                helperText={
                                    errors.leadCode
                                        ? errors.leadCode.message
                                        : vcft.fields.leadCode.helperText
                                }
                                aria-errormessage={
                                    errors.leadCode && errors.leadCode.message
                                }
                            />
                        ) : (
                            <>
                                <TextField
                                    fullWidth
                                    name="givenCode"
                                    inputRef={register()}
                                    label={vcft.fields.givenCode.label}
                                    disabled={true}
                                    className={classes.mb1}
                                />
                                <Alert severity="info" className={classes.mb2}>
                                    {vcft.hints.givenCodeHint}
                                </Alert>

                                <Divider className={classes.mb2} />

                                <Typography
                                    className={classes.mtx2}
                                    component="h3"
                                    variant="h4"
                                    gutterBottom
                                >
                                    {vcft.titleCompany}
                                </Typography>

                                <Alert severity="info" className={classes.mb2}>
                                    {vcft.hints.companyHint}
                                </Alert>

                                <ValueChainCompanies
                                    control={control}
                                    register={register}
                                    errors={errors}
                                />
                            </>
                        )}
                    </>
                )}

                {isValueChain && (
                    <Alert severity="warning" className={classes.mb2}>
                        {vcft.hints.isValueChainHint}
                    </Alert>
                )}
            </form>
        </>
    );
};

ValueChainForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.any,
    defaultValues: PropTypes.object,
    response: PropTypes.object,
    saveResponse: PropTypes.object,
};

export default ValueChainForm;
