import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { texts as dft } from '../../../texts/Forms/Digi3FormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextfieldWithCounter from '../../Partials/Form/TextfieldWithCounter';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import QuestionDigi3 from './QuestionDigi3';
import { TYPE } from '../../../util/DataTypes/Response';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        marginBottom: theme.spacing(2),
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        color: 'white',
        border: '1px solid #838383',
        borderBottom: '0',
        marginTop: theme.spacing(2),
    },
    questions: {
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    desc: {
        fontWeight: 'normal',
    },
    border: {
        border: '1px solid #838383',
    },
}));

const DigiThreeForm = (props) => {
    const { defaultValues, saveResponse, formId, onSubmit } = props;
    const classes = useStyles();
    const {
        handleSubmit,
        register,
        errors,
        setError,
        unregister,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });

    const submitCallback = (values) => {
        onSubmit({ ...values, '@id': defaultValues['@id'] });
    };

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                let violationPropertyPath = violation.propertyPath.replace(
                    'expectedResults',
                    ''
                );
                setError(violationPropertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    return (
        <form
            id={formId}
            onSubmit={(e) => {
                clearErrors();
                handleSubmit(submitCallback)(e);
            }}
            style={{ paddingBottom: '16px' }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {dft.title}
            </Typography>
            <Typography component="h6" variant="h6" gutterBottom>
                {dft.subhead}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {dft.desc}
            </Typography>

            <Typography
                className={classes.mt}
                variant="h4"
                component="h4"
                gutterBottom
            >
                {dft.abilityEffects.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {dft.abilityEffects.desc}
            </Typography>

            <Typography
                variant="h6"
                style={{ marginTop: '16px', paddingLeft: '16px' }}
                gutterBottom
            >
                {dft.abilityEffects.competitivenes.label}
            </Typography>

            <TextfieldWithCounter
                multiline
                variant="outlined"
                rows={4}
                name="competitivenes"
                register={register}
                max={dft.abilityEffects.competitivenes.maxLength}
                defaultValue={defaultValues.competitivenes}
                validation={{
                    required: dft.abilityEffects.competitivenes.error,
                }}
                error={errors.competitivenes}
            />

            <Typography
                className={classes.mt}
                variant="h6"
                style={{ marginTop: '16px', paddingLeft: '16px' }}
                gutterBottom
            >
                {dft.abilityEffects.innovativeCap.label}
            </Typography>

            <TextfieldWithCounter
                multiline
                variant="outlined"
                rows={4}
                name="innovativeCap"
                register={register}
                max={dft.abilityEffects.innovativeCap.maxLength}
                defaultValue={defaultValues.innovativeCap}
                validation={{
                    required: dft.abilityEffects.innovativeCap.error,
                }}
                error={errors.innovativeCap}
            />

            <Typography
                className={classes.mt}
                variant="h6"
                style={{ marginTop: '16px', paddingLeft: '16px' }}
                gutterBottom
            >
                {dft.abilityEffects.otherExpEffects.label}
            </Typography>

            <TextfieldWithCounter
                multiline
                variant="outlined"
                rows={4}
                name="otherExpEffects"
                register={register}
                max={dft.abilityEffects.otherExpEffects.maxLength}
                defaultValue={defaultValues.otherExpEffects}
                error={errors.otherExpEffects}
            />

            <Paper className={classes.header} elevation={0} square>
                <Typography className={classes.title}>
                    {dft.expectedResults.title}
                </Typography>
                <Typography className={classes.desc}>
                    {dft.expectedResults.desc}
                </Typography>
                <Alert className={classes.mt} severity="info">
                    {dft.expectedResults.info}
                </Alert>
            </Paper>
            <Paper elevation={0} square className={classes.border}>
                {dft.questions.map((question) => (
                    <QuestionDigi3
                        key={question.name}
                        error={errors}
                        name={`expectedResults.${question.name}`}
                        answers={question.answers}
                        tooltip={question.tooltip}
                        text={question.text}
                        isShown={true}
                        unregister={unregister}
                        register={register}
                        defaultValue={defaultValues[question.name]}
                    />
                ))}
            </Paper>
        </form>
    );
};

DigiThreeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

export default DigiThreeForm;
