import { api } from '../AxiosConfig/Config';
import { Response } from '../../../util/DataTypes/Response';
import { getWizardStateMutator } from './WizardMutators';
import {
    getWizardRejectMessage,
    parseSuccessMessage,
} from '../../../util/Helper/WizardTransitionMessages';

export function getWizardState(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/wizard-state`
                );
                resolve(getWizardStateMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Der Aktuelle Stand des Antrags konnte nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function wizardTransition(proposalId, toStep, back) {
    return new Promise(async (resolve, reject) => {
        const transition = `${back ? 'back_' : ''}to_step_${toStep}`;
        try {
            const { data } = await api().put(
                `/action/proposal/${proposalId}/apply-transition`,
                {
                    transition: transition,
                }
            );

            resolve(parseSuccessMessage(data.transition));
        } catch (e) {
            reject(getWizardRejectMessage(transition, back, e));
        }
    });
}

export function isStepComplete(proposalId, step) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/action/proposal/${proposalId}/wizard-state`
            );

            if (
                0 <=
                data.enabledTransitions.findIndex(
                    (el) => el.name === `to_step_${step + 1}`
                )
            ) {
                resolve(true);
            }

            resolve(false);
        } catch (e) {
            reject(Response.Error('Fehler beim Laden der Information', e));
        }
    });
}
