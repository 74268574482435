export const texts = {
    title: 'Zuschuss "Digital Jetzt"',
    parser: {
        hintQuota: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Die Förderquote ist ab dem 1. Januar 2022 abhängig von der Unternehmensgröße folgendermaßen festgesetzt:',
                },
                {
                    type: 'p',
                    text: '40% für Unternehmen bis 50 Mitarbeitende',
                },
                {
                    type: 'p',
                    text: '35% für Unternehmen bis 250 Mitarbeitende',
                },
                {
                    type: 'p',
                    text: '30% für Unternehmen bis 499 Mitarbeitende',
                },
                {
                    type: 'p',
                    text: 'In folgenden Fällen werden Bonusprozentpunkte berücksichtigt:',
                },
                {
                    type: 'p',
                    text: '- Gleichzeitige Investionen von mehreren Unternehmen entlang einer Wertschöpfungskette oder innerhalb eines Wertschöpfungsnetzwerks (plus 5 Prozentpunkte)',
                },
                {
                    type: 'p',
                    text: '- Investitionen zur Erhöhung der IT-Sicherheit (inklusive Datenschutz) im Unternehmen (plus 5 Prozentpunkte)',
                },
                {
                    type: 'p',
                    text: '- Investitionen von Unternehmen in wirtschaftlich strukturschwachen Regionen (plus 10 Prozentpunkte)',
                },
            ],
        },
    },
    calculation: {
        title: 'Berechnung Fördersumme / Eigenanteil',
        invest: 'Gesamtinvestition als Summe aller nachgewiesenen Ausgaben',
        quota: 'Förderquote',
    },
    step1: {
        title: 'Schritt 1: Berechnung der Fördersumme über Förderquote – (Zwischensumme)',
        hint: 'Dieser Betrag ergibt sich aus der für Ihr Unternehmen ermittelten Förderquote. Im Folgenden wird zunächst ein Abgleich mit den in der Förderrichtlinie vorgegebenen Unter- und Obergrenzen vorgenommen. ',
    },
    step2: {
        title: 'Schritt 2: Unter- und Obergrenzen "Digital Jetzt"',
        moduleOne:
            'Investition in Modul 1 (Hard- und Software) – Höhe der Förderung (Zwischensumme)',
        moduleTwo:
            'Investition in Modul 2 (Qualifikation) – Höhe der Förderung (Zwischensumme)',
        sum: 'Zwischensumme',
        error: 'Ihr Antrag ist nicht förderfähig, da sich die errechnete Zwischensumme unter dem Minimalbetrag befindet.',
        single: {
            string1: '17.000,00 / 50.000,00 €',
            min1Cents: 1700000,
            max1Cents: 5000000,
            string2: '3.000,00 / 50.000,00 €',
            min2Cents: 300000,
            max2Cents: 5000000,
        },
        multi: {
            string1: '17.000,00 / 100.000,00 €',
            min1Cents: 1700000,
            max1Cents: 10000000,
            string2: '3.000,00 / 100.000,00 €',
            min2Cents: 300000,
            max2Cents: 10000000,
        },
        moduleOneTwo: 'Investition in Modul 1+2 - Höhe der Förderung',
        hint: 'Dieser Betrag ergibt sich lediglich aus der für Ihr Unternehmen ermittelten Förderquote abgeglichen mit den in der Förderrichtlinie vorgegebenen Unter- und Obergrenzen. Im Folgenden wird ein Abgleich mit der möglichen Höhe des Zuschusses vorgenommen.',
    },
    step3: {
        title: 'Schritt 3: Abgleich mit maximal möglicher De-minimis-Beihilfe',
        max: 'Höhe des maximal möglichen Zuschusses (Übertrag aus Berechnung De-minimis-Beihilfe)',
        sum: 'Höhe der in Schritt 2 errechneten Fördersumme (Zwischensumme)',
        dij: 'Höhe des Zuschusses "Digital Jetzt"',
        hint: 'Die Auszahlung des Zuschusses erfolgt nach positivem Abschluss der Prüfung des Verwendungsnachweises.',
        error1: 'Der Antrag ist nicht förderfähig, da die Fördersumme unter dem Minimum liegt',
        error2: (isRoadTransport) => ({
            paragraphs: [
                {
                    type: 'p',
                    text: `Ihre errechnete Fördersumme im Programm "Digital Jetzt" (Zuschuss) ist höher, als ihre Restkapazität einer möglichen De-Minimis Förderung. Der Gesamtbetrag der einem Unternehmen (einschließlich seiner verbundenen Unternehmen) von einem Mitgliedstaat der EU gewährten De-minimis Beihilfen darf innerhalb eines fließenden Zeitraums von drei Steuerjahren (dem laufenden und den beiden vorangegangenen Steuerjahren) maximal den Betrag von ${
                        isRoadTransport === true
                            ? '100.000,00 EUR'
                            : '200.000,00 EUR'
                    } ergeben. Dieser würde durch die Förderung von "Digital Jetzt" überschritten.`,
                },
                {
                    type: 'p',
                    text: `Bitte passen Sie ihr Investitionsvorhaben so an, dass die maximale Obergrenze für die De-minimis Förderung (${
                        isRoadTransport === true
                            ? '100.000,00 EUR'
                            : '200.000,00 EUR'
                    }) inklusive der Förderung von "Digital Jetzt", nicht überschritten wird.`,
                },
            ],
        }),
    },
    step4: {
        title: 'Schritt 4: Berechnung des Eigenanteils',
        invest: 'Investitionssumme gesamt',
        dij: 'Höhe des Zuschusses "Digital Jetzt"',
        self: 'Eigenanteil',
    },
    self: {
        title: 'Angaben zum Eigenanteil im Finanzierungsplan',
        sub: 'Eigenmittel',
        first: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Der im Finanzierungsplan ausgewiesene Eigenanteil[tooltip|0][tooltip] wird durch Eigenmittel aufgebracht.',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'Eigenmittel sind in Geld frei zur Verfügung stehende Finanzmittel – sogenannte Barmittel . In der Regel sind dies Bankguthaben, die zu Beginn des Bewilligungszeitraumes tatsächlich vorhanden sind.',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        errorFirst: {
            required:
                'Sie müssen bestätigen, dass Sie ausgewiesenen Eigenanteil durch Eigenmittel aufbringen',
        },
        second: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Das antragstellende Unternehmen berücksichtigt hinsichtlich der Finanzierung des Gesamtvorhabens alle sonstigen finanziellen Verpflichtungen während der Laufzeit des Vorhabens. Es sollten ausreichend private und betriebliche Mittel verbleiben, um eventuelle Liquiditätsengpässe überbrücken zu können. Das antragstellende Unternehmen berücksichtigt zudem, dass im Falle der Bewilligung des Zuschusses das Vorhaben zunächst auf eigene Kosten umzusetzen ist und die Auszahlung des Zuschusses erst nach Umsetzung und positivem Abschluss der Prüfung des Verwendungsnachweises erfolgt.',
                },
                {
                    type: 'p',
                    text: 'Das antragstellende Unternehmen erklärt, dass die Umsetzung des Investitionsvorhabens auf Basis der bestehenden Personalkapazitäten umgesetzt werden kann.',
                },
            ],
        },
        errorSecond: {
            required: 'Muss bestätigt werden',
        },
    },
    credit: {
        title: 'Kreditzusagen',
        approval:
            'Vorgesehene Eigenmittel sind über Kredite gedeckt, die bereits zugesagt sind ',
        isKfw: 'Es handelt sich dabei um eine auf das Investitionsvorhaben bezogene Kreditzusage der KfW für ein Digitalisierungsvorhaben aus dem „ERP-Digitalisierungs- und Innovationskredit“.',
        errorKfw: {
            required: 'Muss angegeben werden',
        },
        bank: 'Name der Bank',
        bankerror: {
            required: 'Es muss der Name der Bank angegeben werden',
        },
    },
};
