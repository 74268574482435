import moment from 'moment';

export function canCreateNewProposal(raffles) {
    const withoutProposal = raffles.filter((el) => !el.proposal);
    if (withoutProposal.length <= 0) return false;
    const validRaffles = withoutProposal.filter((el) => el.valid);
    if (validRaffles.length <= 0) return false;
    const submittableRaffles = validRaffles.filter(
        (el) => moment(el.rafflePot.submitEnd) > moment()
    );
    return submittableRaffles.length > 0;
}
