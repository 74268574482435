import React from 'react';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { texts as dbt } from '../../texts/Dashboard/DashboardTexts';

const RegisterForm = () => {
    return (
        <Alert severity="info">
            <Typography gutterBottom>
                Sehr geehrte Antragstellerinnen und Antragsteller,
            </Typography>
            <Typography gutterBottom>
                <strong>
                    die Förderrichtlinie „Digital jetzt – Investitionsförderung
                    für KMU“ ist gemäß Punkt 7 der Richtlinie bis zum 31.12.2023
                    befristet.
                </strong>
            </Typography>
            <Typography gutterBottom>
                Aufgrund der Befristung ist eine Bewerbung auf ein
                Antragskontingent nicht mehr möglich.
            </Typography>
            {dbt.anonDashboard.map((line, row) => (
                <Typography key={`anonDash-${row}`} gutterBottom>
                    {line}
                </Typography>
            ))}
        </Alert>
    );
};

export default RegisterForm;
