export const texts = {
    title: {
        headline: 'Antrag abschicken',
        paragraphs: [
            {
                type: 'p',
                text: 'Sie haben den Antrag auf Förderung im Förderprogramm „Digital Jetzt - Investitionsförderung für KMU“ erfolgreich eingereicht.',
            },
            {
                type: 'p',
                text: '[u]Um die Antragseinreichung formal abzuschließen müssen sie den Antrag nach Herunterladen der "Unterschriftsseite" und der Angabe Ihrer Übersendung noch abschicken.[u]',
            },
            {
                type: 'p',
                text: 'Ihr verbindlicher Antrag besteht aus dem „Unterschriftsblatt“, das Sie unter folgendem Link herunterladen können:',
            },
        ],
    },
    hint: {
        paragraphs: [
            {
                type: 'p',
                text: 'Diese Dokumente dienen lediglich Ihrer Information. Sie können diese am Bildschirm lesen oder bei Bedarf herunterladen. Bitte bewahren Sie diese dann bei Ihren Unterlagen auf. Ein Übersenden ist nicht notwendig.',
            },
        ],
    },
    text0: {
        headline: '',
        paragraphs: [
            {
                type: 'p',
                text: 'Zusätzliche Dokumente, die heruntergeladen werden können:',
            },
            {
                type: 'list',
                text: '[li][a|doc1]„Unternehmen in Schwierigkeiten“[a][li][li][a|doc2]Subventionserhebliche Tatsachen - Anlage A und B[a][li][li][a|doc3]Erläuterung De-minimis / EU-Beihilfen[a][li]',
            },
        ],
    },
    text1: {
        paragraphs: [
            {
                type: 'p',
                text: 'Ihr Antrag gilt erst dann als rechtsverbindlich eingegangen, wenn Sie die Datei „Unterschriftsblatt“ eingereicht haben. Erst mit deren Eingang beim DLR Projektträger kann mit der Bearbeitung Ihres Antrags begonnen werden. Um den verbindlichen Antrag bei uns einzureichen, können Sie zwischen folgenden Alternativen wählen:',
            },
        ],
    },
    fileError: {
        paragraphs: [
            {
                type: 'p',
                text: 'Bitte laden Sie den mit einer elektronischen Signatur versehenen Antrag hoch, wenn Sie ihn mit einer elektronischer Signatur einreichen wollen.',
            },
        ],
    },
    textA: {
        paragraphs: [
            {
                type: 'p',
                text: 'Um den Antrag rechtsverbindlich einzureichen sind folgende Schritte notwendig:',
            },
            {
                type: 'list',
                text: '[li]Drucken Sie die heruntergeladene Datei „Unterschriftsblatt“ aus und unterzeichnen Sie den Ausdruck handschriftlich[li][li]Bitte senden Sie ausschließlich die Datei „Unterschriftsblatt“ als Papier-Ausdruck mit Ihrer rechtsverbindlichen Unterschrift versehen, so zeitnah wie es Ihnen möglich ist, dem DLR Projektträger auf dem Postweg zu.[li]',
            },
            {
                type: 'p',
                text: 'Sie erhalten dann eine Eingangsbestätigung per Mail.',
            },
            {
                type: 'p',
                text: 'Erst mit dem Tag des Posteinganges beim DLR Projektträger gilt Ihr Antrag als rechtsverbindlich eingegangen. Ab diesem Zeitpunkt kann Ihr Antrag abschließend geprüft werden.',
            },
        ],
    },
    textB: {
        paragraphs: [
            {
                type: 'p',
                text: 'Um den Antrag elektronisch zu signieren sind folgende Schritte notwendig:',
            },
            {
                type: 'list',
                text: '[li]Unterzeichnen Sie die heruntergeladene Datei „Unterschriftsblatt“ mit Ihrer elektronischen [tooltip|0]Signatur.[tooltip][li][li]Bitte laden Sie die von Ihnen elektronisch unterzeichnete Datei „Unterschriftsblatt“ als pdf hier hoch.[li]',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Folgende Signaturverfahren werden akzeptiert:',
                            },
                            {
                                type: 'list',
                                text: '[li]Signaturkarten / Digitale Unterschriften mit qualifizierten Signaturen[li][ul][li][a|https://www.bundesdruckerei.de/de/loesungen/Signaturkarten#Produktdetails]https://www.bundesdruckerei.de/de/loesungen/Signaturkarten#Produktdetails[a][li][ul][li]Elektronisch signierte Dokumente / sign me[li][ul][li][a|https://www.bundesdruckerei.de/de/2837-sign-me]https://www.bundesdruckerei.de/de/2837-sign-me[a][li][ul]',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: 'Sie erhalten dann direkt im Anschluss eine Eingangsbestätigung per Mail.',
            },
        ],
    },
    finished: {
        headline: 'Antrag erfolgreich eingereicht',
        paragraphs: [
            {
                type: 'p',
                text: 'Ihr Antrag wurde erfolgreich eingereicht! Sie erhalten in Kürze eine E-Mail mit allen Informationen zum weiteren Vorgehen.',
            },
        ],
    },
    finished2: {
        paragraphs: [
            {
                type: 'p',
                text: 'Sie können sich nun aus dem Portal [a|/abmelden]abmelden[a] oder sich unter "[a|/antrag]Antragsübersicht[a]" den eingereichten Antrag anzeigen lassen.',
            },
        ],
    },
    error401: {
        headline: 'Nicht berechtigt',
        paragraphs: [
            {
                type: 'p',
                text: 'Sie sind nicht berechtigt auf diesen Antrag zuzugreifen.',
            },
        ],
    },
    error404: {
        headling: 'Antrag nicht gefunden',
        paragraphs: [
            {
                type: 'p',
                text: 'Der Antrag konnte nicht gefunden weren',
            },
        ],
    },
    static: {
        titleA: 'Händische Unterschrift der von Ihnen heruntergeladenen PDF und Versand auf dem Postweg',
        titleB: 'Qualifizierte digitale Signatur (sign-me der D-Trust GmbH und D-TRUST Signaturkarte) und elektronische Übermittlung',
        finalA: 'Sobald Ihr Antrag rechtsverbindlich unterzeichnet eingegangen ist, erhalten Sie eine Eingangsbestätigung. Ab diesem Zeitpunkt kann Ihr Antrag abschließend geprüft werden.',
        finalB: 'Sie haben Ihren Antrag rechtsverbindlich unterschrieben eingereicht.',
        error: {
            required:
                'Bitte wählen Sie aus, wie Sie den Antrag einreichen wollen',
        },
        pdf: 'Download Unterschriftsblatt',
        pdfRedownload: 'Unterschriftsblatt erneut downloaden',
        hasDownload:
            'Bitte laden Sie die Datei "Unterschriftsblatt" herunter, um den Antrag einreichen zu können.',
    },
    info: {
        headline: 'Antrag eingereicht',
        paragraphs: [
            {
                type: 'p',
                text: 'Ihr Antrag auf Förderung im Förderprogramm „Digital Jetzt - Investitionsförderung für KMU“ wurde erfolgreich eingereicht. Das zum Abschließen des Antrages erforderliche Unterschriftsblatt wird in den nächsten 48 Stunden erstellt und Ihnen im Digital Jetzt Förderportal zum Download bereitgestellt.',
            },
            {
                type: 'p',
                text: 'Sie erhalten diesbezüglich weitere Informationen per E-Mail. Sollten Sie binnen 48 Stunden keine E-Mail von uns erhalten, wenden Sie sich bitte, unter Nennung der registrierten E-Mail, an den technischen Support unter pt-webservice@dlr.de.',
            },
        ],
    },
    signatureRequirements: {
        dialog: 'Bitte bestätigen Sie, dass es sich bei der von Ihnen verwendeten digitalen Signatur um eine der beiden qualifizierten digitalen Signaturen sign-me der D-Trust GmbH oder D-TRUST Signaturkarte handelt.',
        info: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Die digitale Einreichung des Antrages / des Unterschriftsblattes ist ausschließlich unter Nutzung einer der beiden qualifizierten digitalen Signaturen sign-me der D-Trust GmbH oder D-TRUST Signaturkarte möglich.',
                },
                {
                    type: 'p',
                    text: 'Im Fall einer nicht gültigen digitalen Signatur gilt der Antrag als nicht eingereicht und die Nachreichung eines rechtsverbindlich unterschriebenen Antrages / Unterschriftsblattes ist notwendig. Die Anträge werden nach Eingang des rechtsverbindlich unterschriebenen Antrags beim DLR Projektträger abgearbeitet.',
                },
            ],
        },
    },
    signatureError:
        'Bitte bestätigen Sie, dass die digitale Unterschrift den Vorgaben entspricht.',
    finalNotice:
        'Mit dem Posteingang der Unterschriftsseite oder dem Hochladen der „Unterschriftsseite“ mit qualifizierter digitaler Signatur beim DLR Projektträger und dem Abschluss der Antragstellung im Digital Jetzt Förderportal gilt Ihr Antrag als rechtsverbindlich eingereicht. Sie werden über den Eingang der „Unterschriftsseite“ per E-Mail informiert. Im Anschluss daran kann mit der Prüfung Ihres Antrages begonnen werden. Von Fragen zum Bearbeitungsstand bitten wir abzusehen. Hierfür bitten wir um Ihr Verständnis.',
};
