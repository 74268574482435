import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import moment from 'moment';

const FormBasicDatePicker = (props) => {
    const {
        label,
        control,
        defaultValue,
        name,
        rules,
        error,
        notEditable,
        disabled,
        max,
        onChangeExternal,
    } = props;

    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        onChange(e.target.value);
        onChangeExternal(e);
    };

    const handleValue = (value) => {
        if (!value) return '';
        const date = moment(new Date(value));

        if (date.isValid()) return date.format('yyyy-MM-DD');
        return '';
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ value, onChange }) => (
                <TextField
                    label={label}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    value={handleValue(value)}
                    name={name}
                    onChange={handleChange(onChange)}
                    error={Boolean(error)}
                    helperText={Boolean(error) ? error.message : undefined}
                    disabled={disabled || notEditable}
                    inputProps={{ max: max }}
                />
            )}
        />
    );
};

FormBasicDatePicker.propTypes = {
    control: PropTypes.object,
    label: PropTypes.any,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    max: PropTypes.string,
    onChangeExternal: PropTypes.func,
};

FormBasicDatePicker.defaultProps = {
    defaultValue: '',
    notEditable: false,
    onChangeExternal: () => {},
};

export default FormBasicDatePicker;
