import React from 'react';
import PropTypes from 'prop-types';
import { texts as oft } from '../../../../texts/Forms/OverviewFormTexts';
import { ContentTypography } from './OverviewComponent';
import Typography from '@material-ui/core/Typography';

export const CompanyLocationOverview = (props) => {
    const data = props.data;
    let isWeak = false;
    const addresses = data.addresses.map((address) => {
        let headline = address.isMain
            ? oft.companyLocation.content.headline.main
            : oft.companyLocation.content.headline.other;
        if (1 === data.addresses.length) {
            headline += ' / ' + oft.companyLocation.content.headline.other;
        }
        if (address.isWeak) {
            isWeak = true;
        }
        return (
            <div key={address.id}>
                <Typography variant="h6" component="h3">
                    {headline}
                </Typography>
                <div style={{ marginLeft: '10px' }}>
                    <Typography>{`${address.street} ${address.housenumber}`}</Typography>
                    <Typography>{`${address.postcode} ${address.city}`}</Typography>
                    <Typography>{`${address.state}`}</Typography>
                </div>
            </div>
        );
    });
    return (
        <>
            {ContentTypography(
                oft.companyLocation.content.name,
                data.companyName
            )}
            {data.companyUrl &&
                ContentTypography(
                    oft.companyLocation.content.url,
                    data.companyUrl
                )}
            {addresses}
            {ContentTypography(
                oft.companyLocation.content.weak,
                isWeak ? 'Ja' : 'Nein'
            )}
        </>
    );
};

CompanyLocationOverview.propTypes = {
    data: PropTypes.object,
};

export default CompanyLocationOverview;
