import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator } from './MinimisFetchMutator';
import { saveMutator } from './MinimisSaveMutator';

export function fetchMinimisData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/step_4`
                );
                resolve(fetchMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die Minimis Beihilfen Daten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveMinimisData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_4_final`,
                saveMutator(data)
            );
            resolve(
                Response.Success(
                    'Die Minimis Behilfen wurden erfolgreich gespeichert'
                )
            );
        } catch (e) {
            const message =
                e?.response?.data['hydra:description'] ||
                'Die Minimis Behilfen Daten konnten nicht gespeichert werden';
            reject(Response.Error(message, e));
        }
    });
}
