import { api } from '../AxiosConfig/Config';
import { Response } from '../../../util/DataTypes/Response';

export function getSectorKeyList() {
    return new Promise((resolve, reject) => {
        api()
            .get('/sector_keys')
            .then((response) => {
                const obj = response.data['hydra:member'];
                resolve(obj);
            })
            .catch((err) => {
                reject(
                    Response.Error(
                        'Fehler beim laden des Branchenschlüssels',
                        err
                    )
                );
            });
    });
}
