import { texts as fpt } from '../../../texts/Forms/FinancingPlan2FormTexts';

export function fetchMutator(responses) {
    const { data } = responses[0];
    const { data: wsk } = responses[1];
    const { data: digi2 } = responses[2];

    data.isValueChain = wsk.isValueChain;
    data.moduleOne = digi2.moduleOne;
    data.moduleTwo = digi2.moduleTwo;
    data.kfwCreditApproval = data.kfwCreditApproval === true ? 'true' : 'false';

    const total = parseInt(data.sumInvestOverall);
    const fq = parseInt(data.fundingRatio);
    const step1 = Math.round((total * fq) / 100);

    const { min, max } = (() => {
        if (data.isValueChain) {
            return data.moduleOne
                ? {
                      min: fpt.step2.multi.min1Cents,
                      max: fpt.step2.multi.max1Cents,
                  }
                : {
                      min: fpt.step2.multi.min2Cents,
                      max: fpt.step2.multi.max2Cents,
                  };
        }
        return data.moduleOne
            ? {
                  min: fpt.step2.single.min1Cents,
                  max: fpt.step2.single.max1Cents,
              }
            : {
                  min: fpt.step2.single.min2Cents,
                  max: fpt.step2.single.max2Cents,
              };
    })();
    const step2 = step1 > max ? max : step1;
    const maxDeminimis = data.maxDeminimis;
    const step3 =
        step2 < min ? 0 : step2 > maxDeminimis ? parseInt(maxDeminimis) : step2;

    return {
        ...data,
        table: {
            total,
            fq,
            step1,
            min,
            max,
            step2,
            step3,
            maxDeminimis,
            isValueChain: data.isValueChain,
            isRoadTransport: data.isRoadTransport,
        },
    };
}

export function saveMutator(data) {
    return {
        approveCoPayments: data.approveCoPayments,
        creditApproval: data.creditApproval,
        approveEconomic: data.approveEconomic,
        kfwCreditApproval: data.kfwCreditApproval === 'true',
        creditBankName: data.creditBankName,
    };
}
