import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
    h4: {
        fontWeight: 'normal',
        fontSize: '1.5rem',
        marginBottom: '1rem',
    },
    body: {
        marginBottom: '.5rem',
    },
});

const FooterGridSupport = (props) => {
    const { headline, body, mail, tel, faq } = props;
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4" component="p" className={classes.h4}>
                {headline}
            </Typography>
            <Typography variant="body1" className={classes.body}>
                {body.map((line, idx) =>
                    idx !== body.length - 1 ? (
                        <React.Fragment key={`footer_support_line${idx}`}>
                            {line}
                            <br />
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={idx}>{line}</React.Fragment>
                    )
                )}
            </Typography>
            {mail?.link && mail?.text && (
                <Typography variant="body1" className={classes.body}>
                    <Link variant="body1" href={'mailto:' + mail.link}>
                        {mail.text}
                    </Link>
                </Typography>
            )}
            {tel && (
                <Typography variant="body1" className={classes.body}>
                    {tel}
                </Typography>
            )}
            {faq?.link && faq?.text && (
                <Typography variant="body1" className={classes.body}>
                    <Link component={RouterLink} to={faq.link}>
                        {faq.text}
                    </Link>
                </Typography>
            )}
        </>
    );
};

FooterGridSupport.propTypes = {
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    mail: PropTypes.string,
    tel: PropTypes.string,
    faq: PropTypes.string,
};

export default FooterGridSupport;
