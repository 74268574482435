import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { step2Texts } from '../../../texts/Verification/Step2Texts';
import FormRadio from '../Components/FormRadio';
import { FORM_TRANSFORMER } from '../../../util/Helper/FormTransformer';
import ConditionalDisplay from '../Components/ConditionalDisplay';
import FormTextArea from '../Components/FormTextArea';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper } from '@material-ui/core';
import Outline from '../../Partials/Info/Outline';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    title: {
        fontSize: 20,
    },
    answer: {
        fontSize: 18,
        fontStyle: 'italic',
    },
    specified: {
        marginTop: theme.spacing(4),
        fontSize: 18,
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
        whiteSpace: 'break-spaces',
    },
}));

const Step2Question = (props) => {
    const classes = useStyles();
    const {
        title,
        questionProps,
        commentProps,
        question,
        explanation,
        label,
        questionDefaultValue,
        commentValue,
        commentDefaultValue,
        watch,
        commentError,
        notEditable,
        errors,
        control,
    } = props;

    return (
        <Outline title={title} gutterTop>
            <Grid containerspacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title} gutterBottom>
                        {question}
                    </Typography>
                    <Typography
                        className={classes.specified}
                        variant="h6"
                        component="h3"
                        gutterBottom
                    >
                        {step2Texts.specified}
                    </Typography>
                    <Paper className={classes.paper} variant="outlined" square>
                        <Typography className={classes.answer} gutterBottom>
                            {explanation}
                        </Typography>
                    </Paper>
                    <Typography
                        className={classes.mt4}
                        variant="h6"
                        component="h2"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                    <FormRadio
                        control={control}
                        name={questionProps.name}
                        label={questionProps.label}
                        buttons={questionProps.buttons}
                        rules={questionProps.rules}
                        defaultValue={questionDefaultValue}
                        notEditable={notEditable}
                        {...FORM_TRANSFORMER.radioBool}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ConditionalDisplay
                        field={questionProps.name}
                        control={control}
                        display={false}
                    >
                        <FormTextArea
                            value={commentValue}
                            control={control}
                            name={commentProps.name}
                            label={commentProps.label}
                            defaultValue={commentDefaultValue}
                            notEditable={notEditable}
                            rules={commentProps.rules(watch)}
                            error={errors[commentError]}
                            fullWidth
                            maxLength={600}
                        />
                    </ConditionalDisplay>
                </Grid>
            </Grid>
        </Outline>
    );
};

Step2Question.propTypes = {
    title: PropTypes.string,
    questionProps: PropTypes.object.isRequired,
    commentProps: PropTypes.object.isRequired,
    question: PropTypes.string,
    explanation: PropTypes.string,
    label: PropTypes.string,
    questionDefaultValue: PropTypes.bool,
    commentValue: PropTypes.string,
    commentDefaultValue: PropTypes.string,
    //watch: PropTypes.func.isRequired,
    commentError: PropTypes.string,
    notEditable: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
};

Step2Question.defaultProps = {
    notEditable: false,
    title: '',
};

export default Step2Question;
