import { fetchMutator as step7FetchMutator } from '../lib/api/DigitalThree/DigitalThreeMutators';
import { fetchStep } from '../lib/api/Proposal/ProposalActions';
import { step2OriginalDataFetchMutator } from '../lib/api/Verification/VerificationMutator';

export const verificationActions = [
    {
        mutator: (data) => data,
        fetch: fetchStep,
        error: {
            generic: 'Die Antragsformdaten konnten nicht geladen werden (1).',
            404: 'Die Antragsformdaten konnten nicht gefunden werden (1).',
            500: 'Wegen eines Serverfehlers konnten die Antragsformdaten nicht geladen werden (1).',
        },
        step: 'step_1',
    },
    {
        mutator: (data) => data,
        fetch: fetchStep,
        error: {
            generic: 'Die Antragsformdaten konnten nicht geladen werden (2).',
            404: 'Die Antragsformdaten konnten nicht gefunden werden (2).',
            500: 'Wegen eines Serverfehlers konnten die Antragsformdaten nicht geladen werden (2).',
        },
        step: 'step_2',
    },
    {
        mutator: step2OriginalDataFetchMutator,
        fetch: fetchStep,
        error: {
            generic: 'Die Antragsformdaten konnten nicht geladen werden (6).',
            404: 'Die Antragsformdaten konnten nicht gefunden werden (6).',
            500: 'Wegen eines Serverfehlers konnten die Antragsformdaten nicht geladen werden (6).',
        },
        step: 'step_6',
    },
    {
        mutator: step7FetchMutator,
        fetch: fetchStep,
        error: {
            generic: 'Die Antragsformdaten konnten nicht geladen werden (7).',
            404: 'Die Antragsformdaten konnten nicht gefunden werden (7).',
            500: 'Wegen eines Serverfehlers konnten die Antragsformdaten nicht geladen werden (7).',
        },
        step: 'step_7',
    },
];
