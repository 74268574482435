import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { MenuItem, TextField } from '@material-ui/core';

const FormDropdown = (props) => {
    const {
        label,
        control,
        defaultValue,
        name,
        rules,
        error,
        notEditable,
        disabled,
        menuItems,
    } = props;

    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        onChange(e.target.value);
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ value, onChange }) => (
                <TextField
                    select
                    fullWidth
                    label={label}
                    value={value}
                    onChange={handleChange(onChange)}
                    disabled={disabled || notEditable}
                    error={Boolean(error)}
                    helperText={Boolean(error) ? error.message : undefined}
                >
                    {menuItems.length === 0 && (
                        <MenuItem disabled value="">
                            Keine Auswahlmöglichkeiten
                        </MenuItem>
                    )}
                    {menuItems.map((item, id) => (
                        <MenuItem value={item.value} key={item.value + id}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );
};

FormDropdown.propTypes = {
    control: PropTypes.object,
    label: PropTypes.any,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    menuItems: PropTypes.array,
};

FormDropdown.defaultProps = {
    defaultValue: '',
    notEditable: false,
    menuItems: [],
};

export default FormDropdown;
