import { isMoneyString } from '../Money/MoneyFunctions';

export function validateDeviceListRow(row, priceLabel) {
    const err = {};

    if (row.description === '') err.description = 'Darf nicht leer sein';

    if (row.explanation === '') err.explanation = 'Darf nicht leer sein';

    if (!/^\d{1,3}((\.\d{3})*|\d*)(,\d{1,4})?$/.test(row.amount))
        err.amount =
            'Bitte geben Sie nur Zahlen mit max. 4 Nachkommastellen an';

    if (!isMoneyString(row[priceLabel])) err[priceLabel] = 'Ungültiger Wert';

    return Object.keys(err).length === 0 ? true : err;
}

export function validateActivityListRow(row, priceLabel) {
    const isValid = validateDeviceListRow(row, priceLabel);

    const err = typeof isValid !== 'boolean' ? { ...isValid } : {};

    if (!/^[1-9]\d*$/.test(row.duration)) err.duration = 'Darf nicht leer sein';
    if (!/^[1-9]\d*$/.test(row.participants))
        err.participants = 'Darf nicht leer sein';

    return Object.keys(err).length === 0 ? true : err;
}

export function validateBasicEquipment(value, devices, message = '') {
    if (devices && value === true) return true;
    if (!devices) return true;
    return message;
}
