const getMimeTypeForSignature = (signature) => {
    switch (signature) {
        case '25504446':
            return {
                mimeType: 'application/pdf',
                extension: '.pdf',
            };
        default:
            return null;
    }
};

const getMimeType = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
                const uint = new Uint8Array(evt.target.result);
                let bytes = [];
                uint.forEach((byte) => {
                    bytes.push(byte.toString(16));
                });
                const hex = bytes.join('').toUpperCase();

                resolve(getMimeTypeForSignature(hex));
            }

            reject();
        };

        reader.readAsArrayBuffer(blob.slice(0, 4));
    });
};

export { getMimeType };
