import { states } from '../../util/Constants/States';
import { isOfType } from '../../util/Validation/ValidateType';
import { validatePlz } from '../../util/Validation/ValidatePlz';

export const step1Texts = {
    title: 'Verwendungsnachweis Dateneingabe',
    errorText:
        'Das Formular enthält Fehler, bitte überprüfen Sie Ihre Eingaben.',
    outline1: {
        fkzLabel: 'Förderkennzeichen',
        nameLabel: 'Vor- und Nachname der bevollmächtigten Person',
        emailLabel: 'E-Mail Adresse der bevollmächtigten Person',
        streetLabel: 'Straße (Hauptsitz)',
        postcodeLabel: 'PLZ (Hauptsitz)',
        cityLabel: 'Ort (Hauptsitz)',
        stateLabel: 'Bundesland (Hauptsitz)',
    },
    outline2: {
        changed: {
            label: 'Haben sich die im Antrag angegebenen Daten zum Unternehmen geändert?',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            name: 'hasNewAddress',
            rules: {
                validate: (value) =>
                    isOfType(
                        value,
                        'boolean',
                        'Bitte geben Sie an, ob sich die Daten geändert haben.'
                    ),
            },
        },
        authorizedPersonHeadline: 'Bevollmächtigte Person',
        salutation: {
            label: 'Anrede',
            name: 'salutation',
            menuItems: [
                { value: 'm', label: 'Herr' },
                { value: 'f', label: 'Frau' },
                { value: 'd', label: 'Divers' },
            ],
            rules: { required: 'Bitte geben Sie eine Anrede an' },
        },
        title: {
            label: 'Titel',
            name: 'title',
            menuItems: [
                { value: '', label: 'Kein Titel' },
                { value: 'Dr.', label: 'Dr.' },
                { value: 'PhD', label: 'PhD' },
                { value: 'Prof.', label: 'Prof.' },
            ],
        },
        firstName: {
            label: 'Vorname',
            name: 'firstName',
            rules: {
                required: 'Bitte geben Sie den Vornamen an.',
            },
        },
        lastName: {
            label: 'Nachname',
            name: 'lastName',
            rules: {
                required: 'Bitte geben Sie den Nachnamen an.',
            },
        },
        position: {
            label: 'Position im Unternehmen',
            name: 'func',
            menuItems: [
                { value: 0, label: 'Geschäftsführung' },
                { value: 1, label: 'Leitung Abteilung/Unternehmenseinheit' },
                { value: 2, label: 'Mitarbeitende' },
            ],
            rules: {
                required: 'Bitte geben Sie die Position im Unternehmen an.',
            },
        },
        email: {
            label: 'E-Mail',
            name: 'email',
            rules: {
                required: 'Bitte geben Sie die E-Mail-Adresse an.',
            },
        },
        phone: {
            label: 'Telefon',
            name: 'phone',
            rules: {
                required: 'Bitte geben Sie die Telefonnummer an.',
            },
        },
        fax: {
            label: 'Fax',
            name: 'fax',
        },
        companyHeadline: 'Hauptsitz des Unternehmens',
        street: {
            label: 'Straße',
            name: 'street',
            rules: {
                required: 'Bitte geben Sie die Straße an.',
            },
        },
        houseNumber: {
            label: 'Hausnr.',
            name: 'houseNumber',
            rules: { required: 'Bitte geben Sie eine Hausnummer an' },
        },
        plz: {
            label: 'PLZ',
            name: 'postcode',
            rules: {
                required: 'Bitte geben Sie die PLZ an.',
                validate: (value) =>
                    validatePlz(value, 'Bitte geben Sie eine gültige PLZ an.'),
            },
        },
        city: {
            label: 'Ort',
            name: 'city',
            rules: {
                required: 'Bitte geben Sie die Stadt an.',
            },
        },
        state: {
            label: 'Bundesland',
            name: 'state',
            items: states.map((el) => ({ label: el.name, value: el.id })),
            rules: {
                required: 'Bitte geben Sie das Bundesland an.',
            },
        },
    },
    outline3: {
        title: 'Bewilligungszeitraum',
        subtitle:
            'Zeitraum für die Umsetzung der Maßnahmen laut Zuwendungsbescheid oder aktuellstem Änderungsbescheid',
        startLabel: 'Von',
        endLabel: 'Bis',
    },
    info:
        'Hier werden Ihnen die Daten zum Zeitpunkt der Antragstellung gespiegelt. Sollten sich diese Daten ' +
        'geändert haben, aktualisieren Sie diese bitte im folgenden Formular. Bitte beachten Sie, dass die ' +
        'Korrespondenz zum Verwendungsnachweis über die registrierte E-Mail Adresse erfolgt. Diese kann aus ' +
        'Datenschutzgründen hier nicht geändert werden. Möchten Sie ihre Registrierungsdaten anpassen, wenden Sie ' +
        'sich bitte an den Support: pt-webservice@dlr.de',
};
