import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextBlock from '../../TextBlock/TextBlock';
import { texts } from '../../../texts/Forms/Digi2FormTexts';
import Alert from '@material-ui/lab/Alert';
import { getFirstValidDate } from '../../../util/Validation/ValidateDate';

const useStyles = makeStyles((theme) => ({
    dateGridItem: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
}));

const Digi2ProjectPeriod = (props) => {
    const { setValue, errors, onDiffChange, defaultValues, showPeriodHint } =
        props;
    const classes = useStyles();
    const [start, setStart] = React.useState(null);
    const [end, setEnd] = React.useState(null);

    const handleDiff = React.useCallback(
        (start, end) => {
            const s = moment(start);
            const e = moment(end);
            const diff = e.diff(s, 'months', true);

            if (onDiffChange) onDiffChange(diff);
        },
        [onDiffChange]
    );

    const handleStartChange = React.useCallback(
        (date, value) => {
            const d = date.startOf('day');
            setValue('startDate', d);
            setStart(d);
            handleDiff(d, end);
        },
        [end, setValue, handleDiff]
    );

    const handleEndChange = React.useCallback(
        (date, value) => {
            const d = date.startOf('days');
            setValue('endDate', d);
            setEnd(d);
            handleDiff(start, d);
        },
        [start, handleDiff, setValue]
    );

    React.useEffect(() => {
        setStart(defaultValues.startDate || null);
        setEnd(defaultValues.endDate || null);
    }, [defaultValues]);

    return (
        <Grid container justify="center" style={{ marginTop: '16px' }}>
            <Grid item xs={12} sm={6} className={classes.dateGridItem}>
                <TextBlock textBlock={texts.dateStart} />
                <div style={{ textAlign: 'center' }}>
                    <KeyboardDatePicker
                        margin="normal"
                        label="Anfang"
                        format="DD.MM.YYYY"
                        value={start}
                        onChange={handleStartChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disablePast
                        inputVariant="outlined"
                        invalidLabel="Datum ungültig"
                        invalidDateMessage="Datumsformat ungültig"
                        error={!!errors.startDate?.message}
                        cancelLabel="Schließen"
                        minDate={getFirstValidDate()}
                        minDateMessage="Datum ungültig"
                    />
                    {errors.startDate && (
                        <Typography
                            style={{ textAlign: 'center', color: '#f44336' }}
                        >
                            {errors.startDate.message}
                        </Typography>
                    )}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.dateGridItem}>
                <TextBlock
                    textBlock={
                        defaultValues.isValueChain
                            ? texts.dateEnd
                            : texts.dateEndSingle
                    }
                />{' '}
                <div style={{ textAlign: 'center' }}>
                    <KeyboardDatePicker
                        margin="normal"
                        label="Ende"
                        format="DD.MM.YYYY"
                        value={end}
                        onChange={handleEndChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disablePast
                        inputVariant="outlined"
                        invalidLabel="Datum ungültig"
                        invalidDateMessage="Datumsformat ungültig"
                        error={!!errors.endDate?.message}
                        cancelLabel="Schließen"
                        minDate={getFirstValidDate()}
                        minDateMessage="Datum ungültig"
                    />
                    {errors.endDate && (
                        <Typography
                            style={{ textAlign: 'center', color: '#f44336' }}
                        >
                            {errors.endDate.message}
                        </Typography>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">
                    <TextBlock textBlock={texts.config.date.hint} />
                </Alert>
            </Grid>
            {showPeriodHint && (
                <Grid item xs={12}>
                    <Alert severity="warning" style={{ marginTop: 12 }}>
                        <Typography variant="body2">
                            {texts.diff.lessThanTwoMonthError}
                        </Typography>
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

Digi2ProjectPeriod.propTypes = {
    setValue: PropTypes.func.isRequired,
    onDiffChange: PropTypes.func,
    errors: PropTypes.object,
    defaultValues: PropTypes.object,
};

Digi2ProjectPeriod.defaultProps = {
    errors: {},
    defaultValues: {},
};

export default Digi2ProjectPeriod;
