import moment from 'moment';

export function fetchSixMutator(data) {
    data.startDate = moment(data.startDate);
    data.endDate = moment(data.endDate);

    const targets = [];

    Object.keys(data.targets).forEach((key) => {
        if (!data.targets[key]) return;
        switch (key) {
            case 'digitizeModel':
                targets.push(
                    'Geschäftsmodell digitalisieren und digitale Geschäftsfelder (= digitale Produkte und Dienstleistungen) entwickeln bzw. optimieren'
                );
                break;
            case 'digitalTechnologies':
                targets.push(
                    'Einsatz digitaler Technologien zur Optimierung von Unternehmens- und Geschäftsprozessen'
                );
                break;

            case 'digitalWork':
                targets.push('Digitales Arbeiten ausbauen');
                break;

            case 'employeeCompetence':
                targets.push(
                    'Erhöhung der Kompetenzen und Fertigkeiten von Mitarbeitenden im Umgang mit digitalen Technologien'
                );
                break;

            case 'security':
                targets.push(
                    'Erhöhung der IT-Sicherheit (Datensicherheit und -schutz)'
                );
                break;

            case 'digitalNetworking':
                targets.push(
                    'Verbesserung der digitalen Vernetzung entlang oder innerhalb einer Wertschöpfungskette'
                );
                break;

            default:
                return null;
        }
    });

    if (data.targetsOther) targets.push(`Sonstiges: ${data.targetsOther}`);
    data.targetsNew = targets;
    return data;
}
