import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextBlock from '../../../TextBlock/TextBlock';

const RenderTableHead = (props) => {
    const { columns } = props;

    return (
        <TableRow>
            {columns.map((column, id) => (
                <React.Fragment key={`finance_table_header_${id}`}>
                    {column.label.paragraphs && (
                        <TableCell>
                            <TextBlock
                                style={column.style}
                                textBlock={column.label}
                            />
                        </TableCell>
                    )}
                    {!column.label.paragraphs && (
                        <TableCell style={column.style}>
                            {column.label}
                        </TableCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};

RenderTableHead.propTypes = {
    columns: PropTypes.array,
};

export default RenderTableHead;
