import React from 'react';
import { texts as ppt } from '../../texts/PrivacyPolicy/PrivacyPolicyTexts';
import LinkOtherPage from '../../components/TextBlock/components/LinkOtherPage';
import TextBlock from '../../components/TextBlock/TextBlock';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
}));

const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part0}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part1}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part2}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part3}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part4}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part5}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part6}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
            <div className={classes.mt}>
                <TextBlock
                    textBlock={ppt.part7}
                    headlineVariant="h4"
                    parserOptions={{
                        components: {
                            a: {
                                component: LinkOtherPage,
                            },
                        },
                    }}
                />
            </div>
        </>
    );
};

export default PrivacyPolicy;
