import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { texts as vcft } from '../../../texts/ValueChainFormTexts';

const useStyles = makeStyles((theme) => ({
    flex: {
        width: '100%',
        display: 'flex',
        '& *': {
            flex: '1 1 auto',
        },
        '& div:nth-child(0)': {
            marginRight: theme.spacing(2),
        },
        '& div:nth-child(1)': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '& button': {
            marginLeft: theme.spacing(2),
            flexShrink: '2',
        },
    },
    'mb-2': {
        marginBottom: theme.spacing(2),
    },
    mt: {
        marginTop: theme.spacing(4),
    },
    mtx2: {
        marginTop: theme.spacing(8),
    },
}));

function getFieldError(errors, index, groupName) {
    if (!_.isArray(errors[groupName]))
        return { error: false, message: undefined };
    if (
        errors[groupName][index] === null ||
        errors[groupName][index] === undefined
    )
        return { error: false, message: undefined };
    return {
        error: true,
        message: errors[groupName][index],
    };
}

const ValueChainCompanies = (props) => {
    const classes = useStyles();
    const { control, register, errors } = props;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'linkedCompanies',
    });

    return (
        <>
            <Typography
                component="h3"
                variant="h5"
                gutterBottom
                className={classes.mt}
            >
                {fields.length === 0
                    ? vcft.vcCompanies.buttons.noCompany
                    : vcft.vcCompanies.buttons.atLeastOneCompany}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {vcft.vcCompanies.desc}
            </Typography>

            {errors.linkedCompanies && (
                <Grid item xs={12}>
                    <Typography color={'error'}>
                        {errors.linkedCompanies.message}
                    </Typography>
                </Grid>
            )}
            {fields.map((item, idx) => {
                const err = getFieldError(errors, idx, 'linkedCompanies');
                return (
                    <div className={classes.flex} key={item.id}>
                        <TextField
                            fullWidth
                            name={`linkedCompanies[${idx}].name`}
                            inputRef={register({
                                required: vcft.vcCompanies.fields.name.error,
                            })}
                            defaultValue={item.name}
                            error={err.error}
                            className={classes['mb-2']}
                            label={`${vcft.vcCompanies.fields.name.label} #${
                                idx + 1
                            }`}
                        />

                        <TextField
                            fullWidth
                            name={`linkedCompanies[${idx}].location`}
                            inputRef={register({
                                required:
                                    vcft.vcCompanies.fields.location.error,
                            })}
                            defaultValue={item.location}
                            error={err.error}
                            className={classes['mb-2']}
                            label={`${
                                vcft.vcCompanies.fields.location.label
                            } #${idx + 1}`}
                        />

                        <IconButton
                            aria-label={vcft.vcCompanies.buttons.remove(
                                idx + 1
                            )}
                            onClick={() => {
                                remove(idx);
                            }}
                            className={classes['mb-2']}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            })}
            <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => append({})}
                className={classes['mb-2']}
            >
                {vcft.vcCompanies.buttons.add}
            </Button>
        </>
    );
};

ValueChainCompanies.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

export default ValueChainCompanies;
