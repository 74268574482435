export const texts = {
    anonDashboard: [
        'Wurde Ihnen im Zuweisungsverfahren ein Antragskontingent zugeteilt, wurden Sie darüber bereits automatisch benachrichtigt. Sie können innerhalb von vier Wochen einen Antrag einreichen. Zur Antragstellung melden Sie sich bitte als Antragstellerinnen und Antragsteller mit zugewiesenem Antragskontingent im Förderportal für Digital Jetzt an und gehen auf „Antragsübersicht“.',
        'Die Antragstellung im Rahmen von Digital Jetzt erfolgt ausschließlich über dieses Förderportal. Alle notwendigen Informationen und Unterlagen werden hier eingegeben bzw. digital hochgeladen.',
        'Für die Nutzung des Tools ist einer der folgenden Browser notwendig: Mozilla Firefox, Google Chrome, Safari (ab 11.1), Microsoft Edge (ab Version 84) oder Opera. Bitte verwenden Sie die aktuelle Version des Browsers (inklusive Aktivierung von JavaScript).',
    ],
    authDashboard: {
        headline: 'Herzlich willkommen! Ihre Anmeldung war erfolgreich.',
        paragraphs: [
            {
                type: 'p',
                text: '[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet.[b]',
            },
            {
                type: 'p',
                text: '[u]Bewerbung Antragskontingent[u]',
            },
            {
                type: 'p',
                text: 'Aufgrund der Befristung der Förderrichtlinie bis zum 31.12.2023 ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.',
            },
            {
                type: 'p',
                text: '[u]Antragstellung[u]',
            },
            {
                type: 'p',
                text: 'Wurde Ihnen im Zuweisungsverfahren ein Antragskontingent zugeteilt, haben Sie unter „[a|/antrag]Antragsübersicht[a]“ die Möglichkeit einen neuen Antrag zu erstellen bzw. Ihren laufenden Antrag zu bearbeiten und einzureichen.',
            },
            {
                type: 'p',
                text: '[u]Verwendungsnachweis[u]',
            },
            {
                type: 'p',
                text: 'Ist Ihr Projekt abgeschlossen und haben Sie alle Ausgaben für Ihre Investitionsmaßnahmen beglichen, können Sie unter „Verwendungsnachweis“ den digitalen Verwendungsnachweis einreichen und die Auszahlung des Zuschusses beantragen.',
            },
        ],
    },
    authDashboard2024: {
        headline: 'Herzlich willkommen! Ihre Anmeldung war erfolgreich.',
        paragraphs: [
            {
                type: 'p',
                text: '[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet. Das Förderprogramm ist ausgelaufen und es können keine Anträge mehr gestellt werden.[b]',
            },
            {
                type: 'p',
                text: '[u]Verwendungsnachweis[u]',
            },
            {
                type: 'p',
                text: 'Ist Ihr Projekt abgeschlossen und haben Sie alle Ausgaben für Ihre Investitionsmaßnahmen beglichen, können Sie unter „Verwendungsnachweis“ den digitalen Verwendungsnachweis einreichen und die Auszahlung des Zuschusses beantragen.',
            },
        ],
    },
    authDashboardLast: () => ({
        paragraphs: [
            {
                type: 'p',
                text: 'Letzte ist jetzt.',
            },
        ],
    }),
    authDashboardFinished: {
        paragraphs: [
            {
                type: 'p',
                text: 'Es gibt keine Ziehungen mehr.',
            },
        ],
    },
    parser: {
        registerStop: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Sehr geehrte Antragstellerinnen und Antragsteller,',
                },
                {
                    type: 'p',
                    text: 'aufgrund der Vielzahl der Förderanträge für Digital Jetzt ist es für kurze Zeit nicht möglich sich im Digital Jetzt Förderportal zu registrieren und Anträge einzureichen.',
                },
                {
                    type: 'p',
                    text: 'Am [u]15. Oktober um 9:00 Uhr[u] wird ein Kontingent an neuen Registrierungen für das Antragstool freigeschaltet.',
                },
                {
                    type: 'p',
                    text: 'Das Programm Digital Jetzt ist stark nachgefragt und es gibt eine monatliche Kontingentierung für die Registrierung und Antragseinreichungen im Antragstool.',
                },
            ],
        },
        loggedOutHint: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Sehr geehrte Antragstellerinnen und Antragsteller,',
                },
                {
                    type: 'p',
                    text: 'aufgrund der Vielzahl der Förderanträge für Digital Jetzt ist es für kurze Zeit nicht möglich, sich im Digital Jetzt Förderportal zu registrieren und Anträge einzureichen. Die Antragstellung wird voraussichtlich zum 1. des Folgemonats wieder möglich sein. Den genauen Zeitpunkt erfahren Sie auf [a|http://www.bmwk.de/digitaljetzt]www.bmwk.de/digitaljetzt[a].',
                },
                {
                    type: 'p',
                    text: 'Das Förderprogramm Digital Jetzt läuft bis Ende 2023. Insgesamt stehen für das Programm 203 Millionen Euro zur Verfügung. Bis Ende 2020 stehen 40 Millionen Euro zur Verfügung.',
                },
                {
                    type: 'p',
                    text: 'Die bereits registrierten Nutzer können bis dahin weiterhin ihren Antrag im Digital Jetzt Förderportal vorbereiten und zwischenspeichern.',
                },
                {
                    type: 'p',
                    text: 'Aufgrund der Vielzahl der Förderanträge sind die Systeme dementsprechend stark ausgelastet, was zu längeren Ladezeiten führen kann. Wir arbeiten mit Hochdruck daran, die Ladezeiten zu optimieren. Bitte versuchen Sie es ggf. zu einem späteren Zeitpunkt erneut. Vielen Dank für Ihre Geduld!',
                },
            ],
        },
        loggedOut: {
            headline:
                'Förderprogramm „Digital Jetzt – Investitionsförderung für KMU“',
            paragraphs: [
                //{
                //   type: 'p',
                //   text:
                //       'Um einen Förderantrag zu stellen, müssen Sie sich erstmalig registrieren (unter dem Link „[a|/registrieren]Registrieren[a]“ in der Menüleiste). Wenn Sie bereits registriert sind, melden Sie sich mit Ihren Benutzerdaten an  (unter dem Link „[a|/anmelden]Anmelden[a]“ in der Menüleiste).',
                // },
                {
                    type: 'p',
                    text: 'Die Antragstellung im Rahmen von "Digital Jetzt" erfolgt ausschließlich über dieses Förderportal. Alle notwendigen Informationen und  Unterlagen werden hier eingeben bzw. digital hochgeladen.',
                },
                {
                    type: 'p',
                    text: 'Aufgrund der Vielzahl der Förderanträge sind die Systeme dementsprechend stark ausgelastet, was zu längeren Ladezeiten führen kann. Wir arbeiten mit Hochdruck daran, die Ladezeiten zu optimieren. Bitte versuchen Sie es ggf. zu einem späteren Zeitpunkt erneut. Vielen Dank für Ihre Geduld!',
                },
            ],
        },
        loggedIn: {
            headline: 'Herzlich willkommen! Ihre Anmeldung war erfolgreich.',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Sie können nun Ihren Antrag auf Förderung in "Digital Jetzt" stellen bzw. bearbeiten. Gehen Sie dafür auf den entsprechenden Link „[a|/antrag]Antragsübersicht[a]“ in der Menüleiste.',
                },
            ],
        },
        loggedIn2: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Bitte beachten Sie, dass ein monatliches Kontingent an Anträgen eingereicht werden kann, um die Verfügbarkeit der Fördermittel und eine angemessene Bearbeitungszeit sicherzustellen.',
                },
                {
                    type: 'p',
                    text: 'Wenn das Kontingent für den laufenden Monat erreicht ist, bleibt das Förderportal geöffnet. Sie können somit weiterhin Ihren Antrag im Förderportal erstellen bzw. bearbeiten und speichern. Der Antrag kann dann aber noch nicht abschließend eingereicht werden.',
                },
                {
                    type: 'p',
                    text: 'Neue Anträge können wieder ab dem 1. Tag des Folgemonats im Förderportal eingereicht werden.',
                },
            ],
        },
        loggedInHint: {
            headline: 'Hinweis Antragstellung:',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Die Nachfrage nach Förderung in "Digital Jetzt" ist voraussichtlich sehr hoch. Die gestellten Anträge werden in der Reihenfolge des Antragseingangs abgearbeitet.',
                },
                {
                    type: 'p',
                    text: 'Bitte beachten Sie vor Antragstellung die Informationen unter den „Wichtigen Fragen und Antworten rund um die Förderung mit "Digital Jetzt"“ auf der Webseite des Bundesministeriums für Wirtschaft und Klimaschutz:',
                },
                {
                    type: 'p',
                    text: '[a|https://www.bmwk.de/Redaktion/DE/FAQ/Digital-Jetzt/faq-digital-jetzt.html]https://www.bmwk.de/Redaktion/DE/FAQ/Digital-Jetzt/faq-digital-jetzt.html[a].',
                },
                {
                    type: 'p',
                    text: 'Aufgrund der Vielzahl der Förderanträge sind die Systeme dementsprechend stark ausgelastet, was zu längeren Ladezeiten führen kann. Wir arbeiten mit Hochdruck daran, die Ladezeiten zu optimieren. Bitte versuchen Sie es ggf. zu einem späteren Zeitpunkt erneut. Vielen Dank für Ihre Geduld!',
                },
            ],
        },
        formChangesHint: {
            headline: 'Allgemeiner Hinweis',
            paragraphs: [
                {
                    type: 'p',
                    text: '[u]Hinweis zum Digitalisierungsplan:[/u][br]Bitte überprüfen Sie vor dem Abschließen und der Einreichung des Antrags im Schritt „Digitalisierungsplan - Vorhabenbeschreibung“ den Vorhabenzeitraum auf Validität. Das Startdatum muss aufgrund der Bearbeitungszeit der Anträge mindestens zwei Monate im Verhältnis zum nächsten „ersten des Monats“ in der Zukunft liegen. Zudem darf der Antragszeitraum für Einzelunternehmen 12 Monate nicht übersteigen.',
                },
                {
                    type: 'p',
                    text: '[u]Hinweis zu De-Minimis:[/u][br]Wenn Ihr Unternehmen im gewerblichen Straßengüterverkehr tätig ist, beträgt die De-Minimis-Beihilfen-Obergrenze 100.000 €. Bitte prüfen Sie Ihre Angaben im Formular „De-Minimis“.',
                },
            ],
        },
        deactivated: {
            headline: 'Konto deaktiviert',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Ihr Konto wurde deaktiviert. Bitte wenden Sie sich bei Fragen an den Support.',
                },
            ],
        },
        notConfirmed: {
            headline: 'E-Mail nicht bestätigt',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Sie haben Ihre E-Mail noch nicht bestätigt. Sollten Sie die E-Mail nicht erhalten haben, wenden Sie sich bitte an den Support.',
                },
            ],
        },
        notApproved: {
            headline: 'E-Mail nicht bestätigt',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Sie müssen Ihre E-Mail bestätigen um mit dem Antrag fortfahren zu können. Sollten Sie keine E-Mail zur Bestätigung erhalten haben, wenden Sie sich bitte an den Support.',
                },
            ],
        },
        passwordChange: {
            headline: 'Bitte ändern Sie Ihr Passwort',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Ihr Passwort entspricht nicht (mehr) den Richtlinien für ein sicheres Passwort. Bitte ändern Sie dieses.',
                },
            ],
        },
    },
    static: {
        headlineLoggedOut: 'Digitalisierung des Mittelstands',
        headlineLoggedIn:
            'Antragsportal „Digital Jetzt – Investitionsförderung für KMU“',
    },
};
