import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Response, TYPE } from '../../../util/DataTypes/Response';
import scrollTop from '../../../util/Helper/ScrollTop';

const DisplayResponseTop = (props) => {
    const { response, onlyError, scroll } = props;

    React.useEffect(() => {
        if (!response) return;
        if (onlyError && response.type() !== TYPE.ERROR) return;
        if (scroll) scrollTop();
    }, [scroll, response, onlyError]);

    if (!response) return null;

    if (onlyError && response.type() !== TYPE.ERROR) return null;

    return <Alert severity={response.type()}>{response.message()}</Alert>;
};

DisplayResponseTop.propTypes = {
    response: PropTypes.instanceOf(Response),
    onlyError: PropTypes.bool,
    scroll: PropTypes.bool,
};

export default DisplayResponseTop;
