import React from 'react';
import PropTypes from 'prop-types';
import { TYPE } from '../../../util/DataTypes/Response';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { texts as mft } from '../../../texts/Forms/MinimisFormTexts';
import Alert from '@material-ui/lab/Alert';
import RadioButtons from '../../Partials/Form/RadioButtons';
import { Divider } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MinimisCalculation from './MinimisCalculation';
import { useStore } from './Minimis.store';
import TextBlock from '../../TextBlock/TextBlock';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomTooltip from '../../TextBlock/components/CustomTooltip';
import scrollTop from '../../../util/Helper/ScrollTop';
import infoPdf from '../../../static/files/DJ_Infoblatt_De-Minimis.pdf';

const useStyles = makeStyles((theme) => ({
    mb: {
        marginBottom: theme.spacing(2),
    },
}));

const MinimisForm = (props) => {
    const { defaultValues, formId, onSubmit, saveResponse } = props;
    const {
        control,
        register,
        setError,
        errors,
        handleSubmit,
        watch,
        trigger,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });
    const classes = useStyles();

    const { initialize, ...state } = useStore((state) => ({
        initialize: state.initialize,
        oneMainA: state.oneMainA,
        oneMainB: state.oneMainB,
        twoMainA: state.twoMainA,
        twoMainB: state.twoMainB,
        threeMainA: state.threeMainA,
        threeMainB: state.threeMainB,
        companies: state.companies,
    }));

    const isRoadTranport = watch('isRoadTransport');

    React.useEffect(() => {
        initialize(defaultValues);
    }, [defaultValues, initialize]);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data && data.violations) {
            data.violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }

        if (errors.received || errors.requested) scrollTop();
    }, [saveResponse, setError]); //eslint-disable-line

    const submitCallback = (values) => {
        onSubmit({ state, values });
    };

    const linkedCompanies = watch('linkedCompanies');
    const firstFields = watch(['received', 'requested']);
    const open =
        firstFields.received === 'true' || firstFields.requested === 'true';

    return (
        <div style={{ overflow: 'hidden', padding: '0 1px' }}>
            <form
                id={formId}
                onSubmit={(e) => {
                    clearErrors();
                    handleSubmit(submitCallback)(e);
                }}
            >
                <Typography variant="h4" component="h1">
                    {mft.title}{' '}
                    <CustomTooltip
                        interactive
                        title={<TextBlock textBlock={mft.titleTooltip} />}
                    >
                        <InfoOutlinedIcon />
                    </CustomTooltip>
                </Typography>

                <RadioButtons
                    name="linkedCompanies"
                    control={control}
                    buttons={mft.fields.linkedCompanies.buttons}
                    groupLabel={
                        <TextBlock
                            textBlock={mft.fields.linkedCompanies.text(infoPdf)}
                        />
                    }
                    defaultValue={defaultValues.received}
                    rules={mft.fields.linkedCompanies.rules}
                    errors={errors.linkedCompanies}
                />

                <Collapse in={linkedCompanies === 'false'}>
                    <Alert severity="info" style={{ marginBottom: 12 }}>
                        {mft.fields.linkedCompanies.hint}
                    </Alert>
                </Collapse>

                <Typography gutterBottom>{mft.leadingText}</Typography>

                <RadioButtons
                    groupLabel={mft.fields.received.text}
                    name="received"
                    control={control}
                    buttons={mft.fields.received.buttons}
                    errors={errors.received}
                    defaultValue={defaultValues.received}
                    rules={{ required: mft.fields.received.error }}
                />
                <RadioButtons
                    groupLabel={mft.fields.requested.text}
                    name="requested"
                    control={control}
                    buttons={mft.fields.requested.buttons}
                    errors={errors.requested}
                    defaultValue={defaultValues.requested}
                    rules={{ required: mft.fields.requested.error }}
                />
                <RadioButtons
                    groupLabel={mft.fields.goDigitalFunded.text}
                    name="goDigitalFunded"
                    control={control}
                    buttons={mft.fields.goDigitalFunded.buttons}
                    errors={errors.goDigitalFunded}
                    defaultValue={defaultValues.goDigitalFunded}
                    rules={{ required: mft.fields.goDigitalFunded.error }}
                />
                <RadioButtons
                    groupLabel={mft.fields.roadTransport.text}
                    name="isRoadTransport"
                    control={control}
                    buttons={mft.fields.roadTransport.buttons}
                    errors={errors.isRoadTransport}
                    rules={{ required: mft.fields.roadTransport.error }}
                    defaultValue={defaultValues.isRoadTransport}
                />

                <Divider style={{ margin: '8px 0' }} />

                <Collapse in={open} unmountOnExit={true}>
                    <Alert className={classes.mb} severity="info">
                        {mft.hint}
                    </Alert>

                    {errors.received && (
                        <Typography
                            style={{ color: '#f44336', marginTop: '8px' }}
                        >
                            {errors.received.message}
                        </Typography>
                    )}
                    {errors.requested && (
                        <Typography
                            style={{ color: '#f44336', marginTop: '8px' }}
                        >
                            {errors.requested.message}
                        </Typography>
                    )}

                    <MinimisCalculation
                        errors={errors}
                        register={register}
                        open={open}
                        control={control}
                        defaultValues={defaultValues}
                        fields={firstFields}
                        trigger={trigger}
                        isRoadTransport={isRoadTranport}
                    />
                </Collapse>
                {errors.isValid && (
                    <Typography
                        style={{
                            textAlign: 'center',
                            color: '#f44336',
                            marginBottom: '16px',
                        }}
                    >
                        {errors.isValid.message}
                    </Typography>
                )}
            </form>
        </div>
    );
};

MinimisForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.any,
    defaultValues: PropTypes.object,
    response: PropTypes.object,
    saveResponse: PropTypes.object,
};

export default MinimisForm;
