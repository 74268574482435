import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
    root: (props) => ({
        marginBottom: theme.spacing(props.gutter),
    }),
}));

const RHFTextField = (props) => {
    const {
        control,
        disabled,
        error,
        fullWidth,
        gutter,
        label,
        name,
        type,
        validation,
        variant,
        helperText,
        defaultValue,
    } = props;

    const classes = useStyle({ gutter });

    const hasError = Object.keys(error).length !== 0;

    return (
        <Controller
            name={name}
            as={
                <TextField
                    fullWidth={fullWidth}
                    error={hasError}
                    disabled={disabled}
                    className={classes.root}
                    label={label}
                    inputProps={{
                        'aria-label': label,
                    }}
                    helperText={hasError ? error.message : helperText}
                />
            }
            control={control}
            rules={validation}
            variant={variant}
            type={type}
            defaultValue={defaultValue}
        />
    );
};

RHFTextField.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    gutter: PropTypes.number,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    validation: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'date']),
    helperText: PropTypes.string,
    defaultValue: PropTypes.string,
};

RHFTextField.defaultProps = {
    variant: 'standard',
    type: 'text',
    validation: {},
    error: {},
    disabled: false,
    fullWidth: true,
    gutter: 2,
    defaultValue: '',
};

export default RHFTextField;
