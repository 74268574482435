//eslint-disable-next-line
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { createVerificationProof } from '../../lib/api/Verification/VerificationActions';
import { useSnackbar } from 'notistack';

const VerificationActionsContainer = (props) => {
    const { proposalId, render, verification, ...rest } = props;

    const { enqueueSnackbar } = useSnackbar();

    const { mutate, isLoading } = useMutation(
        () => createVerificationProof({ proposalId }),
        {
            onSuccess: (data) => {
                enqueueSnackbar(data.message(), { variant: 'success' });
            },
            onError: (error) => {
                enqueueSnackbar(error.message(), { variant: 'error' });
            },
        }
    );

    return render({ mutate, isLoading, verification, ...rest });
};

VerificationActionsContainer.propTypes = {
    proposalId: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    verification: PropTypes.object,
    revisionStatus: PropTypes.object,
};

export default VerificationActionsContainer;
