export const step6Texts = {
    title: 'Daten des Verwendungsnachweises',
    subtitle:
        'Hier haben Sie die Möglichkeit Ihre Daten nochmals zu kontrollieren. Sind ihre Angaben korrekt, können Sie den Verwendungsnachweis übermitteln. Möchten Sie etwas korrigieren, klicken Sie auf den "Zurück"-Button.',
    isFinalized: {
        paragraphs: [
            {
                type: 'p',
                text: 'Ich bestätige, dass das oben genannte Fördervorhaben umgesetzt und beendet wurde. Mit der Einreichung des Verwendungsnachweises gilt das Vorhaben offiziell als abgeschlossen. [b]Änderungen[b] sind anschließend nicht mehr möglich.',
            },
        ],
        rules: {
            required:
                'Bitte bestätigen Sie, dass das oben genannte Fördervorhaben umgesetzt und beendet wurde.',
        },
    },
};
