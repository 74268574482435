import React from 'react';
import { useAppState } from '../../../Stores/AppState.store';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.dark,
        boxSizing: 'border-box',
    },
    hr: {
        maxWidth: 250,
        backgroundColor: 'rgba(255,255,255,0.4)',
    },
    text: {
        color: 'white',
        opacity: '.8',
    },
    button: {
        color: 'rgba(255,255,255,0.8)',
        borderColor: 'rgba(255,255,255,0.8)',
        borderRadius: 18,
        '&:hover': {
            color: 'rgba(255,255,255,0.9)',
            borderColor: 'rgba(255,255,255,0.9)',
            backgroundColor: 'rgba(255,255,255,0.1)',
        },
    },
    margin1: {
        marginTop: theme.spacing(1),
    },
    margin4: {
        marginTop: theme.spacing(4),
    },
    marginLeft: {
        marginLeft: theme.spacing(3),
    },
    support: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(4),
    },
    preAnimate: {
        transform: 'translateY(-20px)',
        opacity: '0',
    },
    animate1: {
        animation: '$move .5s 1 .5s ease-out forwards;',
    },
    animate2: {
        animation: '$move .5s 1 .6s ease-out forwards;',
    },
    animate3: {
        animation: '$move .5s 1 .7s ease-out forwards;',
    },
    animate4: {
        animation: '$move .5s 1 .8s ease-out forwards;',
    },
    '@keyframes move': {
        '0%': {
            transform: 'translateY(-20px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateY(0px)',
            opacity: '.8',
        },
    },
}));

const RafflePotsError = (props) => {
    const rafflePotsError = useAppState((state) => state.rafflePotsError);
    const classes = useStyles();

    const handleClick = () => {
        window.location.reload();
    };

    const status = rafflePotsError.data()?.response?.status
        ? rafflePotsError.data()?.response?.status
        : '';

    return (
        <div className={classes.wrapper}>
            <div style={{ maxWidth: 650 }}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                    className={classNames(classes.animate1, classes.preAnimate)}
                >
                    {status}
                </Typography>
                <Typography
                    variant="h3"
                    className={classNames(
                        classes.text,
                        classes.margin1,
                        classes.animate2,
                        classes.preAnimate
                    )}
                >
                    Die Seite konnte nicht geladen werden.
                </Typography>
                <Divider
                    variant="middle"
                    className={classNames(classes.hr, classes.margin4)}
                />
                <Typography
                    variant="h5"
                    className={classNames(
                        classes.text,
                        classes.margin4,
                        classes.animate3,
                        classes.preAnimate
                    )}
                >
                    {rafflePotsError.message()}
                </Typography>
                <div className={classNames(classes.margin4)}>
                    <Button
                        className={classNames(
                            classes.button,
                            classes.animate4,
                            classes.preAnimate
                        )}
                        variant="outlined"
                        onClick={handleClick}
                    >
                        Neuladen
                    </Button>
                </div>
            </div>
            <div>
                <Typography
                    className={classNames(classes.text, classes.support)}
                >
                    Technischer Support 030 67055-767 / pt-webservice@dlr.de
                </Typography>
            </div>
        </div>
    );
};

RafflePotsError.propTypes = {};

export default RafflePotsError;
