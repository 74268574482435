import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogWithConfirmation from '../Partials/Dialog/DialogWithConfirmation';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    buttonBar: {
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            marginRight: theme.spacing(2),
        },
        '& button:last-child': {
            marginRight: '0',
        },
    },
}));

const WizardButtons = (props) => {
    const {
        activeStep,
        changeStep,
        formId,
        formLoading,
        maxSteps,
        saving,
        nextAfterSave,
        stepComplete,
        transitioning,
        checkingStepComplete,
    } = props;

    const classes = useStyles();

    const [showModal, setShowModal] = React.useState(false);

    const handleClose = (submitted) => {
        nextAfterSave.current = submitted;
        setShowModal(false);
    };
    const handleOpen = () => {
        setShowModal(true);
    };

    return (
        <div className={classes.buttonBar}>
            {activeStep < maxSteps - 1 && (
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => changeStep(-1)}
                    disabled={
                        activeStep === 0 ||
                        saving ||
                        formLoading ||
                        transitioning
                    }
                >
                    Zurück
                </Button>
            )}

            {activeStep < maxSteps - 2 && (
                <>
                    <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        form={formId}
                        disabled={saving || formLoading || transitioning}
                        startIcon={
                            saving ? <CircularProgress size="1rem" /> : null
                        }
                    >
                        Speichern
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        form={formId}
                        onClick={() => (nextAfterSave.current = true)}
                        disabled={saving || formLoading || transitioning}
                    >
                        Speichern & Weiter
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => changeStep(1)}
                        disabled={!stepComplete || transitioning || saving}
                        startIcon={
                            checkingStepComplete ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        Weiter
                    </Button>
                </>
            )}

            {activeStep === maxSteps - 2 && (
                <>
                    <Button
                        onClick={() => handleOpen()}
                        color="primary"
                        variant="outlined"
                        disabled={transitioning || saving}
                        startIcon={
                            saving ? <CircularProgress size="1rem" /> : null
                        }
                    >
                        Einreichen
                    </Button>
                    <DialogWithConfirmation
                        closeFunc={handleClose}
                        title="Antrag einreichen?"
                        content="Möchten Sie den Antrag unwiderruflich einreichen? Bitte beachten Sie: Nach Einreichung des Antrags haben Sie keine Möglichkeit mehr, Korrekturen in Ihrem Antrag vorzunehmen."
                        open={showModal}
                        formId={formId}
                        confirmButtonText="Einreichen"
                    />
                </>
            )}

            {activeStep === maxSteps - 1 && (
                <>
                    <Button
                        id="finalWizardButton"
                        onClick={() => handleOpen()}
                        color="primary"
                        variant="outlined"
                        disabled={transitioning || saving}
                        startIcon={
                            saving ? <CircularProgress size="1rem" /> : null
                        }
                    >
                        Abschicken
                    </Button>
                    <DialogWithConfirmation
                        closeFunc={handleClose}
                        title="Antrag abschicken?"
                        content="Bitte bestätigen Sie, dass Sie den Antrag unwiderruflich abschicken möchten."
                        open={showModal}
                        formId={formId}
                    />
                </>
            )}
        </div>
    );
};

WizardButtons.propTypes = {
    formId: PropTypes.string.isRequired,
    changeStep: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    formLoading: PropTypes.bool,
    activeStep: PropTypes.number.isRequired,
    maxSteps: PropTypes.number.isRequired,
    nextAfterSave: PropTypes.object.isRequired,
    stepComplete: PropTypes.bool,
    transitioning: PropTypes.bool,
    checkingStepComplete: PropTypes.bool,
};

export default WizardButtons;
