import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { step4Texts } from '../../../../texts/Verification/Step4Texts';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormCheckbox from '../../Components/FormCheckbox';
import FormTextArea from '../../Components/FormTextArea';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

const DescriptionModal = (props) => {
    const { data, onCancel, onSave, open, index, displayOnly, provider } =
        props;

    const handleSubmitCallback = (value) => {
        if (value[step4Texts.descriptionModal.form.changes.name] === true) {
            onSave(
                {
                    [step4Texts.descriptionModal.form.changes.name]: true,
                    [step4Texts.descriptionModal.form.providerChanged
                        .name]: false,
                    [step4Texts.descriptionModal.form.investChanged
                        .name]: false,
                },
                index
            );
            onCancel();
            return;
        }
        onSave(value, index);
        onCancel();
    };

    const { control, handleSubmit, watch, setValue, errors, register } =
        useForm({
            defaultValues: data,
        });

    const hasNoChanges = watch(step4Texts.descriptionModal.form.changes.name);
    const providerChanged = watch(
        step4Texts.descriptionModal.form.providerChanged.name
    );
    const investChanged = watch(
        step4Texts.descriptionModal.form.investChanged.name
    );

    React.useEffect(() => {
        if (hasNoChanges) {
            setValue(
                step4Texts.descriptionModal.form.providerChanged.name,
                false
            );
            setValue(
                step4Texts.descriptionModal.form.investChanged.name,
                false
            );
        }
    }, [setValue, hasNoChanges]);

    const valid = React.useMemo(() => {
        if (hasNoChanges === true) return 'valid';
        if (providerChanged === true || investChanged === true) return 'valid';
        return 'invalid';
    }, [hasNoChanges, providerChanged, investChanged]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={onCancel}>
            <form onSubmit={handleSubmit(handleSubmitCallback)}>
                <DialogTitle>{step4Texts.descriptionModal.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormCheckbox
                                control={control}
                                label={
                                    step4Texts.descriptionModal.form.changes
                                        .label
                                }
                                name={
                                    step4Texts.descriptionModal.form.changes
                                        .name
                                }
                                defaultValue={false}
                                notEditable={displayOnly}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox
                                control={control}
                                label={
                                    step4Texts.descriptionModal.form
                                        .providerChanged.label +
                                    ' (' +
                                    step4Texts.descriptionModal.form
                                        .providerChanged.previous +
                                    provider +
                                    ')'
                                }
                                name={
                                    step4Texts.descriptionModal.form
                                        .providerChanged.name
                                }
                                notEditable={hasNoChanges || displayOnly}
                                defaultValue={false}
                            />
                        </Grid>
                        {providerChanged === true && (
                            <Grid item xs={12}>
                                <FormTextArea
                                    control={control}
                                    name={
                                        step4Texts.descriptionModal.form
                                            .providerName.name
                                    }
                                    label={
                                        step4Texts.descriptionModal.form
                                            .providerName.label
                                    }
                                    multiline={false}
                                    rules={
                                        step4Texts.descriptionModal.form
                                            .providerName.rules
                                    }
                                    notEditable={displayOnly}
                                    error={
                                        errors[
                                            step4Texts.descriptionModal.form
                                                .providerName.name
                                        ]
                                    }
                                />
                                <FormTextArea
                                    control={control}
                                    name={
                                        step4Texts.descriptionModal.form
                                            .providerDesc.name
                                    }
                                    label={
                                        step4Texts.descriptionModal.form
                                            .providerDesc.label
                                    }
                                    multiline={true}
                                    maxLength={1500}
                                    rules={
                                        step4Texts.descriptionModal.form
                                            .providerDesc.rules
                                    }
                                    notEditable={displayOnly}
                                    error={
                                        errors[
                                            step4Texts.descriptionModal.form
                                                .providerDesc.name
                                        ]
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormCheckbox
                                control={control}
                                label={
                                    step4Texts.descriptionModal.form
                                        .investChanged.label
                                }
                                name={
                                    step4Texts.descriptionModal.form
                                        .investChanged.name
                                }
                                notEditable={hasNoChanges || displayOnly}
                                defaultValue={false}
                            />
                        </Grid>
                        {investChanged && (
                            <Grid item xs={12}>
                                <FormTextArea
                                    control={control}
                                    name={
                                        step4Texts.descriptionModal.form
                                            .investName.name
                                    }
                                    label={
                                        step4Texts.descriptionModal.form
                                            .investName.label
                                    }
                                    multiline={true}
                                    maxLength={1500}
                                    rules={
                                        step4Texts.descriptionModal.form
                                            .investName.rules
                                    }
                                    notEditable={displayOnly}
                                    error={
                                        errors[
                                            step4Texts.descriptionModal.form
                                                .investName.name
                                        ]
                                    }
                                />
                                <FormTextArea
                                    control={control}
                                    multiline={true}
                                    maxLength={1500}
                                    name={
                                        step4Texts.descriptionModal.form
                                            .investDesc.name
                                    }
                                    rules={
                                        step4Texts.descriptionModal.form
                                            .investDesc.rules
                                    }
                                    label={
                                        step4Texts.descriptionModal.form
                                            .investDesc.label
                                    }
                                    notEditable={displayOnly}
                                    error={
                                        errors[
                                            step4Texts.descriptionModal.form
                                                .investDesc.name
                                        ]
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <input
                                type="hidden"
                                value={valid}
                                ref={register(
                                    step4Texts.descriptionModal.form.valid.rules
                                )}
                                name={
                                    step4Texts.descriptionModal.form.valid.name
                                }
                            />
                            {valid !== 'valid' && (
                                <Typography style={{ color: red[500] }}>
                                    {
                                        step4Texts.descriptionModal.form.valid
                                            .message
                                    }
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        variant="outlined"
                        color="primary"
                    >
                        {step4Texts.descriptionModal.buttonCancel}
                    </Button>
                    {!displayOnly && (
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            color="primary"
                        >
                            {step4Texts.descriptionModal.buttonSave}
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

DescriptionModal.propTypes = {
    data: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    index: PropTypes.number,
    displayOnly: PropTypes.bool,
    provider: PropTypes.string,
};

export default DescriptionModal;
