import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Step, StepButton, Stepper } from '@material-ui/core';
import { verificationConfig } from './VerificationConfig';
import Divider from '@material-ui/core/Divider';
import LockIcon from '@material-ui/icons/Lock';
import grey from '@material-ui/core/colors/grey';

const VerificationControls = (props) => {
    const {
        children,
        data,
        onCurrentStepChange,
        currentStep,
        isTransitioning,
        transition,
        displayMode,
    } = props;

    const handleStep = React.useCallback(
        (toStep) => onCurrentStepChange(toStep),
        [onCurrentStepChange]
    );

    const buttons = React.useMemo(
        () =>
            verificationConfig.map((el, idx) => (
                <Step key={el.key}>
                    <StepButton
                        alternativeLabel
                        disabled={
                            !displayMode &&
                            (isTransitioning || el.isDisabled(transition))
                        }
                        onClick={() => handleStep(idx)}
                        completed={el.isComplete(data) && !displayMode}
                        icon={
                            el.isDisabled(transition) && !displayMode ? (
                                <LockIcon style={{ color: grey[500] }} />
                            ) : (
                                `${idx + 1}`
                            )
                        }
                    >
                        {el.label}
                    </StepButton>
                </Step>
            )),
        [data, handleStep, isTransitioning, transition, displayMode]
    );

    return (
        <div>
            <Stepper nonLinear activeStep={currentStep}>
                {buttons}
            </Stepper>
            {isTransitioning && <LinearProgress />}
            <Divider variant="middle" />
            <div style={{ padding: '24px' }}>{children}</div>
            <Divider variant="middle" />
            {isTransitioning && <LinearProgress />}
            <Stepper nonLinear activeStep={currentStep}>
                {buttons}
            </Stepper>
        </div>
    );
};

VerificationControls.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
    currentStep: PropTypes.number,
    onCurrentStepChange: PropTypes.func,
    isTransitioning: PropTypes.bool,
    transition: PropTypes.string,
    displayMode: PropTypes.bool,
};

VerificationControls.defaultProps = {
    displayMode: false,
};

export default VerificationControls;
