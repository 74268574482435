import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from './Minimis.store';
import MinimisTable from './MinimisTable';
import { mutateMinimiYear } from '../../../util/Helper/MinimiYearMutation';

const MinimisMainTableView = (props) => {
    const { ab, year, title } = props;

    const { data, createNewMain, removeMain, changeMain } = useStore(
        (state) => ({
            data: state[`${year}Main${ab.toUpperCase()}`],
            createNewMain: state.createNewMain,
            removeMain: state.removeMain,
            changeMain: state.changeMain,
        })
    );

    const [editRowIndex, setEditRowIndex] = React.useState(-1);
    const [newRow, setNewRow] = React.useState(null);

    const handleAddNewRow = () => {
        setNewRow({});
    };

    const handleDeleteRow = (index) => () => {
        removeMain(year, ab, index);
    };

    const handleEditRow = (index) => () => {
        setEditRowIndex(index);
    };

    const handleOnAbort = () => {
        setEditRowIndex(-1);
        if (newRow) setNewRow(null);
    };

    const handleSaveOnNewRow = (values) => {
        createNewMain(year, ab, values);
        setEditRowIndex(-1);
        setNewRow(null);
    };

    const handleSaveOnUpdate = (year, ab) => (index) => (values) => {
        changeMain(year, ab, index, values);
        setEditRowIndex(-1);
    };

    return (
        <MinimisTable
            data={data}
            editRowIndex={editRowIndex}
            handleAddNewRow={handleAddNewRow}
            handleSaveOnNewRow={handleSaveOnNewRow}
            handleOnAbort={handleOnAbort}
            handleSaveOnUpdate={handleSaveOnUpdate(year, ab)}
            handleDeleteRow={handleDeleteRow}
            handleEditRow={handleEditRow}
            newRow={newRow}
            title={title}
            year={mutateMinimiYear(year)}
            ab={ab}
        />
    );
};

MinimisMainTableView.propTypes = {
    year: PropTypes.oneOf(['one', 'two', 'three']).isRequired,
    ab: PropTypes.oneOf(['A', 'B', 'a', 'b']).isRequired,
    title: PropTypes.string,
};

export default MinimisMainTableView;
