import { isDefined } from '../../../util/Validation/ValidateDefined';

export function fetchMutator(data) {
    data.isValueChain =
        isDefined(data.isValueChain) && data.isValueChain === true
            ? 'true'
            : 'false';
    data.isLead =
        isDefined(data.isLead) && data.isLead === true ? 'true' : 'false';

    return data;
}

export function saveMutator(data) {
    data.isValueChain = data.isValueChain === 'true';

    if (isDefined(data.isLead)) {
        data.isLead = data.isLead === 'true';
    }

    if (!data.isValueChain) {
        data.isLead = false;
        data.linkedCompanies = [];
        return data;
    }

    if (!data.isLead) {
        data.linkedCompanies = [];
        return data;
    }

    data.leadCode = null;

    return data;
}
