export const texts = {
    headline: 'Fragen und Antworten',
    categories: [
        {
            title: 'Benutzerverwaltung',
            questions: [
                {
                    title: 'Wie kann ich ein neues Passwort erzeugen?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Über die Funktion [a|/oauth/passwort-zuruecksetzen]"Passwort vergessen"[a] können Sie jederzeit ein neues Passwort für Ihren Account vergeben.',
                            },
                        ],
                    },
                },
                {
                    title: 'Warum kann ich mich nicht mehr registrieren?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Förderrichtlinie „Digital Jetzt – Investitionsförderung für KMU“ ist bis zum 31.12.2023 befristet. Eine Registrierung zur Bewerbung um ein Antragskontingent ist nicht mehr möglich.',
                            },
                        ],
                    },
                },
                {
                    title: 'Wie gehe ich vor, wenn ich Daten, wie z.B. meine E-Mail-Adresse für die Registrierung ändern möchte?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Bitte wenden Sie sich für eine Änderung jeglicher Daten an den inhaltlichen Support: [a|mailto:digitaljetzt@dlr.de]digitaljetzt@dlr.de[a].',
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Antragskontingentvergabe',
            questions: [
                {
                    title: 'Wann kann ich mich auf ein Antragskontingent bewerben?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Förderrichtlinie „Digital Jetzt – Investitionsförderung für KMU“ ist bis zum 31.12.2023 befristet. Eine Registrierung zur Bewerbung um ein Antragskontingent ist nicht mehr möglich.',
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Antragstellung',
            questions: [
                {
                    title: 'Wann kann ich meinen Antrag einreichen?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Nach der erfolgreichen Zuweisung eines Antragskontingents können Sie ab dem 02. eines Monats den Antrag vorbereiten und innerhalb von ca. vier Wochen einreichen. Die letzte Antragseinreichung wird voraussichtlich im November 2023 stattfinden.',
                            },
                            {
                                type: 'p',
                                text: 'Für alle Fragen rund um Ihren Antrag, wenden Sie sich bitte an den inhaltlichen Support: [a|mailto:digitaljetzt@dlr.de]digitaljetzt@dlr.de[a].',
                            },
                        ],
                    },
                },
                {
                    title: 'Wann kann ich meinen Antrag mit meinem Personalausweis signieren?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Das Förderportal unterstützt derzeit keine elektronische Unterschrift via Personalausweis. Bitte nutzen Sie für eine digitale Signatur entweder das sign-me Verfahren der D-Trust GmbH oder die D-TRUST Signaturkarte.',
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Fehlermeldungen',
            questions: [
                {
                    title: 'Was mache ich, wenn ein technischer Fehler auftritt?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Digital Jetzt unterstützt die Browser Mozilla Firefox, Google Chrome, Microsoft Edge (ab Version 84) oder Opera) in aktuellsten Version und ist auf die Desktop-Nutzung optimiert. Bei einer Fehlermeldung oder veralten Anzeige, löschen Sie bitte zunächst den Browsercache, die Cookies und starten Ihren Browser neu.',
                            },
                            {
                                type: 'p',
                                text: 'Tritt der Fehler immer noch auf, senden Sie uns Ihre Fehlerbeschreibung gerne an den technischen Support: [a|mailto:pt-webservice@dlr.de]pt-webservice@dlr.de[a]',
                            },
                        ],
                    },
                },
            ],
        },
    ],
};

export const texts2024 = {
    headline: 'Fragen und Antworten',
    categories: [
        {
            title: 'Benutzerverwaltung',
            questions: [
                {
                    title: 'Wie kann ich ein neues Passwort erzeugen?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Über die Funktion [a|/oauth/passwort-zuruecksetzen]"Passwort vergessen"[a] können Sie jederzeit ein neues Passwort für Ihren Account vergeben.',
                            },
                        ],
                    },
                },
                {
                    title: 'Warum kann ich mich nicht mehr registrieren?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Förderrichtlinie „Digital Jetzt – Investitionsförderung für KMU“ ist bis zum 31.12.2023 befristet. Eine Registrierung zur Bewerbung um ein Antragskontingent ist nicht mehr möglich.',
                            },
                        ],
                    },
                },
                {
                    title: 'Wie gehe ich vor, wenn ich Daten, wie z.B. meine E-Mail-Adresse für die Registrierung ändern möchte?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Bitte wenden Sie sich für eine Änderung jeglicher Daten an den inhaltlichen Support: [a|mailto:digitaljetzt@dlr.de]digitaljetzt@dlr.de[a].',
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Antragstellung / Einreichung Verwendungsnachweis',
            questions: [
                {
                    title: 'Ist eine Antragsstellung noch möglich?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Förderrichtlinie „Digital Jetzt - Investitionsförderung für KMU" ist bis zum 31.12.2023 befristet. Eine Antragsstellung ist nicht mehr möglich.',
                            },
                            {
                                type: 'p',
                                text: 'Für Fragen, wenden Sie sich bitte an den inhaltlichen Support: [a|mailto:digitaljetzt@dlr.de]digitaljetzt@dlr.de[a].',
                            },
                        ],
                    },
                },
                {
                    title: 'Wann kann ich meinen Verwendungsnachweis mit meinem Personalausweis signieren?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Das Förderportal unterstützt derzeit keine elektronische Unterschrift via Personalausweis. Bitte nutzen Sie für eine digitale Signatur entweder das sign-me Verfahren der D-Trust GmbH oder die D-TRUST Signaturkarte.',
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Fehlermeldungen',
            questions: [
                {
                    title: 'Was mache ich, wenn ein technischer Fehler auftritt?',
                    answer: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Digital Jetzt unterstützt die Browser Mozilla Firefox, Google Chrome, Microsoft Edge (ab Version 84) oder Opera) in aktuellsten Version und ist auf die Desktop-Nutzung optimiert. Bei einer Fehlermeldung oder veralten Anzeige, löschen Sie bitte zunächst den Browsercache, die Cookies und starten Ihren Browser neu.',
                            },
                            {
                                type: 'p',
                                text: 'Tritt der Fehler immer noch auf, senden Sie uns Ihre Fehlerbeschreibung gerne an den technischen Support: [a|mailto:pt-webservice@dlr.de]pt-webservice@dlr.de[a]',
                            },
                        ],
                    },
                },
            ],
        },
    ],
};
