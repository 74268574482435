import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { texts as ppt } from '../../texts/Pages/PagesTexts';

const NotFound = () => {
    return (
        <React.Fragment>
            <Typography
                variant="h3"
                component="h1"
                style={{ marginBottom: '1rem' }}
            >
                404 - {ppt.not_found.headline}
            </Typography>
            <Link to={'/anmelden'}>
                <Button color="primary" variant="contained">
                    {ppt.not_found.button}
                </Button>
            </Link>
        </React.Fragment>
    );
};

export default NotFound;
