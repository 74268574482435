import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Avatar, CircularProgress, TableCell } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import FinancialTableRow from './FinancialTableRow';
import CollapsibleRow from './CollapsibleRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UploadModal from './UploadModal';
import BillInformationModal from './BillInformationModal';
import DescriptionModal from './DescriptionModal';
import _ from 'lodash';
import TableFooter from '@material-ui/core/TableFooter';
import FormFooter from '../FormFooter';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dinero from 'dinero.js';
import { useSnackbar } from 'notistack';
import Outline from '../../../Partials/Info/Outline';
import { step4Texts } from '../../../../texts/Verification/Step4Texts';
import FormRadio from '../../Components/FormRadio';
import { FORM_TRANSFORMER } from '../../../../util/Helper/FormTransformer';
import FormMoney from '../../Components/FormMoney';
import { Alert } from '@material-ui/lab';
import useVerificationPatch from '../../../../hooks/Verification/useVerificationPatch';
import { verifyRow } from '../../../../util/Verification/ValidateTableRow';
import FormTextArea from '../../Components/FormTextArea';

export const MODAL_TYPE = {
    NONE: 'NONE',
    UPLOAD: 'UPLOAD',
    BILLING: 'BILLING',
    DESCRIPTION: 'DESC',
};

const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
    },
}));

const createDefaultValues = (data, formData) => {
    const defaultValues = {
        money: [],
        paid: [],
        [step4Texts.additionalFunding.radio.name]:
            formData[step4Texts.additionalFunding.radio.name],
        [step4Texts.additionalFunding.textfield.name]:
            formData[step4Texts.additionalFunding.textfield.name],
        [step4Texts.additionalFunding.desc.name]:
            formData[step4Texts.additionalFunding.desc.name],
        accepted: [],
    };

    data.forEach((element) => {
        defaultValues.money.push(element.invoiceTotal);
        defaultValues.paid.push(
            '' +
                element.payments.reduce(
                    (acc, cur) => acc + parseInt(cur.sum),
                    0
                )
        );
        defaultValues.accepted.push(
            typeof verifyRow(
                element.invoiceTotal,
                element.payments,
                element.explanation
            ) === 'boolean'
                ? 'true'
                : 'false'
        );
    });

    return defaultValues;
};

const FinancialPlanTableOld = (props) => {
    const { tableData, displayOnly, verificationId, onStepChange, formData } =
        props;
    const classes = useRowStyles();

    const [modal, setModal] = React.useState({
        index: -1,
        type: MODAL_TYPE.NONE,
    });
    const [extendedRow, setExtendedRow] = React.useState(-1);
    const [tmpTableData, setTmpTableData] = React.useState(
        _.cloneDeep(tableData)
    );

    const { control, handleSubmit, getValues, setValue, watch, errors } =
        useForm({
            defaultValues: createDefaultValues(tmpTableData, formData),
        });

    const { enqueueSnackbar } = useSnackbar();

    const onRowExtend = React.useCallback(
        (index) => {
            if (extendedRow === index) {
                setExtendedRow(-1);
                return;
            }
            setExtendedRow(index);
        },
        [extendedRow, setExtendedRow]
    );

    const onModalSet = React.useCallback(
        (idx, type = MODAL_TYPE.NONE) => {
            setModal({ index: idx, type });
        },
        [setModal]
    );

    const onModalClose = React.useCallback(() => {
        onModalSet(-1);
    }, [onModalSet]);

    const onPaymentChange = React.useCallback(
        (payments, index) => {
            const tmp = [...tmpTableData];
            tmp[index].payments = payments;
            setTmpTableData(tmp);

            const total = payments.reduce(
                (acc, cur) => acc + parseInt(cur.sum),
                0
            );
            setValue(`paid[${index}]`, '' + total);
        },
        [setValue, setTmpTableData, tmpTableData]
    );

    const onExplanationChange = React.useCallback(
        (exp, index) => {
            const tmp = [...tmpTableData];
            tmp[index].explanation = exp;
            setTmpTableData(tmp);
        },
        [setTmpTableData, tmpTableData]
    );

    const paid = watch('paid');
    const money = watch('money');
    const additionalFunding = watch(step4Texts.additionalFunding.radio.name);

    const totalPaid = React.useMemo(() => {
        const sum = paid.reduce((acc, cur) => acc + parseInt(cur), 0);
        return {
            str: new Dinero({
                amount: sum,
            }).toFormat(),
            num: sum,
        };
    }, [paid]);

    const totalInvoice = React.useMemo(
        () =>
            new Dinero({
                amount: money.reduce(
                    (acc, cur) => acc + (parseInt(cur) || 0),
                    0
                ),
            }).toFormat(),
        [money]
    );

    const totalSum = React.useMemo(
        () =>
            new Dinero({
                amount: tableData.reduce(
                    (acc, cur) => acc + parseInt(cur.totalSum),
                    0
                ),
            }).toFormat(),
        [tableData]
    );

    const onFinish = (data) => {
        enqueueSnackbar(data.message(), { variant: data.type() });
    };

    const { mutate: mutatePatch, isSaving } = useVerificationPatch({
        onSuccess: onFinish,
        onError: onFinish,
    });

    const onSubmitCallback = (values) => {
        const putData = tmpTableData.map((el, idx) => ({
            ...el,
            proof:
                el.proof.indexOf('/api') === 0
                    ? el.proof
                    : `/api/proofs/${el.proof}`,
            verification: `/api/verifications/${verificationId}`,
            invoiceTotal: values.money[idx],
            explanation: { ...el.explanation },
            payments: [...el.payments],
        }));

        mutatePatch({
            data: {
                hasAdditionalFunding:
                    values[step4Texts.additionalFunding.radio.name],
                additionalFunding:
                    values[step4Texts.additionalFunding.radio.name] === true
                        ? values[step4Texts.additionalFunding.textfield.name]
                        : null,
                additionalComment:
                    values[step4Texts.additionalFunding.radio.name] === true
                        ? values[step4Texts.additionalFunding.desc.name]
                        : null,
                verificationProofs: putData,
            },
            success: 'Finanzierungsplan erfolgreich gespeichert.',
            id: verificationId,
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitCallback)}>
                <Outline gutterTop>
                    <Table size="small">
                        <TableHead>
                            <TableRow className={classes.root}>
                                <TableCell colSpan={4} />
                                <TableCell>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar className={classes.avatar}>
                                            <Filter1Icon />
                                        </Avatar>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar className={classes.avatar}>
                                            <Filter2Icon />
                                        </Avatar>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar className={classes.avatar}>
                                            <Filter3Icon />
                                        </Avatar>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar className={classes.avatar}>
                                            <Filter4Icon />
                                        </Avatar>
                                    </div>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell />

                                <TableCell>Nr.</TableCell>
                                <TableCell>Nachweisname</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    bewilligter Betrag p. Nachweis
                                </TableCell>
                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                    }}
                                >
                                    Rechnungsbetrag
                                </TableCell>
                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                    }}
                                >
                                    Gezahlte Summe
                                </TableCell>
                                <TableCell
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                    }}
                                >
                                    Erläuterung
                                </TableCell>
                                <TableCell>Speichern</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tmpTableData.map((el, idx) => (
                                <React.Fragment key={el.id}>
                                    <FinancialTableRow
                                        rowData={el}
                                        rowIndex={idx}
                                        onRowExtendClick={onRowExtend}
                                        control={control}
                                        setValue={setValue}
                                        onActionClick={onModalSet}
                                        isRowExtended={extendedRow === idx}
                                        displayOnly={displayOnly}
                                        getValues={getValues}
                                        watch={watch}
                                        error={
                                            errors.accepted?.[idx] || undefined
                                        }
                                        verificationId={verificationId}
                                    />
                                    <CollapsibleRow
                                        rowData={el.proofs}
                                        open={extendedRow === idx}
                                        proofId={el.proof}
                                        description={el.description}
                                    />
                                </React.Fragment>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow className={classes.root}>
                                <TableCell colSpan={3} />
                                <TableCell>
                                    <Typography>{totalSum}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{totalInvoice}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{totalPaid.str}</Typography>
                                </TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Outline>
                <Outline
                    subtitle={step4Texts.additionalFunding.title}
                    gutterTop
                >
                    {!displayOnly && (
                        <Alert severity="info">
                            <Typography>
                                {step4Texts.additionalFunding.hint}
                            </Typography>
                        </Alert>
                    )}
                    <FormRadio
                        rules={step4Texts.additionalFunding.radio.rules}
                        error={errors[step4Texts.additionalFunding.radio.name]}
                        notEditable={displayOnly}
                        name={step4Texts.additionalFunding.radio.name}
                        buttons={step4Texts.additionalFunding.radio.buttons}
                        control={control}
                        {...FORM_TRANSFORMER.radioBool}
                    />

                    {additionalFunding && (
                        <>
                            <FormMoney
                                name={
                                    step4Texts.additionalFunding.textfield.name
                                }
                                control={control}
                                notEditable={displayOnly}
                                error={
                                    errors[
                                        step4Texts.additionalFunding.textfield
                                            .name
                                    ]
                                }
                                rules={{
                                    ...step4Texts.additionalFunding.textfield
                                        .rules,
                                    validate: (value) => {
                                        return (
                                            parseInt(value) <= totalPaid.num ||
                                            'Die angegebenen Deckungsmittel können nicht höher als die gesamt gezahlte Summe sein'
                                        );
                                    },
                                }}
                                label={
                                    step4Texts.additionalFunding.textfield.label
                                }
                            />
                            <FormTextArea
                                name={step4Texts.additionalFunding.desc.name}
                                control={control}
                                notEditable={displayOnly}
                                rules={step4Texts.additionalFunding.desc.rules}
                                error={
                                    errors[
                                        step4Texts.additionalFunding.desc.name
                                    ]
                                }
                                label={step4Texts.additionalFunding.desc.label}
                            />
                        </>
                    )}
                </Outline>

                {!displayOnly && (
                    <Alert severity="info" style={{ marginTop: 16 }}>
                        <Typography>{step4Texts.hint}</Typography>
                    </Alert>
                )}

                {!displayOnly && (
                    <FormFooter
                        displayText={Object.keys(errors).length > 0}
                        text={step4Texts.formError}
                    >
                        <Button
                            type="submit"
                            color="primary"
                            disableElevation
                            variant="contained"
                            disabled={isSaving}
                        >
                            {isSaving && (
                                <CircularProgress
                                    size="1rem"
                                    style={{ marginRight: 8 }}
                                />
                            )}
                            Speichern
                        </Button>
                        <Button
                            style={{ marginLeft: 8 }}
                            color="primary"
                            disableElevation
                            variant="contained"
                            onClick={() => onStepChange(4, 'edit')}
                            disabled={isSaving}
                        >
                            Abschließen
                        </Button>
                    </FormFooter>
                )}
            </form>

            {modal.type === MODAL_TYPE.UPLOAD && (
                <UploadModal
                    proofId={tmpTableData[modal.index].proof}
                    open={true}
                    verificationId={verificationId}
                    index={modal.index}
                    onCancel={onModalClose}
                    displayOnly={displayOnly}
                />
            )}

            {modal.type === MODAL_TYPE.BILLING && (
                <BillInformationModal
                    open={true}
                    data={tmpTableData[modal.index].payments}
                    max={parseInt(getValues(`money[${modal.index}]`))}
                    onClose={onModalClose}
                    onSave={onPaymentChange}
                    index={modal.index}
                    displayOnly={displayOnly}
                />
            )}

            {modal.type === MODAL_TYPE.DESCRIPTION && (
                <DescriptionModal
                    open={true}
                    data={tmpTableData[modal.index].explanation}
                    onSave={onExplanationChange}
                    onCancel={onModalClose}
                    index={modal.index}
                    displayOnly={displayOnly}
                    provider={tmpTableData[modal.index].provider}
                />
            )}
        </div>
    );
};

FinancialPlanTableOld.propTypes = {
    tableData: PropTypes.array,
    displayOnly: PropTypes.bool,
    verificationId: PropTypes.string,
    onDirtyChange: PropTypes.func,
    onStepChange: PropTypes.func,
};

export default FinancialPlanTableOld;
