export function verifyRow(invoiceTotal, paidTotal, explanation) {
    if (!invoiceTotal) return 'Bitte geben Sie einen Rechnungsbetrag (1) an.';

    const invoice = parseInt(invoiceTotal);
    const paid = parseInt(paidTotal) || 0;

    if (paid > invoice) return 'Bitte überprüfen Sie die Zahlungsdaten (2).';

    if (!explanation) return 'Bitte überprüfen Sie die Erläuterung (3).';

    if (explanation && !explanation.notChanged) {
        if (
            explanation.providerChanged &&
            (!explanation.provider || !explanation.providerReason)
        )
            return 'Bitte überprüfen Sie die Erläuterung (3).';
        if (
            explanation.investmentChanged &&
            (!explanation.investmentChanges || !explanation.investmentReason)
        )
            return 'Bitte überprüfen Sie die Erläuterung (3).';

        if (!explanation.providerChanged && !explanation.investmentChanged)
            return 'Bitte überprüfen Sie die Erläuterung (3).';
    }
    return true;
}
