import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import { centToString } from '../../../../util/Money/MoneyFunctions';
import EditIcon from '@material-ui/icons/Edit';

const DisplayRow = (props) => {
    const { proof, index, editStart, deleteCallback, editIndex } = props;

    return (
        <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                <IconButton
                    disabled={editIndex >= 0}
                    size="small"
                    onClick={() => deleteCallback(index)}
                >
                    <DeleteIcon />
                </IconButton>
                <IconButton
                    disabled={editIndex >= 0}
                    size="small"
                    onClick={() => {
                        editStart(index);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
            <TableCell>
                {proof.proofDescription !== ''
                    ? proof.proofDescription
                    : '[Noch keine Beschreibung angegeben]'}
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>
                {centToString(
                    parseInt(proof.sumDevice) + parseInt(proof.sumActivity),
                    false
                ) + ' €'}
            </TableCell>
        </TableRow>
    );
};

DisplayRow.propTypes = {
    proof: PropTypes.object,
    index: PropTypes.number,
    editStart: PropTypes.func,
    deleteCallback: PropTypes.func,
};

export default DisplayRow;
