import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/de';
import Moment from 'react-moment';
import { enableMapSet } from 'immer';

Moment.globalLocale = 'de';
Moment.globalFormat = 'DD.MM.YYYY, HH:mm';

enableMapSet();

ReactDOM.render(
    <React.Fragment>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.Fragment>,
    document.getElementById('root')
);
