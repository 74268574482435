import React from 'react';
import { useProposals } from '../../../hooks/Proposal/useProposals';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import ProposalDisplay, { PROPOSAL_STATE } from './ProposalDisplay';
import TextBlock from '../../TextBlock/TextBlock';
import { texts as pot } from '../../../texts/Components/ProofOverviewTexts';
import LinkSameSite from '../../TextBlock/components/LinkSameSite';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import ProposalOverviewCreate from './ProposalOverviewCreate';
import { filterProposals } from '../../../util/Proposal/FilterProposals';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    link: {
        '& a': {
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
    },
}));

const ProposalOverview = () => {
    const { data, isLoading, error, isFetching, refetch } = useProposals();
    const [activeTab, setActiveTab] = React.useState(0);

    const classes = useStyles();

    const handleChange = (_, newValue) => {
        setActiveTab(newValue);
    };

    const parsedData = React.useMemo(() => {
        return filterProposals(data);
    }, [data]);

    const inAuditDisabled = parsedData.inAudit.length === 0;
    const finishedDisabled = parsedData.finished.length === 0;

    return (
        <div>
            <Typography variant="h4" component="h1">
                Anträge
            </Typography>
            <Typography gutterBottom>
                Folgend sind alle Ihre Anträge aufgelistet.
            </Typography>

            <Paper variant="outlined" square>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    style={{ borderBottom: '1px solid rgb(204, 204, 204)' }}
                >
                    <Tab label="In Bearbeitung" />
                    <Tab label="In Prüfung" disabled={inAuditDisabled} />
                    <Tab label="Abgeschlossen" disabled={finishedDisabled} />
                </Tabs>

                {error && (
                    <Box padding={2}>
                        <Alert
                            severity="error"
                            action={
                                <Button onClick={refetch} disabled={isFetching}>
                                    Neuladen
                                </Button>
                            }
                        >
                            <AlertTitle>
                                Fehler beim laden der Anträge
                            </AlertTitle>
                            {error.message()}
                        </Alert>
                    </Box>
                )}

                {isLoading ? (
                    <div
                        style={{
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TabPanel activeTab={activeTab} index={0}>
                            <ProposalDisplay
                                data={parsedData.inProgress}
                                state={PROPOSAL_STATE.IN_PROGRESS}
                            />
                        </TabPanel>

                        <TabPanel activeTab={activeTab} index={1}>
                            <ProposalDisplay
                                data={parsedData.inAudit}
                                state={PROPOSAL_STATE.IN_AUDIT}
                            />
                        </TabPanel>

                        <TabPanel activeTab={activeTab} index={2}>
                            <ProposalDisplay
                                data={parsedData.finished}
                                state={PROPOSAL_STATE.FINISHED}
                            />
                        </TabPanel>
                    </>
                )}
                <Box padding={2}>
                    <ProposalOverviewCreate />
                </Box>
            </Paper>
            <div style={{ marginTop: '16px' }}>
                <TextBlock
                    textBlock={pot.parser.text}
                    headlineVariant="h5"
                    listProps={{ component: 'ol' }}
                />
                <div className={classes.link}>
                    <TextBlock
                        textBlock={pot.parser.text2}
                        headlineVariant="h5"
                        parserOptions={{
                            components: {
                                a: {
                                    component: LinkSameSite,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProposalOverview;
