import React from 'react';
import PropTypes from 'prop-types';
import useFinalVerificationData from '../../../hooks/Verification/useFinalVerificationData';
import Table from '@material-ui/core/Table';
import FinalPlanData from './FinalPlanData';
import FinalPlanTableHeader from './FinalPlanTableHeader';
import Outline from '../../Partials/Info/Outline';
import CenterOverlay from './CenterOverlay';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const VerificationFinalPlan = (props) => {
    const { id } = props;

    const { data, error, isFetching, refetch } = useFinalVerificationData(id);

    return (
        <Outline>
            <div style={{ position: 'relative', width: '100%' }}>
                <Table>
                    <FinalPlanTableHeader />
                    <FinalPlanData data={data} />
                </Table>
                {isFetching && (
                    <CenterOverlay>
                        <CircularProgress />
                    </CenterOverlay>
                )}
                {!isFetching && error && (
                    <CenterOverlay>
                        <Typography>{error.message()}</Typography>
                        <Button onClick={refetch} variant="outlined">
                            Erneut versuchen
                        </Button>
                    </CenterOverlay>
                )}
            </div>
        </Outline>
    );
};

VerificationFinalPlan.propTypes = {
    id: PropTypes.string.isRequired,
};

export default VerificationFinalPlan;
