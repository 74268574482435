import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

const DialogWithLogout = (props) => {
    const { isOpen, title, content, closeCallback } = props;
    const [open, setOpen] = React.useState(isOpen);

    const handleClose = () => {
        if (closeCallback) closeCallback();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-close-title"
            aria-describedby="modal-close-content"
        >
            <DialogTitle id="modal-close-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="modal-close-content">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Eingaben überprüfen
                </Button>
                <Button color="primary" component={RouterLink} to="/abmelden">
                    Abmelden
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogWithLogout.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    closeCallback: PropTypes.func,
};

DialogWithLogout.defaultProps = {
    isOpen: false,
};

export default DialogWithLogout;
