import { Response } from '../DataTypes/Response';
import uniqid from 'uniqid';

function getMesssage(messages, code) {
    if (messages[code]) return messages[code];
    return messages.generic;
}

export function handleError(e, messages) {
    if (e.response) {
        //status >2XX
        if (e.response.status >= 500 && !messages[e.response.status])
            e.response.status = 500;
        return Response.Error(getMesssage(messages, e.response.status), e);
    } else if (e.request) {
        //No response received
        return Response.Error(getMesssage(messages, 500), e);
    }
    //No request was sent
    return Response.Error(getMesssage(messages, 'generic'), e);
}

function generateViolations(violations, title) {
    const errors = {
        title: 'Beim speichern sind folgende Fehler aufgetreten:',
        violations: [],
    };

    violations.forEach((violation) => {
        errors.violations.push({
            message: violation.message,
            key: uniqid(),
            id: violation.propertyPath,
        });
    });

    return errors;
}

export function buildResponseError(e, messages) {
    if (
        e.response?.data?.violations &&
        e.response?.data?.violations.length > 0
    ) {
        return generateViolations(e.response.data.violations);
    }
    const error = handleError(e, messages);
    return { title: error.message(), violations: [] };
}
