import BundesEot from '../../../static/fonts/bundessans/BundesSansWeb-Regular.eot';
import BundesTtf from '../../../static/fonts/bundessans/BundesSansWeb-Regular.ttf';
import BundesWoff from '../../../static/fonts/bundessans/BundesSansWeb-Regular.woff';
import BundesSvg from '../../../static/fonts/bundessans/BundesSansWeb-Regular.svg';

export const bundesRegular = {
    fontFamily: 'BundesSansWeb',
    fontWeight: 'normal',
    fontStyle: 'normal',
    src: `
        url(${BundesEot}),
        url(${BundesEot}#iefix) format('embedded-opentype'),
        url(${BundesWoff}) format('woff'),
        url(${BundesTtf}) format('truetype'),
        url(${BundesSvg}#fdcee3472cc53561b5916afc05bd2e03) format('svg')
    `,
};
