import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { privateRouteTexts } from '../../../texts/PrivateRouteTexts';

const PrivateRouteButton = (props) => {
    const { isLoading, onClick } = props;

    return (
        <Button onClick={onClick} disabled={isLoading} variant="outlined">
            {privateRouteTexts.button}
        </Button>
    );
};

PrivateRouteButton.propTypes = {
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
};

export default PrivateRouteButton;
