export const texts = {
    static: {
        title: 'Digitalisierungsplan - Vorhabenbeschreibung',
        module1Headline: 'Modul 1: Investition in digitale Technologien',
        module2Headline:
            'Modul 2: Investition in die Qualifizierung der Mitarbeitenden',
        dataHeadline: '2. Rahmendaten des Vorhabens',
        dateHeadline: '2.2 Geplanter Umsetzungszeitraum',
        goal1: '(1) Geschäftsmodell digitalisieren und digitale Geschäftsfelder (= digitale Produkte und Dienstleistungen) entwickeln bzw. optimieren',
        goal2: '(2) Geschäftsprozesse und Arbeitsorganisation digitalisieren ',
        goal3: '(3) Erhöhung der Kompetenzen und Fertigkeiten von Mitarbeitenden im Umgang mit digitalen Technologien',
        goal4: '(4) Erhöhung der IT-Sicherheit (Datensicherheit und -schutz)',
        goal5: '(5) Verbesserung der digitalen Vernetzung entlang oder innerhalb einer Wertschöpfungskette',
        goal6: '(6) Sonstiges',
        goalNot6: '(5) Sonstiges',
        description: '4. Beschreibung des Vorhabens',
    },
    config: {
        module: {
            atLeastOne:
                'Bitte wählen Sie mindestens eines der beiden Module aus.',
        },
        titleField: {
            maxChars: 100,
            required: 'Bitte geben Sie den Titel für das Vorhaben an.',
        },
        dateTooLong: {
            maxChars: 300,
            required:
                'Bitte erläutern Sie, aus welchen Gründen Ihr Projekt mehr als 12 Monate dauert.',
        },
        executionNotStarted: {
            required: 'Bitte bestätigen Sie diese Angabe.',
        },
        other: {
            maxChars: 200,
        },
        goals: {
            atLeastOne: 'Bitte wählen Sie mindestens ein Ziel aus',
        },
        date: {
            diffBelowZero: 'Das Enddatum kann nicht vor dem Anfangsdatum sein.',
            hint: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Berücksichtigen Sie bei der Antragstellung hinsichtlich des geplanten Laufzeitbeginns und der Bindefrist der einzuholenden Angebote eine Bearbeitungszeit von mindestens 6-8 Wochen.',
                    },
                    {
                        type: 'p',
                        text: 'Die Zuwendung darf nur für die im Bewilligungszeitraum für das Vorhaben verursachten Ausgaben abgerechnet werden. Das bedeutet, dass Lieferung, vollständige Umsetzung der beantragten Investitionen sowie Zahlung innerhalb des Bewilligungszeitraumes erfolgen müssen.',
                    },
                    {
                        type: 'p',
                        text: 'Der Bewilligungszeitraum im Zuwendungsbescheid orientiert sich an dem hier gewählten Projektzeitraum. Bitte wählen Sie daher einen nicht zu kurzen Zeitraum. Bitte beachten Sie, dass es hier zu zeitlichen Verschiebungen kommen kann.',
                    },
                ],
            },
        },
        dateStart: {
            required: 'Bitte geben Sie das Anfangsdatum an.',
        },
        dateEnd: {
            required: 'Bitte geben Sie das Enddatum an.',
        },
        question1: {
            maxChars: 3500,
            required:
                'Bitte erläutern Sie, in welche Technologien/Qualifizierungsmaßnahmen Sie investieren möchten.',
        },
        question2: {
            maxChars: 3500,
            required: 'Bitte erläutern Sie die Anwendung der Investitionen.',
        },
        question3: {
            maxChars: 3500,
            required:
                'Bitte erläutern Sie, welche Verbesserungen bzw. Neuerungen Sie erwarten.',
        },
        questionSecurityA: {
            maxChars: 2000,
            required:
                'Bitte geben Sie an, in welche Technologien und/oder Qualifizierungsmaßnahmen Sie investieren wollen.',
        },
        questionSecurityB: {
            maxChars: 2000,
            required:
                'Bitte geben Sie an, wie sich durch das Investitionsvorhaben die allg. IT-Sicherheit in Ihrem Unternehmen verbessert.',
        },
        valueChain1: {
            maxChars: 2000,
            required:
                'Bitte geben Sie an, welche Rolle bzw. Funktion Ihr Unternehmen einnimmt.',
        },
        valueChain2: {
            maxChars: 2000,
            required:
                'Bitte geben Sie an, wie das Investitionsvorhaben zur Verbesserung Ihrer Rolle bzw. Funktion beiträgt.',
        },
    },
    invest: {
        headline: 'Digitalisierungsplan - Vorhabenbeschreibung ',
        paragraphs: [
            {
                type: 'p',
                text: 'Die Zuwendung im Förderprogramm „Digital Jetzt“ wird als [u]nicht[u] rückzahlbarer Zuschuss (Projektförderung) in Form einer Anteilfinanzierung gewährt. Die Förderquote (in % der Investitionskosten) ist bis zum [tooltip|0]30. Juni 2021[tooltip] abhängig von der Unternehmensgröße folgendermaßen festgesetzt:',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Ab 1. Juli 2021 (Antragseingang) Änderung der gestaffelten Förderquoten (für alle Module):',
                            },
                            {
                                type: 'list',
                                text: '[li]40% für Unternehmen bis 50 Mitarbeitende,[li][li]35% für Unternehmen bis 250 Mitarbeitende,[li][li]30% für Unternehmen bis 499 Mitarbeitende[li]',
                            },
                            {
                                type: 'p',
                                text: 'Die Bonusprozentpunkte (Wertschöpfungskette, IT-Sicherheit, wirtschaftlich strukturschwache Regionen siehe Fördergebietskarte) bleiben der Höhe nach bestehen.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'list',
                text: '[li]50% für Unternehmen bis 50 Mitarbeitende,[li][li]45% für Unternehmen bis 250 Mitarbeitende,[li][li]40% für Unternehmen bis 499 Mitarbeitende.[li]',
            },
        ],
    },
    invest2: {
        paragraphs: [
            {
                type: 'p',
                text: 'In folgenden Fällen werden Bonusprozentpunkte berücksichtigt:',
            },
            {
                type: 'list',
                text: '[li]Gleichzeitige Investitionen von mehreren Unternehmen entlang der [tooltip|0]Wertschöpfungskette[tooltip] oder innerhalb eines Wertschöpfungsnetzwerkes (plus 5 Prozentpunkte), [li][li]Investitionen zur Erhöhung der IT-Sicherheit (inklusive Datenschutz) im Unternehmen (plus 5 Prozentpunkte)[li][li]Investitionen von Unternehmen in [tooltip|1]wirtschaftlich strukturschwachen Regionen[tooltip] (plus 10 Prozentpunkte)[li]',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Mehrere Unternehmen arbeiten entweder entlang einer Wertschöpfungskette zusammen. Diese kann von der Zulieferung von Rohstoffen und Materialien über Zwischenprodukte bis hin zum Endprodukt reichen. Oder mehrere Unternehmen kooperieren in einem Wertschöpfungsnetz - beispielsweise arbeiten mehrere Zulieferer bei der Motorenfertigung in der Automobilindustrie zusammen. Die Unternehmen liefern jeweils eigenständige Beiträge in der Kette oder dem Netzwerk. Jedes Unternehmen muss einen eigenen Antrag stellen und einen eigenen Digitalisierungsplan einreichen.',
                            },
                        ],
                    },
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: '[a|#]Karte wirtschaftlich strukturschwache Regionen[a]',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    invest3: {
        paragraphs: [
            {
                type: 'p',
                text: 'Maximale Höhe der Förderung:',
            },
            {
                type: 'list',
                text: '[li]Für Einzelunternehmen beträgt der Förderhöchstbetrag (= Zuschuss) 50.000 Euro,[li][li]Bei gleichzeitigen Investitionen von mehreren Unternehmen entlang der Wertschöpfungskette oder innerhalb eines Wertschöpfungsnetzwerkes beträgt der Förderhöchstbetrag (= Zuschuss) 100.000  Euro pro antragstellendes Teilunternehmen. Es sind gleichzeitige, abgestimmte Investitionen der beteiligten Unternehmen erforderlich.[li]',
            },
        ],
    },
    investType: {
        headline: 'Geplante Investition',
        paragraphs: [
            {
                type: 'p',
                text: 'Wählen Sie bitte das Modul, in dem Sie eine Investition planen.',
            },
            {
                type: 'p',
                text: 'Beide Module sind kombinierbar. Sollten Sie Investitionen in beiden Modulen gleichzeitig vorsehen, kreuzen Sie bitte beide Module an.',
            },
        ],
    },
    module1: {
        //headline: 'Module 1: Investition in digitale Technologien',
        paragraphs: [
            {
                type: 'p',
                text: 'In Modul 1 werden Investitionen in digitale Technologien ([tooltip|0]Hardware und Software[tooltip]) im Unternehmen und damit verbundene Prozesse und Implementierungen gefördert. Die Implementierung ist nur durch einen externen Dienstleister förderfähig.',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Gefördert werden insbesondere Investitionen in Hard- und Software, welche die interne und externe Vernetzung der Unternehmen unterstützen. Folgende Aspekte können dabei berücksichtigt werden: Datengetriebene Geschäftsmodelle, Künstliche Intelligenz, Cloud-Anwendungen, Big Data, Sensorik, 3D-Druck sowie IT-Sicherheit und Datenschutz.',
                            },
                            {
                                type: 'p',
                                text: 'Weitere Informationen zu förderfähigen und nicht förderfähigen Gegenständen sind in "Wichtige Fragen und Antworten rund um die Förderung" auf der ([a|https://www.bmwk.de/Redaktion/DE/Dossier/digital-jetzt.html]Webseite[a]) aufgeführt.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: 'Die [u]Untergrenze[u] für die beantragte Fördersumme (= Zuschuss) beträgt 17.000 Euro.',
            },
        ],
    },
    module2: {
        paragraphs: [
            {
                type: 'p',
                text: 'In Modul 2 werden Investitionen in [tooltip|0]Qualifizierungsmaßnahmen[tooltip] für Mitarbeitende des Unternehmens zum Umgang mit digitalen Technologien gefördert. Der Weiterbildungsanbieter für die Investitionen in Modul 2 muss durch seine räumliche, technische und personelle Ausstattung eine erfolgreiche Weiterbildung erwarten lassen und es muss eine mehrjährige Stetigkeit im Angebot vorhanden sein. Weiterhin muss das Qualitätsniveau der Weiterbildungsanbieter der Qualifizierungsmaßnahmen durch eine Zertifizierung oder durch gesetzliche Anerkennung oder Nachweise für die Qualitätssicherung des Angebotes nachweisbar sein. Alternativ ist die Zertifizierung durch Eigenrecherche des antragstellenden Unternehmens in einem Screenshot oder mit einer Selbsterklärung des Anbieters zu dokumentieren und im Finanzierungsplan bei Modul 2 hochzuladen.  ',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Gefördert werden Investitionen, die die Qualifizierung von Mitarbeitenden des Unternehmens verbessern – insbesondere bei der Erarbeitung und Umsetzung einer digitalen Strategie im Unternehmen sowie bei IT-Sicherheit und Datenschutz, aber auch ganz grundsätzlich zu digitalem Arbeiten und den nötigen Basiskompetenzen.',
                            },
                            {
                                type: 'p',
                                text: 'Weitere Informationen zu förderfähigen und nicht förderfähigen Gegenständen sind in "Wichtige Fragen und Antworten rund um die Förderung" auf der Webseite ([a|http://www.bmwk.de/digital-jetzt]www.bmwk.de/digital-jetzt[a]) aufgeführt.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: 'Beachten Sie, dass Einweisungen, Schulungen etc., die in direktem Bezug zu beantragten Investitionen in digitale Technologien (v.a. Soft-/Hardware) in Modul 1 stehen, als Teil der Implementierung in Modul 1 beantragt werden müssen. ',
            },
            {
                type: 'p',
                text: 'Die [u]Untergrenze[u] für die beantragte Fördersumme (= Zuschuss) beträgt 3.000 Euro.',
            },
            {
                type: 'p',
                text: 'Bei kumulativer Inanspruchnahme der Module 1 und 2 beträgt die [u]Untergrenze[u] für die beantragte Fördersumme (= Zuschuss) 17.000 Euro.',
            },
        ],
    },
    title: {
        headline: '2.1 Bezeichnung des Vorhabens',
        hint: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Hinweis: Hier beantragen Sie den geplanten Beginn und das Ende ihres Investitionsvorhabens. Dieser Zeitraum wird im Zuwendungsbescheid durch einen Bewilligungszeitraum rechtsverbindlich festgelegt.\n',
                },
                {
                    type: 'p',
                    text: 'Der Bewilligungszeitraum ist der Zeitraum, [u]innerhalb dessen das Vorhaben betriebsbereit umgesetzt werden soll.[u]\n',
                },
                {
                    type: 'p',
                    text: 'Förderfähig sind nur Vorhaben, mit denen zum Zeitpunkt der Bewilligung [ub]noch nicht begonnen worden[ub] ist. Als Vorhabenbeginn gilt der Abschluss eines der Ausführung zuzurechnenden Lieferungs- oder Leistungsvertrags.\n',
                },
                {
                    type: 'p',
                    text: 'Hierzu zählt bspw. auch die Bestellung oder die Beauftragung von Leistungen oder Investitionen.\n',
                },
                {
                    type: 'p',
                    text: 'Die Zuwendung/Förderung darf nur für [u]die im Bewilligungszeitraum für das Vorhaben verursachten Ausgaben[u] abgerechnet werden.\n',
                },
                {
                    type: 'p',
                    text: 'Zu den verursachten Ausgaben zählt der Abschluss von Verträgen (Bestellung, Beauftragung), sowie die Anzahlung oder Bezahlung - diese müssen [b]innerhalb des Bewilligungszeitraums[b] getätigt werden. Die Lieferungen müssen im Bewilligungszeitraum erfolgen bzw. Leistungen im Bewilligungszeitraum erbracht worden sein. \n',
                },
                {
                    type: 'p',
                    text: 'Bitte beachten Sie diese Rahmenbedingungen in der folgenden Auswahl Ihres geplanten Investitionszeitraums.',
                },
            ],
        },
        paragraphs: [
            {
                type: 'p',
                text: 'Geben Sie dem Vorhaben einen kurzen prägnanten [tooltip|0]Titel[tooltip]',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Der Titel des Vorhabens sollte möglichst allgemeinverständlich sein und den thematischen Schwerpunkt des Investitionsvorhabens und ggfs. den Anwendungsbereich wiedergeben.',
                            },
                            {
                                type: 'p',
                                text: 'Beispiele für mögliche Titel: „Einsatz von digitalen Assistenzsystemen in der Produktion“, „Implementierung eines CRM-Systems zur Verbesserung des Kundenmanagements“; „Qualifizierung zur Erstellung von Gebäudemodellen durch Building Information Modeling-Software“',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    diff: {
        error: 'Die maximale Vorhabenslaufzeit für Einzelunternehmen beträgt maximal 12 Monate. Bitte ändern Sie Ihre Angaben.',
        lessThanTwoMonthError:
            'Angelehnt an die von Ihnen gewählte Projektlaufzeit orientiert sich im Falle der Bewilligung der im Zuwendungsbescheid festgelegte Bewilligungszeitraum. Bitte beachten Sie, dass im Bewilligungszeitraum die Bestellung, Beauftragung sowie Anzahlung oder Bezahlung getätigt werden müssen. ' +
            'Ebenso muss im Bewilligungszeitraum die Lieferung bzw. die Leistungen erbracht werden. Versichern Sie sich, dass eine Projektumsetzung innerhalb der angegebenen Projektlaufzeit realisiert werden kann.',
    },
    dateStart: {
        paragraphs: [
            {
                type: 'p',
                text: 'Geplanter [tooltip|0]Beginn[tooltip] (Datum der geplanten Auftragserteilung / Bestellung)',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Eine Antragstellung ist [u]nicht[u] möglich, wenn Sie das Vorhaben bereits begonnen haben. Das Vorhaben wurde begonnen, sobald eine rechtsverbindliche Auftragserteilung / Bestellung zur Lieferung eines Produktes oder Erbringung einer Dienstleistung (ggfs. auch mündlich) erfolgt ist.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    dateEnd: {
        paragraphs: [
            {
                type: 'p',
                text: 'Geplantes [tooltip|0]Ende[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Vorhabenlaufzeit umfasst in der Regel maximal 12 Monate.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    dateEndSingle: {
        paragraphs: [
            {
                type: 'p',
                text: 'Geplantes [tooltip|0]Ende[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Die Vorhabenslaufzeit umfasst maximal 12 Monate',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    dateTooLong: {
        paragraphs: [
            {
                type: 'p',
                text: 'Erläutern Sie, weshalb die Umsetzung von Ihrem Investitionsvorhaben mehr als 12 Monate umfasst.',
            },
        ],
    },
    confirmDate: {
        paragraphs: [
            {
                type: 'p',
                text: 'Bestätigung: Das antragsstellende Unternehmen erklärt, dass mit dem Vorhaben noch [u]nicht[u] begonnen wurde.',
            },
        ],
    },
    goal: {
        headline: '3. Ziele des Vorhabens',
        paragraphs: [
            {
                type: 'p',
                text: 'Geben Sie anhand der folgenden Auswahlmöglichkeiten an, welche Ziele Ihr Unternehmen mit dem geplanten Vorhaben verfolgt.Mehrfachantworten sind möglich.',
            },
        ],
    },
    goalHint: {
        headline: 'Investitionen zur Erhöhung der IT-Sicherheit im Unternehmen',
        paragraphs: [
            {
                type: 'p',
                text: 'Bitte beachten Sie, dass für Investitionen in digitale Technologien und Qualifizierungsmaßnahmen zur Erhöhung der IT-Sicherheit (inklusive Datenschutz) im Unternehmen die Förderquote um fünf Prozentpunkte erhöht wird. Die erhöhte Förderquote gilt dann für das gesamte Investitionsvorhaben.',
            },
            {
                type: 'p',
                text: 'Wenn Sie in Ihrem Vorhaben ausschließlich bzw. unter anderem Investitionen zur Erhöhung der IT-Sicherheit planen, wählen Sie bitte im Folgenden das entsprechende Ziel „Erhöhung der IT-Sicherheit“ aus.',
            },
            {
                type: 'p',
                text: 'Im Digitalisierungsplan sind anschließend konkrete Angaben zu machen, wie das Ziel erreicht werden soll.',
            },
        ],
    },
    goal1: {
        paragraphs: [
            {
                type: 'p',
                text: 'Internetbasierte Technologien können in bestehende Geschäftsmodelle integriert werden (teildigitalisiertes Geschäftsmodell), oder es werden ganz neue Geschäftsmodelle entwickelt (volldigitalisiertes Geschäftsmodell).',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiel Handwerk[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Beispiel Handwerk: Ein Tischlereibetrieb richtet einen Online-Shop ein. So werden Möbel und weitere Produkte mithilfe von digitalen Vertriebs- und Marketingkanälen auch überregional angeboten. Mit einem Produktkonfigurator können Kunden zudem ihre Möbel individuell gestalten (Material, Maße, Farbe etc.). Der Kundenstamm kann so um neue Kundensegmente erweitert werden.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiel Vertreibendes Gewerbe[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Beispiel Verarbeitendes Gewerbe: Ein Hersteller für Werkzeuge stattet seine Produkte mit intelligenter Sensorik aus. Dabei werden Daten, u.a. zum Verschleiß der Werkzeuge, zum Hersteller übertragen und dort ausgewertet. Den Kunden können so zusätzliche Dienstleistungen, u.a. zur Instandhaltung, angeboten werden. Neben einem einmaligen Ertrag durch den Verkauf des Werkzeuges können somit zusätzliche kontinuierliche Erlöse erzielt werden.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiel Dienstleistungen[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Beispiel Dienstleistungen: Ein Hochzeitsausstatter entwickelt einen Marktplatz für weitere Unternehmen entlang der Wertschöpfungskette (Catering, Juweliere etc.). So werden neue Kundensegmente sowie zusätzliche Einnahmenquellen (Nutzungsgebühren, Provisionen) erschlossen.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal2: {
        headline:
            '(2.1.) Einsatz digitaler Technologien zur Optimierung von Unternehmens- und Geschäftsprozessen',
        paragraphs: [
            {
                type: 'p',
                text: 'Digitale Technologien bieten die Chance, wichtige Geschäftsprozesse im Unternehmen (u.a. Kundenmanagement, Einkauf, Lagerhaltung, Produktion, Fertigung, Vertrieb) effizient und flexibel zu gestalten.',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiele[tooltip]',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'list',
                                text: '[li]Digitaler Vertrieb von Produkten und Dienstleistungen (u.a. Web-Shop, Online-Produkt-Konfigurator, App);[li][li]Verbesserung der Kommunikation mit Kunden und Partnern (u.a. personalisierte Kundenansprache durch CRM-System);[li][li]Analyse und Auswertung von Kundenbedarfen (u.a. Big Data-Analysen);[li][li]Planung, Dokumentation und Simulation von Prozessen (z.B. durch ERP-System, Warenwirtschaftssystem, digitalem Zwilling);[li][li]Modellierung und Simulation von Produkten (u.a. 3D-CAD, BIM);[li][li]Vernetzung von Produktionsanlagen sowie Analyse und Auswertung von Daten für Instandhaltung, Prozessoptimierung sowie effizientem Ressourcenverbrauch (Maschine-Maschine-Kommunikation);[li][li]Assistenzsysteme zur Unterstützung bei Fertigung, Konfiguration, Wartung und Qualitätssicherung (Mensch-Maschine-Kommunikation); [li][li]Automatisierung der Produktion und Logistik;[li][li]Additive Fertigung (u.a. 3D-Druck).[li]',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal2b: {
        headline: '(2.2.) Digitales Arbeiten ausbauen',
        paragraphs: [
            {
                type: 'p',
                text: 'Digitale Technologien ermöglichen neue Arbeitsmethoden und Formen der Zusammenarbeit der Mitarbeitenden.',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiele[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'list',
                                text: '[li]Digitale Plattformen zum internen Datenaustausch;[li][li]Digitale Kommunikationsplattformen; [li][li]Digitales Projektmanagement;[li][li]Digitales Wissensmanagement.[li]',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal3: {
        paragraphs: [
            {
                type: 'p',
                text: 'Durch die zunehmende interne und externe Vernetzung von Unternehmen sowie digitale Geschäftsmodelle verändern sich die Tätigkeiten der Mitarbeitenden und die Anforderungen an Mitarbeitende.',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiele[tooltip]',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'list',
                                text: '[li]Qualifizierungsmaßnahmen für die Bewertung von digitalen Technologien, u.a. um Digitalisierungsvorhaben im Unternehmen zu analysieren, vorzubereiten, zu planen und umzusetzen;[li][li]Qualifizierungsmaßnahmen für die Nutzung und Anwendungen von digitalen Technologien, u.a. digitales Arbeiten, Datenanalysefähigkeit, Softwareanwendungen, Prozess-Know-how, IT-Sicherheit).[li]',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal4: {
        paragraphs: [
            {
                type: 'p',
                text: 'IT-Sicherheit beschreibt den Schutz aller Teile eines IT-Systems (u.a. Endgeräte, Betriebssysteme und Anwendungen), mit denen im Unternehmen Daten verarbeitet, genutzt und gesichert werden, vor unbefugtem Zugriff, Manipulation oder Diebstahl.',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiele[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'list',
                                text: '[li]Technische Maßnahmen: Firewall, Virenschutz, Datensicherung und -verschlüsselung etc.;[li][li]Qualifizierungsmaßnahmen: Schulungen für Mitarbeitende in IT-Sicherheit.[li]',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal5: {
        paragraphs: [
            {
                type: 'p',
                text: 'Durch die digitale Vernetzung mit anderen Unternehmen innerhalb bzw. entlang der Wertschöpfungskette (u.a. Kunden, Zulieferern) können Synergieeffekte u.a. bei der Umsetzung von digitalen Geschäftskonzepten erzielt werden. Dazu müssen Medienbrüche an Schnittstellen abgebaut bzw. vermieden werden.',
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiel Handwerk[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Handwerksbetriebe  unterschiedlicher Gewerke bauen gemeinsam ein Wertschöpfungsnetzwerk auf. Mit Hilfe einer digitalen Softwarelösung können gemeinsame Projekte abgewickelt und entstehende Daten (Bilder, Dokumente und Pläne) abgelegt werden. Durch die strategische Einbindung verschiedener Gewerke können komplexe Aufträge akquiriert werden.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: '[tooltip|0]Beispiel Handel[tooltip]',
                tooltips: [
                    {
                        interactive: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Mehrere mittelständische Handelsunternehmen verbinden für eine netzwerkinterne Überwachung von Warenbeständen und Bedarfsentwicklungen ihre ERP-Systeme mit einem gemeinsamen virtuellen Lager. So lassen sich die Bestände im Netzwerk senken, Kosten reduzieren und die Warenverfügbarkeit erhöhen.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    goal6: {
        paragraphs: [
            {
                type: 'p',
                text: 'Nennen Sie hier ggfs. weitere Zielstellungen, die Sie mit dem Investitionsvorhaben verfolgen, falls diese den o.g. Zielen nicht zuordenbar sind',
            },
            {
                type: 'p',
                text: 'Diese sollten in direktem Bezug zu weiteren Themenbereichen der Digitalisierung im Unternehmen stehen. ',
            },
        ],
    },
    description: {
        headline: 'Darstellung des allgemeinen Investitionsvorhabens',
        paragraphs: [
            {
                type: 'p',
                text: 'Im Folgenden bitten wir um eine inhaltliche Beschreibung des von Ihnen geplanten Investitionsvorhabens.',
            },
            {
                type: 'p',
                text: 'Gehen Sie möglichst konkret auf die bestehende Situation in Ihrem Unternehmen ein. Nehmen Sie dabei Bezug auf die von Ihnen zuvor ausgewählten Ziele. ',
            },
            {
                type: 'p',
                text: 'Wir benötigen diese Informationen, um beurteilen zu können:',
            },
            {
                type: 'list',
                text: '[li]ob eine Weiterentwicklung Ihres Unternehmens (z.B. in Bezug auf ein digitales Geschäftsmodell und digitale Geschäftsfelder, Unternehmensprozesse, IT-Sicherheit) auf Grund der Investitionen zu erwarten ist und[li][li]inwiefern die Investitionen einen erkennbaren Beitrag zu dieser Entwicklung leisten.[li]',
            },
            {
                type: 'p',
                text: '[Finanzielle Details zu den Investitionen werden im Anschluss im Finanzierungsplan aufgenommen.]',
            },
        ],
    },
    question1: {
        title: '1. In  welche digitalen Technologien und/oder Qualifizierungsmaßnahmen möchten Sie konkret investieren? Zählen Sie diese auf (1., 2.,3., ...) und beschreiben sie diese möglichst knapp und anschaulich.',
        titleModule1: 'Modul 1: Nennung der geplanten digitalen Technologien.',
        titleModule2:
            'Modul 2: Darstellung der Themenschwerpunkte und Lernziele der geplanten Qualifizierungsmaßnahmen.',
    },
    question2: {
        title: '2. Erläutern Sie die konkrete Anwendung der Investitionen im Unternehmen.',
        titleModule1:
            'Modul 1: Erläutern Sie die konkrete Anwendung  bzw. den Mehrwert der geplanten digitalen Technologien im Unternehmen.',
        titleModule2:
            'Modul 2: Erläutern Sie die voraussichtlich erworbenen Kenntnisse bzw. Fähigkeiten der Qualifizierungsmaßnahmen, sowie ihre Anwendung im Unternehmen.',
    },
    question3: {
        title: '3. Welche konkreten grundlegenden Verbesserungen bzw. neuen Funktionen ("Potenzialhebung") sind mit den beantragten Investitionen mit Blick auf die bestehende Ausgangssituation der Digitalisierung im Unternehmen verbunden?',
        title2: 'Hier ist schlüssig und nachvollziehbar darzustellen, wie durch die konkret beantragten Investitionen / Investitionsvorhaben z.B. ein neues digitales Geschäftsmodell entwickelt wird, die Organisation im Unternehmen effizienter gestaltet wird und / oder die IT-Sicherheit im Unternehmen erhöht wird. Gehen Sie dabei auch möglichst konkret auf die bestehende Situation in Ihrem Unternehmen ein.',
    },
    questionSecurity: {
        headline:
            'Investitionen zur Erhöhung der IT-Sicherheit (Datensicherheit und -schutz) im Unternehmen',
        paragraphs: [
            {
                type: 'p',
                text: 'Sie möchten mit Ihrem Investitionsvorhaben neben weiteren Zielstellungen die [tooltip|0]IT-Sicherheit im Unternehmen[tooltip] erhöhen. Hierfür sind auf Grund der erhöhten Förderquote (plus 5 Prozentpunkte) [u]zusätzliche[u] Erläuterungen notwendig.',
                tooltips: [
                    {
                        interactvie: false,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Bei Investitionen in digitale Technologien und Qualifizierungsmaßnahmen zur Erhöhung der IT-Sicherheit (inklusive Datenschutz) im Unternehmen wird die Förderquote um fünf Prozentpunkte erhöht.',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'p',
                text: 'Bitte erläutern Sie im Folgenden, wie Ihr geplantes Investitionsvorhaben konkret zur Erhöhung der IT-Sicherheit beiträgt. Gehen Sie auch hier möglichst konkret auf die Situation in Ihrem Unternehmen ein. ',
            },
        ],
    },
    questionSecurityA: {
        title: '1. In welche (ggfs. zusätzlichen) digitalen Technologien und/oder Qualifizierungsmaßnahmen, welche die IT-Sicherheit im Unternehmen erhöhen, möchten Sie konkret investieren? Zählen Sie diese auf (1., 2., 3.,...) und beschreiben Sie diese möglichst knapp und anschaulich.',
    },
    questionSecurityB: {
        title: '2. Wie erhöhen diese Investitionen / Maßnahmen konkret die IT-Sicherheit in Ihrem Unternehmen?',
    },
    valueChain: {
        headline:
            'Gleichzeitige Investitionen mit weiteren Unternehmen entlang bzw. innerhalb der Wertschöpfungskette',
        paragraphs: [
            {
                type: 'p',
                text: 'Sie möchten mit weiteren Unternehmen entlang bzw. innerhalb der Wertschöpfungskette gleichzeitig Investitionen in digitalen Technologien und /oder Qualifizierungsmaßnahmen realisieren, um die digitale Vernetzung in der Wertschöpfungskette bzw. im Netzwerk zu verbessern. Hierfür sind auf Grund der [tooltip|0]erhöhten Förderquote[tooltip] (plus 5 Prozentpunkte) zusätzliche Erläuterungen zur Funktion Ihres Unternehmens in der Wertschöpfungskette bzw. im Netzwerk und zur gemeinsamen Zielstellung der Investitiionsvorhaben der beteiligten Unternehmen aus der Wertschöpfungskette notwendig. ',
                tooltips: [
                    {
                        interactive: true,
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Jedes Unternehmen innerhalb der Wertschöpfungskette bzw. des Wertschöpfungsnetzwerks muss einen eigenen Antrag stellen. ',
                            },
                            {
                                type: 'p',
                                text: 'Die Antragstellung einer Wertschöpfungskette gilt als abgeschlossen, wenn alle beteiligten Unternehmen ihre Anträge erfolgreich gestellt haben. ',
                            },
                            {
                                type: 'p',
                                text: 'Basis hierfür ist der PIN-Code, der von allen in der Wertschöpfungskette / im Netzwerk beteiligten Unternehmen genutzt werden muss. Der PIN-Code wird durch den ErstAntragsteller der Wertschöpfungskette / im Netzwerk generiert und muss an die weiteren Unternehmen weitergegeben werden.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    valueChain1: {
        title: '1. Beschreiben Sie die Wertschöpfungskette, in der Sie und die weiteren beteiligten Unternehmen mitwirken. Stellen Sie zudem die Rolle bzw. Funktion Ihres Unternehmens in dieser Wertschöpfungskette bzw. in diesem Netzwerk dar.',
    },
    valueChain2: {
        title: '2. Wie trägt das Investitionsvorhaben zum einen zur Verbesserung der Rolle bzw. Funktion des Unternehmens in der Wertschöpfungskette bzw. im Netzwerk und zum anderen zur Verbesserung innerhalb der Wertschöpfungskette insgesamt (gemeinsame Zielstellung der beteiligten Unternehmen) bei?',
    },
};
