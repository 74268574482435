import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

const MinimisTextField = React.forwardRef((props, ref) => {
    const {
        value,
        label,
        disabled,
        error,
        removeError,
        placeholder,
        endAdornment,
        ...rest
    } = props;

    const handleChange = () => {
        if (error) removeError();
    };

    return (
        <TextField
            label={label}
            inputRef={ref}
            defaultValue={value}
            fullWidth
            disabled={disabled}
            error={error}
            size="small"
            variant="outlined"
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
            InputProps={{
                endAdornment: endAdornment && (
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                ),
            }}
        />
    );
});

MinimisTextField.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.bool,
    removeError: PropTypes.func,
    placeholder: PropTypes.string,
    endAdornment: PropTypes.string,
};

MinimisTextField.defaultProps = {
    disabled: false,
    value: '',
    error: false,
};

export default MinimisTextField;
