import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    button: {
        animationDuration: '1s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: `$placeHolderShimmer`,
        animationTimingFunction: 'linear',
        backgroundColor: 'rgb(1,79,128)', //rgb(193, 220, 239)',
        background:
            'linear-gradient(90deg, rgba(1,79,128,1) 35%, rgba(0,95,154,1) 50%, rgba(1,79,128,1) 65%)', //'linear-gradient(90deg, rgba(193,220,239,1) 35%, rgba(238,248,255,1) 50%, rgba(193,220,239,1) 65%)',
        backgroundSize: '230px 40px',
        height: '40px',
        width: '115px',
        borderRadius: '4px',
        marginRight: theme.spacing(2),
        '&:last-child': {
            marginLeft: 'auto',
        },
    },
    '@keyframes placeHolderShimmer': {
        '0%': {
            backgroundPosition: '-115px 0',
        },
        '100%': {
            backgroundPosition: '115px 0',
        },
    },
}));

const NavbarLoading = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <div className={classes.button}></div>
            <div className={classes.button}></div>
            <div className={classes.button}></div>
            <div className={classes.button}></div>
            <div className={classes.button}></div>
        </Container>
    );
};

export default NavbarLoading;
