import React from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import {
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
    MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const ControlledSelect = ({
    label,
    control,
    items,
    error,
    name,
    rules,
    defaultValue,
    displayEmpty = true,
    disabled = false,
    ...rest
}) => {
    const classes = rest.className;
    return (
        <FormControl
            error={!_.isEmpty(error)}
            fullWidth
            className={classes}
            disabled={disabled}
        >
            <InputLabel id={`label_${name}`}>{label}</InputLabel>
            <Controller
                as={
                    <Select
                        labelId={`label_${name}`}
                        displayEmpty={displayEmpty}
                        inputProps={{ 'data-testid': `select_${name}` }}
                    >
                        {items.map((element, key) => (
                            <MenuItem key={key} value={element.value}>
                                {element.title}
                            </MenuItem>
                        ))}
                    </Select>
                }
                control={control}
                name={name}
                rules={rules}
                defaultValue={!defaultValue ? '' : defaultValue}
            />
            <FormHelperText>{error && error.message}</FormHelperText>
        </FormControl>
    );
};

ControlledSelect.propTypes = {
    label: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    errors: PropTypes.object,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
};

export default ControlledSelect;
