import React from 'react';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { centToString } from '../../../../../util/Money/MoneyFunctions';
import FileTreeItem from './FileTreeItem';

const FinancingPlanTreeView = React.memo((props) => {
    const { proofs, priceLabel, total } = props;

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {proofs.map((proof) => (
                <TreeItem
                    key={proof.id}
                    nodeId={proof.id}
                    label={proof.proofDescription}
                >
                    <TreeItem nodeId={`${proof.id}_general`} label="Übersicht">
                        {proof.activityNotSame && (
                            <TreeItem
                                nodeId={`${proof.id}_general_notSameActivity`}
                                label={`Summe der Maßnahmen stimmt nicht mit der Summe des Nachweises überein: ${proof.activityNotSame}`}
                            />
                        )}
                        {proof.deviceNotSame && (
                            <TreeItem
                                nodeId={`${proof.id}_general_notSameDevice`}
                                label={`Summe der Gegenstände stimmt nicht mit der Summe des Nachweises überein: ${proof.deviceNotSame}`}
                            />
                        )}
                        <TreeItem
                            nodeId={`${proof.id}_general_isbasic`}
                            label={`Keine Grundausstattung: ${
                                proof.isNotBasicEquipment ? 'Ja' : 'Nein'
                            } `}
                        />
                        <TreeItem
                            nodeId={`${proof.id}_general_proofProvider`}
                            label={`Anbieter: ${proof.proofProvider}`}
                        />
                        <TreeItem
                            nodeId={`${proof.id}_general_proofType`}
                            label={`Art: ${
                                proof.proofType === 0
                                    ? 'Angebot / Einfacher Kostenvoranschlag'
                                    : 'Kostenermittlung auf Basis eigener Recherchen'
                            }`}
                        />
                        <TreeItem
                            nodeId={`${proof.id}_general_sumActivity`}
                            label={`Summe der Maßnahmen: ${centToString(
                                proof.sumActivity
                            )} €`}
                        />
                        <TreeItem
                            nodeId={`${proof.id}_general_sumDevice`}
                            label={`Summe der Geräte: ${centToString(
                                proof.sumDevice
                            )} €`}
                        />
                    </TreeItem>

                    <FileTreeItem proofId={proof.id} />

                    {proof.deviceList.length > 0 && (
                        <TreeItem
                            nodeId={`deviceList_${proof.proofDescription}`}
                            label="Liste der Investitionen"
                        >
                            {proof.deviceList.map((device) => (
                                <TreeItem
                                    nodeId={device.id}
                                    key={device.id}
                                    label={device.description}
                                >
                                    <TreeItem
                                        nodeId={`${device.id}_explanation`}
                                        label={`Erläuterung: ${device.explanation}`}
                                    />
                                    <TreeItem
                                        nodeId={`${device.id}_posProof`}
                                        label={`Position im Nachweis: ${device.posProof}`}
                                    />
                                    <TreeItem
                                        nodeId={`${device.id}_isCombination`}
                                        label={`Zusammenfassung: ${
                                            device.isCombination ? 'Ja' : 'Nein'
                                        }`}
                                    />
                                    <TreeItem
                                        nodeId={`${device.id}_amount`}
                                        label={`Anzahl: ${device.amount}`}
                                    />
                                    <TreeItem
                                        nodeId={`${device.id}_price`}
                                        label={`Preis: ${centToString(
                                            device[priceLabel]
                                        )} €`}
                                    />
                                </TreeItem>
                            ))}
                        </TreeItem>
                    )}
                    {proof.activityList.length > 0 && (
                        <TreeItem
                            nodeId={`activityList_${proof.proofDescription}`}
                            label="Maßnahmen"
                        >
                            {proof.activityList.map((activity) => (
                                <TreeItem
                                    nodeId={activity.id}
                                    key={activity.id}
                                    label={activity.description}
                                >
                                    <TreeItem
                                        nodeId={`${activity.id}_explanation`}
                                        label={`Erläuterung: ${activity.explanation}`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_posProof`}
                                        label={`Position im Nachweis: ${activity.posProof}`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_isCombination`}
                                        label={`Zusammenfassung: ${
                                            activity.isCombination
                                                ? 'Ja'
                                                : 'Nein'
                                        }`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_duration`}
                                        label={`Dauer in Std.: ${activity.duration}`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_participa`}
                                        label={`Teilnehmer: ${activity.participants}`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_amount`}
                                        label={`Anzahl: ${activity.amount}`}
                                    />
                                    <TreeItem
                                        nodeId={`${activity.id}_price`}
                                        label={`Preis: ${centToString(
                                            activity[priceLabel]
                                        )} €`}
                                    />
                                </TreeItem>
                            ))}
                        </TreeItem>
                    )}
                </TreeItem>
            ))}

            <TreeItem
                nodeId="count"
                label={`Anzahl Nachweise: ${proofs.length}`}
            />
            <TreeItem
                nodeId="totalSum"
                label={`Summe Gesamt: ${centToString(total, false)} €`}
            />
        </TreeView>
    );
});

FinancingPlanTreeView.propTypes = {
    deductInputTax: PropTypes.bool,
    proofs: PropTypes.array,
    priceLabel: PropTypes.string,
    total: PropTypes.string,
};

export default FinancingPlanTreeView;
