import React from 'react';
import { useAppState } from '../../Stores/AppState.store';
import {
    getLastRaffle,
    isDayOfRaffle,
    RAFFLE_STATE,
} from '../../util/Raffle/RaffleFunctions';
import ProposalDisplayClosed from '../../components/Proposal/ProposalEditClosed';
import RaffleDisplay from '../../components/Raffle/RaffleDisplay';
import IsClosed from '../../components/Raffle/States/IsClosed';
import NotDrawn from '../../components/Raffle/States/NotDrawn';
import InvalidTime from '../../components/Raffle/States/InvalidTime';
import CanParticipate from '../../components/Raffle/States/CanParticipate';
import RaffleParticipating from '../../components/Raffle/RaffleParticipating';
import WasDrawn from '../../components/Raffle/States/WasDrawn';
import { hasSubmittedProposal } from '../../util/Helper/UserHasProposal';

const RaffleStateContainer = (props) => {
    const { raffles, raffleStatus, rafflePots } = useAppState((state) => ({
        rafflePots: state.rafflePots,
        raffleStatus: state.raffleStatus,
        raffles: state.raffles,
    }));

    const hasSubProposal = React.useMemo(
        () => hasSubmittedProposal(raffles),
        [raffles]
    );

    if (raffleStatus === RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE)
        return (
            <RaffleDisplay label={<CanParticipate isProposalPage={true} />} />
        );

    if (raffleStatus === RAFFLE_STATE.RAFFLE_IS_PARTICIPATING)
        return (
            <RaffleDisplay>
                <RaffleParticipating raffle={getLastRaffle(raffles)} />
            </RaffleDisplay>
        );

    if (raffleStatus === RAFFLE_STATE.RAFFLE_IS_PARTICIPATING_AGAIN)
        return (
            <>
                <RaffleDisplay>
                    <RaffleParticipating raffle={getLastRaffle(raffles)} />
                </RaffleDisplay>
                {props.children}
            </>
        );

    if (raffleStatus === RAFFLE_STATE.RAFFLE_IS_CLOSED) {
        return hasSubProposal ? (
            props.children
        ) : (
            <RaffleDisplay
                label={
                    <IsClosed
                        rafflePots={rafflePots}
                        raffles={raffles}
                        child={props.children}
                        displayForProposal={props.displayForProposal}
                    />
                }
            />
        );
    }

    if (raffleStatus === RAFFLE_STATE.RAFFLE_NOT_DRAWN)
        return (
            <RaffleDisplay
                label={
                    <NotDrawn
                        rafflePot={rafflePots[rafflePots.length - 1]}
                        isProposalPage={true}
                    />
                }
            />
        );

    if (raffleStatus === RAFFLE_STATE.RAFFLE_TIME_INVALID)
        return (
            <RaffleDisplay
                label={
                    <InvalidTime
                        rafflePot={rafflePots[rafflePots.length - 1]}
                    />
                }
            />
        );

    if (
        raffleStatus === RAFFLE_STATE.RAFFLE_WAS_DRAWN ||
        raffleStatus === RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE_AGAIN
    ) {
        if (isDayOfRaffle(rafflePots[rafflePots.length - 1])) {
            return <WasDrawn raffle={rafflePots[rafflePots.length - 1]} />;
        }
        return props.children;
    }

    if (raffleStatus === RAFFLE_STATE.RAFFLE_CANNOT_PARTICIPATE) {
        return props.children;
    }

    return <ProposalDisplayClosed />;
};

export default RaffleStateContainer;
