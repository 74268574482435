import { isDefinedAndNotEmptyString } from '../../../util/Validation/ValidateDefined';

export function fetchMutator(data) {
    if (isDefinedAndNotEmptyString(data.expectedResults)) {
        data.expectedResults.forEach(function (element, index) {
            data[index] = element.toString();
        }, data.expectedResults);
        delete data.expectedResults;
    }

    return data;
}

export function saveMutator(data) {
    data.expectedResults.forEach(function (element, index) {
        if (!isDefinedAndNotEmptyString(element)) element = '99';

        this[index] = parseInt(element, 10);
    }, data.expectedResults);

    return data;
}
