import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const DialogWithConfirmation = (props) => {
    const {
        open,
        closeFunc,
        confirmFunc,
        content,
        title,
        cancelButtonText,
        confirmButtonText,
        formId,
    } = props;

    return (
        <Dialog
            open={open}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-content"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-content">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeFunc(false)} color="primary">
                    {cancelButtonText ? cancelButtonText : 'Abbrechen'}
                </Button>
                <Button
                    type={formId ? 'submit' : 'button'}
                    form={formId ? formId : undefined}
                    onClick={() => {
                        closeFunc(true);
                        if (!formId) confirmFunc();
                    }}
                    color="primary"
                    autoFocus
                >
                    {confirmButtonText ? confirmButtonText : 'Abschicken'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogWithConfirmation.propTypes = {
    closeFunc: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    confirmFunc: PropTypes.func,
    formId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
};

export default DialogWithConfirmation;
