import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, Typography } from '@material-ui/core';
import { useAuth } from '../../../Stores/Auth.store';
import {
    fetchEligibleProposals,
    fetchVerification,
} from '../../../lib/api/Verification/VerificationActions';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import { featureFlagConfig } from '../../../config/FeatureFlags';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    svg: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
    },
    span: {
        color: theme.palette.grey[800],
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const UserBar = () => {
    const user = useAuth((state) => state.user);
    const classes = useStyles();
    const history = useHistory();
    const [resubmitInvoicesVn, setResubmitInvoicesVn] = useState(null);

    const resubmitInvoicesFeatureEnabled = useFlag(
        featureFlagConfig.resubmitInvoices.name
    );

    useEffect(() => {
        const getVerifications = async () => {
            const proposals = await fetchEligibleProposals();
            const proposalWithResubmitInvoices = proposals.find(
                (p) => p?.verification?.currentStep === 'resubmit_invoices'
            );
            if (proposalWithResubmitInvoices) {
                const vn = await fetchVerification(
                    proposalWithResubmitInvoices.verification.id
                );
                setResubmitInvoicesVn(vn);
            }
        };
        if (resubmitInvoicesFeatureEnabled) {
            getVerifications();
        }
    }, [resubmitInvoicesFeatureEnabled]);

    if (!user) return null;

    return (
        <Container role="complementary" className={classes.container}>
            <div className={classes.root}>
                <Typography variant="body1">
                    {user.title ? `${user.title} ` : ''}
                    {user.firstName} {user.lastName}{' '}
                    <span className={classes.span}>
                        <MailOutlineIcon className={classes.svg} />
                        <Typography variant="body2" component="span">
                            {user.email}
                        </Typography>
                    </span>
                </Typography>
            </div>
            {resubmitInvoicesVn && (
                <Alert
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                history.push(
                                    `/nachreichen/${resubmitInvoicesVn.id}`
                                );
                            }}
                            size="small"
                            disableElevation
                        >
                            Jetzt nachreichen
                        </Button>
                    }
                    severity="warning"
                >
                    Reichen Sie bis{' '}
                    <strong>
                        {moment(resubmitInvoicesVn.resubmitUntil).format(
                            'DD.MM.YYYY [23:59 Uhr]'
                        )}
                    </strong>{' '}
                    Rechnungen nach.
                </Alert>
            )}
        </Container>
    );
};

export default UserBar;
