import _ from 'lodash';
import { isDefined } from '../../../util/Validation/ValidateDefined';

export function mutateFetch(data) {
    if (isDefined(data.companyType)) {
        if (isDefined(data.companyType.isCommunalCompany)) {
            data.companyType.isCommunalCompany =
                data.companyType.isCommunalCompany === true ? 'true' : 'false';
        }

        if (isDefined(data.companyType.isFoundedLast2Y)) {
            data.companyType.isFoundedLast2Y =
                data.companyType.isFoundedLast2Y === true ? 'true' : 'false';
        }

        if (isDefined(data.companyType.isDeductInputTax)) {
            data.companyType.isDeductInputTax =
                data.companyType.isDeductInputTax === true ? 'true' : 'false';
        }

        if (isDefined(data.companyType.employeesCount)) {
            data.companyType.employeesCount =
                '' + data.companyType.employeesCount;
        }

        data.companyType.employeeCheck =
            data.companyType?.employeeCheck === true ? 'true' : 'false';

        return data.companyType;
    }

    return data;
}

export function saveMutator(data) {
    const defaultData = {
        isCommunalCompany: false,
        hasHRGNumber: false,
        hRGNumber: '',
        hasTradeRegistered: false,
        tradeLicenceNumber: '',
        isCraftCompany: false,
        craftNumber: '',
        isFreelance: false,
        taxNumber: '',
        otherLegalForm: '',
        isFoundedLast2Y: false,
        dateFounded: null,
        isDeductInputTax: false,
        employeesCount: null,
    };

    if (isDefined(data.employeesCount))
        data.employeesCount = parseInt(data.employeesCount, 10);

    data.isCommunalCompany = data.isCommunalCompany === 'true';
    data.isFoundedLast2Y = data.isFoundedLast2Y === 'true';
    data.isDeductInputTax = data.isDeductInputTax === 'true';

    data.employeeCheck = data.employeeCheck === 'true';
    data.legalForm = data.legalForm['@id'];
    data.sectorKey = data.sectorKey['@id'];

    const mergedData = _.merge({}, defaultData, data);

    return {
        companyType: mergedData,
    };
}
