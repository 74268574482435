import React from 'react';
import Typography from '@material-ui/core/Typography';
import { raffleTexts as rt } from '../../texts/Forms/Raffle/RaffleTexts';
import { useAppState } from '../../Stores/AppState.store';
import {
    getCurrentRafflePot,
    RAFFLE_STATE,
} from '../../util/Raffle/RaffleFunctions';
import Alert from '@material-ui/lab/Alert';

const ProposalDisplayClosed = (props) => {
    const { raffleStatus, rafflePots } = useAppState((state) => ({
        raffleStatus: state.raffleStatus,
        rafflePots: state.rafflePots,
    }));

    const pot = React.useMemo(
        () => getCurrentRafflePot(rafflePots),
        [rafflePots]
    );

    const renderClosedState = () => (
        <Typography>{rt.proposalClosed.closed}</Typography>
    );

    const renderNotClosedState = () => (
        <Typography>
            {rt.proposalClosed.notClosed(pot, raffleStatus)}
        </Typography>
    );

    return (
        <div>
            <Typography variant="h4" component="h2">
                {rt.proposalClosed.title}
            </Typography>
            <Alert severity="info">
                {raffleStatus === RAFFLE_STATE.RAFFLE_IS_CLOSED
                    ? renderClosedState()
                    : renderNotClosedState()}
            </Alert>
        </div>
    );
};

export default ProposalDisplayClosed;
