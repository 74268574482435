import React from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import PropTypes from 'prop-types';

const CanParticipate = ({ isProposalPage }) => (
    <Alert severity="info">
        <Typography>
            {isProposalPage
                ? rt.canParticipateProposalPage
                : rt.canParticipate.text0}
        </Typography>
        {!isProposalPage && (
            <>
                <Typography color="error">{rt.canParticipate.text1}</Typography>
                <Typography color="error">{rt.canParticipate.text2}</Typography>
            </>
        )}
    </Alert>
);

CanParticipate.propTypes = {
    isProposalPage: PropTypes.bool,
};

export default CanParticipate;
