export const texts = {
    parser: {
        text: {
            headline: '',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Von dieser Übersichtsseite aus können Sie ',
                },
                {
                    type: 'list',
                    text: '[li]einen neuen Antrag erstellen - bitte nutzen Sie dafür den Button „Neuen Antrag erstellen“[li][li]Ihren bereits begonnenen Antrag weiterbearbeiten. [li][li]ggf. bereits abgeschlossene Anträge einsehen.[li]',
                },
                {
                    type: 'p',
                    text: 'Es kann parallel immer nur ein Antrag erstellt bzw. bearbeitet werden.',
                },
            ],
        },
        text2: {
            headline: '',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Das Förderportal führt Sie sicher durch die folgenden Themenblöcke:',
                },
                {
                    type: 'list',
                    text: '[li]Kontaktdaten des Antragstellenden;[li][li]Unternehmensdaten;[li][li]Digitalisierungsplan;[li][li]Finanzierungsplan.[li]',
                },
                {
                    type: 'p',
                    text: 'Über den Link „[a|/]Startseite[a]“ in der Menüleiste gelangen Sie wieder auf die Startseite des Förderportals. Über den Link „[a|/abmelden]Abmelden[a]“ in der Menüleiste können Sie sich jederzeit abmelden.',
                },
                {
                    type: 'p',
                    text: 'Bitte stellen Sie dabei sicher, dass Sie die bei der Antragstellung eingegebenen Informationen im Förderportal fortlaufend zwischenspeichern (über den Link „Speichern“ oder „Speichern & Weiter“). Somit ist eine sukzessive Bearbeitung möglich und Ihre Eingaben gehen nicht verloren.',
                },
                {
                    type: 'p',
                    text: 'Über den Link „Zurück“ können Sie jederzeit Eingaben in den vorangegangenen Themenblöcken korrigieren. ',
                },
                {
                    type: 'p',
                    text: 'Das Förderportal unterstützt Sie bei der Antragstellung mit spezifischen Hinweistexten zum Anklicken oder Mouse-over (u.a. mit Erläuterungen zu notwendigen Informationen und mit Definitionen von Fachbegriffen). Zudem weist das Förderportal Sie frühzeitig auf ggf. falsche Eingaben und Kriterien der Nicht-Förderfähigkeit hin.',
                },
                {
                    type: 'p',
                    text: ' In der abschließenden Übersicht wird Ihnen die voraussichtlich zu erwartende Förderung (Höhe der Zuwendung, Förderquote) anhand Ihrer gemachten Angaben des Unternehmens aufgezeigt.',
                },
            ],
        },
        overview: {
            headline: 'Ihre Antragsdaten',
            paragraphs: [],
        },
        overviewError: {
            headline: 'Ihre Antragsdaten',
            paragraphs: [
                {
                    type: 'p',
                    text: 'Beim Laden Ihrer Antragsdaten ist ein Fehler aufgetreten.',
                },
            ],
        },
    },
    static: {
        overviewErrorButton: 'Daten erneut laden',
        cantCreate:
            'Es kann kein neuer Antrag erstellt werden, da sich entweder bereits ein laufender oder eingereichter Antrag in Bearbeitung befindet.',
    },
};
