import React from 'react';
import { Response, TYPE } from '../../util/DataTypes/Response';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../Stores/Auth.store';

const LogoutContainer = () => {
    const logout = useAuth((state) => state.logout);
    const [response, setResponse] = React.useState(null);

    React.useEffect(() => {
        logout()
            .then(() => {
                setResponse(
                    Response.Success('Sie haben sich erfolgreich abgemeldet.')
                );
            })
            .catch(() => {
                setResponse(
                    Response.Error('Beim Abmelden ist ein Fehler aufgetreten.')
                );
            });
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                Abmelden
            </Typography>
            {!response && <Typography>Sie werden abgemeldet...</Typography>}
            {response?.type() === TYPE.SUCCESS && (
                <Typography>{response.message()}</Typography>
            )}
            {response?.type() === TYPE.ERROR && (
                <Typography>{response.message()}</Typography>
            )}
        </>
    );
};

export default LogoutContainer;
