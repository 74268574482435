export const transitionTexts = {
    submitError: {
        generic:
            'Auf Grund der aktuell sehr hohen Nachfrage im Antragstool ist eine Zeitüberschreitung aufgetreten. Ihr Antrag wurde nicht eingereicht. Bitte versuchen Sie es erneut.',
        423: 'Der Antrag konnte nicht eingereicht werden, da Sie einen ungültigen Durchführungszeitraum angegeben haben.',
        //500: 'Beim Einreichen des Antrags ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        //502: 'Beim Einreichen des Antrags kam es zu einer Zeitüberschreitung. Bitte versuchen Sie es später erneut.',
        //504: 'Beim Einreichen des Antrags kam es zu einer Zeitüberschreitung. Bitte versuchen Sie es später erneut.',
    },
    success: {
        submitConfirm: 'Antrag erfolgreich eingereicht.', //to step 11
        confirm: 'Antrag erfolgreich eingereicht.', //to step 12
        changed: 'Wizardschritt erfolgreich gewechselt.', //Generic changed
    },
};
