import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
    parseCentToString,
    parseToCentString,
} from '../../../util/Raffle/RaffleFunctions';
import { Controller } from 'react-hook-form';
import MoneyInput from '../../Partials/Form/MoneyInput';

const FormMoney = (props) => {
    const {
        control,
        label,
        name,
        error,
        rules,
        defaultValue,
        notEditable,
        adornment,
        placeholder,
        onChangeExternal,
    } = props;

    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        onChange(parseToCentString(e.target.value));

        onChangeExternal({
            ...e,
            target: {
                ...e.target,
                value: parseToCentString(e.target.value),
            },
        });
    };

    return (
        <Controller
            render={({ onChange, value }) => {
                return (
                    <TextField
                        onChange={handleChange(onChange)}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        value={parseCentToString(value)}
                        label={label}
                        disabled={notEditable}
                        name={name}
                        InputProps={{
                            inputComponent: MoneyInput,
                            endAdornment: adornment,
                        }}
                        error={Boolean(error)}
                        helperText={Boolean(error) ? error.message : undefined}
                        placeholder={placeholder}
                        fullWidth
                    />
                );
            }}
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
        />
    );
};

FormMoney.propTypes = {
    control: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.object,
    rules: PropTypes.object,
    defaultValue: PropTypes.string,
    notEditable: PropTypes.bool,
    adornment: PropTypes.node,
    placeholder: PropTypes.string,
    onChangeExternal: PropTypes.func,
};

FormMoney.defaultProps = {
    onChangeExternal: () => {},
};

export default FormMoney;
