import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { Collapse } from '@material-ui/core';

const ConditionalDisplay = (props) => {
    const { control, defaultValue, field, display, children } = props;

    const value = useWatch({
        control: control,
        name: field,
        defaultValue: defaultValue,
    });

    const show = React.useMemo(() => {
        return value === display;
    }, [display, value]);

    return (
        <Collapse in={show} unmountOnExit>
            {children}
        </Collapse>
    );
};

ConditionalDisplay.propTypes = {
    field: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    display: PropTypes.any.isRequired,
    defaultValue: PropTypes.any,
    children: PropTypes.node,
};

export default ConditionalDisplay;
