import React from 'react';
import PropTypes from 'prop-types';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import moment from 'moment';
import TextBlock from '../../TextBlock/TextBlock';
import LinkSameSite from '../../TextBlock/components/LinkSameSite';
import Alert from '@material-ui/lab/Alert';

const WasDrawn = (props) => {
    const { raffle } = props;

    const block = rt.wasDrawn(
        moment(raffle.rafflePot.submitBegin).format('LLL'),
        moment(raffle.rafflePot.submitEnd).format('LLL')
    );

    return (
        <Alert severity="success">
            <TextBlock
                parserOptions={{
                    components: { a: { component: LinkSameSite, props: {} } },
                }}
                textBlock={block}
            />
        </Alert>
    );
};

WasDrawn.propTypes = {
    raffle: PropTypes.object,
};

export default WasDrawn;
