import React from 'react';
import PropTypes from 'prop-types';
import { texts } from '../../../texts/Forms/Digi2FormTexts';
import TextBlock from '../../TextBlock/TextBlock';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import TextfieldDigi2 from './TextfieldDigi2';
import Digi2ProjectPeriod from './Digi2ProjectPeriod';
import Alert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TYPE } from '../../../util/DataTypes/Response';
import LinkOtherPage from '../../TextBlock/components/LinkOtherPage';

const Digi2Form = (props) => {
    const { defaultValues, saveResponse, onSubmit, formId } = props;

    const {
        register,
        errors,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        watch,
    } = useForm({
        defaultValues: defaultValues,
    });
    const [showExtended, setShowExtended] = React.useState(false);
    const [showPeriodHint, setShowPeriodHint] = React.useState(false);

    const setPeriodError = React.useCallback(() => {
        setError('startDate', {
            type: 'manual',
            message: texts.config.date.diffBelowZero,
        });
        setError('endDate', {
            type: 'manual',
            message: texts.config.date.diffBelowZero,
        });
    }, [setError]);

    const handleDiffChange = React.useCallback(
        (diff) => {
            setShowPeriodHint(false);
            clearErrors(['diff', 'startDate', 'endDate']);
            setValue('diff', diff);
            if (diff < 0) {
                setPeriodError();
            }
            if (diff < 12) {
                setShowExtended(false);
                if (diff < 2) {
                    setShowPeriodHint(true);
                }
                return;
            }

            if (diff > 12 && defaultValues.isValueChain) {
                setShowExtended(true);
                return;
            }

            setError('diff', { type: 'manual', message: texts.diff.error });
        },
        [
            clearErrors,
            setPeriodError,
            defaultValues.isValueChain,
            setValue,
            setError,
        ]
    );

    const {
        moduleOne,
        moduleTwo,
        digitizeModel,
        digitalTechnologies,
        digitalWork,
        employeeCompetence,
        security,
        digitalNetworking,
    } = watch([
        'moduleOne',
        'moduleTwo',
        'digitizeModel',
        'digitalTechnologies',
        'digitalWork',
        'employeeCompetence',
        'security',
        'digitalNetworking',
    ]);

    const [modulePickError, setModulePickError] = React.useState(false);
    React.useEffect(() => {
        if (!moduleOne && !moduleTwo) {
            setModulePickError(true);
            return;
        }
        setModulePickError(false);
    }, [moduleTwo, moduleOne]);

    const [showSecurity, setShowSecurity] = React.useState(false);
    const [atLeastOneError, setAtLeastOneError] = React.useState(false);
    const [otherValue, setOtherValue] = React.useState('');
    React.useEffect(() => {
        const chosen = [
            digitizeModel,
            digitalTechnologies,
            digitalWork,
            employeeCompetence,
            digitalNetworking,
        ].filter((el) => el === true).length;

        setShowSecurity(security && (chosen > 0 || otherValue));
        setAtLeastOneError(!security && chosen === 0 && !otherValue);
    }, [
        digitizeModel,
        digitalTechnologies,
        digitalWork,
        employeeCompetence,
        security,
        digitalNetworking,
        otherValue,
    ]);

    const handleOtherCallback = (value) => {
        if (value !== otherValue) setOtherValue(value);
    };

    const submitCallback = (values) => {
        if (!moduleOne && !moduleTwo) {
            setModulePickError(true);
            return;
        }

        if (atLeastOneError) {
            return;
        }

        onSubmit(values);
    };
    React.useEffect(() => {
        register(
            { name: 'startDate' },
            {
                required: texts.config.dateStart.required,
            }
        );
        register(
            { name: 'endDate' },
            {
                required: texts.config.dateEnd.required,
            }
        );
        register({ name: 'diff' }, {});
    }, [register, defaultValues.isValueChain]);

    //Handle Sideeffects when default values change
    React.useEffect(() => {
        handleDiffChange(defaultValues.diff);
        setOtherValue(defaultValues.other);
    }, [defaultValues, handleDiffChange]);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    return (
        <form
            id={formId}
            onSubmit={(e) => {
                clearErrors();
                handleSubmit(submitCallback)(e);
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {texts.static.title}
            </Typography>
            <TextBlock
                textBlock={texts.investType}
                paragraphProps={{ style: { marginTop: '16px' } }}
                headlineVariant="h5"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.module1Headline}
            </Typography>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register({})}
                        defaultChecked={defaultValues.moduleOne}
                    />
                }
                label={
                    <TextBlock
                        textBlock={texts.module1}
                        parserOptions={{
                            components: { a: { component: LinkOtherPage } },
                        }}
                    />
                }
                name="moduleOne"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.module2Headline}
            </Typography>
            <FormControl>
                <FormControlLabel
                    style={{ alignItems: 'flex-start' }}
                    control={
                        <Checkbox
                            style={{ marginTop: '-4px' }}
                            color="primary"
                            inputRef={register({})}
                            defaultChecked={defaultValues.moduleTwo}
                        />
                    }
                    label={
                        <TextBlock
                            textBlock={texts.module2}
                            parserOptions={{
                                components: { a: { component: LinkOtherPage } },
                            }}
                        />
                    }
                    name="moduleTwo"
                />
            </FormControl>

            {modulePickError && (
                <Typography style={{ color: '#f44336' }}>
                    {texts.config.module.atLeastOne}
                </Typography>
            )}

            <Typography variant="h4" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.dataHeadline}
            </Typography>

            <Alert severity="info">
                <TextBlock textBlock={texts.title.hint} headlineVariant="h6" />
            </Alert>

            <TextfieldDigi2
                name="title"
                defaultValue={defaultValues.title}
                Label={
                    <TextBlock textBlock={texts.title} headlineVariant="h6" />
                }
                validation={{ required: texts.config.titleField.required }}
                register={register}
                max={texts.config.titleField.maxChars}
                error={errors.title}
            />

            <Digi2ProjectPeriod
                setValue={setValue}
                errors={errors}
                defaultValues={defaultValues}
                onDiffChange={handleDiffChange}
                showPeriodHint={showPeriodHint}
            />

            {errors.diff && (
                <Typography style={{ color: '#f44336' }}>
                    {errors.diff.message}
                </Typography>
            )}

            {showExtended && (
                <TextfieldDigi2
                    name="extendedExecution"
                    defaultValue={defaultValues.extendedExecution}
                    Label={<TextBlock textBlock={texts.dateTooLong} />}
                    validation={{ required: texts.config.dateTooLong.required }}
                    register={register}
                    max={texts.config.dateTooLong.maxChars}
                    error={errors.extendedExecution}
                />
            )}
            <FormControl
                error={!!errors.executionNotStarted?.message}
                style={{ marginTop: '16px' }}
            >
                <FormControlLabel
                    style={{ alignItems: 'flex-start' }}
                    control={
                        <Checkbox
                            style={{ marginTop: '-4px' }}
                            color="primary"
                            inputRef={register({
                                required:
                                    texts.config.executionNotStarted.required,
                            })}
                            defaultChecked={defaultValues.executionNotStarted}
                        />
                    }
                    name="executionNotStarted"
                    label={<TextBlock textBlock={texts.confirmDate} />}
                />
                {!!errors.executionNotStarted?.message && (
                    <FormHelperText>
                        {errors.executionNotStarted.message}
                    </FormHelperText>
                )}
            </FormControl>

            <TextBlock
                textBlock={texts.goal}
                paragraphProps={{ style: { marginTop: '16px' } }}
                headlineVariant="h4"
            />

            <Alert severity="info">
                <TextBlock textBlock={texts.goalHint} headlineVariant="h6" />
            </Alert>

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.goal1}
            </Typography>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={defaultValues.digitizeModel}
                    />
                }
                label={<TextBlock textBlock={texts.goal1} />}
                name="digitizeModel"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.goal2}
            </Typography>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={defaultValues.digitalTechnologies}
                    />
                }
                label={
                    <TextBlock textBlock={texts.goal2} headlineVariant="h6" />
                }
                name="digitalTechnologies"
            />

            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={defaultValues.digitalWork}
                    />
                }
                label={
                    <TextBlock textBlock={texts.goal2b} headlineVariant="h6" />
                }
                name="digitalWork"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.goal3}
            </Typography>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={defaultValues.employeeCompetence}
                    />
                }
                label={
                    <TextBlock textBlock={texts.goal3} headlineVariant="h6" />
                }
                name="employeeCompetence"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.goal4}
            </Typography>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        style={{ marginTop: '-4px' }}
                        color="primary"
                        inputRef={register()}
                        defaultChecked={defaultValues.security}
                    />
                }
                label={
                    <TextBlock textBlock={texts.goal4} headlineVariant="h6" />
                }
                name="security"
            />

            {defaultValues.isValueChain && (
                <>
                    <Typography
                        variant="h5"
                        style={{ marginTop: '16px' }}
                        gutterBottom
                    >
                        {texts.static.goal5}
                    </Typography>
                    <FormControlLabel
                        style={{ alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                style={{ marginTop: '-4px' }}
                                color="primary"
                                inputRef={register()}
                                defaultChecked={defaultValues.digitalNetworking}
                            />
                        }
                        label={
                            <TextBlock
                                textBlock={texts.goal5}
                                headlineVariant="h6"
                            />
                        }
                        name="digitalNetworking"
                    />
                </>
            )}

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {defaultValues.isValueChain
                    ? texts.static.goal6
                    : texts.static.goalNot6}
            </Typography>
            <TextfieldDigi2
                name="other"
                defaultValue={defaultValues.other}
                Label={
                    <TextBlock textBlock={texts.goal6} headlineVariant="h6" />
                }
                register={register}
                max={texts.config.other.maxChars}
                setCallback={handleOtherCallback}
            />

            {atLeastOneError && (
                <Typography style={{ color: '#f44336' }}>
                    {texts.config.goals.atLeastOne}
                </Typography>
            )}

            <Typography variant="h4" style={{ marginTop: '16px' }} gutterBottom>
                {texts.static.description}
            </Typography>
            <TextBlock
                textBlock={texts.description}
                paragraphProps={{ style: { marginTop: '16px' } }}
                headlineVariant="h5"
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.question1.title}
            </Typography>
            {moduleOne && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question1.titleModule1}
                </Typography>
            )}
            {moduleTwo && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question1.titleModule2}
                </Typography>
            )}
            <TextfieldDigi2
                name="explainCommon"
                defaultValue={defaultValues.explainCommon}
                register={register}
                validation={{ required: texts.config.question1.required }}
                max={texts.config.question1.maxChars}
                multiline
                rows={6}
                variant="outlined"
                error={errors.explainCommon}
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.question2.title}
            </Typography>
            {moduleOne && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question2.titleModule1}
                </Typography>
            )}
            {moduleTwo && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question2.titleModule2}
                </Typography>
            )}
            <TextfieldDigi2
                name="explainApplication"
                defaultValue={defaultValues.explainApplication}
                register={register}
                validation={{ required: texts.config.question2.required }}
                max={texts.config.question2.maxChars}
                multiline
                rows={6}
                variant="outlined"
                error={errors.explainApplication}
            />

            <Typography variant="h5" style={{ marginTop: '16px' }} gutterBottom>
                {texts.question3.title}
            </Typography>
            <Typography variant="h5" gutterBottom>
                {texts.question3.title2}
            </Typography>
            {moduleOne && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question3.titleModule1}
                </Typography>
            )}

            {moduleTwo && (
                <Typography
                    variant="h6"
                    style={{ marginTop: '16px', paddingLeft: '16px' }}
                    gutterBottom
                >
                    {texts.question3.titleModule2}
                </Typography>
            )}
            <TextfieldDigi2
                name="explainImprovements"
                defaultValue={defaultValues.explainImprovements}
                register={register}
                validation={{ required: texts.config.question3.required }}
                max={texts.config.question3.maxChars}
                multiline
                rows={6}
                variant="outlined"
                error={errors.explainImprovements}
            />

            {showSecurity && (
                <>
                    <TextBlock
                        textBlock={texts.questionSecurity}
                        headlineVariant="h5"
                    />
                    <Typography
                        variant="h6"
                        style={{ marginTop: '16px' }}
                        gutterBottom
                    >
                        {texts.questionSecurityA.title}
                    </Typography>
                    <TextfieldDigi2
                        name="securityTechnologies"
                        defaultValue={defaultValues.securityTechnologies}
                        register={register}
                        validation={{
                            required: texts.config.questionSecurityA.required,
                        }}
                        max={texts.config.questionSecurityA.maxChars}
                        multiline
                        rows={6}
                        variant="outlined"
                        error={errors.securityTechnologies}
                    />

                    <Typography
                        variant="h6"
                        style={{ marginTop: '16px' }}
                        gutterBottom
                    >
                        {texts.questionSecurityB.title}
                    </Typography>
                    <TextfieldDigi2
                        name="securityTargets"
                        defaultValue={defaultValues.securityTargets}
                        register={register}
                        validation={{
                            required: texts.config.questionSecurityB.required,
                        }}
                        max={texts.config.questionSecurityB.maxChars}
                        multiline
                        rows={6}
                        variant="outlined"
                        error={errors.securityTargets}
                    />
                </>
            )}

            {defaultValues.isValueChain && (
                <>
                    <TextBlock
                        textBlock={texts.valueChain}
                        headlineVariant="h5"
                    />
                    <Typography
                        variant="h6"
                        style={{ marginTop: '16px' }}
                        gutterBottom
                    >
                        {texts.valueChain1.title}
                    </Typography>
                    <TextfieldDigi2
                        name="valueChainRole"
                        defaultValue={defaultValues.valueChainRole}
                        register={register}
                        validation={{
                            required: texts.config.valueChain1.required,
                        }}
                        max={texts.config.valueChain1.maxChars}
                        multiline
                        rows={6}
                        variant="outlined"
                        error={errors.valueChainRole}
                    />

                    <Typography
                        variant="h6"
                        style={{ marginTop: '16px' }}
                        gutterBottom
                    >
                        {texts.valueChain2.title}
                    </Typography>
                    <TextfieldDigi2
                        name="valueChainImprove"
                        defaultValue={defaultValues.valueChainImprove}
                        register={register}
                        validation={{
                            required: texts.config.valueChain2.required,
                        }}
                        max={texts.config.valueChain2.maxChars}
                        multiline
                        rows={6}
                        variant="outlined"
                        error={errors.valueChainImprove}
                    />
                </>
            )}
        </form>
    );
};

Digi2Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

export default Digi2Form;
