import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { texts as ppt } from '../../texts/Pages/PagesTexts';

export function errorFallback({ resetErrorBoundary }) {
    return (
        <div role="alert">
            <Typography
                variant="h3"
                component="h1"
                style={{ marginBottom: '1rem' }}
            >
                500 - {ppt.critical.headline}
            </Typography>
            <Button
                color="primary"
                variant="contained"
                onClick={resetErrorBoundary}
            >
                {ppt.critical.button}
            </Button>
        </div>
    );
}

export default errorFallback;
