import moment from 'moment';

export const RAFFLE_STATE = {
    RAFFLE_CAN_PARTICIPATE: 0,
    RAFFLE_IS_PARTICIPATING: 1,
    RAFFLE_WAS_DRAWN: 2,
    RAFFLE_NOT_DRAWN: 3,
    RAFFLE_CANNOT_PARTICIPATE: 4,
    RAFFLE_TIME_INVALID: 5,
    RAFFLE_IS_CLOSED: 6,
    RAFFLE_UNKNOWN: 7,
    RAFFLE_CAN_PARTICIPATE_AGAIN: 8,
    RAFFLE_IS_PARTICIPATING_AGAIN: 9,
};

export function sortRaffles(raffles) {
    return raffles.sort(
        (a, b) =>
            moment(b.rafflePot.dayOfRaffle) - moment(a.rafflePot.dayOfRaffle)
    ); //DESC;
}

export function isDayOfRaffle(raffle) {
    return moment().isBetween(
        moment(raffle.dayOfRaffle),
        moment(raffle.submitBegin)
    );
}

export function sortRafflePots(pots) {
    return pots.sort((a, b) => moment(b.raffleEnd) - moment(a.raffleEnd));
}

export function getCurrentRafflePot(pots) {
    if (!pots)
        throw new Error('Should not call function with empty parameters');
    const now = moment();
    return pots.find(
        (pot) => moment(pot.raffleBegin) <= now && moment(pot.raffleEnd) > now
    );
}

export function getRaffleOfCurrentPot(currentPot, raffles) {
    return raffles.find((raffle) => {
        if (!raffle.rafflePot) return false;
        return raffle.rafflePot['@id'] === currentPot['@id'];
    });
}

export function checkSubmitEnd(raffle) {
    if (moment(raffle.rafflePot.submitEnd) < moment())
        return RAFFLE_STATE.RAFFLE_TIME_INVALID;
    return RAFFLE_STATE.RAFFLE_WAS_DRAWN;
}

export function canCreateNewRaffle(raffle) {
    return !Boolean(raffle.proposal.submittedAt);
}

export function getRaffleState(raffles, rafflePots) {
    const currentPot = getCurrentRafflePot(rafflePots);
    if (!currentPot) return RAFFLE_STATE.RAFFLE_IS_CLOSED;

    if (!raffles || raffles.length === 0)
        return RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE;

    if (!raffles || filterDeniedProposals(raffles).length === 0)
        return RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE_AGAIN;

    let raffle = getRaffleOfCurrentPot(currentPot, raffles);
    if (raffle && raffles.length > 1)
        return RAFFLE_STATE.RAFFLE_IS_PARTICIPATING_AGAIN;
    if (raffle) return RAFFLE_STATE.RAFFLE_IS_PARTICIPATING;

    raffle = raffles[0]; //DESC Order: latest = index 0
    if (!raffle.valid) return RAFFLE_STATE.RAFFLE_NOT_DRAWN;

    if (!raffle.proposal || !raffle.proposal.submittedAt) {
        return checkSubmitEnd(raffle);
    }

    if (canCreateNewRaffle(raffle)) return RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE;
    return RAFFLE_STATE.RAFFLE_CANNOT_PARTICIPATE;
}

export function hasProposal(raffles) {
    return (
        raffles.filter(
            (raffle) => raffle.proposal && Boolean(raffle.proposal.submittedAt)
        ).length > 0
    );
}

export function parseStateToString(state) {
    switch (state) {
        case RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE:
        case RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE_AGAIN:
            return 'Bevor Sie einen Antrag stellen können, muss Ihnen ein Antragsbudget zugewiesen worden sein. Füllen Sie dazu bitte das Formular unter "Bewerbung Antragskontingent" aus und bestätigen Sie, dass Sie an der Zuweisung teilnehmen wollen.';
        case RAFFLE_STATE.RAFFLE_IS_PARTICIPATING:
        case RAFFLE_STATE.RAFFLE_IS_PARTICIPATING_AGAIN:
            return 'Sie nehmen an der nächsten Ziehung teil.';
        case RAFFLE_STATE.RAFFLE_WAS_DRAWN:
            return 'Sie wurden in der letzten Ziehung gezogen.';
        case RAFFLE_STATE.RAFFLE_NOT_DRAWN:
            return 'Sie wurden leider nicht in der letzten Ziehung gezogen und nehmen an der aktuellen Ziehung noch nicht teil.';
        case RAFFLE_STATE.RAFFLE_CANNOT_PARTICIPATE:
            return 'Sie können an der aktuellen Ziehung nicht Teilnehmen.';
        case RAFFLE_STATE.RAFFLE_TIME_INVALID:
            return 'Sie haben Ihren letzten Antrag leider nicht rechtzeitig eingereicht.';
        case RAFFLE_STATE.RAFFLE_IS_CLOSED:
            return 'Die aktuelle Ziehung ist demnächst. Sollten Sie gezogen werden, erhalten Sie eine E-Mail mit weiteren Anweisungen.';
        case RAFFLE_STATE.RAFFLE_UNKNOWN:
        default:
            return '';
    }
}

export function canCreateNewProposal(raffles) {
    const valid = raffles.filter((raffle) => raffle.valid === true);
    if (valid.length === 0) return false;

    const validWithProposal = valid.filter((v) => Boolean(v.proposal));
    return validWithProposal.length === 0;
}

export function verifyEmployeeCount(employees) {
    return employees < 3 || employees > 499;
}

export function verifyTrue(value) {
    return value === true;
}

export function filterDeniedProposals(pots) {
    return pots.filter((pot) => !pot?.proposal?.denied);
}

export function filterExpiredPots(pots) {
    return pots.filter((pot) => moment(pot.submitEnd) >= moment());
}

export function getRaffleDate(pot) {
    return moment(pot.raffleEnd).add(1, 'day').startOf('day');
}

export function getLastRaffle(raffles) {
    if (!raffles || raffles.length === 0) return {};
    return raffles[0];
}

export function parseToCentString(value) {
    const removedDot = value.replace(/\./g, '');
    if (removedDot === '000') return '0';
    return removedDot.replace(/^0+/, '');
}

export function parseCentToString(value) {
    if (!value) return value;
    return value.slice(0, value.length - 2) + '.' + value.slice(-2);
}

export function employeeValue(value) {
    if (value === undefined || value === null) return null;
    return value;
}

export function employeeChange(onChange) {
    return (e) => {
        onChange(parseInt(e.target.value));
    };
}
