import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';

const FinalPlanTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Bezeichnung</TableCell>
                <TableCell>Summe</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default FinalPlanTableHeader;
