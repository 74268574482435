import React from 'react';
import { useSnackbar } from 'notistack';
import { Redirect, useParams } from 'react-router-dom';
import { getWizardState } from '../../lib/api/Wizard/WizardActions';
import useAsync from '../../hooks/useAsync';
import WizardContainer from '../Wizard/WizardContainer';
import ReloadAction from '../../components/Partials/Actions/ReloadAction';
import { steps } from '../../config/WizardSteps';
import FormSkelleton from '../../components/Forms/FormSkelleton';

const ProposalEditContainer = (props) => {
    const { proposalId } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mounted = React.useRef(true);

    const wizardState = React.useMemo(
        () => getWizardState(proposalId),
        [proposalId]
    );

    React.useEffect(
        () => () => {
            mounted.current = false;
        },
        [mounted]
    );

    const {
        pending: loadingWizard,
        value: valueWizard,
        error: errorWizard,
        execute: executeWizard,
    } = useAsync(wizardState, mounted);

    React.useEffect(() => {
        if (!errorWizard) return;
        enqueueSnackbar(errorWizard.message(), {
            variant: errorWizard.type(),
            action: ReloadAction(executeWizard, closeSnackbar),
        });
    }, [errorWizard, enqueueSnackbar, executeWizard, closeSnackbar]);

    if (loadingWizard) return <FormSkelleton />;

    if (parseInt(valueWizard?.currentStep) === steps.length) {
        return <Redirect to={`/eingereicht/${proposalId}`} />;
    }

    return (
        <WizardContainer
            currentStep={parseInt(valueWizard?.currentStep || 0)}
            proposalId={proposalId}
            reloadProposal={executeWizard}
        />
    );
};

export default ProposalEditContainer;
