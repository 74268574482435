import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStore } from './Minimis.store';

const usePickerStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    root: {
        marginTop: theme.spacing(0),
    },
}));

const MinimisCompanyPicker = (props) => {
    const { companies, onChange, label, helperText } = props;
    const classes = usePickerStyles();
    const items = useStore((state) => state.companies);

    const [test, setTest] = React.useState([...companies]);

    React.useEffect(() => {
        setTest([...companies]);
    }, [companies]);

    const handleChange = (event) => {
        if (event.target.value.length > test.length) {
            const newValue = _.difference(event.target.value, test);
            onChange(newValue[0]);
        } else {
            const deletedValue = _.difference(test, event.target.value);
            onChange(deletedValue[0], false);
        }
        setTest(event.target.value);
    };
    return (
        <FormControl fullWidth className={classes.root}>
            <InputLabel>{label}</InputLabel>
            <Select
                style={{ boxSizing: 'border-box' }}
                multiple
                value={test}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                className={classes.chip}
                            />
                        ))}
                    </div>
                )}
            >
                {items.map((item) => (
                    <MenuItem value={item.value} key={`picker_${item.key}`}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

MinimisCompanyPicker.propTypes = {
    companies: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
};

export default MinimisCompanyPicker;
