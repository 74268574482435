import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { texts as dbt } from '../../texts/Dashboard/DashboardTexts';
import moment from 'moment';

const DashboardAnon = () => {
    const is2024 = parseInt(moment().format('YYYY')) > 2023;

    return (
        <>
            <Typography variant="h3" component="h1" gutterBottom>
                {dbt.static.headlineLoggedOut}
            </Typography>
            <Alert severity="info">
                <Typography gutterBottom>
                    Sehr geehrte Antragstellerinnen und Antragsteller,
                </Typography>
                <Typography gutterBottom>
                    <strong>
                        die Förderrichtlinie „Digital jetzt –
                        Investitionsförderung für KMU“ ist gemäß Punkt 7 der
                        Richtlinie bis zum 31.12.2023 befristet.
                    </strong>
                    {is2024 && (
                        <strong>
                            {' '}
                            Das Förderprogramm ist ausgelaufen und es können
                            keine Anträge mehr gestellt werden.
                        </strong>
                    )}
                </Typography>
                {!is2024 ? (
                    <>
                        <Typography gutterBottom>
                            Aufgrund der Befristung ist eine Bewerbung auf ein
                            Antragskontingent nicht mehr möglich.
                        </Typography>

                        {dbt.anonDashboard.map((line, row) => (
                            <Typography key={`anonDash-${row}`} gutterBottom>
                                {line}
                            </Typography>
                        ))}
                    </>
                ) : (
                    <>
                        <Typography gutterBottom>
                            Das Förderportal ist weiterhin für das{' '}
                            <strong>
                                Einreichen des Verwendungsnachweises
                            </strong>{' '}
                            für abgeschlossene Projekte geöffnet. Bitte loggen
                            Sie sich dazu ein.
                        </Typography>
                        <Typography gutterBottom>
                            Für die Nutzung des Tools ist einer der folgenden
                            Browser notwendig: Mozilla Firefox, Google Chrome,
                            Safari (ab 11.1), Microsoft Edge (ab Version 84)
                            oder Opera. Bitte verwenden Sie die aktuelle Version
                            des Browsers (inklusive Aktivierung von JavaScript).
                        </Typography>
                    </>
                )}
            </Alert>
        </>
    );
};

export default DashboardAnon;
