import _ from 'lodash';

export function fetchMutator(data) {
    const { digiData, financeData, taxData } = data;

    financeData.proofs.forEach((proof) => {
        proof.deviceList.forEach((device) => {
            device.amount = '' + device.amount;

            if (taxData.companyType.isDeductInputTax) {
                if (device.netPrice === '0' && device.price !== '0') {
                    device.netPrice = device.price;
                    device.price = '0';
                }

                if (device.price !== '0') device.price = '0';
            } else {
                if (device.netPrice !== '0' && device.price === '0') {
                    device.price = device.netPrice;
                    device.netPrice = '0';
                }

                if (device.netPrice !== '0') device.netPrice = '0';
            }
        });

        proof.deviceList = _.sortBy(
            proof.deviceList,
            (value) => value.position
        );

        proof.activityList.forEach((activity) => {
            activity.amount = '' + activity.amount;

            if (taxData.companyType.isDeductInputTax) {
                if (activity.netPrice === '0' && activity.price !== '0') {
                    activity.netPrice = activity.price;
                    activity.price = '0';
                }

                if (activity.price !== '0') activity.price = '0';
            } else {
                if (activity.netPrice !== '0' && activity.price === '0') {
                    activity.price = activity.netPrice;
                    activity.netPrice = '0';
                }

                if (activity.netPrice !== '0') activity.netPrice = '0';
            }
        });

        proof.activityList = _.sortBy(
            proof.activityList,
            (value) => value.position
        );

        proof.proofType = '' + proof.proofType;
        proof.hasModuleTwo = '' + proof.hasModuleTwo;
        proof.files = {
            device: [],
            certificate: [],
        };
    });

    return {
        ...financeData,
        moduleOne: digiData.moduleOne,
        moduleTwo: digiData.moduleTwo,
        deductInputTax: taxData.companyType.isDeductInputTax,
    };
}

export function saveMutator(data) {
    const mediaLinks = [];
    const proofs = _.cloneDeep(data.proofs);

    proofs.forEach((proof) => {
        proof.files.device.forEach((file) => {
            if (file.mediaLinks) {
                mediaLinks.push({
                    uri: file.mediaLinks['@id'],
                    keywords: file.mediaLinks.keywords?.filter(
                        (str) => str !== 'tmp'
                    ),
                });
            }
        });

        proof.files.certificate.forEach((file) => {
            if (file.mediaLinks) {
                mediaLinks.push({
                    uri: file.mediaLinks['@id'],
                    keywords: file.mediaLinks.keywords?.filter(
                        (str) => str !== 'tmp'
                    ),
                });
            }
        });

        proof.proofType = parseInt(proof.proofType);
        proof.isNotBasicEquipment =
            proof.isNotBasicEquipment === 'true' ||
            proof.isNotBasicEquipment === true;
        proof.hasModuleTwo =
            proof.hasModuleTwo === 'true' || proof.hasModuleTwo === true;

        proof.deviceList.forEach((device) => {
            delete device.id;
            device.isCombination =
                device.isCombination === 'true' ||
                device.isCombination === true;
            device.position = parseInt(device.position);
        });

        proof.activityList.forEach((activity) => {
            delete activity.id;
            activity.isCombination =
                activity.isCombination === 'true' ||
                activity.isCombination === true;
            activity.position = parseInt(activity.position);
        });

        delete proof.files;
    });

    return { data: proofs, media: mediaLinks };
}
