import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const FormCheckbox = (props) => {
    const {
        label,
        control,
        defaultValue,
        name,
        rules,
        error,
        notEditable,
        disabled,
        checkedIcon,
        indeterminateIcon,
        icon,
        onChangeExternal,
        ...rest
    } = props;

    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        onChange(e.target.checked);
        onChangeExternal(e.target.checked);
    };

    return (
        <FormControl error={Boolean(error)}>
            <FormControlLabel
                control={
                    <Controller
                        name={name}
                        render={({ value, onChange }) => (
                            <Checkbox
                                checked={value}
                                onChange={handleChange(onChange)}
                                color="primary"
                                disabled={disabled || notEditable}
                                disableRipple={notEditable}
                                checkedIcon={checkedIcon}
                                indeterminateIcon={indeterminateIcon}
                                icon={icon}
                                {...rest}
                            />
                        )}
                        rules={rules}
                        control={control}
                        defaultValue={defaultValue}
                    />
                }
                label={label}
            />
            {Boolean(error) && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

FormCheckbox.propTypes = {
    control: PropTypes.object,
    label: PropTypes.any,
    defaultValue: PropTypes.bool,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    checkedIcon: PropTypes.node,
    indeterminateIcon: PropTypes.node,
    icon: PropTypes.node,
    onChangeExternal: PropTypes.func,
};

FormCheckbox.defaultProps = {
    defaultValue: false,
    notEditable: false,
    disabled: false,
    checkedIcon: <CheckBoxIcon />,
    indeterminateIcon: <IndeterminateCheckBoxIcon />,
    icon: <CheckBoxOutlineBlankIcon />,
    onChangeExternal: () => {},
};

export default FormCheckbox;
