import React from 'react';
import PropTypes from 'prop-types';
import ProofTable from '../Components/ProofTable';
import DisplayTable from './DisplayTable';

const ProofShow = (props) => {
    const { editStart, handleAdd, deductInputTax, editIndex } = props;

    return (
        <>
            <ProofTable
                renderTable={DisplayTable}
                editStart={editStart}
                handleAdd={handleAdd}
                deductInputTax={deductInputTax}
                editIndex={editIndex}
            />
        </>
    );
};

ProofShow.propTypes = {
    editStart: PropTypes.func,
    handleAdd: PropTypes.func,
    deductInputTax: PropTypes.bool,
};

export default ProofShow;
