import React from 'react';
import PropTypes from 'prop-types';

const TextBold = ({ text, weight, decoration }) => {
    return (
        <span style={{ fontWeight: weight, textDecoration: decoration }}>
            {text}
        </span>
    );
};

TextBold.propTypes = {
    text: PropTypes.string,
    weight: PropTypes.string,
    decoration: PropTypes.string,
};

export default TextBold;
