import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator, saveMutator } from './ValueChainMutators';

/*const dummyAsync = (data, timeout = 2000) =>
    new Promise((resolve, reject) => setTimeout(() => resolve(data), timeout));

const valueChainData = {
    data: {
        isValueChain: true,
        isLead: true,
        givenCode: 'DJ_WSK_1234567890',
        leadCode: 'DJ_WSK_0987654321',
        linkedCompanies: [
            {
                name: 'Company 1',
                location: 'Location 1',
            },
            {
                name: 'Company 2',
                location: 'Location 2',
            },
            {
                name: 'Company 3',
                location: 'Location 3',
            },
        ],
    },
};*/

export function fetchValueChainData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/step_0`
                );
                resolve(fetchMutator(data));
            } catch (error) {
                reject(
                    Response.Error(
                        'Die angegebenen Daten konnten nicht geladen werden.',
                        error
                    )
                );
            }
        });
    };
}

export function saveValueChainData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_0`,
                saveMutator(data)
            );
            resolve(Response.Success('Daten erfolgreich gespeichert.'));
        } catch (error) {
            reject(
                Response.Error(
                    'Die Daten konnten nicht gespeichert werden',
                    error
                )
            );
        }
    });
}
