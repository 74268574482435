import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { Collapse, TableCell } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Dinero from 'dinero.js';

const CollapsibleRow = (props) => {
    const { open, rowData, proofId, description } = props;

    const device = rowData[0];
    const activity = rowData[1];

    [device, activity].map((el) => el.sort((a, b) => a.sequence - b.sequence));

    return (
        <TableRow>
            <TableCell
                colSpan={1000}
                style={{ paddingTop: 0, paddingBottom: 0 }}
            >
                <Collapse unmountOnExit in={open}>
                    <Box margin={1}>
                        <Typography variant="h6">{description}</Typography>
                        <Table size="small">
                            <TableBody>
                                {device.length > 0 && (
                                    <>
                                        <TableRow>
                                            <TableCell colSpan={1000}>
                                                Liste der
                                                Investitionsgegenstände (Modul
                                                1)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: 1 }} />
                                            <TableCell>Bezeichnung</TableCell>
                                            <TableCell style={{ width: 1 }}>
                                                Summe
                                            </TableCell>
                                        </TableRow>
                                        {device.map((el, id) => (
                                            <TableRow key={`${proofId}_${id}`}>
                                                <TableCell>
                                                    {parseInt(el.sequence) + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {el.description}
                                                </TableCell>
                                                <TableCell>
                                                    {new Dinero({
                                                        amount:
                                                            parseInt(
                                                                el.totalSum
                                                            ) || 0,
                                                    }).toFormat()}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                                {activity.length > 0 && (
                                    <>
                                        <TableRow>
                                            <TableCell colSpan={1000}>
                                                Aktivitätenliste (Modul 2)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: 1 }} />
                                            <TableCell>Bezeichnung</TableCell>
                                            <TableCell style={{ width: 1 }}>
                                                Summe
                                            </TableCell>
                                        </TableRow>
                                        {activity.map((el, id) => (
                                            <TableRow key={`${proofId}_${id}`}>
                                                <TableCell>
                                                    {parseInt(el.sequence) + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {el.description}
                                                </TableCell>
                                                <TableCell>
                                                    {new Dinero({
                                                        amount:
                                                            parseInt(
                                                                el.totalSum
                                                            ) || 0,
                                                    }).toFormat()}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

CollapsibleRow.propTypes = {
    open: PropTypes.bool,
    rowData: PropTypes.array,
    proofId: PropTypes.string,
};

CollapsibleRow.defaultProps = {
    rowData: [[], []],
};

export default CollapsibleRow;
