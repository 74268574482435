import moment from 'moment';

export function saveMutator({ state: data, values }) {
    const returnObj = {};
    returnObj.minimis = [];

    const currentYear = new Date().getFullYear();
    if (values.received === 'true') {
        data.oneMainA.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                received: true,
                '@id': null,
                id: null,
                year: currentYear.toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                executionDate: moment(minimi.executionDate).format(),
            });
        });
        data.twoMainA.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                received: true,
                '@id': null,
                id: null,
                year: (currentYear - 1).toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                executionDate: moment(minimi.executionDate).format(),
            });
        });
        data.threeMainA.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                received: true,
                '@id': null,
                id: null,
                year: (currentYear - 2).toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                executionDate: moment(minimi.executionDate).format(),
            });
        });
    }
    if (values.requested === 'true') {
        data.oneMainB.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                '@id': null,
                id: null,
                received: false,
                year: currentYear.toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                //executionDate: minimi.executionDate
                //    ? moment(minimi.executionDate).format()
                //    : null,
            });
        });
        data.twoMainB.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                received: false,
                '@id': null,
                id: null,
                year: (currentYear - 1).toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                //executionDate: minimi.executionDate
                //    ? moment(minimi.executionDate).format()
                //    : null,
            });
        });
        data.threeMainB.forEach((minimi) => {
            returnObj.minimis.push({
                ...minimi,
                received: false,
                '@id': null,
                id: null,
                year: (currentYear - 2).toString(),
                fundingAmount: formatAmount(minimi.fundingAmount),
                investmentAmount: formatAmount(minimi.investmentAmount),
                //executionDate: minimi.executionDate
                //    ? moment(minimi.executionDate).format()
                //   : null,
            });
        });
    }

    /*
    const tempShareholders = {};

    data.companies.forEach((company) => {
        tempShareholders[company.value] = {
            '@type': 'ShareholderCompany',
            id: company.key,
            '@id': '/api/shareholder_companies/' + company.key,
            minimis: [],
        };
    });
    if (values.received === 'true') {
        _.forEach(data.oneChildA, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: true,
                    '@id': null,
                    id: null,
                    year: currentYear.toString(),
                    amount: formatAmount(minimi.amount),
                    executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
        _.forEach(data.twoChildA, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: true,
                    '@id': null,
                    id: null,
                    year: (currentYear - 1).toString(),
                    amount: formatAmount(minimi.amount),
                    executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
        _.forEach(data.threeChildA, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: true,
                    '@id': null,
                    id: null,
                    year: (currentYear - 2).toString(),
                    amount: formatAmount(minimi.amount),
                    executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
    }
    if (values.requested === 'true') {
        _.forEach(data.oneChildB, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: false,
                    '@id': null,
                    id: null,
                    year: currentYear.toString(),
                    amount: formatAmount(minimi.amount),
                    //executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
        _.forEach(data.twoChildB, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: false,
                    '@id': null,
                    id: null,
                    year: (currentYear - 1).toString(),
                    amount: formatAmount(minimi.amount),
                    //executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
        _.forEach(data.threeChildB, (minimis, shareholderId) => {
            minimis.forEach((minimi) => {
                tempShareholders[shareholderId].minimis.push({
                    ...minimi,
                    received: false,
                    '@id': null,
                    id: null,
                    year: (currentYear - 2).toString(),
                    amount: formatAmount(minimi.amount),
                    //executionDate: moment(minimi.executionDate).format(),
                });
            });
        });
    }

    returnObj.shareholderCompanies = _.map(tempShareholders, (company) => {
        return company;
    });
    */
    returnObj.minimiFields = {
        ...values,
        received: values.received === 'true',
        requested: values.requested === 'true',
        linkedCompanies: values.linkedCompanies === 'true',
    };

    returnObj.goDigitalFunded = values.goDigitalFunded === 'true';

    returnObj.isRoadTransport = values.isRoadTransport === 'true';

    return returnObj;
}

function formatAmount(fundingAmount) {
    return parseInt(
        fundingAmount.replace(',', '').replaceAll('.', ''),
        10
    ).toString(10);
}
