import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AnonNavlist from './AnonNavlist';
import AuthNavList from './AuthNavList';
import NavbarLoading from './NavbarLoading';
import InfoTopSlideIn from '../../Partials/Info/InfoTopSlideIn';
import { useAppState } from '../../../Stores/AppState.store';
import { useAuth } from '../../../Stores/Auth.store';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        padding: '1rem 0',
    },
}));

const Navbar = ({ isAuthenticated }) => {
    const isLoading = useAuth((state) => state.loading);
    const classes = useStyles();

    const {
        register: { isOpen, error, loading },
        retry,
        clear,
    } = useAppState((state) => ({
        register: state.status.register,
        retry: state.checkRegister,
        clear: state.clearRegisterError,
    }));

    if (isLoading) {
        return (
            <nav className={classes.root} role="navigation">
                <NavbarLoading />
            </nav>
        );
    }

    return (
        <nav className={classes.root} role="navigation">
            <InfoTopSlideIn
                show={Boolean(error)}
                message={error?.message()}
                retry={retry}
                clear={clear}
                loading={loading}
            />

            {isAuthenticated ? (
                <AuthNavList />
            ) : (
                <AnonNavlist canRegister={isOpen} />
            )}
        </nav>
    );
};

export default Navbar;
