import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PageRouter from './pages';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { ErrorBoundary } from 'react-error-boundary';
import externalErrorFallback from './pages/ErrorPages/External';
import Dinero from 'dinero.js';
import { QueryClientProvider } from 'react-query';
import { client } from './QueryClient';
import { useAuth } from './Stores/Auth.store';
import { getRafflePots } from './lib/api/Raffle/RaffleActions';
import FlagProvider from '@unleash/proxy-client-react';

if (
    process.env.REACT_APP_TARGET !== 'production' &&
    process.env.REACT_APP_TARGET !== 'prelive'
) {
    import('axe-core').then((axe) => {
        const runAxe = async () => {
            const response = await axe.default.run(document);
            return response?.violations || [];
        };

        // make global for selenium
        window.runAxe = runAxe;
    });
}

moment.locale('de');

Dinero.defaultCurrency = 'EUR';
Dinero.globalLocale = 'de-DE';

const config = {
    url: `${process.env.REACT_APP_HOST}/proxy`,
    clientKey: 'dij_front',
    refreshInterval: 15,
    appName: 'dij_front',
    environment: process.env.NODE_ENV,
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    snackbarSuccess: {
        'background-color': '#327e35',
    },
    snackbarWarning: {
        'background-color': '#ac4311',
    },
    snackbarInfo: {
        'background-color': '#0067b9',
    },
}));

function App() {
    //const { isLoading: authLoading, fetchUserProfile } = useAuth();
    const { authLoading, fetchUserProfile } = useAuth((state) => ({
        fetchUserProfile: state.relog,
        authLoading: state.loading,
    }));

    const classes = useStyles();

    React.useEffect(() => {
        fetchUserProfile().finally(async () => {
            try {
                await getRafflePots();
            } catch (e) {}
        });
        //eslint-disable-next-line
    }, []);

    const isLoading = authLoading;

    return (
        <React.Fragment>
            <ErrorBoundary FallbackComponent={externalErrorFallback}>
                <HelmetProvider>
                    <FlagProvider config={config}>
                        <QueryClientProvider client={client}>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                locale="de"
                            >
                                <SnackbarProvider
                                    ariaAttributes={{
                                        'aria-describedby': ``,
                                        'data-test': 'snackbar',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    classes={{
                                        variantSuccess: classes.snackbarSuccess,
                                        variantWarning: classes.snackbarWarning,
                                        variantInfo: classes.snackbarInfo,
                                    }}
                                >
                                    <Backdrop
                                        className={classes.backdrop}
                                        open={isLoading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                    {!isLoading && <PageRouter />}
                                </SnackbarProvider>
                            </MuiPickersUtilsProvider>
                        </QueryClientProvider>
                    </FlagProvider>
                </HelmetProvider>
            </ErrorBoundary>
        </React.Fragment>
    );
}

export default App;
