import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { TYPE } from '../../../util/DataTypes/Response';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RadioButtons from '../../Partials/Form/RadioButtons';
import { texts as mgmt } from '../../../texts/Forms/ManagementFormTexts';
import Divider from '@material-ui/core/Divider';
import ManagementStaff from './ManagementStaff';
import scrollTop from '../../../util/Helper/ScrollTop';
import { parseFuncString } from '../../../util/Helper/ParseFunctionString';

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
}));

const ManagementForm = (props) => {
    const { defaultValues, saveResponse, formId, onSubmit, saving } = props;
    const {
        reset,
        handleSubmit,
        errors,
        setError,
        control,
        watch,
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });

    const classes = useStyles();

    const [formError, setFormError] = React.useState(null);
    const isRegisteredUser = watch('isRegisteredUser');
    const projectManagementFunc = watch('projectManagement.func');
    const businessManagementFunc = watch('businessManagement.func');

    React.useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;
        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    React.useEffect(() => {
        if (isRegisteredUser === 'true') {
            setValue(
                'projectManagement',
                {
                    ...defaultValues.user,
                    func: parseFuncString(defaultValues.user.position),
                },
                { shouldValidate: true }
            );
        }
    }, [isRegisteredUser, setValue, defaultValues.user]);

    const handleSubmitCallback = (data) => {
        if (
            !projectManagementFunc ||
            (projectManagementFunc &&
                projectManagementFunc !== '0' &&
                !businessManagementFunc)
        ) {
            setFormError(mgmt.staff.functionError);
            scrollTop();
            return;
        }

        if (
            parseInt(projectManagementFunc) !== 0 &&
            parseInt(businessManagementFunc) !== 0
        ) {
            setFormError(mgmt.missingBoss);
            scrollTop();
            return;
        }

        setFormError(null);
        if (data.isRegisteredUser === 'true') {
            data.user = { ...data.projectManagement };
        }
        onSubmit(data);
    };

    return (
        <>
            {formError && <Alert severity="error">{formError}</Alert>}
            {errors.staff?.message && (
                <Alert severity="error">{errors.staff.message}</Alert>
            )}
            <form
                id={formId}
                onSubmit={(e) => {
                    clearErrors();
                    handleSubmit(handleSubmitCallback)(e);
                }}
            >
                <Typography component="h1" variant="h4" gutterBottom>
                    {mgmt.titles.contacts}
                </Typography>

                <RadioButtons
                    groupLabel={mgmt.isRegisteredUser.label}
                    name="isRegisteredUser"
                    control={control}
                    buttons={[
                        { label: 'Ja', value: 'true' },
                        { label: 'Nein', value: 'false' },
                    ]}
                    className={classes.marginBottom}
                    ariaLabel={mgmt.isRegisteredUser.ariaLabel}
                    defaultValue={defaultValues.isRegisteredUser}
                    rules={{
                        required: mgmt.isRegisteredUser.error,
                    }}
                    errors={errors.isRegisteredUser}
                />

                <Alert severity="info" className={classes.marginBottom}>
                    {mgmt.staff.hint}
                </Alert>

                <ManagementStaff
                    title={mgmt.staff.person1}
                    control={control}
                    error={errors.projectManagement}
                    disabled={saving}
                    className={classes.marginBottom}
                    defaultValues={defaultValues.projectManagement}
                    name={'projectManagement'}
                />

                {projectManagementFunc && projectManagementFunc !== '0' && (
                    <>
                        <ManagementStaff
                            title={mgmt.staff.person2}
                            control={control}
                            error={errors.businessManagement}
                            disabled={saving}
                            className={classes.marginBottom}
                            defaultValues={{
                                func: '0',
                                ...defaultValues.businessManagement,
                            }}
                            name={'businessManagement'}
                            funcDisabled={true}
                        />
                    </>
                )}
            </form>
            <Divider className={classes.marginBottom} />
            <Alert severity="info" className={classes.marginBottom}>
                {mgmt.hint1}
            </Alert>
            <Alert severity="info" className={classes.marginBottom}>
                {mgmt.hint2}
            </Alert>
            <Alert severity="info" className={classes.marginBottom}>
                {mgmt.hint3}
            </Alert>
        </>
    );
};

ManagementForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

ManagementForm.defaultProps = {
    formId: 'wizardForm',
    saving: false,
    error: null,
    defaultValues: {},
};

export default ManagementForm;
