export const overviewTexts = {
    title: 'Verwendungsnachweis',
    body: {
        paragraphs: [
            {
                type: 'p',
                text: 'Mit diesem Verwendungsnachweis berichten Sie über die Umsetzung Ihres Investitionsvorhabens.',
            },
            {
                type: 'p',
                text: 'Dies geschieht über einen Abgleich zwischen Ihren ursprünglichen Planungen, die Grundlage der Bewilligung waren, und den tatsächlich getätigten Investitionen.',
            },
            {
                type: 'p',
                text: 'Bevor Sie beginnen, beachten Sie bitte folgende Hinweise:',
            },
            {
                type: 'list',
                text: '[ul][li]Sie sind verpflichtet, in Ihrem Verwendungsnachweis erhebliche inhaltliche oder finanzielle Abweichungen vom ursprünglich geplanten Vorhaben zu dokumentieren und zu begründen. Bei erheblichen Abweichungen besteht kein Anspruch auf Förderung, das Bundesministerium für Wirtschaft und Klimaschutz bzw. der Projektträger entscheidet jeweils im Einzelfall.[li][li]Laden Sie zu jeder getätigten Investition einen Beleg über die in Rechnung gestellten Ausgaben hoch (Kopie der Original-Rechnung).[li][li]Es können nur Ausgaben erstattet werden, die von Ihnen bereits vollständig bezahlt wurden, Sie müssen also für sämtliche Ausgaben in Vorleistung gehen. Belege über die getätigten Zahlungen sind auf Verlangen vorzulegen.[li][li]Entstandene Mehrausgaben sind generell nicht zuwendungsfähig, die in Ihrem Zuwendungsbescheid genannte Höchstfördersumme kann nicht überschritten werden.[li][ul]',
            },
            {
                type: 'p',
                text: 'Die Pflicht zur Einreichung des Verwendungsnachweises ist erst dann erfüllt, wenn der Verwendungsnachweis qualifiziert unterzeichnet beim Projektträger eingegangen ist.',
            },
            {
                type: 'p',
                text: '[b]Mit der Einreichung des Verwendungsnachweises gilt Ihr Investitionsvorhaben als formal abgeschlossen. Dies gilt auch bei Einreichung des Verwendungsnachweises vor Ende des Bewilligungszeitraumes.[b]',
            },
            {
                type: 'p',
                text: '[b]Das Zurückziehen des eingereichten Verwendungsnachweises ist nicht möglich.[b]',
            },
        ],
    },
};
