import React from 'react';
import PropTypes from 'prop-types';
import useAsync from '../../../hooks/useAsync';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ContentTypography } from './AccordionContent/OverviewComponent';

const Temporary = (props) => {
    const { error } = props;
    if (error) return <p>{error.message()}</p>;

    return ContentTypography(
        <Skeleton width="50%" />,
        <Skeleton width="25%" />
    );
};

const OverviewContainer = (props) => {
    const { fetchFunc, Ui, proposalId, labels } = props;

    const mountedRef = React.useRef(true);

    React.useEffect(
        () => () => {
            mountedRef.current = false;
        },
        [mountedRef]
    );

    const { value, error, execute, pending } = useAsync(
        fetchFunc(proposalId),
        mountedRef,
        false
    );
    const [open, setOpen] = React.useState(false);

    const called = React.useRef(false);
    const handleChange = (next) => {
        if (next && !called.current) {
            execute();
            called.current = true;
        }

        setOpen(next);
    };

    const render = () => {
        if (pending || !value) return <Temporary error={error} />;

        return <Ui data={value} />;
    };

    React.useEffect(() => {
        if (!error) return;

        called.current = false;
    }, [error]);

    return (
        <Accordion expanded={open} onChange={() => handleChange(!open)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={labels.aria}
                id={`panel${labels.id}bh-header`}
            >
                <Typography component={'h2'}>{labels.headline}</Typography>
            </AccordionSummary>
            {open && (
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    {render()}
                </AccordionDetails>
            )}
        </Accordion>
    );
};

OverviewContainer.propTypes = {
    fetchFunc: PropTypes.func,
    Ui: PropTypes.any,
    proposalId: PropTypes.string,
    labels: PropTypes.object,
};

export default OverviewContainer;
