import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    mb: {
        marginBottom: theme.spacing(2),
    },
}));

const TextFieldMax = (props) => {
    const {
        register,
        maxLength,
        label,
        name,
        defaultValue,
        rules,
        error,
        multiline,
        rows,
        ...rest
    } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(defaultValue || '');

    return (
        <TextField
            label={label}
            name={name}
            inputRef={register(rules)}
            fullWidth
            multiline={multiline}
            rows={rows}
            className={classes.mb}
            inputProps={{
                maxLength: maxLength,
            }}
            helperText={`${value.length}/${maxLength}${
                error ? ' ' + error.message : ''
            }`}
            error={error !== undefined && error !== null}
            onChange={(evt) => {
                setValue(evt.target.value.substr(0, maxLength));
            }}
            {...rest}
        />
    );
};

TextFieldMax.propTypes = {
    register: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
};

TextFieldMax.defaultProps = {
    defaultValue: '',
    rules: {},
    maxLength: 200,
    error: null,
    multiline: false,
    rows: 1,
};

export default TextFieldMax;
