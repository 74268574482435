import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { texts as mft } from '../../../texts/Forms/MinimisFormTexts';
import MinimisCalculationYear from './MinimisCalculationYear';
import Slide from '@material-ui/core/Slide';
import MinimisSum from './MinimisSum';
import { Typography } from '@material-ui/core';
import MinimisCheckbox from './MinimisCheckbox';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#e8e8e8',
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
}));

const TabPanel = React.forwardRef((props, ref) => {
    const { index, value, children, ariaLabeledBy } = props;

    return (
        <Box
            role="tabpanel"
            aria-hidden={index !== value}
            id={`panel${index}`}
            aria-labelledby={ariaLabeledBy}
            ref={ref}
        >
            {index === value && children}
        </Box>
    );
});

const MinimisCalculation = (props) => {
    const {
        errors,
        register,
        fields,
        defaultValues,
        trigger,
        control,
        isRoadTransport,
    } = props;
    const classes = useStyles();

    const [openTab, setOpenTab] = React.useState(0);

    const handleChange = (evt, idx) => {
        setOpenTab(idx);
    };

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Paper elevation={0} className={classes.root}>
                <Tabs
                    value={openTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab
                        id={currentYear}
                        label={currentYear}
                        aria-controls="panel0"
                    />
                    <Tab
                        id={currentYear - 1}
                        label={currentYear - 1}
                        aria-controls="panel1"
                    />
                    <Tab
                        id={currentYear - 2}
                        label={currentYear - 2}
                        aria-controls="panel2"
                    />
                </Tabs>
            </Paper>
            <Alert className={classes.mb} severity="info">
                {mft.calculator.topHint}
            </Alert>
            <Slide
                direction="right"
                in={openTab === 0}
                mountOnEnter
                unmountOnExit
            >
                <TabPanel ariaLabeledBy={currentYear} index={0} value={openTab}>
                    <MinimisCalculationYear year={'one'} ab={fields} />
                </TabPanel>
            </Slide>
            <Slide
                direction="right"
                in={openTab === 1}
                mountOnEnter
                unmountOnExit
            >
                <TabPanel
                    ariaLabeledBy={currentYear - 1}
                    index={1}
                    value={openTab}
                >
                    <MinimisCalculationYear year={'two'} ab={fields} />
                </TabPanel>
            </Slide>
            <Slide
                direction="right"
                in={openTab === 2}
                mountOnEnter
                unmountOnExit
            >
                <TabPanel
                    ariaLabeledBy={currentYear - 2}
                    index={2}
                    value={openTab}
                >
                    <MinimisCalculationYear year={'three'} ab={fields} />
                </TabPanel>
            </Slide>
            <MinimisSum
                register={register}
                trigger={trigger}
                control={control}
                isRoadTransport={isRoadTransport}
            />
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                {mft.calculator.checkbox.title}
            </Typography>
            <MinimisCheckbox
                register={register}
                name="ackSubFraud"
                defaultChecked={defaultValues.ackSubFraud}
                label={mft.calculator.checkbox.ackFraud}
                error={errors.ackSubFraud}
                validation={{
                    validate: (value) =>
                        (value !== true &&
                            fields.received !== 'true' &&
                            fields.requested !== 'true') ||
                        (value === true &&
                            (fields.received === 'true' ||
                                fields.requested === 'true')) ||
                        mft.calculator.checkbox.error,
                }}
            />
            <MinimisCheckbox
                register={register}
                name="ackDisclosure"
                defaultChecked={defaultValues.ackDisclosure}
                label={mft.calculator.checkbox.ackDisclosure}
                error={errors.ackDisclosure}
                validation={{
                    validate: (value) =>
                        (value !== true &&
                            fields.received !== 'true' &&
                            fields.requested !== 'true') ||
                        (value === true &&
                            (fields.received === 'true' ||
                                fields.requested === 'true')) ||
                        mft.calculator.checkbox.error,
                }}
            />
            <MinimisCheckbox
                register={register}
                name="ackRegulation"
                defaultChecked={defaultValues.ackRegulation}
                label={mft.calculator.checkbox.ackRegulation}
                error={errors.ackRegulation}
                validation={{
                    validate: (value) =>
                        (value !== true &&
                            fields.received !== 'true' &&
                            fields.requested !== 'true') ||
                        (value === true &&
                            (fields.received === 'true' ||
                                fields.requested === 'true')) ||
                        mft.calculator.checkbox.error,
                }}
            />
            <MinimisCheckbox
                register={register}
                name="ackDeadline"
                defaultChecked={defaultValues.ackDeadline}
                label={mft.calculator.checkbox.ackDeadline}
                error={errors.ackDeadline}
                validation={{
                    validate: (value) =>
                        (value !== true &&
                            fields.received !== 'true' &&
                            fields.requested !== 'true') ||
                        (value === true &&
                            (fields.received === 'true' ||
                                fields.requested === 'true')) ||
                        mft.calculator.checkbox.error,
                }}
            />
            <MinimisCheckbox
                register={register}
                name="ackMinimiProof"
                defaultChecked={defaultValues.ackMinimiProof}
                label={mft.calculator.checkbox.ackMinimiProof}
                error={errors.ackMinimiProof}
                validation={{
                    validate: (value) =>
                        (value !== true &&
                            fields.received !== 'true' &&
                            fields.requested !== 'true') ||
                        (value === true &&
                            (fields.received === 'true' ||
                                fields.requested === 'true')) ||
                        mft.calculator.checkbox.error,
                }}
            />
        </>
    );
};

MinimisCalculation.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    fields: PropTypes.object,
    control: PropTypes.object,
    isRoadTransport: PropTypes.string,
};

MinimisCalculation.defaultProps = {
    fields: {},
};

export default MinimisCalculation;
