import { req } from './DigitalOneActions';

export function fetchMutator(data) {
    const { data: step5 } = data[0];
    const { data: step0 } = data[1];
    const isEmpty = step5 === null || step5 === undefined || step5 === '';

    if (!isEmpty) {
        Object.keys(step5.fields).map(
            (key) => (step5.fields[key] = `${step5.fields[key]}`)
        );
    }

    const tmpArray = Array(12).fill(false);
    const mutated = isEmpty
        ? {
              requires: req,
              ...{
                  initialBusinessUnits: tmpArray,
                  otherBusinessUnit: '',
              },
          }
        : {
              requires: req,
              ...step5,
              ...step5?.fields,
          };
    if (!isEmpty) {
        mutated.initialBusinessUnits.map(
            (el) => (tmpArray[parseInt(el)] = true)
        );
    }
    mutated.initialBusinessUnits = tmpArray;
    mutated.isValueChain = step0.isValueChain || false;
    mutated.isOther = mutated.initialBusinessUnits[11];

    return mutated;
}

export function saveMutator(data) {
    const mutated = {};

    mutated.initialBusinessUnits = data.initialBusinessUnits.reduce(
        (acc, cur, idx) => {
            cur === true && acc.push(`${idx}`);
            return acc;
        },
        []
    );
    mutated.otherBusinessUnit = data.otherBusinessUnit
        ? data.otherBusinessUnit
        : null;

    delete data.initialBusinessUnits;
    delete data.otherBusinessUnit;

    Object.keys(data).map((key) => (data[key] = parseInt(data[key])));
    mutated.fields = { ...data };

    return mutated;
}
