import ValueChainForm from '../components/Forms/ValueChainForm/ValueChainForm';
import {
    fetchValueChainData,
    saveValueChainData,
} from '../lib/api/ValueChain/ValueChainActions';
import ProposalOverviewForm from '../components/Forms/ProposalOverview/ProposalOverviewForm';
import ManagementForm from '../components/Forms/Management/ManagementForm';
import {
    fetchManagementData,
    saveManagementData,
} from '../lib/api/Management/ManagementActions';
import CompanyLocationForm from '../components/Forms/CompanyLocation/CompanyLocationForm';
import {
    fetchCompanyLocationData,
    saveCompanyLocationData,
} from '../lib/api/CompanyLocation/CompanyLocationActions';
import Digi2Form from '../components/Forms/Digi2/Digi2Form';
import { fetchDigi2Data, saveDigi2Data } from '../lib/api/Digi2/Digi2Actions';
import MinimisForm from '../components/Forms/Minimis/MinimisForm';
import {
    fetchMinimisData,
    saveMinimisData,
} from '../lib/api/Minimis/MinimisActions';
import {
    fetchDigitalOneFormData,
    saveDigitalOneFormData,
} from '../lib/api/DigitalOne/DigitalOneActions';
import DigiOneForm from '../components/Forms/Digi1/DigiOneForm';
import CompanyTypeForm from '../components/Forms/CompanyType/CompanyTypeForm';
import {
    fetchCompanyTypeData,
    saveCompanyTypeData,
} from '../lib/api/CompanyType/CompanyTypeActions';
import DigiThreeForm from '../components/Forms/Digi3/DigiThreeForm';
import {
    fetchDigitalThreeFormData,
    saveDigitalThreeFormData,
} from '../lib/api/DigitalThree/DigitalThreeActions';
import FinancingPlanForm from '../components/Forms/FinancingPlan/FinancingPlanForm';
import {
    fetchFinancingPlanData,
    saveFinancingPlanData,
} from '../lib/api/FinancingPlan/FinancingPlanActions';
import FinancingPlan2Form from '../components/Forms/FinancingPlan2/FinancingPlan2Form';
import {
    fetchFinancingPlan2Data,
    saveFinancingPlan2Data,
} from '../lib/api/FinancingPlan2/FinancingPlan2Actions';
import { approveProposal } from '../lib/api/Overview/OverviewActions';
import FinalizeForm from '../components/Forms/Finalize/FinalizeForm';
import {
    fetchFinalizeInfo,
    saveFinalize,
} from '../lib/api/Finalize/FinalizeActions';

export const steps = [
    {
        name: 'Wahl der Antragsform',
        component: ValueChainForm,
        saveFunc: saveValueChainData,
        fetchFunc: fetchValueChainData,
    },
    {
        name: 'Bevollmächtigte Person',
        component: ManagementForm,
        saveFunc: saveManagementData,
        fetchFunc: fetchManagementData,
    },
    {
        name: 'Antragstellendes Unternehmen',
        component: CompanyLocationForm,
        saveFunc: saveCompanyLocationData,
        fetchFunc: fetchCompanyLocationData,
    },
    {
        name: 'Angaben zum Unternehmen',
        component: CompanyTypeForm,
        saveFunc: saveCompanyTypeData,
        fetchFunc: fetchCompanyTypeData,
    },
    {
        name: 'De-minimis-Behilfe',
        component: MinimisForm,
        saveFunc: saveMinimisData,
        fetchFunc: fetchMinimisData,
    },
    {
        name: 'Digitalisierungsplan',
        component: DigiOneForm,
        saveFunc: saveDigitalOneFormData,
        fetchFunc: fetchDigitalOneFormData,
    },
    {
        name: 'Digitalisierungsplan - Vorhabenbeschreibung',
        component: Digi2Form,
        saveFunc: saveDigi2Data,
        fetchFunc: fetchDigi2Data,
    },
    {
        name: 'Digitalisierungsplan - Nachhaltige Wirkung der Investition',
        component: DigiThreeForm,
        saveFunc: saveDigitalThreeFormData,
        fetchFunc: fetchDigitalThreeFormData,
    },
    {
        name: 'Finanzierungsplan',
        component: FinancingPlanForm,
        saveFunc: saveFinancingPlanData,
        fetchFunc: fetchFinancingPlanData,
    },
    {
        name: 'Zuschuss "Digital Jetzt"',
        component: FinancingPlan2Form,
        saveFunc: saveFinancingPlan2Data,
        fetchFunc: fetchFinancingPlan2Data,
    },
    {
        name: 'Übersicht & Einreichen',
        component: ProposalOverviewForm,
        saveFunc: approveProposal,
        fetchFunc: () => () => Promise.resolve(),
    },
    {
        name: 'Antrag abschicken',
        component: FinalizeForm,
        saveFunc: saveFinalize,
        fetchFunc: fetchFinalizeInfo,
    },
];
