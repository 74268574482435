import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { texts as trans } from '../../texts/Components/ImpressumText.js';
import LinkOtherPage from '../../components/TextBlock/components/LinkOtherPage';
import TextBlock from '../../components/TextBlock/TextBlock';

const Impressum = () => {
    return (
        <>
            <h2>{trans.impressum.titel}</h2>
            <Typography>
                {trans.impressum.address.name}
                <br />
                {trans.impressum.address.street}
                <br />
                {trans.impressum.address.postalcode_city}
                <br />
                {trans.label_tel} {trans.impressum.address.tel}
                <br />
                {trans.label_mail}{' '}
                <a
                    href={'mailto:' + trans.impressum.address.mail}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans.impressum.address.mail}
                </a>
                <br />
                {trans.label_internet}{' '}
                <a
                    href={trans.impressum.address.internet}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans.impressum.address.internet}
                </a>
                <br />
            </Typography>
            <Typography>{trans.impressum.text}</Typography>

            <h3>{trans.location.title}</h3>
            <TextBlock
                textBlock={trans.location}
                parserOptions={{
                    components: { a: { component: LinkOtherPage } },
                }}
            />

            <h3>{trans.registration.title}</h3>
            <Typography>{trans.registration.text}</Typography>

            <h3>{trans.taxNumber.title}</h3>
            <Typography>{trans.taxNumber.text}</Typography>

            <h3>{trans.technique.title}</h3>
            <Typography>
                {trans.technique.address.name}
                <br />
                {trans.technique.address.department}
                <br />
                {trans.technique.address.street}
                <br />
                {trans.technique.address.postalcode_city}
                <br />
            </Typography>
            <Typography>
                {trans.label_tel} {trans.technique.address.tel}
                <br />
                {trans.label_fax} {trans.technique.address.fax}
                <br />
                {trans.label_mail}{' '}
                <Link
                    variant="body1"
                    href={'mailto:' + trans.technique.address.mail}
                >
                    {trans.technique.address.mail}
                </Link>
                <br />
            </Typography>

            <h3>{trans.responsible.title}</h3>
            <Typography>{trans.responsible.text}</Typography>

            <h4>{trans.supervisedBy.title}</h4>
            <Typography>
                {trans.supervisedBy.address.name}
                <br />
                {trans.supervisedBy.address.department}
                <br />
                {trans.supervisedBy.address.street}
                <br />
                {trans.supervisedBy.address.postalcode_city}
                <br />
            </Typography>
            <TextBlock
                textBlock={trans.supervisedBy}
                parserOptions={{
                    components: { a: { component: LinkOtherPage } },
                }}
            />

            <h2>{trans.disclaimer.title}</h2>
            <TextBlock
                textBlock={trans.disclaimer}
                parserOptions={{
                    components: { a: { component: LinkOtherPage } },
                }}
            />

            <h2>{trans.disclaimerLinks.title}</h2>
            <Typography>{trans.disclaimerLinks.text}</Typography>

            <h2>{trans.termsOfUse.title}</h2>
            {trans.termsOfUse.content.map((tOU) => (
                <React.Fragment key={tOU.title}>
                    <h3>{tOU.title}</h3>
                    {tOU.paragraphs && (
                        <TextBlock
                            textBlock={tOU}
                            parserOptions={{
                                components: { a: { component: LinkOtherPage } },
                            }}
                        />
                    )}
                    {tOU.text && <Typography>{tOU.text}</Typography>}
                    {tOU.address && (
                        <>
                            <Typography>
                                {tOU.address.name}
                                <br />
                                {tOU.address.department}
                                <br />
                                {tOU.address.street}
                                <br />
                                {tOU.address.postalcode_city}
                                <br />
                            </Typography>
                            <Typography>
                                {trans.label_tel} {tOU.address.tel}
                                <br />
                                {trans.label_fax} {tOU.address.fax}
                                <br />
                                {trans.label_mail}{' '}
                                <Link
                                    variant="body1"
                                    href={'mailto:' + tOU.address.mail}
                                >
                                    {tOU.address.mail}
                                </Link>
                                <br />
                            </Typography>
                        </>
                    )}
                </React.Fragment>
            ))}

            <h2>{trans.note.title}</h2>
            <TextBlock
                textBlock={trans.note}
                parserOptions={{
                    components: { a: { component: LinkOtherPage } },
                }}
            />
        </>
    );
};

export default Impressum;
