export const texts = {
    title: 'Digitalisierungsplan',
    desc: 'Der Digitalisierungsplan beschreibt möglichst genau das beantragte Investitionsvorhaben und besteht aus folgenden drei Teilen:',
    bullet1: {
        label: 'Ausgangssituation (Digitalisierungsplan 1) ',
        info: 'Darstellung des aktuellen Standes der Digitalisierung im Unternehmen (bzw. in den für die Investitionen relevanten Unternehmensbereichen) anhand einer Selbsteinschätzung',
    },
    bullet2: {
        label: 'Vorhabenbeschreibung (Digitalisierungsplan 2)',
        info: 'Darstellung des geplanten Investitionsvorhabens, der Ziele und der konkreten Verbesserungen für das Unternehmen (z.B. in Bezug auf Geschäftsmodell bzw. Geschäftsfelder, Unternehmensprozesse, IT-Sicherheit; Digitalisierungskompetenzen der Mitarbeitenden)',
    },
    bullet3: {
        label: 'Nachhaltige Wirkung der Investition (Digitalisierungsplan 3)',
        info: 'Darstellung der voraussichtlich zu erwartenden Effekte der Investitionen auf die weitere Entwicklung des Unternehmens.',
    },
    disclosure:
        'Die hier von Ihnen aufgenommenen Informationen werden ausschließlich für die Antragsbewilligung im Rahmen von "Digital Jetzt" und nicht für weitere Zwecke im Bundesministerium für Wirtschaft und Klimaschutz und DLR Projektträger verwendet.',
    bulletCaption: {
        0: '(vgl. hierzu ',
        1: 'Förderrichtlinie',
        2: ', u.a. Abschnitt 4.2 und ',
        3: 'FAQ',
        4: ')',
        link: 'https://www.bmwk.de/Redaktion/DE/Downloads/P-R/richtlinie-zum-foerderprogramm-digital-jetzt-investitionsfoerderung-kmu.pdf?__blob=publicationFile&v=4',
        linkFaq:
            'https://www.bmwk.de/Redaktion/DE/FAQ/Digital-Jetzt/faq-digital-jetzt.html',
    },
    text1: 'Darstellung der voraussichtlich zu erwartenden Effekte bzw. Auswirkungen der Investitionen auf die weitere Entwicklung des Unternehmens.',
    initialSituation: {
        title: 'Ausgangssituation',
        desc: 'Nennen Sie zunächst den Unternehmensbereich, in dem das geplante Vorhaben umgesetzt werden soll. Mehrfachnennungen sind möglich.',
        industrySectors: [
            'Geschäftsführung / Management',
            'Beschaffung / Einkauf',
            'Logistik (inklusive Lager und Materialwirtschaft)',
            'Produktion (inklusive Wartung und Instandhaltung)',
            'Qualitätssicherung',
            'Marketing / Kommunikation',
            'Vertrieb / Kundenmanagement',
            'Personal',
            'Finanzen / Controlling',
            'IT / IKT',
            'Übergreifend',
            'Sonstiges',
        ],
        sectors: [
            { label: 'Geschäftsführung / Management' },
            { label: 'Beschaffung / Einkauf' },
            { label: 'Logistik (inklusive Lager und Materialwirtschaft)' },
            { label: 'Produktion (inklusive Wartung und Instandhaltung)' },
            { label: 'Qualitätssicherung' },
            { label: 'Marketing / Kommunikation' },
            { label: 'Vertrieb / Kundenmanagement' },
            { label: 'Personal' },
            { label: 'Finanzen / Controlling' },
            { label: 'IT / IKT' },
            {
                label: 'Übergreifend',
                tooltip:
                    'Die Option „übergreifend“ zielt auf Unternehmen (z.B. Kleinstunternehmen und kleine Unternehmen), die nicht in spezifische Unternehmensbereiche differenzieren.',
            },
            { label: 'Sonstiges' },
        ],
        infoComprehensive:
            'Die Option „übergreifend“ zielt auf Unternehmen (z.B. Kleinstunternehmen und kleine Unternehmen, die nicht in spezifische Unternehmensbereiche differenzieren.',
        other: 'Sonstiges',
        otherId: 11,
        otherMax: 200,
        otherError: 'Bitte füllen Sie dieses Feld aus.',
    },
    sections: {
        strategie: {
            title: 'Strategie',
            desc: 'Hier soll festgestellt werden, ob und in welcher Ausprägung in Ihrem Unternehmen eine Digitalisierungsstrategie existiert und gezielt verfolgt wird. ',
            tooltip:
                'Die strategische Betrachtung der Thematik Digitalisierung legt den Grundstein für die systematische Umsetzung von Digitalisierungsmaßnahmen in den verschiedenen Unternehmensbereichen.',
        },
        products: {
            title: 'Produkte & Dienstleistungen',
            desc: 'Hier soll festgestellt werden, ob und in welcher Ausprägung Ihr Unternehmen digitale Geschäftsmodelle verfolgt, digitale (datenbasierte) Produkte und Dienstleistungen als Kern- bzw. Zusatzleistungen anbietet, sowie dabei digitale Vertriebswege (z.B. Webshop, Plattformen) nutzt.',
        },
        communcation: {
            title: 'Kommunikation mit Kunden, Lieferanten und Partnern',
            desc: 'Hier soll dargestellt werden, ob und in welcher Tiefe bereits digitale Technologien angewendet werden, um u.a. Kunden individualisiert ansprechen zu können und Kundendaten weiterführend nutzen zu können. ',
        },
        process: {
            title: 'Prozesse & Organisation',
            desc: 'Hier soll dargestellt werden, inwieweit digitale Technologien durchgängig bzw. bereits miteinander abgestimmt zur effizienten Steuerung von Prozessen im Unternehmen genutzt werden.',
        },
        technology: {
            title: 'Technologien',
            desc: 'Hier soll dargestellt werden, welche mittlerweile relativ weit verbreiteten digitalen Technologien (Hard- und Software) im Unternehmen eingesetzt werden und mit welcher Zielstellung.',
        },
        itsec: {
            title: 'IT-Sicherheit',
            desc: 'Hier soll dargestellt werden, in welcher Form Ihr Unternehmen aktuell vor IT-basierten Angriffen oder Ausfällen bei Anlagen und IT-Systemen geschützt ist.',
        },
        external: {
            title: 'Externe Vernetzung in Wertschöpfungskette bzw. -netzwerk',
            desc: 'Hier soll dargestellt werden, ob und inwieweit Sie über Ihre Unternehmensgrenzen hinaus mit anderen Unternehmen in der Wertschöpfungskette (Kunden, Zulieferern etc.) vernetzt agieren und mit Hilfe digitaler Technologien Synergieeffekte erzielen.',
            tooltip:
                'Beispiel Handel: Mehrere mittelständische Handelsunternehmen verbinden für eine netzwerkinterne Überwachung von Warenbeständen und Bedarfsentwicklungen ihre ERP-Systeme mit einem gemeinsamen virtuellen Lager. So lassen sich die Bestände im Netzwerk senken, Kosten reduzieren und die Warenverfügbarkeit erhöhen. Beispiel Handwerk: Handwerksbetriebe unterschiedlicher Gewerke bauen gemeinsam ein Wertschöpfungsnetzwerk auf. Mit Hilfe einer digitalen Softwarelösung können gemeinsame Projekte abgewickelt und entstehende Daten (Bilder, Dokumente und Pläne) abgelegt werden. Durch die strategische Einbindung neuer Partner und Gewerke können zudem neue Dienstleistungen angeboten und komplexe Aufträge akquiriert werden.',
        },
        competence: {
            title: 'Kompetenzen der Mitarbeitenden',
            desc: 'Mitarbeitende eines Unternehmens sind maßgeblich mitverantwortlich für den Erfolg oder Misserfolg von Veränderungsprozessen wie der digitalen Transformation. Hier soll beschrieben werden, ob und in welchem Umfang Fähigkeiten und Fertigkeiten zur Nutzung digitaler Technologien bei den Mitarbeitenden verfügbar sind bzw. gezielt weiterentwickelt werden. Zudem soll der Bedarf an spezifischen Qualifizierungsmaßnahmen zur (Weiter-)Entwicklung digitaler Kompetenzen dargestellt werden.',
            tooltip:
                'Die digitale Transformation stellt einen kontinuierlichen Prozess dar, bei dem digitale Technologien zur Begegnung fortlaufender Marktveränderungen eingesetzt werden.',
        },
    },
    question: {
        required: 'Bitte beantworten Sie diese Frage.',
    },
    questions: {
        0: {
            name: '0',
            text: 'Haben Sie für Ihr Unternehmen eine Digitalisierungsstrategie mit konkreten Zielen entwickelt?',
            answers: [
                {
                    label: 'Strategie nicht vorhanden bzw. nicht in Planung',
                    value: '1',
                },
                {
                    label: 'Strategie nur in Form von  Pilotinitiativen',
                    value: '2',
                },
                {
                    label: 'Strategie in Arbeit',
                    value: '3',
                },
                {
                    label: 'Strategie in Umsetzung',
                    value: '4',
                },
                {
                    label: 'Strategie umgesetzt und kontinuierlich weiterentwickelt',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        1: {
            name: '1',
            text: 'Gibt es in Ihrem Unternehmen digitale Produkte oder Dienstleistungen bei Nutzung digitaler Vertriebskanäle?',
            tooltip:
                'Digitale Produkte und Dienstleistungen zeichnen sich dadurch aus, dass sie mit Hilfe von Informationssystemen entwickelt, verteilt oder angewendet werden.  Der Kundennutzen von digitalen Dienstleistungen basiert vor allem auf der Erfassung, Analyse, Auswertung, Aufbereitung und / oder dem Austausch von Daten. Beispiele für digitale Produkte: digitale Medien (E-Books, Computerspiele etc.) und Software. Beispiele für digitale Dienstleistungen: internetbasierte Qualifizierungsangebote wie Webinare oder Online-Tutorials und die Bereitstellung von Assistenzsystemen zur Unterstützung von Fertigungsprozessen.',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'ja, einzelne',
                    value: '2',
                },
                {
                    label: 'ja, mehrere',
                    value: '3',
                },
                {
                    label: 'ja, sehr viele',
                    value: '4',
                },
                {
                    label: 'ja, alle Produkte / Dienstleistungen',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        2: {
            name: '2',
            text: 'Bietet Ihr Unternehmen internet- oder datenbasierte Dienstleistungen als Ergänzung der (Kern-)Produkte an?',
            tooltip:
                'Auf (Kern-) Produkten aufbauende internet- oder datenbasierte Dienstleistungen basieren z.B. auf der Analyse und Auswertung von durch den Kunden anfallenden Daten. Den Kunden können dadurch zusätzliche Dienstleistungen, u.a. zur Instandhaltung von Maschinen, zum Prozessmanagement, angeboten werden.',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'ja, aber nur wenige',
                    value: '2',
                },
                {
                    label: 'ja, mehrere',
                    value: '3',
                },
                {
                    label: 'ja, sehr viele',
                    value: '4',
                },
                {
                    label: 'ja, alle Möglichkeiten sind ausgeschöpft',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        3: {
            name: '3',
            text: 'Wie findet die Kommunikation mit Kunden, Lieferanten und Partnern in Ihrem Unternehmen statt?',
            answers: [
                {
                    label: 'analog (Fax, Telefon)',
                    value: '1',
                },
                {
                    label: 'über einzelne digitale Medien (z.B. E-Mail) ',
                    value: '2',
                },
                {
                    label: 'über mehrere digitale Medien',
                    value: '3',
                    tooltip:
                        '(z.B. E-Mail, Kontaktformular / FAQs auf Website)',
                },
                {
                    label: 'über eine Vielzahl an digitalen Medien',
                    value: '4',
                    tooltip:
                        '(z.B. E-Mail, Kontaktformular / FAQs, Kundenportal, Online-Plattformen)',
                },
                {
                    label: 'über integrierte Kommunikationsplattform',
                    value: '5',
                    tooltip:
                        'Im Sinne von untereinander harmonisierten und verbundenen Systemen (individuell angepasst; Self-Service-Anwendungen)',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        4: {
            name: '4',
            text: 'Inwieweit findet die Dokumentation wichtiger Unternehmensbereiche bzw. ressourcenaufwändiger Prozesse (z.B. Kundenmanagement, Einkauf, Lagerhaltung, Produktion, Vertrieb, Rechnungswesen) in digitaler Form statt? ',
            tooltip:
                'Prozesse umfassen alle festgelegten Abläufe im Unternehmen, an deren Ende ein Ergebnis steht.',
            answers: [
                {
                    label: 'sehr gering – Dokumentation v.a. papierbasiert',
                    value: '1',
                },
                {
                    label: 'gering – vereinzelt digitale Dokumentation, aber manuelle Prozesse',
                    value: '2',
                },
                {
                    label: 'mittel – digitale Dokumentation jedoch mit zahlreichen Medienbrüchen',
                    value: '3',
                    tooltip:
                        'Ein Medienbruch entsteht, wenn die Übertragungskette eines Prozesses dadurch unterbrochen wird, dass Daten / Informationen in einer anderen Form mit sehr hohem manuellem Aufwand übertragen werden müssen als sie empfangen wurden.',
                },
                {
                    label: 'hoch – digitale Systeme, teilvernetzt',
                    value: '4',
                },
                {
                    label: 'sehr hoch – digitale Systeme, voll vernetzt (keine Medienbrüche)',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
            5: {
                name: '5',
                text: 'Welchen Einsatzzweck haben mobile oder digitale Endgeräte (z.B. Datenbrille, Smartphone, Tablet, Handscanner) im Unternehmen?',
                answers: [
                    {
                        label: 'nicht vorhanden',
                        value: '1',
                    },
                    {
                        label: 'vorhanden – zur Visualisierung von Informationen',
                        value: '2',
                    },
                    {
                        label: 'vorhanden – zur Rückmeldung von Informationen',
                        value: '3',
                    },
                    {
                        label: 'vorhanden – zur Interaktion oder zum Dialog mit digitalen Systemen (z.B. Warenwirtschaftssystem)',
                        value: '4',
                        tooltip:
                            'Ein Warenwirtschaftssystem (WWS) ist eine Software zur Abbildung der Warenströme (u.a. Warenein- und ausgang, Disposition und Logistik).',
                    },
                    {
                        label: 'vorhanden – zur Interaktion mit digitalen Systemen und zur Lernunterstützung für Mitarbeitende',
                        value: '5',
                    },
                    {
                        label: 'k.A.',
                        value: '99',
                    },
                ],
            },
        },
        5: {
            name: '5',
            text: 'Welchen Einsatzzweck haben mobile oder digitale Endgeräte (z.B. Datenbrille, Smartphone, Tablet, Handscanner) im Unternehmen?',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'vorhanden – zur Visualisierung von Informationen',
                    value: '2',
                },
                {
                    label: 'vorhanden – zur Rückmeldung von Informationen',
                    value: '3',
                },
                {
                    label: 'vorhanden – zur Interaktion oder zum Dialog mit digitalen Systemen (z.B. Warenwirtschaftssystem)',
                    value: '4',
                    tooltip:
                        'Ein Warenwirtschaftssystem (WWS) ist eine Software zur Abbildung der Warenströme (u.a. Warenein- und ausgang, Disposition und Logistik).',
                },
                {
                    label: 'vorhanden – zur Interaktion mit digitalen Systemen und zur Lernunterstützung für Mitarbeitende',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        6: {
            name: '6',
            text: 'Kommt es zum Einsatz eines Customer Relationship Management Systems (CRM) und/ oder Enterprise Resource Planning Systems (ERP) im Unternehmen?',
            tooltip:
                'Ein CRM-System (CRM = Customer Relationship Management) ist eine Software zum Management von Kundenbeziehungen. Sie bildet alle relevanten Kundeninformationen (z.B. Ansprechpartner, Transaktionen und Korrespondenzen) ab und ermöglicht u.a. eine individuelle Kundenansprache. Ein ERP-System (ERP = Enterprise Resource Planning) ist eine Software zur Ressourcenplanung (z.B. Personal, Material, Finanzen) in einem Unternehmen.',
            answers: [
                {
                    label: 'Keines der beiden Systeme vorhanden',
                    value: '1',
                },
                {
                    label: 'Planung mindestens eines der Systeme begonnen',
                    value: '2',
                },
                {
                    label: 'Planung mindestens eines der Systeme abgeschlossen',
                    value: '3',
                },
                {
                    label: 'Umsetzung mindestens eines der Systeme gestartet',
                    value: '4',
                },
                {
                    label: 'Umsetzung mindestens eines der Systeme abgeschlossen',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        7: {
            name: '7',
            text: 'Wie ausgeprägt ist die Umsetzung der Sicherheit der IT-Systeme und Daten in Ihrem Unternehmen?',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'Basisschutz (z.B. Firewall, Virusschutz) auf allen Endgeräten',
                    value: '2',
                    tooltip:
                        'Eine Firewall ist ein Sicherungssystem basierend auf einer Software, das ein Rechnernetz oder einen einzelnen Computer vor unerwünschten Zugriffen schützt.',
                },
                {
                    label: 'Konzept zur IT-Sicherheit ist implementiert und Datenaustausch ist verschlüsselt (z.B. SSL-Verschlüsselung)',
                    value: '3',
                    tooltip:
                        'Die SSL-Verschlüsselung (SSL = Secure Sockets Layer) ist ein hybrides Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet. Mittlerweile wurde es unter dem Namen TLS (Transport Layer Security) weiterentwickelt.',
                },
                {
                    label: 'IT-Sicherheit ist zertifiziert (z.B. BSI-Zertifizierung) und Mitarbeitende werden zum Thema geschult ',
                    value: '4',
                    tooltip:
                        'BSI-Standards (BSI - Bundesamt für Sicherheit in der Informationstechnik) enthalten Methoden und Vorgehensweisen zu den unterschiedlichsten Themen der Informationssicherheit. Sie stellen mit dem IT-Grundschutz-Kompendium einen De-Facto-Standard für IT-Sicherheit dar. U.a. ist eine ISO 27001 Zertifizierung auf Basis dieses IT-Grundschutzes möglich.',
                },
                {
                    label: 'IT-Sicherheit ist zertifiziert (z.B. BSI-Zertifizierung), regelmäßige Risikoanalyse und Durchführung von Verbesserungsmaßnahmen sowie Mitarbeiterschulungen',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        8: {
            name: '8',
            text: 'Sind Sie mit externen Partnern (z.B. Kunden, Zulieferern und/oder Dienstleistern) digital vernetzt, um Synergien zu generieren und z.B. Ihre Wettbewerbsfähigkeit und/oder Innovationsfähigkeit zu verbessern?',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'ja, mit einzelnen',
                    value: '2',
                },
                {
                    label: 'ja, mit mehreren',
                    value: '3',
                },
                {
                    label: 'ja, mit (sehr) vielen ',
                    value: '4',
                },
                {
                    label: 'ja, mit allen ',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        9: {
            name: '9',
            text: 'Wie hoch ist schätzungsweise der Anteil der Mitarbeitenden mit ausgeprägten digitalen Kompetenzen (z.B. Datenanalysefähigkeit, Anwendung von Standard- und Kollaborations-Software, Prozess-Know-how) in Ihrem Unternehmen?',
            tooltip:
                'Kollaborations-Software ermöglicht Mitarbeitenden in Projekten virtuell zusammen zu arbeiten, in einer Gruppe zu kommunizieren und diese zu organisieren. Funktionalitäten der Software sind z.B. Projektmanagement, Chats, Gruppenkalender und Telefon-/Videokonferenzen.',
            answers: [
                {
                    label: '≤ 20%',
                    value: '1',
                },
                {
                    label: '≤ 40%',
                    value: '2',
                },
                {
                    label: '≤ 60%',
                    value: '3',
                },
                {
                    label: '≤ 80%',
                    value: '4',
                },
                {
                    label: '≤ 100%',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        10: {
            name: '10',
            text: 'Gibt es in Ihrem Unternehmen (regelmäßige) Schulungen der Mitarbeitenden im Umgang mit digitalen Technologien (z.B. IT-Kompetenz, Prozess-Know-how, Kommunikationsfähigkeiten)?',
            answers: [
                {
                    label: 'nicht vorhanden',
                    value: '1',
                },
                {
                    label: 'ja, es gibt einzelne Maßnahmen',
                    value: '2',
                },
                {
                    label: 'ja, es gibt mehrere Maßnahmen',
                    value: '3',
                },
                {
                    label: 'ja, es gibt bereits viele Maßnahmen',
                    value: '4',
                },
                {
                    label: 'ja, es gibt ein umfassendes Maßnahmenpaket',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
        11: {
            name: '11',
            text: 'Wie schätzen Sie den Bedarf an Qualifizierungsmaßnahmen ein, um mittelfristige Ziele der Unternehmensentwicklung in Hinsicht auf die digitale Transformation zu erreichen?',
            answers: [
                {
                    label: 'sehr hoher Qualifizierungsbedarf',
                    value: '1',
                },
                {
                    label: 'hoher Qualifizierungsbedarf',
                    value: '2',
                },
                {
                    label: 'mittlerer Qualifizierungsbedarf',
                    value: '3',
                },
                {
                    label: 'kaum Qualifizierungsbedarf',
                    value: '4',
                },
                {
                    label: 'kein Qualifizierungsbedarf',
                    value: '5',
                },
                {
                    label: 'k.A.',
                    value: '99',
                },
            ],
        },
    },
};
