import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
const FormTextArea = (props) => {
    const {
        label,
        control,
        defaultValue,
        name,
        rules,
        error,
        notEditable,
        disabled,
        multiline,
        maxLength,
        onChangeExternal,
    } = props;

    const [currentLength, setCurrentLength] = React.useState(
        defaultValue.length
    );
    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        if (maxLength) {
            setCurrentLength(e.target.value.length);
        }
        onChange(e.target.value);
        onChangeExternal(e);
    };

    const renderHelperText = (errorText) => {
        if (errorText && maxLength > 0)
            return `${errorText} - ${currentLength}/${maxLength}`;
        if (errorText) return errorText;
        if (maxLength > 0) {
            return `${currentLength}/${maxLength}`;
        }
        return undefined;
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ value, onChange }) => {
                return (
                    <TextField
                        multiline={multiline}
                        label={label}
                        value={value}
                        name={name}
                        onChange={handleChange(onChange)}
                        error={Boolean(error)}
                        helperText={renderHelperText(error?.message)}
                        disabled={disabled || notEditable}
                        fullWidth
                        inputProps={{
                            'aria-label': label,
                            maxLength: maxLength || undefined,
                        }}
                    />
                );
            }}
            rules={rules}
        />
    );
};

FormTextArea.propTypes = {
    control: PropTypes.object,
    label: PropTypes.any,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    maxLength: PropTypes.number,
    onChangeExternal: PropTypes.func,
};

FormTextArea.defaultProps = {
    defaultValue: '',
    notEditable: false,
    multiline: true,
    onChangeExternal: () => {},
};

export default FormTextArea;
