import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import MinimisTableEditableRow from './MinimisTableEditableRow';
import MinimisTableRow from './MinimisTableRow';
import Button from '@material-ui/core/Button';
import { TableFooter } from '@material-ui/core';

const MinimisTable = (props) => {
    const {
        editRowIndex,
        handleAddNewRow,
        handleOnAbort,
        handleSaveOnNewRow,
        newRow,
        handleDeleteRow,
        handleEditRow,
        handleSaveOnUpdate,
        data,
        title,
        year,
        ab,
    } = props;

    const showDate = ab !== 'b';

    return (
        <div style={{ width: '100%', marginTop: '16px' }}>
            <Paper style={{ width: '100%' }} elevation={1}>
                <Toolbar>
                    <Typography variant="subtitle1">{title}</Typography>
                </Toolbar>
                <TableContainer>
                    <Table aria-labelledby={'table'} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {showDate && (
                                    <TableCell style={{ maxWidth: '200px' }}>
                                        Bescheiddatum
                                    </TableCell>
                                )}
                                <TableCell>Beihilfegeber</TableCell>
                                <TableCell>Förderkennzeichen</TableCell>

                                <TableCell>Form der Beihilfe</TableCell>
                                <TableCell>Fördersumme</TableCell>
                                <TableCell>Subventionswert</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) =>
                                index === editRowIndex ? (
                                    <MinimisTableEditableRow
                                        values={row}
                                        onSave={handleSaveOnUpdate(index)}
                                        onAbort={handleOnAbort}
                                        key={row.key}
                                        year={year}
                                        showDate={showDate}
                                    />
                                ) : (
                                    <MinimisTableRow
                                        values={row}
                                        onDeleteRow={handleDeleteRow(index)}
                                        onStartRowEdit={handleEditRow(index)}
                                        key={row.key}
                                        disabled={
                                            editRowIndex >= 0 || newRow !== null
                                        }
                                        showDate={showDate}
                                    />
                                )
                            )}
                            {newRow && (
                                <MinimisTableEditableRow
                                    onSave={handleSaveOnNewRow}
                                    onAbort={handleOnAbort}
                                    year={year}
                                    showDate={showDate}
                                />
                            )}
                            {data.length === 0 && !newRow && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        Keine Einträge vorhanden
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Button
                                        onClick={handleAddNewRow}
                                        disabled={
                                            editRowIndex >= 0 || newRow !== null
                                        }
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        Beihilfe hinzufügen
                                        <AddBoxIcon
                                            htmlColor={'#fff'}
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

MinimisTable.propTypes = {
    data: PropTypes.array.isRequired,
    editRowIndex: PropTypes.number.isRequired,
    handleAddNewRow: PropTypes.func.isRequired,
    handleSaveOnNewRow: PropTypes.func.isRequired,
    handleOnAbort: PropTypes.func.isRequired,
    newRow: PropTypes.object,
    handleSaveOnUpdate: PropTypes.func.isRequired,
    handleDeleteRow: PropTypes.func.isRequired,
    handleEditRow: PropTypes.func.isRequired,
    title: PropTypes.string,
    year: PropTypes.number,
};

export default MinimisTable;
