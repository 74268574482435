export const step7Texts = {
    title: 'Verwendungsnachweis abschicken',
    text1: {
        paragraphs: [
            {
                type: 'p',
                text: '[u]Um die Verwendungsnachweiseinreichung formal abzuschließen, müssen Sie den Verwendungsnachweis nach Herunterladen der "Unterschriftsseite" und der Angabe Ihrer Übersendung noch abschicken.[u]',
            },
            {
                type: 'p',
                text: 'Ihr verbindlicher Verwendungsnachweis besteht aus dem "Unterschriftsblatt", das Sie unter folgendem Link herunterladen können:',
            },
        ],
    },
    outline1: {
        title: 'Ihr Verwendungsnachweis gilt erst dann als rechtverbindlich eingegangen, wenn Sie die Datei "Unterschriftsblatt" eingereicht haben. Erst mit deren Eingang beim DLR Projektträger kann mit der Bearbeitung Ihres Verwendungsnachweises begonnen werden. Um den verbindlichen Verwendungsnachweis bei uns einzureichen, können Sie zwischen folgenden Alternativen wählen:',
        documentTransmission: {
            name: 'documentTransmission',
            buttons: [
                {
                    value: 'a',
                    label: 'Händische Unterschrift der von Ihnen heruntergeladenen PDF und Versand auf dem Postweg',
                },
                {
                    value: 'b',
                    label: 'Qualifizierte digitale Signatur (sign-me der D-Trust GmbH und D-TRUST Signaturkarte) und elektronische Übermittlung',
                },
            ],
            rules: { required: 'Bitte treffen Sie eine Auswahl' },
        },
        hint: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Die digitale Einreichung des Verwendungsnachweises / des Unterschriftsblattes ist ausschließlich unter Nutzung einer der beiden qualifizierten digitalen Signaturen sign-me der D-Trust GmbH oder D-TRUST Signaturkarte möglich.',
                },
                {
                    type: 'p',
                    text: 'Im Fall einer nicht gültigen digitalen Signatur gilt der Verwendungsnachweis als nicht eingereicht und die Nachreichung eines rechtsverbindlich unterschriebenen Verwendungsnachweises / Unterschriftsblattes ist notwendig. Die Anträge werden nach Eingang des rechtsverbindlich unterschriebenen Verwendungsnachweises beim DLR Projektträger abgearbeitet.',
                },
            ],
        },
    },
    outline2_a: {
        title: 'Um den Verwendungsnachweis rechtsverbindlich einzureichen sind folgende Schritte notwendig:',
        list: {
            paragraphs: [
                {
                    type: 'list',
                    text: '[li]Drucken Sie die heruntergeladene Datei "Unterschriftsblatt" aus und unterzeichnen Sie den Ausdruck handschriftlich[li][li]Bitte senden Sie ausschließlich die Datei "Unterschriftsblatt" als Papier-Ausdruck mit Ihrer rechtsverbindlichen Unterschrift versehen, so zeitnah wie es Ihnen möglich ist, dem DLR Projektträger auf dem Postweg zu.[li]',
                },
                {
                    type: 'p',
                    text: 'Sie erhalten dann eine Eingangsbestätigung per Mail.',
                },
                {
                    type: 'p',
                    text: 'Erst mit dem Tag des Posteinganges beim DLR Projektträger gilt Ihr Verwendungsnachweis als rechtsverbindlich eingegangen. Ab diesem Zeitpunkt kann Ihr Verwendungsnachweis abschließend geprüft werden.',
                },
            ],
        },
    },
    outline2_b: {
        title: 'Um den Verwendungsnachweis elektronisch zu signieren sind folgende Schritte notwendig:',
        list: {
            paragraphs: [
                {
                    type: 'list',
                    text: '[li]Unterzeichnen Sie die heruntergeladene Datei „Unterschriftsblatt“ mit Ihrer elektronischen [tooltip|0]Signatur.[tooltip][li][li]Bitte laden Sie die von Ihnen elektronisch unterzeichnete Datei „Unterschriftsblatt“ als pdf hier hoch.[li]',
                    tooltips: [
                        {
                            interactive: true,
                            paragraphs: [
                                {
                                    type: 'p',
                                    text: 'Folgende Signaturverfahren werden akzeptiert:',
                                },
                                {
                                    type: 'list',
                                    text: '[li]Signaturkarten / Digitale Unterschriften mit qualifizierten Signaturen[li][li][a|https://www.bundesdruckerei.de/de/loesungen/Signaturkarten#Produktdetails]https://www.bundesdruckerei.de/de/loesungen/Signaturkarten#Produktdetails[a][li][li]Elektronisch signierte Dokumente / sign me[li][li][a|https://www.bundesdruckerei.de/de/2837-sign-me]https://www.bundesdruckerei.de/de/2837-sign-me[a][li]',
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'p',
                    text: 'Sie erhalten dann direkt im Anschluss eine Eingangsbestätigung per Mail.',
                },
            ],
        },
    },
    hint: {
        paragraphs: [
            {
                type: 'p',
                text: 'Mit dem Posteingang der "Unterschriftsseite" oder dem Hochladen der "Unterschriftsseite" mit qualifizierter digitaler Signatur beim DLR-Projektträger und dem Abschluss des Verwendungsnachweises im Digital Jetzt Förderportal gilt Ihr Verwendungsnachweis als rechtsverbindlich eingereicht. Sie werden über den Eingang der "Unterschriftsseite" per E-Mail informiert. Im Anschluss daran kann mit der Prüfung Ihres Verwendungsnachweises begonnen werden. Von Fragen zum Bearbeitungsstand bitten wir abzusehen. Hierfür bitten wir um Ihr Verständnis.',
            },
            {
                type: 'p',
                text: 'Als letzten Schritt müssen Sie den Verwendungsnachweis im Förderportal abschicken. Hierfür klicken Sie auf den Button „Einreichen“.',
            },
        ],
    },
    downloadHint:
        'Um den Verwendungsnachweis abschließen zu können, müssen Sie das "Unterschriftsblatt" herunterladen.',
    uploadHint:
        'Bitte laden Sie das elektronisch signierte Dokument hoch, um den Verwendungsnachweis abzuschließen.',
    button: 'Einreichen',
    dialog: {
        title: 'Verwendungsnachweis abschicken',
        body: 'Sind Sie sicher, dass der Verwendungsnachweis final eingereicht wird?',
        close: 'Abbrechen',
        submit: 'Einreichen',
    },
    success: 'Verwendungsnachweis erfolgreich eingereicht.',
};
