import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const MinimisCollapse = (props) => {
    const { children, label } = props;
    const [open, setOpen] = React.useState(true);

    const toggle = () => {
        setOpen((state) => !state);
    };

    return (
        <>
            <Typography gutterBottom>
                {label}
                <IconButton
                    color="primary"
                    onClick={toggle}
                    aria-label={
                        open ? `${label} ausblenden` : `${label} einblenden`
                    }
                >
                    {!open ? (
                        <KeyboardArrowDownIcon />
                    ) : (
                        <KeyboardArrowUpIcon />
                    )}
                </IconButton>
            </Typography>
            <Collapse style={{ width: '100%' }} in={open}>
                {children}
            </Collapse>
        </>
    );
};

MinimisCollapse.propTypes = {
    children: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
};

MinimisCollapse.defaultProps = {};

export default MinimisCollapse;
