import uniqid from 'uniqid';
import {
    employeeChange,
    employeeValue,
    parseStateToString,
} from '../../../util/Raffle/RaffleFunctions';
import moment from 'moment';
import { validateNotEmpty } from '../../../util/Validation/ValidateNotEmpty';
import { validateEmail } from '../../../util/Validation/EmailValidation';

export const raffleTexts = {
    title: 'Bewerbung Antragskontingent',
    subtitle: 'SomeTextWhichNeedsToBeSet',
    form: {
        acceptTerms: {
            name: 'acceptTerms',
            label: (dayOfRaffle) =>
                `Ja, ich möchte an der nächsten Zuweisung der Antragskontingente am ${dayOfRaffle} teilnehmen.`,
            rules: {
                required:
                    'Bitte bestätigen Sie, dass Sie an der aktuellen Losziehung teilnehmen möchten.',
            },
        },
        applicantIsInternal: {
            name: 'applicantIsInternal',
            label: 'Sind Sie Mitarbeitender (oder Geschäftsführung, Inhaber) des Unternehmens oder vom Unternehmen bevollmächtigt die Bewerbung für ein Antragskontingent vorzunehmen?',
            options: [
                {
                    label: 'Ich bin Mitarbeitender bzw. unternehmensintern',
                    value: 'true',
                    key: uniqid(),
                },
                {
                    label: 'Ich bin vom Unternehmen bevollmächtigt',
                    value: 'false',
                    key: uniqid(),
                },
            ],
            rules: {
                validate: (value) =>
                    validateNotEmpty(
                        value,
                        'Bitte geben Sie an, ob Sie unternehmensintern oder vom Unternehmen bevollmächtigt sind.'
                    ),
            },
        },
        employeesCount: {
            name: 'employeesCount',
            label: 'Anzahl der Mitarbeiter',
            options: [
                { label: '0 bis 2', value: 0, key: uniqid() },
                { label: '3 bis 10', value: 3, key: uniqid() },
                { label: '11 bis 50', value: 11, key: uniqid() },
                { label: '51 bis 250', value: 51, key: uniqid() },
                { label: '251 bis 499', value: 251, key: uniqid() },
                { label: '500 und mehr', value: 500, key: uniqid() },
            ],
            valueMutator: employeeValue,
            onChangeMutator: employeeChange,
            rules: {
                min: {
                    value: 3,
                    message: 'Sie müssen mindestens 3 Angestellte haben',
                },
                max: {
                    value: 499,
                    message: 'Sie dürfen maximal 499 Angestellte haben',
                },
                required: 'Bitte geben Sie die Mitarbeitendenzahl an.',
            },
        },
        isCommunalCompany: {
            name: 'isCommunalCompany',
            label: 'Ist das Unternehmen einer der nachfolgend genannten Rechtsformen bzw. Kriterien zugeordnet?: Unternehmen mit öffentlicher Beteiligung; gemeinnützige Unternehmen; Unternehmen des öffentlichen Rechts und Religionsgemeinschaften sowie deren Beteiligungen; Unternehmen in Gründung; Vereine, Stiftungen; Insolvente Unternehmen oder Unternehmen in Schwierigkeiten (VO (EU) Nr. 651/2014)',
            options: [
                { label: 'Ja', value: 'true', key: uniqid() },
                { label: 'Nein', value: 'false', key: uniqid() },
            ],
            row: true,
            rules: {
                validate: (value) =>
                    validateNotEmpty(
                        value,
                        'Bitte geben Sie an, ob Ihr Unternehmen einer der angegebenen Rechtsformen bzw. Kriterien zugeordnet ist.'
                    ),
            },
        },
        isWeak: {
            name: 'isWeak',
            label: 'Strukturschwache Region',
            options: [
                {
                    label: 'Das Investitionsvorhaben wird in einer strukturschwachen Region umgesetzt.',
                    value: 'true',
                    key: uniqid(),
                },
                {
                    label: 'Das Investitionsvorhaben wird NICHT in einer strukturschwachen Region umgesetzt.',
                    value: 'false',
                    key: uniqid(),
                },
            ],
            rules: {
                validate: (value) =>
                    validateNotEmpty(
                        value,
                        'Bitte geben Sie an, ob das Investitionsvorhaben in einer strukturschwachen Region umgesetzt wird.'
                    ),
            },
        },
        expectedSumInvest: {
            name: 'expectedSumInvest',
            label: 'Voraussichtliche Gesamtinvestitionssumme',
            alert: {
                title: '',
                body: 'Maßgebend ist der Umsetzungsort der finanziellen Hauptinvestition. Wenn dies nicht eindeutig zu benennen ist, ist der Umsetzungsort mit der größten Mitarbeiterzahl maßgebend.',
            },
            alert2: {
                title: '',
                body:
                    'Bitte geben Sie im folgenden Feld Ihre geplante Investitionssumme an. Beachten Sie dabei, dass es ' +
                    'eine Mindestfördersumme (Zuschuss des Bundes) gibt. Die minimale Fördersumme beträgt 17.000 Euro ' +
                    'in Modul 1 („Investition in digitale Technologien“) sowie bei kumulativer Inanspruchnahme der ' +
                    'Module 1 & 2 und 3.000 Euro in Modul 2 (Investition in die Qualifizierung der Mitarbeitenden).\n' +
                    '\n' +
                    'Beispiel: Modul 1:  Bei einer Gesamt-Investitionssumme von 34.000€ und 50% Förderquote beträgt die ' +
                    'Fördersumme (Zuschuss): 17.000€.',
            },
            rules: {
                required:
                    'Bitte geben Sie eine voraussichtliche Gesamtinvestitionssumme an.',
                min: {
                    value: 1,
                    message:
                        'Die voraussichtliche Investitionssumme muss größer als 0 sein.',
                },
            },
        },
        companyName: {
            name: 'companyName',
            label: 'Rechtsverbindlicher Unternehmensname',
            rules: {
                required: 'Bitte tragen Sie den Unternehmensnamen ein.',
                validate: (value) =>
                    /[a-zA-Z]/.test(value) ||
                    'Bitte geben Sie einen gültigen Unternehmensnamen an.',
            },
        },
        companyNameHint:
            'Achtung: verbindlich für die mögliche Antragstellung! Sie finden den rechtsverbindlichen Namen Ihres Unternehmens bspw. im Handelsregisterauszug.',
        wskCompanyNameHint:
            'Achtung: der rechtsverbindliche Unternehmensname ist verbindlich für die mögliche Antragstellung! Man findet den rechtsverbindlichen Namen des Unternehmens bspw. im Handelsregisterauszug.',
        isValueChain: {
            name: 'isValueChain',
            label: 'Bitte geben Sie an, ob Sie den "Digital Jetzt"-Antrag als einzelnes Unternehmen oder gemeinsam mit weiteren Unternehmen als Teil einer Wertschöpfungskette stellen.',
            options: [
                {
                    label: 'Ich stelle den Antrag als einzelnes Unternehmen.',
                    value: 'false',
                    key: uniqid(),
                },
                {
                    label: 'Ich stelle den Antrag zusammen mit weiteren Unternehmen innerhalb bzw. entlang einer Wertschöpfungskette (WSK).',
                    value: 'true',
                    key: uniqid(),
                },
            ],
            rules: {
                validate: (value) =>
                    validateNotEmpty(
                        value,
                        'Bitte geben Sie an, ob Sie den Antrag als einzelnes Unternehmen oder zusammen mit weiteren Unternehmen innerhalb einer Wertschöpfungskette stellen.'
                    ),
            },
        },
        isLead: {
            name: 'isLead',
            label: 'Handelt es sich bei Ihrem Unternehmen um das Unternehmen der Wertschöpfungskette, das als "Lead-Partner" agiert und somit den ersten Antrag stellen wird?',
            options: [
                { label: 'Ja', value: 'true', key: uniqid() },
                { label: 'Nein', value: 'false', key: uniqid() },
            ],
            rules: {
                validate: (value) =>
                    validateNotEmpty(
                        value,
                        'Bitte geben Sie an, welche Rolle Sie innerhalb der WSK einnehmen.'
                    ),
            },
        },
        chainMembers: {
            texts: {
                registered: {
                    paragraphs: [
                        {
                            type: 'p',
                            text: `Die Partnerunternehmen der Wertschöpfungskette müssen registriert sein. Bitte geben Sie hier (sofern sie „Lead-Partner“ der Wertschöpfungskette sind) die registrierten E-Mail Adressen der Partnerunternehmen und die [u]rechtsverbindlichen[u] Unternehmensnamen an.`,
                        },
                        {
                            type: 'p',
                            text: `Sofern die Wertschöpfungskette ein Antragsbudget erhalten hat, können die Partnerunternehmen der Wertschöpfungskette mit dem Wertschöpfungs-Code einen Antrag stellen.`,
                        },
                    ],
                },
            },
            name: 'chainMembers',
            label: 'ChainMembers',
            alert: {
                isNotLead:
                    'Als Unternehmen einer Wertschöpfungskette nimmt nur Ihr Lead-Partner an der Zuweisung von Antragskontingenten teil. Dies ist der Partner der nach der erfolgreichen Zuweisung den ersten Antrag stellt und damit einen Wertschöpfungs-Code für alle weiteren Partner erzeugt.',
            },
            rules: {
                username: {
                    required: 'Bitte geben Sie eine E-Mail Adresse an.',
                    validate: (value) =>
                        validateEmail(
                            value,
                            'Bitte geben Sie eine gültige E-Mail an'
                        ),
                },
                companyName: {
                    required: 'Bitte geben Sie einen Unternehmensnamen an.',
                    validate: (value) =>
                        /[a-zA-Z]/.test(value) ||
                        'Bitte geben Sie einen gültigen Unternehmensnamen an.',
                },
            },
        },
        obligationCheck: {
            headline: 'Einwilligungserklärung',
            name: 'obligationCheck',
            label: [
                'Ich habe die',
                'Vorgaben zur Verbindlichkeit',
                'der Unternehmensdaten bei der Bewerbung auf ein Antragskontingent zur Kenntnis genommen und akzeptiere diese. Mir ist bewusst, dass diese Bedingungen auch bestimmen, dass ein Antragskontingent, welches ein bestimmtes Unternehmen erhalten hat, auch nur von diesem Unternehmen zur Antragstellung genutzt werden kann und eine Weitergabe des Antragskontingents ein anderes Unternehmen nicht zur Antragstellung berechtigt.',
            ],
            error: 'Bitte akzeptieren Sie die Vorgaben zur Verbindlichkeit der Unternehmensdaten.',
            tooltip:
                'Bitte beachten Sie: Es ist nur eine Bewerbung pro Unternehmen pro Monat zulässig. Bei einer erfolgreichen Zuweisung eines Antragskontingents ist ausschließlich das Unternehmen zur Antragstellung berechtigt, welches sich für die Zuteilung eines Antragskontingents namentlich beworben hat. Jedwede Weitergabe des zugewiesenen Antragskontingents ist unzulässig.',
        },
    },

    wsk_helper_text:
        'Bitte beachten Sie, dass verbundene Unternehmen sowohl bei der Zahl der Mitarbeitenden als auch bei der De-minimis-Grenze gemeinsam betrachtet werden',

    modals: {
        employee: {
            title: 'Nicht förderfähig',
            body: 'Ihr Unternehmen ist nicht antragsberechtigt. Ausdrücklich von der Förderung ausgenommen sind Unternehmen mit weniger als drei Mitarbeitenden oder mehr als 499 Mitarbeitenden. Bitte beachten Sie, dass falsche Angaben den Widerruf der auf dieser Grundlage bewilligten Förderung und die Rückforderung der ausgezahlten Beträge nebst Verzinsung zur Folge haben können.',
            button: 'Eingabe überprüfen',
            logout: 'Abmelden',
        },
        isCommunalCompany: {
            title: 'Nicht förderfähig',
            body: 'Ihr Unternehmen ist nicht antragsberechtigt. Begründung (siehe Webseite des Bundesministeriums für Wirtschaft und Klimaschutz unter „Wichtige Fragen und Antworten rund um die Förderung“). Bitte beachten Sie, dass falsche Angaben den Widerruf der auf dieser Grundlage bewilligten Förderung und die Rückforderung der ausgezahlten Beträge nebst Verzinsung zur Folge haben können.',
            button: 'Eingabe überprüfen',
            logout: 'Abmelden',
        },
    },
    display: {
        participating1: (month, year) =>
            `Sie nehmen an der nächsten Zuweisung für ein monatliches Antragskontingent der Fördermittel für ${month} ${year} teil.`,
        participating2:
            'Sofern Ihnen ein Antragskontingent zugewiesen wird, erhalten Sie eine entsprechende E-Mail innerhalb von 24 Stunden nach der Zuweisung. Die E-Mail erläutert ebenfalls das weitere Vorgehen.',
    },
    notEligible:
        'Sie können nicht an der Zuweisung für ein monatliches Antragskontingent teilnehmen, da Ihr Antrag nicht förderfähig ist.',
    proposalClosed: {
        title: 'Antragsübersicht',
        closed: 'Aktuell können Sie sich nicht für die Zuweisung für ein monatliches Antragskontingent anmelden und Sie besitzen keinen abgeschlossenen Antrag.',
        notClosed: (pot, status) =>
            `Die nächste Ziehung ist am ${moment(pot.raffle).format(
                'LL'
            )}. ${parseStateToString(status, pot)}`,
    },
    cannotParticipate:
        'Sie haben bereits ein Antragskontingent zur Förderung in „Digital Jetzt“  erhalten, aus diesem Grund ist eine erneute Bewerbung um ein Antragskontingent nicht möglich. Je nach Status Ihres Vorhaben können Sie unter „Antragsübersicht“ oder „Verwendungsnachweis“ den aktuellen Stand einsehen bzw. die Bearbeitung fortführen.',
    inObjection:
        'Eine Teilnahme am Losverfahren zur Förderung zu "Digital Jetzt" ist nicht möglich, da ein Widerspruch eingereicht wurde.',
    wasDrawn: (start, end) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Ihnen wurde ein Antragskontingent in Digital Jetzt zugewiesen!`,
            },
            {
                type: 'p',
                text: `Sie haben vom ${start} Uhr bis zum ${end} Uhr die Möglichkeit, einen Antrag zu bearbeiten und einzureichen. Gehen Sie dafür auf den entsprechenden Link „Antragsübersicht“ in der Menüleiste und erstellen dort einen neuen Antrag bzw. bearbeiten Sie Ihren laufenden Antrag.`,
            },
            {
                type: 'p',
                text: `Bitte beachten Sie: Das zugewiesene Antragskontingent berechtigt ausschließlich das Unternehmen zur Antragstellung, das sich für die Zuteilung eines Antragskontingentes namentlich beworben hat. Eine Weitergabe des zugewiesenen Antragskontingentes ist nicht zulässig.`,
            },
            {
                type: 'p',
                text: `[u]Wichtig[u]: Die Antragseinreichung ist nur bis zum ${end} Uhr möglich. Sollten Sie bis dahin Ihren Antrag nicht eingereicht haben, verfällt Ihr Antragskontingent.`,
            },
        ],
    }),
    notDrawn: (start, isProposalPage) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Ihre Teilnahme an der Bewerbung für ein Antragskontingent war leider nicht erfolgreich.${
                    isProposalPage
                        ? ' Somit können Sie keinen Antrag stellen.'
                        : ''
                }`,
            },
            {
                type: 'p',
                text: `[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet.[b] Aufgrund der Befristung der Förderrichtlinie ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.`,
            },
        ],
    }),
    closedForever:
        'Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet. Aufgrund der Befristung der Förderrichtlinie ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.',
    isClosedBefore: (dayOfRaffle) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Eine Teilnahme an der Bewerbung für ein Antragskontingent ist nicht mehr möglich.`,
            },
            {
                type: 'p',
                text: `Die Zuweisung der Antragskontingente erfolgt am ${dayOfRaffle}.`,
            },
            {
                type: 'p',
                text: `Sofern Ihnen ein Antragskontingent zugewiesen wird, erhalten Sie eine entsprechende E-Mail innerhalb von 24 Stunden nach der Zuweisung. Die E-Mail erläutert ebenfalls das weitere Vorgehen.`,
            },
        ],
    }),
    isClosed: (nextRaffle, raffleBegin) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Im letzten Zuweisungsverfahren wurde Ihnen kein Antragskontingent zugeteilt.`,
            },
            {
                type: 'p',
                text: `[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet.[b] Aufgrund der Befristung der Förderrichtlinie ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.`,
            },
        ],
    }),
    isClosedLastNotParticipated: (nextBegin) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Eine Teilnahme an der Bewerbung für ein Antragskontingent ist nicht mehr möglich.`,
            },
            {
                type: 'p',
                text: `[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet.[b] Aufgrund der Befristung der Förderrichtlinie ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.`,
            },
        ],
    }),
    isClosedWithoutNextRaffle:
        'Eine Teilnahme an der Bewerbung für ein Antragskontingent ist nicht mehr möglich.',
    isClosedWasDrawn: (submitStart, submitEnd) => ({
        paragraphs: [
            {
                type: 'p',
                text: `Ihnen wurde ein Antragskontingent in Digital Jetzt zugewiesen!`,
            },
            {
                type: 'p',
                text: `Sie haben vom ${submitStart} Uhr bis zum ${submitEnd} Uhr die Möglichkeit, einen Antrag zu bearbeiten und einzureichen. Gehen Sie dafür auf den entsprechenden Link „Antragsübersicht“ in der Menüleiste und erstellen dort einen neuen Antrag bzw. bearbeiten Sie Ihren laufenden Antrag.`,
            },
            {
                type: 'p',
                text: `Bitte beachten Sie: Das zugewiesene Antragskontingent berechtigt ausschließlich das Unternehmen zur Antragstellung, das sich für die Zuteilung eines Antragskontingentes namentlich beworben hat. Eine Weitergabe des zugewiesenen Antragskontingentes ist nicht zulässig.`,
            },
            {
                type: 'p',
                text: `[u]Wichtig[u]: Die Antragseinreichung ist nur bis zum ${submitEnd} Uhr möglich. Sollten Sie bis dahin Ihren Antrag nicht eingereicht haben, verfällt Ihr Antragskontingent.`,
            },
        ],
    }),
    canParticipate: {
        text0: 'Sie können an der Bewerbung teilnehmen. Füllen Sie dazu bitte das Formular aus und bestätigen Sie, dass das im Formular namentlich benannte Unternehmen an der Zuweisung der Antragskontingente teilnehmen möchte. Die Angabe des sich bewerbenden Unternehmens ist verbindlich.',
        text1: 'Bitte beachten Sie: Es ist nur eine Bewerbung pro Unternehmen pro Monat zulässig.',
        text2: 'Bei einer erfolgreichen Zuweisung eines Antragskontingents ist ausschließlich das Unternehmen zur Antragstellung berechtigt, welches sich für die Zuteilung eines Antragskontingents namentlich beworben hat. Jedwede Weitergabe des zugewiesenen Antragskontingents ist unzulässig.',
    },
    canParticipateProposalPage:
        'Bevor Sie einen Antrag stellen können, muss Ihnen ein Antragsbudget zugewiesen worden sein. Füllen Sie dazu bitte das Formular unter "Bewerbung Antragskontingent" aus und bestätigen Sie, dass Sie an der Zuweisung teilnehmen wollen.\n',
    invalidTime: (next) => ({
        paragraphs: [
            {
                type: 'p',
                text: 'Sie haben Ihren Antrag nicht rechtzeitig eingereicht.',
            },
            {
                type: 'p',
                text: `[b]Die Förderrichtlinie „Digital jetzt – Investitionsförderung für KMU“ ist gemäß Punkt 7 bis zum 31.12.2023 befristet.[b] Aufgrund der Befristung der Förderrichtlinie ist eine Bewerbung auf ein Antragskontingent nicht mehr möglich.`,
            },
        ],
    }),
};
