import create from 'zustand';
import { isRegisterOpen } from '../lib/api/Register/RegisterActions';
import { getRaffleState, RAFFLE_STATE } from '../util/Raffle/RaffleFunctions';

const initial = {
    status: {
        register: {
            isOpen: true,
            error: undefined,
            loading: true,
        },
        open: {
            isOpen: true,
            error: undefined,
            loading: true,
        },
    },
    raffles: [],
    rafflePots: [],
    allRafflePots: [],
    raffleStatus: RAFFLE_STATE.RAFFLE_UNKNOWN,
    rafflePotsError: undefined,
};

const withFunctions = (set, get) => ({
    ...initial,
    checkRegister: async () => {
        try {
            const open = await isRegisterOpen();
            set((state) => {
                state.status.register.isOpen = open;
                state.status.register.error = undefined;
                state.status.register.loading = false;
            });
        } catch (e) {
            set((state) => {
                state.status.register.isOpen = false;
                state.status.register.error = e;
                state.status.register.loading = false;
            });
        }
    },
    clearRegisterError: () => {
        set((state) => {
            state.status.register.error = undefined;
        });
    },
    raffleState: () => {
        const status = getRaffleState(get().raffles, get().rafflePots);
        set((state) => (state.raffleStatus = status));
        return status;
    },
    setRaffles: (raffles) => {
        set((state) => (state.raffles = raffles));
        if (get().rafflePots) get().raffleState();
    },
    setRafflePots: (rafflePots) => {
        set((state) => {
            state.rafflePots = rafflePots;
            state.rafflePotsError = undefined;
        });
        if (get().raffles) get().raffleState();
    },
    setAllRafflePots: (rafflePots) => {
        set((state) => {
            state.allRafflePots = rafflePots;
        });
    },
    setValidRaffleCreated: (data) => {
        set((state) => {
            const idx = state.raffles.findIndex(
                (r) => r.valid === true && !Boolean(r.proposal)
            );
            if (idx >= 0) {
                state.raffles[idx].proposal = data;
            }
        });
    },
});

export const useAppState = create(withFunctions);
