import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';

const VNOverviewContainer = (props) => {
    const { stepData, Ui, headline } = props;

    const mountedRef = React.useRef(true);

    React.useEffect(
        () => () => {
            mountedRef.current = false;
        },
        [mountedRef]
    );

    const [open, setOpen] = React.useState(false);

    const called = React.useRef(false);
    const handleChange = (next) => {
        if (next && !called.current) {
            called.current = true;
        }

        setOpen(next);
    };

    return (
        <Accordion expanded={open} onChange={() => handleChange(!open)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={headline}
                id={`panel${headline}bh-header`}
            >
                <Typography component={'h2'}>{headline}</Typography>
            </AccordionSummary>
            {open && (
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <Ui data={stepData} />
                </AccordionDetails>
            )}
        </Accordion>
    );
};

VNOverviewContainer.propTypes = {
    stepData: PropTypes.array,
    Ui: PropTypes.any,
    headline: PropTypes.string,
};

export default VNOverviewContainer;
