import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { texts as oft } from '../../../../texts/Forms/OverviewFormTexts';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const ManagementOverview = (props) => {
    const data = props.data;
    return data.staff.map((staff) => {
        let headline = staff.isProjectManagement
            ? oft.management.content.headline.management
            : oft.management.content.headline.ceo;
        if (staff.isProjectManagement && 0 === staff.func) {
            headline += ' / ' + oft.management.content.headline.ceo;
        }
        const salutation =
            staff.salutation === 'f'
                ? 'Frau'
                : staff.salutation === 'm'
                ? 'Herr'
                : 'Person';
        return (
            <div key={staff.id}>
                <Typography variant="h6" component="h3">
                    {headline}
                </Typography>
                <div style={{ marginLeft: '10px' }}>
                    <Typography>{`${salutation} ${staff.title} ${staff.name} ${staff.lastname}`}</Typography>
                    <Typography>
                        <PhoneIcon
                            style={{ fontSize: 'small', marginRight: '5px' }}
                        />
                        {staff.phone}
                    </Typography>
                    <Typography>
                        <MailOutlineIcon
                            style={{ fontSize: 'small', marginRight: '5px' }}
                        />
                        {staff.email}
                    </Typography>
                    {staff.fax && <Typography>Fax: {staff.fax}</Typography>}
                </div>
            </div>
        );
    });
};

ManagementOverview.propTypes = {
    data: PropTypes.object,
};

export default ManagementOverview;
