import React from 'react';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import { centToString } from '../../../../../util/Money/MoneyFunctions';

const RenderMinimiNode = (props) => {
    const { minimi } = props;

    return (
        <TreeItem
            nodeId={minimi.id + '_0'}
            label={`Förderkennzeichen: ${minimi.fkz}`}
        >
            <TreeItem
                nodeId={minimi.id + '_1'}
                label={`Fördersumme: ${centToString(minimi.amount)} €`}
            />
            <TreeItem
                nodeId={minimi.id + '_2'}
                label={`Beihilfegeber: ${minimi.provider}`}
            />
            <TreeItem
                nodeId={minimi.id + '_3'}
                label={`Form der Beihilfe: ${minimi.type}`}
            />
        </TreeItem>
    );
};

RenderMinimiNode.propTypes = {
    minimi: PropTypes.object,
};

export default RenderMinimiNode;
