import { api, authApi } from '../AxiosConfig/Config';
import { getRafflePots } from '../Raffle/RaffleActions';
import { useAppState } from '../../../Stores/AppState.store';
import {
    buildUrl,
    generateCodeChallenge,
    generateCodeVerifier,
    generateState,
} from './AuthorizeActions';
import { receiveMessage } from '../../../util/Auth/ReceiveMessage';
import { sortRaffles } from '../../../util/Raffle/RaffleFunctions';
import Cookies from 'js-cookie';

export function fetchUserData() {
    if (!Cookies.get('login_token')) {
        return Promise.reject();
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await api().get('/action/account-profile');

            const { getState } = useAppState;
            if (getState().rafflePotsError) await getRafflePots(false);

            const { raffles, ...user } = response.data;

            const sorted = sortRaffles(raffles);
            getState().setRaffles(sorted);

            resolve(user);
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchToken({ verify, code }) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'authorization_code');
            formData.set('client_id', process.env.REACT_APP_CLIENT_ID);
            formData.set('code', code);
            formData.set('code_verifier', verify);
            formData.set('scope', 'user');
            formData.set(
                'redirect_uri',
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_REDIRECT}`
            );

            const response = await authApi.post('/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function removeToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await authApi.get('/logout');
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function refreshToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'refresh_token');
            formData.set('client_id', process.env.REACT_APP_CLIENT_ID);

            const response = await authApi.post('/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
}

export function loginNewWindow(cb) {
    return new Promise(async (resolve, reject) => {
        const verify = generateCodeVerifier();
        const challenge = generateCodeChallenge(verify);
        const state = generateState();
        const url = buildUrl(challenge, state);

        let data = null;
        const setData = (d) => {
            data = d;
        };
        window.addEventListener(
            'message',
            receiveMessage(window, setData),
            false
        );
        const w = window.open(url, '_blank');

        cb('Sie wurden zum Login weitergeleitet...');
        let timeout, interval;
        try {
            await new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    window.focus();
                    w.close();
                    reject('Login timeout');
                }, parseInt(process.env.REACT_APP_LOGIN_TIMEOUT));
                interval = setInterval(() => {
                    if (w.closed && !data)
                        reject('Das Loginfenster wurde geschlossen');
                    if (!data) return;
                    clearInterval(interval);
                    clearTimeout(timeout);
                    resolve();
                }, 1000);
            });
        } catch (e) {
            clearInterval(interval);
            clearTimeout(timeout);
            reject(e);
        }

        if (!data || state !== data.state) {
            reject('Fehler beim Übertragen der Logindaten');
        }
        try {
            cb('Nutzerdaten akzeptiert, Sie werden eingeloggt...', 2);
            await fetchToken({ verify: verify, code: data.code });
        } catch (e) {
            reject('Fehler beim Einloggen');
        }

        try {
            cb('Lade Nutzerprofil...', 3);
            const user = await fetchUserData();

            resolve(user);
        } catch (e) {
            reject('Fehler beim Laden des Nutzerprofils');
        }
    });
}
