import React from 'react';
import PropTypes from 'prop-types';
import useAsync from '../../hooks/useAsync';
import { useSnackbar } from 'notistack';
import WizardStepDisplay from '../../components/Wizard/WizardStepDisplay';
import WizardButtons from '../../components/Wizard/WizardButtons';
import WizardSteps from '../../components/Wizard/WizardSteps';
import {
    isStepComplete,
    wizardTransition,
} from '../../lib/api/Wizard/WizardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormSkelleton from '../../components/Forms/FormSkelleton';
import ReloadAction from '../../components/Partials/Actions/ReloadAction';
import { steps } from '../../config/WizardSteps';
import _ from 'lodash';

const WizardContainer = (props) => {
    const { currentStep, proposalId, reloadProposal } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mounted = React.useRef(true);
    const nextAfterSave = React.useRef(false);

    const [stepComplete, setStepComplete] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(currentStep);
    //const [activeStep, setActiveStep] = React.useState(3);
    const [submitError, setSubmitError] = React.useState(null);
    React.useEffect(
        () => () => {
            mounted.current = false;
        },
        [mounted]
    );

    const step = steps[activeStep];

    const formFunction = React.useMemo(
        () => step.fetchFunc(proposalId),
        [proposalId, step]
    );

    const {
        pending: loadingForm,
        value: valueForm,
        error: errorForm,
        execute: executeForm,
    } = useAsync(formFunction, mounted);

    React.useEffect(() => {
        if (!errorForm) return;
        enqueueSnackbar(errorForm.message(), {
            variant: errorForm.type(),
            action: ReloadAction(executeForm, closeSnackbar),
        });
    }, [errorForm, enqueueSnackbar, executeForm, closeSnackbar]);

    const [saving, setSaving] = React.useState(false);
    const onSubmit = (data) => {
        setSaving(true);
        setSubmitError(null);
        return step
            .saveFunc(proposalId, data)
            .then((response) => {
                if (!mounted.current) return;
                if (!response.data().hide) {
                    enqueueSnackbar(response.message(), {
                        variant: response.type(),
                    });
                }
                setStepComplete(true);
                if (nextAfterSave.current) {
                    nextAfterSave.current = false;
                    handleSingleStep(1);
                }
                return true;
            })
            .catch((error) => {
                if (!mounted.current) return;
                enqueueSnackbar(error.message(), { variant: error.type() });
                setSubmitError(error);
                return false;
            })
            .finally(() => {
                if (mounted.current) setSaving(false);
            });
    };

    const [transitioning, setTransitioning] = React.useState(false);
    const handleStep = (step) => {
        if (activeStep === step) return;
        setTransitioning(true);
        wizardTransition(proposalId, step, step < activeStep)
            .then((response) => {
                if (!mounted.current) return;
                if (step === steps.length) {
                    reloadProposal();
                    return;
                }
                enqueueSnackbar(response.message(), {
                    variant: response.type(),
                });
                setActiveStep(step);
            })
            .catch((error) => {
                enqueueSnackbar(error.message(), { variant: error.type() });
            })
            .finally(() => {
                if (mounted.current) setTransitioning(false);
            });
    };

    const handleSingleStep = (summand) => {
        handleStep(activeStep + summand);
    };

    React.useEffect(() => {
        setStepComplete(false);
        isStepComplete(proposalId, activeStep)
            .then((complete) => {
                if (mounted.current && complete) setStepComplete(true);
            })
            .catch((error) => {
                enqueueSnackbar(error.message(), { variant: error.type() });
            });
    }, [activeStep, setStepComplete, enqueueSnackbar, proposalId]);

    const Form = step.component;

    return (
        <>
            <WizardSteps
                handleStep={handleStep}
                activeStep={activeStep}
                steps={_.map(steps, 'name')}
                currentStepComplete={stepComplete}
            />
            <WizardStepDisplay
                currentStep={activeStep}
                stepCount={steps.length}
                stepName={step.name}
                stepComplete={stepComplete}
                position="top"
            />
            {transitioning && <LinearProgress />}
            {loadingForm ? (
                <FormSkelleton />
            ) : (
                <Form
                    onSubmit={onSubmit}
                    defaultValues={valueForm || {}}
                    saving={saving}
                    saveResponse={submitError}
                    formId="wizardForm"
                />
            )}
            <WizardButtons
                formId="wizardForm"
                changeStep={handleSingleStep}
                activeStep={activeStep}
                maxSteps={steps.length}
                nextAfterSave={nextAfterSave}
                saving={saving}
                formLoading={loadingForm}
                stepComplete={stepComplete}
                transitioning={transitioning}
            />
            {transitioning && <LinearProgress />}
            <WizardStepDisplay
                currentStep={activeStep}
                stepCount={steps.length}
                stepName={step.name}
                stepComplete={stepComplete}
                position="bottom"
            />
        </>
    );
};

WizardContainer.propTypes = {
    currentStep: PropTypes.number.isRequired,
    proposalId: PropTypes.string.isRequired,
    reloadProposal: PropTypes.func.isRequired,
};

export default WizardContainer;
