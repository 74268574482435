import { handleError } from '../../../util/Error/HandleError';
import { useAppState } from '../../../Stores/AppState.store';
import {
    filterExpiredPots,
    getCurrentRafflePot,
    sortRafflePots,
} from '../../../util/Raffle/RaffleFunctions';
import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { mutateRaffle } from './RaffleMutator';

export function getRafflePots(removeError = true) {
    return new Promise(async (resolve, reject) => {
        try {
            const { getState, setState } = useAppState;
            if (removeError) setState({ rafflePotsError: undefined });

            const { data } = await api().get('/raffle_pots');

            getState().setRafflePots(
                filterExpiredPots(
                    sortRafflePots(
                        data['hydra:member'].map((el) => ({
                            ...el,
                            raffle: el.dayOfRaffle,
                        }))
                    )
                )
            );
            getState().setAllRafflePots(
                data['hydra:member'].map((el) => ({
                    ...el,
                    raffle: el.dayOfRaffle,
                }))
            );
            resolve();
        } catch (e) {
            const error = handleError(e, {
                generic:
                    'Aufgrund der hohen Nachfrage sind die Systeme dementsprechend stark ausgelastet, was zu längeren Ladezeiten führen kann. Wir arbeiten daran, die Ladezeiten zu optimieren. Bitte versuchen Sie es ggf. zu einem späteren Zeitpunkt erneut.',
            });
            const { setState } = useAppState;
            setState({ rafflePotsError: error });
            reject();
        }
    });
}

export function saveRaffle(values, userIri) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().post('/raffles', {
                ...mutateRaffle(values),
                user: userIri,
            });

            const { getState } = useAppState;
            getState().setRaffles([
                {
                    ...data,
                    rafflePot: {
                        ...getCurrentRafflePot(getState().rafflePots),
                    },
                },
                ...getState().raffles,
            ]);
            resolve(
                Response.Success('Bewerbung Antragskontingent eingegangen.')
            );
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Ihre Teilnahme am Losverfahren konnte nicht gespeichert werden.',
                    500: 'Wegen eines Fehlers konnte Ihre Teilname am Losverfahren nicht gespeichert werden.',
                    502: 'Wegen einer Zeitüberschreitung konnte Ihre Teilname am Losverfahren nicht gespeichert werden.',
                    504: 'Wegen einer Zeitüberschreitung konnte Ihre Teilname am Losverfahren nicht gespeichert werden.',
                    412: 'Eine Teilnahme am Losverfahren ist nicht mehr möglich.',
                })
            );
        }
    });
}
