export const step3Texts = {
    title: 'Effekte auf die Unternehmensentwicklung',
    subhead:
        'Schätzen Sie im Folgenden die voraussichtlichen Effekte Ihres Investitionsvorhabens in Hinsicht auf die mittel- / langfristige Entwicklung Ihres Unternehmens ein:',
    desc: '',
    descNoOriginal:
        'Bitte schätzen Sie die voraussichtlichen wirtschaftlichen Effekte, die Sie als Resultat der Investition erwarten, anhand einer Skala ein.',
    errorText:
        'Das Formular enthält Fehler, bitte überprüfen Sie Ihre Eingaben und füllen alle benötigten Felder aus.',
    errorQuestion: 'Bitte beantworten Sie diese Frage:',
    previouslyAnswered: 'Angaben bei Antragstellung:',
    correction: 'Einschätzung nach Durchführung des Investitionsvorhabens:',
    noUnknownAnswer:
        'keine Angabe („k.A.“) ist beim Verwendungsnachweis nicht möglich. Zur Programmevaluierung benötigen wir eine aussagekräftige Stellungnahme seitens des antragstellenden Unternehmens. Wir bitten Sie daher, die Entwicklungen knapp einzuschätzen.',
    questions: [
        {
            name: '0',
            text: 'Bezogen auf die erwartete Umsatzentwicklung in den nächsten zwei Jahren (nach Umsetzung der Investition):',
            answers: [
                {
                    label: 'Keine Zunahme',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
            ],
        },
        {
            name: '1',
            text: 'Bezogen auf das erwartete Beschäftigungswachstum in den nächsten zwei Jahren (nach Umsetzung der Investition):',
            answers: [
                {
                    label: 'Keine Zunahme',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
            ],
        },
        {
            name: '2',
            text: 'Bezogen auf die erwarteten Kosteneinsparungen in den nächsten zwei Jahren (nach Umsetzung der Investition) ein:',
            answers: [
                {
                    label: 'Keine Einsparungen',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
            ],
        },
        {
            name: '3',
            text: 'Bezogen auf die erwarteten Produktivitätssteigerungen in den nächsten zwei Jahren (nach Umsetzung der Investition):',
            answers: [
                {
                    label: 'Keine Steigerung',
                    value: '1',
                },
                {
                    label: 'Gering (≤ 10%)',
                    value: '2',
                },
                {
                    label: 'Mittel (≤ 20%)',
                    value: '3',
                },
                {
                    label: 'Hoch (20-50%)',
                    value: '4',
                },
                {
                    label: 'Sehr hoch (> 50%)',
                    value: '5',
                },
            ],
        },
    ],
};
