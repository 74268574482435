import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Custom = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        boxShadow: theme.shadows[5],
    },
}))(Tooltip);

const CustomHelperIcon = withStyles((theme) => ({
    root: {
        cursor: 'help',
        verticalAlign: 'text-bottom',
    },
}))(InfoOutlinedIcon);

const CustomTooltip = (props) => {
    const { children } = props;

    return (
        <Custom
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={<Typography>{children}</Typography>}
        >
            <CustomHelperIcon fontSize="small" />
        </Custom>
    );
};

CustomTooltip.propTypes = {
    children: PropTypes.any.isRequired,
};

export default CustomTooltip;
