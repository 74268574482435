import React from 'react';
import Typography from '@material-ui/core/Typography';
import { texts as trans } from '../../texts/Components/RegisterTexts';

const RegisterDone = () => {
    return (
        <React.Fragment>
            <Typography variant="h3">{trans.register.done.headline}</Typography>
            <Typography>{trans.register.done.content}</Typography>
        </React.Fragment>
    );
};

export default RegisterDone;
