import Typography from '@material-ui/core/Typography';
import React from 'react';

export const ContentTypography = (headline, content) => {
    return (
        <>
            <Typography variant="h6" component="h3">
                {headline}
            </Typography>
            <Typography
                style={{ marginLeft: '10px', whiteSpace: 'break-spaces' }}
            >
                {content}
            </Typography>
        </>
    );
};
