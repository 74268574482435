import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableCell,
} from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import FormBasicDatePicker from '../../Components/FormBasicDatePicker';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import Dinero from 'dinero.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { step4Texts } from '../../../../texts/Verification/Step4Texts';
import FormMoney from '../../Components/FormMoney';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    green: {
        color: green[500],
    },
    red: {
        color: red[500],
    },
}));

const today = moment().format('yyyy-MM-DD');

const BillInformationModal = (props) => {
    const { open, onClose, onSave, data, max, index, displayOnly } = props;

    const classes = useStyles();

    const { control, errors, handleSubmit, watch, reset } = useForm({
        defaultValues: { billing: data },
    });
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: 'billing',
    });

    const handleSubmitCallback = (values) => {
        onSave(values.billing || [], index);
        onClose();
    };

    const billing = watch('billing');
    const billingSum = React.useMemo(() => {
        if (fields.length === 0) return 0;
        return billing.reduce((acc, cur) => {
            return (
                acc +
                (parseInt(cur[step4Texts.billingModal.form.amount.name]) || 0)
            );
        }, 0);
    }, [billing, fields]);

    const handleRemove = (index) => {
        const newBilling = billing.splice(index, 1);
        reset({ billing: newBilling });
        remove(index);
    };

    const diff = max - billingSum;
    const err = errors.billing || [];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit(handleSubmitCallback)} name="payments">
                <DialogTitle>{step4Texts.billingModal.title}</DialogTitle>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 1 }}>
                                    <IconButton
                                        disabled={displayOnly}
                                        onClick={() =>
                                            append({ date: '', amount: '' })
                                        }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell colSpan={2}>
                                    {fields.length === 0 ? (
                                        <Typography>
                                            {step4Texts.billingModal.noEntries}
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {step4Texts.billingModal.entries}
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((field, id) => (
                                <TableRow key={field.id}>
                                    <TableCell>
                                        <IconButton
                                            disabled={displayOnly}
                                            onClick={() => handleRemove(id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <FormBasicDatePicker
                                            control={control}
                                            name={`billing[${id}].${step4Texts.billingModal.form.date.name}`}
                                            notEditable={displayOnly}
                                            label={
                                                step4Texts.billingModal.form
                                                    .date.label
                                            }
                                            rules={
                                                step4Texts.billingModal.form
                                                    .date.rules
                                            }
                                            error={
                                                err[id]?.[
                                                    step4Texts.billingModal.form
                                                        .date.name
                                                ] || undefined
                                            }
                                            defaultValue={
                                                field[
                                                    step4Texts.billingModal.form
                                                        .date.name
                                                ]
                                            }
                                            max={today}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormMoney
                                            error={
                                                err[id]?.[
                                                    step4Texts.billingModal.form
                                                        .amount.name
                                                ]
                                            }
                                            notEditable={displayOnly}
                                            label={
                                                step4Texts.billingModal.form
                                                    .amount.label
                                            }
                                            name={`billing[${id}].${step4Texts.billingModal.form.amount.name}`}
                                            rules={
                                                step4Texts.billingModal.form
                                                    .amount.rules
                                            }
                                            control={control}
                                            defaultValue={
                                                field[
                                                    step4Texts.billingModal.form
                                                        .amount.name
                                                ]
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    style={{ textAlign: 'right' }}
                                >
                                    <input
                                        type="hidden"
                                        value={diff}
                                        name={
                                            step4Texts.billingModal.form.hidden
                                                .name
                                        }
                                        ref={control.register(
                                            step4Texts.billingModal.form.hidden
                                                .rules
                                        )}
                                    />
                                    <Typography
                                        variant="h6"
                                        className={classNames(
                                            {
                                                [classes.green]: diff === 0,
                                            },
                                            {
                                                [classes.red]: diff < 0,
                                            }
                                        )}
                                    >
                                        {`${new Dinero({
                                            amount: billingSum,
                                        }).toFormat()} / ${new Dinero({
                                            amount: max,
                                        }).toFormat()}`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {diff < 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                        style={{ textAlign: 'right' }}
                                    >
                                        <Typography className={classes.red}>
                                            {step4Texts.billingModal.error(
                                                new Dinero({
                                                    amount: Math.abs(diff),
                                                }).toFormat()
                                            )}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableFooter>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                    >
                        {step4Texts.billingModal.closeButton}
                    </Button>
                    {!displayOnly && (
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            color="primary"
                            value="Billing"
                        >
                            {step4Texts.billingModal.saveButton}
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

BillInformationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    data: PropTypes.array,
    max: PropTypes.number,
    index: PropTypes.number,
    displayOnly: PropTypes.bool,
};

BillInformationModal.defaultProps = {
    data: [],
};

export default BillInformationModal;
