import React from 'react';
import uniqid from 'uniqid';
import {
    step1Complete,
    step2Complete,
    step3Complete,
} from '../../../util/Verification/StepComplete';
import Step1 from '../../Forms/Verification/Step1';
import Step2 from '../../Forms/Verification/Step2';
import Step3 from '../../Forms/Verification/Step3';
import Step4 from '../../Forms/Verification/Step4';
import Step5 from '../../Forms/Verification/Step5';
import Step6 from '../../Forms/Verification/Step6';
import Step7 from '../../Forms/Verification/Step7';

export const stepStrings = {
    0: 'editable',
    1: 'editable',
    2: 'editable',
    3: 'editable',
    4: 'affirmable',
    5: 'in_review',
    6: 'submittable',
    7: 'submitted',
};

export const stepNumbers = {
    editable: 0,
    affirmable: 4,
    in_review: 5,
    submittable: 6,
    submitted: 7,
    edit: 0,
    affirm: 4,
    review: 5,
    submit: 6,
};

export const verificationConfig = [
    {
        component: (props) => <Step1 {...props} />,
        label: 'Dateneingabe',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'editable',
        isComplete: step1Complete,
    },
    {
        component: (props) => <Step2 {...props} />,
        label: 'Digitalisierungsplan',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'editable',
        isComplete: step2Complete,
    },
    {
        component: (props) => <Step3 {...props} />,
        label: 'Unternehmensentwicklung',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'editable',
        isComplete: step3Complete,
    },
    {
        component: (props) => <Step4 {...props} />,
        label: 'Finanzierungsplan',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'editable',
        isComplete: (data) => false,
    },
    {
        component: (props) => <Step5 {...props} />,
        label: 'Finanzplanübersicht',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'affirmable',
        isComplete: (data) => false,
    },
    {
        component: (props) => <Step6 {...props} />,
        label: 'Übersicht',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'in_review',
        isComplete: (data) => false,
    },
    {
        component: (props) => <Step7 {...props} />,
        label: 'Abschluss',
        key: uniqid(),
        isDisabled: (currentStep) => currentStep !== 'submittable',
        isComplete: (data) => false,
    },
];
