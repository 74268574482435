import moment from 'moment';
import _ from 'lodash';
import { v4 as uuid4 } from 'uuid';
import { step2Texts } from '../../../texts/Verification/Step2Texts';

export function mutateStaticData(data) {
    const { proposal, approvedData, step6, step7, id } = data;

    return {
        staticData: {
            validWsk: approvedData.validWsk,
            securityAudit: approvedData.security,
            fkz: proposal.proposalId,
            user: {
                name: approvedData.authorizedPersonName,
                email: approvedData.authorizedPersonEmail,
                street: `${approvedData.address.street} ${approvedData.address.houseNumber}`,
                postcode: approvedData.address.postcode,
                city: approvedData.address.city,
                state: approvedData.address.state,
            },
            start: moment(approvedData.startDate).format('DD.MM.yyyy'),
            end: moment(approvedData.endDate).format('DD.MM.yyyy'),
            digi2: { ...step6 },
            digi3: { ...step7 },
            id,
            proposal,
        },
    };
}

export function step2Transformer(data) {
    return {
        targets: {
            security: data.security,
            digitalWork: data.digitalWork,
            digitizeModel: data.digitizeModel,
            employeeCompetence: data.employeeCompetence,
            digitalNetworking: data.digitalNetworking,
        },
        otherTargetsPursued: data.otherTargetsPursued,
        targetFields: {
            common: {
                valid: data.common,
                comment: data.commonComment || '',
            },
            application: {
                valid: data.application,
                comment: data.applicationComment || '',
            },
            improvements: {
                valid: data.improvements,
                comment: data.improvementsComment || '',
            },

            // If security values are undefined (security target not selected), set values to null instead of not
            // sending them to overwrite stored patch data
            securityTechnologies: {
                valid:
                    typeof data.securityTechnologies !== 'undefined'
                        ? data.securityTechnologies
                        : null,
                comment: data.securityTechnologiesComment || '',
            },
            securityTarget: {
                valid:
                    typeof data.securityTarget !== 'undefined'
                        ? data.securityTarget
                        : null,
                comment: data.securityTargetComment || '',
            },
            valueChainRole: {
                valid: data.valueChainRole,
                comment: data.valueChainRoleComment || '',
            },
            valueChainImprove: {
                valid: data.valueChainImprove,
                comment: data.valueChainImproveComment || '',
            },
        },
    };
}

export function generateProofs(verificationProofs, proofs) {
    const newProofs = [];

    proofs.forEach((proof) => {
        const index = verificationProofs.findIndex(
            (verificationProof) =>
                verificationProof.proof === `/api/proofs/${proof.id}`
        );
        if (index >= 0) {
            newProofs.push(verificationProofs[index]);
            newProofs[newProofs.length - 1].proofs = _.partition(
                proof.proofItems,
                { type: 'device' }
            );
            newProofs[newProofs.length - 1].provider = proof.provider;
            newProofs[newProofs.length - 1].description = proof.description;
            newProofs[newProofs.length - 1].id = proof.id;
            newProofs[newProofs.length - 1].totalSum = proof.totalSum;
            return;
        }

        const id = uuid4();
        newProofs.push({
            id,
            proof: proof.id,
            invoiceTotal: '',
            explanation: {},
            payments: [],
            proofs: _.partition(proof.proofItems, { type: 'device' }),
            provider: proof.provider,
            description: proof.description,
            totalSum: proof.totalSum,
        });
    });
    return newProofs;
}

export function transformTransition(value) {
    switch (value) {
        case 'editable':
            return 'edit';
        case 'affirmable':
            return 'affirm';
        case 'in_review':
            return 'review';
        case 'submittable':
            return 'submit';
        default:
            return value;
    }
}

export function step2FetchMutator(data) {
    return {
        [step2Texts.outline1.form.targets.digitizeModel.name]:
            data.targets?.digitizeModel,
        [step2Texts.outline1.form.targets.digitalWork.name]:
            data.targets?.digitalWork,
        [step2Texts.outline1.form.targets.employeeCompetence.name]:
            data.targets?.employeeCompetence,
        [step2Texts.outline1.form.targets.security.name]:
            data.targets?.security,
        [step2Texts.outline1.form.targets.digitalNetworking.name]:
            data.targets?.digitalNetworking,
        [step2Texts.outline1.form.targets.otherTargetPursued.name]:
            data.targets?.otherTargetPursued,
        [step2Texts.outline1.form.targets.other.name]: Boolean(
            data.otherTargetsPursued
        ),

        [step2Texts.outline1.form.otherTargetPursued.name]:
            data.otherTargetsPursued,

        [step2Texts.outline2.form.application.name]:
            data.targetFields?.application?.valid,
        [step2Texts.outline2.form.applicationComment.name]:
            data.targetFields?.application?.comment,

        [step2Texts.outline2.form.common.name]:
            data.targetFields?.common?.valid,
        [step2Texts.outline2.form.commonComment.name]:
            data.targetFields?.common?.comment,

        [step2Texts.outline2.form.improvements.name]:
            data.targetFields?.improvements?.valid,
        [step2Texts.outline2.form.improvementsComment.name]:
            data.targetFields?.improvements?.comment,

        [step2Texts.outline3.form.securityTechnologies.name]:
            data.targetFields?.securityTechnologies?.valid,
        [step2Texts.outline3.form.securityTechnologiesComment.name]:
            data.targetFields?.securityTechnologies?.comment,

        [step2Texts.outline3.form.securityTarget.name]:
            data.targetFields?.securityTarget?.valid,
        [step2Texts.outline3.form.securityTargetComment.name]:
            data.targetFields?.securityTarget?.comment,

        [step2Texts.outline4.form.valueChainRole.name]:
            data.targetFields?.valueChainRole?.valid,
        [step2Texts.outline4.form.valueChainRoleComment.name]:
            data.targetFields?.valueChainRole?.comment,

        [step2Texts.outline4.form.valueChainImprove.name]:
            data.targetFields?.valueChainImprove?.valid,
        [step2Texts.outline4.form.valueChainImproveComment.name]:
            data.targetFields?.valueChainImprove?.comment,
    };
}

export function step2OriginalDataFetchMutator(data) {
    const defaultValues = {
        targets: {
            digitizeModel: false,
            digitalWork: false,
            employeeCompetence: false,
            security: false,
            digitalNetworking: false,
            digitalTechnologies: false,
        },
    };

    return _.merge(defaultValues, { ...data });
}
