export function mutateFetch(data) {
    data.identical = 'true';
    if (data.addresses) {
        data.addresses.forEach((address) => {
            if (address.isMain !== null) {
                address.isMain = address.isMain === true ? 'true' : 'false';
            }
            if (address.isWeak !== null) {
                address.isWeak = address.isWeak === true ? 'true' : 'false';
            }
            // other executive addresses exist => identical = false
            if (address.isMain === 'false') {
                data.identical = 'false';
            }

            if (address.employees !== null)
                address.employees = `${address.employees}`;
        });
    } else {
        delete data.identical;
    }

    data.companyName = data.companyName || '';

    data.companyUrl = data.companyUrl || '';

    if (data.inGermany !== null && data.inGermany !== undefined) {
        data.inGermany = data.inGermany === true ? 'true' : 'false';
    } else {
        data.inGermany = 'true';
    }

    if (data.companyName === '') data.companyName = data.company?.name || '';

    return data;
}

export function saveMutator(data) {
    data.inGermany = data.inGermany === 'true';

    data.addresses.forEach((address, idx) => {
        address.isMain = address.isMain === 'true';
        address.isWeak = address.isWeak === 'true';
        address.employees = 1; //parseInt(address.employees);

        if (
            idx > 1 ||
            (address.isMain === true &&
                data.identical === 'false' &&
                data.identical !== undefined)
        ) {
            address.isWeak = null;
        }

        if (typeof address.state === 'object') {
            address.state = address.state.name;
        }
    });

    data.hasMoreThan50 = false;

    return data;
}
