function formatAmount(amount) {
    if (amount === undefined) return '';
    return (
        (amount.length < 3 ? '0' : '') +
        amount.slice(0, amount.length - 2) +
        ',' +
        amount.slice(amount.length - 2)
    );
}

export function fetchMutator(data) {
    const currentYear = new Date().getFullYear();

    const companies = data.shareholderCompanies.map((element) => ({
        value: element.companyName,
        label: element.companyName,
        key: element.id,
        '@id': element['@id'],
    }));

    const mainMinimis = data.minimis;
    const childMinimis = data.shareholderCompanies;

    const oneMainA = [];
    const oneMainB = [];
    const twoMainA = [];
    const twoMainB = [];
    const threeMainA = [];
    const threeMainB = [];

    mainMinimis.forEach((minimi) => {
        switch (parseInt(minimi.year)) {
            case currentYear:
                minimi.received
                    ? oneMainA.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      })
                    : oneMainB.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      });
                break;
            case currentYear - 1:
                minimi.received
                    ? twoMainA.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      })
                    : twoMainB.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      });
                break;
            case currentYear - 2:
                minimi.received
                    ? threeMainA.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      })
                    : threeMainB.push({
                          ...minimi,
                          fundingAmount: formatAmount(minimi.fundingAmount),
                          investmentAmount: formatAmount(
                              minimi.investmentAmount
                          ),
                          key: minimi.id,
                      });
                break;
            default:
                break;
        }
    });

    const oneChildA = {};
    const oneChildB = {};
    const twoChildA = {};
    const twoChildB = {};
    const threeChildA = {};
    const threeChildB = {};

    childMinimis.forEach((company) => {
        company.minimis.forEach((minimi) => {
            switch (parseInt(minimi.year)) {
                case currentYear:
                    if (minimi.received) {
                        if (oneChildA[company.companyName] === undefined)
                            oneChildA[company.companyName] = [];
                    } else {
                        if (oneChildB[company.companyName] === undefined)
                            oneChildB[company.companyName] = [];
                    }

                    minimi.received
                        ? (oneChildA[company.companyName] = [
                              ...oneChildA[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ])
                        : (oneChildB[company.companyName] = [
                              ...oneChildB[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ]);
                    break;
                case currentYear - 1:
                    if (minimi.received) {
                        if (twoChildA[company.companyName] === undefined)
                            twoChildA[company.companyName] = [];
                    } else {
                        if (twoChildB[company.companyName] === undefined)
                            twoChildB[company.companyName] = [];
                    }
                    minimi.received
                        ? (twoChildA[company.companyName] = [
                              ...twoChildA[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ])
                        : (twoChildB[company.companyName] = [
                              ...twoChildB[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ]);
                    break;
                case currentYear - 2:
                    if (minimi.received) {
                        if (threeChildA[company.companyName] === undefined)
                            threeChildA[company.companyName] = [];
                    } else {
                        if (threeChildB[company.companyName] === undefined)
                            threeChildB[company.companyName] = [];
                    }
                    minimi.received
                        ? (threeChildA[company.companyName] = [
                              ...threeChildA[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ])
                        : (threeChildB[company.companyName] = [
                              ...threeChildB[company.companyName],
                              {
                                  ...minimi,
                                  fundingAmount: formatAmount(
                                      minimi.fundingAmount
                                  ),
                                  investmentAmount: formatAmount(
                                      minimi.investmentAmount
                                  ),
                                  key: minimi.id,
                              },
                          ]);
                    break;
                default:
                    break;
            }
        });
    });

    const minimiFields = {
        received: data.minimiFields.received ? 'true' : 'false',
        requested: data.minimiFields.requested ? 'true' : 'false',
        goDigitalFunded: data.goDigitalFunded ? 'true' : 'false',
        ackSubFraud: data.minimiFields.ackSubFraud,
        ackDisclosure: data.minimiFields.ackDisclosure,
        ackRegulation: data.minimiFields.ackRegulation,
        ackDeadline: data.minimiFields.ackDeadline,
        ackMinimiProof: data.minimiFields.ackMinimiProof,
        isRoadTransport: data.isRoadTransport === true ? 'true' : 'false',
        linkedCompanies: data.minimiFields.linkedCompanies ? 'true' : 'false',
    };

    return {
        '@id': data['@id'],
        companies,
        oneMainA,
        oneMainB,
        oneChildA,
        oneChildB,
        twoMainA,
        twoMainB,
        twoChildA,
        twoChildB,
        threeMainA,
        threeMainB,
        threeChildA,
        threeChildB,
        ...minimiFields,
    };
}
