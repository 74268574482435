import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const ListFieldMax = React.forwardRef((props, ref) => {
    const { max, defaultValue, changeCallback, ...rest } = props;

    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (evt) => {
        const val = evt.target.value;

        if (changeCallback) changeCallback(evt);

        if (val.length > max) {
            setValue(val.slice(0, max));
            return;
        }
        setValue(val);
    };

    return (
        <TextField
            inputRef={ref}
            helperText={`${value.length}/${max}`}
            onChange={handleChange}
            defaultValue={defaultValue}
            {...rest}
        />
    );
});

ListFieldMax.propTypes = {
    max: PropTypes.number,
    defaultValue: PropTypes.string,
    changeCallback: PropTypes.func,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
    error: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium']),
    type: PropTypes.string,
};

ListFieldMax.defaultProps = {
    max: 100,
    defaultValue: '',
};

export default ListFieldMax;
