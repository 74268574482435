import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import ValueOrLoader from './ValueOrLoader';
import Dinero from 'dinero.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { finPlanTexts } from '../../../texts/Verification/FinPlanTexts';

const useStyles = makeStyles((theme) => ({
    tableSubHeader: {
        background: theme.palette.grey['A100'],
        fontWeight: 'bold',
    },
}));

const FinalPlanData = (props) => {
    const { data } = props;

    const classes = useStyles();

    const transformEuro = (value) => {
        if (value)
            return new Dinero({ amount: parseInt(value) || 0 }).toFormat();
        return undefined;
    };

    const transformPercent = (value) => {
        if (value) return `${value} %`;
        return undefined;
    };

    return (
        <>
            <TableBody>
                <TableRow className={classes.tableSubHeader}>
                    <TableCell colSpan={2}>
                        <Typography>{finPlanTexts.sumApproved}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.approvedInvest}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumInvestApproved)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.approvedSum}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumFundingApproved)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.fundingRatio}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformPercent(data.fundingRatio)}
                        />
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell colSpan={2} />
                </TableRow>

                <TableRow className={classes.tableSubHeader}>
                    <TableCell colSpan={2}>
                        <Typography>
                            {finPlanTexts.sumInVerification}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.invoice}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumInvestOverall)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.paidSum}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumInvestEffective)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.additionalMoney}</Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.additionalFunding)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>
                            {finPlanTexts.realizedInvestSum}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumInvestRealized)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>
                            {finPlanTexts.calculatedFunding}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumCalculatedFunding)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2} />
                </TableRow>

                <TableRow className={classes.tableSubHeader}>
                    <TableCell>
                        <Typography style={{ fontWeight: 'bold' }}>
                            {finPlanTexts.sumFunding}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <ValueOrLoader
                            value={transformEuro(data.sumFunding)}
                            bold="bold"
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography>{finPlanTexts.hint}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>
    );
};

FinalPlanData.propTypes = {
    data: PropTypes.object.isRequired,
};

FinalPlanData.defaultProps = {
    data: {},
};

export default FinalPlanData;
