import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const ProofCheckbox = (props) => {
    const {
        label,
        name,
        className,
        error,
        defaultChecked,
        register,
        validation,
    } = props;

    return (
        <FormControl error={!!error.message}>
            <FormControlLabel
                className={className}
                label={label}
                name={name}
                control={
                    <Checkbox
                        color={'primary'}
                        defaultChecked={defaultChecked}
                        inputRef={register(validation)}
                    />
                }
            />
            {!!error.message && (
                <FormHelperText>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

ProofCheckbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.object,
    defaultChecked: PropTypes.bool,
    register: PropTypes.func,
    validation: PropTypes.object,
};

ProofCheckbox.defaultProps = {
    error: {},
    defaultChecked: false,
    validation: {},
};

export default ProofCheckbox;
