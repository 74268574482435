import React from 'react';
import PropTypes from 'prop-types';
import FinancingPlanTreeView from './FinancingPlanTreeView';
import { Typography } from '@material-ui/core';

const FinancingPlanOverview = (props) => {
    const { data } = props;

    const priceLabel = data.deductInputTax ? 'netPrice' : 'price';

    return (
        <>
            <Typography>Folgende Nachweise haben Sie angegeben:</Typography>
            <FinancingPlanTreeView
                deductInputTax={data.deductInputTax}
                proofs={data.proofs}
                priceLabel={priceLabel}
                total={data.total}
            />
        </>
    );
};

FinancingPlanOverview.propTypes = {
    data: PropTypes.object,
};

export default FinancingPlanOverview;
