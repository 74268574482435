import React from 'react';
import PropTypes from 'prop-types';
import { texts as oft } from '../../../../texts/Forms/OverviewFormTexts';
import { ContentTypography } from './OverviewComponent';

export const ValueChainOverview = (props) => {
    const data = props.data;
    const companies = data.linkedCompanies.map((company, key) => {
        return (
            <span key={company.id} style={{ display: 'block' }}>
                {key + 1 + '. ' + company.name + ' - ' + company.location}
            </span>
        );
    });
    return (
        <>
            {!data.isValueChain &&
                ContentTypography(oft.valueChain.content.isNotValueChain, null)}
            {data.isValueChain &&
                ContentTypography(oft.valueChain.content.isValueChain, null)}
            {data.isValueChain &&
                ContentTypography(
                    oft.valueChain.content.isLead,
                    data.isLead ? 'Ja' : 'Nein'
                )}
            {data.isValueChain &&
                ContentTypography(
                    oft.valueChain.content.code,
                    data.isLead ? data.givenCode : data.leadCode
                )}
            {data.isValueChain &&
                ContentTypography(oft.valueChain.content.company, companies)}
        </>
    );
};

ValueChainOverview.propTypes = {
    data: PropTypes.object,
};

export default ValueChainOverview;
