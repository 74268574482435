import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import ListIcon from '@material-ui/icons/List';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        display: 'flex',
        flexShrink: '0',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
        fontSize: '1em',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    active: {
        color: theme.palette.primary.main,
    },
    activeBold: {
        '& span': {
            fontWeight: 'bold',
        },
    },
    header: {
        '& span': {
            fonzSize: theme.typography.pxToRem(15),
            fontWeight: 'bold',
        },
    },
}));

const WizardSteps = (props) => {
    const { steps, activeStep, handleStep, currentStepComplete } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const stepChange = (step) => () => handleStep(step);

    if (activeStep === steps.length - 1) return null;

    return (
        <>
            <Fab
                color="primary"
                aria-label="Wizard öffnen"
                onClick={() => setOpen(true)}
                className={classes.fab}
                variant="extended"
                hidden={open}
            >
                <ListIcon className={classes.extendedIcon} />
                Antragsschritte
            </Fab>
            <Drawer
                classes={{ paper: classes.drawerPaper }}
                open={open}
                anchor="left"
                onClose={() => setOpen(false)}
            >
                <List>
                    <ListItem>
                        <ListItemText
                            inset
                            primary="Antragsschritte"
                            className={classes.header}
                        />
                    </ListItem>
                    {steps.map((text, index) => (
                        <ListItem
                            button
                            key={text}
                            disabled={activeStep < index}
                            onClick={stepChange(index)}
                            className={
                                activeStep === index
                                    ? classes.active
                                    : undefined
                            }
                        >
                            <ListItemIcon>
                                {activeStep > index ||
                                (activeStep === index &&
                                    currentStepComplete) ? (
                                    <CheckCircleOutlineIcon
                                        className={
                                            activeStep === index
                                                ? classes.active
                                                : null
                                        }
                                    />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        className={
                                            activeStep === index
                                                ? classes.active
                                                : null
                                        }
                                    />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                                className={
                                    activeStep === index
                                        ? classes.activeBold
                                        : undefined
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

WizardSteps.propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number,
    handleStep: PropTypes.func.isRequired,
    currentStepComplete: PropTypes.bool,
};

export default WizardSteps;
