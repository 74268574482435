import {
    isDefined,
    isDefinedAndNotEmptyString,
} from '../Validation/ValidateDefined';

export function step1Complete(data = {}) {
    if (data.hasNewAddress === false) return true;
    const d = data.newAddress;
    if (d === undefined) return false;
    return (
        d.firstName?.length > 0 &&
        d.lastName?.length > 0 &&
        d.street?.length > 0 &&
        d.postcode?.length > 0 &&
        d.city?.length > 0 &&
        d.state?.length > 0
    );
}

export function step3Complete(data = {}) {
    const d = data.digiPlan?.fields;
    if (!isDefined(d) || !Array.isArray(d)) return false;

    const hasEntries = d.filter(function (value) {
        return !isDefinedAndNotEmptyString(value);
    });

    return d.length === 4 && hasEntries.length === 0;
}

export function step2Complete(data = {}) {
    const d = data.digitisation?.targets;
    if (!isDefined(d)) return false;

    return (
        isDefined(d.security) &&
        isDefined(d.digitalWork) &&
        isDefined(d.digitizeModel) &&
        isDefined(d.employeeCompetence) &&
        isDefined(d.digitalNetworking)
    );
}
