import React from 'react';
import { Typography } from '@material-ui/core';
import { privateRouteTexts } from '../../../texts/PrivateRouteTexts';
import LoginContainer from '../../../container/Auth/LoginContainer';
import PrivateRouteButton from './PrivateRouteButton';
import LoginButtonModal from '../../Layout/Navbar/LoginButton/LoginButtonModal';
import { useAuth } from '../../../Stores/Auth.store';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const usePrivateRouteLoginStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },
}));

const PrivateRouteLogin = (props) => {
    const error = useAuth((state) => state.error);
    const classes = usePrivateRouteLoginStyles();
    return (
        <>
            <Typography variant="h4" component="h2" gutterBottom>
                {privateRouteTexts.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {privateRouteTexts.body}
            </Typography>
            {error && (
                <Typography className={classes.error} gutterBottom>
                    {error}
                </Typography>
            )}
            <LoginContainer
                render={PrivateRouteButton}
                renderModal={LoginButtonModal}
            />
        </>
    );
};

export default PrivateRouteLogin;
