import React from 'react';
import TextBlock from '../../components/TextBlock/TextBlock';
import { texts as fft } from '../../texts/Forms/FinalizeFormTexts';
import LinkSameSite from '../../components/TextBlock/components/LinkSameSite';
import { finalizeInfo } from '../../lib/api/Proposal/ProposalActions';
import { useParams } from 'react-router-dom';
import FormSkelleton from '../../components/Forms/FormSkelleton';

const renderError = (status) => {
    if (status === 401)
        return <TextBlock textBlock={fft.error401} headlineVariant="h4" />;
    return <TextBlock textBlock={fft.error404} headlineVariant="h4" />;
};

const ProposalSubmittedContainer = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    const { proposalId } = useParams();

    React.useEffect(() => {
        finalizeInfo(proposalId)
            .then()
            .catch((e) => {
                setError(e.response.status);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []); //eslint-disable-line

    return (
        <>
            {isLoading ? (
                <FormSkelleton />
            ) : (
                <>
                    {!error ? (
                        <>
                            <TextBlock
                                textBlock={fft.finished}
                                headlineVariant="h4"
                            />
                            <TextBlock
                                textBlock={fft.finished2}
                                headlineVariant="h4"
                                parserOptions={{
                                    components: {
                                        a: { component: LinkSameSite },
                                    },
                                }}
                            />
                        </>
                    ) : (
                        <>{renderError(error)}</>
                    )}
                </>
            )}
        </>
    );
};

export default ProposalSubmittedContainer;
