import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useMobileLayout } from '../../../hooks/useMobileLayout';
import List from '@material-ui/core/List';
import LoginContainer from '../../../container/Auth/LoginContainer';
import LoginButtonModal from './LoginButton/LoginButtonModal';
import { pages } from '../../../config/Navigation';
import MobileListItem from './Mobile/MobileListItem';

const MobileNavigation = (props) => {
    const { isAuthenticated } = props;

    const show = useMobileLayout();
    const [open, setOpen] = useState();

    if (!show) return null;

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <MenuIcon style={{ color: 'white' }} />
            </IconButton>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <List style={{ width: 200 }}>
                    {isAuthenticated ? (
                        <>
                            {pages.map((page, idx) => (
                                <MobileListItem
                                    label={page.label}
                                    href={page.href}
                                    match={page.match}
                                    key={`mobile_nav_${idx}`}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            <MobileListItem label={'Startseite'} href={'/'} />
                            <LoginContainer
                                render={({ onClick }) => (
                                    <MobileListItem
                                        label={'Login'}
                                        onClick={onClick}
                                    />
                                )}
                                renderModal={LoginButtonModal}
                            />
                            <MobileListItem
                                label={'Registrieren'}
                                href={'/registrieren'}
                            />
                        </>
                    )}
                </List>
            </Drawer>
        </>
    );
};

MobileNavigation.propTypes = {
    isAuthenticated: PropTypes.bool,
};

export default MobileNavigation;
