import React from 'react';
import PropTypes from 'prop-types';
import { Response } from '../../util/DataTypes/Response';
import Typography from '@material-ui/core/Typography';
import ButtonWithLoader from '../Partials/Button/ButtonWithLoader';
import RefreshIcon from '@material-ui/icons/Refresh';

const VerificationError = (props) => {
    const { error, refetch, isLoading, icon } = props;

    return (
        <div>
            <Typography>{error.message()}</Typography>;
            {refetch && (
                <ButtonWithLoader
                    func={refetch}
                    isLoading={isLoading}
                    icon={icon}
                />
            )}
        </div>
    );
};

VerificationError.propTypes = {
    error: PropTypes.instanceOf(Response),
    refetch: PropTypes.func,
    isLoading: PropTypes.bool,
    icon: PropTypes.node,
};

VerificationError.defaultProps = {
    icon: <RefreshIcon />,
};

export default VerificationError;
