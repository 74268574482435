export const step4Texts = {
    tooltips: {
        upload: 'Nachweise hochladen',
        payments: 'Zahlungen angeben',
        desc: 'Erläuterungen angeben',
    },
    formError:
        'Das Formular enthält Fehler. Bitte überprüfen Sie Ihre Angaben.',
    billingModal: {
        topHintTeaser: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Über das „+“-Symbol geben Sie für diese Rechnung das Datum der Zahlung und den gezahlten Betrag ein.',
                },
            ],
        },
        topHint: {
            paragraphs: [
                {
                    type: 'p',
                    text: 'Beachten Sie dabei Folgendes:',
                },
                {
                    type: 'list',
                    text: '[li]Basierend auf dem Prinzip der Wirtschaftlichkeit und Sparsamkeit muss ein Skonto, Rabatt oder Nachlass stets berücksichtigt werden, auch wenn diese Möglichkeit nicht wahrgenommen wurde.[li][li]Wenn die Rechnung in Teilzahlungen beglichen wurde, geben Sie bitte sämtliche Teilzahlungen mit dem jeweiligen Betrag und Datum ein. Sie können hier auch mehrere Teilzahlungen erfassen.[li][li]Originalbelege (Auszüge aus Ihrer Buchhaltung) sind auf Verlangen vorzulegen.[li][li]Die von Ihnen gezahlte Summe bildet die Bemessungsgrundlage für die Berechnung der abschließenden Fördersumme.[li][li]Bei Zuwendungsempfängern ohne Vorsteuerabzugsberechtigung sind Bruttoausgaben anzugeben. Zuwendungsempfänger mit Vorsteuerabzugsberechtigung geben hier die Nettoausgaben an.[li]',
                },
            ],
        },
        title: 'Zahlbetragsfeld',
        noEntries: 'Keine Einträge vorhanden',
        entries: 'Weitere Zahlung hinzufügen',
        error: (amount) =>
            `Der von Ihnen angegebene Rechnungsbetrag kann nicht überschritten werden. Sie überschreiten den zulässigen Betrag um ${amount}`,
        saveButton: 'Speichern',
        closeButton: 'Abbrechen',
        form: {
            date: {
                label: 'Zahlungsdatum',
                name: 'payedAt',
                rules: { required: 'Bitte Datum angeben.' },
            },
            amount: {
                label: 'Betrag',
                name: 'sum',
                rules: { required: 'Bitte Wert angeben.' },
            },
            comment: {
                label: 'Stellungnahme',
                name: 'payedAtComment',
                rules: { required: 'Bitte geben Sie eine Stellungnahme an.' },
            },
            description: {
                label: 'Stellungnahme',
                name: 'description',
                rules: { required: 'Bitte Wert angeben.' },
            },
            hidden: {
                name: 'val',
                rules: {
                    validate: (value) => parseInt(value) >= 0 || '',
                },
            },
        },
    },
    descriptionModal: {
        title: 'Erläuterungen',
        buttonCancel: 'Abbrechen',
        buttonSave: 'Speichern',
        form: {
            changes: {
                name: 'notChanged',
                label: 'Keine Änderungen vom ursprünglichen Plan',
            },
            providerChanged: {
                previous: 'bisheriger Anbieter: ',
                name: 'providerChanged',
                label: 'Anbieterwechsel',
            },
            providerName: {
                name: 'provider',
                label: 'Anbietername',
                rules: {
                    required: 'Bitte geben Sie einen Anbieter an.',
                },
            },
            providerDesc: {
                name: 'providerReason',
                label: 'Begründung für die den Anbieterwechsel',
                rules: {
                    required: 'Bitte geben Sie eine Begründung an.',
                },
            },
            investChanged: {
                name: 'investmentChanged',
                label: 'Investitionsgegenstände/Maßnahmen haben sich geändert',
            },
            investName: {
                name: 'investmentChanges',
                label: 'Investitionsgegenstand/Maßnahme',
                rules: {
                    required:
                        'Bitte geben Sie einen Gegenstand/eine Maßnahme an.',
                },
            },
            investDesc: {
                name: 'investmentReason',
                label: 'Begründung für die Änderung des Gegenstands/der Maßnahme',
                rules: {
                    required: 'Bitte geben Sie eine Begründung an.',
                },
            },
            valid: {
                name: 'valid',
                rules: {
                    validate: (value) => value === 'valid',
                },
                message:
                    'Bitte geben Sie an, ob eine Änderung vorgenommen wurde.',
            },
        },
    },
    invoiceModal: {
        hint: {
            invoiceDate: {
                beforeZB: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zum Rechnungsdatum wurden die Ausgaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) verursacht. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung vor Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zum Rechnungsdatum wurden die Ausgaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) verursacht. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung vor Beginn des Bewilligungszeitraums.`,
                }),
                beforeBWZ: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zum Rechnungsdatum wurden die Ausgaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) verursacht. Die Ausgaben sind voraussichtlich nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung vor Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zum Rechnungsdatum wurden die Ausgaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) verursacht. Die Ausgaben sind voraussichtlich nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung vor Beginn des Bewilligungszeitraums.`,
                }),
                betweenEndOfBWZAndEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zum Rechnungsdatum wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgestellt. Die Ausgaben sind voraussichtlich nicht förderfähig, wenn kein sachgerechter Grund gegeben ist. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zum Rechnungsdatum wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgestellt. Die Ausgaben sind voraussichtlich nicht förderfähig, wenn kein sachgerechter Grund gegeben ist. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung nach Ende des Bewilligungszeitraums.`,
                }),
                afterEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zum Rechnungsdatum wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgestellt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zum Rechnungsdatum wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgestellt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Rechnungserstellung nach Ende des Bewilligungszeitraums.`,
                }),
            },
            orderDate: {
                beforeZB: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zur Beauftragung wurde diese vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung vor Erhalt des Zuwendungsbescheides und Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zur Beauftragung wurde diese vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung vor Erhalt des Zuwendungsbescheides und Beginn des Bewilligungszeitraums.`,
                }),
                beforeBWZ: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zur Beauftragung wurde diese vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind voraussichtlich nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung vor Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: null, // no message here
                }),
                betweenEndOfBWZAndEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zur Beauftragung wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zur Beauftragung wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung nach Ende des Bewilligungszeitraums.`,
                }),
                afterEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den getätigten Angaben zur Beauftragung wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den getätigten Angaben zur Beauftragung wurde diese nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung nach Ende des Bewilligungszeitraums.`,
                }),
            },
            period: {
                beforeZB: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung vor Erhalt des Zuwendungsbescheides und Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zum Leistungszeitraum wurde das Vorhaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) begonnen. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie den Leistungsbeginn vor Start des Bewilligungszeitraums.`,
                }),
                beforeBWZ: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) ausgeführt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Bestellung vor Erhalt des Zuwendungsbescheides und Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zum Leistungszeitraum wurde das Vorhaben vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) begonnen. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie den Leistungsbeginn vor Start des Bewilligungszeitraums.`,
                }),
                betweenEndOfBWZAndEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) fortgeführt. Die Ausgaben, die nach Ende des Bewilligungszeitraums entstanden sind, sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) fortgeführt. Die Ausgaben, die nach Ende des Bewilligungszeitraums entstanden sind, sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums.`,
                }),
                afterEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) fortgeführt. Die Ausgaben, die nach Ende des Bewilligungszeitraums entstanden sind, sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zum Leistungszeitraum wurde die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) fortgeführt. Die Ausgaben, die nach Ende des Bewilligungszeitraums entstanden sind, sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Umsetzung des bewilligten Vorhabens nach Ende des Bewilligungszeitraums.`,
                }),
            },
            paymentDate: {
                beforeZB: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung vor Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung vor Beginn des Bewilligungszeitraums.`,
                }),
                beforeBWZ: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung vor Beginn des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen vor Start des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung vor Beginn des Bewilligungszeitraums.`,
                }),
                betweenEndOfBWZAndEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind voraussichtlich nicht förderfähig, wenn kein sachgerechter Grund gegeben ist. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) getätigt. Die Ausgaben sind voraussichtlich nicht förderfähig, wenn kein sachgerechter Grund gegeben ist. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung nach Ende des Bewilligungszeitraums.`,
                }),
                afterEndOfBWZPlusTwoMonths: (outputDate) => ({
                    pvaIsSameOrBefore: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) beglichen. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung nach Ende des Bewilligungszeitraums.`,
                    pvaIsAfter: `Laut den Angaben zu den Zahlungsdaten wurden Zahlungen nach Beendigung des Bewilligungszeitraums (Zuwendungsbescheid vom ${outputDate}) beglichen. Die Ausgaben sind nicht förderfähig. Bitte prüfen Sie die Richtigkeit Ihrer Angaben und begründen Sie die Zahlung nach Ende des Bewilligungszeitraums.`,
                }),
            },
        },
    },
    overviewTable: {
        actions: {
            upload: {
                tooltip: 'Datei hochladen',
            },
            billing: {
                tooltip: 'Zahlungsinformationen eingeben',
            },
            changes: {
                tooltip: 'Erläuterungen eingeben',
            },
        },
    },
    additionalFunding: {
        title: 'Sind seit dem Zeitpunkt der Bewilligung (Zuwendungsbescheid) weitere oder zusätzliche Deckungsmittel (z.B. eine Investitionszulage) hinzugekommen?',
        hint: 'Es sind nur zusätzliche Deckungsmittel anzugeben, die direkt den über „Digital Jetzt“ geförderten Investitionsgegenstand betreffen. Tragen Sie in Summe die Förderungen oder Zulagen ein, die Sie seit der Antragstellung zusätzlich erhalten haben. Bitte beachten Sie, dass die Fördersumme anteilig (entsprechend der Förderquote) um die zusätzlichen Deckungsmittel gekürzt wird.',
        radio: {
            name: 'hasAdditionalFunding',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            rules: {
                validate: (value) =>
                    typeof value === 'boolean' ||
                    'Bitte geben Sie an, ob Sie zusätzliche Deckungsmittel erhalten haben',
            },
        },
        textfield: {
            name: 'additionalFunding',
            rules: { required: 'Bitte Deckungsmittel angeben' },
            label: 'Bitte geben Sie den Gesamtbetrag der Deckungsmittel an',
        },
        desc: {
            name: 'additionalComment',
            rules: { required: 'Bitte geben Sie eine Erläuterung an' },
            label: 'Bitte erläutern Sie die zusätzlichen Deckungsmittel',
        },
    },
    operational: {
        title: 'Bitte bestätigen Sie, dass Sie das Vorhaben und alle Investitionen bis zur Einreichung des Verwendungsnachweise betriebsbereit umgesetzt haben.',
        radio: {
            name: 'operational',
            buttons: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
            ],
            rules: {
                validate: (value) =>
                    typeof value === 'boolean' ||
                    'Bitte geben Sie an, ob Sie das Vorhaben und alle Investitionen betriebsbereit umgesetzt haben.',
            },
        },
        comment: {
            name: 'operationalComment',
            rules: { required: 'Bitte angeben' },
            label: 'Bitte nennen Sie die Investitionen, die nicht betriebsbereit umgesetzt wurden, und erläutern Sie kurz warum.',
        },
    },
    innerTable: {
        title: 'Überschrift',
    },
    hint: 'Bitte speichern Sie Ihre Angaben mit Klick auf den unten stehenden Button "Speichern". Sobald Sie Ihre Angaben gesamtheitlich abgeschlossen und gespeichert haben, klicken Sie bitte auf "Abschließen", um zur nächsten Seite zu gelangen.',
    topHintTeaser: {
        paragraphs: [
            {
                type: 'p',
                text: 'Im Finanzierungsplan Ihres Verwendungsnachweises nehmen Sie einen Abgleich vor zwischen den bewilligten Fördergegenständen und den Investitionen, die Sie tatsächlich getätigt haben.',
            },
        ],
    },
    topHint: {
        paragraphs: [
            {
                type: 'p',
                text: 'Zunächst sehen Sie eine Übersicht der bewilligten Nachweise inklusive der bewilligten Beträge. Mit einem Klick auf den Pfeil neben der Tabellenzeile öffnet sich die Detailansicht zu dem jeweiligen Nachweis. Hier können Sie nachweisbezogen die relevanten Rechnungen hochladen. Klicken Sie dazu bitte auf „Rechnung hinzufügen“.',
            },
            {
                type: 'p',
                text: 'In dem folgenden Pop-Up-Fenster werden Sie aufgefordert, das Rechnungsdokument als PDF-Format hochzuladen. Bitte beachten Sie, pro Rechnung ist ausschließlich das Hochladen einer Datei möglich. Anschließend dokumentieren Sie bitte die erforderlichen Informationen zu der jeweils hochgeladenen Rechnung. Halten Sie dazu bitte die folgenden Informationen bereit: Rechnungsdokument als PDF, Name des Lieferanten, Rechnungsnummer, Rechnungsdatum, Auftrags-/Bestelldatum, Leistungs-/Lieferzeitraum, Rechnungsbetrag sowie Zahlungsdatum und gezahlter Betrag. Zusätzlich werden Ihnen Fragen zu einem möglichen Lieferantenwechsel sowie substanziellen Änderungen in Bezug auf die Umsetzung des bewilligten Vorhabens gestellt.',
            },
            {
                type: 'p',
                text: 'Bitte nutzen Sie die ergänzenden Kommentarfelder um Änderungen, bspw. in der Projektrealisierung oder von Investitionsgegenständen, möglichst genau zu begründen. Dies ermöglicht eine schnellere Prüfung Ihres Verwendungsnachweises und vermeidet Nachfragen zur Klärung offener Fragestellungen.',
            },
            {
                type: 'p',
                text: 'Bezieht sich eine Rechnung auf mehr als einen Nachweis, laden Sie die Rechnung bitte jeweils bei den relevanten Nachweisen hoch und kennzeichnen Sie, welche Rechnungsinhalte bzw. Positionen für den jeweiligen Nachweis von Relevanz sind. Splitten Sie bitte in diesem Fall den Gesamtrechnungsbetrag und Zahlbetrag in Teilrechnungsbeträge und Teilzahlungsbeträge auf, die Sie im jeweiligen Nachweis geltend machen.',
            },
            {
                type: 'p',
                text: 'Mit „Speichern“, können Sie Ihre Eingaben zu der Rechnung zwischenspeichern oder abschließen. Zusätzlich werden Ihre Eingaben im Hintergrund gespeichert, so dass bei einer Unterbrechung der Bearbeitung keine Daten verloren gehen sollten. An dem „Disketten- oder Warndreieck-Symbol" können Sie erkennen, wenn zu der Rechnung noch nicht alle Eingaben gespeichert wurden oder die Eingabe noch unvollständig ist. Über das Symbol „Stift“ können Sie Ihre Eingaben zur jeweiligen Rechnung bearbeiten.',
            },
            {
                type: 'p',
                text: 'Erst wenn alle erforderlichen Daten in allen Nachweisen hinterlegt sind, können Sie durch „Abschließen“ zur nächsten Seite einer Übersichtsansicht Ihres Verwendungsnachweises gelangen.',
            },
        ],
    },
    topHintOld: {
        paragraphs: [
            {
                type: 'p',
                text: 'Im Finanzierungsplan Ihres Verwendungsnachweises nehmen Sie einen Abgleich vor zwischen dem bewilligten Fördergegenstand und den Investitionen, die Sie tatsächlich getätigt haben.',
            },
            {
                type: 'p',
                text: 'In der folgenden Tabelle ist für Sie Ihre Bewilligungsgrundlage eingeblendet. Durch einen Klick auf den Pfeil neben der Tabellenzeile können Sie einsehen, welche Einzelgegenstände die Nachweise beinhalten.',
            },
            {
                type: 'p',
                text: 'Die Bearbeitung Ihres Finanzierungsplans erfolgt in 3 Schritten:',
            },
            {
                type: 'p',
                text: '1. Tragen Sie bei „Rechnungsbetrag“ den vollständigen Rechnungsbetrag ein und laden die zugehörige Rechnung durch einen Klick auf das Upload-Symbol hoch. Beachten Sie dabei Folgendes:',
            },
            {
                type: 'list',
                text: '[li]Soweit Ihr Unternehmen die Möglichkeit zum Vorsteuerabzug nach § 15 des Umsatzsteuergesetzes hat, dürfen nur die Preise ohne Umsatzsteuer als Rechnungsbetrag berücksichtigt werden.[li][li]Wenn es mehrere Rechnungen zu einem Nachweis gibt, geben Sie die Gesamtsumme aller Einzelrechnungen ein und laden Sie alle Rechnungen hoch.[li]',
            },
            {
                type: 'p',
                text: '2. Geben Sie unter „Gezahlte Summe“ über das +-Symbol ein, wann die Bezahlung der Rechnung erfolgt ist. Beachten Sie dabei Folgendes:',
            },
            {
                type: 'list',
                text: '[li]Die Angaben müssen mit Ihrer internen Buchhaltung übereinstimmen. Wenn die Rechnung in Teilzahlungen beglichen wurde, geben Sie bitte sämtliche Teilzahlungen mit dem jeweiligen Betrag und Datum ein. Sie können hier auch mehrere Teilzahlungen erfassen.[li][li]Originalbelege (Auszüge aus Ihrer Buchhaltung) sind auf Verlangen vorzulegen.[li][li]Die von Ihnen gezahlte Summe bildet die Bemessungsgrundlage für die Berechnung der abschließenden Fördersumme.[li]',
            },
            {
                type: 'p',
                text: '3. Unter „Erläuterung“ geben Sie an, ob Sie ihre Investitionen wie geplant getätigt haben oder ob es erheblich Abweichungen gab. Beachten Sie dabei folgendes:',
            },
            {
                type: 'list',
                text: '[li]Abweichungen können sowohl die Höhe der Investition, den Gegenstand als auch den Anbieter betreffen.[li][li]Es können nur Zahlungen an geeignete, externe Anbieter berücksichtigt werden. Leistungen, die Inhouse erbracht wurden, können nicht gefördert werden.[li]',
            },
            {
                type: 'p',
                text: '4. Unter „Speichern“ können Sie die Eingaben zu einem Nachweis abschließen.',
            },
            {
                type: 'list',
                text: '[li]Sie erhalten eine Fehlermeldung, wenn Eingaben unvollständig oder inkonsistent sind.[li][li]Wenn Sie nach dem Speichern Änderungen vornehmen möchten, kann die Bearbeitung durch einen Klick auf den „Grünen Haken“ wieder geöffnet werden.[li]',
            },
        ],
    },
};
