export function saveMutator(values) {
    let companies = [];
    let addresses = [];

    addresses.push(values.companies.addresses);

    companies.push({
        name: values.companies.name,
        addresses,
    });

    const data = { ...values, username: values.email, companies };

    data.title = data.title || null;
    data.position = data.position || null;

    return data;
}
