export const texts = {
    not_found: {
        headline: 'Seite nicht gefunden.',
        button: 'Anmelden',
    },
    critical: {
        headline: 'Da ist etwas schiefgelaufen.',
        button: 'Erneut versuchen',
    },
    external: {
        headline: 'Da ist etwas schiefgelaufen.',
        text: 'Bitte stellen Sie sicher, dass Sie eine aktuelle Version von Ihrem Browser verwenden.',
        button: 'Erneut versuchen',
    },
};
