export const texts = {
    container: {
        buttonAdd: 'Datei(en) hochladen',
        table: {
            ariaLabel: 'Hochgeladene Dateien',
            cell: ['Dateiname', 'Dateityp', 'Dateigröße', 'Hochgeladen am'],
            emptyLabel: 'Keine Datei(en) hochgeladen',
        },
    },
    dropzone: {
        onDrag: (maxFileCount) =>
            maxFileCount === 1
                ? 'Datei hier ablegen'
                : 'Datei(en) hier ablegen',
        drop: (maxFileCount) =>
            maxFileCount === 1
                ? 'Datei hier ablegen oder hier klicken'
                : 'Datei(en) hier ablegen oder hier klicken',
        caption: (maxFileSize, maxFileCount, acceptedFileTypes) =>
            `Maximale Dateigröße: ${maxFileSize} MB,${
                maxFileCount > 0 ? ` Max Anzahl Dateien: ${maxFileCount},` : ''
            } Erlaubte Dateitypen: ${acceptedFileTypes.join(', ')}`,
    },
};
