import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../../../../TextBlock/TextBlock';
import { texts as oft } from '../../../../../texts/Forms/OverviewFormTexts';

import MinimisTreeview from './MinimisTreeview';

const MinimiOverview = (props) => {
    const { data } = props;
    return (
        <>
            {!data.minimiFields.received && !data.minimiFields.requested ? (
                <TextBlock textBlock={oft.minimis.parser.text1} />
            ) : (
                <>
                    {data.minimiFields.received &&
                        !data.minimiFields.requested && (
                            <TextBlock
                                textBlock={oft.minimis.parser.received}
                            />
                        )}

                    {!data.minimiFields.received &&
                        data.minimiFields.requested && (
                            <TextBlock
                                textBlock={oft.minimis.parser.requested}
                            />
                        )}

                    {data.minimiFields.received &&
                        data.minimiFields.requested && (
                            <TextBlock
                                textBlock={oft.minimis.parser.receivedRequested}
                            />
                        )}
                    <MinimisTreeview data={data} />
                </>
            )}
        </>
    );
};

MinimiOverview.propTypes = {
    data: PropTypes.object,
};

export default MinimiOverview;
