import { api } from '../AxiosConfig/Config';
import { Response } from '../../../util/DataTypes/Response';

export function getLegalList() {
    return new Promise((resolve, reject) => {
        api()
            .get('/legal_forms')
            .then((response) => {
                const obj = response.data['hydra:member'];
                resolve(obj);
            })
            .catch((err) => {
                reject(
                    Response.Error('Fehler beim laden der Rechtsformen', err)
                );
            });
    });
}
