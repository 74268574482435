import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { texts as mft } from '../../../texts/Forms/MinimisFormTexts';
import { useStore } from './Minimis.store';
import MinimisCollapse from './MinimisCollapse';
import MinimisCompanyPicker from './MinimisCompanyPicker';
import MinimisMainTableView from './MinimisMainTableView';
import Alert from '@material-ui/lab/Alert';

const MinimisCalculationYear = (props) => {
    const { year, ab } = props;

    const { childA, childB, createNewEmptyChild, removeChild, companies } =
        useStore((state) => ({
            childA: state[`${year}ChildA`],
            childB: state[`${year}ChildB`],
            companies: state.companies,
            createNewEmptyChild: state.createNewEmptyChild,
            removeChild: state.removeChild,
        }));

    const handleChildChange =
        (ab) =>
        (value, add = true) => {
            if (add) {
                createNewEmptyChild(year, ab, value);
                return;
            }
            removeChild(year, ab, value);
        };

    return (
        <>
            {ab.received === 'true' && (
                <>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {mft.calculator.a}
                    </Typography>

                    <MinimisCollapse label={mft.calculator.sub1}>
                        <MinimisMainTableView
                            year={year}
                            ab={'a'}
                            title="Beihilfen"
                        />
                    </MinimisCollapse>

                    {companies.length > 0 && (
                        <>
                            <MinimisCollapse label={mft.calculator.sub2}>
                                <MinimisCompanyPicker
                                    companies={Object.keys(childA)}
                                    onChange={handleChildChange('a')}
                                    helperText={
                                        mft.calculator
                                            .chooseChildCompaniesReceived
                                    }
                                    label="Unternehmen"
                                />
                            </MinimisCollapse>
                        </>
                    )}
                </>
            )}

            {ab.requested === 'true' && (
                <>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {mft.calculator.b}
                    </Typography>
                    <Alert severity="info">
                        <Typography>{mft.calculator.bSub}</Typography>
                    </Alert>
                    <MinimisCollapse label={mft.calculator.sub1}>
                        <MinimisMainTableView
                            year={year}
                            ab={'b'}
                            title="Beihilfen"
                        />
                    </MinimisCollapse>

                    {companies.length > 0 && (
                        <>
                            <MinimisCollapse label={mft.calculator.sub2}>
                                <MinimisCompanyPicker
                                    companies={Object.keys(childB)}
                                    onChange={handleChildChange('b')}
                                    helperText={
                                        mft.calculator
                                            .chooseChildCompaniesReceived
                                    }
                                    label="Unternehmen"
                                />
                            </MinimisCollapse>
                        </>
                    )}
                </>
            )}
        </>
    );
};

MinimisCalculationYear.propTypes = {
    year: PropTypes.string.isRequired,
    ab: PropTypes.object,
};

MinimisCalculationYear.defaultProps = {
    ab: {},
};

export default MinimisCalculationYear;
