import { muiLocalization } from '../../config/MuiLocalization';

export const verificationLocale = (searching) => ({
    textLabels: {
        ...muiLocalization.textLabels,
        body: {
            ...muiLocalization.textLabels.body,
            noMatch: searching
                ? 'Es wurden keine Verwendungsnachweise gefunden.'
                : 'Das digitale Verwendungsnachweistool ist für Sie noch nicht freigeschaltet. Die Frist zur Einreichung des Verwendungsnachweise beträgt 2 Monate nach Ende des Bewilligungszeitraums (Informationen hierzu finden Sie im Zuwendungsbescheid). Sollte das digitale Verwendungsnachweistool auch nach dem Ende ihres Bewilligungszeitraums noch nicht freigeschaltet sein, bitten wir Sie uns dies kurz mitzuteilen. Wir werden Sie dann schnellstmöglich freischalten. Bitte schreiben Sie hierzu eine E-Mail an: digitaljetzt@dlr.de mit dem Betreff: „Einreichung des Verwendungsnachweises für das Förderkennzeichen 01WDxxxx."',
        },
    },
});
