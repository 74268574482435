import React from 'react';
import Typography from '@material-ui/core/Typography';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import Alert from '@material-ui/lab/Alert';

const CannotParticipate = (props) => {
    return (
        <Alert severity="error">
            <Typography>{rt.cannotParticipate}</Typography>
        </Alert>
    );
};

export default CannotParticipate;
