import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
    fetchVerificationById,
    finishResubmitInvoices,
} from '../../lib/api/Verification/VerificationActions';
import Typography from '@material-ui/core/Typography';
import moment from 'moment/moment';
import Alert from '@material-ui/lab/Alert';
import FullscreenCircularLoader from '../../components/Partials/Loader/FullscreenCircularLoader';
import TextField from '@material-ui/core/TextField';
import { client } from '../../QueryClient';
import { step4Texts } from '../../texts/Verification/Step4Texts';
import useVerificationPatch from '../../hooks/Verification/useVerificationPatch';
import FormFooter from '../../components/Forms/Verification/FormFooter';
import Outline from '../../components/Partials/Info/Outline';
import FormRadio from '../../components/Forms/Components/FormRadio';
import FormMoney from '../../components/Forms/Components/FormMoney';
import FormTextArea from '../../components/Forms/Components/FormTextArea';
import FormCheckbox from '../../components/Forms/Components/FormCheckbox';
import { FORM_TRANSFORMER } from '../../util/Helper/FormTransformer';
import DropzoneContainer from '../Dropzone/DropzoneContainer';
import {
    downloadFile,
    fetchUploadedFilesFor,
} from '../../lib/api/Files/FileActions';
import {
    createInvoice,
    deleteInvoice,
    fetchProof,
    saveInvoiceData,
    setNoInvoice,
    updateVerificationProofs,
} from '../../lib/api/VerificationProof/VerificationProofActions';
import FormBasicDatePicker from '../../components/Forms/Components/FormBasicDatePicker';
import RadioButtons from '../../components/Partials/Form/RadioButtons';
import { createDownloadLinkForFileResponse } from '../../util/Helper/CreateDownloadLinkForFileResponse';
import ToggleableInfobox from '../../components/Helper/ToggleableInfobox';
import TextBlock from '../../components/TextBlock/TextBlock';
import { FinancialPlanTable } from '@dlr-pt/prj-dij-react-components';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import { featureFlagConfig } from '../../config/FeatureFlags';
import Countdown from '../../components/Countdown/Countdown';
import { useSnackbar } from 'notistack';

const ResubmitInvoicesContainer = () => {
    const { vnId } = useParams();
    const [tableData, setTableData] = React.useState([]);
    const [verificationProofsLoading, setVerificationProofsLoading] =
        React.useState(true);
    const [
        showFinishResubmitInvoicesDialog,
        setShowFinishResubmitInvoicesDialog,
    ] = React.useState(false);
    const [isFinishing, setIsFinishing] = React.useState(false);
    const [invalidInvoices, setInvalidInvoices] = React.useState(false);

    const history = useHistory();

    let resubmitInvoicesFeatureEnabled = useFlag(
        featureFlagConfig.resubmitInvoices.name
    );
    const { enqueueSnackbar } = useSnackbar();

    const { data, isLoading } = useQuery(
        ['verification', vnId],
        () => fetchVerificationById(vnId),
        { refetchOnWindowFocus: false }
    );

    React.useEffect(() => {
        const refreshVerification = async () => {
            const proofs = await updateVerificationProofs(vnId);

            setTableData(proofs);
        };

        refreshVerification().then(() => {
            setVerificationProofsLoading(false);
        });
    }, [vnId]);

    const vnData = data?.formData;

    const handleCloseFinishResubmitInvoicesDialog = () => {
        setShowFinishResubmitInvoicesDialog(false);
    };

    const handleFinish = async () => {
        setIsFinishing(true);
        try {
            await finishResubmitInvoices(vnId);

            // ugly, but we need to change the header state too, so reloading is the easiest way
            window.location.reload();
        } catch (e) {
            setIsFinishing(false);
            enqueueSnackbar(e.message(), { variant: 'error' });
        }
    };

    if (isLoading) return <FullscreenCircularLoader />;

    if (
        !resubmitInvoicesFeatureEnabled ||
        vnData?.currentStep !== 'resubmit_invoices'
    ) {
        return (
            <>
                <Typography variant="h4" component="h1" gutterBottom>
                    Rechnungen nachreichen
                </Typography>

                <Alert severity="info">
                    <Typography>
                        Die Rechnungsnachreichung wurde abgeschlossen. Es können
                        keine weiteren Rechnungen nachgereicht werden.
                    </Typography>
                </Alert>

                <Button
                    style={{ marginTop: 16 }}
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={() => {
                        history.push(`/vn/${vnId}/anzeigen`);
                    }}
                >
                    Verwendungsnachweis anzeigen
                </Button>
            </>
        );
    }

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                Rechnungen nachreichen
            </Typography>

            <Alert severity="info">
                <Typography>
                    Bitte reichen Sie bis spätestens{' '}
                    <strong>
                        {moment(vnData.resubmitUntil).format(
                            'DD.MM.YYYY [23:59 Uhr]'
                        )}
                    </strong>{' '}
                    Rechnungen nach. Beachten Sie dabei folgende
                    Bearbeitungshinweise:
                </Typography>
                <TextField
                    style={{ marginTop: 8 }}
                    multiline
                    readOnly
                    fullWidth
                    label=""
                    value={vnData.resubmitComment}
                />
            </Alert>

            {!verificationProofsLoading ? (
                <>
                    <FinancialPlanTable
                        tableData={tableData}
                        displayOnly={false}
                        resubmitInvoices={true}
                        setInvalidInvoices={setInvalidInvoices}
                        verificationId={vnData.id}
                        onStepChange={() => {}}
                        onDirtyChange={() => {}}
                        formData={vnData}
                        proposal={data.staticData.proposal}
                        client={client}
                        texts={step4Texts}
                        useVerificationPatch={useVerificationPatch}
                        formFooter={FormFooter}
                        outline={Outline}
                        formRadio={FormRadio}
                        formMoney={FormMoney}
                        formTextArea={FormTextArea}
                        formCheckbox={FormCheckbox}
                        formTransformer={FORM_TRANSFORMER}
                        dropzoneContainer={DropzoneContainer}
                        fetchUploadedFilesFor={fetchUploadedFilesFor}
                        createInvoice={createInvoice}
                        setNoInvoice={setNoInvoice}
                        formBasicDatePicker={FormBasicDatePicker}
                        radioButtons={RadioButtons}
                        fetchProof={fetchProof}
                        saveInvoiceData={saveInvoiceData}
                        deleteInvoice={deleteInvoice}
                        downloadFile={downloadFile}
                        createDownloadLinkForFileResponse={
                            createDownloadLinkForFileResponse
                        }
                        toggleableInfobox={ToggleableInfobox}
                        textBlock={TextBlock}
                    />

                    {invalidInvoices && (
                        <Alert severity="warning" style={{ marginTop: 16 }}>
                            <Typography variant="body2">
                                Die Angaben zu mindestens einer nachgereichten
                                Rechnung sind unvollständig. Bitte überprüfen
                                Sie die Angaben, bevor die Nachreichung
                                abgeschlossen wird.
                            </Typography>
                        </Alert>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 16,
                        }}
                    >
                        <Button
                            style={{ marginRight: 16, flexShrink: 0 }}
                            color="primary"
                            disableElevation
                            variant="contained"
                            onClick={() =>
                                setShowFinishResubmitInvoicesDialog(true)
                            }
                        >
                            Nachreichung abschließen
                        </Button>
                        <Alert severity="warning">
                            <Typography
                                style={{ marginTop: -8 }}
                                variant="body2"
                            >
                                Sie haben noch{' '}
                                <strong style={{ fontSize: '120%' }}>
                                    <Countdown
                                        end={moment(
                                            vnData.resubmitUntil
                                        ).format('YYYY-MM-DD[T23:59:59]')}
                                    />
                                </strong>{' '}
                                Zeit, um Rechnungen nachzureichen. Nach Ablauf
                                der Zeit wird die Nachreichung automatisch
                                abgeschlossen und{' '}
                                <strong>gespeicherte Rechnungen</strong>{' '}
                                geprüft.
                            </Typography>
                        </Alert>
                    </div>

                    <Dialog
                        open={showFinishResubmitInvoicesDialog}
                        onClose={handleCloseFinishResubmitInvoicesDialog}
                        maxWidth="md"
                    >
                        <DialogTitle>Nachreichung abschließen</DialogTitle>
                        <DialogContent>
                            <Alert severity="warning">
                                <Typography
                                    style={{ marginTop: -8 }}
                                    variant="body2"
                                >
                                    Sie haben noch{' '}
                                    <strong style={{ fontSize: '120%' }}>
                                        <Countdown
                                            end={moment(
                                                vnData.resubmitUntil
                                            ).format('YYYY-MM-DD[T23:59:59]')}
                                        />
                                    </strong>{' '}
                                    Zeit, um Rechnungen nachzureichen. Bitte
                                    stellen Sie sicher, dass Sie die
                                    Bearbeitungshinweise beachtet haben.
                                </Typography>
                            </Alert>

                            {invalidInvoices && (
                                <Alert
                                    severity="warning"
                                    style={{ marginTop: 16 }}
                                >
                                    <Typography variant="body2">
                                        Die Angaben zu mindestens einer
                                        nachgereichten Rechnung sind
                                        unvollständig. Bitte überprüfen Sie die
                                        Angaben, bevor die Nachreichung
                                        abgeschlossen wird.
                                    </Typography>
                                </Alert>
                            )}
                        </DialogContent>
                        <DialogActions>
                            {isFinishing && (
                                <CircularProgress
                                    size={20}
                                    style={{ marginRight: 4 }}
                                />
                            )}
                            <Button
                                onClick={handleFinish}
                                variant="contained"
                                disableElevation
                                color="primary"
                                disabled={isFinishing}
                            >
                                Nachreichung abschließen
                            </Button>
                            <Button
                                onClick={
                                    handleCloseFinishResubmitInvoicesDialog
                                }
                                disableElevation
                                color="primary"
                                disabled={isFinishing}
                            >
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={30} />
                </div>
            )}
        </>
    );
};

export default ResubmitInvoicesContainer;
