export function fetchFourMutator(data) {
    const { minimis, shareholderCompanies } = data;

    const fields = {
        received: {},
        requested: {},
        shareholder: {
            received: {},
            requested: {},
        },
    };

    minimis.forEach((minimi) => {
        if (!fields.received[minimi.year]) {
            fields.received[minimi.year] = [];
            fields.requested[minimi.year] = [];
        }
        minimi.received
            ? fields.received[minimi.year].push(minimi)
            : fields.requested[minimi.year].push(minimi);
    });

    shareholderCompanies.forEach((company) => {
        company.minimis.forEach((minimi) => {
            if (!fields.shareholder.received[company.companyName]) {
                fields.shareholder.received[company.companyName] = {};
                fields.shareholder.requested[company.companyName] = {};
            }
            if (
                !fields.shareholder.requested[company.companyName][minimi.year]
            ) {
                fields.shareholder.received[company.companyName][minimi.year] =
                    [];
                fields.shareholder.requested[company.companyName][minimi.year] =
                    [];
            }
            minimi.received
                ? fields.shareholder.received[company.companyName][
                      minimi.year
                  ].push(minimi)
                : fields.shareholder.requested[company.companyName][
                      minimi.year
                  ].push(minimi);
        });
    });

    return { ...data, fields };
}

//shareholderCompanies[] -> minimis[] -> year/received
