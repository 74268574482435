import { handleError } from '../../../util/Error/HandleError';
import { Response } from '../../../util/DataTypes/Response';
import { client } from '../../../QueryClient';
import { api } from '../AxiosConfig/Config';
import { getIdFromIri } from '../../../util/Helper/GetIdFromIri';
import { verificationActions } from '../../../config/ProposalDetail';
import {
    generateProofs,
    mutateStaticData,
    transformTransition,
} from './VerificationMutator';
import _ from 'lodash';
import { downloadFile } from '../Files/FileActions';

export function fetchEligibleProposals() {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/proposals?exists[revision]=true`
            );

            const filtered = data['hydra:member'].filter(
                (el) =>
                    Boolean(el.revisionStatus?.final_approved) ||
                    Boolean(el.revisionStatus?.final_revoked)
            );

            resolve(filtered);
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Beim Laden der Anträge ist ein Fehler aufgetreten.',
                })
            );
        }
    });
}

export function createVerificationProof({ proposalId }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: d } = await api().post(
                `/verifications`,
                {
                    proposal: `/api/proposals/${proposalId}`,
                },
                { headers: { 'content-type': 'application/json' } }
            );

            const data = client.getQueryData(['verification']);
            const index = data.findIndex((d) => d.id === proposalId);
            data[index].verification = {
                currentStep: 'editable',
                id: d['@id'].slice('/api/verifications/'.length),
            };
            client.setQueryData(['verification'], data);

            resolve(Response.Success('Verwendungsnachweis angelegt.'));
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Verwendungsnachweis konnte nicht angelegt werden.',
                })
            );
        }
    });
}

export function fetchVerification(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(`/verifications/${id}`);
            resolve(data);
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Es ist ein allgemeiner Fehler aufgetreten',
                    401: 'Sie sind nicht berechtigt den Antrag zu laden',
                    404: 'Der Antrag konnte nicht gefunden werden',
                    422: 'Die Anfrage war fehlerhaft',
                    500: 'Es ist ein allgemeiner Serverfehler aufgetreten',
                })
            );
        }
    });
}

export function fetchProposalSteps(iri, progressCallback) {
    const maxSteps = 10;
    const id = getIdFromIri(iri);
    return new Promise(async (resolve, reject) => {
        try {
            const steps = {};
            await Promise.all(
                verificationActions.map(async (action) => {
                    const stepKey = action.step.replace(/_/g, '');
                    steps[stepKey] = await action.fetch(
                        action.step,
                        action.mutator,
                        action.error
                    )(id);
                    progressCallback(
                        Math.floor((Object.keys(steps).length / maxSteps) * 100)
                    );
                })
            );
            resolve(steps);
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Einige Daten konnten nicht geladen werden',
                })
            );
        }
    });
}

export function fetchVerificationById(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await fetchVerification(id);

            const proposalId = getIdFromIri(data.proposal);
            const steps = await fetchProposalSteps(proposalId, () => {});
            const { data: proposal } = await api().get(
                `/proposals/${proposalId}`
            );

            const { data: approvedData } = await api().get(
                `/verifications/${id}/approved-data`
            );

            let verificationProofs = {};

            client.setQueryData(['verificationApprovedData', id], approvedData);

            verificationProofs = await fetchVerificationProofs(id);

            const proofs = generateProofs(
                verificationProofs['hydra:member'],
                approvedData.proofs || []
            );

            resolve({
                ...mutateStaticData({
                    proposal,
                    approvedData,
                    id,
                    ...steps,
                }),
                formData: { ...data, verificationProofs: proofs },
            });
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Konnte die Daten des Verwendungsnachweises nicht laden.',
                })
            );
        }
    });
}

export function patchVerification({ data, id, success, transition }) {
    return new Promise(async (resolve, reject) => {
        try {
            const cachedData = client.getQueryData(['verification', id]);
            const headers = {
                'content-type': 'application/merge-patch+json',
            };

            if (transition) {
                headers['X-Transition'] = transformTransition(transition);
            }

            const { data: result } = await api().patch(
                `/verifications/${id}`,
                { ...cachedData.formData, ...data },
                { headers }
            );

            //Set After successfull save
            client.setQueryData(['verification', id], {
                formData: {
                    ...cachedData.formData,
                    ...data,
                    checkRequired: result.checkRequired,
                },
                staticData: { ...cachedData.staticData },
            });

            resolve(
                Response.Success(
                    success ||
                        'Der Verwendungsnachweis wurde erfolgreich gespeichert.',
                    {}
                )
            );
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Der Verwendungsnachweis konnte nicht gespeichert werden',
                })
            );
        }
    });
}

export function putVerificationProofs({ data, id, success }) {
    return new Promise(async (resolve, reject) => {
        try {
            const responses = await Promise.all(
                data.map(async (d) =>
                    api().put(
                        `/verification_proofs/${d.proof.slice(
                            '/api/proofs/'.length
                        )}`,
                        d
                    )
                )
            );

            const cachedData = client.getQueryData(['verification', id]);
            const mergedData = _.merge(cachedData, {
                verifications: [...responses.map((el) => el.data)],
            });
            client.setQueryData(['verification', id], mergedData);

            resolve(Response.Success(success || 'Erfolgreich gespeichert'));
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Daten konnten nicht gespeichert werden.',
                })
            );
        }
    });
}

export function verificationTransition(verificationId, transition) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/verification/${verificationId}/apply-transition`,
                {
                    transition: transformTransition(transition),
                }
            );

            resolve(Response.Success('Erfolgreich den Schritt gewechselt'));
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Der Schritt konnte nicht gewechselt werden',
                })
            );
        }
    });
}

export function getFinalFinancialPlanData(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/verifications/${id}/financial-plan`
            );
            resolve(data);
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Die Finanzplandaten konnten nicht geladen werden',
                })
            );
        }
    });
}

export function downloadPdf(verificationId, downloadCallback) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: verification } = await api().get(
                `/verifications/${verificationId}`
            );

            if (!verification.verificationPdf) {
                reject(
                    Response.Error(
                        'Das Unterschriftsblatt konnte nicht gefunden werden'
                    )
                );
                return;
            }

            const { data: pdf } = await api().get(
                `${verification.verificationPdf.slice('/api'.length)}`
            );

            const response = await downloadFile(
                pdf.downloadUrl,
                pdf.fileName,
                pdf.fileSize,
                downloadCallback
            );

            resolve({ response, file: pdf });
        } catch (e) {
            reject(
                handleError(e, { generic: 'Datei konnte nicht geladen werden' })
            );
        }
    });
}

export function fetchVerificationProofs(verificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/verifications/${verificationId}/verification_proofs`
            );
            resolve(data);
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Die Nachweise konnten nicht geladen werden',
                })
            );
        }
    });
}

export function finishResubmitInvoices(verificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/verification/${verificationId}/apply-transition`,
                {
                    transition: 'finish_resubmit',
                }
            );

            resolve(
                Response.Success(
                    'Die Rechnungsnachreichung wurde abgeschlossen.',
                    {}
                )
            );
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Die Rechnungsnachreichung konnte nicht abgeschlossen werden.',
                })
            );
        }
    });
}
