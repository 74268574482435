import React from 'react';
import PropTypes from 'prop-types';

const RaffleDisplay = (props) => {
    const { label, children } = props;

    return (
        <div>
            {label}
            {children}
        </div>
    );
};

RaffleDisplay.propTypes = {
    label: PropTypes.any,
    children: PropTypes.node,
};

export default RaffleDisplay;
