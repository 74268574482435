export function mutateFetch(data) {
    const mutatedData = Object.assign({}, data);

    // Change bool to string
    mutatedData.meta.hasProviderChanged =
        mutatedData.meta.hasProviderChanged?.toString() || '';
    mutatedData.meta.hasInvestChanged =
        mutatedData.meta.hasInvestChanged?.toString() || '';
    mutatedData.meta.relevantPosOnly =
        mutatedData.meta.relevantPosOnly?.toString() || '';
    mutatedData.meta.periodApproved =
        mutatedData.meta.periodApproved?.toString() || '';

    // Convert all null values to empty string (form needs this)
    Object.keys(mutatedData.meta).map((field) => {
        return mutatedData.meta[field] === null
            ? (mutatedData.meta[field] = '')
            : mutatedData.meta[field];
    });

    return mutatedData;
}

export function saveMutator(data) {
    const mutatedData = Object.assign({}, data);

    // Change string to bool
    mutatedData.hasProviderChanged = mutatedData.hasProviderChanged === 'true';
    mutatedData.hasInvestChanged = mutatedData.hasInvestChanged === 'true';
    mutatedData.relevantPosOnly = mutatedData.relevantPosOnly === 'true';
    mutatedData.periodApproved = mutatedData.periodApproved === 'true';

    mutatedData.paymentsComment = mutatedData.paymentsComment || null;
    mutatedData.providerChangedText = mutatedData.providerChangedText || null;

    // Remove 'notListed' selection and only send proof item ids
    mutatedData.proofItems = mutatedData.proofItems.filter(
        (pI) => pI !== 'notListed'
    );

    return {
        meta: mutatedData,
    };
}
