import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const CreateLink = (props) => {
    const { string, uris, target } = props;
    const url = uris.shift();
    if (url.startsWith('http')) {
        return (
            <Link target={target} href={url} key={url}>
                {string.slice(2, -2)}
            </Link>
        );
    }
    return (
        <Link target={target} to={url} key={url} component={RouterLink}>
            {string.slice(2, -2)}
        </Link>
    );
};

CreateLink.propTypes = {
    string: PropTypes.string.isRequired,
    uris: PropTypes.array,
    target: PropTypes.oneOf(['_blank', undefined]),
};

export default CreateLink;
