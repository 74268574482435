export const texts = {
    form: {
        salutation: {
            label: 'Anrede',
            required: 'Bitte Anrede auswählen.',
            options: {
                m: 'Herr',
                f: 'Frau',
                d: 'divers',
            },
        },
        title: {
            label: 'Titel',
            options: {
                empty: 'Kein Titel',
                dr: 'Dr.',
                phd: 'PhD',
                prof: 'Prof.',
            },
        },
        firstName: {
            label: 'Vorname',
            required: 'Bitte geben Sie Ihren Vornamen an.',
        },
        lastName: {
            label: 'Nachname',
            required: 'Bitte geben Sie Ihren Nachnamen an.',
        },
        position: {
            label: 'Position im Unternehmen',
            options: {
                empty: 'keine Angabe',
                0: 'Geschäftsführung',
                1: 'Leitung Abteilung/Unternehmenseinheit',
                2: 'Mitarbeitende',
            },
        },
        email: {
            label: 'E-Mail Adresse',
            required: 'Bitte geben Sie Ihre E-Mail Adresse an.',
            error: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
        },
        emailConfirm: {
            label: 'E-Mail Adresse bestätigen',
            required: 'Bitte geben Sie Ihre E-Mail Adresse an.',
            error: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
            validate: 'E-Mail Adressen müssen übereinstimmen',
            formField: 'email_confirm',
        },
        password: {
            label: 'Passwort angeben',
            required: 'Bitte Passwort angeben.',
            fieldName: 'password',
        },
        passwordConfirm: {
            label: 'Passwort bestätigen',
            required: 'Bitte Passwort angeben',
            validate: 'Passwörter stimmen nicht überein.',
            formField: 'password_confirm',
        },
        companyName: {
            label: 'Rechtsverbindlicher Name der Organisation',
            required: 'Bitte geben Sie den rechtsverbindlichen Namen an.',
            formField: 'companies.name',
        },
        companyStreet: {
            label: 'Straße',
            required: 'Bitte geben Sie die Straße an.',
            formField: 'companies.addresses.street',
        },
        companyHouseNumber: {
            label: 'Hausnummer',
            required: 'Bitte geben Sie die Hausnummer an.',
            formField: 'companies.addresses.housenumber',
        },
        companyPostcode: {
            label: 'PLZ',
            validate: 'Bitte geben Sie eine gültige PLZ an.',
            formField: 'companies.addresses.postcode',
        },
        companyCity: {
            label: 'Ort',
            required: 'Bitte geben Sie einen Ort an.',
            formField: 'companies.addresses.city',
        },
        companyPhone: {
            label: 'Telefonnummer',
            validate:
                'Bitte tragen Sie nur Zahlen ohne Sonder- oder Leerzeichen ein, beginnend mit einer 0.',
            formField: 'companies.addresses.phone',
        },
        privacy: {
            title: 'Einwilligungserklärung',
            label: 'Einwilligungserklärung',
            content:
                'Ich bin mit der Verarbeitung meiner personenbezogenen Daten einverstanden und nehme die ' +
                '{%Datenschutzerklärung%} zur Kenntnis. Ich habe ' +
                'insbesondere von meinem Widerrufsrecht Kenntnis genommen. Mir ist bewusst, dass meine ' +
                'Registrierungsdaten nur dann vorzeitig gelöscht werden können, wenn ich noch keine vollständige ' +
                'Einreichung getätigt habe.',
            uri: 'https://www.digitaljetzt-portal.de/datenschutz',
            checkbox_label:
                'Ich habe die Einwilligungserklärung zur Verarbeitung meiner Daten zur Kenntnis genommen.',
            required: 'Bitte bestätigen Sie die Einwilligungserklärung.',
        },
        send: {
            label: 'Registrieren',
        },
    },
    register: {
        done: {
            headline: 'Erfolgreich registriert',
            content:
                'Vielen Dank für Ihre Anmeldung. Sie erhalten innerhalb der nächsten 24 Stunden eine E-Mail mit einem Aktivierungslink.',
        },
    },
    validate: {
        loading: {
            headline: 'E-Mail bestätigen...',
            content: 'Ihre E-Mail Adresse wird bestätigt...',
        },
        success: {
            headline: 'E-Mail bestätigt!',
            content:
                'Ihre E-Mail wurde erfolgreich bestätigt. Per Klick auf den obigen Anmeldebutton können Sie sich ab sofort im Förderportal anmelden, um an der nächstmöglichen monatlichen Zuweisung der Antragskontingente teilzunehmen.',
        },
        error: {
            headline: 'E-Mail bestätigen',
            headline2: 'E-Mail bestätigen',
            content:
                'Das von Ihnen angegebene Token ist ungültig, da der Bestätigungslink entweder bereits erfolgreich genutzt wurde oder dessen Gültigkeitsdauer überschritten wurde. Bitte versuchen Sie, sich mit Ihren Benutzerdaten anzumelden oder sich erneut zu registrieren.',
        },
    },
    globalError:
        'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die eingegebenen Daten:',
    passwordDirective: {
        label: {
            show: 'Passwort Richtlinien anzeigen',
            hide: 'Passwort Richtlinien ausblenden',
        },
        hint: {
            headline: 'Folgende Richtlinien müssen beachtet werden',
            list: {
                0: 'Passwort muss mindestens 10 Zeichen lang sein',
                1: '3 der folgenden 4:',
                2: 'Großbuchstabe',
                3: 'Kleinbuchstabe',
                4: 'Sonderzeichen',
                5: 'Nummer',
            },
        },
    },
};
