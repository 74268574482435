import React from 'react';
import VerificationActionsContainer from '../../container/Verification/VerificationActionsContainer';
import UsageTableFunctions from './VerificationTableFunctions';

export const columns = (data) => [
    {
        name: 'proposalId',
        label: 'Bewilligter Antrag',
        options: {
            filter: false,
        },
    },
    {
        name: 'proposal',
        label: 'Verwendungsnachweis',
        options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
                return (
                    <VerificationActionsContainer
                        proposalId={data[dataIndex].id}
                        render={UsageTableFunctions}
                        verification={data[dataIndex].verification}
                        revisionStatus={data[dataIndex].revisionStatus}
                        inAmendment={data[dataIndex].inAmendment}
                        inObjection={data[dataIndex].inObjection}
                    />
                );
            },
        },
    },
];
