import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const MinimisSelect = (props) => {
    const { label, value, items, onChange, error, removeError } = props;

    const handleChange = (evt) => {
        if (error) removeError();
        onChange(evt.target.value);
    };

    return (
        <TextField
            size="small"
            label={label}
            select
            value={value}
            fullWidth
            onChange={handleChange}
            variant="outlined"
            error={error}
        >
            <MenuItem value={''} disabled>
                Bitte auswählen
            </MenuItem>
            {items.map((item, key) => (
                <MenuItem value={item.value} key={key}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

MinimisSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    removeError: PropTypes.func,
};

MinimisSelect.defaultProps = {
    items: [],
    value: '',
    disabled: false,
    error: false,
};

export default MinimisSelect;
