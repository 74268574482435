import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import RenderTableHead from '../Components/RenderTableHead';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import RenderTableFooter from '../Components/RenderTableFooter';
import DeviceTableEditRow from './DeviceTableEditRow';
import DeviceTableRow from './DeviceTableRow';
import RenderEmptyRow from '../Components/RenderEmptyRow';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';
import { strToFloat } from '../../../../util/Money/MoneyFunctions';

const DeviceTable = (props) => {
    const { deductInputTax, control, register, setSum } = props;

    const columns = React.useMemo(
        () =>
            deductInputTax
                ? [
                      ...fpt.proof.deviceList.columns,
                      ...fpt.proof.deviceList.columnsNet,
                  ]
                : [
                      ...fpt.proof.deviceList.columns,
                      ...fpt.proof.deviceList.columnsBru,
                  ],

        [deductInputTax]
    );

    const [editIndex, setEditIndex] = React.useState(-1);
    const [disabledAdd, setDisableAdd] = React.useState(false);
    const [isAddingNew, setIsAddingNew] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'deviceList',
    });

    const priceLabel = deductInputTax ? 'netPrice' : 'price';

    const calculateTotal = React.useCallback(
        (devices) => {
            return devices.reduce((total, device) => {
                const amount = parseFloat(device.amount);
                const price = parseInt(device[priceLabel]);

                return total + Math.round(amount * price);
            }, 0);
        },
        [priceLabel]
    );

    React.useEffect(
        () => setTotal(calculateTotal(fields)),
        [fields, priceLabel, calculateTotal]
    );

    React.useEffect(() => {
        setSum(total);
    }, [total, setSum]);

    const handleAddNew = React.useCallback(() => {
        setDisableAdd(true);
        setIsAddingNew(true);
    }, [setIsAddingNew]);

    const handleAddCancel = React.useCallback(() => {
        setDisableAdd(false);
        setIsAddingNew(false);
    }, [setDisableAdd, setIsAddingNew]);

    const handleAddSave = React.useCallback(
        (data) => {
            data.amount = strToFloat(data.amount);
            append({ id: uuid4(), ...data });
            handleAddCancel();
        },
        [handleAddCancel, append]
    );

    const handleEditStart = React.useCallback(
        (index) => {
            setEditIndex(index);
        },
        [setEditIndex]
    );

    const handleEditCancel = React.useCallback(() => {
        setEditIndex(-1);
    }, [setEditIndex]);

    const handleEditSave = React.useCallback(
        (data, deviceIndex) => {
            fields[deviceIndex] = { ...fields[deviceIndex], ...data };
            fields[deviceIndex].amount = strToFloat(fields[deviceIndex].amount);
            setTotal(calculateTotal(fields));
            setEditIndex(-1);
        },
        [fields, setEditIndex, setTotal, calculateTotal]
    );

    const handleRemoveDevice = React.useCallback(
        (deviceIndex) => {
            remove(deviceIndex);
        },
        [remove]
    );

    return (
        <Table size="small">
            <TableHead>{RenderTableHead({ columns })}</TableHead>
            <TableBody>
                {fields.length === 0 && <RenderEmptyRow colSpan={6} />}
                {fields.map((device, deviceIndex) =>
                    editIndex === deviceIndex ? (
                        <DeviceTableEditRow
                            key={device.id}
                            defaultValues={device}
                            index={deviceIndex}
                            deductInputTax={deductInputTax}
                            saveCallback={handleEditSave}
                            cancelCallback={handleEditCancel}
                        />
                    ) : (
                        <DeviceTableRow
                            key={device.id}
                            defaultValues={device}
                            index={deviceIndex}
                            deductInputTax={deductInputTax}
                            editCallback={handleEditStart}
                            deleteCallback={handleRemoveDevice}
                            register={register}
                        />
                    )
                )}

                {isAddingNew && (
                    <DeviceTableEditRow
                        index={fields.length}
                        cancelCallback={handleAddCancel}
                        deductInputTax={deductInputTax}
                        saveCallback={handleAddSave}
                    />
                )}
            </TableBody>
            <TableFooter>
                {RenderTableFooter({
                    disabled: disabledAdd,
                    handleAddNew,
                    total: total,
                })}
            </TableFooter>
        </Table>
    );
};

DeviceTable.propTypes = {
    index: PropTypes.number,
    deductInputTax: PropTypes.bool,
    control: PropTypes.object,
    register: PropTypes.func,
};

export default DeviceTable;
