export function fetchNineMutator(data) {
    const wskData = data[0].data;
    const moduleData = data[1].data;
    const fp = data[2].data;

    const { moduleOne } = moduleData;
    const { isValueChain } = wskData;

    const step1 = Math.ceil((fp.sumInvestOverall * fp.fundingRatio) / 100);

    let min, max;

    if (moduleOne) {
        min = 1700000;
    } else {
        min = 300000;
    }
    max = isValueChain ? 10000000 : 5000000;

    let step2 = step1;
    if (step1 < min) step2 = 0;
    if (step1 > max) step2 = max;

    const step3 = step2 > fp.maxDeminimis ? fp.maxDeminimis : step2;

    const self = fp.sumInvestOverall - step3;

    const fpData = {
        step3,
        self,
        sumInvestOverall: fp.sumInvestOverall,
        creditApproval: fp.creditApproval,
        fq: fp.fundingRatio,
        kfw: fp.kfwCreditApproval,
        bank: fp.creditBankName,
    };

    return { fpData };
}
