import { api } from '../AxiosConfig/Config';
import { Response } from '../../../util/DataTypes/Response';
import { useAppState } from '../../../Stores/AppState.store';
import { handleError } from '../../../util/Error/HandleError';

//import moment from 'moment';

export function getProposals() {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get('/proposals');

            resolve(data['hydra:member']);
        } catch (err) {
            reject(
                Response.Error('Es konnten keine Anträge geladen werden.', err)
            );
        }
    });
}

export function getProposalInfo(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(`/proposals/${proposalId}`);
                resolve(data);
            } catch (err) {
                reject(
                    Response.Error(
                        'Der Antrag konnte nicht geladen werden.',
                        err
                    )
                );
            }
        });
    };
}

export function createNewProposal(companyIri) {
    return new Promise(async (resolve, reject) => {
        try {
            //TODO: Async function
            const { data } = await api().post('/proposals', {
                company: companyIri,
            });

            const { getState } = useAppState;

            getState().setValidRaffleCreated(data);

            resolve(data);
        } catch (error) {
            reject(
                Response.Error(
                    'Beim Erstellen eines neuen Antrags ist ein Fehler aufgetreten',
                    error
                )
            );
        }
    });
}

export function finalizeInfo(proposalId) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(`/proposals/${proposalId}`);
            resolve(data);
        } catch (err) {
            reject(
                Response.Error('Der Antrag konnte nicht geladen werden.', err)
            );
        }
    });
}

export function fetchStep(step, mutator, errorMessages = {}) {
    return (proposalId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/${step}`
                );
                resolve(mutator(data));
            } catch (e) {
                reject(handleError(e, errorMessages));
            }
        });
    };
}

/*export function isAbleToCreateNewProposal() {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(`/proposals`);

            const validate = (element) => {
                if (element.currentStep <= 12) return true;
                return (
                    moment(Date.now()).diff(element.submittedAt, 'months') < 12
                );
            };

            const filtered = data['hydra:member'].filter((element) =>
                validate(element)
            );
            resolve(filtered.length === 0);
        } catch (e) {
            reject(
                Response.Error('Fehler beim laden der Antragsinformationen')
            );
        }
    });
}*/
