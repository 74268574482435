import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Controller } from 'react-hook-form';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InfoBalloon from './InfoBalloon';

const RadioButtons = (props) => {
    const {
        buttons,
        control,
        defaultValue,
        ariaLabel,
        className,
        groupLabel,
        name,
        disabled,
        rules,
        errors,
        onChangeExternal,
        ...rest
    } = props;

    return (
        <FormControl className={className} error={!_.isEmpty(errors)} fullWidth>
            <FormLabel>{groupLabel}</FormLabel>
            <Controller
                as={
                    <RadioGroup aria-label={ariaLabel}>
                        {buttons.map((button, idx) => (
                            <FormControlLabel
                                disabled={disabled || button.disabled}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        {button.label}
                                        {button.info && (
                                            <InfoBalloon info={button.info} />
                                        )}
                                    </>
                                }
                                value={button.value}
                                name={name}
                                onChange={onChangeExternal}
                                key={`${button.value}.${idx}`}
                            />
                        ))}
                    </RadioGroup>
                }
                name={name}
                control={control}
                defaultValue={defaultValue === undefined ? null : defaultValue}
                rules={rules}
                {...rest}
            />
            {errors && <FormHelperText>{errors.message}</FormHelperText>}
        </FormControl>
    );
};

RadioButtons.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    groupLabel: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    rules: PropTypes.object,
    errors: PropTypes.object,
    onChangeExternal: PropTypes.func,
};

RadioButtons.defaultProps = {
    defaultValue: undefined,
    disabled: false,
    rules: {},
    errors: {},
    onChangeExternal: () => {},
};

export default RadioButtons;
