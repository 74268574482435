export function isMoneyString(string) {
    if (string === '') {
        return false;
    }

    return /^\d{1,3}((\.\d{3})*|\d*)(,\d{1,2})?$/.test(string);
}

export function stringToCents(str) {
    if (!isMoneyString(str)) {
        return '';
    }

    const floatVal = strToFloat(str);

    if (isNaN(floatVal)) {
        return 0;
    }

    return Math.round(floatVal * 100);
}

export function centToString(val, empty = true) {
    if (val == null || val === '' || isNaN(val)) {
        return empty ? '' : '0,00';
    }

    return (val / 100).toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    });
}

export function moneyAdd(a, b, parse = false) {
    const valA = stringToCents(a);
    const valB = stringToCents(b);
    const total = valA + valB;

    return parse ? centToString(total) : total;
}

export function moneyMult(amount, times, parse = false) {
    const val = stringToCents(amount);
    let t = strToFloat(times);
    t = isNaN(t) ? 0 : t;
    const total = Math.round(val * t);

    if (parse) {
        return centToString(total, false);
    }

    return total;
}

export function toLocale(amount) {
    return strToFloat(amount).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
}

export function strToFloat(strValue) {
    if (strValue == null) {
        return 0;
    }

    return Number.parseFloat(strValue.replaceAll('.', '').replaceAll(',', '.'));
}
