import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

const RHFSelect = (props) => {
    const {
        control,
        name,
        error,
        rules,
        defaultValue,
        label,
        items,
        disabled,
        className,
    } = props;

    return (
        <FormControl
            error={!_.isEmpty(error)}
            disabled={disabled}
            fullWidth
            className={className}
        >
            <InputLabel>{label}</InputLabel>
            <Controller
                as={
                    <Select id={`${name}id`}>
                        <MenuItem value="" disabled>
                            Bitte auswählen
                        </MenuItem>
                        {items.map((item, idx) => (
                            <MenuItem
                                value={item.value}
                                key={`${item.value}.${idx}`}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                }
                name={name}
                control={control}
                defaultValue={!defaultValue ? '' : defaultValue}
                rules={rules}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

RHFSelect.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    error: PropTypes.object,
    defaultValue: PropTypes.any,
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

RHFSelect.defaultProps = {
    rules: {},
    defaultValue: null,
    error: {},
    disabled: false,
};

export default RHFSelect;
