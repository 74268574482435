import { Response } from '../../../util/DataTypes/Response';
import { fetchMutator, saveMutator } from './FinancingPlanMutators';
import { api } from '../AxiosConfig/Config';

/*const dummy = (data) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(data);
        }, 1000);
    });
*/
export function fetchFinancingPlanData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: digiData } = await api().get(
                    `/action/proposal/${proposalId}/step_6`
                );

                const { data: taxData } = await api().get(
                    `/action/proposal/${proposalId}/step_3`
                );

                const { data: financeData } = await api().get(
                    `/action/proposal/${proposalId}/step_8`
                );
                resolve(fetchMutator({ digiData, financeData, taxData }));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die Finanzierungsplan Daten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveFinancingPlanData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: mutated, media } = saveMutator(data);

            await api().put(
                `/action/proposal/${proposalId}/step_8`,
                {
                    proofs: mutated,
                },
                {
                    headers: {
                        'content-type': 'application/ld+json',
                    },
                }
            );

            media.map(async (m) => {
                try {
                    if (m.uri)
                        await api().patch(
                            m.uri.slice(4),
                            { keywords: m.keywords },
                            {
                                headers: {
                                    'content-type':
                                        'application/merge-patch+json',
                                },
                            }
                        );
                } catch (e) {
                    console.log(e);
                }
            });

            //await dummy(data);
            resolve(
                Response.Success(
                    'Die Finanzierungsdaten wurden erfolgreich gespeichert'
                )
            );
        } catch (e) {
            console.log(e);
            reject(
                Response.Error(
                    'Die Finanzierungsdaten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
