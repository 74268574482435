import React from 'react';

const useAsync = (asyncFunction, mountedRef, immediate = true) => {
    const [pending, setPending] = React.useState(immediate);
    const [value, setValue] = React.useState(null);
    const [error, setError] = React.useState(null);

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = React.useCallback(() => {
        setPending(true);
        setValue(null);
        setError(null);
        return asyncFunction()
            .then((response) =>
                mountedRef.current ? setValue(response) : null
            )
            .catch((error) => (mountedRef.current ? setError(error) : null))
            .finally(() => (mountedRef.current ? setPending(false) : null));
    }, [asyncFunction, mountedRef]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    React.useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return { execute, pending, value, error };
};

export default useAsync;
