import create from 'zustand';
import { produce } from 'immer';
import _ from 'lodash';
import { v4 as uuid4 } from 'uuid';

const initial = {
    proofs: [],
};

const emptyProof = {
    proofType: '',
    proofDescription: '',
    proofProvider: '',
    isNotBasicEquipment: false,
    hasModuleTwo: '',
    deviceNotSame: '',
    activityNotSame: '',
    deviceList: [],
    activityList: [],
    sumDevice: 0,
    sumActivity: 0,
    files: {
        device: [],
        certificate: [],
    },
};

const emptyDevice = {
    position: 0,
    description: '',
    posProof: '',
    explanation: '',
    isCombination: false,
    amount: '1',
    netPrice: '0',
    price: '0',
};

const emptyActivity = {
    position: 0,
    description: '',
    posProof: '',
    explanation: '',
    isCombination: false,
    amount: '0',
    netPrice: '0',
    price: '0',
    duration: '',
    participants: '',
};

function newProof(values = undefined) {
    if (values) return { ...values };
    return { ...emptyProof, id: uuid4() };
}

function newDevice(values = {}) {
    const obj = _.merge({ ...emptyDevice }, values);
    if (!obj.id) obj.id = uuid4();
    return obj;
}

function newActivity(values = {}) {
    const obj = _.merge({ ...emptyActivity }, values);
    if (!obj.id) obj.id = uuid4();
    return obj;
}

const stateAndActions = (set) => {
    return {
        ...initial,
        initialize: (proofs = []) =>
            set(
                produce((state) => {
                    state.proofs = proofs;
                })
            ),
        addProof: (values = undefined) =>
            set(
                produce((state) => {
                    state.proofs.push(newProof(values));
                })
            ),
        addDevice: (index, data = {}) =>
            set(
                produce((state) => {
                    state.proofs[index].deviceList.push(newDevice(data));
                })
            ),
        addActivity: (index, data = {}) =>
            set(
                produce((state) => {
                    state.proofs[index].activityList.push(newActivity(data));
                })
            ),
        removeDevice: (proof, index) =>
            set(
                produce((state) => {
                    state.proofs[proof].deviceList.splice(index, 1);
                })
            ),
        removeActivity: (proof, index) =>
            set(
                produce((state) => {
                    state.proofs[proof].activityList.splice(index, 1);
                })
            ),
        removeProof: (index) =>
            set(
                produce((state) => {
                    state.proofs.splice(index, 1);
                })
            ),
        editDevice: (proof, device, data) =>
            set(
                produce((state) => {
                    state.proofs[proof].deviceList[device] = _.merge(
                        state.proofs[proof].deviceList[device],
                        data
                    );
                })
            ),
        editActivity: (proof, activity, data) =>
            set(
                produce((state) => {
                    state.proofs[proof].activityList[activity] = _.merge(
                        state.proofs[proof].activityList[activity],
                        data
                    );
                })
            ),
        editProof: (proof, data, key = undefined) =>
            set(
                produce((state) => {
                    if (key) {
                        state.proofs[proof][key] = data;
                        return;
                    }
                    state.proofs[proof] = _.assign(state.proofs[proof], data);
                })
            ),
    };
};

export const useStore = create((set) => ({
    ...stateAndActions(set),
}));
