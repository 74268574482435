import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import RenderMinimiNode from './RenderMinimiNode';
import { centToString } from '../../../../../util/Money/MoneyFunctions';

const MinimisTreeview = (props) => {
    const { data } = props;

    const renderShareholder = (type, idx) => {
        if (
            (type === 'requested' && data.minimiFields.requested) ||
            (type === 'received' && data.minimiFields.received)
        )
            return (
                <TreeItem
                    key={`${type}_${idx}`}
                    nodeId={`${type}_${idx}`}
                    label={
                        type === 'requested'
                            ? 'Beantragte Beihilfen'
                            : 'Erhaltene Beihilfen'
                    }
                >
                    {Object.keys(data.fields.shareholder[type]).map(
                        (company) => (
                            <TreeItem
                                nodeId={`${company}_${type}`}
                                label={company}
                                key={`${company}_${type}`}
                            >
                                {Object.keys(
                                    data.fields.shareholder[type][company]
                                ).map((year) => (
                                    <TreeItem
                                        nodeId={`${year}_${company}_${type}`}
                                        label={year}
                                        key={`${year}_${company}_${type}`}
                                    >
                                        {data.fields.shareholder[type][company][
                                            year
                                        ].map((minimi) => (
                                            <RenderMinimiNode
                                                minimi={minimi}
                                                key={minimi.id}
                                            />
                                        ))}
                                    </TreeItem>
                                ))}
                            </TreeItem>
                        )
                    )}
                </TreeItem>
            );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <TreeItem nodeId="1" label="Übersicht">
                <TreeItem
                    nodeId="2"
                    label={`De-minimis Beihilfen erhalten: ${
                        data.minimiFields.received ? 'Ja' : 'Nein'
                    }`}
                />
                <TreeItem
                    nodeId="3"
                    label={`De-minimis Beihilfen beantragt: ${
                        data.minimiFields.requested ? 'Ja' : 'Nein'
                    }`}
                />
                <TreeItem
                    nodeId="4"
                    label={`Summe der Beihilfen: ${centToString(
                        data.minimiFields.sum
                    )} €`}
                />
            </TreeItem>

            {data.minimiFields.received && (
                <TreeItem nodeId="received" label="Erhaltene Beihilfen">
                    {Object.keys(data.fields.received).map((key, idx) => (
                        <TreeItem
                            key={`${key}_${idx}`}
                            nodeId={`${key}_${idx}`}
                            label={key}
                        >
                            {data.fields.received[key].map((minimi) => (
                                <RenderMinimiNode
                                    minimi={minimi}
                                    key={minimi.id}
                                />
                            ))}
                        </TreeItem>
                    ))}
                </TreeItem>
            )}

            {data.minimiFields.requested && (
                <TreeItem nodeId="requested" label="Beantragte Beihilfen">
                    {Object.keys(data.fields.requested).map((key, idx) => (
                        <TreeItem
                            key={`${key}_${idx}`}
                            nodeId={`${key}_${idx}`}
                            label={key}
                        >
                            {data.fields.requested[key].map((minimi) => (
                                <RenderMinimiNode
                                    minimi={minimi}
                                    key={minimi.id}
                                />
                            ))}
                        </TreeItem>
                    ))}
                </TreeItem>
            )}

            {data.shareholderCompanies.length > 0 && (
                <TreeItem
                    nodeId="shareholderCompanies"
                    label="Verbundene Unternehmen"
                >
                    {Object.keys(data.fields.shareholder).map((type, idx) =>
                        renderShareholder(type, idx)
                    )}
                </TreeItem>
            )}
        </TreeView>
    );
};

MinimisTreeview.propTypes = {
    data: PropTypes.object,
};

export default MinimisTreeview;
