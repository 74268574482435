import { steps } from '../../config/WizardSteps';
import { Response, TYPE } from '../DataTypes/Response';
import { handleError } from '../Error/HandleError';
import { transitionTexts } from '../../texts/Components/TransitionTexts';

function getIndex(message) {
    return parseInt(message.split(/_/).pop());
}

function parseType(message) {
    switch (message) {
        case 'to_step_11':
        case 'to_step_12':
        case 'back_to_step_11':
        case 'back_to_step_12':
            return TYPE.SUCCESS;
        default:
            return TYPE.INFO;
    }
}

function parseMessage(message) {
    const isBack = message.indexOf('back_to') === 0;
    const index = getIndex(message);

    if (index < 0 || index > steps.length) {
        return transitionTexts.success.changed;
    }

    switch (index) {
        case 11:
            return transitionTexts.success.submitConfirm;
        case 12:
            return transitionTexts.success.confirm;
        default:
            return `Erfolgreich ${isBack ? 'zurück ' : ''}zu "${
                steps[index].name
            }" gewechselt.`;
    }
}

export function parseSuccessMessage(message) {
    return new Response(parseMessage(message), undefined, parseType(message));
}

function getSubmitError(error) {
    return handleError(error, transitionTexts.submitError);
}

export function getWizardRejectMessage(toStep, back, error) {
    if (toStep === 'to_step_11') {
        return getSubmitError(error);
    }

    const stepName = steps[getIndex(toStep)].name;
    const backString = back ? 'zurück ' : '';

    return handleError(error, {
        generic: `Es konnte nicht ${backString}zu "${stepName}" gewechselt werden.`,
        412: `Sie müssen den aktuellen Schritt fertigstellen, um in "${stepName}" zu wechseln.`,
        500: `Beim Wechsel ${backString}zu "${stepName}" ist ein Fehler aufgetreten.`,
        502: `Beim Wechsel ${backString}zu "${stepName}" kam es zu einer Zeitüberschreitung.`,
        504: `Beim Wechsel ${backString}zu "${stepName}" kam es zu einer Zeitüberschreitung.`,
    });
}
