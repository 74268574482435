import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const LinkOtherPage = (props) => {
    const { href, children } = props;

    return (
        <Link href={href} target="_blank" rel="noreferrer">
            {children}
        </Link>
    );
};

LinkOtherPage.propTypes = {
    href: PropTypes.string,
    children: PropTypes.any,
};

export default LinkOtherPage;
