import React from 'react';
import PropTypes from 'prop-types';
import {
    centToString,
    moneyMult,
    stringToCents,
} from '../../../../util/Money/MoneyFunctions';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { validateDeviceListRow } from '../../../../util/Validation/FinancingPlanValidation';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ListFieldMax from '../Components/ListFieldMax';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';

const useStyles = makeStyles((theme) => ({
    hideBorderBottom: {
        '& td': {
            borderBottom: 0,
        },
    },
}));

const DeviceTableEditRow = (props) => {
    const {
        index,
        deductInputTax,
        saveCallback,
        defaultValues,
        cancelCallback,
    } = props;
    const classes = useStyles();

    const descriptionRef = React.useRef();
    const posProofRef = React.useRef();
    const explanationRef = React.useRef();
    const isCombinationRef = React.useRef();
    const amountRef = React.useRef();
    const priceRef = React.useRef();
    const priceLabel = deductInputTax ? 'netPrice' : 'price';
    const [total, setTotal] = React.useState(
        centToString(
            Math.round(
                parseFloat(defaultValues.amount) *
                    parseInt(defaultValues[priceLabel])
            ),
            false
        ) + ' €'
    );
    const [errors, setErrors] = React.useState({});

    const handleChange = (evt) => {
        const name = evt.target.name;
        if (errors[name]) {
            setErrors((state) => {
                const tmp = { ...state };
                delete tmp[name];
                return tmp;
            });
        }
    };

    const handleTotalChange = (evt) => {
        const amount = amountRef.current.value;
        const price = priceRef.current.value;

        setTotal(`${moneyMult(price, amount, true)} €`);
        handleChange(evt);
    };

    const handleSave = () => {
        setErrors({});

        const obj = {
            description: descriptionRef.current.value,
            posProof: posProofRef.current.value,
            explanation: explanationRef.current.value,
            isCombination: isCombinationRef.current.checked,
            amount: amountRef.current.value,
            [priceLabel]: stringToCents(priceRef.current.value),
        };

        const validation = validateDeviceListRow(obj, priceLabel);
        if (typeof validation === 'boolean') {
            saveCallback(obj, index);
            return;
        }

        setErrors(validation);
    };

    return (
        <>
            <TableRow className={classes.hideBorderBottom}>
                <TableCell />
                <TableCell style={{ verticalAlign: 'top' }}>
                    <div
                        style={{
                            lineHeight: '40px',
                            display: 'block',
                        }}
                    >
                        {index + 1}
                    </div>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    <ListFieldMax
                        size="small"
                        ref={descriptionRef}
                        variant="outlined"
                        name="description"
                        changeCallback={handleChange}
                        error={!!errors.description}
                        defaultValue={defaultValues.description}
                        max={200}
                        fullWidth
                        autoFocus
                        placeholder={
                            fpt.proof.deviceList.columns[2].placeholder
                        }
                        inputProps={{ maxLength: 200 }}
                    />
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    <TextField
                        size="small"
                        inputRef={amountRef}
                        variant="outlined"
                        onChange={handleTotalChange}
                        name="amount"
                        error={!!errors.amount}
                        helperText={errors.amount}
                        defaultValue={parseFloat(
                            defaultValues.amount || '1'
                        ).toLocaleString('de-DE', { maximumFractionDigits: 4 })}
                    />
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    <TextField
                        size="small"
                        inputRef={priceRef}
                        variant="outlined"
                        onChange={handleTotalChange}
                        name={priceLabel}
                        error={!!errors[priceLabel]}
                        helperText={
                            !!errors[priceLabel]
                                ? 'Bitte geben Sie nur Zahlen ein.'
                                : undefined
                        }
                        defaultValue={centToString(defaultValues[priceLabel])}
                        placeholder="0,00"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    €
                                </InputAdornment>
                            ),
                        }}
                    />
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    <div
                        style={{
                            lineHeight: '40px',
                            display: 'block',
                            textAlign: 'right',
                        }}
                    >
                        {total}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={classes.hideBorderBottom}>
                <TableCell />
                <TableCell colSpan={4}>
                    <ListFieldMax
                        size="small"
                        ref={posProofRef}
                        variant="outlined"
                        name="posProof"
                        changeCallback={handleChange}
                        error={!!errors.posProof}
                        defaultValue={defaultValues.posProof}
                        max={100}
                        fullWidth
                        label={fpt.proof.deviceList.columnsOther.posProof}
                        inputProps={{ maxLength: 100 }}
                    />
                </TableCell>
                <TableCell />
            </TableRow>
            <TableRow className={classes.hideBorderBottom}>
                <TableCell />
                <TableCell colSpan={4}>
                    <ListFieldMax
                        size="small"
                        ref={explanationRef}
                        variant="outlined"
                        name="explanation"
                        changeCallback={handleChange}
                        error={!!errors.explanation}
                        defaultValue={defaultValues.explanation}
                        max={600}
                        fullWidth
                        label={fpt.proof.deviceList.columnsOther.description}
                        inputProps={{ maxLength: 600 }}
                    />
                </TableCell>
                <TableCell />
            </TableRow>
            <TableRow className={classes.hideBorderBottom}>
                <TableCell />
                <TableCell colSpan={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputRef={isCombinationRef}
                                defaultChecked={
                                    defaultValues.isCombination === 'true' ||
                                    defaultValues.isCombination === true
                                }
                                color="primary"
                            />
                        }
                        label={fpt.proof.deviceList.columnsOther.combination}
                    />
                </TableCell>
                <TableCell />
            </TableRow>

            <TableRow>
                <TableCell />
                <TableCell colSpan={5}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSave}
                    >
                        Speichern
                    </Button>
                    <Button
                        style={{ marginLeft: '16px' }}
                        variant="outlined"
                        onClick={cancelCallback}
                    >
                        Abbrechen
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

DeviceTableEditRow.propTypes = {
    index: PropTypes.number,
    deductInputTax: PropTypes.bool,
    defaultValues: PropTypes.object,
    saveCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
};

DeviceTableEditRow.defaultProps = {
    defaultValues: {},
};

export default DeviceTableEditRow;
