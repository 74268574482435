import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './ErrorPages/NotFound';
import Layout from '../components/Layout/Layout';
import RegisterFormContainer from '../container/Register/RegisterFormContainer';
import RegisterValidateContainer from '../container/Register/RegisterValidateContainer';
import DashboardContainer from '../container/Dashboard/DashboardContainer';
import LogoutContainer from '../container/Login/LogoutContainer';
import ProposalRoutes from './Proposal/ProposalRoutes';
import PrivacyPolicy from './Static/PrivacyPolicy';
import Impressum from './Static/Impressum';
import ProposalOverviewContainer from '../container/Proposal/ProposalOverviewContainer';
import { ErrorBoundary } from 'react-error-boundary';
import errorFallback from './ErrorPages/Critical';
import ProposalSubmittedContainer from '../container/Proposal/ProposalSubmittedContainer';
import { useAppState } from '../Stores/AppState.store';
import RaffleContainer from '../container/Raffle/RaffleContainer';
import VerificationRoutes from './Verification/VerificationRoutes';
import { useAuth } from '../Stores/Auth.store';
import PrivateRoute from '../components/Partials/PrivateRoute/PrivateRoute';
import Faq from './Static/Faq';
import ResubmitInvoicesContainer from '../container/ResubmitInvoices/ResubmitInvoicesContainer';

const PageRouter = () => {
    const user = useAuth((state) => state.user);
    const isAuthenticated = !!user;
    const isOpen = useAppState((state) => state.status.register.isOpen);

    return (
        <Layout isAuthenticated={isAuthenticated}>
            <Switch>
                <PrivateRoute path={'/antrag'}>
                    <ProposalRoutes />
                </PrivateRoute>
                <PrivateRoute path={'/uebersicht/:proposalId'}>
                    <ProposalOverviewContainer />
                </PrivateRoute>
                <PrivateRoute path={'/eingereicht/:proposalId'}>
                    <ProposalSubmittedContainer />
                </PrivateRoute>
                <PrivateRoute path={'/los'}>
                    <RaffleContainer />
                </PrivateRoute>
                <PrivateRoute path={'/vn'}>
                    <VerificationRoutes />
                </PrivateRoute>
                <PrivateRoute path={'/nachreichen/:vnId'}>
                    <ResubmitInvoicesContainer />
                </PrivateRoute>
                <Route path="/abmelden">
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <LogoutContainer />
                    </ErrorBoundary>
                </Route>
                <Route path="/registrieren" exact>
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <RegisterFormContainer open={isOpen} />
                    </ErrorBoundary>
                </Route>
                <Route path="/benutzerkonto-bestaetigen/:token">
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <RegisterValidateContainer />
                    </ErrorBoundary>
                </Route>
                <Route path="/" exact>
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <DashboardContainer />
                    </ErrorBoundary>
                </Route>
                <Route path="/datenschutz">
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <PrivacyPolicy />
                    </ErrorBoundary>
                </Route>
                <Route path="/impressum">
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <Impressum />
                    </ErrorBoundary>
                </Route>
                <Route path="/faq">
                    <ErrorBoundary FallbackComponent={errorFallback}>
                        <Faq />
                    </ErrorBoundary>
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Layout>
    );
};

export default PageRouter;
