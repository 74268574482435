import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { mutateFetch, saveMutator } from './VerificationProofMutators';
import { client } from '../../../QueryClient';
import { fetchVerificationProofs } from '../Verification/VerificationActions';
import { generateProofs } from '../Verification/VerificationMutator';

export function updateVerificationProofs(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const verificationProofs = await fetchVerificationProofs(id);
            let approvedData = client.getQueryData([
                'verificationApprovedData',
                id,
            ]);
            if (!approvedData) {
                const { data: updatedApprovedData } = await api().get(
                    `/verifications/${id}/approved-data`
                );
                client.setQueryData(
                    ['verificationApprovedData', id],
                    updatedApprovedData
                );
                approvedData = updatedApprovedData;
            }

            const proofs = generateProofs(
                verificationProofs['hydra:member'],
                approvedData.proofs || []
            );

            resolve(proofs);
        } catch (e) {
            reject(
                Response.Error(
                    'Die Nachweise konnten nicht aktualisiert werden.',
                    e
                )
            );
        }
    });
}

export function createInvoice(id, proofId, mediaIri, resubmitInvoices = false) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: response } = await api().put(
                `/verification_proof_invoices/${id}`,
                {
                    verificationProof: `/api/verification_proofs/${proofId}`,
                    media: mediaIri,
                    resubmitted: resubmitInvoices,
                }
            );

            resolve(mutateFetch(response));
        } catch (e) {
            reject(
                Response.Error('Die Rechnung konnte nicht angelegt werden.', e)
            );
        }
    });
}

export function saveInvoiceData(id, data, verificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            const headers = {
                'content-type': 'application/merge-patch+json',
            };
            await api().patch(
                `/verification_proof_invoices/${id}`,
                saveMutator(data),
                { headers }
            );

            resolve(Response.Success('Rechnung erfolgreich gespeichert'));
        } catch (e) {
            let errorMessage = 'Die Rechnung konnte nicht gespeichert werden.';

            const violations = e?.response?.data?.violations;
            if (violations?.length > 0) {
                const errorFields = [];
                violations.forEach((violation) => {
                    errorFields.push(
                        violation?.propertyPath?.replace('meta.', '')
                    );
                });
                errorMessage += ` (${errorFields.join(', ')})`;
            }

            reject(Response.Error(errorMessage, e));
        }
    });
}

export function deleteInvoice(id, verificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().delete(`/verification_proof_invoices/${id}`);

            resolve(
                Response.Success('Die Rechnung wurde erfolgreich gelöscht.')
            );
        } catch (e) {
            reject(
                Response.Error('Die Rechnung konnte nicht gelöscht werden', e)
            );
        }
    });
}

export function setNoInvoice(id, data, verificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            const headers = {
                'content-type': 'application/merge-patch+json',
            };
            await api().patch(`/verification_proofs/${id}`, data, { headers });

            resolve(Response.Success('Nachweis erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error('Speichern des Nachweises nicht möglich.', e)
            );
        }
    });
}

export function fetchProof(verificationId, proofId) {
    return new Promise(async (resolve, reject) => {
        try {
            let { data: response } = await api().get(
                `/verification_proofs/${proofId}`
            );

            let approvedData = client.getQueryData([
                'verificationApprovedData',
                verificationId,
            ]);

            if (!approvedData) {
                // approvedData no longer in cache - fetch and cache again
                const { data: updatedApprovedData } = await api().get(
                    `/verifications/${verificationId}/approved-data`
                );

                client.setQueryData(
                    ['verificationApprovedData', verificationId],
                    updatedApprovedData
                );
                approvedData = updatedApprovedData;
            }

            const approvedDataFields = approvedData.proofs.find(
                (aD) => aD.id === proofId
            );
            if (approvedDataFields) {
                response = {
                    ...response,
                    ...approvedDataFields,
                };
            }

            response.invoices.map((invoice) => mutateFetch(invoice));
            resolve(response);
        } catch (e) {
            reject(
                Response.Error(
                    'Die Rechnungen konnten nicht geladen werden.',
                    e
                )
            );
        }
    });
}
