import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator, saveMutator } from './DigitalOneMutators';

export const req = {
    0: [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11],
    1: [0, 3, 4, 5, 6, 7, 9, 10, 11],
    2: [0, 4, 5, 6, 7, 9, 10, 11],
    3: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
    4: [0, 4, 5, 6, 7, 9, 10, 11],
    5: [0, 7, 9, 10, 11],
    6: [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11],
    7: [0, 4, 5, 6, 7, 9, 10, 11],
    8: [0, 4, 5, 6, 7, 9, 10, 11],
    9: [0, 5, 6, 7, 9, 10, 11],
    10: [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11],
    11: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
};

export function fetchDigitalOneFormData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            let wskResult = undefined;
            try {
                wskResult = await api().get(
                    `/action/proposal/${proposalId}/step_0`
                );

                const dResult = await api().get(
                    `/action/proposal/${proposalId}/step_5`
                );

                const results = [dResult, wskResult];
                const mutated = fetchMutator(results);

                resolve(mutated);
            } catch (e) {
                const url = e?.config?.url;
                const status = e?.response?.status;

                if (
                    url === `/action/proposal/${proposalId}/step_5` &&
                    status === 422 &&
                    Boolean(wskResult)
                ) {
                    resolve(fetchMutator([{ data: undefined }, wskResult]));
                    return;
                }
                reject(
                    Response.Error(
                        'Digitalisierungsplan 1 Daten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveDigitalOneFormData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            const mutated = saveMutator(data);
            await api().put(`/action/proposal/${proposalId}/step_5`, mutated);
            resolve(
                Response.Success(
                    'Digitalisierungsplan 1 erfolgreich gespeichert'
                )
            );
        } catch (e) {
            reject(
                Response.Error(
                    'Digitalisierungsplan 1 konnte nicht gespeichert werden'
                ),
                e
            );
        }
    });
}
