import React from 'react';
import { Container, Grid } from '@material-ui/core';

import digitalJetzt from '../../../static/images/BMWK_Logo_Digital_Jetzt_RGB_RZ.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        minHeight: '150px',
        '& div': {
            height: '100%',
        },
    },
    img: {
        height: '150px',
        'object-fit': 'cover',
        width: '350px',
        'margin-left': '-40px',
    },
}));

const Header = () => {
    const classes = useStyles();
    return (
        <Container role="banner">
            <Grid container className={classes.header}>
                <Grid item xs={12} sm="auto">
                    <Link to={'/'}>
                        <img
                            src={digitalJetzt}
                            alt="Digital Jetzt Logo"
                            className={classes.img}
                        />
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Header;
