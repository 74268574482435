import React from 'react';
import Paragraph from './Paragraph';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { parseToJsx } from './parseToJsx';
import uniqid from 'uniqid';

const TextBlock = (props) => {
    const {
        textBlock,
        headlineComponent,
        headlineVariant,
        paragraphProps,
        paragraphRootComponent,
        listProps,
        listRootComponent,
        parserOptions,
    } = props;

    const paragraphs = [];

    textBlock.paragraphs.forEach((paragraph) => {
        const fragments = parseToJsx(paragraph, parserOptions);
        paragraphs.push(fragments);
    });

    const Headline = headlineComponent;

    return (
        <>
            {textBlock.headline && (
                <Headline variant={headlineVariant} gutterBottom>
                    {textBlock.headline}
                </Headline>
            )}
            {paragraphs.map((fragment, idx) => {
                switch (textBlock.paragraphs[idx].type) {
                    case 'list':
                        return (
                            <Paragraph
                                as={listRootComponent}
                                {...listProps}
                                key={uniqid()}
                                paragraph={fragment}
                            />
                        );
                    default:
                        return (
                            <Paragraph
                                as={paragraphRootComponent}
                                key={uniqid()}
                                {...paragraphProps}
                                paragraph={fragment}
                            />
                        );
                }
            })}
        </>
    );
};

TextBlock.propTypes = {
    textBlock: PropTypes.object.isRequired,
    headlineComponent: PropTypes.elementType,
    headlineVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    paragraphRootComponent: PropTypes.elementType,
    paragraphProps: PropTypes.object,
    listRootComponent: PropTypes.elementType,
    listProps: PropTypes.object,
    parserOptions: PropTypes.object,
};

TextBlock.defaultProps = {
    headlineComponent: Typography,
    headlineVariant: 'h2',
    paragraphRootComponent: Typography,
    listRootComponent: Typography,
    paragraphProps: { variant: 'body1', component: 'p' },
    listProps: { component: 'ul' },
};

export default React.memo(TextBlock);
