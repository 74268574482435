import React from 'react';
import { Button } from '@material-ui/core';
import { useProposalPost } from '../../../hooks/Proposal/useProposalPost';
import { useAuth } from '../../../Stores/Auth.store';
import { useSnackbar } from 'notistack';
import { useAppState } from '../../../Stores/AppState.store';
import moment from 'moment';
import { client } from '../../../QueryClient';
import { canCreateNewProposal } from '../../../util/Proposal/PropsalCanCreate';

const ProposalOverviewCreate = () => {
    const { enqueueSnackbar } = useSnackbar();
    const user = useAuth((state) => state.user);
    const { raffles, setRaffles } = useAppState((state) => ({
        raffles: state.raffles,
        setRaffles: state.setRaffles,
    }));

    const handleSuccess = (response) => {
        const oldProposals = client.getQueryData(['proposals']) || [];
        client.setQueryData(['proposals'], [...oldProposals, { ...response }]);

        const newRaffles = [...raffles];
        const index = newRaffles.findIndex(
            (el) => el.valid && moment(el.rafflePot.submitEnd) > moment()
        );
        newRaffles[index].proposal = { ...response };
        setRaffles(newRaffles);
        enqueueSnackbar('Antrag erfolgreich erstellt.', { variant: 'success' });
    };

    const handleError = (result) => {
        enqueueSnackbar(result.message(), { variant: result.type() });
    };

    const { mutate, isLoading } = useProposalPost({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    const companyIri = React.useMemo(() => {
        return user.companies[0];
    }, [user]);

    const canCreate = React.useMemo(() => {
        return canCreateNewProposal(raffles);
    }, [raffles]);

    if (!canCreate) return null;

    return (
        <Button
            onClick={() => mutate({ companyIri })}
            color="primary"
            variant="contained"
            disableElevation
            disabled={isLoading}
        >
            Neuen Antrag Erstellen
        </Button>
    );
};

export default ProposalOverviewCreate;
