export function fetchEightMutator(data) {
    const deductInputTax = data[0].data.companyType.isDeductInputTax;
    const proofs = data[1].data.proofs;

    const total = proofs.reduce(
        (acc, cur) => acc + parseInt(cur.sumActivity) + parseInt(cur.sumDevice),
        0
    );

    return { deductInputTax, proofs, total: '' + total };
}
