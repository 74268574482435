import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const FormSkelleton = (props) => {
    const { title } = props;

    return (
        <>
            {title ? (
                <Typography component="h1" variant="h4">
                    {title}
                </Typography>
            ) : (
                <Typography
                    component="h1"
                    variant="h3"
                    style={{ width: '33%' }}
                >
                    <Skeleton animation="wave" />
                </Typography>
            )}

            {Array.apply(null, Array(5)).map((el, idx) => (
                <Skeleton
                    width="100%"
                    key={`formSkell_${idx}`}
                    animation="wave"
                >
                    <TextField key={`inputSkepp_${idx}`} />
                </Skeleton>
            ))}
        </>
    );
};

FormSkelleton.propTypes = {
    title: PropTypes.string,
};

export default FormSkelleton;
