import React from 'react'; //eslint-disable-line
import PropTypes from 'prop-types';

const TabPanel = (props) => {
    const { activeTab, index, children } = props;

    if (activeTab !== index) return null;

    return children;
};

TabPanel.propTypes = {
    activeTab: PropTypes.number,
    index: PropTypes.number,
};

export default TabPanel;
