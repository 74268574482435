import { useQuery } from 'react-query';
import { getProposals } from '../../lib/api/Proposal/ProposalActions';

export function useProposals(options = {}) {
    return useQuery(['proposals'], () => getProposals(), {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        ...options,
    });
}
