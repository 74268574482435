import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { parseToString } from './MinimisSum';

const MinimisTableRow = (props) => {
    const { values, onDeleteRow, onStartRowEdit, disabled, showDate } = props;

    const formatAmount = (amount) =>
        parseToString(parseInt(amount.replace(',', '').replaceAll('.', '')));

    return (
        <TableRow>
            <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                <IconButton
                    size="small"
                    onClick={onStartRowEdit}
                    disabled={disabled}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={onDeleteRow}
                    disabled={disabled}
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            {showDate && (
                <TableCell>
                    {values.executionDate
                        ? moment(values.executionDate).format('DD.MM.yyyy')
                        : ''}
                </TableCell>
            )}
            <TableCell>{values.provider}</TableCell>
            <TableCell>{values.fkz}</TableCell>
            <TableCell>{values.type}</TableCell>
            <TableCell>{formatAmount(values.fundingAmount)}</TableCell>
            <TableCell>{formatAmount(values.investmentAmount)}</TableCell>
        </TableRow>
    );
};

MinimisTableRow.propTypes = {
    values: PropTypes.object,
    onStartRowEdit: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

MinimisTableRow.defaultProps = {
    values: {},
    disabled: false,
};

export default MinimisTableRow;
