import React from 'react';
import { Container } from '@material-ui/core';
import NavbarButton from './NavbarButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getUserState, UserState } from '../../../util/Helper/UserState';
import { pages } from '../../../config/Navigation';
import { useAuth } from '../../../Stores/Auth.store';
import { useMobileLayout } from '../../../hooks/useMobileLayout';
import MobileNavigation from './MobileNavigation';
import moment from 'moment/moment';

const useStyles = makeStyles({
    nav: {
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            '&:last-child': {
                marginLeft: 'auto',
            },
        },
    },
});

const AuthNavList = () => {
    const user = useAuth((state) => state.user);
    const classes = useStyles();
    const show = useMobileLayout();

    return (
        <Container className={classes.nav}>
            {!show &&
                pages.map((el, id) => {
                    if (
                        parseInt(moment().format('YYYY')) > 2023 &&
                        el.href === '/los'
                    ) {
                        return null;
                    }
                    return (
                        <NavbarButton
                            href={el.href}
                            match={el.match}
                            enabled={
                                getUserState(user) === UserState.ACTIVE ||
                                id === 0 ||
                                id === pages.length - 1
                            }
                            title={el.label}
                            key={id}
                        />
                    );
                })}

            <MobileNavigation isAuthenticated={!!user} />
        </Container>
    );
};

export default AuthNavList;
