import { fetchMutator, saveMutator } from './FinancingPlan2Mutators';
import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';

export function fetchFinancingPlan2Data(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const promise1 = api().get(
                    `/action/proposal/${proposalId}/step_9`
                );
                const promise2 = api().get(
                    `/action/proposal/${proposalId}/step_0`
                );
                const promise3 = api().get(
                    `action/proposal/${proposalId}/step_6`
                );

                const data = await Promise.all([promise1, promise2, promise3]);

                resolve(fetchMutator(data));
            } catch (e) {
                console.log(e);
                reject(
                    Response.Error(
                        'Finanzplan 2 Daten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveFinancingPlan2Data(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_9`,
                saveMutator(data)
            );
            resolve(
                Response.Success('Finanzplan 2 Daten erfolgreich gespeichert')
            );
        } catch (e) {
            reject(
                Response.Error(
                    'Finanzplan 2 Daten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
