import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { texts as dft } from '../../../texts/Forms/Digi1FormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import SectorPicker from './SectorPicker';
import TextFieldMax from '../../Partials/Form/TextFieldMax';
import Section from './Section';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        marginBottom: theme.spacing(2),
    },
}));

const DigiOneForm = (props) => {
    const { defaultValues, formId, onSubmit } = props;
    const classes = useStyles();
    const {
        handleSubmit,
        register,
        errors,
        unregister,
        setError,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });

    const [isOther, setIsOther] = React.useState(false);

    const [sections, setSections] = React.useState({
        strategy: true,
        products: true,
        communication: true,
        process: true,
        technology: true,
        security: true,
        external: defaultValues.isValueChain || false,
        competence: true,
    });

    React.useEffect(() => {
        setSections((state) => ({
            ...state,
            external: defaultValues.isValueChain,
        }));
        setIsOther(defaultValues.isOther);
    }, [defaultValues, setSections]);

    const handleSectorChange = React.useCallback(
        (id, checked, other) => {
            clearErrors('initialBusinessUnits');
            if (other) setIsOther(checked);
        },
        [clearErrors]
    );
    const submitCallback = (values) => {
        if (
            !_.reduce(
                values.initialBusinessUnits,
                (acc, cur) => {
                    return acc || cur === true;
                },
                false
            )
        ) {
            setError('initialBusinessUnits', {
                type: 'manual',
                message: 'Es muss mindestens ein Bereich ausgewählt werden',
            });
            return;
        }

        onSubmit({ ...values, '@id': defaultValues['@id'] });
    };

    return (
        <form
            id={formId}
            onSubmit={(e) => {
                clearErrors();
                handleSubmit(submitCallback)(e);
            }}
            style={{ paddingBottom: '16px' }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {dft.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {dft.desc}
            </Typography>
            <ul>
                <Typography component="li" className={classes.heading}>
                    {dft.bullet1.label}{' '}
                    <Typography variant="subtitle2">
                        {dft.bullet1.info}
                    </Typography>
                </Typography>
                <Typography component="li" className={classes.heading}>
                    {dft.bullet2.label}{' '}
                    <Typography variant="subtitle2">
                        {dft.bullet2.info}
                    </Typography>
                </Typography>
                <Typography component="li" className={classes.heading}>
                    {dft.bullet3.label}{' '}
                    <Typography variant="subtitle2">
                        {dft.bullet3.info}
                    </Typography>
                </Typography>
            </ul>
            <Alert className={classes.mt} severity="info">
                {dft.disclosure}
            </Alert>
            <Typography
                className={classes.mt}
                variant="h6"
                component="h2"
                gutterBottom
            >
                {dft.initialSituation.title}
            </Typography>
            <Typography gutterBottom>{dft.initialSituation.desc}</Typography>
            {errors.initialBusinessUnits && (
                <FormHelperText error={true}>
                    {errors.initialBusinessUnits.message}
                </FormHelperText>
            )}
            <SectorPicker
                register={register}
                onChange={handleSectorChange}
                defaultChecked={defaultValues.initialBusinessUnits}
            />
            {isOther && (
                <TextFieldMax
                    name="otherBusinessUnit"
                    register={register}
                    maxLength={dft.initialSituation.otherMax}
                    label={dft.initialSituation.other}
                    defaultValue={defaultValues.otherBusinessUnit}
                    rules={{ required: dft.initialSituation.otherError }}
                    error={errors.otherBusinessUnit}
                />
            )}

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.strategie.title}
                questions={[dft.questions['0']]}
                description={dft.sections.strategie.desc}
                tooltip={dft.sections.strategie.tooltip}
                errors={errors}
                open={sections.strategy}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.products.title}
                questions={[dft.questions['1'], dft.questions['2']]}
                description={dft.sections.products.desc}
                tooltip={dft.sections.products.tooltip}
                errors={errors}
                open={sections.products}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.communcation.title}
                questions={[dft.questions['3']]}
                description={dft.sections.communcation.desc}
                tooltip={dft.sections.communcation.tooltip}
                errors={errors}
                open={sections.communication}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.process.title}
                questions={[dft.questions['4']]}
                description={dft.sections.process.desc}
                tooltip={dft.sections.process.tooltip}
                errors={errors}
                open={sections.process}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.technology.title}
                questions={[dft.questions['5'], dft.questions['6']]}
                description={dft.sections.technology.desc}
                tooltip={dft.sections.technology.tooltip}
                errors={errors}
                open={sections.technology}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.itsec.title}
                questions={[dft.questions['7']]}
                description={dft.sections.itsec.desc}
                tooltip={dft.sections.itsec.tooltip}
                errors={errors}
                open={sections.security}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.external.title}
                questions={[dft.questions['8']]}
                description={dft.sections.external.desc}
                tooltip={dft.sections.external.tooltip}
                errors={errors}
                open={sections.external}
                defaultValues={defaultValues}
            />

            <Section
                register={register}
                unregister={unregister}
                title={dft.sections.competence.title}
                questions={[
                    dft.questions['9'],
                    dft.questions['10'],
                    dft.questions['11'],
                ]}
                description={dft.sections.competence.desc}
                tooltip={dft.sections.competence.tooltip}
                errors={errors}
                open={sections.competence}
                defaultValues={defaultValues}
            />
        </form>
    );
};

DigiOneForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

export default DigiOneForm;
