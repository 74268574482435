import React from 'react';
import Typography from '@material-ui/core/Typography';
import { texts, texts2024 } from '../../texts/Components/FaqText.js';
import TextBlock from '../../components/TextBlock/TextBlock';
import moment from 'moment';

const Faq = () => {
    const outputTexts =
        parseInt(moment().format('YYYY')) > 2023 ? texts2024 : texts;

    return (
        <>
            <Typography variant="h5">{outputTexts.headline}</Typography>

            {outputTexts.categories.map((category, catIdx) => (
                <React.Fragment key={catIdx}>
                    <Typography variant="h6" style={{ marginTop: 24 }}>
                        {category.title}
                    </Typography>

                    {category.questions.map((question, questionIdx) => (
                        <React.Fragment key={questionIdx}>
                            <Typography style={{ marginTop: 16 }}>
                                <strong>{question.title}</strong>
                            </Typography>
                            <TextBlock textBlock={question.answer} />
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </>
    );
};

export default Faq;
