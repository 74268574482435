export const texts = {
    label_tel: 'Telefon:',
    label_fax: 'Fax:',
    label_mail: 'E-Mail:',
    label_internet: 'WWW:',
    impressum: {
        titel: 'Impressum gem. § 5 Digitale-Dienste-Gesetz und § 18 Abs. 2 Medienstaatsvertrag',
        address: {
            name: 'Deutsches Zentrum für Luft- und Raumfahrt e. V. (DLR)',
            street: 'Linder Höhe',
            postalcode_city: '51147 Köln',
            tel: '+49 2203 601-0',
            mail: 'contact-dlr@dlr.de',
            internet: 'https://www.dlr.de',
        },
        text:
            'Gesetzlicher Vertreter des DLR ist der Vorstand, bestehend aus Prof. Dr.-Ing. Anke Kaysser-Pyzalla ' +
            '(Vorsitzende des Vorstands), Klaus Hamacher (stellvertretender Vorsitzender des Vorstands), Prof. ' +
            'Dr.-Ing. Karsten Lemmer und Dr.-Ing. Walther Pelzer.',
    },
    location: {
        title: 'Sitz des Vorstands',
        paragraphs: [
            {
                type: 'p',
                text: 'Deutsches Zentrum für Luft- und Raumfahrt (DLR), Linder Höhe, 51147 Köln.',
            },
            {
                type: 'p',
                text: 'Der Vorstand kann auch durch von ihm ermächtigte Personen vertreten werden. Auskünfte über den Umfang der Ermächtigung erteilt der Leiter Allgemeine Rechtsangelegenheiten des DLR, Linder Höhe, 51147 Köln.',
            },
        ],
    },
    registration: {
        title: 'Registergericht/Registernummer',
        text: 'Amtsgericht Bonn, VR 2780',
    },
    taxNumber: {
        title: 'Umsatzsteuer-Identifikationsnummer',
        text: 'DE 121965658',
    },
    technique: {
        title: 'Technische Betreuung:',
        address: {
            name: 'DLR Projektträger',
            department: 'IKT-Service',
            street: 'Sachsendamm 61',
            postalcode_city: '10829 Berlin',
            tel: '030 67055-767',
            fax: '030 67055-712',
            mail: 'pt-webservice@dlr.de',
        },
    },
    responsible: {
        title: 'Verantwortlicher nach § 18 Abs. 2 Medienstaatsvertrag',
        text: 'Andreas Schütz, DLR, Linder Höhe, 51147 Köln',
    },
    supervisedBy: {
        title: 'Das DLR Web Portal wird betreut durch',
        address: {
            name: 'Deutsches Zentrum für Luft- und Raumfahrt e.V. (DLR)',
            department: 'Kommunikation',
            street: 'Linder Höhe',
            postalcode_city: '51147 Köln',
        },
        paragraphs: [
            {
                type: 'p',
                text: 'Dieses Impressum gilt auch für die DLR-Angebote auf [a|https://www.facebook.com/DLRde/]Facebook[a], [a|https://www.flickr.com/photos/dlr_de/]Flickr[a], [a|https://social.bund.de/@DLR]Mastodon[a], [a|https://www.instagram.com/germanaerospacecenter/]Instagram[a], [a|https://www.kununu.com/]kununu[a], [a|https://de.linkedin.com/company/dlr]LinkedIn[a], [a|https://soundcloud.com/dlrde]Soundcloud[a], [a|https://twitter.com/DLR_de]X (ehemals Twitter)[a], [a|https://vimeo.com/dlr]Vimeo[a], [a|https://vine.co/DLR_de]Vine[a], [a|https://www.xing.com/company/dlr]XING[a], Threads und [a|http://www.youtube.com/user/dlrde]YouTube[a].',
            },
        ],
    },
    disclaimer: {
        title: 'Haftung des DLR für die eigenen Inhalte',
        paragraphs: [
            {
                type: 'p',
                text: 'Sofern das DLR „eigene Inhalte“ (Informationen, Software oder Dokumentation) im vorliegenden Internetangebot auf [a|https://www.dlr.de/de]https://www.dlr.de/de[a] kostenlos zur Nutzung bereit stellt, ist die Haftung für Sach- und Rechtsmängel der DLR eigenen Inhalte, insbesondere für deren Richtigkeit, Fehlerfreiheit, Freiheit von Schutz- und Urheberrechten Dritter, Vollständigkeit und/oder Verwendbarkeit – außer bei Vorsatz und grober Fahrlässigkeit – ausgeschlossen.',
            },
        ],
    },
    disclaimerLinks: {
        title: 'Haftung für fremde verlinkte Inhalte',
        text: 'Von diesen eigenen Inhalten sind Querverweise bzw. Hyperlinks („Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Durch den Hyperlink hält das DLR insofern „fremde Inhalte“ zur Nutzung bereit, die durch den Hinweis „[extern]“oder den Link entsprechend gekennzeichnet sind. Diese fremden Inhalte wurden bei der erstmaligen Link-Setzung daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Es ist jedoch nicht auszuschließen, dass die Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden. Das DLR überprüft die Inhalte, auf die es in seinem Angebot verweist, nicht ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen könnten. Sollten Sie der Ansicht sein, dass die verlinkten externen Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte mit.',
    },
    termsOfUse: {
        title: 'Nutzungsbedingungen',
        content: [
            {
                title: '1. Urheberrecht und Nutzungsbedingungen',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Die Urheberrechte aller in diesem öffentlichen Internet-Angebot (d.h. in dieser Website, Anwendung, App bzw. diesem Social-Media-Kanal) verwendeten urheberrechtlich geschützten Inhalte wie z.B. Bilder, Videos, Abbildungen und Texte liegen, sofern nicht anders angegeben, beim Deutschen Zentrum für Luft- und Raumfahrt e.V. (DLR). So bedeutet z. B. „Quelle: DLR“, dass die Urheberrechte beim Deutschen Zentrum für Luft- und Raumfahrt e.V. (DLR) liegen. Die jeweilig angegebenen Rechteinhaber sind vor der Verwendung des Materials in jedem Einzelfall um Verwendungserlaubnis (Lizenz) anzufragen.',
                    },
                ],
            },
            {
                title: '1.a. Erfragen eines Nutzungsrechts, wenn das Urheberrecht beim DLR liegt',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Wenn Sie für einen Inhalt dieses öffentlichen Internet-Angebots, bei dem das Urheberrecht beim DLR liegt, ein individuelles Nutzungsrecht anfragen möchten, wenden Sie sich bitte an:',
                    },
                ],
                address: {
                    name: 'Deutsches Zentrum für Luft- und Raumfahrt e.V. (DLR)',
                    department: 'Abteilung Kommunikation und Presse',
                    street: 'Linder Höhe',
                    postalcode_city: '51147 Köln',
                    tel: '+49 2203 601-2116',
                    fax: '+49 2203 601-3249',
                    mail: 'bildredaktion@dlr.de',
                },
            },
            {
                title: '1.b. Nutzungsrechte für Inhalte, wenn das Urheberrecht nicht beim DLR liegt',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Bestimmte Bilder, Videos, Abbildungen oder Texte des öffentlichen Internet-Angebots stammen aus anderen Quellen als dem DLR. Bitte wenden Sie sich bezüglich der jeweiligen Nutzungsbedingungen und der Einholung der Lizenz für die Verwendung an den jeweils angegebenen Rechteinhaber.',
                    },
                    {
                        type: 'p',
                        text: 'Bestimmte Bilder, Videos, Abbildungen oder Texte haben mehrere Rechteinhaber. Diese sind entsprechend als von mehreren Rechteinhabern stammend gekennzeichnet. In diesem Fall gelten regelmäßig die Nutzungsbedingungen aller in der Quellenangabe genannten Rechteinhaber. In Zweifelsfällen wenden Sie sich bitte direkt an alle angegebenen Rechteinhaber.',
                    },
                ],
            },
            {
                title: '2. Ggf. Creative Commons Lizenz (CC-Lizenz)',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Sofern ausdrücklich angegeben (z.B. unter "Informationen" zum jeweiligen Werk), stehen Bilder und Videos des DLR unter einer [a|https://creativecommons.org/licenses/by-nc-nd/3.0/de/]Creative-Commons-Lizenz CC BY-NC-ND 3.0. Deutschland[a]. Das heißt, Sie dürfen das Werk bzw. den Inhalt vervielfältigen, verbreiten und öffentlich zugänglich machen, jedoch keine Abwandlungen und Bearbeitungen des Werkes bzw. Inhaltes anfertigen oder das jeweilige Werk kommerziell nutzen. Bei Verwendung ist das DLR wie folgt ausdrücklich und gut lesbar zu nennen. Beispiele: "Bild: DLR, CC BY-NC-ND 3.0", "Bilder: DLR, CC BY-NC-ND 3.0", "Video: DLR, CC BY-NC-ND 3.0".',
                    },
                    {
                        type: 'p',
                        text: 'Bilder, Videos, Abbildungen und Texte, die keinen Hinweis auf eine CC-Lizenz besitzen und bei denen nur der betreffende Rechteinhaber als Quelle angegeben ist, (z. B.: " Quelle: DLR"), stehen nicht unter CC-Lizenz.',
                    },
                ],
            },
            {
                title: '3. Nutzung von DLR-Inhalten durch Presse, Rundfunk und Fernsehen',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Inhalte des öffentlichen Internet-Angebots, zu denen kein spezieller Hinweis auf eine Nutzungsbedingung angegeben ist, d.h. bei denen wo entweder nichts zur  den Nutzungsbedingungen angegeben ist oder bei denen wo das DLR als Quelle angegeben wird ohne Hinweis auf spezifisch geltende Nutzungsbedingungen (Beispiele: "Bild: DLR", "Video: DLR") dürfen bei Nennung des DLR als Urheber verwendet werden von Presse, Rundfunk und Fernsehanstalten sowie entsprechenden Internet-Informations-Angeboten. Hiervon ausgenommen sind personenbezogene Daten wie zum Beispiel Fotos oder Videos, auf denen Personen identifizierbar sind oder persönliche Informationen wie Namen, E-Mail-Adressen, etc..',
                    },
                ],
            },
            {
                title: '4. Keine Einschränkung der Rechte anderer',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Die vom DLR festgelegten Nutzungsbedingungen haben keinerlei Einfluss auf die folgenden Rechte:',
                    },
                    {
                        type: 'list',
                        text: `[li]Die Rechte, die jedermann wegen der Schranken des Urheberrechts (wie z. B. dem Zitatrecht) oder aufgrund gesetzlicher Erlaubnisse zustehen (in einigen Ländern als Doktrin des fair use etabliert)[li]
                        [li]Das Urheberpersönlichkeitsrecht des Rechteinhabers[li]
                        [li]Rechte anderer Personen, entweder am Lizenzgegenstand selbst oder bezüglich seiner Verwendung, zum Beispiel die Persönlichkeitsrechte abgebildeter Personen, Markenrechte an dargestellten geschützten Markenzeichen, etc.[li]
                        `,
                    },
                ],
            },
            {
                title: '5. Keine Nutzungsrechte für bestimmte Inhalte',
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Jedwede Lizenz für die Nutzung urheberrechtlich geschützten Materials, inklusive der Creative-Commons-Lizenz, erstreckt sich nur auf die Nutzung urheberrechtlich geschützten Materials. Nicht hiervon erfasst ist die Erlaubnis zur Nutzung geschützter Markenzeichen, wie z. B. des DLR-Logos und seiner Bestandteile sowie weiterer Elemente der DLR Corporate Identity wie beispielsweise die "Blaue Erde". Das DLR-Logo und seine Bestandteile dürfen nicht von Personen, die keine DLR-Mitarbeitenden sind oder denen keine Nutzungserlaubnis seitens des DLR vorliegt, genutzt werden. Jedwede Nutzungserlaubnis zur Nutzung von Marken ist separat beim jeweiligen Inhaber der Markenrechte anzufragen.',
                    },
                ],
            },
        ],
    },
    note: {
        title: 'Anmerkung',
        paragraphs: [
            {
                type: 'p',
                text: 'Webauftritte von Instituten, Einrichtungen und Organisationseinheiten des DLR sowie Projekt-Websites werden nicht von der DLR-Kommunikation, sondern von den jeweiligen Mitarbeiterinnen und Mitarbeitern betreut. Bitte wenden Sie sich bei spezifischen Fragen an die auf der jeweiligen Seite angegebenen Kontakte.',
            },
            {
                type: 'p',
                text: '[i]Zuletzt geändert am 10. Juni 2024[i]',
            },
        ],
    },
};
