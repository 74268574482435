import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { texts as ppt } from '../../texts/Pages/PagesTexts';

export function externalErrorFallback({ resetErrorBoundary }) {
    return (
        <Container role="alert" style={{ marginTop: '5rem' }}>
            <Typography
                variant="h3"
                component="h1"
                style={{ marginBottom: '1rem' }}
            >
                500 - {ppt.external.headline}
            </Typography>
            <Typography>{ppt.external.text}</Typography>
            <Button
                color="primary"
                variant="contained"
                onClick={resetErrorBoundary}
            >
                {ppt.external.button}
            </Button>
        </Container>
    );
}

export default externalErrorFallback;
