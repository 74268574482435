import React from 'react';
import PropTypes from 'prop-types';
import { downloadPdf } from '../../../lib/api/Finalize/FinalizeActions';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { texts as fft } from '../../../texts/Forms/FinalizeFormTexts';
import { createDownloadLinkForFileResponse } from '../../../util/Helper/CreateDownloadLinkForFileResponse';

const FinalizeProposalPdf = (props) => {
    const { onComplete, downloadFunc, id } = props;
    const redownload =
        props.redownload !== undefined ? props.redownload : false;

    const [progress, setProgress] = React.useState(-1);

    const handleDownload = (percent) => {
        setProgress(percent);
    };

    let downloadText = fft.static.pdf;
    if (redownload) {
        downloadText = fft.static.pdfRedownload;
    }

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            setProgress(0);
            const { response, file } = await downloadFunc(id, handleDownload);
            createDownloadLinkForFileResponse(response, file.fileName);
            onComplete();
        } catch (e) {}
        setProgress(-1);
    };

    return (
        <span style={{ display: 'block', textAlign: 'center' }}>
            <Button
                onClick={handleClick}
                size="large"
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
                disabled={progress >= 0}
            >
                <span>
                    <BorderColorIcon style={{ marginRight: '16px' }} />{' '}
                    {downloadText}
                    {progress >= 0 && (
                        <LinearProgress variant="indeterminate" />
                    )}
                </span>
            </Button>
        </span>
    );
};

FinalizeProposalPdf.propTypes = {
    onComplete: PropTypes.func.isRequired,
    downloadFunc: PropTypes.func.isRequired,
    redownload: PropTypes.bool,
    id: PropTypes.string.isRequired,
};

FinalizeProposalPdf.defaultProps = {
    downloadFunc: downloadPdf,
};

export default FinalizeProposalPdf;
