import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.error.main,
        color: 'white',
        boxShadow: theme.shadows[1],
        zIndex: theme.zIndex.drawer + 1,
    },
    flex: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    button: {
        color: '#fff',
        marginTop: 8,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
}));

const Info = (props) => {
    const { to, title, message, backgroundColor } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const show = React.useMemo(() => {
        const now = moment();
        const end = moment(to);

        return now.isBefore(end);
    }, [to]);

    if (!show) return null;

    return (
        <Slide direction="up" in={open} unmountOnExit mountOnEnter>
            <div
                className={classes.box}
                style={{
                    backgroundColor:
                        backgroundColor || classes.box.backgroundColor,
                }}
            >
                <Container data-test="maintenance">
                    <div className={classes.flex}>
                        <div>
                            <Typography variant="h6">{title}</Typography>
                            {typeof message === 'object' ? (
                                message
                            ) : (
                                <Typography>{message}</Typography>
                            )}
                        </div>
                        <div>
                            <IconButton
                                onClick={() => {
                                    setOpen(false);
                                }}
                                color="inherit"
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                        className={classes.button}
                        variant="text"
                        size="small"
                    >
                        Schließen
                    </Button>
                </Container>
            </div>
        </Slide>
    );
};

Info.propTypes = {
    from: PropTypes.string,
    to: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundColor: PropTypes.string,
};

export default Info;
