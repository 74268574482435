import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator, saveMutator } from './ManagementMutators';

export function fetchManagementData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/step_1`
                );
                resolve(fetchMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die Managementdaten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveManagementData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_1`,
                saveMutator(data)
            );

            resolve(Response.Success('Daten erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error(
                    'Die Managementdaten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
