import React from 'react';
import PropTypes from 'prop-types';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import moment from 'moment';
import TextBlock from '../../TextBlock/TextBlock';
import Alert from '@material-ui/lab/Alert';

const InvalidTime = (props) => {
    const { rafflePot } = props;

    const block = React.useMemo(
        () => rt.invalidTime(moment(rafflePot.raffleBegin).format('LLL')),
        [rafflePot]
    );

    return (
        <Alert severity="info">
            <TextBlock textBlock={block} />
        </Alert>
    );
};

InvalidTime.propTypes = {
    rafflePot: PropTypes.object,
};

export default InvalidTime;
