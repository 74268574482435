import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DropzoneContainer from '../../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../../lib/api/Files/FileActions';
import Button from '@material-ui/core/Button';

const UploadModal = (props) => {
    const { open, onCancel, verificationId, proofId, displayOnly } = props;

    const id = React.useMemo(() => {
        if (proofId.indexOf('/api') === 0)
            return proofId.slice('/api/proofs/'.length);
        return proofId;
    }, [proofId]);

    return (
        <Dialog open={open} onClose={onCancel} maxWidth="md" fullWidth>
            <DialogContent>
                <DropzoneContainer
                    prefetchFiles={fetchUploadedFilesFor(verificationId, [
                        'verification',
                        'proofItem',
                        id,
                    ])}
                    keywords={['verification', 'proofItem', id]}
                    linkedResource={verificationId}
                    acceptedFileTypes={['.pdf']}
                    disabled={displayOnly}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    value="contained"
                    disableElevation
                    color="primary"
                >
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UploadModal.propTypes = {
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    index: PropTypes.number,
    verificationId: PropTypes.string,
    proofId: PropTypes.string,
    displayOnly: PropTypes.bool,
};

export default UploadModal;
