import React from 'react';
import PropTypes from 'prop-types';
import { texts as d3ft } from '../../../../../texts/Forms/Digi3FormTexts';
import { ContentTypography } from '../OverviewComponent';

const Digi3Overview = (props) => {
    const { data } = props;
    const questions = data.expectedResults.map((question, id) => {
        let response = '';
        d3ft.questions[id].answers.forEach((answer) => {
            if (answer.value === question.toString()) {
                response = answer.label;
            }
        });
        return (
            <div key={'digi3_' + question + '_' + id}>
                {ContentTypography(d3ft.questions[id].text, response)}
            </div>
        );
    });
    return (
        <>
            {ContentTypography(
                d3ft.abilityEffects.competitivenes.label,
                data.competitivenes
            )}
            {ContentTypography(
                d3ft.abilityEffects.innovativeCap.label,
                data.innovativeCap
            )}
            {ContentTypography(
                d3ft.abilityEffects.otherExpEffects.label,
                data.otherExpEffects
            )}
            {questions}
        </>
    );
};

Digi3Overview.propTypes = {
    data: PropTypes.object,
};

export default Digi3Overview;
