export const texts = {
    title: 'Antragstellendes Unternehmen',
    title2: 'Unternehmensdaten',
    title3: 'Ort der Umsetzung',
    title4: 'Hauptsitz des Unternehmens',
    title5: 'Ort der Umsetzung',
    employees: 'Anzahl der Mitarbeiter pro Standort',
    mainHint:
        'Für die Höhe der Förderquote ist der Ort der Umsetzung maßgeblich.',
    map: {
        hint: 'Folgend können Sie die strukturschwachen Regionen nach "Digital Jetzt" einsehen. Diese dient zur Orientierung und wird für Fragen in diesem Formular benötigt. Weiterhin können Sie jederzeit die Karte erneut aufrufen, wenn Sie mit der Maus an die rechte Seite gehen.',
        uri: 'https://www.bmwk.de/Redaktion/DE/Downloads/foerdergebietskarte-ab-08-2017.pdf?__blob=publicationFile&v=9',
        alt: 'Übersicht strukturschwache Regionen',
        title: 'Karte herunterladen',
    },
    notInGermany: {
        title: 'Antrag nicht förderfähig',
        body: 'Ihr Unternehmen ist nicht antragsberechtigt - Die Betriebsstätte oder Niederlassung, in der die Investition erfolgt, muss in Deutschland liegen (siehe Förderrichtlinie Punkt 3). Bitte beachten Sie, dass falsche Angaben den Widerruf der auf dieser Grundlage bewilligten Förderung und die Rückforderung der ausgezahlten Beträge nebst Verzinsung zur Folge haben können.',
    },
    companyName: {
        label: 'Unternehmensname',
        error: 'Bitte geben Sie den Unternehmensnamen an.',
    },
    companyUrl: {
        label: 'Unternehmenshomepage',
        error: 'Bitte geben Sie die Homepage in diesem Format an: "http://bmwk.de".',
    },
    inGermany: {
        label: 'Es handelt sich beim Ort der Umsetzung des Vorhabens um eine Betriebsstätte bzw. eine Niederlassung in Deutschland.',
        buttons: [
            { value: 'true', label: 'Ja' },
            { value: 'false', label: 'Nein' },
        ],
        error: 'Bitte beantworten Sie diese Frage.',
    },
    addresses: {
        question:
            'Geben Sie bitte bei mehreren Umsetzungsorten der Investitionen zuerst den Hauptumsetzungsort der geplanten Investition an.',
        hint: 'Maßgebend für die Förderquote ist der Umsetzungsort der finanzellen Hauptinvestition. Wenn dies nicht eindeutig zu benennen ist, ist der Umsetzungsort mit der größten Mitarbeiterzahl maßgebend.',
        street: {
            label: 'Straße',
            error: 'Bitte geben Sie die Straße an.',
        },
        nr: {
            label: 'Hausnummer',
            error: 'Bitte geben Sie die Hausnummer an.',
        },
        postalcode: {
            label: 'PLZ',
            error: 'Bitte geben Sie eine gültige PLZ an.',
        },
        city: {
            label: 'Ort',
            error: 'Bitte geben Sie den Ort an.',
        },
        state: {
            label: 'Bundesland',
            error: 'Bitte wählen Sie ein Bundesland aus.',
        },
        employees: {
            error: 'Bitte geben Sie die Mitarbeitendenzahl an.',
        },
        headquarter: {
            label: 'Hauptsitz und Ort der Umsetzung sind identisch',
            buttons: [
                { value: 'true', label: 'Ja' },
                { value: 'false', label: 'Nein' },
            ],
            error: 'Bitte beantworten Sie diese Frage.',
            isWeak: {
                label: 'Hauptsitz (gleichzeitig Ort der Umsetzung) befindet sich in einer strukturschwachen Region (s. Karte zur Orientierung).',
                buttons: [
                    { value: 'true', label: 'Ja' },
                    { value: 'false', label: 'Nein' },
                ],
                error: 'Bitte beantworten Sie diese Frage.',
                hint: {
                    text: '{%Karte%} zur Orientierung',
                    uris: [
                        'https://www.bmwk.de/Redaktion/DE/Downloads/foerdergebietskarte-ab-08-2017.pdf?__blob=publicationFile&v=9',
                    ],
                },
            },
        },
        isWeak: {
            label: 'Ort der Umsetzung befindet sich in einer strukturschwachen Region (s. Karte zur Orientierung).',
            buttons: [
                { value: 'true', label: 'Ja' },
                { value: 'false', label: 'Nein' },
            ],
            error: 'Bitte beantworten Sie diese Frage.',
            hint: {
                text: '{%Karte%} zur Orientierung',
                uris: [
                    'https://www.bmwk.de/Redaktion/DE/Downloads/foerdergebietskarte-ab-08-2017.pdf?__blob=publicationFile&v=9',
                ],
            },
        },
    },
    hasMoreThan50: {
        label: 'Mehr als 50 % der vorgesehenen Ausgaben  für Investition und Qualifizierungsmaßnahmen sind an dem Ort / den Orten der Umsetzung vorgesehen, der/ die in einer strukturschwachen Region liegt',
    },
};
