import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { stringToUri } from '../../../util/Helper/StringToUri';
import CreateLink from '../../Partials/CreateLink';

const useStyles = makeStyles({
    h4: {
        fontWeight: 'normal',
        fontSize: '1.5rem',
        marginBottom: '1rem',
    },
    body: {
        marginBottom: '.5rem',
    },
});

const FooterGridDirective = (props) => {
    const { headline, body1, body2, uris1, uris2 } = props;
    const classes = useStyles();

    const splitData1 = stringToUri(body1);
    const splitData2 = stringToUri(body2);
    const uri1 = _.cloneDeep(uris1);
    const uri2 = _.cloneDeep(uris2);

    return (
        <>
            <Typography variant="h4" component="p" className={classes.h4}>
                {headline}
            </Typography>
            <Typography variant="body1" className={classes.body}>
                {splitData1.map((element) =>
                    element.startsWith('{%') ? (
                        <React.Fragment key={`footer_${element}`}>
                            <br />
                            {CreateLink({
                                string: element,
                                uris: uri1,
                                target: '_blank',
                            })}
                        </React.Fragment>
                    ) : (
                        element
                    )
                )}
            </Typography>
            <Typography variant="body1" className={classes.body}>
                {splitData2.map((element) =>
                    element.startsWith('{%') ? (
                        <React.Fragment key={`footer_${element}`}>
                            <br />
                            {CreateLink({
                                string: element,
                                uris: uri2,
                                target: '_blank',
                            })}
                        </React.Fragment>
                    ) : (
                        element
                    )
                )}
            </Typography>
        </>
    );
};

FooterGridDirective.propTypes = {
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    uris: PropTypes.array,
};

export default FooterGridDirective;
