import { isOfType } from '../../util/Validation/ValidateType';

export const step2Texts = {
    headline: 'Verwendungsnachweis Digitalisierungsplan',
    title: 'Teil 1 Verwendungsnachweis Schlussbericht (Sachbericht)',
    errorText:
        'Das Formular enthält Fehler, bitte überprüfen Sie Ihre Eingaben.',
    specified: 'Angegeben wurde:',
    hint: {
        paragraphs: [
            {
                type: 'p',
                text: 'Sollte das Vorhaben nach der Bewilligung nicht wie geplant umgesetzt worden sein, ist dies dem Projektträger an dieser Stelle mit dem Verwendungsnachweis mitzuteilen, zu dokumentieren und zu begründen.',
            },
            {
                type: 'p',
                text: 'Auch nach erfolgter Bewilligung besteht kein Anspruch auf Förderung, wenn sich erhebliche inhaltliche oder finanzielle Abweichungen vom ursprünglich geplanten Vorhaben ergeben, die im Konflikt zur Förderrichtlinie stehen. Entstandene Mehrausgaben, welche über den Gesamtzuschuss der Förderung hinausgehen, sind generell nicht zuwendungsfähig. Das Bundesministerium für Wirtschaft und Klimaschutz beziehungsweise der Projektträger entscheidet jeweils im Einzelfall. Änderungen des ausführenden Anbieters beziehungsweise Dienstleisters sind ebenfalls mit dem Verwendungsnachweis mitzuteilen.',
            },
            {
                type: 'p',
                text: 'Im Folgenden wird Ihnen gespiegelt, was Gegenstand der Antragsstellung war.',
            },
        ],
    },
    outline1: {
        title: '1 Ziele des Vorhabens',
        subtitle: 'Folgende Ziele haben Sie im Antrag ausgewählt.',
        form: {
            targets: {
                label: 'Bitte markieren Sie (Checkbox) die Ziele, die mit der Maßnahme tatsächlich verfolgt wurden.',
                digitizeModel: {
                    name: 'digitizeModel',
                    label: '(1) Geschäftsmodell digitalisieren und digitale Geschäftsfelder (= digitale Produkte und Dienstleistungen) entwickeln bzw. optimieren',
                },
                digitalWork: {
                    name: 'digitalWork',
                    label: '(2) Geschäftsprozesse und Arbeitsorganisation digitalisieren',
                },
                employeeCompetence: {
                    name: 'employeeCompetence',
                    label: '(3) Erhöhung der Kompetenzen und Fertigkeiten von Mitarbeitenden im Umgang mit digitalen Technologien',
                },
                security: {
                    name: 'security',
                    label: '(4) Erhöhung der IT-Sicherheit (Datensicherheit und -schutz)',
                },
                digitalNetworking: {
                    name: 'digitalNetworking',
                    label: '(5) Verbesserung der digitalen Vernetzung entlang oder innerhalb einer Wertschöpfungskette',
                },
                otherTargetPursued: {
                    name: 'otherTargetsPursued',
                    label: '(5) Verbesserung der digitalen Vernetzung entlang oder innerhalb einer Wertschöpfungskette',
                },
                other: {
                    name: 'other',
                    label: '(6) Sonstige',
                },
            },
            otherTargetPursued: {
                name: 'otherTargetsPursued',
                label: 'Sonstiges',
                rules: (other) => {
                    if (other)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
        },
    },
    outline2: {
        title: '2 Beschreibung des Vorhabens',
        subtitle:
            'Im Folgenden möchten wir überprüfen, ob die Investitionsmaßnahmen inhaltlich weitestgehend wie geplant umgesetzt wurden. \n' +
            'Außerdem überprüfen wir, ob die digitalen Technologien und / oder Qualifizierungsmaßnahmen der Mitarbeitenden wie geplant angewendet und / oder durchgeführt werden. \n' +
            'Bitte geben Sie nachfolgend anhand der jeweiligen Fragen an, ob die Angaben, die Sie im Antrag gemacht haben, weitestgehend noch zutreffen.\n' +
            'Geben Sie ggf. Änderungen an. \n',
        form: {
            questionCommon:
                'Zur Frage: In welche digitalen Technologien und / oder Qualifizierungsmaßnahmen möchten Sie konkret investieren? Benennen Sie diese möglichst knapp und anschaulich',
            common: {
                name: 'common',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            commonComment: {
                name: 'commonComment',
                label: 'Erläuterung und Begründung der Änderungen',
                rules: (common) => {
                    if (!common)
                        return {
                            required:
                                'Bitte geben Sie eine Erläuterung und Begründung der Änderungen ein.',
                        };
                    return undefined;
                },
            },
            questionApplication:
                'Zur Frage: Erläutern Sie die konkrete Anwendung der Investitionen im Unternehmen. Erläutern Sie die konkrete Anwendung bzw. den Mehrwert der geplanten digitalen Technologien im Unternehmen.',
            application: {
                name: 'application',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            applicationComment: {
                name: 'applicationComment',
                label: 'Kommentar',
                rules: (application) => {
                    if (!application)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
            questionImprovements:
                'Zur Frage: Welche Verbesserungen bzw. Neuerungen für Ihr Unternehmen erwarten Sie konkret als Ergebnis des Investitionsvorhabens? \n' +
                'Sind Ihre Angaben im Förderantrag zu den erwarteten Verbesserungen / Neuerungen für Ihr Unternehmen durch die Investitions-/ Qualifizierungsmaßnahme(n) weiterhin realistisch?',
            improvements: {
                name: 'improvements',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            improvementsComment: {
                name: 'improvementsComment',
                label: 'Kommentar',
                rules: (improvements) => {
                    if (!improvements)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
        },
    },
    outline3: {
        title: 'IT-Sicherheit',
        form: {
            questionSecurityTechnologies:
                'Zur Frage: In welche (ggfs. zusätzlichen) digitalen Technologien und / oder Qualifizierungsmaßnahmen mit Bezug zu IT-Sicherheit möchten Sie ' +
                'konkret investieren? Benennen Sie diese möglichst knapp und anschaulich.',
            securityTechnologies: {
                name: 'securityTechnologies',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            securityTechnologiesComment: {
                name: 'securityTechnologiesComment',
                label: 'Kommentar',
                rules: (securityTechnologies) => {
                    if (!securityTechnologies)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
            questionSecurityTarget:
                'Zur Frage: Wie erhöht sich durch das Investitionsvorhaben insgesamt die IT-Sicherheit im Unternehmen?',
            securityTarget: {
                name: 'securityTarget',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            securityTargetComment: {
                name: 'securityTargetComment',
                label: 'Kommentar',
                rules: (securityTarget) => {
                    if (!securityTarget)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
        },
    },
    outline4: {
        title: 'WSK',
        form: {
            questionValueChainRole:
                'Zur Frage: 1. Beschreiben Sie die Wertschöpfungskette, in der Sie und die weiteren ' +
                'beteiligten Unternehmen mitwirken. Stellen Sie zudem die Rolle bzw. Funktion Ihres Unternehmens in dieser Wertschöpfungskette bzw. in diesem Netzwerk dar.',
            valueChainRole: {
                name: 'valueChainRole',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            valueChainRoleComment: {
                name: 'valueChainRoleComment',
                label: 'Kommentar',
                rules: (valueChainRole) => {
                    if (!valueChainRole)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
            questionValueChainImprove:
                'Zur Frage: 2. Wie trägt das Investitionsvorhaben zum einen zur Verbesserung der Rolle bzw. ' +
                'Funktion des Unternehmens in der Wertschöpfungskette bzw. im Netzwerk und zum anderen zur Verbesserung ' +
                'innerhalb der Wertschöpfungskette insgesamt (gemeinsame Zielstellung der beteiligten Unternehmen) bei?',
            valueChainImprove: {
                name: 'valueChainImprove',
                label: 'Sind diese Angaben weitestgehend noch zutreffend?',
                buttons: [
                    { label: 'Zutreffend', value: 'true' },
                    { label: 'Nicht zutreffend', value: 'false' },
                ],
                rules: {
                    validate: (value) =>
                        isOfType(
                            value,
                            'boolean',
                            'Bitte geben Sie an, ob sich die Daten geändert haben.'
                        ),
                },
            },
            valueChainImproveComment: {
                name: 'valueChainImproveComment',
                label: 'Kommentar',
                rules: (valueChainImprove) => {
                    if (!valueChainImprove)
                        return {
                            required: 'Bitte geben Sie einen Kommentar ein.',
                        };
                    return undefined;
                },
            },
        },
    },
};
