import React from 'react';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

const ToggleableInfobox = (props) => {
    const { teaserText, text, type } = props;
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Alert severity={type} style={{ marginTop: 16, marginBottom: 16 }}>
            {teaserText}
            {expanded && <div style={{ marginTop: 8 }}>{text}</div>}

            <div style={{ marginTop: 16 }}>
                <Button
                    variant="outlined"
                    size="small"
                    disableElevation
                    color="primary"
                    onClick={() => {
                        setExpanded(expanded ? false : true);
                    }}
                    startIcon={
                        expanded ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )
                    }
                >
                    {expanded ? 'Weniger' : 'Mehr'} anzeigen
                </Button>
            </div>
        </Alert>
    );
};

ToggleableInfobox.propTypes = {
    teaserText: PropTypes.object.isRequired,
    text: PropTypes.object.isRequired,
    type: PropTypes.string,
};

ToggleableInfobox.defaultProps = {
    type: 'info',
};

export default ToggleableInfobox;
