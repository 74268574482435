import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    smaller: {
        fontSize: 13,
        marginBottom: 0,
    },
    text: {
        fontSize: 17,
        lineHeight: '1',
    },
}));

const RaffleCard = (props) => {
    const { pot } = props;
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                <Typography color="textSecondary" className={classes.smaller}>
                    Antragszeitraum
                </Typography>
                <Typography className={classes.text} gutterBottom>
                    {moment(pot.submitBegin).format('lll')} -{' '}
                    {moment(pot.submitEnd).format('lll')}
                </Typography>
            </CardContent>
        </Card>
    );
};

RaffleCard.propTypes = {
    pot: PropTypes.object,
};

export default RaffleCard;
