import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';

const Plan2Radiogroup = (props) => {
    const { defaultValue, error, fields, label, register, validation, name } =
        props;

    return (
        <FormControl error={!!error.message}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup name={name} defaultValue={defaultValue}>
                {fields.map((field, idx) => (
                    <FormControlLabel
                        key={`${name}_${idx}`}
                        control={
                            <Radio
                                color="primary"
                                inputRef={register(validation)}
                                value={field.value}
                            />
                        }
                        label={field.label}
                    />
                ))}
            </RadioGroup>
            {!!error.message && (
                <FormHelperText>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

Plan2Radiogroup.propTypes = {
    label: PropTypes.any,
    defaultValue: PropTypes.string,
    error: PropTypes.object,
    register: PropTypes.func,
    fields: PropTypes.array,
    validation: PropTypes.object,
    name: PropTypes.string,
};

Plan2Radiogroup.defaultProps = {
    error: {},
    validation: {},
    fields: [
        {
            label: 'Ja',
            value: 'true',
        },
        {
            label: 'Nein',
            value: 'false',
        },
    ],
};

export default Plan2Radiogroup;
