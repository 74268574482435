import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
    root: (props) => ({
        marginBottom: theme.spacing(props.gutter),
    }),
}));

const TextInput = ({
    gutter,
    variant,
    register,
    validation,
    fullWidth,
    type,
    name,
    label,
    error,
    disabled,
    helperText,
}) => {
    const classes = useStyle({ gutter });

    const hasError = Object.keys(error).length !== 0;

    return (
        <TextField
            className={classes.root}
            variant={variant}
            inputRef={register(validation)}
            fullWidth={fullWidth}
            type={type}
            name={name}
            label={label}
            error={hasError}
            helperText={hasError ? error.message : helperText}
            disabled={disabled}
        />
    );
};

TextInput.propTypes = {
    gutter: PropTypes.number,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    register: PropTypes.func.isRequired,
    validation: PropTypes.object,
    fullWidth: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
};

TextInput.defaultProps = {
    variant: 'standard',
    type: 'text',
    validation: {},
    error: {},
    disabled: false,
    fullWidth: true,
    gutter: 2,
};

export default TextInput;
