import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import RaffleState from './RaffleState';
import { raffleTexts as rt } from '../../texts/Forms/Raffle/RaffleTexts';

const RaffleOverview = (props) => {
    const { raffles, rafflePots, raffleStatus } = props;

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                {rt.title}
            </Typography>

            <RaffleState
                state={raffleStatus}
                raffles={raffles}
                pots={rafflePots}
            />
        </div>
    );
};

RaffleOverview.propTypes = {
    raffles: PropTypes.array,
    rafflePots: PropTypes.array,
    raffleStatus: PropTypes.number,
};

export default RaffleOverview;
