import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const LinkSameSite = (props) => {
    const { children, href } = props;

    return (
        <Link component={RouterLink} to={href}>
            {children}
        </Link>
    );
};

LinkSameSite.propTypes = {
    children: PropTypes.any,
    href: PropTypes.string,
};

export default LinkSameSite;
