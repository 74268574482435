import React from 'react';
import PropTypes from 'prop-types';
import { texts as clft } from '../../../texts/Forms/CompanyLocationFormTexts';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RHFTextField from '../../Partials/Form/RHFTextField';
import { useFieldArray, useForm } from 'react-hook-form';
import RadioButtons from '../../Partials/Form/RadioButtons';
import CompanyLocation from './CompanyLocation';
import Button from '@material-ui/core/Button';
import DialogWithLogout from '../../Partials/Dialog/DialogWithLogout';
import { TYPE } from '../../../util/DataTypes/Response';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import assistedRegion from '../../../static/images/foerdergebiet.png';
import { Box } from '@material-ui/core';
import Map from '../../../static/files/Strukturschwache_Regionen_nach_Digital_Jetzt_ab_20220301.pdf';
const useStyles = makeStyles((theme) => ({
    marginBot: {
        marginBottom: theme.spacing(2),
    },
    map: {
        overflow: 'hidden',
        textAlign: 'center',
        '& a img': {
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
        },
        '& a': {
            opacity: '0.3',
            transition: 'opacity 0.3s ease',
            display: 'block',
            width: '150px',
            height: '150px',
            margin: '0 auto',
        },
        '& a:hover': {
            opacity: '1',
        },
    },
    sideMap: {
        backgroundColor: '#60810E',
        boxShadow: theme.shadows[10],
        position: 'fixed',
        right: '0',
        top: '50%',
        transform: 'translate(calc(100% - ' + theme.spacing(6) + 'px), -50%)',
        transition: 'transform 0.4s ease-out',
        zIndex: '100',
        display: 'flex',
        flexDirection: 'row',
        '& div:first-child': {
            backgroundColor: '#60810E',
            width: theme.spacing(6),
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& span': {
                '-ms-writing-mode': 'tb-rl',
                writingMode: 'vertical-rl',
                fontWeight: 'bold',
                color: 'white',
                letterSpacing: '2px',
            },
        },
        '& div:last-child': {
            padding: theme.spacing(3),
            width: '200px',
            backgroundColor: 'white',
            '& img': {
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                opacity: '0.3',
                transition: 'opacity 0.3s ease',
                '&:hover': {
                    opacity: '1',
                },
            },
        },
        '&:hover': {
            transform: 'translate(0, -50%)',
        },
    },
}));

const CompanyLocationForm = (props) => {
    const { defaultValues, saveResponse, formId, onSubmit, saving } = props;
    const classes = useStyles();
    const {
        control,
        handleSubmit,
        errors,
        watch,
        register,
        setError,
        clearErrors,
    } = useForm({
        defaultValues: defaultValues,
    });
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'addresses',
    });
    const [inGermanyError, setInGermanyError] = React.useState(null);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [saveResponse, setError]);

    const handleSubmitCallback = (values) => {
        if (values.inGermany === 'false') {
            setInGermanyError(true);
            return;
        }
        onSubmit(values);
    };

    if (fields.length === 0) {
        append({});
    }

    const firstIsMain = watch('identical');
    if (firstIsMain === 'false' && fields.length <= 1) {
        append({});
    }

    React.useEffect(() => {
        if (firstIsMain !== 'true' || fields.length === 1) return;
        remove(Array.from(Array(fields.length).keys()).splice(1));
    }, [firstIsMain, remove, fields.length]);

    const inGermany = watch('inGermany');
    React.useEffect(() => {
        setInGermanyError(inGermany === 'false' ? true : null);
    }, [inGermany, setInGermanyError]);

    return (
        <>
            <Box className={classes.sideMap}>
                <div>
                    <span>Karte nach "Digital Jetzt"</span>
                </div>
                <div>
                    <Link href={Map} target="_blank" title={clft.map.title}>
                        <img src={assistedRegion} alt="" />
                    </Link>
                </div>
            </Box>

            <Typography variant="h4" component="h1" gutterBottom>
                {clft.title}
            </Typography>
            <form
                id={formId}
                onSubmit={(e) => {
                    clearErrors();
                    handleSubmit(handleSubmitCallback)(e);
                }}
                onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                style={{ paddingBottom: '16px' }}
            >
                <Typography variant="h5" component="h2" gutterBottom>
                    {clft.title2}
                </Typography>
                <RHFTextField
                    label={clft.companyName.label}
                    control={control}
                    name="companyName"
                    disabled
                    validation={{ required: clft.companyName.error }}
                    error={errors.companyName}
                />
                <RHFTextField
                    label={clft.companyUrl.label}
                    control={control}
                    name="companyUrl"
                    error={errors.companyUrl}
                    disabled={saving}
                />

                <Typography variant="h5" component="h2" gutterBottom>
                    {clft.title3}
                </Typography>
                <RadioButtons
                    groupLabel={clft.inGermany.label}
                    name="inGermany"
                    control={control}
                    buttons={clft.inGermany.buttons}
                    rules={{ required: clft.inGermany.error }}
                    errors={errors.inGermany}
                />

                <RadioButtons
                    groupLabel={clft.addresses.headquarter.label}
                    name={`identical`}
                    control={control}
                    buttons={clft.addresses.headquarter.buttons}
                    errors={errors.identical}
                    rules={{ required: clft.addresses.headquarter.error }}
                    className={classes.marginBot}
                    disabled={saving}
                />
                <Alert severity="info" className={classes.marginBot}>
                    {clft.mainHint}
                </Alert>

                {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                        <CompanyLocation
                            index={index}
                            control={control}
                            defaultValues={field}
                            errors={errors}
                            watch={watch}
                            register={register}
                        />
                        {index > 0 && (
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => remove(index)}
                                className={classes.marginBot}
                                disabled={
                                    firstIsMain === 'false' && index === 1
                                }
                            >
                                Ort der Ausführung entfernen
                            </Button>
                        )}
                    </React.Fragment>
                ))}
                {firstIsMain === 'false' && (
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                            append({});
                        }}
                        className={classes.marginBot}
                    >
                        Ort der Ausführung hinzufügen
                    </Button>
                )}

                <Alert severity="info" className={classes.marginBot}>
                    {clft.map.hint}
                </Alert>
                <Box className={classes.map}>
                    <Link href={Map} target="_blank" title={clft.map.title}>
                        <img src={assistedRegion} alt={clft.map.alt} />
                    </Link>
                </Box>
            </form>

            {inGermanyError && (
                <DialogWithLogout
                    title={clft.notInGermany.title}
                    content={clft.notInGermany.body}
                    closeCallback={() => {
                        setInGermanyError(null);
                    }}
                    isOpen={true}
                />
            )}
        </>
    );
};

CompanyLocationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

CompanyLocationForm.defaultProps = {
    formId: 'wizardForm',
    saving: false,
    saveResponse: null,
    defaultValues: {},
};

export default CompanyLocationForm;
