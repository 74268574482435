//eslint-disable-next-line
import React from 'react';
import PropTypes from 'prop-types';
import VerificationEditWrapper from '../../components/Verification/Edit/VerificationEditWrapper';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchVerificationById } from '../../lib/api/Verification/VerificationActions';

const VerificationEditContainer = (props) => {
    const { render, ...rest } = props;

    const { id } = useParams();

    const { data, isLoading, isFetching, error, refetch } = useQuery(
        ['verification', id],
        () => fetchVerificationById(id),
        { refetchOnWindowFocus: false }
    );

    return render({ data, isLoading, isFetching, error, refetch, id, ...rest });
};

VerificationEditContainer.propTypes = {
    render: PropTypes.func,
    displayOnly: PropTypes.bool,
};

VerificationEditContainer.defaultProps = {
    render: VerificationEditWrapper,
    displayOnly: false,
};

export default VerificationEditContainer;
