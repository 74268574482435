import React from 'react';
import PropTypes from 'prop-types';
import { useMessages } from '../../../Stores/Messages.store';
import moment from 'moment';
import TextBlock from '../../TextBlock/TextBlock';
import LinkOtherPage from '../../TextBlock/components/LinkOtherPage';
import Alert from '@material-ui/lab/Alert';

const RegisterStopHint = (props) => {
    const { open } = props;

    const { messages } = useMessages((state) => ({
        messages: state.status.register.messages,
    }));

    const currentMessage = React.useMemo(() => {
        const time = moment();
        return messages.find((message) => moment(message.to) >= time);
    }, [messages]);

    if (open) return null;

    return (
        <Alert severity="info">
            <TextBlock
                textBlock={currentMessage.message}
                parserOptions={{
                    components: {
                        a: {
                            component: LinkOtherPage,
                        },
                    },
                }}
            />
        </Alert>
    );
};

RegisterStopHint.propTypes = {
    open: PropTypes.bool,
};

export default RegisterStopHint;
