import { createMuiTheme } from '@material-ui/core/styles';
import { bundesRegular } from './Fonts';
import grey from '@material-ui/core/colors/grey';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(95,49,110)',
            light: '#9675A1',
            dark: '#461256',
            contrastText: '#fff',
        },
        secondary: {
            main: 'rgb(249,224,58)',
            contrastText: '#000',
        },
        info: {
            main: 'rgb(128,205,236)',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: '"BundesSansWeb", Helvetica, Arial, sans-serif',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [bundesRegular],
            },
        },
        MuiInput: {
            root: {
                '&$disabled': {
                    color: grey[700],
                },
            },
        },
        MuiInputLabel: {
            root: {
                '&$disabled': {
                    color: grey[700],
                },
            },
        },
    },
});
