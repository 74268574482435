import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const ValueOrLoader = (props) => {
    const { value, bold } = props;

    return (
        <Typography style={{ fontWeight: bold }}>
            {!value ? <Skeleton /> : value}
        </Typography>
    );
};

ValueOrLoader.propTypes = {
    value: PropTypes.any,
    bold: PropTypes.oneOf(['unset', 'bold']),
};

ValueOrLoader.defaultProps = {
    bold: 'unset',
};

export default ValueOrLoader;
