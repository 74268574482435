import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Navbar from './Navbar';
import UserBar from './UserBar';
import Content from './Content';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './Theme/Styles';
import Info from './CriticalInfo/Info';
import ErrorWrapper from './Error/ErrorWrapper';
import TextBlock from '../TextBlock/TextBlock';

const Layout = ({ isAuthenticated, children }) => {
    const information = {
        to: '2023-10-08T23:59:59',
        title: 'Wartungsfenster Freitag bis Sonntag',
        message: (
            <TextBlock
                textBlock={{
                    paragraphs: [
                        {
                            type: 'p',
                            text: 'Aufgrund von Wartungsarbeiten kann es vom 07.10. - 08.10.2023 zu Einschränkungen im Förderportal kommen. Ab dem 09.10.2023 können Sie dann wie bisher die Antragsstellung und den Verwendungsnachweis wieder bearbeiten.',
                        },
                        {
                            type: 'p',
                            text: 'Vielen Dank für Ihr Verständnis.',
                        },
                        {
                            type: 'p',
                            text: 'Ihr DigitalJetzt-Team',
                        },
                    ],
                }}
            />
        ),
    };

    return (
        <ThemeProvider theme={theme}>
            <ErrorWrapper>
                <Helmet>
                    <title>Digital Jetzt - Förderportal</title>
                </Helmet>
                <CssBaseline />
                <Info {...information} />
                <Header />
                <Navbar isAuthenticated={isAuthenticated} />
                {isAuthenticated && <UserBar />}
                <Content>{children}</Content>
                <Footer />
            </ErrorWrapper>
        </ThemeProvider>
    );
};

export default Layout;
