import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import { calculateFilesSize } from '../../../util/Helper/CalculateFileSize';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'moment/locale/de';
import Moment from 'react-moment';
import { downloadFile } from '../../../lib/api/Files/FileActions';
import { useSnackbar } from 'notistack';
import { createDownloadLinkForFileResponse } from '../../../util/Helper/CreateDownloadLinkForFileResponse';

const DropzoneTableRow = (props) => {
    const { file, progress, deleteFile, disabled, listViewMode } = props;

    const [downloadProgress, setDownloadProgress] = React.useState(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const mountedRef = React.useRef(true);

    const handleDownloadProgress = (progress) => {
        if (mountedRef.current) setDownloadProgress(progress);
    };

    const handleDownload = (link, fileName, fileSize) => () => {
        setDownloadProgress(0);
        downloadFile(link, fileName, fileSize, handleDownloadProgress)
            .then((response) => {
                createDownloadLinkForFileResponse(response, fileName);
            })
            .catch((err) => {
                enqueueSnackbar(err.message(), { variant: 'error' });
            })
            .finally(() => {
                if (mountedRef.current) setDownloadProgress(undefined);
            });
    };

    React.useEffect(
        () => () => {
            mountedRef.current = false;
        },
        [mountedRef]
    );

    return (
        <>
            <TableRow>
                <TableCell style={{ width: 1, padding: 6 }}>
                    {!disabled && !listViewMode && (
                        <IconButton
                            size="small"
                            onClick={deleteFile}
                            disabled={!file['@id'] || downloadProgress >= 0}
                        >
                            {file.isDeleting ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <DeleteIcon style={{ fontSize: '1rem' }} />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>
                    {file.isDeleting ||
                    !file.downloadUrl ||
                    downloadProgress ? (
                        `${file.fileName}${
                            downloadProgress >= 0
                                ? ' wird heruntergeladen...'
                                : ''
                        }`
                    ) : (
                        <Link
                            href="#"
                            onClick={() => {
                                handleDownload(
                                    file.downloadUrl,
                                    file.fileName,
                                    file.fileSize
                                )();
                            }}
                        >
                            {file.fileName}
                        </Link>
                    )}
                </TableCell>
                <TableCell align="right">
                    {calculateFilesSize(file.fileSize)}
                </TableCell>
                <TableCell align="right">
                    {file.uploadedAt && <Moment>{file.uploadedAt}</Moment>}
                </TableCell>
            </TableRow>
            <TableRow>
                {progress >= 0 && (
                    <TableCell
                        colSpan={5}
                        style={{ padding: 0, margin: 0, height: '4px' }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    </TableCell>
                )}
                {downloadProgress >= 0 && (
                    <TableCell
                        colSpan={5}
                        style={{ padding: 0, margin: 0, height: '4px' }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={downloadProgress}
                        />
                    </TableCell>
                )}
            </TableRow>
        </>
    );
};

DropzoneTableRow.propTypes = {
    file: PropTypes.object.isRequired,
    deleteFile: PropTypes.func.isRequired,
    progress: PropTypes.number,
    listViewMode: PropTypes.bool,
};

export default DropzoneTableRow;
