import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator, saveMutator } from './DigitalThreeMutators';

export function fetchDigitalThreeFormData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/step_7`
                );
                resolve(fetchMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die Daten zum Digitalplan 3 konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveDigitalThreeFormData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            //await dummyAsync(saveMutator(data));
            await api().put(
                `/action/proposal/${proposalId}/step_7`,
                saveMutator(data)
            );
            resolve(Response.Success('Daten erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error(
                    'Die Daten zum Digitalplan 3 konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
