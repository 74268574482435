import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        marginBottom: theme.spacing(props.gutter),
    }),
}));

const RHFAutocomplete = (props) => {
    const {
        control,
        asyncFunc,
        optionsIdKey,
        optionsLabelKey,
        name,
        label,
        valueIdKey,
        error,
        validation,
        gutter,
        staticData,
        disabled,
        defaultValue: value,
    } = props;
    const classes = useStyles({ gutter });
    const [options, setOptions] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const loading = open && options.length === 0;

    const func = React.useCallback(() => {
        if (staticData) {
            return Promise.resolve(staticData);
        }
        return asyncFunc();
    }, [asyncFunc, staticData]);

    React.useEffect(() => {
        let active = true;

        if (!loading) return undefined;

        func().then((data) => {
            if (active) setOptions(data);
        });
        return () => {
            active = false;
        };
    }, [func, loading]);

    const selectedValue = (option, value) => {
        if (value !== null && typeof value === 'object') {
            return option[optionsIdKey] === value[valueIdKey];
        }
        return option[optionsLabelKey] === value;
    };

    const optionLabel = (option) => {
        if (option !== null && typeof option === 'object') {
            return option[optionsLabelKey] || '';
        }
        return option;
    };

    return (
        <Controller
            render={({ onChange, ...props }) => {
                return (
                    <Autocomplete
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                fullWidth
                                error={!_.isEmpty(error)}
                                helperText={error && error.message}
                                className={classes.root}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        options={options}
                        getOptionLabel={(option) => optionLabel(option)}
                        getOptionSelected={(option, value) =>
                            selectedValue(option, value)
                        }
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        open={open}
                        loading={loading}
                        disabled={disabled}
                        onChange={(e, data) => onChange(data)}
                        defaultValue={value}
                    />
                );
            }}
            name={name}
            control={control}
            defaultValue={value}
            rules={validation}
        />
    );
};

RHFAutocomplete.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any,
    asyncFunc: PropTypes.func,
    staticData: PropTypes.array,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    optionsIdKey: PropTypes.string,
    optionsLabelKey: PropTypes.string,
    valueIdKey: PropTypes.string,
    valueLabelKey: PropTypes.string,
    error: PropTypes.object,
    validation: PropTypes.object,
    gutter: PropTypes.number,
    disabled: PropTypes.bool,
};

RHFAutocomplete.defaultProps = {
    optionsIdKey: 'id',
    optionsLabelKey: 'name',
    valueIdKey: 'id',
    valueLabelKey: 'name',
    gutter: 2,
    disabled: false,
    error: {},
};

export default RHFAutocomplete;
