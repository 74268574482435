import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    active: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));

const MobileListItem = (props) => {
    const { href, label, onClick, match } = props;

    const location = useLocation();
    const classes = useStyles();

    const isActive = React.useMemo(() => {
        const { pathname } = location;

        if (!pathname) return false;
        if (href === '/') {
            return pathname === href;
        }

        if (match) {
            return match.some((m) => pathname.indexOf(m) === 0);
        }

        return pathname.indexOf(href) === 0;
    }, [location, match, href]);

    return (
        <ListItem
            className={isActive ? classes.active : undefined}
            component={Link}
            to={href}
            onClick={onClick}
            button
        >
            <ListItemText primary={label} />
        </ListItem>
    );
};

MobileListItem.propTypes = {
    label: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    match: PropTypes.array,
};

export default MobileListItem;
