import React from 'react';
import PropTypes from 'prop-types';
import doc1 from '../../../static/files/01_Unternehmen_in_Schwierigkeiten_DLR-PT_20190731.pdf';
import doc2 from '../../../static/files/02_Aufklaerung_Subventionserhebliche_Tatsachen_Digital_Jetzt.pdf';
import doc3 from '../../../static/files/03_De-minimis_BMWK.pdf';
import Link from '@material-ui/core/Link';

const FinalizeDownloadLink = (props) => {
    const { href, children } = props;

    const getHref = () => {
        switch (href) {
            case 'doc1':
                return doc1;
            case 'doc2':
                return doc2;
            case 'doc3':
                return doc3;
            default:
                return '';
        }
    };

    return (
        <Link href={getHref()} target="_blank">
            {children}
        </Link>
    );
};

FinalizeDownloadLink.propTypes = {
    href: PropTypes.string,
};

export default FinalizeDownloadLink;
