export const states = [
    { id: 'Baden-Württemberg', name: 'Baden-Württemberg' },
    { id: 'Bayern', name: 'Bayern' },
    { id: 'Berlin', name: 'Berlin' },
    { id: 'Brandenburg', name: 'Brandenburg' },
    { id: 'Bremen', name: 'Bremen' },
    { id: 'Hamburg', name: 'Hamburg' },
    { id: 'Hessen', name: 'Hessen' },
    { id: 'Mecklenburg-Vorpommern', name: 'Mecklenburg-Vorpommern' },
    { id: 'Niedersachsen', name: 'Niedersachsen' },
    { id: 'Nordrhein-Westfalen', name: 'Nordrhein-Westfalen' },
    { id: 'Rheinland-Pfalz', name: 'Rheinland-Pfalz' },
    { id: 'Saarland', name: 'Saarland' },
    { id: 'Sachsen', name: 'Sachsen' },
    { id: 'Sachsen-Anhalt', name: 'Sachsen-Anhalt' },
    { id: 'Schleswig-Holstein', name: 'Schleswig-Holstein' },
    { id: 'Thüringen', name: 'Thüringen' },
];
