import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import ValueChainOverview from './AccordionContent/ValueChainOverview';
import OverviewContainer from './OverviewContainer';
import {
    fetchMutliStep,
    fetchStep,
} from '../../../lib/api/Overview/OverviewActions';
import { useParams } from 'react-router-dom';
import { fetchZeroMutator } from '../../../lib/api/Overview/StepZeroMutator';
import { texts as oft } from '../../../texts/Forms/OverviewFormTexts';
import ManagementOverview from './AccordionContent/ManagementOverview';
import CompanyLocationOverview from './AccordionContent/CompanyLocationOverview';
import CompanyTypeOverview from './AccordionContent/CompanyTypeOverview';
import MinimiOverview from './AccordionContent/Minimis/MinimiOverview';
import { fetchFourMutator } from '../../../lib/api/Overview/StepFourMutator';
import FinancingPlanOverview from './AccordionContent/FinancingPlan/FinancingPlanOverview';
import { fetchEightMutator } from '../../../lib/api/Overview/StepEightMutator';
import Digi1Overview from './AccordionContent/Digi1/Digi1Overview';
import { fetchFiveMutator } from '../../../lib/api/Overview/StepFiveMutator';
import Digi2Overview from './AccordionContent/Digi2/Digi2Overview';
import { fetchSixMutator } from '../../../lib/api/Overview/StepSixMutator';
import FinancingPlan2Overview from './AccordionContent/FinancingPlan2/FinancingPlan2Overview';
import { fetchNineMutator } from '../../../lib/api/Overview/StepNineMutator';
import Digi3Overview from './AccordionContent/Digi3/Digi3Overview';
import { TYPE } from '../../../util/DataTypes/Response';
import Alert from '@material-ui/lab/Alert';
import TextBlock from '../../TextBlock/TextBlock';
import { texts as dbt } from '../../../texts/Dashboard/DashboardTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isStepComplete } from '../../../lib/api/Wizard/WizardActions';
import { pass } from '../../../lib/api/Overview/EmptyMutator';

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: theme.spacing(4),
    },
    loggedOutTitle: {
        textTransform: 'uppercase',
    },
}));

const ProposalOverviewForm = (props) => {
    const { formId, onSubmit, noForm, saveResponse } = props;
    const { handleSubmit, register } = useForm();
    const { proposalId } = useParams();
    const classes = useStyles();

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (
            !saveResponse ||
            saveResponse.type() !== TYPE.ERROR ||
            !saveResponse.data()
        )
            return;

        const { data } = saveResponse.data().response;
        if (data) {
            data.violations.forEach((violation) => {
                if (violation.propertyPath === 'invalid')
                    setError(violation.message);
            });
        }
    }, [saveResponse]);

    const [canSubmit, setCanSubmit] = React.useState(false);

    React.useEffect(() => {
        isStepComplete(proposalId, 10).then((response) => {
            setCanSubmit(response);
        });
    }, [proposalId]);

    return (
        <>
            {!noForm && (
                <>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Übersicht & Einreichen
                    </Typography>
                    <Alert severity="info" className={classes.mt}>
                        <TextBlock
                            textBlock={dbt.parser.formChangesHint}
                            headlineVariant={'h5'}
                        />
                    </Alert>
                    <Typography gutterBottom>{oft.subHeadline}</Typography>
                </>
            )}
            <OverviewContainer
                proposalId={proposalId}
                fetchFunc={fetchStep('step_0', fetchZeroMutator)}
                Ui={ValueChainOverview}
                labels={oft.valueChain.labels}
            />
            <OverviewContainer
                proposalId={proposalId}
                fetchFunc={fetchStep('step_1', pass)}
                Ui={ManagementOverview}
                labels={oft.management.labels}
            />
            <OverviewContainer
                proposalId={proposalId}
                fetchFunc={fetchStep('step_2', pass)}
                Ui={CompanyLocationOverview}
                labels={oft.companyLocation.labels}
            />
            <OverviewContainer
                proposalId={proposalId}
                fetchFunc={fetchStep('step_3', pass)}
                Ui={CompanyTypeOverview}
                labels={oft.companyType.labels}
            />
            <OverviewContainer
                Ui={MinimiOverview}
                labels={oft.minimis.labels}
                fetchFunc={fetchStep('step_4', fetchFourMutator)}
                proposalId={proposalId}
            />
            <OverviewContainer
                Ui={Digi1Overview}
                labels={oft.digi1.labels}
                fetchFunc={fetchStep('step_5', fetchFiveMutator)}
                proposalId={proposalId}
            />
            <OverviewContainer
                Ui={Digi2Overview}
                labels={oft.digi2.labels}
                fetchFunc={fetchStep('step_6', fetchSixMutator)}
                proposalId={proposalId}
            />
            <OverviewContainer
                Ui={Digi3Overview}
                labels={oft.digi3.labels}
                fetchFunc={fetchStep('step_7', pass)}
                proposalId={proposalId}
            />
            <OverviewContainer
                Ui={FinancingPlanOverview}
                labels={oft.financingPlan.labels}
                fetchFunc={fetchMutliStep(
                    ['step_3', 'step_8'],
                    fetchEightMutator
                )}
                proposalId={proposalId}
            />
            <OverviewContainer
                Ui={FinancingPlan2Overview}
                labels={oft.financing2Plan.labels}
                fetchFunc={fetchMutliStep(
                    ['step_1', 'step_6', 'step_9'],
                    fetchNineMutator
                )}
                proposalId={proposalId}
            />
            {!noForm && (
                <>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        id={formId}
                        style={{ marginBottom: '16px' }}
                    >
                        <input
                            type="hidden"
                            ref={register()}
                            name="hidden"
                            value="true"
                        />
                        <input
                            type="hidden"
                            ref={register()}
                            name="canSubmit"
                            value={canSubmit}
                        />
                        {error && (
                            <Typography
                                variant="h6"
                                style={{
                                    color: '#ff4743',
                                    marginTop: '16px',
                                    textAlign: 'center',
                                }}
                            >
                                {error}
                            </Typography>
                        )}
                    </form>
                </>
            )}
        </>
    );
};

ProposalOverviewForm.propTypes = {
    onSubmit: PropTypes.func,
    formId: PropTypes.any,
    defaultValues: PropTypes.object,
    noForm: PropTypes.bool,
};

ProposalOverviewForm.defaultProps = {
    noForm: false,
};

export default ProposalOverviewForm;
