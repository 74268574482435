import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { texts as fpt } from '../../../texts/Forms/FinancingPlan2FormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextBlock from '../../TextBlock/TextBlock';
import Plan2Checkbox from './Plan2Checkbox';
import Plan2Radiogroup from './Plan2Radiogroup';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../lib/api/Files/FileActions';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Plan2Table from './Plan2Table';

const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(2),
    },
    alignRight: {
        '& input': {
            textAlign: 'right',
        },
    },
}));

const FinancingPlan2Form = (props) => {
    const { defaultValues, formId, onSubmit } = props;
    const classes = useStyles();
    const { register, handleSubmit, errors, watch, clearErrors } = useForm({
        defaultValues: defaultValues,
    });

    const { proposalId } = useParams();

    const submitCallback = (values) => {
        onSubmit(values);
    };

    const creditApproval = watch('creditApproval');
    const kfwCreditApproval = watch('kfwCreditApproval');

    if (!defaultValues.table)
        return (
            <Typography>
                Beim laden der Daten ist ein Fehler aufgetreten
            </Typography>
        );

    return (
        <form
            id={formId}
            onSubmit={(e) => {
                clearErrors();
                handleSubmit(submitCallback)(e);
            }}
            style={{ paddingBottom: '32px' }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {fpt.title}
            </Typography>

            <Plan2Table
                register={register}
                className={classes.field}
                defaultValues={defaultValues.table || {}}
                moduleOne={defaultValues.moduleOne}
                moduleTwo={defaultValues.moduleTwo}
            />

            <Typography variant="h5" component="h2" className={classes.field}>
                {fpt.self.title}
            </Typography>

            <Plan2Checkbox
                error={errors.approveCoPayments}
                defaultChecked={defaultValues.approveCoPayments}
                label={<TextBlock textBlock={fpt.self.first} />}
                validation={fpt.self.errorFirst}
                register={register}
                name="approveCoPayments"
            />

            <Plan2Checkbox
                error={errors.approveEconomic}
                defaultChecked={defaultValues.approveEconomic}
                label={<TextBlock textBlock={fpt.self.second} />}
                validation={fpt.self.errorSecond}
                register={register}
                name="approveEconomic"
            />

            <Typography variant="h5" component="h2" className={classes.field}>
                {fpt.credit.title}
            </Typography>

            <Plan2Checkbox
                error={errors.creditApproval}
                defaultChecked={defaultValues.creditApproval}
                label={fpt.credit.approval}
                register={register}
                name="creditApproval"
            />

            {creditApproval && (
                <>
                    <Plan2Radiogroup
                        error={errors.KfwCreditApproval}
                        defaultValue={defaultValues.kfwCreditApproval}
                        register={register}
                        validation={fpt.credit.errorKfw}
                        name="kfwCreditApproval"
                        label={fpt.credit.isKfw}
                    />

                    <DropzoneContainer
                        prefetchFiles={fetchUploadedFilesFor(proposalId, [
                            'bank',
                            'finance',
                        ])}
                        keywords={['bank', 'finance']}
                        linkedResource={proposalId}
                        acceptedFileTypes={['.pdf']}
                        maxFileSize={18}
                    />

                    {kfwCreditApproval === 'false' && (
                        <>
                            <TextField
                                className={classes.field}
                                inputRef={register(fpt.credit.bankerror)}
                                name="creditBankName"
                                label={fpt.credit.bank}
                                defaultValue={defaultValues.creditBankName}
                                error={!!errors.creditBankName?.message}
                                helperText={
                                    errors.creditBankName
                                        ? errors.creditBankName.message
                                        : ''
                                }
                                fullWidth
                            />
                        </>
                    )}
                </>
            )}
        </form>
    );
};

FinancingPlan2Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

FinancingPlan2Form.defaultProps = {
    defaultValues: {
        table: {
            total: 0,
            fq: 0,
            step1: 0,
            min: 0,
            step2: 0,
            step3: 0,
            maxDeminimis: 0,
        },
    },
};

export default FinancingPlan2Form;
