import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { theme } from '../Theme/Styles';
import FooterGridContainer from '../../../container/Layout/Footer/FooterGridContainer';
import FooterCopyright from './FooterCopyright';

const useStyles = makeStyles({
    support: {
        backgroundColor: theme.palette.grey.A100,
        color: 'black',
        width: '100%',
        padding: '2rem 0',
        flexGrow: 1,
    },
    copyright: {
        backgroundColor: 'white',
        width: '100%',
        color: 'black',
        padding: '2rem 0',
    },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <div role="contentinfo">
            <div className={classes.support}>
                <Container>
                    <FooterGridContainer />
                </Container>
            </div>
            <div className={classes.copyright}>
                <Container>
                    <FooterCopyright />
                </Container>
            </div>
        </div>
    );
};

export default Footer;
