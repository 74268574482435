import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from './Minimis.store';
import _ from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { texts as mft } from '../../../texts/Forms/MinimisFormTexts';
import green from '@material-ui/core/colors/green';
import { validateStringMax } from '../../../util/Validation/ValidateStringMax';
import { validateStringRange } from '../../../util/Validation/ValidateStringRange';
import TextBlock from '../../TextBlock/TextBlock';

const parseToInt = (value) =>
    parseInt(value.replace(',', '').replaceAll('.', ''));
export const parseToString = (intVal) => {
    if (intVal === 0) return '0,00 €';
    const value = `${intVal}`;
    const str =
        value.slice(0, value.length - 2) + '.' + value.slice(value.length - 2);

    const n = Number(str);

    return n.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

const sumArray = (arr) =>
    _.reduce(arr, (sum, el) => sum + parseToInt(el.investmentAmount), 0);

const sumObject = (obj) => {
    let sum = 0;
    Object.keys(obj).forEach((key) => {
        sum += sumArray(obj[key]);
    });
    return sum;
};

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: red[200],
        marginTop: theme.spacing(2),
    },
    success: {
        backgroundColor: green[200],
        marginTop: theme.spacing(2),
    },
}));

const MinimisSum = (props) => {
    const { register, trigger, isRoadTransport } = props;

    const {
        oneMainA,
        oneChildA,
        twoMainA,
        twoChildA,
        threeMainA,
        threeChildA,
    } = useStore((state) => ({
        oneMainA: state.oneMainA,
        oneChildA: state.oneChildA,
        twoMainA: state.twoMainA,
        twoChildA: state.twoChildA,
        threeMainA: state.threeMainA,
        threeChildA: state.threeChildA,
    }));

    const classes = useStyles();
    const [sumOne, setSumOne] = React.useState(0);
    const [sumTwo, setSumTwo] = React.useState(0);
    const [sumThree, setSumThree] = React.useState(0);
    const containerRef = React.useRef(true);

    const fundingLimit = isRoadTransport === 'true' ? 10000000 : 20000000;

    React.useEffect(() => {
        let sum = 0;
        sum += sumArray(oneMainA);
        sum += sumObject(oneChildA);

        if (containerRef.current) setSumOne(sum);
    }, [oneMainA, oneChildA, setSumOne]);

    React.useEffect(() => {
        let sum = 0;
        sum += sumArray(twoMainA);
        sum += sumObject(twoChildA);

        if (containerRef.current) setSumTwo(sum);
    }, [twoMainA, twoChildA, setSumTwo]);

    React.useEffect(() => {
        let sum = 0;
        sum += sumArray(threeMainA);
        sum += sumObject(threeChildA);

        if (containerRef.current) setSumThree(sum);
    }, [threeMainA, threeChildA, setSumThree]);

    React.useEffect(
        () => () => {
            containerRef.current = false;
        },
        [containerRef]
    );

    const total = sumOne + sumTwo + sumThree;

    React.useEffect(() => {
        trigger('isValid');
    }, [total, trigger, fundingLimit]);

    const renderHint = () =>
        isRoadTransport === 'true' ? (
            <TextBlock textBlock={mft.hint2} />
        ) : (
            <Typography>{mft.hint}</Typography>
        );

    const renderSuccess = (total) => (
        <>
            <Typography>
                {fundingLimit - total >= 300000
                    ? mft.calculator.sum.parseSucces(
                          parseToString(fundingLimit)
                      )
                    : mft.calculator.sum.parseSuccessUnder3000(
                          parseToString(fundingLimit)
                      )}
            </Typography>
            {renderHint()}
            <Typography>
                {mft.calculator.sum.parsePossble(
                    parseToString(
                        fundingLimit - total >= 300000
                            ? fundingLimit - total
                            : 0
                    )
                )}
            </Typography>
        </>
    );

    const renderError = (total) => (
        <>
            <Typography>
                {mft.calculator.sum.parseNoSucces(
                    parseToString(fundingLimit),
                    parseToString(total - fundingLimit)
                )}
            </Typography>
        </>
    );

    return (
        <>
            <Card
                className={
                    fundingLimit - total >= 300000
                        ? classes.success
                        : classes.error
                }
            >
                <CardContent>
                    <input
                        name="isValid"
                        type="hidden"
                        ref={register({
                            validate: {
                                inRange: (value) =>
                                    validateStringRange(
                                        value,
                                        fundingLimit - 300000,
                                        fundingLimit,
                                        mft.fields.isValid.error
                                    ),
                                over: (value) =>
                                    validateStringMax(
                                        value,
                                        fundingLimit - 300000,
                                        mft.fields.isValid.error2
                                    ),
                            },
                        })}
                        value={total}
                    />
                    <Typography color="textSecondary" gutterBottom>
                        Summe
                    </Typography>
                    <Typography gutterBottom>{parseToString(total)}</Typography>
                    {total >= fundingLimit
                        ? renderError(total)
                        : renderSuccess(total)}
                </CardContent>
            </Card>
        </>
    );
};

MinimisSum.propTypes = {
    register: PropTypes.func,
    trigger: PropTypes.func,
    isRoadTransport: PropTypes.string,
};

export default MinimisSum;
