import { isDefined } from '../../../util/Validation/ValidateDefined';

export function fetchMutator(data) {
    let isProjectManagement = -1;

    const company = data.user.companies.shift();
    if (company) {
        const address = company.addresses.shift();
        if (address) {
            data.user.phone = address.phone;
        }
    }

    data.staff.forEach((staff, index) => {
        Object.keys(staff).forEach((key) => {
            if (staff[key] === null) {
                staff[key] = '';
            }
            if (key === 'func') {
                staff[key] = '' === staff[key] ? 'null' : `${staff[key]}`;
            }
        });

        if (staff.isProjectManagement === true) {
            isProjectManagement = index;
        }
    });

    data.user.name = data.user.firstName;
    data.user.lastname = data.user.lastName;

    if (isProjectManagement >= 0) {
        data.staff.unshift(data.staff.splice(isProjectManagement, 1)[0]);
        const isUser = data.staff[0].isUser;
        if (isUser) {
            const id = data.user['@id'];
            data.user = { ...data.staff[0], '@id': id };
        }
    }

    Object.keys(data.user).forEach((key) => {
        if (data.user[key] === null) {
            data.user[key] = '';
        }
    });

    if (isDefined(data.isRegisteredUser)) {
        data.isRegisteredUser =
            data.isRegisteredUser === true ? 'true' : 'false';
    }

    if (isDefined(data.staff[0])) {
        data.projectManagement = { ...data.staff[0] };
    }
    if (isDefined(data.staff[1])) {
        data.businessManagement = { ...data.staff[1] };
    }
    delete data.staff;

    return data;
}

export function saveMutator(data) {
    data.staff = [];

    if (data.isRegisteredUser)
        data.isRegisteredUser = data.isRegisteredUser === 'true';

    if (data.projectManagement) {
        data.projectManagement.func = parseInt(data.projectManagement.func);
        if (data.isRegisteredUser === true) {
            data.projectManagement.isUser = true;
        }
        data.projectManagement.isProjectManagement = true;
        data.staff[0] = data.projectManagement;
        delete data.projectManagement;
    }

    if (data.businessManagement) {
        data.businessManagement.func = parseInt(data.businessManagement.func);
        data.businessManagement.isProjectManagement = false;
        data.staff[1] = data.businessManagement;
        delete data.businessManagement;
    }

    if (data.user) {
        data.user.firstName = data.user.name;
        data.user.lastName = data.user.lastname;
    }

    if (!data.staff.length) delete data.staff;

    return data;
}
