import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../Stores/Auth.store';
import { loginNewWindow } from '../../lib/api/Auth/AuthActions';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import loginRedirectPages from '../../config/LoginRedirectPages';

const LoginContainer = (props) => {
    const { render, renderModal } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [message, setMessage] = React.useState(undefined);
    const mountedRef = React.useRef(true);
    const { setUser } = useAuth((state) => ({
        setUser: state.setUser,
    }));
    const currentLocation = useLocation();
    const history = useHistory();
    const handleClick = () => {
        setIsLoading(true);
        setModalOpen(true);
        setError(undefined);
        setMessage(undefined);
        loginNewWindow(setMessage)
            .then((user) => {
                setUser(user);
                // Redirect to start page if current location is login/register
                if (loginRedirectPages.includes(currentLocation.pathname)) {
                    history.push('/');
                }
            })
            .catch((err) => {
                setError(err);
                setMessage(undefined);
            })
            .finally(() => {
                if (!mountedRef.current) return;
                setIsLoading(false);
            });
    };

    React.useEffect(
        () => () => {
            mountedRef.current = false;
        },
        [mountedRef]
    );

    return (
        <>
            {render({ onClick: handleClick, isLoading: isLoading })}
            {renderModal({
                open: modalOpen,
                onClose: setModalOpen,
                isLoading: isLoading,
                status: message,
                error: error,
                retry: handleClick,
            })}
        </>
    );
};

LoginContainer.propTypes = {
    render: PropTypes.func.isRequired,
    renderModal: PropTypes.func.isRequired,
};

export default LoginContainer;
