export const texts = {
    titles: {
        contacts: 'Bevollmächtigte Person',
    },
    missingBoss: 'Sie müssen einen Geschäftsführer angeben',
    isRegisteredUser: {
        label: 'Ist die zur Antragstellung bevollmächtigte Person identisch mit der Person, die die Registrierung angelegt hat?',
        error: 'Bitte geben Sie an, ob die Antragstellende Person identisch mit der Person ist, die sich registriert hat. ',
        ariaLabel: 'Person identisch',
    },
    staff: {
        hint: 'Bitte benennen Sie im Folgenden die von Ihrem Unternehmen als zuständig benannte Person.',
        person1: 'Bevollmächtigte Person',
        person2: 'Geschäftsführung',
        nameError: 'Bitte geben Sie den Vornamen an.',
        lastNameError: 'Bitte geben Sie den Nachnamen an.',
        salutationError: 'Bitte wählen Sie eine Anrede aus.',
        title: {
            empty: 'Kein Titel',
            dr: 'Dr.',
            phd: 'PhD',
            prof: 'Prof.',
        },
        functions: {
            null: 'k.A.',
            0: 'Geschäftsführung',
            1: 'Leitung Abteilung/ Unternehmenseinheit',
            2: 'Mitarbeitende',
        },
        functionError:
            'Bitte wählen Sie die Funktion der bevollmächtigten Person bzw. Geschäftsführung aus',
        phoneError:
            'Bitte tragen Sie nur Zahlen ohne Sonder- oder Leerzeichen ein, beginnend mit einer 0.',
        emailError: 'Bitte geben Sie eine E-Mail-Adresse an.',
        emailErrorFormat: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
        hintAdditionalBoss:
            'Sofern weitere Personen die Geschäftsführung innehaben, fügen Sie untenstehend bitte weitere ein',
        addBossButton: 'Weitere Geschäftsführung hinzufügen',
        removeBossPerson: 'Person entfernen',
    },
    hint1: 'Das antragsberechtigte Unternehmen bzw. eine bevollmächtigte Person stellt den Antrag ausschließlich über das elektronische Antragsformular. Notwendige Anlagen werden ausschließlich elektronisch hochgeladen.',
    hint2: 'Die Anträge werden nach Reihenfolge der Antragstellung bearbeitet und beschieden. Für den Zeitpunkt der Antragstellung ist das Datum des Eingangs des Antrages mit rechtsverbindlicher Unterschrift (postalisch oder elektronisch) bei dem vom Bundesministerium für Wirtschaft und Klimaschutz beauftragten Projektträger maßgeblich.',
    hint3: 'Das Bundesministerium für Wirtschaft und Klimaschutz oder der von ihm beauftragte Projektträger ist berechtigt, bei Bedarf weitere Unterlagen anzufordern. ',
};
