import React from 'react';
import RafflePotsError from './RafflePotsError';
import { useAppState } from '../../../Stores/AppState.store';

const ErrorWrapper = (props) => {
    const rafflePotsError = useAppState((state) => state.rafflePotsError);

    if (rafflePotsError) return <RafflePotsError />;

    return props.children;
};

ErrorWrapper.propTypes = {};

export default ErrorWrapper;
