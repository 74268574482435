import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from 'react-hook-form';
import {
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
} from '@material-ui/core';

const FormRadio = (props) => {
    const {
        control,
        defaultValue,
        name,
        rules,
        error,
        notEditable,
        disabled,
        buttons,
        transformer,
        changeMutator,
    } = props;

    const handleChange = (onChange) => (e) => {
        if (notEditable) return;
        onChange(changeMutator(e.target.value));
    };

    return (
        <FormControl error={Boolean(error)}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ value, onChange }) => {
                    return (
                        <RadioGroup
                            name={name}
                            value={transformer(value)}
                            onChange={handleChange(onChange)}
                        >
                            {buttons.map((btn) => (
                                <FormControlLabel
                                    key={btn.value}
                                    control={
                                        <Radio
                                            color="primary"
                                            value={btn.value}
                                            disabled={disabled || notEditable}
                                            disableRipple={notEditable}
                                        />
                                    }
                                    label={btn.label}
                                />
                            ))}
                        </RadioGroup>
                    );
                }}
            />
            {Boolean(error) && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

FormRadio.propTypes = {
    control: PropTypes.object,
    defaultValue: PropTypes.any,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    buttons: PropTypes.array,
    changeMutator: PropTypes.func,
    transformer: PropTypes.func,
};

FormRadio.defaultProps = {
    defaultValue: null,
    changeMutator: (data) => data,
    transformer: (data) => data,
};

export default FormRadio;
