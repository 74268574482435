import React from 'react';
import PropTypes from 'prop-types';

const CenterOverlay = (props) => {
    const { children } = props;

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'white',
                opacity: '.8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            {children}
        </div>
    );
};

CenterOverlay.propTypes = {
    children: PropTypes.node,
};

export default CenterOverlay;
