import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { generateUrlSearchParams } from '../../../util/Helper/UrlSearchParams';

export function uploadFile(data, onUpdate, linkInfo = null) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append('file', data);

            const config = {
                onUploadProgress: function (progressEvent) {
                    onUpdate(
                        Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    );
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const { data: response } = await api().post(
                '/media',
                formData,
                config
            );

            if (linkInfo) {
                const { data: mediaLinks } = await api().post(
                    '/media_links',
                    { ...linkInfo, media: response['@id'] },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                resolve({ ...response, mediaLinks });
                return;
            }

            resolve(response);
        } catch (e) {
            reject(Response.Error('Datei konnte nich hochgeladen werden', e));
        }
    });
}

export function deleteFile(iri) {
    return new Promise(async (resolve, reject) => {
        try {
            const id = iri.slice('/api/media/'.length);
            await api().delete(`/media/${id}`);
            resolve(Response.Success('Datei erfolgreisch gelöscht'));
        } catch (e) {
            reject(Response.Error('Konnte Datei nicht löschen', e));
        }
    });
}

export function fetchUploadedFilesFor(linkedResources, keywords) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const search = generateUrlSearchParams({
                    'mediaLinks.linkedResource': linkedResources,
                    'mediaLinks.keywords': keywords,
                });

                const { data } = await api().get('/media' + search);
                resolve(data['hydra:member']);
            } catch (e) {
                reject(
                    Response.Error(
                        'Liste der Dateien konnte nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function downloadFile(link, fileName, fileSize, onProgress) {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                responseType: 'blob',
                onDownloadProgress: function (progressEvent) {
                    onProgress(
                        Math.round((progressEvent.loaded / fileSize) * 100)
                    );
                },
            };
            const response = await api().get(link.slice('/api'.length), config);
            resolve(response);
        } catch (e) {
            reject(
                Response.Error(
                    'Beim Download der Datei ist ein Fehler aufgetreten',
                    e
                )
            );
        }
    });
}
