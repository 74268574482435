import { texts as dft } from '../../../texts/Forms/Digi1FormTexts';

export function fetchFiveMutator(data) {
    const sectors = [];
    const questions = [];

    data.initialBusinessUnits.forEach((unit) => {
        sectors.push(dft.initialSituation.sectors[parseInt(unit)]);
    });

    Object.keys(data.fields).forEach((key) => {
        if (key === '@id') return;
        questions.push({
            question: dft.questions[key].text,
            answer: dft.questions[key].answers.filter(
                (value) => value.value === `${data.fields[key]}`
            )[0].label,
        });
    });

    return { sectors, other: data.otherBusinessUnit, questions };
}
