import React from 'react';
import PropTypes from 'prop-types';
import FinalizeProposalPdf from '../Finalize/FinalizeProposalPdf';
import { downloadPdf } from '../../../lib/api/Verification/VerificationActions';
import Outline from '../../Partials/Info/Outline';
import TextBlock from '../../TextBlock/TextBlock';
import { step7Texts } from '../../../texts/Verification/Step7Texts';
import FormRadio from '../Components/FormRadio';
import { useForm } from 'react-hook-form';
import FormFooter from './FormFooter';
import { step1Texts } from '../../../texts/Verification/Step1Texts';
import Button from '@material-ui/core/Button';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import red from '@material-ui/core/colors/red';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../lib/api/Files/FileActions';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const Step7 = (props) => {
    const { displayOnly, id, formData } = props;

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message(), { variant: 'success' });
        history.push('/vn');
    };
    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
    };

    const [submittedData, setSubmittedData] = React.useState(undefined);

    const {
        control,
        errors,
        handleSubmit,
        register,
        setValue,
        watch,
        trigger,
    } = useForm({
        defaultValues: {
            downloaded: true,
            uploaded: undefined,
            [step7Texts.outline1.documentTransmission.name]:
                formData[step7Texts.outline1.documentTransmission.name],
        },
    });

    const handleComplete = () => {
        setValue('downloaded', true, { shouldValidate: true });
    };

    const submitCallback = (values) => {
        setSubmittedData(values);
    };

    const documentTransmission = watch(
        step7Texts.outline1.documentTransmission.name
    );
    const downloaded = watch('downloaded');

    const handleDialogClose = () => {
        setSubmittedData(undefined);
    };

    const handleDialogSubmit = () => {
        mutate({
            data: {
                [step7Texts.outline1.documentTransmission.name]:
                    submittedData[
                        step7Texts.outline1.documentTransmission.name
                    ],
            },
            transition: 'submit',
            id,
            success: step7Texts.success,
            usePut: false,
        });
    };

    const handleFilesChanged = (files) => {
        if (files.length === 0) {
            setValue('uploaded', undefined, { shouldValidate: true });
            return;
        }

        if (files[0]['@id']) {
            setValue('uploaded', true, { shouldValidate: true });
        }
    };

    React.useEffect(() => {
        trigger('uploaded').then((r) => {});
    }, [documentTransmission, trigger]);

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    return (
        <form onSubmit={handleSubmit(submitCallback)}>
            <Outline subtitle={step7Texts.title}>
                <TextBlock textBlock={step7Texts.text1} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <FinalizeProposalPdf
                        onComplete={handleComplete}
                        downloadFunc={downloadPdf}
                        redownload={Boolean(downloaded)}
                        id={id}
                    />
                </div>
            </Outline>
            <Outline subtitle={step7Texts.outline1.title} gutterTop>
                <FormRadio
                    notEditable={displayOnly}
                    error={
                        errors[step7Texts.outline1.documentTransmission.name]
                    }
                    rules={step7Texts.outline1.documentTransmission.rules}
                    name={step7Texts.outline1.documentTransmission.name}
                    buttons={step7Texts.outline1.documentTransmission.buttons}
                    control={control}
                />
                <input
                    type="hidden"
                    name="downloaded"
                    ref={register({ required: step7Texts.downloadHint })}
                />
                <input
                    type="hidden"
                    name="uploaded"
                    ref={register({
                        validate: (value) => {
                            if (
                                documentTransmission === 'b' &&
                                value === 'true'
                            )
                                return true;
                            if (documentTransmission === 'a') return true;
                            if (!documentTransmission) return true;
                            return step7Texts.uploadHint;
                        },
                    })}
                />

                <Alert severity="info" style={{ marginTop: 16 }}>
                    <TextBlock textBlock={step7Texts.outline1.hint} />
                </Alert>
            </Outline>

            {documentTransmission === 'a' && (
                <Outline subtitle={step7Texts.outline2_a.title} gutterTop>
                    <TextBlock textBlock={step7Texts.outline2_a.list} />
                </Outline>
            )}

            {documentTransmission === 'b' && (
                <>
                    <Outline subtitle={step7Texts.outline2_b.title} gutterTop>
                        <TextBlock textBlock={step7Texts.outline2_b.list} />
                    </Outline>
                    <Outline gutterTop>
                        <DropzoneContainer
                            prefetchFiles={fetchUploadedFilesFor(id, [
                                'verification',
                                'final',
                            ])}
                            keywords={['verification', 'final']}
                            linkedResource={id}
                            onFilesChanged={handleFilesChanged}
                            maxFileSize={18}
                            acceptedFileTypes={['.pdf']}
                            maxFileCount={1}
                            displayOnly={displayOnly}
                        />
                    </Outline>
                </>
            )}

            {errors.downloaded && (
                <Typography variant="h6" style={{ color: red[500] }}>
                    {errors.downloaded.message}
                </Typography>
            )}
            {errors.uploaded && (
                <Typography variant="h6" style={{ color: red[500] }}>
                    {errors.uploaded.message}
                </Typography>
            )}

            <Alert severity="info" style={{ marginTop: 16 }}>
                <TextBlock textBlock={step7Texts.hint} />
            </Alert>

            {!displayOnly && (
                <FormFooter
                    displayText={Object.keys(errors).length > 1}
                    text={step1Texts.errorText}
                    gutterTop
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                    >
                        {step7Texts.button}
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                </FormFooter>
            )}

            <Dialog open={Boolean(submittedData)} onClose={handleDialogClose}>
                <DialogTitle>{step7Texts.dialog.title}</DialogTitle>
                <DialogContent>{step7Texts.dialog.body}</DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleDialogClose}
                        disabled={isSaving}
                    >
                        {step7Texts.dialog.close}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={handleDialogSubmit}
                        color="primary"
                        disabled={isSaving}
                    >
                        {step7Texts.dialog.submit}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

Step7.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

export default Step7;
