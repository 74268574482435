export const texts = {
    subHeadline:
        'Bitte überprüfen Sie nochmals genau alle Angaben in den Schritten 1 bis 10 und schließen dann die Antragseingabe über den Button „Einreichen“ ab.',
    valueChain: {
        labels: {
            headline: '1. Wahl der Antragsform',
            aria: '1. Übersicht Wahl der Antragsform',
            id: 1,
        },
        content: {
            isValueChain: 'Antrag als Teil einer Wertschöpfungskette (WSK)',
            isNotValueChain: 'Antrag als Einzelunternehmen',
            isLead: 'Das antragstellende Unternehmen ist Lead-Partner',
            code: 'WSK-Code',
            company: 'Unternehmen der Wertschöpfungskette',
        },
    },
    management: {
        labels: {
            headline: '2. Bevollmächtigte Person',
            aria: '2. Übersicht bevollmächtigte Person',
            id: 2,
        },
        content: {
            headline: {
                management: 'Zur Antragstellung bevollmächtigte Person',
                ceo: 'Geschäftsführung',
            },
        },
    },
    companyLocation: {
        labels: {
            headline: '3. Antragstellendes Unternehmen',
            aria: '3. Übersicht Antragstellendes Unternehmen',
            id: 3,
        },
        content: {
            name: 'Unternehmensname',
            url: 'Website des Unternehmens',
            headline: {
                main: 'Hauptsitz des Unternehmens',
                other: 'Ort der Ausführung',
            },
            weak: 'Der Ort der Ausführung befindet sich in einer strukturschwachen Region',
        },
    },
    companyType: {
        labels: {
            headline: '4. Angaben zum Unternehmen',
            aria: '3. Übersicht Angaben zum Unternehmen',
            id: 4,
        },
        content: {
            communalCompany:
                'Das antragstellende Unternehmen ist einer der nachfolgend genannten Rechtsformen bzw. ' +
                'Kriterien zuzuordnen: Unternehmen mit öffentlicher Beteiligung; gemeinnützige Unternehmen; ' +
                'Unternehmen des öffentlichen Rechts und Religionsgemeinschaften sowie deren Beteiligungen; ' +
                'Unternehmen in Gründung; Vereine; Stiftungen; Insolvente Unternehmen oder Unternehmen in ' +
                'Schwierigkeiten (VO (EU) Nr. 651/2014).',
            registered: {
                headline: 'Registereinträge',
                hrg: 'Handelsregisternummer',
                trade: 'Gewerbenummer',
                craft: 'Handwerksrolle',
                taxNumber: 'Steuernummer',
            },
            legal: 'Rechtsform',
            founded:
                'Das Unternehmen ist innerhalb der letzten 24 Monate gegründet worden',
            sector: 'Branche',
            deductTax: 'Das Unternehmen ist vorsteuerabzugsberechtigt',
            type: {
                headline: 'Unternehmenstyp',
                0: 'Es handelt sich beim antragstellenden Unternehmen um ein eigenständiges Unternehmen',
                1: 'Es handelt sich beim antragstellenden Unternehmen um ein Partnerunternehmen',
                2: 'Es handelt sich beim antragstellenden Unternehmen um ein verbundenes Unternehmen',
            },
            shareholder: {
                headline: 'Verbundenes Unternehmen',
                name: 'Name',
                legal: 'Rechtsform',
                city: 'Sitz / Stadt',
                intendsProposal:
                    'Beabsichtigt eine Antragstellung in "Digital Jetzt"',
            },
            employeeCheck: {
                headline:
                    'Bei der Anzahl der Mitarbeitenden werden sämtliche Mitarbeitende berücksichtigt, die zum Zeitpunkt der Antragstellung beschäftigt sind',
            },
            employees: {
                headline: 'Zahl der Mitarbeitenden',
                3: '3 bis 10',
                11: '11 bis 50',
                51: '51 bis 250',
                251: '251 bis 499',
            },
            files: {
                headline: 'Datei(en)',
            },
        },
    },
    minimis: {
        labels: {
            headline: '5. De-minimis Beihilfe',
            aria: '5. Übersicht De-minimis Beihilfe',
            id: 5,
        },
        parser: {
            text1: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Sie haben in den letzten drei Jahren keine De-minimis Beihilfen erhalten oder beantragt.',
                    },
                ],
            },
            received: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Folgende De-minimis Beihilfen haben Sie in den letzten drei Jahren erhalten:',
                    },
                ],
            },
            requested: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Folgende De-minimis Beihilfen haben Sie in den letzten drei Jahren beantragt:',
                    },
                ],
            },
            receivedRequested: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Folgende De-minimis Beihilfen haben Sie in den letzten drei Jahren erhalten und beantragt:',
                    },
                ],
            },
        },
    },
    financingPlan: {
        labels: {
            headline: '9. Finanzierungsplan',
            aria: '9. Übersicht Finanzierungsplan',
            id: 9,
        },
    },
    digi1: {
        labels: {
            headline: '6. Digitalisierungsplan',
            aria: '6. Übersicht Digitalisierungsplan',
            id: 6,
        },
        static: {
            text0: 'Sie haben folgende Unternehmensbereiche ausgewählt:',
            text1: 'Sie haben die Fragen wie folgt beantwortet:',
        },
    },
    digi2: {
        labels: {
            headline: '7. Digitalisierungsplan - Vorhabenbeschreibung',
            aria: '7. Übersicht Digitalisierungsplan - Vorhabenbeschreibung',
            id: 7,
        },
        static: {
            module: 'Sie haben folgendes Modul ausgewählt',
            moduleMulti: 'Sie haben folgende Module ausgewählt',
            description: 'Titel des Vorhabens',
            time: 'Start- und Enddatum',
            extended:
                'Erläuterung, warum der Zeitraum mehr als 12 Monate umfasst',
            confirm:
                'Sie haben bestätigt, dass Sie noch nicht mit dem Vorhaben angefangen haben',
            targets: 'Folgende Ziele haben Sie ausgewählt',
        },
    },
    digi3: {
        labels: {
            headline:
                '8. Digitalisierungsplan - Nachhaltige Wirkung der Investition',
            aria: '8. Übersicht Digitalisierungsplan - Nachhaltige Wirkung der Investition',
            id: 8,
        },
    },
    financing2Plan: {
        labels: {
            headline: '10. Zuschuss "Digital Jetzt"',
            aria: '10. Übersicht Zuschuss Digital Jetzt',
            id: 10,
        },
        static: {
            sum: 'Investitionssumme gesamt',
            dij: 'Höhe des De-minimis Zuschusses "Digital Jetzt"',
            self: 'Eigenanteil',
            confirmTitle: 'Folgendem haben Sie zugestimmt:',
            confirm1:
                'Der im Finanzierungsplan ausgewiesene Eigenanteil wird durch Eigenmittel aufgebracht.',
            confirm2:
                'Das antragstellende Unternehmen berücksichtigt hinsichtlich der Finanzierung des Gesamtvorhabens alle sonstigen finanziellen Verpflichtungen während der Laufzeit des Vorhabens. Es sollten ausreichend private und betriebliche Mittel verbleiben, um eventuelle Liquiditätsengpässe überbrücken zu können. Das antragstellende Unternehmen berücksichtigt zudem, dass im Falle der Bewilligung des Zuschusses das Vorhaben zunächst auf eigene Kosten umzusetzen ist und die Auszahlung des Zuschusses erst nach Umsetzung und positivem Abschluss der Prüfung des Verwendungsnachweises erfolgt.',
            confirm2b:
                'Das antragstellende Unternehmen erklärt, dass die Umsetzung des Investitionsvorhabens auf Basis der bestehenden Personalkapazitäten umgesetzt werden kann.',
            credit: 'Vorgesehene Eigenmittel sind über Kredite gedeckt, die bereits zugesagt sind',
            kfwTrue:
                'Es handelt sich dabei um eine auf das Investitionsvorhaben bezogene Kreditzusage der KfW für ein Digitalisierungsvorhaben aus dem „ERP-Digitalisierungs- und Innovationskredit“.',
            kfwFalse:
                'Es handelt sich dabei nicht um eine auf das Investitionsvorhaben bezogene Kreditzusage der KfW für ein Digitalisierungsvorhaben aus dem „ERP-Digitalisierungs- und Innovationskredit“.',
            bank: 'Name der Bank',
            fq: 'Förderquote',
            uploaded: 'Hochgeladene Datei(en)',
        },
    },
};
