import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { centToString, toLocale } from '../../../util/Money/MoneyFunctions';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    alignRight: {
        '& input': {
            textAlign: 'right',
        },
    },
}));

const parseMoney = (m) => {
    const cents = centToString(m);
    return toLocale(cents);
};

const Display = (props) => {
    const { amount, format, isMoney } = props;
    const classes = useStyles();
    return (
        <TextField
            variant="filled"
            value={`${isMoney ? parseMoney(amount) : amount} ${format}`}
            fullWidth
            className={classes.alignRight}
            InputProps={{
                readOnly: true,
            }}
        />
    );
};

Display.propTypes = {
    amount: PropTypes.any,
    format: PropTypes.string,
    isMoney: PropTypes.bool,
};

Display.defaultProps = {
    format: '',
    isMoney: false,
};

export default Display;
