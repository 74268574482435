import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Outline from '../../Partials/Info/Outline';
import { step2Texts } from '../../../texts/Verification/Step2Texts';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import FormFooter from './FormFooter';
import FormCheckbox from '../Components/FormCheckbox';
import FormTextArea from '../Components/FormTextArea';
import {
    step2FetchMutator,
    step2Transformer,
} from '../../../lib/api/Verification/VerificationMutator';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import TextBlock from '../../TextBlock/TextBlock';
import Step2Question from './Step2Question';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {},
    mt4: {
        marginTop: theme.spacing(4),
    },
}));

const Step2 = (props) => {
    const classes = useStyles();

    const {
        data,
        formData,
        isLoading,
        displayOnly,
        id,
        onDirtyChange,
        handleStepChange,
    } = props;

    const defaultValues = React.useMemo(() => {
        return step2FetchMutator(formData.digitisation || {});
    }, [formData]);

    const { control, errors, handleSubmit, watch, formState } = useForm({
        defaultValues,
    });

    const security = watch(step2Texts.outline1.form.targets.security.name);
    const digitalNetworking = watch(
        step2Texts.outline1.form.targets.digitalNetworking.name
    );

    const common = watch(step2Texts.outline2.form.common.name);
    const application = watch(step2Texts.outline2.form.application.name);
    const improvements = watch(step2Texts.outline2.form.improvements.name);

    const securityTechnologies = watch(
        step2Texts.outline3.form.securityTechnologies.name
    );

    const securityTarget = watch(step2Texts.outline3.form.securityTarget.name);

    const valueChainRole = watch(step2Texts.outline4.form.valueChainRole.name);
    const valueChainImprove = watch(
        step2Texts.outline4.form.valueChainImprove.name
    );
    const otherTargetPursued = watch(
        step2Texts.outline1.form.targets.other.name
    );

    const { isDirty } = formState;

    React.useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange]);

    const { enqueueSnackbar } = useSnackbar();

    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
    };
    const handleSuccess = (data) => {
        enqueueSnackbar(data.message(), { variant: 'success' });
        onDirtyChange(false);
    };

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onError: handleError,
        onSuccess: handleSuccess,
    });

    const submitCallback = (values) => {
        mutate({
            data: {
                ...formData,
                digitisation: { ...step2Transformer(values) },
            },
            id,
            success: 'Daten wurden erfolgreich gespeichert.',
        });
    };

    if (isLoading) return <FullscreenCircularLoader />;

    return (
        <form onSubmit={handleSubmit(submitCallback)} title={step2Texts.title}>
            <Typography variant="h4" component="h1">
                {step2Texts.headline}
            </Typography>

            <Alert severity="info" style={{ marginTop: 16 }}>
                <TextBlock textBlock={step2Texts.hint} />
            </Alert>

            <Outline title={step2Texts.outline1.title} gutterTop>
                <Typography
                    className={classes.mt4}
                    variant="h6"
                    component="h2"
                    gutterBottom
                >
                    {step2Texts.outline1.subtitle}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalDigitizeModel"
                            label={
                                step2Texts.outline1.form.targets.digitizeModel
                                    .label
                            }
                            notEditable={true}
                            defaultValue={data.digi2?.targets.digitizeModel}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalDigitalWork"
                            label={
                                step2Texts.outline1.form.targets.digitalWork
                                    .label
                            }
                            notEditable={true}
                            defaultValue={data.digi2?.targets.digitalWork}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalEmployeeCompetence"
                            label={
                                step2Texts.outline1.form.targets
                                    .employeeCompetence.label
                            }
                            notEditable={true}
                            defaultValue={
                                data.digi2?.targets.employeeCompetence
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalSecurity"
                            label={
                                step2Texts.outline1.form.targets.security.label
                            }
                            notEditable={true}
                            defaultValue={data.digi2?.targets.security}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalDigitalNetworking"
                            label={
                                step2Texts.outline1.form.targets
                                    .digitalNetworking.label
                            }
                            notEditable={true}
                            defaultValue={
                                data.digi2?.targets.digitalTechnologies
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name="originalDigitizeModel"
                            label={step2Texts.outline1.form.targets.other.label}
                            notEditable={true}
                            defaultValue={data.digi2?.targetsOther !== ''}
                        />
                    </Grid>
                    {Boolean(data.digi2?.targetsOther) && (
                        <Grid item xs={12}>
                            <FormTextArea
                                name={
                                    step2Texts.outline1.form.otherTargetPursued
                                        .name
                                }
                                control={control}
                                label={
                                    step2Texts.outline1.form.otherTargetPursued
                                        .label
                                }
                                defaultValue={data.digi2?.targetsOther}
                                error={
                                    errors[
                                        step2Texts.outline1.form
                                            .otherTargetPursued.name
                                    ]
                                }
                                notEditable={true}
                                multiline={false}
                            />
                        </Grid>
                    )}
                </Grid>
                <Typography
                    className={classes.mt4}
                    variant="h6"
                    component="h2"
                    gutterBottom
                >
                    {step2Texts.outline1.form.targets.label}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={
                                step2Texts.outline1.form.targets.digitizeModel
                                    .name
                            }
                            label={
                                step2Texts.outline1.form.targets.digitizeModel
                                    .label
                            }
                            defaultValue={
                                formData.digitisation?.targets?.digitizeModel
                            }
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={
                                step2Texts.outline1.form.targets.digitalWork
                                    .name
                            }
                            label={
                                step2Texts.outline1.form.targets.digitalWork
                                    .label
                            }
                            defaultValue={
                                formData.digitisation?.targets?.digitalWork
                            }
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={
                                step2Texts.outline1.form.targets
                                    .employeeCompetence.name
                            }
                            label={
                                step2Texts.outline1.form.targets
                                    .employeeCompetence.label
                            }
                            defaultValue={
                                formData.digitisation?.targets
                                    ?.employeeCompetence
                            }
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={
                                step2Texts.outline1.form.targets.security.name
                            }
                            label={
                                step2Texts.outline1.form.targets.security.label
                            }
                            defaultValue={
                                formData.digitisation?.targets?.security
                            }
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={
                                step2Texts.outline1.form.targets
                                    .digitalNetworking.name
                            }
                            label={
                                step2Texts.outline1.form.targets
                                    .digitalNetworking.label
                            }
                            defaultValue={
                                formData.digitisation?.targets
                                    ?.digitalNetworking
                            }
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            control={control}
                            name={step2Texts.outline1.form.targets.other.name}
                            label={step2Texts.outline1.form.targets.other.label}
                            defaultValue={Boolean(
                                formData.digitisation?.otherTargetPursued
                            )}
                            notEditable={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextArea
                            name={
                                step2Texts.outline1.form.otherTargetPursued.name
                            }
                            control={control}
                            label={
                                step2Texts.outline1.form.otherTargetPursued
                                    .label
                            }
                            defaultValue={
                                defaultValues[
                                    step2Texts.outline1.form.otherTargetPursued
                                        .name
                                ]
                            }
                            rules={step2Texts.outline1.form.otherTargetPursued.rules(
                                otherTargetPursued
                            )}
                            error={
                                errors[
                                    step2Texts.outline1.form.otherTargetPursued
                                        .name
                                ]
                            }
                            multiline={false}
                            notEditable={displayOnly}
                        />
                    </Grid>
                </Grid>
            </Outline>
            <Outline
                title={step2Texts.outline2.title}
                subtitle={step2Texts.outline2.subtitle}
                gutterTop
            />
            <Step2Question
                questionProps={step2Texts.outline2.form.common}
                commentProps={step2Texts.outline2.form.commonComment}
                question={step2Texts.outline2.form.questionCommon}
                explanation={data.digi2?.explainCommon}
                label={step2Texts.outline2.form.application.label}
                questionDefaultValue={
                    formData.digitisation?.targetFields?.common?.valid
                }
                commentValue={formData.digitisation?.commonComment}
                commentDefaultValue={
                    defaultValues[step2Texts.outline2.form.commonComment.name]
                }
                watch={common}
                commentError={step2Texts.outline2.form.commonComment.name}
                notEditable={displayOnly}
                errors={errors}
                control={control}
            />

            <Step2Question
                questionProps={step2Texts.outline2.form.application}
                commentProps={step2Texts.outline2.form.applicationComment}
                question={step2Texts.outline2.form.questionApplication}
                explanation={data.digi2?.explainApplication}
                label={step2Texts.outline2.form.application.label}
                questionDefaultValue={
                    formData.digitisation?.targetFields?.application?.valid
                }
                commentValue={formData.digitisation?.applicationComment}
                commentDefaultValue={
                    defaultValues[
                        step2Texts.outline2.form.applicationComment.name
                    ]
                }
                watch={application}
                commentError={step2Texts.outline2.form.applicationComment.name}
                notEditable={displayOnly}
                errors={errors}
                control={control}
            />

            <Step2Question
                questionProps={step2Texts.outline2.form.improvements}
                commentProps={step2Texts.outline2.form.improvementsComment}
                question={step2Texts.outline2.form.questionImprovements}
                explanation={data.digi2?.explainImprovements}
                label={step2Texts.outline2.form.improvements.label}
                questionDefaultValue={
                    formData.digitisation?.targetFields?.improvements?.valid
                }
                commentValue={formData.digitisation?.improvementsComment}
                commentDefaultValue={
                    defaultValues[
                        step2Texts.outline2.form.improvementsComment.name
                    ]
                }
                watch={improvements}
                commentError={step2Texts.outline2.form.improvementsComment.name}
                notEditable={displayOnly}
                errors={errors}
                control={control}
            />

            {data.digi2?.targets.security && security && (
                <>
                    <Step2Question
                        title={step2Texts.outline3.title}
                        questionProps={
                            step2Texts.outline3.form.securityTechnologies
                        }
                        commentProps={
                            step2Texts.outline3.form.securityTechnologiesComment
                        }
                        question={
                            step2Texts.outline3.form
                                .questionSecurityTechnologies
                        }
                        explanation={data.digi2?.securityTechnologies}
                        label={
                            step2Texts.outline3.form.securityTechnologies.label
                        }
                        questionDefaultValue={
                            formData.digitisation?.targetFields
                                ?.securityTechnologies?.valid
                        }
                        commentValue={
                            formData.digitisation?.securityTechnologiesComment
                        }
                        commentDefaultValue={
                            defaultValues[
                                step2Texts.outline3.form
                                    .securityTechnologiesComment.name
                            ]
                        }
                        watch={securityTechnologies}
                        commentError={
                            step2Texts.outline3.form.securityTechnologiesComment
                                .name
                        }
                        notEditable={displayOnly}
                        errors={errors}
                        control={control}
                    />

                    <Step2Question
                        questionProps={step2Texts.outline3.form.securityTarget}
                        commentProps={
                            step2Texts.outline3.form.securityTargetComment
                        }
                        question={
                            step2Texts.outline3.form.questionSecurityTarget
                        }
                        explanation={data.digi2?.securityTargets}
                        label={step2Texts.outline3.form.securityTarget.label}
                        questionDefaultValue={
                            formData.digitisation?.targetFields?.securityTarget
                                ?.valid
                        }
                        commentValue={
                            formData.digitisation?.securityTargetComment
                        }
                        commentDefaultValue={
                            defaultValues[
                                step2Texts.outline3.form.securityTargetComment
                                    .name
                            ]
                        }
                        watch={securityTarget}
                        commentError={
                            step2Texts.outline3.form.securityTargetComment.name
                        }
                        notEditable={displayOnly}
                        errors={errors}
                        control={control}
                    />
                </>
            )}
            {data.digi2?.targets.digitalNetworking && digitalNetworking && (
                <>
                    <Step2Question
                        title={step2Texts.outline4.title}
                        questionProps={step2Texts.outline4.form.valueChainRole}
                        commentProps={
                            step2Texts.outline4.form.valueChainRoleComment
                        }
                        question={
                            step2Texts.outline4.form.questionValueChainRole
                        }
                        explanation={data.digi2?.valueChainRole}
                        label={step2Texts.outline4.form.valueChainRole.label}
                        questionDefaultValue={
                            formData.digitisation?.targetFields?.valueChainRole
                                ?.valid
                        }
                        commentValue={
                            formData.digitisation?.valueChainRoleComment
                        }
                        commentDefaultValue={
                            defaultValues[
                                step2Texts.outline4.form.valueChainRoleComment
                                    .name
                            ]
                        }
                        watch={valueChainRole}
                        commentError={
                            step2Texts.outline4.form.valueChainRoleComment.name
                        }
                        notEditable={displayOnly}
                        errors={errors}
                        control={control}
                    />

                    <Step2Question
                        questionProps={
                            step2Texts.outline4.form.valueChainImprove
                        }
                        commentProps={
                            step2Texts.outline4.form.valueChainImproveComment
                        }
                        question={
                            step2Texts.outline4.form.questionValueChainImprove
                        }
                        explanation={data.digi2?.valueChainImprove}
                        label={step2Texts.outline4.form.valueChainImprove.label}
                        questionDefaultValue={
                            formData.digitisation?.targetFields
                                ?.valueChainImprove?.valid
                        }
                        commentValue={
                            formData.digitisation?.valueChainImproveComment
                        }
                        commentDefaultValue={
                            defaultValues[
                                step2Texts.outline4.form
                                    .valueChainImproveComment.name
                            ]
                        }
                        watch={valueChainImprove}
                        commentError={
                            step2Texts.outline4.form.valueChainImproveComment
                                .name
                        }
                        notEditable={displayOnly}
                        errors={errors}
                        control={control}
                    />
                </>
            )}
            {!displayOnly && (
                <FormFooter
                    displayText={Object.keys(errors).length > 0}
                    text={step2Texts.errorText}
                    gutterTop
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                    >
                        Speichern
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                        onClick={() => handleStepChange(2)}
                        style={{ marginLeft: 8 }}
                    >
                        Weiter
                    </Button>
                </FormFooter>
            )}
        </form>
    );
};

Step2.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

Step2.defaultProps = {
    data: {},
};

export default Step2;
