import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { raffleTexts as rt } from '../../../texts/Forms/Raffle/RaffleTexts';
import TextBlock from '../../TextBlock/TextBlock';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { hasSubmittedProposal } from '../../../util/Helper/UserHasProposal';
import CannotParticipate from './CannotParticipate';
import { sortRaffles } from '../../../util/Raffle/RaffleFunctions';

const IsClosed = (props) => {
    const { rafflePots, raffles } = props;
    const rafflesSorted = raffles.length > 0 ? sortRaffles(raffles) : [];

    // get first raffle because sortRaffles() orders by DESC
    const lastRaffle = rafflesSorted[0];

    if (rafflePots.length === 0 && lastRaffle?.valid !== true)
        return (
            <Alert severity="info">
                <Typography>{rt.closedForever}</Typography>
            </Alert>
        );

    const hasSubProposal = hasSubmittedProposal(raffles);

    const timeOfRaffle =
        raffles.length > 0
            ? moment(rafflesSorted[0].rafflePot.dayOfRaffle)
            : null;

    const wasLastRaffle = Boolean(timeOfRaffle)
        ? moment().month() === timeOfRaffle.month()
        : false;

    if (hasSubProposal) {
        return <CannotParticipate />;
    }

    if (timeOfRaffle > moment()) {
        return (
            <Alert severity="info">
                <TextBlock
                    textBlock={rt.isClosedBefore(timeOfRaffle.format('LL'))}
                />
            </Alert>
        );
    }

    if (lastRaffle?.valid === true) {
        if (props.displayForProposal) {
            return props.child;
        }
        return (
            <Alert severity="success">
                <TextBlock
                    textBlock={rt.isClosedWasDrawn(
                        moment(lastRaffle.rafflePot.submitBegin).format('LLL'),
                        moment(lastRaffle.rafflePot.submitEnd).format('LLL')
                    )}
                />
            </Alert>
        );
    }

    if (!Boolean(timeOfRaffle) || !wasLastRaffle) {
        return (
            <Alert severity="info">
                <TextBlock
                    textBlock={rt.isClosedLastNotParticipated(
                        moment(
                            rafflePots[rafflePots.length - 1].raffleBegin
                        ).format('LLL')
                    )}
                />
            </Alert>
        );
    }

    if (rafflePots.length === 0) {
        return <Alert severity="info">{rt.isClosedWithoutNextRaffle}</Alert>;
    }

    return (
        <Alert severity="info">
            <TextBlock
                textBlock={rt.isClosed(
                    moment(lastRaffle.dayOfRaffle).format('LLL'),
                    moment(lastRaffle.raffleBegin).format('LLL')
                )}
            />
        </Alert>
    );
};

IsClosed.propTypes = {
    rafflePots: PropTypes.array,
    raffles: PropTypes.array,
};

export default IsClosed;
