import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { centToString } from '../../../../util/Money/MoneyFunctions';

const RenderTableFooter = (props) => {
    const { disabled, handleAddNew, total } = props;

    return (
        <TableRow>
            <TableCell />
            <TableCell colSpan={4}>
                <Button
                    onClick={handleAddNew}
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                >
                    Hinzufügen
                </Button>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>
                {centToString(total, false) + ' €'}
            </TableCell>
        </TableRow>
    );
};

RenderTableFooter.propTypes = {
    disabled: PropTypes.bool,
    handleAddNew: PropTypes.func,
    total: PropTypes.string,
};

export default RenderTableFooter;
