export const texts = {
    part0: {
        headline: 'Datenschutzerklärung',
        paragraphs: [
            {
                type: 'p',
                text:
                    'Über das "Antragssystem Digital Jetzt" werden webbasierte Formulare für Förderanträge und Verwendungsnachweise bereit gestellt. Anhand des Benutzer Accounts werden die Anträge und Verwendungsnachweise der Nutzer miteinander verknüpft.[br]' +
                    'Mit den Formularen werden sämtliche Informationen digital erfasst und gespeichert, die für die ordnungsgemäße Prüfung benötigt werden.[br]' +
                    'Soweit im Antrag personenbezogene Daten von Beschäftigten des/der Antragstellers/in oder sonstigen natürlichen Personen enthalten sind, sind diese entsprechend den Datenschutzhinweisen zu informieren und deren Einverständnis einzuholen.[br]',
            },
            {
                type: 'p',
                text:
                    'Die in den Formularen enthaltenen personenbezogenen Daten und sonstigen Angaben werden vom Empfänger des Formulars und seinen Beauftragten im Rahmen seiner/ihrer Zuständigkeit erhoben, verarbeitet und genutzt. Die Möglichkeit zur Weitergabe dieser Daten an andere Stellen richtet sich nach der EU-Datenschutz-Grundverordnung (EU-DSGVO) und dem Bundesdatenschutzgesetz (BDSG).[br]' +
                    'Mit der Abwicklung der Fördermaßnahme hat das Bundesministerium für Wirtschaft und Klimaschutz folgenden Projektträger (PT) beauftragt:[br]' +
                    'DLR Projektträger[br]' +
                    'Heinrich-Konen-Str. 1[br]' +
                    '53227 Bonn',
            },
            {
                type: 'p',
                text:
                    'Unterauftragnehmer:[br]' +
                    'Euronorm GmbH[br]' +
                    'Stralauer Platz 34[br]' +
                    '10243 Berlin',
            },
        ],
    },
    part1: {
        headline: 'I. Name und Anschrift des Verantwortlichen',
        paragraphs: [
            {
                type: 'p',
                text: 'Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes sowie sonstiger datenschutzrechtlicher Bestimmungen ist:',
            },
            {
                type: 'p',
                text:
                    'Deutsches Zentrum für Luft- und Raumfahrt e. V. (DLR)[br]' +
                    'Linder Höhe[br]' +
                    '51147 Köln[br]' +
                    'Tel.: 02203 601-0[br]' +
                    'Fax: 02203 67310[br]' +
                    'E-Mail: contact-dlr@dlr.de[br]' +
                    'www.dlr.de[br]',
            },
        ],
    },
    part2: {
        headline: 'II. Name und Anschrift des Datenschutzbeauftragten',
        paragraphs: [
            {
                type: 'p',
                text: 'Der Datenschutzbeauftragte des Verantwortlichen ist:',
            },
            {
                type: 'p',
                text: 'Uwe Gorschütz[br]datenschutz@dlr.de[br]',
            },
        ],
    },
    part3: {
        headline: 'III. Allgemeines zur Datenverarbeitung',
        paragraphs: [
            {
                type: 'p',
                text: 'Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.',
            },
            {
                type: 'p',
                text: 'Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.',
            },
            {
                type: 'p',
                text: 'Für jede Verarbeitung von personenbezogenen Daten auf unseren Internetseiten finden Sie in dieser Datenschutzerklärung unter dem jeweiligen Stichwort Erläuterungen zu folgenden Aspekten:',
            },
            {
                type: 'p',
                text:
                    'a)Umfang der Verarbeitung personenbezogener Daten[br]' +
                    'b)Rechtsgrundlage für die Verarbeitung personenbezogener Daten[br]' +
                    'c)Zweck der Datenverarbeitung[br]' +
                    'd)Dauer der Speicherung[br]' +
                    'e)Widerspruchs- und Beseitigungsmöglichkeit[br]',
            },
        ],
    },
    part4: {
        headline: 'IV. Bereitstellung der Website und Erstellung von Logfiles',
        paragraphs: [
            {
                type: 'p',
                text:
                    'a. Beschreibung und Umfang der Datenverarbeitung[br]' +
                    'Diese Website erhebt und speichert automatisch in den Server-Logfiles Informationen, die Ihr Browser übermittelt.[br]' +
                    'Folgende Daten werden hierbei erhoben:[br]' +
                    '(1) Informationen über den Browsertyp und die verwendete Version[br]' +
                    '(2) Das Betriebssystem des Nutzers[br]' +
                    '(3) Die IP-Adresse des Nutzers[br]' +
                    '(4) Datum und Uhrzeit des Zugriffs[br]' +
                    '(5) Websites, von denen das System des Nutzers auf unsere Internetseite gelangt [br]' +
                    '(6) Websites, die vom System des Nutzers über unsere Website aufgerufen werden[br]' +
                    'Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.[br]',
            },
            {
                type: 'p',
                text:
                    'b. Rechtsgrundlage für die Datenverarbeitung[br]' +
                    'Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.[br]',
            },
            {
                type: 'p',
                text:
                    'c. Zweck der Datenverarbeitung[br]' +
                    'Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt. [br]' +
                    'In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.[br]',
            },
            {
                type: 'p',
                text:
                    'd. Dauer der Speicherung[br]' +
                    'Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. [br]',
            },
            {
                type: 'p',
                text:
                    'e. Widerspruchs- und Beseitigungsmöglichkeit[br]' +
                    'Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.[br]',
            },
        ],
    },
    part5: {
        headline: 'V. Verwendung von Cookies',
        paragraphs: [
            {
                type: 'p',
                text:
                    'a. Beschreibung und Umfang der Datenverarbeitung[br]' +
                    'Unsere Webseite verwendet Session-Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. [br]' +
                    'Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. [br]',
            },
            {
                type: 'p',
                text:
                    'b. Rechtsgrundlage für die Datenverarbeitung [br]' +
                    'Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.[br]',
            },
            {
                type: 'p',
                text:
                    'c. Zweck der Datenverarbeitung[br]' +
                    'Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.[br]' +
                    'Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.[br]' +
                    'In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO. [br]',
            },
            {
                type: 'p',
                text:
                    'd. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit[br]' +
                    'Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.[br]',
            },
        ],
    },
    part6: {
        headline: 'VI. Registrierung',
        paragraphs: [
            {
                type: 'p',
                text:
                    'a. Beschreibung und Umfang der Datenverarbeitung[br]' +
                    'Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren. Die Daten werden dabei in eine Eingabemaske eingegeben und an uns übermittelt und gespeichert. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:[br]' +
                    '(1) Anrede[br]' +
                    '(2) Titel[br]' +
                    '(3) Vorname[br]' +
                    '(4) Nachname[br]' +
                    '(5) Position im Unternehmen[br]' +
                    '(6) E-Mail Adresse[br]' +
                    '(7) Rechtsverbindlicher Name der Organisation[br]' +
                    '(8) Straße[br]' +
                    '(9) Hausnummer[br]' +
                    '(10) PLZ[br]' +
                    '(11) Ort[br]' +
                    '(12) Telefonnummer[br]' +
                    '[br]' +
                    'Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:[br]' +
                    '(1) Datum und Uhrzeit der Registrierung[br]' +
                    '(2) Status der Registrierung[br]' +
                    'Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers zur Verarbeitung dieser Daten eingeholt.[br]',
            },
            {
                type: 'p',
                text:
                    'b. Rechtsgrundlage für die Datenverarbeitung [br]' +
                    'Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Datenschutz:[br]',
            },
            {
                type: 'p',
                text:
                    'c. Zweck der Datenverarbeitung[br]' +
                    'Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte und Leistungen erforderlich, insbesondere für die Nutzung des elektronischen Formularsystems zur Erstellung von Förderanträgen und Verwendungsnachweisen. Anhand des Benutzer Accounts werden die Anträge und Verwendungsnachweise der Nutzer miteinander verknüpft.  [br]' +
                    'Der Benutzer stellt über die Webanwendung die Informationen bereit, die für die ordnungsgemäße Prüfung von Förderanträgen und Verwendungsnachweisen benötigt werden. Die in den Formularen enthaltenen personenbezogenen Daten und sonstigen Angaben werden vom Empfänger des Formulars und seinen Beauftragten im Rahmen seiner/ihrer Zuständigkeit ausschließlich zum Zweck der Antragsbearbeitung erhoben, verarbeitet und genutzt. Die Möglichkeit zur Weitergabe dieser Daten an andere Stellen richtet sich nach der EU-Datenschutz-Grundverordnung (EU-DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die Möglichkeit zur Weitergabe beinhaltet auch die erforderliche Verarbeitung der erhobenen Daten zum Zweck der Rechnungsprüfung. Soweit im Antrag personenbezogene Daten von Beschäftigten des/der Antragstellers/in oder sonstigen natürlichen Personen enthalten sind, sind diese entsprechend den Datenschutzhinweisen zu informieren und deren Einverständnis einzuholen.[br]' +
                    'Die Bestätigung der Registrierung ist die Voraussetzung für die Speicherung der dort erhobenen Daten. [br]' +
                    'Der Antrag wird an Auftraggeber und in bestimmten Fällen an externe Dienstleister zu Prüfzwecken übermittelt, welche bezüglich der Vertraulichkeit denselben Vorschriften unterliegen. [br]',
            },
            {
                type: 'p',
                text:
                    'd. Dauer der Speicherung[br]' +
                    'Die Registrierungsdaten werden bis zur Auflösung der Registrierung (s. VI e) auf unserem Server gespeichert. [br]',
            },
            {
                type: 'p',
                text:
                    'e. Widerspruchs- und Beseitigungsmöglichkeit[br]' +
                    'Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit abändern lassen. [br]' +
                    'Sie können eine Löschung oder Änderung des Accounts durchführen, indem Sie sich mit dem technischen Ansprechpartner Projektträger im Deutschen Zentrum für Luft- und Raumfahrt e.V., Management/IKT-Service per E-Mail unter der Adresse [br]' +
                    'pt-webservice@dlr.de in Verbindung setzen. [br]' +
                    '[br]' +
                    'Nach der verbindlichen digitalen Einreichung von Unterlagen ist eine vorzeitige Löschung der Registrierungsdaten jedoch nicht mehr möglich. Diese Daten werden in diesem Fall anonymisiert. [br]',
            },
        ],
    },
    part7: {
        headline: 'VII. Rechte der betroffenen Person',
        paragraphs: [
            {
                type: 'p',
                text:
                    'Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:[br]' +
                    'a. Auskunftsrecht[br]' +
                    'Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. [br]' +
                    'Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:[br]' +
                    '(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;[br]' +
                    '(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;[br]' +
                    '(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;[br]' +
                    '(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;[br]' +
                    '(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung; [br]' +
                    '(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;[br]' +
                    '(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;[br]' +
                    '(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.[br]' +
                    'Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.[br]',
            },
            {
                type: 'p',
                text:
                    'b. Recht auf Berichtigung [br]' +
                    'Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.[br]',
            },
            {
                type: 'p',
                text:
                    'c. Recht auf Einschränkung der Verarbeitung[br]' +
                    'Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:[br]' +
                    '(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;[br]' +
                    '(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;[br]' +
                    '(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder[br]' +
                    '(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.[br]' +
                    'Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.[br]' +
                    'Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.[br]',
            },
            {
                type: 'p',
                text:
                    'd. Recht auf Löschung[br]' +
                    'a) Löschungspflicht[br]' +
                    'Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:[br]' +
                    '(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.[br]' +
                    '(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung. [br]' +
                    '(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein. [br]' +
                    '(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet. [br]' +
                    '(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt. [br]' +
                    'b) Information an Dritte[br]' +
                    'Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben. [br]' +
                    'c) Ausnahmen[br]' +
                    'Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist[br]' +
                    '(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;[br]' +
                    '(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;[br]' +
                    '(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;[br]' +
                    '(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder[br]' +
                    '(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.[br]',
            },
            {
                type: 'p',
                text:
                    'f. Recht auf Datenübertragbarkeit[br]' +
                    'Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern[br]' +
                    '(1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und[br]' +
                    '(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.[br]' +
                    'In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.[br]' +
                    'Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.[br]',
            },
            {
                type: 'p',
                text:
                    'g. Widerspruchsrecht[br]' +
                    'Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen. [br]' +
                    'Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.[br]',
            },
            {
                type: 'p',
                text:
                    'h. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung[br]' +
                    'Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.[br]',
            },
            {
                type: 'p',
                text:
                    'i. Recht auf Beschwerde bei einer Aufsichtsbehörde[br]' +
                    'Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. [br]' +
                    'Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.[br]',
            },
        ],
    },
};
