import _ from 'lodash';
import moment from 'moment';

export function fetchMutator(data) {
    const defaultValues = {
        moduleOne: false,
        moduleTwo: false,
        title: '',
        startDate: null,
        endDate: null,
        extendedExecution: '',
        targets: {
            digitizeModel: false,
            digitalTechnologies: false,
            digitalWork: false,
            employeeCompetence: false,
            security: false,
            digitalNetworking: false,
        },
        targetsOther: '',
        explainCommon: '',
        explainApplication: '',
        explainImprovement: '',
        securityTechnologies: '',
        securityTargets: '',
        valueChainRole: '',
        valueChainImprove: '',
    };

    const [{ data: digi2Data }, { data: valueChainData }] = data;

    const fetchData = { ...digi2Data };

    fetchData.other = fetchData.targetsOther || '';
    fetchData.isValueChain = valueChainData.isValueChain || false;

    fetchData.startDate = moment(fetchData.startDate);
    fetchData.endDate = moment(fetchData.endDate);

    fetchData.diff = fetchData.endDate.diff(
        fetchData.startDate,
        'months',
        true
    );

    return _.merge(defaultValues, { ...fetchData, ...fetchData.targets });
}

export function saveMutator(data) {
    const saveData = { ...data };

    saveData.targetsOther = data.other;
    saveData.targets = {
        digitizeModel: data.digitizeModel,
        digitalTechnologies: data.digitalTechnologies,
        digitalWork: data.digitalWork,
        employeeCompetence: data.employeeCompetence,
        security: data.security,
        digitalNetworking: data.digitalNetworking,
    };

    saveData.startDate = data.startDate.format('yyyy-MM-DD');
    saveData.endDate = data.endDate.format('yyyy-MM-DD');

    if (!saveData.extendedExecution) saveData.extendedExecution = '';
    if (!saveData.securityTechnologies) saveData.securityTechnologies = '';
    if (!saveData.securityTargets) saveData.securityTargets = '';
    if (!saveData.valueChainRole) saveData.valueChainRole = '';
    if (!saveData.valueChainImprove) saveData.valueChainImprove = '';

    return saveData;
}
