import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Outline from '../../Partials/Info/Outline';
import { step1Texts } from '../../../texts/Verification/Step1Texts';
import { CircularProgress, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormRadio from '../Components/FormRadio';
import { FORM_TRANSFORMER } from '../../../util/Helper/FormTransformer';
import ConditionalDisplay from '../Components/ConditionalDisplay';
import FormTextArea from '../Components/FormTextArea';
import FormDropdown from '../Components/FormDropdown';
import { Alert } from '@material-ui/lab';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import FormFooter from './FormFooter';
import Typography from '@material-ui/core/Typography';

const Step1 = (props) => {
    const {
        data,
        formData,
        isLoading,
        displayOnly,
        id,
        onDirtyChange,
        handleStepChange,
    } = props;

    const { control, errors, handleSubmit, reset, formState } = useForm({
        defaultValues: {
            hasNewAddress: formData.hasNewAddress,
            ...formData.newAddress,
        },
    });

    const { isDirty } = formState;

    React.useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange]);

    const { enqueueSnackbar } = useSnackbar();

    const handleDataReset = (d) => {
        reset({ hasNewAddress: d.hasNewAddress, ...d.newAddress });
    };

    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
    };
    const handleSuccess = (data) => {
        const d = data.data();
        handleDataReset(d);
        enqueueSnackbar(data.message(), { variant: 'success' });
    };

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onError: handleError,
        onSuccess: handleSuccess,
    });

    const submitCallback = (values) => {
        const { hasNewAddress, ...newAddressValues } = values;
        mutate({
            data: {
                ...formData,
                hasNewAddress: hasNewAddress,
                newAddress:
                    hasNewAddress === true ? { ...newAddressValues } : null,
            },
            id,
            success: 'Erfolgreich gespeichert.',
        });
    };

    React.useEffect(() => {
        if (isLoading) return;
        reset({
            hasNewAddress: formData.hasNewAddress,
            ...formData.newAddress,
        });
    }, [isLoading, formData, reset]);

    if (isLoading) return <FullscreenCircularLoader />;

    return (
        <form onSubmit={handleSubmit(submitCallback)}>
            <Typography variant="h4" component="h1">
                {step1Texts.title}
            </Typography>

            <Alert severity="info" style={{ marginTop: 16 }}>
                {step1Texts.info}
            </Alert>

            <Outline gutterTop={true}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.fkzLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.fkzLabel,
                            }}
                            value={data.fkz}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.nameLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.nameLabel,
                            }}
                            value={data.user?.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.emailLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.emailLabel,
                            }}
                            value={data.user?.email}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.streetLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.streetLabel,
                            }}
                            value={data.user?.street}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.postcodeLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.postcodeLabel,
                            }}
                            value={data.user?.postcode}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.cityLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.cityLabel,
                            }}
                            value={data.user?.city}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={step1Texts.outline1.stateLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline1.stateLabel,
                            }}
                            value={data.user?.state}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Outline>
            <Outline subtitle={step1Texts.outline2.changed.label} gutterTop>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormRadio
                            notEditable={displayOnly}
                            buttons={step1Texts.outline2.changed.buttons}
                            control={control}
                            name={step1Texts.outline2.changed.name}
                            error={errors[step1Texts.outline2.changed.name]}
                            defaultValue={
                                formData[step1Texts.outline2.changed.name]
                            }
                            rules={step1Texts.outline2.changed.rules}
                            {...FORM_TRANSFORMER.radioBool}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0px 8px' }}>
                        <ConditionalDisplay
                            field={step1Texts.outline2.changed.name}
                            control={control}
                            display={true}
                        >
                            <Typography
                                variant="subtitle1"
                                component="h3"
                                style={{ marginTop: 16 }}
                            >
                                {step1Texts.outline2.authorizedPersonHeadline}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormDropdown
                                        label={
                                            step1Texts.outline2.salutation.label
                                        }
                                        rules={
                                            step1Texts.outline2.salutation.rules
                                        }
                                        name={
                                            step1Texts.outline2.salutation.name
                                        }
                                        control={control}
                                        notEditable={displayOnly}
                                        error={
                                            errors[
                                                step1Texts.outline2.salutation
                                                    .name
                                            ]
                                        }
                                        menuItems={
                                            step1Texts.outline2.salutation
                                                .menuItems
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormDropdown
                                        label={step1Texts.outline2.title.label}
                                        name={step1Texts.outline2.title.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        error={
                                            errors[
                                                step1Texts.outline2.title.name
                                            ]
                                        }
                                        menuItems={
                                            step1Texts.outline2.title.menuItems
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.firstName
                                                    .name
                                            ]
                                        }
                                        name={
                                            step1Texts.outline2.firstName.name
                                        }
                                        control={control}
                                        notEditable={displayOnly}
                                        label={
                                            step1Texts.outline2.firstName.label
                                        }
                                        multiline={false}
                                        rules={
                                            step1Texts.outline2.firstName.rules
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.lastName
                                                    .name
                                            ]
                                        }
                                        name={step1Texts.outline2.lastName.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={
                                            step1Texts.outline2.lastName.label
                                        }
                                        multiline={false}
                                        rules={
                                            step1Texts.outline2.lastName.rules
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormDropdown
                                        label={
                                            step1Texts.outline2.position.label
                                        }
                                        name={step1Texts.outline2.position.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        rules={
                                            step1Texts.outline2.position.rules
                                        }
                                        error={
                                            errors[
                                                step1Texts.outline2.position
                                                    .name
                                            ]
                                        }
                                        menuItems={
                                            step1Texts.outline2.position
                                                .menuItems
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.email.name
                                            ]
                                        }
                                        type="email"
                                        name={step1Texts.outline2.email.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.email.label}
                                        multiline={false}
                                        rules={step1Texts.outline2.email.rules}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.phone.name
                                            ]
                                        }
                                        type="phone"
                                        name={step1Texts.outline2.phone.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.phone.label}
                                        multiline={false}
                                        rules={step1Texts.outline2.phone.rules}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        type="phone"
                                        name={step1Texts.outline2.fax.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.fax.label}
                                        multiline={false}
                                    />
                                </Grid>
                            </Grid>

                            <Typography
                                variant="subtitle1"
                                component="h3"
                                style={{ marginTop: 16 }}
                            >
                                {step1Texts.outline2.companyHeadline}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={10}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.street.name
                                            ]
                                        }
                                        name={step1Texts.outline2.street.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.street.label}
                                        multiline={false}
                                        rules={step1Texts.outline2.street.rules}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.houseNumber
                                                    .name
                                            ]
                                        }
                                        notEditable={displayOnly}
                                        control={control}
                                        name={
                                            step1Texts.outline2.houseNumber.name
                                        }
                                        rules={
                                            step1Texts.outline2.houseNumber
                                                .rules
                                        }
                                        label={
                                            step1Texts.outline2.houseNumber
                                                .label
                                        }
                                        multiline={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[step1Texts.outline2.plz.name]
                                        }
                                        name={step1Texts.outline2.plz.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.plz.label}
                                        multiline={false}
                                        rules={step1Texts.outline2.plz.rules}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormTextArea
                                        error={
                                            errors[
                                                step1Texts.outline2.city.name
                                            ]
                                        }
                                        name={step1Texts.outline2.city.name}
                                        control={control}
                                        notEditable={displayOnly}
                                        label={step1Texts.outline2.city.label}
                                        multiline={false}
                                        rules={step1Texts.outline2.city.rules}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormDropdown
                                        label={step1Texts.outline2.state.label}
                                        name={step1Texts.outline2.state.name}
                                        notEditable={displayOnly}
                                        control={control}
                                        error={
                                            errors[
                                                step1Texts.outline2.state.name
                                            ]
                                        }
                                        menuItems={
                                            step1Texts.outline2.state.items
                                        }
                                        rules={step1Texts.outline2.state.rules}
                                    />
                                </Grid>
                            </Grid>
                        </ConditionalDisplay>
                    </Grid>
                </Grid>
            </Outline>
            <Outline
                title={step1Texts.outline3.title}
                subtitle={step1Texts.outline3.subtitle}
                gutterTop
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={step1Texts.outline3.startLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline3.startLabel,
                            }}
                            value={data.start}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={step1Texts.outline3.endLabel}
                            inputProps={{
                                'aria-label': step1Texts.outline3.endLabel,
                            }}
                            value={data.end}
                            disabled
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Outline>
            {!displayOnly && (
                <FormFooter
                    displayText={Object.keys(errors).length > 0}
                    text={step1Texts.errorText}
                    gutterTop
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                    >
                        Speichern
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                        onClick={() => handleStepChange(1)}
                        style={{ marginLeft: 8 }}
                    >
                        Weiter
                    </Button>
                </FormFooter>
            )}
        </form>
    );
};

Step1.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

Step1.defaultProps = {
    data: {},
};

export default Step1;
