import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import TextBlock from '../../../TextBlock/TextBlock';
import { centToString } from '../../../../util/Money/MoneyFunctions';

const ProofTotal = (props) => {
    const { deductInputTax, className, sumDevice, sumActivity, register } =
        props;

    return (
        <div className={className}>
            {deductInputTax ? (
                <TextBlock textBlock={fpt.parser.sumTax} />
            ) : (
                <TextBlock textBlock={fpt.parser.sumNoTax} />
            )}
            <TextField
                fullWidth
                label={fpt.proof.sum.sum}
                value={
                    centToString(
                        parseInt(sumDevice) + parseInt(sumActivity),
                        false
                    ) + ' €'
                }
                InputProps={{
                    readOnly: true,
                }}
                variant="filled"
            />
            <input
                type="hidden"
                ref={register()}
                name={'sumDevice'}
                value={sumDevice}
            />
            <input
                type="hidden"
                ref={register()}
                name={'sumActivity'}
                value={sumActivity}
            />
        </div>
    );
};

ProofTotal.propTypes = {
    index: PropTypes.number,
    deductInputTax: PropTypes.bool,
    className: PropTypes.string,
};

export default ProofTotal;
