export const texts = {
    title: 'Angaben zum Unternehmen',
    isCommunalCompany: {
        title: 'Nicht antragsberechtigte Unternehmen / Organisationen',
        label: 'Ist das antragstellende Unternehmen einer der nachfolgend genannten Rechtsformen bzw. Kriterien zuzuordnen?: Unternehmen mit öffentlicher Beteiligung; gemeinnützige Unternehmen; Unternehmen des öffentlichen Rechts und Religionsgemeinschaften sowie deren Beteiligungen; Unternehmen in Gründung; Vereine; Stiftungen; Insolvente Unternehmen oder Unternehmen in Schwierigkeiten (VO (EU) Nr. 651/2014).',
        error: 'Bitte beantworten Sie diese Frage.',
        ariaLabel: 'Kommunales Unternehmen mit öffentlicher Beteiligung.',
    },
    noCommunalCompany: {
        title: 'Antrag nicht förderfähig',
        body: 'Ihr Unternehmen ist nicht antragsberechtigt – Begründung (siehe Webseite des Bundesministeriums für Wirtschaft und Klimaschutz unter „Wichtige Fragen und Antworten rund um die Förderung“)',
    },
    corporateForm: {
        title: 'Registereinträge',
        label: 'Es handelt sich um ein Unternehmen (bitte nur eine Option auswählen),',
        hasHRGNumber: {
            label: '… das im Handelsregister eingetragen ist.',
            error: 'Fehler bei der HRG-Nummer',
            ariaLabel: 'Unternehmen, das im Handelsregister eingetragen ist',
        },
        hRGNumber: {
            label: 'HRG-Nummer',
            error: 'Bitte geben Sie ihre HRG-Nummer ein.',
        },
        hasTradeRegistered: {
            label: '… das ein Gewerbe angemeldet hat.',
            error: 'Fehler beim Gewerbe Button',
            ariaLabel: 'Unternehmen, das ein Gewerbe angemeldet hat',
        },
        tradeLicenceNumber: {
            label: 'Gewerbeschein-Nr.',
            error: 'Bitte geben Sie ihre Gewerbeschein-Nr. ein.',
        },
        isCraftCompany: {
            label: '… das ein Handwerk ausübt.',
            error: 'Fehler beim Handwerk Button',
            ariaLabel: 'Unternehmen, das ein Handwerk ausübt',
        },
        craftNumber: {
            label: 'Nummer Eintrag in der Handwerksrolle',
            error: 'Bitte geben Sie ihre Nummer in der Handwerksrolle ein.',
        },
        isFreelance: {
            label: '…dessen Unternehmenszweck auf freiberuflicher Tätigkeit basiert.',
            error: 'Fehler beim freiberuflicher Tätigkeit Button',
            ariaLabel: 'Unternehmenszweck ist freiberufliche Tätigkeit',
        },
        taxNumber: {
            label: 'Steuernummer',
            error: 'Bitte geben Sie ihre Steuernummer ein.',
        },
    },
    legalForm: {
        title: 'Rechtsform',
        subhead: 'Bitte geben Sie hier die Rechtsform ihres Unternehmens an.',
        label: 'Rechtsform',
        error: 'Bitte geben Sie eine Rechtsform an.',
        hint: 'Sofern Sie die Rechtsform GbR führen, erklären Sie sich damit einverstanden, dass jegliche Korrespondenz zu diesem Antrag ausschließlich mit der hier benannten Person geführt wird.',
    },
    otherLegalForm: {
        label: 'Sonstige Rechtsform',
        error: 'Bitte die Rechtsform angeben.',
    },
    isFoundedLast2Y: {
        title: 'Unternehmensgründung',
        label: 'Das  Unternehmen ist innerhalb der letzten 24 Monate  gegründet worden.',
        error: 'Bitte geben Sie an, ob das Unternehmen innerhalb der letzten 24 Monate  gegründet worden ist.',
        ariaLabel:
            'Unternehmen ist innerhalb der letzten 24 Monate  gegründet worden.',
    },
    dateFounded: {
        label: 'Eingabe Gründungsdatum',
        error: 'Bitte geben Sie das Gründungsdatum ein',
    },
    uploadBalanceSheet: {
        label: 'Wir bitten Sie, die vorhandenen Bilanzen  / die Eröffnungsbilanz hochzuladen.',
        error: 'Bitte laden Sie die vorhandenen Bilanzen  / die Eröffnungsbilanz hoch.',
        ariaLabel:
            'Upload-Bereich für die vorhandenen Bilanzen  / die Eröffnungsbilanz.',
    },
    sectorKey: {
        title: 'Branche',
        label: 'Welcher Branche gehört Ihr Unternehmen an?',
        error: 'Bitte wählen Sie den Branchenschlüssel aus.',
        ariaLabel: 'Auswahl des Branchenschlüssels.',
    },
    isDeductInputTax: {
        title: 'Vorsteuerabzugsberechtigung',
        info: 'Vorsteuerabzugsberechtigt gem. Umsatzsteuergesetz (UStG) § 15 und 15 a sind Unternehmen, die Umsatzsteuer erheben und diese an das Finanzamt weiterleiten.',
        label: 'Das Unternehmen ist vorsteuerabzugsberechtigt.',
        error: 'Bitte geben Sie an, ob das Unternehmen vorsteuerabzugsberechtigt ist',
        ariaLabel: 'Auswahl, ob das Unternehmen vorsteuerabzugsberechtigt ist.',
    },
    companyOption: {
        title: 'Unternehmenstyp',
        addShareholderCompany: 'Verbundenes Unternehmen hinzufügen',
        removeShareholderCompany: 'Verbundenes Unternehmen entfernen',
        desc:
            'Im  Folgenden geht es darum, festzustellen, ob Ihr Unternehmen als kleines oder mittleres Unternehmen (KMU) grundsätzlich gefördert werden kann. Entscheidend sind: (1) die Größe des Unternehmens (Zahl der Mitarbeitenden); (2) die Beziehung zu anderen Unternehmen (Besitz- und Beteiligungsstrukturen oder Kontroll- und Beherrschungsverhältnisse). ' +
            'Dies hat Auswirkungen auf die Förderquote  und damit auf die Berechnung der Höhe der De-minimis-Beihilfe. Dafür ist nicht allein auf ihre eigenen Unternehmensdaten, sondern auch auf die des/der „verbundenen Unternehmen“ abzustellen.' +
            ' Falsche Angaben können strafrechtliche Konsequenzen haben, da es sich um „subventionserhebliche Angaben“ handelt.',
        hint: 'Auch wenn Ihr Unternehmen rechtlich eigenständig ist, kann es durch Besitz- und Beteiligungsverhältnisse im Verbund zu betrachten sein (hinsichtlich: Kapitalbeteiligung; Kontrolle von Stimmrechten; Rechts zur Ausübung eines beherrschenden Einflusses). Hierbei geht es nicht um ein eventuelles gemeinsames geschäftliches Tätigwerden zusammen mit anderen Unternehmen.',
        label: 'Unternehmenstyp',
        types: [
            {
                value: '0', //'independentCompany',
                title: 'Es handelt sich beim antragstellenden Unternehmen um ein eigenständiges Unternehmen.',
                info: 'Ein Unternehmen ist eigenständig, wenn es Anteile von weniger als 25% an einem anderen Unternehmen hält und / oder es sich zu weniger als 25% unmittelbar im Besitz eines anderen Unternehmens bzw. einer öffentlichen Stelle oder im gemeinsamen Besitz mehrerer verbundener Unternehmen bzw. öffentlicher Stellen befindet. (Artikel 3 des Anhangs I der Verordnung (EU) Nr. 651/2014 der Kommission vom 17. Juni 2014)',
            },
            {
                value: '1', //'partnerCompany',
                title: 'Es handelt sich beim antragstellenden Unternehmen um ein Partnerunternehmen.',
                info: 'Ein Unternehmen ist Partnerunternehmen eines anderen Unternehmens, (1) wenn es Anteile von mehr 25% und weniger als 50% an einem anderen Unternehmen hält oder andere Unternehmen einen Anteil zwischen 25% und 50% an dem antragstellenden Unternehmen halten. (Artikel 3 des Anhangs I der Verordnung (EU) Nr. 651/2014 der Kommission vom 17. Juni 2014)',
            },
            {
                value: '2', //'shareholderCompany',
                title: 'Es handelt sich beim antragstellenden Unternehmen um ein verbundenes Unternehmen.',
                info: 'Ein Unternehmen gilt als ein mit anderen Unternehmen verbundenes Unternehmen, wenn es Anteile ab 50% an einem anderen Unternehmen hält oder andere Unternehmen einen Anteil ab 50% an dem antragstellenden Unternehmen halten. Das Unternehmen hat bezogen auf ein anderes Unternehmen die mittel- oder unmittelbare Kontrolle der Mehrheit des Kapitals oder der Stimmrechte oder es übt einen beherrschenden Einfluss aus, oder es gehört einer Unternehmensgruppe an. Es erstellt einen konsolidierenden Abschluss zusammen mit einem anderen Unternehmen. (Artikel 3 des Anhangs I der Verordnung (EU) Nr. 651/2014 der Kommission vom 17. Juni 2014)',
            },
        ],
        error: 'Bitte machen Sie eine Auswahl zum Unternehmenstyp',
        ariaLabel: 'Angabe zum Unternehmenstyp',
    },
    shareholderCompany: {
        title: 'Verbundenes Unternehmen',

        companyName: {
            label: 'Name',
            error: 'Bitte geben Sie den Namen des Unternehmens ein.',
        },
        legalForm: {
            label: 'Rechtsform',
            error: 'Bitte geben Sie eine Rechtsform an.',
        },
        city: {
            label: 'Sitz / Stadt',
            error: 'Bitte geben Sie den Sitz bzw. die Stadt an.',
        },
        intendsProposal: {
            info: {
                paragraphs: [
                    {
                        type: 'p',
                        text: 'Bei verbundenen Unternehmen kann nur ein Antrag aus dem Unternehmensverbund bei „Digital Jetzt“ gestellt bzw. gefördert werden. Mehrere parallel laufende Anträge sind nicht möglich.',
                    },
                    {
                        type: 'p',
                        text: 'Eine erneute Antragstellung ist erst nach Beendigung des laufenden Projektes einschließlich der abschließenden Prüfung des Verwendungsnachweises möglich.',
                    },
                    {
                        type: 'p',
                        text: 'Bitte bestätigen Sie:',
                    },
                    {
                        type: 'list',
                        text: '[li]Es ist keine Antragstellung dieses verbundenen Unternehmens geplant.[li][li]Es ist eine weitere Antragstellung geplant. Diese erfolgt erst nach Beendigung des laufenden Projektes. Das antragstellende Unternehmen informiert das verbundenen Unternehmen entsprechend.[li]',
                    },
                ],
            },
            error: 'Bitte bestätigen Sie diese Frage.',
            label: 'Bestätigen',
            ariaLabel:
                'Angabe, ob das verbundene Unternehmen eine Antragstellung in "Digital Jetzt" beabsichtigt.',
        },
        otherLegalForm: {
            label: 'Sonstige Rechtsform',
            error: 'Bitte die Rechtsform angeben',
        },
    },
    employeeCheck: {
        desc: (fileLink) => ({
            paragraphs: [
                {
                    type: 'p',
                    text: 'Bei der Anzahl der Mitarbeitenden werden sämtliche Mitarbeitende berücksichtigt, die zum Zeitpunkt der Antragstellung',
                },
                {
                    type: 'list',
                    text: '[li]beim Antragsteller selbst sowie[li][li]bei mit dem Antragsteller verbundenen Unternehmen sowie[li][li]Partnerunternehmen (anteilig)[li]',
                },
                {
                    type: 'p',
                    text: `beschäftigt sind. Die Ermittlung der Zahl der Mitarbeitenden erfolgt in Vollzeitäquivalenten. Die Informationen aus dem „[a|${fileLink}]Infoblatt Mitarbeiteranzahl Verbundene und Partnerunternehmen[a]“ wurden beachtet.`,
                },
            ],
        }),
        error: {
            validate: (value) =>
                value === 'true' || 'Bitte bestätigen Sie diese Angabe.',
        },
        alert: 'Bei der Ermittlung der Anzahl der Mitarbeitenden sind Mitarbeitende von Partner- bzw. verbundenen Unternehmen zwingend mit einzubeziehen.',
    },
    employeesCount: {
        title: 'Zahl der Mitarbeitenden',
        desc: (fileLink) => ({
            paragraphs: [
                {
                    text: `Bitte geben Sie im Folgenden die Zahl der Mitarbeitenden an. Neben den Daten des eigenen Unternehmens sind im Einzelfall auch die Daten von „verbundenen Unternehmen“ und/oder „Partnerunternehmen“ einzurechnen. Informationen über Unternehmensverflechtungen entnehmen Sie dem „[a|${fileLink}]Infoblatt Verbundene und Partnerunternehmen[a]“.`,
                },
            ],
        }),
        hint1:
            'In die Zählung der Mitarbeitenden gehen ein: Lohn- und Gehaltsempfänger; für das Unternehmen tätige Personen, die in einem Unterordnungsverhältnis zu diesem stehen und nach nationalem Recht Arbeitnehmern gleichgestellt sind; Arbeitnehmerinnen in Mutterschutz sowie mitarbeitende Eigentümer und Teilhaber.\n' +
            'Nicht bei der Zählung der Mitarbeitenden berücksichtigt werden: Auszubildende oder in der beruflichen Ausbildung stehende Personen, die einen Lehr- beziehungsweise Berufsausbildungsvertrag haben, Praktikanten, Mitarbeitende in  der Elternzeit und Mitarbeitende, deren Arbeitsverhältnis ruht.',
        hint2:
            'Berechnung der Gesamtzahl bzgl.: \n' +
            '(1) Mitarbeitende im Partnerunternehmen: Die Zahl der Mitarbeitenden des Partnerunternehmens ist anteilig zu berücksichtigen. Der Anteil entspricht im Prozentsatz dem des Umfangs der Beteiligung. \n' +
            '(2) Mitarbeitende in verbundenen Unternehmen: Die Zahl der Mitarbeitenden des verbundenen Unternehmens fließt vollständig in die Berechnung ein.',
        hint3:
            'Es ist bei der Berechnung die Anzahl der Vollzeitäquivalente (VZÄ ) als Hilfsgröße anzugeben. Ein VZÄ beruht auf einer Vollzeitstelle, für die eine festgelegte Stundenzahl pro Woche definiert ist. Je nach Tarifvertrag können dies bspw. 40 oder 35 Stunden sein. \n' +
            'Berechnung VZÄ gesamt: Summe der von allen Mitarbeitenden geleisteten Arbeitsstunden pro Woche dividiert durch das übliche Jahresmittel der Stunden, die Vollzeitbeschäftigte i.d.R. in der Woche erbringen.\n',
        label: 'Zahl der Mitarbeitenden',
        types: [
            {
                value: '0',
                title: '0 bis 2',
                info: 'Hinweis: Ihr Unternehmen ist nicht antragsberechtigt: Ausdrücklich von der Förderung ausgenommen sind Unternehmen mit weniger als drei Mitarbeitenden.',
            },
            {
                value: '3',
                title: '3 bis 10',
            },
            {
                value: '11',
                title: '11 bis 50',
            },
            {
                value: '51',
                title: '51 bis 250',
            },
            {
                value: '251',
                title: '251 bis 499',
            },
            {
                value: '500',
                title: '500 und mehr',
                info: 'Hinweis: Ihr Unternehmen ist nicht antragsberechtigt: Ausdrücklich von der Förderung ausgenommen sind Unternehmen mit mehr als 499 Mitarbeitenden.',
            },
        ],
        valueError: 'Fehler bei der Anzahl der Mitarbeitenden',
        error: 'Bitte machen Sie eine Auswahl zur Zahl der Mitarbeitenden.',
        ariaLabel: 'Angabe zur Zahl der Mitarbeitenden',
    },
};
