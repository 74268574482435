import { Response } from '../../../util/DataTypes/Response';
import { fetchMutator, saveMutator } from './Digi2Mutators';
import { api } from '../AxiosConfig/Config';

export function fetchDigi2Data(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const digi2Data = api().get(
                    `/action/proposal/${proposalId}/step_6`
                );
                const valueChainInfo = api().get(
                    `/action/proposal/${proposalId}/step_0`
                );

                const responses = await Promise.all([
                    digi2Data,
                    valueChainInfo,
                ]);

                resolve(fetchMutator(responses));
            } catch (e) {
                reject(
                    Response.Error(
                        'Die DigiPlan 2 Daten konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function saveDigi2Data(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/action/proposal/${proposalId}/step_6`,
                saveMutator(data)
            );
            resolve(
                Response.Success('DigiPlan 2 Daten erfolgreich gespeichert')
            );
        } catch (e) {
            reject(
                Response.Error(
                    'DigiPlan 2 Daten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
