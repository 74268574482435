export function mutateMinimiYear(year) {
    switch (year) {
        case 'one':
            return new Date().getFullYear();
        case 'two':
            return new Date().getFullYear() - 1;
        case 'three':
            return new Date().getFullYear() - 2;
        default:
            return new Date().getFullYear();
    }
}
