import React from 'react';
import PropTypes from 'prop-types';
import QuestionDigi3 from '../Digi3/QuestionDigi3';
import { step3Texts } from '../../../texts/Verification/Step3Texts';
import Outline from '../../Partials/Info/Outline';

const QuestionStep3 = (props) => {
    const {
        originalQuestions,
        questions,
        originalDefaultValue,
        defaultValue,
        unregister,
        register,
        errors,
        notEditable,
    } = props;

    const allQuestions = questions.map(function (question, index) {
        return {
            proposal: originalQuestions?.[index],
            verification: question,
            proposalValue: originalDefaultValue?.[index],
            verificationValue: defaultValue?.[index],
        };
    });

    function setTooltip(question) {
        return '99' === question.proposalValue
            ? step3Texts.noUnknownAnswer
            : '';
    }

    return (
        <>
            {allQuestions.map((question, index) => (
                <Outline
                    subtitle={question.verification.text}
                    key={`question.${index}`}
                    gutterTop
                >
                    {question.proposal && question.proposalValue && (
                        <QuestionDigi3
                            key={`proposals.${question.proposal.name}`}
                            error={errors}
                            name={`orgName.${question.proposal.name}`}
                            answers={question.proposal.answers}
                            tooltip={question.proposal.tooltip}
                            text={step3Texts.previouslyAnswered}
                            isShown={false}
                            notEditable={true}
                            unregister={unregister}
                            register={register}
                            defaultValue={question.proposalValue}
                        />
                    )}
                    <QuestionDigi3
                        key={question.verification.name}
                        error={errors}
                        name={question.verification.name}
                        answers={question.verification.answers}
                        tooltip={setTooltip(question)}
                        text={
                            question.proposalValue ? step3Texts.correction : ''
                        }
                        isShown={true}
                        notEditable={notEditable}
                        unregister={unregister}
                        register={register}
                        defaultValue={question.verificationValue}
                    />
                </Outline>
            ))}
        </>
    );
};

QuestionStep3.propTypes = {
    originalQuestions: PropTypes.array.isRequired,
    questions: PropTypes.array.isRequired,
    originalDefaultValue: PropTypes.object,
    defaultValue: PropTypes.array,
    unregister: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    notEditable: PropTypes.bool,
    disabled: PropTypes.bool,
};

QuestionDigi3.defaultProps = {
    notEditable: false,
};

export default QuestionStep3;
