import create from 'zustand';
import { produce } from 'immer';
import uniqid from 'uniqid';

const initial = {
    companies: [],

    oneMainA: [],
    oneChildA: {},
    oneMainB: [],
    oneChildB: {},

    twoMainA: [],
    twoChildA: {},
    twoMainB: [],
    twoChildB: {},

    threeMainA: [],
    threeChildA: {},
    threeMainB: [],
    threeChildB: {},

    activeEdit: 0,
};

const empty = (prefix = undefined) => ({
    fundingAmount: '',
    investmentAmount: '',
    fkz: '',
    type: '',
    provider: '',
    key: uniqid(prefix),
});

const stateAndActions = (set) => ({
    ...initial,
    initialize: (initData) =>
        set(
            produce((state) => {
                state.companies = initData.companies || [];
                state.oneMainA = initData.oneMainA || [];
                state.oneMainB = initData.oneMainB || [];
                state.twoMainA = initData.twoMainA || [];
                state.twoMainB = initData.twoMainB || [];
                state.threeMainA = initData.threeMainA || [];
                state.threeMainB = initData.threeMainB || [];
                state.oneChildA = initData.oneChildA || {};
                state.oneChildB = initData.oneChildB || {};
                state.twoChildA = initData.twoChildA || {};
                state.twoChildB = initData.twoChildB || {};
                state.threeChildA = initData.threeChildA || {};
                state.threeChildB = initData.threeChildB || {};
            })
        ),
    changeEditCount: (change) =>
        set(
            produce((state) => {
                change > 0 ? state.activeEdit++ : state.activeEdit--;
            })
        ),
    createNewMain: (number, ab, values = null) =>
        set(
            produce((state) => {
                const key = `${number}Main${ab.toUpperCase()}`;
                state[key].push(
                    values ? { ...values, key: uniqid(key) } : empty(key)
                );
            })
        ),
    removeMain: (number, ab, index) =>
        set(
            produce((state) => {
                const key = `${number}Main${ab.toUpperCase()}`;
                state[key].splice(index, 1);
            })
        ),
    createNewEmptyChild: (number, ab, companyName) =>
        set(
            produce((state) => {
                const key = `${number}Child${ab.toUpperCase()}`;
                state[key][companyName] = [];
            })
        ),
    removeChild: (number, ab, companyName) =>
        set(
            produce((state) => {
                const key = `${number}Child${ab.toUpperCase()}`;
                delete state[key][companyName];
            })
        ),
    changeMain: (number, ab, index, newValues) =>
        set(
            produce((state) => {
                const key = `${number}Main${ab.toUpperCase()}`;
                state[key][index] = { ...state[key][index], ...newValues };
            })
        ),
    changeChild: (number, ab, companyName, index, newValue) =>
        set(
            produce((state) => {
                const key = `${number}Child${ab.toUpperCase()}`;
                state[key][companyName][index] = {
                    ...state[key][companyName][index],
                    ...newValue,
                };
            })
        ),
    removeChildEntry: (number, ab, companyName, index) =>
        set(
            produce((state) => {
                const key = `${number}Child${ab.toUpperCase()}`;
                state[key][companyName].splice(index, 1);
            })
        ),
    addNewChild: (number, ab, companyName, values = null) =>
        set(
            produce((state) => {
                const key = `${number}Child${ab.toUpperCase()}`;
                state[key][companyName].push(
                    values ? { ...values, key: uniqid(key) } : empty(key)
                );
            })
        ),
});

export const useStore = create((set) => stateAndActions(set));
