import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../../TextBlock/TextBlock';
import { texts as fft } from '../../../texts/Forms/FinalizeFormTexts';
import Alert from '@material-ui/lab/Alert';
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useForm } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../lib/api/Files/FileActions';
import { useParams } from 'react-router-dom';
import FinalizeDownloadLink from './FinalizeDownloadLink';
import FinalizeProposalPdf from './FinalizeProposalPdf';
import Typography from '@material-ui/core/Typography';
import FinalizeInfo from './FinalizeInfo';
import DialogWithConfirmation from '../../Partials/Dialog/DialogWithConfirmation';
import Button from '@material-ui/core/Button';
import { downloadPdf } from '../../../lib/api/Finalize/FinalizeActions';

const FinalizeForm = (props) => {
    const { defaultValues, formId, onSubmit } = props;
    const { proposalId } = useParams();
    const { errors, handleSubmit, register, watch } = useForm({
        defaultValues: defaultValues,
    });
    const documentTransmission = watch('documentTransmission');

    const [file, setFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);
    const [signatureValidityError, setSignatureValidityError] =
        React.useState(null);
    const [hasDownloaded, setHasDownloaded] = React.useState(false);
    const [
        openConfirmDigitalSignatureDialog,
        setOpenConfirmDigitalSignatureDialog,
    ] = React.useState(false);
    const [confirmedDigitalSignature, setConfirmedDigitalSignature] =
        React.useState(false);

    React.useEffect(() => {
        setOpenConfirmDigitalSignatureDialog(
            documentTransmission === 'b' && !confirmedDigitalSignature
        );
    }, [
        setOpenConfirmDigitalSignatureDialog,
        documentTransmission,
        confirmedDigitalSignature,
    ]);

    const submitCallback = (values) => {
        if (documentTransmission === 'b' && !file) {
            setFileError(true);
            return;
        }
        if (documentTransmission === 'b' && !confirmedDigitalSignature) {
            setSignatureValidityError(true);
            return;
        }

        const additional = (() => {
            if (!file) return null;
            if (documentTransmission === 'a' && file) {
                return {
                    id: file['@id'],
                    media:
                        file.mediaLinks.length > 0
                            ? file.mediaLinks[0]['@id']
                            : file.mediaLinks['@id'],
                    action: 'delete',
                };
            }
            return {
                id: file['@id'],
                media:
                    file.mediaLinks.length > 0
                        ? file.mediaLinks[0]['@id']
                        : file.mediaLinks['@id'],
                action: 'patch',
            };
        })();

        if (!hasDownloaded) return;

        onSubmit({ values, additional });
    };

    const filesChanged = (files) => {
        files.forEach((file) => {
            if (file.uploadedAt) setFile(file);
        });

        if (files.length === 0 && file) {
            setFile(null);
        }
    };

    const onCompleteCallback = () => {
        setHasDownloaded(true);
    };

    if (!defaultValues.proposalPdf) return <FinalizeInfo />;

    return (
        <form
            id={formId}
            onSubmit={handleSubmit(submitCallback)}
            style={{ paddingBottom: '32px' }}
        >
            <TextBlock textBlock={fft.title} headlineVariant="h4" />

            <input
                type="hidden"
                name="downloaded"
                value={hasDownloaded}
                ref={register({
                    validate: (value) =>
                        value === 'true' || 'Sie müssen das PDF herunterladen',
                })}
            />
            <FinalizeProposalPdf
                onComplete={onCompleteCallback}
                downloadFunc={downloadPdf}
                id={proposalId}
            />

            <TextBlock
                textBlock={fft.text0}
                headlineVariant="h6"
                parserOptions={{
                    components: { a: { component: FinalizeDownloadLink } },
                }}
                listProps={{ component: 'ol' }}
            />
            <Alert severity="info" style={{ marginTop: '16px' }}>
                <TextBlock textBlock={fft.hint} />
            </Alert>
            <FormControl
                error={!!errors.documentTransmission?.message}
                style={{ marginTop: '16px' }}
            >
                <FormLabel>
                    <TextBlock textBlock={fft.text1} />
                </FormLabel>
                <RadioGroup
                    name="documentTransmission"
                    defaultValue={defaultValues.documentTransmission}
                >
                    <FormControlLabel
                        label={fft.static.titleA}
                        control={
                            <Radio
                                color="primary"
                                inputRef={register(fft.static.error)}
                                value="a"
                            />
                        }
                    />
                    <FormControlLabel
                        label={fft.static.titleB}
                        control={
                            <Radio
                                color="primary"
                                inputRef={register(fft.static.error)}
                                value="b"
                            />
                        }
                    />
                </RadioGroup>
                {errors.documentTransmission && (
                    <FormHelperText>
                        {errors.documentTransmission.message}
                    </FormHelperText>
                )}
            </FormControl>
            {signatureValidityError && documentTransmission === 'b' && (
                <Alert severity="error" style={{ marginTop: 16 }}>
                    <Typography
                        style={{ display: 'inline-block', marginRight: 16 }}
                    >
                        {fft.signatureError}
                    </Typography>
                    <Button
                        variant={'outlined'}
                        onClick={() =>
                            setOpenConfirmDigitalSignatureDialog(true)
                        }
                    >
                        Bestätigen
                    </Button>
                </Alert>
            )}
            <Alert severity="info" style={{ marginTop: 16 }}>
                <TextBlock textBlock={fft.signatureRequirements.info} />
            </Alert>

            {documentTransmission === 'a' && (
                <div style={{ marginTop: '16px' }}>
                    <TextBlock textBlock={fft.textA} />
                </div>
            )}
            {documentTransmission === 'b' && (
                <div style={{ marginTop: '16px' }}>
                    <TextBlock textBlock={fft.textB} />
                    {fileError && (
                        <Alert severity="error">
                            <TextBlock textBlock={fft.fileError} />
                        </Alert>
                    )}
                    <DropzoneContainer
                        prefetchFiles={fetchUploadedFilesFor(proposalId, [
                            'proposal',
                            'final',
                        ])}
                        keywords={['proposal', 'final', 'tmp']}
                        linkedResource={proposalId}
                        onFilesChanged={filesChanged}
                        maxFileSize={18}
                        acceptedFileTypes={['.pdf']}
                        maxFileCount={1}
                    />
                </div>
            )}

            <Alert severity="info" style={{ marginTop: 16 }}>
                <Typography>{fft.finalNotice}</Typography>
            </Alert>

            {!hasDownloaded && (
                <Typography
                    variant="h6"
                    style={{ textAlign: 'center', color: '#f44336' }}
                >
                    {fft.static.hasDownload}
                </Typography>
            )}
            {openConfirmDigitalSignatureDialog && (
                <DialogWithConfirmation
                    closeFunc={() => {
                        setSignatureValidityError(true);
                        setConfirmedDigitalSignature(false);
                        setOpenConfirmDigitalSignatureDialog(false);
                    }}
                    content={fft.signatureRequirements.dialog}
                    title={''}
                    open={openConfirmDigitalSignatureDialog}
                    confirmButtonText={'Bestätigen'}
                    confirmFunc={() => {
                        setSignatureValidityError(false);
                        setConfirmedDigitalSignature(true);
                        setOpenConfirmDigitalSignatureDialog(false);
                    }}
                />
            )}
        </form>
    );
};

FinalizeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
    defaultValues: PropTypes.object.isRequired,
};

export default FinalizeForm;
