import React from 'react';
import { CircularProgress } from '@material-ui/core';

const FullscreenCircularLoader = () => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
            }}
        >
            <CircularProgress size={40} />
        </div>
    );
};

export default FullscreenCircularLoader;
