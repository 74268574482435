import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';

const RenderEmptyRow = (props) => {
    const { colSpan } = props;

    return (
        <TableRow>
            <TableCell />
            <TableCell colSpan={colSpan}>Kein Eintrag vorhanden</TableCell>
        </TableRow>
    );
};

RenderEmptyRow.propTypes = {
    colSpan: PropTypes.number,
};

export default RenderEmptyRow;
