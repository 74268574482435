import React from 'react';
import PropTypes from 'prop-types';
import { texts as clft } from '../../../texts/Forms/CompanyLocationFormTexts';
import Typography from '@material-ui/core/Typography';
import RHFTextField from '../../Partials/Form/RHFTextField';
import RHFAutocomplete from '../../Partials/Form/RHFAutocomplete';
import { states } from '../../../util/Constants/States';
import RadioButtons from '../../Partials/Form/RadioButtons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { validatePlz } from '../../../util/Validation/ValidatePlz';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    marginBot: {
        marginBottom: theme.spacing(2),
    },
}));

const CompanyLocation = (props) => {
    const { control, disabled, errors, index, watch, register, defaultValues } =
        props;
    const classes = useStyles();
    const locationError = errors.addresses?.length || 0;

    let error = {};
    if (
        locationError > 0 &&
        locationError >= index &&
        errors.addresses[index]
    ) {
        error = errors.addresses[index];
    }

    return (
        <>
            <Typography variant="subtitle1" component="h3">
                {index === 0 ? clft.title4 : clft.title5 + ' #' + index}
            </Typography>

            {index === 1 && (
                <>
                    <Typography gutterBottom>
                        {clft.addresses.question}
                    </Typography>
                    <Alert severity="info">{clft.addresses.hint}</Alert>
                </>
            )}

            <RHFTextField
                label={clft.addresses.street.label}
                control={control}
                name={`addresses[${index}].street`}
                error={error.street}
                disabled={disabled}
                validation={{ required: clft.addresses.street.error }}
            />

            <RHFTextField
                label={clft.addresses.nr.label}
                control={control}
                name={`addresses[${index}].housenumber`}
                error={error.housenumber}
                disabled={disabled}
                validation={{ required: clft.addresses.nr.error }}
            />

            <RHFTextField
                label={clft.addresses.postalcode.label}
                control={control}
                name={`addresses[${index}].postcode`}
                error={error.postcode}
                disabled={disabled}
                validation={{
                    validate: (value) =>
                        validatePlz(value, clft.addresses.postalcode.error),
                }}
            />

            <RHFTextField
                label={clft.addresses.city.label}
                control={control}
                name={`addresses[${index}].city`}
                error={error.city}
                disabled={disabled}
                validation={{ required: clft.addresses.city.error }}
            />

            <RHFAutocomplete
                control={control}
                name={`addresses[${index}].state`}
                label={clft.addresses.state.label}
                defaultValue={defaultValues.state || null}
                staticData={states}
                error={error.state}
                validation={{ required: clft.addresses.state.error }}
            />

            {index === 0 && (
                <>
                    <input
                        type="hidden"
                        name={`addresses[${index}].isMain`}
                        value="true"
                        ref={register()}
                    />
                    {watch('identical') === 'true' ? (
                        <>
                            <RadioButtons
                                groupLabel={
                                    clft.addresses.headquarter.isWeak.label
                                }
                                name={`addresses[${index}].isWeak`}
                                control={control}
                                buttons={
                                    clft.addresses.headquarter.isWeak.buttons
                                }
                                errors={error.isWeak}
                                rules={{
                                    required:
                                        clft.addresses.headquarter.isWeak.error,
                                }}
                                className={classes.marginBot}
                                disabled={disabled}
                            />
                        </>
                    ) : (
                        <input
                            type="hidden"
                            name={`addresses[${index}].isWeak`}
                            value="false"
                            ref={register()}
                        />
                    )}
                </>
            )}

            {index > 0 && (
                <>
                    <input
                        type="hidden"
                        name={`addresses[${index}].isMain`}
                        value="false"
                        ref={register()}
                    />
                    {index === 1 && (
                        <RadioButtons
                            groupLabel={clft.addresses.isWeak.label}
                            name={`addresses[${index}].isWeak`}
                            control={control}
                            buttons={clft.addresses.isWeak.buttons}
                            errors={error.isWeak}
                            rules={{
                                required: clft.addresses.isWeak.error,
                            }}
                            className={classes.marginBot}
                            disabled={disabled}
                        />
                    )}
                </>
            )}
            {index > 1 && (
                <input
                    type="hidden"
                    name={`addresses[${index}].isWeak`}
                    value=""
                    ref={register()}
                />
            )}
        </>
    );
};

CompanyLocation.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    watch: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
};

CompanyLocation.defaultProps = {
    disabled: false,
    errors: {},
};

export default CompanyLocation;
