import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { texts as oft } from '../../../../../texts/Forms/OverviewFormTexts';

const Digi1Overview = (props) => {
    const { data } = props;

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {oft.digi1.static.text0}
            </Typography>
            <Typography component="ul">
                {data.sectors.map((sector) => (
                    <Typography component="li" key={sector.label}>
                        {sector.label}
                        {data.other &&
                            sector.label === 'Sonstiges' &&
                            `: ${data.other}`}
                    </Typography>
                ))}
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                {oft.digi1.static.text1}
            </Typography>
            <Typography component="ul">
                {data.questions.map((question, idx) => (
                    <Typography component="li" key={`${question}_${idx}`}>
                        <Typography>{question.question}</Typography>
                        <Typography gutterBottom>{question.answer}</Typography>
                    </Typography>
                ))}
            </Typography>
        </>
    );
};

Digi1Overview.propTypes = {
    data: PropTypes.object,
};

export default Digi1Overview;
