import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, TableCell, Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormMoney from '../../Components/FormMoney';
import InputAdornment from '@material-ui/core/InputAdornment';
import BackupIcon from '@material-ui/icons/Backup';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { MODAL_TYPE } from './FinancialPlanTableOld';
import { useWatch } from 'react-hook-form';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Dinero from 'dinero.js';
import Typography from '@material-ui/core/Typography';
import { step4Texts } from '../../../../texts/Verification/Step4Texts';
import { verifyRow } from '../../../../util/Verification/ValidateTableRow';
import { fetchUploadedFilesFor } from '../../../../lib/api/Files/FileActions';
import { getIdFromIri } from '../../../../util/Helper/GetIdFromIri';
import { useMutation } from 'react-query';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useVerificationProofsPut from '../../../../hooks/Verification/useVerificationProofsPut';
import { useSnackbar } from 'notistack';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const defaultColors = {
    error: red[500],
    finished: green[500],
    default: grey[500],
};

const FinancialTableRow = (props) => {
    const {
        rowData,
        rowIndex,
        isRowExtended,
        displayOnly,
        control,
        onActionClick,
        onRowExtendClick,
        error,
        getValues,
        setValue,
        watch,
        verificationId,
    } = props;
    const classes = useRowStyles();

    const handleRowExtendClick = React.useCallback(() => {
        onRowExtendClick(rowIndex);
    }, [rowIndex, onRowExtendClick]);

    const handleActionClick = React.useCallback(
        (index, type) => () => {
            onActionClick(index, type);
        },
        [onActionClick]
    );

    const isAccepted =
        useWatch({ control, name: `accepted[${rowIndex}]` }) === 'true';

    const [rowError, setRowError] = React.useState(undefined);

    const colorString = React.useMemo(() => {
        if (Boolean(error) || !!rowError) return 'error'; //@Todo: Error condition
        if (!isAccepted || displayOnly) return 'default';
        return 'finished';
    }, [isAccepted, error, rowError, displayOnly]);

    const rowMoney = watch(`money[${rowIndex}]`);

    const { mutateAsync, isLoading: isFetchingFileInfo } = useMutation(() => {
        return fetchUploadedFilesFor(verificationId, [
            'verification',
            'proofItem',
            getIdFromIri(rowData.proof),
        ])();
    });

    const { enqueueSnackbar } = useSnackbar();

    const onFinish = (data) => {
        enqueueSnackbar(data.message(), { variant: data.type() });
    };

    const { mutate, isSaving } = useVerificationProofsPut({
        onSuccess: onFinish,
        onError: onFinish,
    });

    const onRowCheck = () => {
        if (getValues(`accepted[${rowIndex}]`) === 'true') {
            setValue(`accepted[${rowIndex}]`, 'false');
            return;
        }
        const async = async () => {
            const invoiceTotal = getValues(`money[${rowIndex}]`);
            const payments = getValues(`paid[${rowIndex}]`);
            const result = verifyRow(
                invoiceTotal,
                payments,
                rowData.explanation
            );
            if (typeof result !== 'boolean') {
                setValue(`accepted[${rowIndex}]`, false);
                setRowError(result);
                return;
            }

            if (rowData.proof) {
                const putData = [
                    {
                        ...rowData,
                        proof:
                            rowData.proof.indexOf('/api') === 0
                                ? rowData.proof
                                : `/api/proofs/${rowData.proof}`,
                        verification: `/api/verifications/${verificationId}`,
                        invoiceTotal: invoiceTotal,
                        explanation: { ...rowData.explanation },
                        payments: [...rowData.payments],
                    },
                ];

                if (invoiceTotal > 0) {
                    try {
                        const result = await mutateAsync(undefined);
                        if (result.length <= 0) {
                            setValue(`accepted[${rowIndex}]`, 'false');
                            setRowError(
                                'Bitte laden Sie eine(n) Nachweis/Rechnung hoch (1)'
                            );
                            return;
                        }

                        mutate({
                            data: putData,
                            id: verificationId,
                            success:
                                'Verwendungsnachweis erfolgreich gespeichert.',
                        });
                    } catch (e) {
                        setValue(`accepted[${rowIndex}]`, 'false');
                        setRowError(
                            'Bitte prüfen Sie, ob eine Datei hochgeladen wurde (1)'
                        );
                        return;
                    }
                } else {
                    mutate({
                        data: putData,
                        id: verificationId,
                        success: 'Verwendungsnachweis erfolgreich gespeichert.',
                    });
                }
            }
            setValue(`accepted[${rowIndex}]`, 'true', { shouldValidate: true });
            setRowError(undefined);
        };
        async();
    };

    const totalSum = React.useMemo(() => {
        return new Dinero({ amount: parseInt(rowData.totalSum) }).toFormat();
    }, [rowData.totalSum]);

    const uploadButtonDisabled = React.useMemo(() => {
        const intMoney = parseInt(rowMoney);
        return (isAccepted && !displayOnly) || !intMoney || intMoney === 0;
    }, [isAccepted, displayOnly, rowMoney]);

    const billingButtonDisabled = React.useMemo(() => {
        const intMoney = parseInt(rowMoney);
        return (isAccepted && !displayOnly) || isNaN(intMoney);
    }, [isAccepted, displayOnly, rowMoney]);

    return (
        <>
            <TableRow className={classes.root} hover>
                <TableCell>
                    <IconButton onClick={handleRowExtendClick} size="small">
                        {isRowExtended ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>{rowIndex + 1}</TableCell>
                <TableCell style={{ wordBreak: 'break-word' }}>
                    {rowData.description}
                </TableCell>
                <TableCell>{totalSum}</TableCell>
                <TableCell>
                    <FormMoney
                        control={control}
                        name={`money[${rowIndex}]`}
                        notEditable={displayOnly || isAccepted}
                        placeholder={'0,00 €'}
                        adornment={
                            <InputAdornment position={'end'}>
                                <Tooltip
                                    title={
                                        <Typography>
                                            {step4Texts.tooltips.upload}
                                        </Typography>
                                    }
                                >
                                    <span>
                                        <IconButton
                                            size="small"
                                            onClick={handleActionClick(
                                                rowIndex,
                                                MODAL_TYPE.UPLOAD
                                            )}
                                            disabled={uploadButtonDisabled}
                                        >
                                            <BackupIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    <FormMoney
                        control={control}
                        name={`paid[${rowIndex}]`}
                        notEditable
                        adornment={
                            <InputAdornment position={'end'}>
                                <Tooltip
                                    title={
                                        <Typography>
                                            {step4Texts.tooltips.payments}
                                        </Typography>
                                    }
                                >
                                    <span>
                                        <IconButton
                                            size="small"
                                            onClick={handleActionClick(
                                                rowIndex,
                                                MODAL_TYPE.BILLING
                                            )}
                                            disabled={billingButtonDisabled}
                                        >
                                            <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                    <Tooltip
                        title={
                            <Typography>{step4Texts.tooltips.desc}</Typography>
                        }
                    >
                        <span>
                            <IconButton
                                size="small"
                                onClick={handleActionClick(
                                    rowIndex,
                                    MODAL_TYPE.DESCRIPTION
                                )}
                                disabled={
                                    (isAccepted && !displayOnly) || !rowMoney
                                }
                            >
                                <DescriptionOutlinedIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <input
                        disabled={displayOnly}
                        type="hidden"
                        ref={control.register({
                            validate: (value) => value === 'true' || 'Error',
                        })}
                        name={`accepted[${rowIndex}]`}
                    />
                    <IconButton
                        style={{ color: defaultColors[colorString] }}
                        onClick={onRowCheck}
                        disabled={isFetchingFileInfo || displayOnly}
                    >
                        {isFetchingFileInfo || isSaving ? (
                            <CircularProgress size={24} />
                        ) : isAccepted ? (
                            <CheckCircleIcon />
                        ) : (
                            <RadioButtonUncheckedIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            {rowError && (
                <TableRow className={classes.root}>
                    <TableCell colSpan={1000}>
                        <Typography style={{ color: defaultColors.error }}>
                            {rowError}
                        </Typography>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

FinancialTableRow.propTypes = {
    rowData: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    displayOnly: PropTypes.bool,
    onActionClick: PropTypes.func,
    isRowExtended: PropTypes.bool,
    onRowExtendClick: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
    verificationId: PropTypes.string,
};

export default FinancialTableRow;
