import React from 'react';
import ProposalOverviewForm from '../../components/Forms/ProposalOverview/ProposalOverviewForm';
import TextBlock from '../../components/TextBlock/TextBlock';
import { texts as pot } from '../../texts/Components/ProofOverviewTexts';
import { useParams } from 'react-router-dom';
import { getProposalInfo } from '../../lib/api/Proposal/ProposalActions';
import FormSkelleton from '../../components/Forms/FormSkelleton';
import { Button } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import moment from 'moment';
import FinalizeProposalPdf from '../../components/Forms/Finalize/FinalizeProposalPdf';
import { downloadPdf } from '../../lib/api/Finalize/FinalizeActions';

const ProposalOverviewContainer = () => {
    const { proposalId } = useParams();
    const [proposal, setProposal] = React.useState(null);
    const [isLoading, setIsloading] = React.useState(true);

    const load = React.useCallback(() => {
        if (!isLoading) setIsloading(true);
        getProposalInfo(proposalId)()
            .then((result) => {
                setProposal(result);
            })
            .catch((e) => {})
            .finally(() => {
                setIsloading(false);
            });
    }, [isLoading, proposalId, setProposal, setIsloading]);

    React.useEffect(() => {
        load();
    }, []); //eslint-disable-line

    if (isLoading) return <FormSkelleton />;

    if (!proposal)
        return (
            <>
                <TextBlock textBlock={pot.parser.overviewError} />
                <Button variant="outlined" color="primary" onClick={load}>
                    {pot.static.overviewErrorButton}
                </Button>
            </>
        );

    const createTimelineItem = (time, label, format, stroke = true) => {
        const showTime = false;
        return (
            <TimelineItem>
                <TimelineOppositeContent>
                    {showTime && (
                        <Typography color="textSecondary">
                            {time ? moment(time).format(format) : ''}
                        </Typography>
                    )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color={time ? 'primary' : undefined} />
                    {stroke && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                    <Typography>{label}</Typography>
                </TimelineContent>
            </TimelineItem>
        );
    };

    return (
        <>
            <TextBlock textBlock={pot.parser.overview} headlineVariant="h4" />

            <Timeline style={{ marginBottom: 0, paddingBottom: 0 }}>
                {createTimelineItem(
                    proposal.confirmedAt,
                    'Antrag eingereicht',
                    'LLL'
                )}
                {createTimelineItem(proposal.receivedAt, 'In Bearbeitung', 'L')}

                {createTimelineItem(
                    proposal.revisionStatus?.['final_approved'],
                    'Antrag bewilligt',
                    'LLL',
                    false
                )}
            </Timeline>

            <div style={{ marginTop: '32px' }}>
                <ProposalOverviewForm noForm />
            </div>

            <FinalizeProposalPdf
                onComplete={() => {}}
                redownload={true}
                downloadFunc={downloadPdf}
                id={proposalId}
            />
        </>
    );
};

export default ProposalOverviewContainer;
