import React from 'react';
import PropTypes from 'prop-types';
import { getLastRaffle, RAFFLE_STATE } from '../../util/Raffle/RaffleFunctions';
import RaffleDisplay from './RaffleDisplay';
import RaffleParticipating from './RaffleParticipating';
import CanParticipate from './States/CanParticipate';
import CannotParticipate from './States/CannotParticipate';
import InObjection from './States/InObjection';
import WasDrawn from './States/WasDrawn';
import NotDrawn from './States/NotDrawn';
import IsClosed from './States/IsClosed';
import InvalidTime from './States/InvalidTime';
import { hasSubmittedProposal } from '../../util/Helper/UserHasProposal';

const RaffleState = (props) => {
    const { raffles, state, pots } = props;

    const lastRaffleInObjection = getLastRaffle(raffles)?.proposal?.inObjection;

    if (lastRaffleInObjection) {
        return <RaffleDisplay label={<InObjection />} />;
    }

    if (
        state === RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE ||
        state === RAFFLE_STATE.RAFFLE_CAN_PARTICIPATE_AGAIN
    )
        return (
            <RaffleDisplay
                label={<CanParticipate isProposalPage={false} />}
            ></RaffleDisplay>
        );

    if (state === RAFFLE_STATE.RAFFLE_TIME_INVALID)
        return (
            <RaffleDisplay
                label={<InvalidTime rafflePot={pots[pots.length - 1]} />}
            ></RaffleDisplay>
        );

    if (state === RAFFLE_STATE.RAFFLE_CANNOT_PARTICIPATE)
        return <RaffleDisplay label={<CannotParticipate />} />;

    if (state === RAFFLE_STATE.RAFFLE_WAS_DRAWN)
        return <RaffleDisplay label={<WasDrawn raffle={raffles[0]} />} />;

    if (state === RAFFLE_STATE.RAFFLE_NOT_DRAWN)
        return (
            <RaffleDisplay
                label={<NotDrawn rafflePot={pots[pots.length - 1]} />}
            ></RaffleDisplay>
        );

    if (state === RAFFLE_STATE.RAFFLE_IS_CLOSED) {
        if (hasSubmittedProposal(raffles))
            return <RaffleDisplay label={<CannotParticipate />} />;
        return (
            <RaffleDisplay
                label={<IsClosed rafflePots={pots} raffles={raffles} />}
            />
        );
    }

    if (
        state === RAFFLE_STATE.RAFFLE_IS_PARTICIPATING ||
        state === RAFFLE_STATE.RAFFLE_IS_PARTICIPATING_AGAIN
    )
        return (
            <RaffleDisplay>
                <RaffleParticipating raffle={getLastRaffle(raffles)} />
            </RaffleDisplay>
        );

    return null;
};

RaffleState.propTypes = {
    state: PropTypes.number.isRequired,
    raffles: PropTypes.array,
    pots: PropTypes.array,
};

export default RaffleState;
