import { useQuery } from 'react-query';
import { getFinalFinancialPlanData } from '../../lib/api/Verification/VerificationActions';

export default function useFinalVerificationData(verificationId) {
    return useQuery(
        ['verification', 'final', verificationId],
        () => getFinalFinancialPlanData(verificationId),
        { refetchOnWindowFocus: false, refetchOnMount: true }
    );
}
