import create from 'zustand';

const initial = {
    status: {
        register: {
            messages: [
                {
                    message: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Sehr geehrte Antragstellerinnen und Antragsteller,',
                            },
                            {
                                type: 'p',
                                text: 'aufgrund der Vielzahl der Förderanträge für Digital Jetzt ist es für kurze Zeit nicht möglich sich im Digital Jetzt Förderportal zu registrieren und Anträge einzureichen.',
                            },
                            {
                                type: 'p',
                                text: 'Am [u]15. Oktober um 9:00 Uhr[u] wird ein Kontingent an neuen Registrierungen für das Antragstool freigeschaltet.',
                            },
                            {
                                type: 'p',
                                text: 'Das Programm Digital Jetzt ist stark nachgefragt und es gibt eine monatliche Kontingentierung für die Registrierung und Antragseinreichungen im Antragstool.',
                            },
                        ],
                    },
                    to: '2020-10-15T09:00',
                },
                {
                    message: {
                        paragraphs: [
                            {
                                type: 'p',
                                text: 'Sehr geehrte Antragstellerinnen und Antragsteller,',
                            },
                            {
                                type: 'p',
                                text: 'aufgrund der Vielzahl der Förderanträge für Digital Jetzt ist das Kontingent für die Registrierung erschöpft und es ist zurzeit nicht möglich sich im Digital Jetzt Förderportal zu registrieren.',
                            },
                            {
                                type: 'p',
                                text: 'Wir arbeiten an einem angepassten Verfahren für die Registrierung und Antragstellung. Dieses Verfahren wird in Kürze auf der Webseite des Bundesministeriums für Wirtschaft und Klimaschutz: [a|https://www.bmwk.de/Redaktion/DE/Dossier/digital-jetzt.html]https://www.bmwk.de/Redaktion/DE/Dossier/digital-jetzt.html[a] veröffentlicht.',
                            },
                        ],
                    },
                    to: '2021-10-15T00:00',
                },
            ],
        },
    },
};

const withFunctions = (set) => ({
    ...initial,
    setMessages: (messages) => {
        set({ ...messages });
    },
});

export const useMessages = create(withFunctions);
