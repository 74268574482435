import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from '../../Stores/AppState.store';
import RaffleOverview from '../../components/Raffle/RaffleOverview';
import moment from 'moment';
import { useHistory } from 'react-router';

const RaffleContainer = (props) => {
    const { render } = props;

    const { raffles, rafflePots, raffleStatus } = useAppState((state) => ({
        raffles: state.raffles,
        rafflePots: state.rafflePots,
        raffleStatus: state.raffleStatus,
    }));

    const history = useHistory();
    const redirect = parseInt(moment().format('YYYY')) > 2023;

    useEffect(() => {
        if (redirect) {
            history.push('/');
        }
    }, [redirect, history]);

    return (
        <div>
            {!redirect &&
                render({
                    rafflePots: rafflePots,
                    raffles: raffles,
                    raffleStatus: raffleStatus,
                })}
        </div>
    );
};

RaffleContainer.propTypes = {
    render: PropTypes.func.isRequired,
};

RaffleContainer.defaultProps = {
    render: RaffleOverview,
};

export default RaffleContainer;
