import { authenticateClient } from '../Client/ClientAuth';
import Cookies from 'js-cookie';

export default (axios) =>
    axios.interceptors.request.use(
        async function (config) {
            if (!Cookies.get('login_token')) {
                await authenticateClient();
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
