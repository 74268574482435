import moment from 'moment';
import React, { useEffect, useState } from 'react';

const getDiff = (start, end) => {
    return moment.duration(end.diff(start));
};

const durationAsString = (start, end) => {
    const duration = getDiff(start, end);

    //Get Days
    const days = Math.floor(duration.asDays());
    const daysFormatted = days ? `${days} ${days > 1 ? 'Tage' : 'Tag'} ` : '';

    //Get Hours
    const hours = duration.hours();
    const hoursFormatted = `${hours > 9 ? hours : '0' + hours}:`;

    //Get Minutes
    const minutes = duration.minutes();
    const minutesFormatted = `${minutes > 9 ? minutes : '0' + minutes}:`;

    //Get Seconds
    const seconds = duration.seconds();
    const secondsFormatted = `${seconds > 9 ? seconds : '0' + seconds}`;

    return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
    ].join('');
};

const Countdown = (props) => {
    const { end } = props;

    const timer = React.useRef(null);

    const setCountdown = React.useCallback(() => {
        let countdown = durationAsString(moment(), moment(end));
        if (getDiff(moment(), moment(end)).seconds() < 0) {
            countdown = '00:00:00';
            clearInterval(timer.current);
        }

        return countdown;
    }, [end]);

    const [outputCounter, setOutputCounter] = useState(setCountdown());

    useEffect(() => {
        timer.current = setInterval(() => {
            setOutputCounter(setCountdown());
        }, 1000);

        return () => {
            clearInterval(timer.current);
        };
    }, [end, setCountdown]);

    return <>{outputCounter}</>;
};

export default Countdown;
