import { validateIban } from '../../util/Validation/ValidateIban';
import isValidBic from '../../util/Validation/ValidateBic';

export const step5Texts = {
    title: 'Finanzplanübersicht',
    errorText:
        'Das Formular enthält Fehler, bitte überprüfen Sie Ihre Eingaben.',
    finalPlan: {
        title: 'Übersicht Finanzierungsplan',
    },
    topHintTeaser: {
        paragraphs: [
            {
                type: 'p',
                text: 'In der folgenden Übersicht sind die von Ihnen im Verwendungsnachweis angegebenen Investitionen zusammengefasst dargestellt. Außerdem sind hier noch einmal die laut Zuwendungsbescheid bewilligte Gesamtinvestitionssumme, Fördersumme und Förderquote aufgeführt.',
            },
        ],
    },
    topHint: {
        paragraphs: [
            {
                type: 'p',
                text: 'Diese Übersicht zeigt Ihnen abschließend die voraussichtliche Fördersumme, unter Berücksichtigung der von Ihnen im Verwendungsnachweis gemachten Angaben und der laut Zuwendungsbescheid maximal bewilligten Fördersumme und Förderquote. Die angezeigte voraussichtliche Fördersumme gilt unter Vorbehalt der noch durchzuführenden Verwendungsnachweisprüfung.',
            },
            {
                type: 'p',
                text: 'Die von Ihnen im Verwendungsnachweis gemachten Angaben werden nach Einreichung des Verwendungsnachweises (im Förderportal und mit rechtsverbindlicher Unterschrift) vom Projektträger geprüft. Die tatsächliche Fördersumme wird Ihnen mit dem Schlussbescheid übermittelt.',
            },
            {
                type: 'p',
                text: 'Der Zuwendungsempfänger ist verpflichtet, nach Ende des Bewilligungszeitraumes einen Verwendungsnachweis einzureichen. Dies gilt auch bei einer voraussichtlichen Fördersumme (unter Vorbehalt der Prüfung durch den Projektträger) in Höhe von 0 Euro.',
            },
        ],
    },

    outlineVN9: {
        title: 'Ich bestätige, dass...',
        inGermany: {
            label: 'es sich um ein Unternehmen der gewerblichen Wirtschaft mit Betriebsstätte und/oder Niederlassung in Deutschland handelt.',
            name: 'verificationAffirm.inGermany',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass es sich um ein Unternehmen der gewerblichen Wirtschaft mit Betriebsstätte und/oder Niederlassung in Deutschland handelt',
            },
        },
        deviceListComplete: {
            label: 'die beschafften Gegenstände der Liste der Gegenstände laut Zuwendungsbescheid entsprechen, bzw. sofern Abweichungen vorliegen, wurden diese im Verwendungsnachweis vollumfänglich aufgeführt. Die beschafften Gegenstände sind nicht der Grundausstattung zuzuordnen.',
            name: 'verificationAffirm.deviceListComplete',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass die beschafften Gegenstände vollständig in der Liste der Gegenstände enthalten und nicht der Grundausstattung zuzuordnen sind.',
            },
        },
        devicesAreRelevant: {
            label: 'die Gegenstände auch zukünftig im Sinne des geförderten Vorhabens im Rahmen unseres individuellen Digitalisierungsplans in unserem Unternehmen eingesetzt werden.',
            name: 'verificationAffirm.devicesAreRelevant',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass die Gegenstände auch zukünftig im Sinne des geförderten Vorhabens im Rahmen unseres individuellen Digitalisierungsplans in unserem Unternehmen eingesetzt werden.',
            },
        },
        block2: 'Darüber hinaus bestätige ich, dass...',
        invoicesSettled: {
            label: 'sämtliche angegebene Zahlungen vollständig getätigt wurden.',
            name: 'verificationAffirm.invoicesSettled',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass sämtliche Zahlungen vollständig getätigt wurden',
            },
        },
        paymentInTime: {
            label: 'das Datum der Schlusszahlung(en) VOR dem Absenden des Verwendungsnachweises liegt,',
            name: 'verificationAffirm.paymentInTime',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass das Datum der Schlusszahlung(en) VOR dem Absenden des Verwendungsnachweises liegt',
            },
        },
        invoicesExist: {
            label: 'Original-Belege (Rechnungen und Zahlungsbeweise) beim Zuwendungsempfänger vorliegen und bei Bedarf vorgelegt werden können, ',
            name: 'verificationAffirm.invoicesExist',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass Original-Belege (Rechnungen und Zahlungsbeweise) beim Zuwendungsempfänger vorliegen und bei Bedarf vorgelegt werden können',
            },
        },
        compliance: {
            label: 'die Bestimmungen des Zuwendungsbescheides beachtet worden sind. Die Ausgaben waren notwendig. Es ist wirtschaftlich und sparsam verfahren worden. Die vorstehenden Angaben sind richtig und vollständig und stimmen mit den Büchern und Belegen überein.',
            name: 'verificationAffirm.compliance',
            rules: {
                required:
                    'Bitte bestätigen Sie, dass die Bestimmungen des Zuwendungsbescheides beachtet worden sind.',
            },
        },
    },
    outlineVN11: {
        title: 'Daten Bankverbindung',
        name: {
            label: 'Kontoinhaber',
            name: 'verificationBank.name',
            rules: {
                required: 'Bitte geben Sie den Kontoinhaber an',
            },
        },
        institution: {
            label: 'Geldinstitut',
            name: 'verificationBank.institution',
            rules: {
                required: 'Bitte geben Sie das Geldinsitut an',
            },
        },
        iban: {
            label: 'IBAN',
            name: 'verificationBank.iban',
            rules: {
                required: 'Bitte eine gültige IBAN angeben',
                validate: (value) => validateIban(value, 'IBAN ist ungültig'),
            },
        },
        bic: {
            label: 'BIC',
            name: 'verificationBank.bic',
            rules: {
                validate: (value) =>
                    isValidBic(value, 'Bitte eine gültige BIC angeben'),
            },
        },
    },
    finalCheck: {
        title: 'Bitte bestätigen Sie, dass Ihre Angaben korrekt sind',
        correct: {
            label: 'Hiermit wird bestätigt, dass alle gemachten Angaben korrekt sind.',
            name: 'verificationAffirm.correct',
            rules: {
                required:
                    'Sie können den Verwendungsnachweis nicht einreichen, wenn Sie nicht bestätigen, dass die Angaben korrekt sind.',
            },
        },
    },
    deepExamination: {
        name: 'deepExamination',
        label: 'Ihr Vorhaben wurde zur vertieften Prüfung ausgewählt. Für die vertiefte Prüfung werden (über die Rechnungen hinaus) Belege benötigt, die nachweisen, dass die Bezahlung der durch die Investitionsmaßnahmen entstandenen Kosten erfolgt ist. Bitte laden Sie für die vertiefte Prüfung Belege über entsprechende Zahlungsströme (Kontonachweise etc.) hoch.',
    },
};
