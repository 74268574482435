export const finPlanTexts = {
    sumInVerification: 'Gesamtinvestition gemäß Verwendungsnachweis',
    invoice: 'Dargestellte Rechnungsbeträge in Summe',
    paidSum: 'Geleistete Zahlungen im Bewilligungszeitraum',
    additionalMoney:
        'Zusätzliche Deckungsmittel zu Finanzierung der Investitionsmaßnahme',
    realizedInvestSum: 'Realisierte Investitionssumme',
    sumApproved: 'Bewilligter Finanzierungsplan',
    approvedInvest: 'Bewilligte Gesamtinvestition',
    approvedSum: 'Bewilligte maximale Fördersumme',
    fundingRatio: 'Förderquote',
    calculatedFunding:
        'Berechnete Fördersumme auf Basis der realisierten Investitionssumme',
    sumFunding:
        'Voraussichtliche Fördersumme gemäß Verwendungsnachweis (unter Berücksichtigung bei maximal bewilligten Fördersumme laut Zuwendungsbescheid).',
    hint: 'Hinweis: Die Fördersumme ist vorbehaltlich der noch ausstehenden Prüfung und wird um die zusätzlichen Deckungsmittel gekürzt.',
};
