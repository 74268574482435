import React from 'react';
import PropTypes from 'prop-types';
import { texts as dft } from '../../../texts/Forms/Digi1FormTexts';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from './CustomTooltip';

const SectorPicker = (props) => {
    const { onChange, register, defaultChecked } = props;
    const genName = (idx) => `initialBusinessUnits[${idx}]`;

    const handleChange = (id) => (e) => {
        onChange(
            id,
            e.target.checked,
            e.target.name === genName(dft.initialSituation.otherId)
        );
    };

    return (
        <FormControl component="fieldset" style={{ display: 'block' }}>
            <FormGroup>
                {dft.initialSituation.sectors.map((sector, idx) => (
                    <FormControlLabel
                        key={`sector_${sector.label}`}
                        control={
                            <Checkbox
                                color="primary"
                                inputRef={register()}
                                onChange={handleChange(idx)}
                                defaultChecked={defaultChecked[idx]}
                            />
                        }
                        label={
                            <Typography>
                                {sector.label}
                                {sector.tooltip && ' '}
                                {sector.tooltip && (
                                    <CustomTooltip>
                                        {sector.tooltip}
                                    </CustomTooltip>
                                )}
                            </Typography>
                        }
                        name={genName(idx)}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

SectorPicker.propTypes = {
    register: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.array,
};

SectorPicker.defaultProps = {
    defaultChecked: [],
};

export default SectorPicker;
