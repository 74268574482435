import React from 'react';
import PropTypes from 'prop-types';
import RegisterForm from '../../components/Register/RegisterForm';
import RegisterDone from '../../components/Register/RegisterDone';
import { register } from '../../lib/api/Register/RegisterActions';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import RegisterStopHint from '../../components/Partials/Info/RegisterStopHint';

const useStyles = makeStyles({
    root: {
        color: '#fff',
        zIndex: 999,
    },
});

const RegisterFormContainer = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(undefined);
    const [created, setCreated] = React.useState(false);

    const classes = useStyles();
    const { open } = props;
    const handleSubmit = (values) => {
        setLoading(true);
        setErrors(undefined);

        register(values)
            .then(() => {
                setCreated(true);
            })
            .catch((error) => {
                setErrors(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <React.Fragment>
            {open ? (
                <>
                    <Backdrop open={loading} className={classes.root}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {created ? (
                        <RegisterDone />
                    ) : (
                        <RegisterForm
                            submit={handleSubmit}
                            responseErrors={errors}
                        />
                    )}
                </>
            ) : (
                <RegisterStopHint open={false} />
            )}
        </React.Fragment>
    );
};

RegisterFormContainer.propTypes = {
    open: PropTypes.bool,
};

RegisterFormContainer.defaultProps = {
    open: true,
};

export default RegisterFormContainer;
