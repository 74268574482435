import React from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        color: 'white',
        padding: '0.5rem 1rem',
        marginRight: '1rem',
        '&:last-child': {
            marginRight: '0',
        },
        '&:hover': {
            backgroundColor: '#C1DCEF',
            color: '#014F80',
        },
        '&:active': {
            backgroundColor: '#60810E',
            color: 'white',
        },
    },
    active: {
        backgroundColor: '#60810E',
    },

    pushLeft: {
        marginRight: 'auto !important',
    },
});

const NavbarButton = ({ href, title, enabled, pl, match }) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const onClickHandler = () => {
        history.push(href);
    };

    const isActive = () => {
        if (!location.pathname) return false;
        if (href === '/') {
            return location.pathname === '/';
        }

        if (match)
            return match.reduce(
                (acc, cur) => acc || location.pathname.indexOf(cur) === 0,
                false
            );

        return location.pathname.indexOf(href) === 0;
    };

    return (
        <Button
            onClick={onClickHandler}
            disabled={!enabled}
            className={classNames(classes.root, {
                [classes.active]: isActive(),
                [classes.pushLeft]: pl,
            })}
        >
            {title}
        </Button>
    );
};

NavbarButton.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    pl: PropTypes.bool,
    match: PropTypes.array,
};

NavbarButton.defaultProps = {
    pl: false,
};

export default NavbarButton;
