import React from 'react';
import PropTypes from 'prop-types';
import VerificationControls from './VerificationControls';
import { stepStrings, verificationConfig } from './VerificationConfig';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import { getCurrentStepNumber } from '../../../util/Verification/GetStepNumber';
import Typography from '@material-ui/core/Typography';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import { useSnackbar } from 'notistack';

const VerificationEditWrapper = (props) => {
    const { data, isLoading, isFetching, error, refetch, displayOnly, id } =
        props;

    const step = data?.formData.currentStep;

    const [currentStep, setCurrentStep] = React.useState({
        step: 0,
        transition: 'editable',
    });

    React.useEffect(() => {
        const stepNumber =
            getCurrentStepNumber(step) % verificationConfig.length;
        setCurrentStep({
            step: stepNumber,
            transition: stepStrings[stepNumber],
        });
    }, [step]);

    const dirtyRef = React.useRef(false);

    const handleDirty = React.useCallback(
        (isDirty) => {
            dirtyRef.current = isDirty;
        },
        [dirtyRef]
    );

    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync, isLoading: isTransitioning } = useVerificationPatch();

    const [dirtyTransition, setDirtyTransition] = React.useState(undefined);

    const handleStepChange = (toStep, transitionTo) => {
        if (dirtyRef.current) {
            setDirtyTransition({ toStep, transitionTo });
            return;
        }

        if (!transitionTo) {
            setCurrentStep({
                step: toStep,
                transition: stepStrings[toStep],
            });
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 2);
            handleDirty(false);
            return;
        }

        mutateAsync({ data, id, transition: transitionTo })
            .then(() => {
                setCurrentStep({
                    step: toStep,
                    transition: stepStrings[toStep],
                });
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }, 2);
                handleDirty(false);
            })
            .catch((error) => {
                enqueueSnackbar(error.message(), { variant: 'error' });
            });
    };

    const handleContinue = () => {
        handleDirty(false);
        handleStepChange(dirtyTransition.toStep, dirtyTransition.transitionTo);
        setDirtyTransition(undefined);
    };

    const handleClose = () => {
        setDirtyTransition(undefined);
    };

    if (isLoading) return <FullscreenCircularLoader />;

    if (error)
        return (
            <div>
                <Typography>{error.message()}</Typography>
                <Button
                    variant="outlined"
                    onClick={refetch}
                    color="primary"
                    style={{ marginTop: 8 }}
                >
                    Erneut versuchen
                </Button>
            </div>
        );

    return (
        <VerificationControls
            data={data.formData}
            currentStep={currentStep.step}
            transition={currentStep.transition}
            onCurrentStepChange={handleStepChange}
            isTransitioning={isTransitioning}
            displayMode={displayOnly}
        >
            {verificationConfig[currentStep.step]?.component({
                data: data.staticData,
                formData: data.formData,
                isLoading,
                isFetching,
                error,
                refetch,
                displayOnly,
                id,
                handleStepChange,
                onDirtyChange: handleDirty,
            })}
            <Dialog open={Boolean(dirtyTransition)} onClose={handleClose}>
                <DialogTitle>Änderungen</DialogTitle>
                <DialogContent>
                    Es gibt eine Änderung im Formular, möchten Sie ohne zu
                    speichern fortfahren?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleContinue}>
                        Weiter ohne speichern
                    </Button>
                    <Button onClick={handleClose}>Abbrechen</Button>
                </DialogActions>
            </Dialog>
        </VerificationControls>
    );
};

VerificationEditWrapper.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
};

export default VerificationEditWrapper;
