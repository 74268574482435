import React from 'react';
import PropTypes from 'prop-types';
import ButtonWithLoader from '../Partials/Button/ButtonWithLoader';
import { Create } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import Alert from '@material-ui/lab/Alert';

const VerificationTableFuntions = (props) => {
    const {
        mutate,
        isLoading,
        verification,
        inAmendment,
        inObjection,
        revisionStatus,
    } = props;

    if (inObjection) {
        return (
            <Tooltip
                title={
                    <Typography>
                        Die Bearbeitung des Verwendungsnachweises ist zur Zeit
                        nicht möglich, da zum zugehörigen Antrag ein Widerspruch
                        eingereicht wurde.
                    </Typography>
                }
            >
                <span>
                    <IconButton disabled>
                        <LockIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    if (inAmendment) {
        return (
            <Tooltip
                title={
                    <Typography>
                        Die Bearbeitung des Verwendungsnachweises ist zur Zeit
                        nicht möglich, da ein Stammdaten- oder
                        Laufzeitänderungsantrag in Bearbeitung ist
                    </Typography>
                }
            >
                <span>
                    <IconButton disabled>
                        <LockIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    if (!!revisionStatus?.final_revoked) {
        return (
            <div style={{ display: 'flex' }}>
                <Tooltip title={<Typography>Ansehen</Typography>}>
                    <span>
                        <IconButton
                            component={Link}
                            to={`/vn/${verification.id}/anzeigen`}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Alert severity="warning" style={{ marginLeft: 8 }}>
                    Der Antrag wurde widerrufen, die Einreichung eines
                    Verwendungsnachweises ist nicht möglich.
                </Alert>
            </div>
        );
    }

    if (!verification)
        return (
            <Tooltip title={<Typography>Erstellen</Typography>}>
                <span>
                    <ButtonWithLoader
                        func={mutate}
                        isLoading={isLoading}
                        icon={<AddIcon />}
                    />
                </span>
            </Tooltip>
        );

    if (
        [
            'editable',
            'affirmable',
            'in_review',
            'submittable',
            null,
            undefined,
        ].findIndex((el) => el === verification.currentStep) >= 0
    )
        return (
            <Tooltip title={<Typography>Bearbeiten</Typography>}>
                <span>
                    <IconButton
                        component={Link}
                        to={`/vn/${verification.id}/bearbeiten`}
                    >
                        <Create />
                    </IconButton>
                </span>
            </Tooltip>
        );

    return (
        <Tooltip title={<Typography>Ansehen</Typography>}>
            <span>
                <IconButton
                    component={Link}
                    to={`/vn/${verification.id}/anzeigen`}
                >
                    <VisibilityIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};

VerificationTableFuntions.propTypes = {
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
    verification: PropTypes.object,
    revisionStatus: PropTypes.object,
    inAmendment: PropTypes.bool,
    inObjection: PropTypes.bool,
};

export default VerificationTableFuntions;
