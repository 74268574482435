import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { theme } from '../Theme/Styles';
import NavbarButton from './NavbarButton';
import LoginContainer from '../../../container/Auth/LoginContainer';
import LoginButton from './LoginButton/LoginButton';
import LoginButtonModal from './LoginButton/LoginButtonModal';
import MobileNavigation from './MobileNavigation';
import { useMobileLayout } from '../../../hooks/useMobileLayout';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

const AnonNavlist = () => {
    const classes = useStyles();

    const show = useMobileLayout();

    return (
        <Container className={classes.root}>
            {!show && (
                <>
                    <NavbarButton
                        href={'/'}
                        enabled={true}
                        title="Startseite"
                        pl
                    />
                    <LoginContainer
                        render={LoginButton}
                        renderModal={LoginButtonModal}
                    />
                </>
            )}

            <MobileNavigation />
        </Container>
    );
};

AnonNavlist.propTypes = {
    canRegister: PropTypes.bool,
};

AnonNavlist.defaultProps = {
    canRegister: false,
};

export default AnonNavlist;
