//eslint-disable-next-line
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchEligibleProposals } from '../../lib/api/Verification/VerificationActions';
import VerificationTable from '../../components/Verification/VerificationTable';

const VerificationContainer = (props) => {
    const { render } = props;

    const { data, isLoading, isFetching, refetch, error } = useQuery(
        ['verification'],
        () => fetchEligibleProposals(),
        {}
    );

    return render({ data, isLoading, isFetching, refetch, error });
};

VerificationContainer.propTypes = {
    render: PropTypes.func.isRequired,
};

VerificationContainer.defaultProps = {
    render: VerificationTable,
};

export default VerificationContainer;
