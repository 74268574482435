import React from 'react';

import TextBlock from '../../TextBlock/TextBlock';
import { texts } from '../../../texts/Forms/FinalizeFormTexts';

const FinalizeInfo = (props) => {
    const button = document.getElementById('finalWizardButton');
    if (button && button.style) {
        button.style.display = 'none';
    }
    return <TextBlock headlineVariant="h4" textBlock={texts.info} />;
};

export default FinalizeInfo;
