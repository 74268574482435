import React from 'react';
import PropTypes from 'prop-types';
import RHFSelect from '../../Partials/Form/RHFSelect';
import { texts as mgmt } from '../../../texts/Forms/ManagementFormTexts';
import { validateEmail } from '../../../util/Validation/EmailValidation';
import Typography from '@material-ui/core/Typography';
import RHFTextField from '../../Partials/Form/RHFTextField';
import _ from 'lodash';
import ControlledSelect from '../../Partials/Form/ControlledSelect';
import { validatePhone } from '../../../util/Validation/ValidatePhone';

const ManagementStaff = (props) => {
    const {
        className,
        control,
        defaultValues,
        error,
        disabled,
        funcDisabled,
        name,
        title,
    } = props;

    return (
        <>
            <Typography variant="h5" component="h2">
                {title}
            </Typography>
            <RHFSelect
                className={className}
                label="Anrede"
                control={control}
                name={`${name}.salutation`}
                items={[
                    { value: 'f', label: 'Frau' },
                    { value: 'm', label: 'Herr' },
                    { value: 'd', label: 'divers' },
                ]}
                defaultValue={defaultValues.salutation}
                disabled={disabled}
                error={error?.salutation}
                rules={{ required: mgmt.staff.salutationError }}
            />
            <ControlledSelect
                label="Titel / akademischer Grad"
                control={control}
                displayEmpty={false}
                rules={{}}
                name={`${name}.title`}
                items={[
                    { value: '', title: mgmt.staff.title.empty },
                    { value: 'Dr.', title: mgmt.staff.title.dr },
                    { value: 'PhD', title: mgmt.staff.title.phd },
                    { value: 'Prof.', title: mgmt.staff.title.prof },
                ]}
                error={error?.title}
                className={className}
                defaultValue={defaultValues.title}
                disabled={disabled}
            />
            <RHFTextField
                label="Vorname"
                control={control}
                name={`${name}.name`}
                disabled={disabled}
                error={error?.name}
                validation={{ required: mgmt.staff.nameError }}
                defaultValue={defaultValues.name}
            />
            <RHFTextField
                label="Nachname"
                control={control}
                name={`${name}.lastname`}
                disabled={disabled}
                error={error?.lastname}
                validation={{ required: mgmt.staff.lastNameError }}
                defaultValue={defaultValues.lastname}
            />
            <ControlledSelect
                label="Funktion"
                control={control}
                displayEmpty={false}
                rules={{}}
                name={`${name}.func`}
                items={_.map(mgmt.staff.functions, (el, id) => ({
                    title: el,
                    value: id,
                }))}
                error={error?.func}
                className={className}
                defaultValue={defaultValues.func}
                disabled={disabled || funcDisabled}
            />

            <RHFTextField
                label="Telefonnummer"
                control={control}
                name={`${name}.phone`}
                disabled={disabled}
                error={error?.phone}
                validation={{
                    validate: (value) =>
                        validatePhone(value, mgmt.staff.phoneError),
                }}
                defaultValue={defaultValues.phone}
            />

            <RHFTextField
                label="E-Mail-Adresse"
                control={control}
                name={`${name}.email`}
                disabled={disabled}
                error={error?.email}
                validation={{
                    required: mgmt.staff.emailError,
                    validate: (value) =>
                        validateEmail(value, mgmt.staff.emailErrorFormat),
                }}
                type="email"
                defaultValue={defaultValues.email}
            />

            <RHFTextField
                label="Fax"
                control={control}
                name={`${name}.fax`}
                disabled={disabled}
                defaultValue={defaultValues.fax}
            />
        </>
    );
};

ManagementStaff.propTypes = {
    defaultValues: PropTypes.object,
    control: PropTypes.object.isRequired,
    error: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    funcDisabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

ManagementStaff.defaultProps = {
    disabled: false,
    errors: {},
    funcDisabled: false,
    defaultValues: {},
};

export default ManagementStaff;
