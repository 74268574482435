import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { texts as fpt } from '../../../texts/Forms/FinancingPlanFormTexts';
import TextBlock from '../../TextBlock/TextBlock';
import { useStore } from './FinancingPlan.store';
import ProofEdit from './Edit/ProofEdit';
import ProofShow from './Display/ProofShow';
import { useParams } from 'react-router-dom';

const FinancingPlanForm = (props) => {
    const { defaultValues, onSubmit, formId } = props;

    const [editIndex, setEditIndex] = React.useState(-1);
    const isAddingNew = React.useRef(false);

    const { proposalId } = useParams();

    const { addProof, proofs, editProof, removeProof, initialize } = useStore(
        (state) => ({
            addProof: state.addProof,
            editProof: state.editProof,
            removeProof: state.removeProof,
            proofs: state.proofs,
            initialize: state.initialize,
        })
    );

    React.useEffect(() => {
        initialize(defaultValues.proofs);
    }, [defaultValues, initialize]);

    const handleAdd = React.useCallback(() => {
        const newIdx = proofs.length;
        addProof();
        setEditIndex(newIdx);
        isAddingNew.current = true;
    }, [addProof, proofs.length, isAddingNew]);

    const handleEditFinish = React.useCallback(() => {
        const idx = editIndex;
        setEditIndex(-1);
        if (isAddingNew.current === true) {
            removeProof(idx);
        }
        isAddingNew.current = false;
    }, [setEditIndex, isAddingNew, editIndex, removeProof]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ proofs, deductInputTax: defaultValues.deductInputTax }).then(
            (res) => {
                if (!res) return;
                proofs.forEach((proof, id) => {
                    editProof(id, `/api/proofs/${proof.id}`, '@id');
                });
            }
        );
    };

    const saveProof = (proof, index) => {
        setEditIndex(-1);
        editProof(index, proof);
        isAddingNew.current = false;
    };

    return (
        <>
            <Typography variant="h4">{fpt.title}</Typography>
            <div style={{ marginTop: '16px' }}>
                <TextBlock textBlock={fpt.introduction.text1} />
                <TextBlock textBlock={fpt.introduction.text2} />
            </div>
            <TextBlock
                textBlock={fpt.parser.financeData}
                headlineVariant="h6"
            />
            <TextBlock
                textBlock={fpt.parser.financeData2}
                headlineVariant="h6"
                parserOptions={{
                    components: {
                        ul: {
                            props: {
                                component: 'ol',
                            },
                        },
                    },
                }}
            />

            <form
                id={formId}
                onSubmit={handleSubmit}
                style={{ paddingBottom: '32px' }}
            >
                <ProofShow
                    editStart={setEditIndex}
                    handleAdd={handleAdd}
                    deductInputTax={defaultValues.deductInputTax}
                    editIndex={editIndex}
                />
                <div style={{ marginTop: '16px' }}>
                    <TextBlock textBlock={fpt.parser.saveInfo} />
                </div>
            </form>
            {editIndex >= 0 && (
                <>
                    <hr />
                    <ProofEdit
                        index={editIndex}
                        deductInputTax={defaultValues.deductInputTax}
                        moduleOne={defaultValues.moduleOne}
                        moduleTwo={defaultValues.moduleTwo}
                        closeCallback={handleEditFinish}
                        defaultValues={proofs[editIndex]}
                        saveCallback={saveProof}
                        proposalId={proposalId}
                    />
                </>
            )}
        </>
    );
};

FinancingPlanForm.propTypes = {
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string,
    saving: PropTypes.bool,
    saveResponse: PropTypes.object,
};

export default FinancingPlanForm;
