import React from 'react';
import PropTypes from 'prop-types';
import { Response, TYPE } from '../../../util/DataTypes/Response';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const DisplayResponsePopup = (props) => {
    const { response, autoHideDuration, onlySuccess, ...rest } = props;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (!response) return;
        if (onlySuccess && response.type() !== TYPE.SUCCESS) return;
        setOpen(true);
    }, [response, onlySuccess]);

    if (!response) return null;

    const handleClose = () => setOpen(false);

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 5000}
            onClose={handleClose}
            {...rest}
        >
            <Alert onClose={handleClose} severity={response.type()}>
                {response.message()}
            </Alert>
        </Snackbar>
    );
};

DisplayResponsePopup.propTypes = {
    response: PropTypes.instanceOf(Response),
    autoHideDuration: PropTypes.number,
    onlySuccess: PropTypes.bool,
};

export default DisplayResponsePopup;
