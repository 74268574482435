import React from 'react';
import PropTypes from 'prop-types';

const TextBold = ({ text, weight }) => {
    return <span style={{ fontWeight: weight }}>{text}</span>;
};

TextBold.propTypes = {
    text: PropTypes.string,
    weight: PropTypes.string,
};

export default TextBold;
