import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '& div': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& div:last-child': {
            marginRight: '0',
        },
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
}));

const WizardStepDisplay = (props) => {
    const { currentStep, stepCount, position, stepName, stepComplete } = props;
    const classes = useStyles();
    return (
        <>
            {position === 'bottom' && <Divider className={classes.divider} />}
            <Box className={classes.box}>
                {_.map(_.range(stepCount), (number) => (
                    <Chip
                        key={`chip_${number}`}
                        variant={
                            currentStep > number ||
                            (currentStep === number && stepComplete)
                                ? 'default'
                                : 'outlined'
                        }
                        label={currentStep !== number ? number + 1 : stepName}
                        color={currentStep >= number ? 'primary' : undefined}
                    />
                ))}

                {}
            </Box>
            {position === 'top' && <Divider className={classes.divider} />}
        </>
    );
};

WizardStepDisplay.propTypes = {
    currentStep: PropTypes.number.isRequired,
    stepCount: PropTypes.number.isRequired,
    position: PropTypes.oneOf(['top', 'bottom']),
    stepName: PropTypes.string,
    stepComplete: PropTypes.bool,
};

export default WizardStepDisplay;
