import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { centToString } from '../../../../util/Money/MoneyFunctions';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import EditIcon from '@material-ui/icons/Edit';
import TextBlock from '../../../TextBlock/TextBlock';

const useStyles = makeStyles({
    removePadding: {
        padding: 0,
        '& td': {
            padding: 0,
        },
    },
    removeBorder: {
        '& td': {
            borderBottom: 0,
        },
    },
});

const DeviceTableRow = (props) => {
    const {
        defaultValues,
        deductInputTax,
        deleteCallback,
        editCallback,
        index,
        register,
    } = props;
    const priceLabel = deductInputTax ? 'netPrice' : 'price';
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    return (
        <>
            <TableRow
                className={classes.removeBorder}
                style={{ whiteSpace: 'nowrap' }}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => deleteCallback(index)}
                    >
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            editCallback(index);
                        }}
                        style={{ marginLeft: '4px' }}
                    >
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? (
                            <KeyboardArrowUpIcon fontSize="inherit" />
                        ) : (
                            <KeyboardArrowDownIcon fontSize="inherit" />
                        )}
                    </IconButton>

                    {Object.keys(defaultValues).map((key) => (
                        <input
                            key={`${defaultValues.id}_${key}`}
                            type="hidden"
                            value={defaultValues[key]}
                            name={`deviceList[${index}].${key}`}
                            ref={register()}
                        />
                    ))}
                    <input
                        type="hidden"
                        value={index}
                        name={`deviceList[${index}].position`}
                        ref={register()}
                    />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '600px',
                    }}
                >
                    {defaultValues.description}
                </TableCell>
                <TableCell>
                    {parseFloat(defaultValues.amount).toLocaleString('de-DE', {
                        maximumFractionDigits: 4,
                    })}
                </TableCell>
                <TableCell>
                    {centToString(defaultValues[priceLabel]) + ' €'}
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                    {centToString(
                        Math.round(
                            parseFloat(defaultValues.amount) *
                                parseInt(defaultValues[priceLabel])
                        )
                    ) + ' €'}
                </TableCell>
            </TableRow>
            <TableRow className={classes.removePadding}>
                <TableCell />
                <TableCell colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <TextBlock
                                                textBlock={
                                                    fpt.proof.deviceList
                                                        .columns[2].label
                                                }
                                            />
                                        }
                                        secondary={defaultValues.description}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            fpt.proof.deviceList.columnsOther
                                                .posProof
                                        }
                                        secondary={defaultValues.posProof}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            fpt.proof.deviceList.columnsOther
                                                .description
                                        }
                                        secondary={defaultValues.explanation}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            fpt.proof.deviceList.columnsOther
                                                .combination
                                        }
                                        secondary={
                                            defaultValues.isCombination
                                                ? 'Ja'
                                                : 'Nein'
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

DeviceTableRow.propTypes = {
    deductInputTax: PropTypes.bool,
    deleteCallback: PropTypes.func,
    editCallback: PropTypes.func,
    index: PropTypes.number,
    defaultValues: PropTypes.object,
    register: PropTypes.func,
};

export default DeviceTableRow;
