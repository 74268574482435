import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { texts as oft } from '../../../../../texts/Forms/OverviewFormTexts';
import { centToString } from '../../../../../util/Money/MoneyFunctions';
import { fetchUploadedFilesFor } from '../../../../../lib/api/Files/FileActions';
import uniqid from 'uniqid';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';

const FinancingPlan2Overview = (props) => {
    const { data } = props;

    const { proposalId } = useParams();

    const [files, setFiles] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const getFiles = React.useCallback(() => {
        if (!isLoading) setIsLoading(true);
        return fetchUploadedFilesFor(proposalId, ['bank', 'finance'])()
            .then((files) => {
                if (files.length > 0)
                    setFiles(
                        files.map((file) => {
                            file.id = file['@id'];
                            return file;
                        })
                    );
                else
                    setFiles([
                        {
                            id: uniqid(),
                            fileName: 'Keine Datei(en) hochgeladen',
                        },
                    ]);
            })
            .catch((e) => {
                setFiles([
                    {
                        id: uniqid(),
                        fileName: 'Beim laden ist ein Fehler aufgetreten',
                        reload: true,
                    },
                ]);
            })
            .finally(() => setIsLoading(false));
    }, [proposalId, isLoading]);

    React.useEffect(() => {
        getFiles();
    }, []); //eslint-disable-line

    return (
        <>
            <Typography variant="h6">{oft.financing2Plan.static.fq}</Typography>
            <Typography gutterBottom>{data.fpData.fq + '%'}</Typography>
            <Typography variant="h6">
                {oft.financing2Plan.static.sum}
            </Typography>
            <Typography gutterBottom>
                {centToString(data.fpData.sumInvestOverall, false) + ' €'}
            </Typography>

            <Typography variant="h6">
                {oft.financing2Plan.static.dij}
            </Typography>
            <Typography gutterBottom>
                {centToString(data.fpData.step3, false) + ' €'}
            </Typography>

            <Typography variant="h6">
                {oft.financing2Plan.static.self}
            </Typography>
            <Typography gutterBottom>
                {centToString(data.fpData.self, false) + ' €'}
            </Typography>

            <Typography variant="h6">
                {oft.financing2Plan.static.confirmTitle}
            </Typography>

            <Typography gutterBottom>
                {oft.financing2Plan.static.confirm1}
            </Typography>
            <Typography>{oft.financing2Plan.static.confirm2}</Typography>
            <Typography gutterBottom>
                {oft.financing2Plan.static.confirm2b}
            </Typography>

            {data.fpData.creditApproval && (
                <>
                    <Typography variant="h6" gutterBottom>
                        {oft.financing2Plan.static.credit}
                    </Typography>

                    {data.fpData.kfw ? (
                        <>
                            <Typography gutterBottom>
                                {oft.financing2Plan.static.kfwTrue}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography gutterBottom>
                                {oft.financing2Plan.static.kfwFalse}
                            </Typography>
                            <Typography variant="h6">
                                {oft.financing2Plan.static.bank}
                            </Typography>
                            <Typography gutterBottom>
                                {data.fpData.bank}
                            </Typography>
                        </>
                    )}
                    <Typography variant="h6">
                        {oft.financing2Plan.static.uploaded}
                    </Typography>
                    {isLoading ? (
                        <Typography component="div" gutterBottom>
                            Lädt... <CircularProgress size="1rem" />
                        </Typography>
                    ) : (
                        <Typography component="ul">
                            {files.map((file) => (
                                <Typography component="li" key={file.id}>
                                    {file.fileName}
                                    {file.reload && (
                                        <IconButton
                                            size="small"
                                            onClick={getFiles}
                                            color="primary"
                                            style={{ marginLeft: '8px' }}
                                        >
                                            <ReplayIcon fontSize="inherit" />
                                        </IconButton>
                                    )}
                                </Typography>
                            ))}
                        </Typography>
                    )}
                </>
            )}
        </>
    );
};

FinancingPlan2Overview.propTypes = {
    data: PropTypes.object,
};

export default FinancingPlan2Overview;
