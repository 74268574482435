import moment from 'moment';

const baseParams = {
    amount: 2,
    unit: 'months',
    compareTo: undefined,
};

function genComparator({ amount, unit, compareTo }) {
    const comparator = moment(compareTo).add(amount, unit).startOf('day');

    if (parseInt(comparator.format('D')) !== 1) {
        comparator.add(1, 'month').startOf('month');
    }
    return comparator;
}

export function validateDateGreaterOrEqual(date, message, params = baseParams) {
    return date >= genComparator(params) || message;
}

export function getFirstValidDate(params = baseParams) {
    return genComparator(params);
}
