import React from 'react';
import PropTypes from 'prop-types';
import { FinancialPlanTable } from '@dlr-pt/prj-dij-react-components';
import FinancialPlanTableOld from './Step4/FinancialPlanTableOld';
import { client } from '../../../QueryClient';
import { step4Texts } from '../../../texts/Verification/Step4Texts';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import FormFooter from './FormFooter';
import Outline from '../../Partials/Info/Outline';
import RadioButtons from '../../Partials/Form/RadioButtons';
import FormRadio from '../Components/FormRadio';
import FormMoney from '../Components/FormMoney';
import FormTextArea from '../Components/FormTextArea';
import FormCheckbox from '../Components/FormCheckbox';
import FormBasicDatePicker from '../Components/FormBasicDatePicker';
import { FORM_TRANSFORMER } from '../../../util/Helper/FormTransformer';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import {
    downloadFile,
    fetchUploadedFilesFor,
} from '../../../lib/api/Files/FileActions';
import {
    createInvoice,
    fetchProof,
    saveInvoiceData,
    deleteInvoice,
    setNoInvoice,
    updateVerificationProofs,
} from '../../../lib/api/VerificationProof/VerificationProofActions';
import { createDownloadLinkForFileResponse } from '../../../util/Helper/CreateDownloadLinkForFileResponse';
import ToggleableInfobox from '../../Helper/ToggleableInfobox';
import TextBlock from '../../TextBlock/TextBlock';
import { Alert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

const Step4 = (props) => {
    const { data, formData, displayOnly, id, onDirtyChange, handleStepChange } =
        props;

    const [tableData, setTableData] = React.useState([]);
    const [verificationProofsLoading, setVerificationProofsLoading] =
        React.useState(true);

    React.useEffect(() => {
        const refreshVerification = async () => {
            const proofs = await updateVerificationProofs(id);

            setTableData(proofs);
        };

        refreshVerification().then(() => {
            setVerificationProofsLoading(false);
        });
    }, [id]);

    // Check if submitted financialPlan is old
    const submittedFinancialPlanIsOld = React.useMemo(() => {
        return (
            ['submittable', 'submitted'].includes(formData.currentStep) &&
            formData.financialPlanVersion === 1
        );
    }, [formData]);

    return (
        <div>
            {submittedFinancialPlanIsOld ? (
                <>
                    {!displayOnly && (
                        <Alert severity="info">
                            <TextBlock textBlock={step4Texts.topHintOld} />
                        </Alert>
                    )}
                    <FinancialPlanTableOld
                        tableData={formData.verificationProofs}
                        displayOnly={displayOnly}
                        verificationId={id}
                        onStepChange={handleStepChange}
                        onDirtyChange={onDirtyChange}
                        formData={formData}
                    />
                </>
            ) : (
                <>
                    {!displayOnly && (
                        <ToggleableInfobox
                            teaserText={
                                <TextBlock
                                    textBlock={step4Texts.topHintTeaser}
                                />
                            }
                            text={<TextBlock textBlock={step4Texts.topHint} />}
                        />
                    )}

                    {!verificationProofsLoading ? (
                        <FinancialPlanTable
                            tableData={tableData}
                            displayOnly={displayOnly}
                            verificationId={id}
                            onStepChange={handleStepChange}
                            onDirtyChange={onDirtyChange}
                            formData={formData}
                            proposal={data.proposal}
                            client={client}
                            texts={step4Texts}
                            useVerificationPatch={useVerificationPatch}
                            formFooter={FormFooter}
                            outline={Outline}
                            formRadio={FormRadio}
                            formMoney={FormMoney}
                            formTextArea={FormTextArea}
                            formCheckbox={FormCheckbox}
                            formTransformer={FORM_TRANSFORMER}
                            dropzoneContainer={DropzoneContainer}
                            fetchUploadedFilesFor={fetchUploadedFilesFor}
                            createInvoice={createInvoice}
                            setNoInvoice={setNoInvoice}
                            formBasicDatePicker={FormBasicDatePicker}
                            radioButtons={RadioButtons}
                            fetchProof={fetchProof}
                            saveInvoiceData={saveInvoiceData}
                            deleteInvoice={deleteInvoice}
                            downloadFile={downloadFile}
                            createDownloadLinkForFileResponse={
                                createDownloadLinkForFileResponse
                            }
                            toggleableInfobox={ToggleableInfobox}
                            textBlock={TextBlock}
                        />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={30} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

Step4.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

export default Step4;
