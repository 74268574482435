import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { texts as oft } from '../../../../texts/Forms/OverviewFormTexts';
import { ContentTypography } from './OverviewComponent';
import moment from 'moment';
import { fetchUploadedFilesFor } from '../../../../lib/api/Files/FileActions';
import { useParams } from 'react-router-dom';

export const CompanyTypeOverview = (props) => {
    const data = props.data.companyType;

    const registered = (
        <>
            {data.hasHRGNumber && (
                <Typography>
                    {oft.companyType.content.registered.hrg}: {data.hRGNumber}
                </Typography>
            )}
            {data.hasTradeRegistered && (
                <Typography>
                    {oft.companyType.content.registered.trade}:{' '}
                    {data.tradeLicenceNumber}
                </Typography>
            )}
            {data.isCraftCompany && (
                <Typography>
                    {oft.companyType.content.registered.craft}:{' '}
                    {data.craftNumber}
                </Typography>
            )}
            {data.isFreelance && (
                <Typography>
                    {oft.companyType.content.registered.taxNumber}:{' '}
                    {data.taxNumber}
                </Typography>
            )}
        </>
    );

    const { proposalId } = useParams();
    const [files, setFiles] = React.useState([]);
    const [isLoading, setIsloading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        fetchUploadedFilesFor(proposalId, ['uploadBalanceSheet'])()
            .then((f) => {
                setFiles(
                    f.map((file) => {
                        file.id = file['@id'];
                        return file;
                    })
                );
            })
            .catch((e) => {
                setError('Beim laden der Dateien ist ein Fehler aufgetreten');
            })
            .finally(() => setIsloading(false));
    }, []); //eslint-disable-line

    return (
        <>
            {ContentTypography(
                oft.companyType.content.communalCompany,
                data.isCommunalCompany ? 'Ja' : 'Nein'
            )}
            {registered.props[true] &&
                ContentTypography(
                    oft.companyType.content.registered.headline,
                    registered
                )}
            {ContentTypography(
                oft.companyType.content.legal,
                data.legalForm.isOther
                    ? data.otherLegalForm
                    : data.legalForm.name
            )}
            {ContentTypography(
                oft.companyType.content.founded,
                data.isFoundedLast2Y
                    ? moment(data.dateFounded).format('DD.MM.Y')
                    : 'Nein'
            )}
            {ContentTypography(
                oft.companyType.content.sector,
                data.sectorKey.name
            )}
            {ContentTypography(
                oft.companyType.content.deductTax,
                data.isDeductInputTax ? 'Ja' : 'Nein'
            )}

            {ContentTypography(
                oft.companyType.content.employeeCheck.headline,
                data.employeeCheck === true ? 'Ja' : 'Nein'
            )}

            {ContentTypography(
                oft.companyType.content.employees.headline,
                oft.companyType.content.employees[data.employeesCount]
            )}

            <Typography variant="h6" component="h3">
                {oft.companyType.content.files.headline}
            </Typography>

            {!error && (
                <Typography component="ul">
                    {isLoading ? (
                        <Typography component="li">Lädt...</Typography>
                    ) : (
                        <>
                            {files.length === 0 && (
                                <Typography component="li">
                                    Keine Datei hochgeladen
                                </Typography>
                            )}
                            {files.map((file) => (
                                <Typography component="li" key={file.id}>
                                    {file.fileName}
                                </Typography>
                            ))}
                        </>
                    )}
                </Typography>
            )}
        </>
    );
};

CompanyTypeOverview.propTypes = {
    data: PropTypes.object,
};

export default CompanyTypeOverview;
