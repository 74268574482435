import React from 'react';
import { useAuth } from '../../Stores/Auth.store';
import { getUserState, UserState } from '../../util/Helper/UserState';
import { texts as dbt } from '../../texts/Dashboard/DashboardTexts';
import TextBlock from '../../components/TextBlock/TextBlock';
import DashboardAnon from '../../components/Dashboard/DashboardAnon';
import DashboardAuth from '../../components/Dashboard/DashboardAuth';

const DashboardContainer = () => {
    const user = useAuth((state) => state.user);

    if (!user) {
        return <DashboardAnon />;
    }

    const userState = getUserState(user);
    //Check User Stuff
    if (userState === UserState.NOT_ACTIVE) {
        return (
            <>
                <TextBlock
                    textBlock={dbt.parser.deactivated}
                    headlineVariant="h5"
                />
            </>
        );
    }

    if (userState === UserState.NOT_CONFIRMED) {
        return (
            <TextBlock
                textBlock={dbt.parser.notApproved}
                headlineVariant="h5"
            />
        );
    }

    if (userState === UserState.NOT_APPROVED) {
        return (
            <>
                <TextBlock
                    textBlock={dbt.parser.notConfirmed}
                    headlineVariant="h5"
                />
            </>
        );
    }

    if (userState === UserState.MUST_CHANGE_PASSWORD) {
        return (
            <>
                <TextBlock
                    textBlock={dbt.parser.passwordChange}
                    headlineVariant="h5"
                />
            </>
        );
    }

    return <DashboardAuth />;
};

export default DashboardContainer;
