import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from '../FinancingPlan.store';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import RenderTableHead from '../Components/RenderTableHead';
import TableBody from '@material-ui/core/TableBody';
import DisplayRow from './DisplayRow';
import { texts as fpt } from '../../../../texts/Forms/FinancingPlanFormTexts';
import RenderEmptyRow from '../Components/RenderEmptyRow';
import { TableFooter } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import { centToString } from '../../../../util/Money/MoneyFunctions';

const DisplayTable = (props) => {
    const { editStart, handleAdd, deductInputTax, editIndex } = props;

    const { proofs, removeProof } = useStore((state) => ({
        proofs: state.proofs,
        removeProof: state.removeProof,
    }));

    const columns = React.useMemo(
        () =>
            deductInputTax
                ? [...fpt.display.columns, fpt.display.columnsNet]
                : [...fpt.display.columns, fpt.display.columnsBru],
        [deductInputTax]
    );

    return (
        <Table>
            <TableHead>{RenderTableHead({ columns })}</TableHead>

            <TableBody>
                {proofs.length === 0 && RenderEmptyRow({ colSpan: 4 })}
                {proofs.map((proof, index) => (
                    <DisplayRow
                        key={proof.id}
                        index={index}
                        proof={proof}
                        editStart={editStart}
                        deleteCallback={removeProof}
                        deductInputTax={deductInputTax}
                        editIndex={editIndex}
                    />
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell style={{ textAlign: 'right' }}>Summe:</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {centToString(
                            proofs.reduce(
                                (acc, cur) =>
                                    acc +
                                    parseInt(cur.sumDevice) +
                                    parseInt(cur.sumActivity),
                                0
                            ),
                            false
                        ) + ' €'}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            disabled={editIndex >= 0}
                        >
                            Neuen Nachweis hinzufügen
                        </Button>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

DisplayTable.propTypes = {
    editStart: PropTypes.func,
    deductInputTax: PropTypes.bool,
};

export default DisplayTable;
