import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { raffleTexts as rt } from '../../texts/Forms/Raffle/RaffleTexts';
import moment from 'moment';

const RaffleParticipating = (props) => {
    const { raffle } = props;

    const date = moment(raffle.rafflePot.dayOfRaffle);

    return (
        <Alert severity="success">
            <Typography>
                {rt.display.participating1(
                    date.format('MMMM'),
                    date.format('yyyy')
                )}
            </Typography>
            <Typography>{rt.display.participating2}</Typography>
        </Alert>
    );
};

RaffleParticipating.propTypes = {
    raffle: PropTypes.object,
};

export default RaffleParticipating;
