export function generateUrlSearchParams(params) {
    if (!params) return '';

    const keys = Object.keys(params);
    if (keys.length === 0) return '';

    let str = '?';

    keys.forEach((key) => {
        if (typeof params[key] === 'string') {
            str += `${key}=${params[key]}&`;
        } else {
            params[key].forEach((param) => {
                str += `${key}[]=${param}&`;
            });
        }
    });

    return str.slice(0, -1);
}
