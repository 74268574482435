export const texts = {
    title: 'Wahl der Antragsform',
    subhead:
        'Im Übersichtsmenü „Antragsschritte“ links unten erhalten Sie eine Übersicht über die einzelnen Antragsschritte und können dort ebenfalls zu bereits getätigten Eingaben zurückkehren und diese bei Bedarf anpassen.',
    titleCompany: 'Beteiligte Unternehmen der Wertschöpfungskette',
    fields: {
        isValueChain: {
            label: 'Bitte geben Sie zunächst an, ob Sie den "Digital Jetzt"-Antrag als Einzelunternehmen oder gemeinsam mit weiteren Unternehmen als Teil einer Wertschöpfungskette stellen.',
            ariaLabel:
                'Das antragstellende Unternehmen ist Teil einer Wertschöpfungskette (WSK)',
            error: 'Es muss angegeben werden, ob das antragstellende Unternehmen Teil einer Wertschöpfungskette (WSK) ist.',
            buttons: [
                {
                    label: 'Ich stelle den Antrag als Einzelunternehmen',
                    value: 'false',
                },
                {
                    label: 'Ich stelle den Antrag zusammen mit weiteren Unternehmen innerhalb bzw. entlang einer Wertschöpfungskette (WSK)',
                    value: 'true',
                },
            ],
        },
        isLead: {
            label: 'Als Unternehmen einer WSK stimmen Sie bitte in Ihrem Verbund ab, welches Unternehmen den ersten Antrag stellt. Mit der Erstanmeldung wird ein PIN Code generiert, den alle Unternehmen der WSK zur Antragstellung benötigen. Handelt es sich bei dem antragstellenden Unternehmen um das Unternehmen der Wertschöpfungskette, das als erstes Unternehmen der Kette ("Lead-Partner") Antragsdaten eingibt?',
            error: 'Es muss angegeben werden, ob es sich bei dem antragstellenden Unternehmen um das Unternehmen der Wertschöpfungskette, das den ersten Antrag stellt ("Lead-Partner"), handelt.',
            buttons: [
                {
                    label: 'Ja',
                    value: 'true',
                },
                {
                    label: 'Nein',
                    value: 'false',
                },
            ],
        },
        leadCode: {
            label: 'WSK-Code',
            ariaLabel: 'WSK-Code Eingabe',
            error: 'Es muss ein WSK-Code angegeben werden',
            helperText:
                'Bitte geben Sie hier den Code ein, den Sie von dem leitenden Unternehmen der WSK erhalten haben',
        },
        givenCode: {
            label: 'Code der Wertschöpfungskette',
        },
    },
    vcCompanies: {
        desc: 'Bitte geben Sie die Daten der weiteren beteiligten Unternehmen in der WSK ein. Alle Unternehmen der Wertschöpfungskette, die einen Antrag stellen, sind aufzuführen.',
        buttons: {
            noCompany: 'Unternehmen der WSK',
            atLeastOneCompany: 'Liste der Unternehmen im WSK',
            remove: (id) => `Unternehmen ${id} entfernen`,
            add: 'Unternehmen hinzufügen',
        },
        fields: {
            name: {
                label: 'Unternehmen',
                error: 'Bitte geben Sie den Unternehmensnamen an',
            },
            location: {
                label: 'Unternehmensstandort',
                error: 'Bitte geben Sie den Unternehmensstandort an',
            },
        },
    },
    hints: {
        registerCompanyHint:
            'Sie finden den rechtsverbindlichen Namen Ihres Unternehmens bspw. im Handelsregisterauszug',
        wskHint:
            'Eine Wertschöpfungskette  ist eine arbeitsteilige Kooperation mehrerer unabhängiger Unternehmen. Die Unternehmen erbringen jeweils eigenständige Beiträge zur Wertschöpfung. Sie sind über Wertschöpfungsprozesse verbunden. Die Wertschöpfungskette stellt zusammenhängende Unternehmensaktivitäten des betrieblichen Prozesses der Erstellung von Gütern und Dienstleistungen dar. Für gleichzeitige, miteinander abgestimmte Investitionen von mehreren Unternehmen einer Wertschöpfungskette beträgt der Förderhöchstbetrag 100.000 Euro pro Antragsteller. Die Förderquote wird zudem um 5 Prozentpunkte erhöht.',
        givenCodeHint:
            'Bitte kopieren Sie den angezeigten PIN Code und leiten Sie diesen PIN Code an alle Unternehmen in Ihrer Wertschöpfungskette weiter.',
        companyHint:
            'Die Unternehmen der Wertschöpfungskette müssen jeweils einen eigenen Antrag stellen. Der bei der Anmeldung des ersten Unternehmens generierte PIN Code ist von allen Unternehmen einer Wertschöpfungskette zu nutzen ',
        isValueChainHint:
            'Die Antragstellung einer Wertschöpfungskette gilt erst dann als abgeschlossen, wenn alle Unternehmen in der Wertschöpfungskette ihre Anträge erfolgreich eingegeben haben.',
    },
};
