import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import CustomTooltip from './CustomTooltip';
import Question from './Question';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        color: 'white',
        border: '1px solid #838383',
        borderBottom: '0',
        marginTop: theme.spacing(2),
    },
    questions: {
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    desc: {
        fontWeight: 'normal',
    },
    border: {
        border: '1px solid #838383',
    },
}));

const Section = (props) => {
    const {
        open,
        title,
        description,
        tooltip,
        questions,
        errors,
        register,
        unregister,
        defaultValues,
    } = props;
    const classes = useStyles();

    return (
        <Collapse in={open} timeout={300}>
            <Paper className={classes.header} elevation={0} square>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.desc}>
                    {description}
                    {tooltip && <CustomTooltip>{tooltip}</CustomTooltip>}
                </Typography>
            </Paper>
            <Paper elevation={0} square className={classes.border}>
                {questions.map((question) => (
                    <Question
                        key={`${title}_${question.name}`}
                        error={errors}
                        name={question.name}
                        answers={question.answers}
                        tooltip={question.tooltip}
                        text={question.text}
                        isShown={open}
                        unregister={unregister}
                        register={register}
                        defaultValue={defaultValues[question.name]}
                    />
                ))}
            </Paper>
        </Collapse>
    );
};

Section.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    description: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    questions: PropTypes.array.isRequired,
    register: PropTypes.func,
    errors: PropTypes.object,
    unregister: PropTypes.func,
    defaultValues: PropTypes.object,
};

Section.defaultProps = {
    open: true,
};

export default Section;
