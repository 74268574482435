import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

const Plan2Checkbox = (props) => {
    const { defaultChecked, error, label, name, register, validation } = props;

    return (
        <FormControl error={!!error.message} style={{ marginTop: '16px' }}>
            <FormControlLabel
                name={name}
                control={
                    <Checkbox
                        color="primary"
                        inputRef={register(validation)}
                        defaultChecked={defaultChecked}
                    />
                }
                label={label}
            />
            {!!error.message && (
                <FormHelperText>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

Plan2Checkbox.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func,
    defaultChecked: PropTypes.bool,
    label: PropTypes.any,
    error: PropTypes.object,
    validation: PropTypes.object,
};

Plan2Checkbox.defaultProps = {
    error: {},
    validation: {},
};

export default Plan2Checkbox;
