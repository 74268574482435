import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Outline from '../../Partials/Info/Outline';
import { step5Texts } from '../../../texts/Verification/Step5Texts';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useVerificationPatch from '../../../hooks/Verification/useVerificationPatch';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FullscreenCircularLoader from '../../Partials/Loader/FullscreenCircularLoader';
import FormFooter from './FormFooter';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import Typography from '@material-ui/core/Typography';
import RHFTextField from '../../Partials/Form/RHFTextField';
import _ from 'lodash';
import VerificationFinalPlan from '../../Verification/FinalPlan/VerificationFinalPlan';
import { verificationTransition } from '../../../lib/api/Verification/VerificationActions';
import DropzoneContainer from '../../../container/Dropzone/DropzoneContainer';
import { fetchUploadedFilesFor } from '../../../lib/api/Files/FileActions';
import red from '@material-ui/core/colors/red';
import ToggleableInfobox from '../../Helper/ToggleableInfobox';
import TextBlock from '../../TextBlock/TextBlock';

const Step5 = (props) => {
    const {
        data,
        formData,
        isLoading,
        displayOnly,
        id,
        onDirtyChange,
        handleStepChange,
    } = props;

    const {
        control,
        errors,
        handleSubmit,
        reset,
        formState,
        register,
        setValue,
    } = useForm({
        defaultValues: data,
    });

    const { checkRequired } = formData;
    const { isDirty } = formState;

    React.useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange]);

    const { enqueueSnackbar } = useSnackbar();

    const handleDataReset = () => {
        reset();
    };

    const handleError = (error) => {
        enqueueSnackbar(error.message(), { variant: 'error' });
    };
    const handleSuccess = (data) => {
        const d = data.data();
        handleDataReset(d);
        enqueueSnackbar(data.message(), { variant: 'success' });
        handleStepChange(5);
    };

    const { mutate, isLoading: isSaving } = useVerificationPatch({
        onError: handleError,
        onSuccess: handleSuccess,
    });

    const submitCallback = (values) => {
        mutate({
            data: {
                ...formData,
                ...values,
            },
            transition: 'affirm',
            id,
            success: 'Erfolgreich gespeichert.',
        });
    };

    const handleFileChange = (files) => {
        setValue('fileCount', files.length);
    };
    const handleBackButton = () => {
        verificationTransition(id, 'back_to_editable').then(() =>
            handleStepChange(3)
        );
    };

    React.useEffect(() => {
        if (isLoading) return;
        reset();
    }, [isLoading, formData, reset]);

    if (isLoading) return <FullscreenCircularLoader />;

    return (
        <form onSubmit={handleSubmit(submitCallback)}>
            <Outline subtitle={step5Texts.finalPlan.title}>
                <Grid container>
                    <Grid item xs={12}>
                        {!displayOnly && (
                            <ToggleableInfobox
                                teaserText={
                                    <TextBlock
                                        textBlock={step5Texts.topHintTeaser}
                                    />
                                }
                                text={
                                    <TextBlock textBlock={step5Texts.topHint} />
                                }
                            />
                        )}
                        <VerificationFinalPlan id={data.id} />
                    </Grid>
                </Grid>
            </Outline>
            <Outline subtitle={step5Texts.outlineVN9.title} gutterTop>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CheckboxWithInfoText
                            label={step5Texts.outlineVN9.inGermany.label}
                            name={step5Texts.outlineVN9.inGermany.name}
                            control={control}
                            register={register}
                            defaultChecked={_.get(
                                formData,
                                step5Texts.outlineVN9.inGermany.name
                            )}
                            ariaLabel={step5Texts.outlineVN9.inGermany.label}
                            validation={step5Texts.outlineVN9.inGermany.rules}
                            error={_.get(
                                errors,
                                step5Texts.outlineVN9.inGermany.name
                            )}
                            disabled={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithInfoText
                            label={
                                step5Texts.outlineVN9.deviceListComplete.label
                            }
                            name={step5Texts.outlineVN9.deviceListComplete.name}
                            control={control}
                            register={register}
                            defaultChecked={_.get(
                                formData,
                                step5Texts.outlineVN9.deviceListComplete.name
                            )}
                            ariaLabel={
                                step5Texts.outlineVN9.deviceListComplete.label
                            }
                            validation={
                                step5Texts.outlineVN9.deviceListComplete.rules
                            }
                            error={_.get(
                                errors,
                                step5Texts.outlineVN9.deviceListComplete.name
                            )}
                            disabled={displayOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithInfoText
                            label={
                                step5Texts.outlineVN9.devicesAreRelevant.label
                            }
                            name={step5Texts.outlineVN9.devicesAreRelevant.name}
                            control={control}
                            register={register}
                            defaultChecked={_.get(
                                formData,
                                step5Texts.outlineVN9.devicesAreRelevant.name
                            )}
                            ariaLabel={
                                step5Texts.outlineVN9.devicesAreRelevant.label
                            }
                            validation={
                                step5Texts.outlineVN9.devicesAreRelevant.rules
                            }
                            error={_.get(
                                errors,
                                step5Texts.outlineVN9.devicesAreRelevant.name
                            )}
                            disabled={displayOnly}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{step5Texts.outlineVN9.block2}</Typography>
                        <Grid item xs={12}>
                            <CheckboxWithInfoText
                                label={
                                    step5Texts.outlineVN9.invoicesSettled.label
                                }
                                name={
                                    step5Texts.outlineVN9.invoicesSettled.name
                                }
                                control={control}
                                register={register}
                                defaultChecked={_.get(
                                    formData,
                                    step5Texts.outlineVN9.invoicesSettled.name
                                )}
                                ariaLabel={
                                    step5Texts.outlineVN9.invoicesSettled.label
                                }
                                validation={
                                    step5Texts.outlineVN9.invoicesSettled.rules
                                }
                                error={_.get(
                                    errors,
                                    step5Texts.outlineVN9.invoicesSettled.name
                                )}
                                disabled={displayOnly}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxWithInfoText
                                label={
                                    step5Texts.outlineVN9.paymentInTime.label
                                }
                                name={step5Texts.outlineVN9.paymentInTime.name}
                                control={control}
                                register={register}
                                defaultChecked={_.get(
                                    formData,
                                    step5Texts.outlineVN9.paymentInTime.name
                                )}
                                ariaLabel={
                                    step5Texts.outlineVN9.paymentInTime.label
                                }
                                validation={
                                    step5Texts.outlineVN9.paymentInTime.rules
                                }
                                error={_.get(
                                    errors,
                                    step5Texts.outlineVN9.paymentInTime.name
                                )}
                                disabled={displayOnly}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxWithInfoText
                                label={
                                    step5Texts.outlineVN9.invoicesExist.label
                                }
                                name={step5Texts.outlineVN9.invoicesExist.name}
                                control={control}
                                register={register}
                                defaultChecked={_.get(
                                    formData,
                                    step5Texts.outlineVN9.invoicesExist.name
                                )}
                                ariaLabel={
                                    step5Texts.outlineVN9.invoicesExist.label
                                }
                                validation={
                                    step5Texts.outlineVN9.invoicesExist.rules
                                }
                                error={_.get(
                                    errors,
                                    step5Texts.outlineVN9.invoicesExist.name
                                )}
                                disabled={displayOnly}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxWithInfoText
                                label={step5Texts.outlineVN9.compliance.label}
                                name={step5Texts.outlineVN9.compliance.name}
                                control={control}
                                register={register}
                                defaultChecked={_.get(
                                    formData,
                                    step5Texts.outlineVN9.compliance.name
                                )}
                                ariaLabel={
                                    step5Texts.outlineVN9.compliance.label
                                }
                                validation={
                                    step5Texts.outlineVN9.compliance.rules
                                }
                                error={_.get(
                                    errors,
                                    step5Texts.outlineVN9.compliance.name
                                )}
                                disabled={displayOnly}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Outline>
            <Outline subtitle={step5Texts.outlineVN11.title} gutterTop>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={step5Texts.outlineVN11.name.label}
                            control={control}
                            defaultValue={_.get(
                                formData,
                                step5Texts.outlineVN11.name.name
                            )}
                            name={step5Texts.outlineVN11.name.name}
                            disabled={displayOnly}
                            validation={step5Texts.outlineVN11.name.rules}
                            error={_.get(
                                errors,
                                step5Texts.outlineVN11.name.name
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={step5Texts.outlineVN11.institution.label}
                            control={control}
                            defaultValue={_.get(
                                formData,
                                step5Texts.outlineVN11.institution.name
                            )}
                            name={step5Texts.outlineVN11.institution.name}
                            disabled={displayOnly}
                            validation={
                                step5Texts.outlineVN11.institution.rules
                            }
                            error={_.get(
                                errors,
                                step5Texts.outlineVN11.institution.name
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={step5Texts.outlineVN11.iban.label}
                            control={control}
                            defaultValue={_.get(
                                formData,
                                step5Texts.outlineVN11.iban.name
                            )}
                            name={step5Texts.outlineVN11.iban.name}
                            disabled={displayOnly}
                            validation={step5Texts.outlineVN11.iban.rules}
                            error={_.get(
                                errors,
                                step5Texts.outlineVN11.iban.name
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={step5Texts.outlineVN11.bic.label}
                            control={control}
                            defaultValue={_.get(
                                formData,
                                step5Texts.outlineVN11.bic.name
                            )}
                            name={step5Texts.outlineVN11.bic.name}
                            disabled={displayOnly}
                            validation={step5Texts.outlineVN11.bic.rules}
                            error={_.get(
                                errors,
                                step5Texts.outlineVN11.bic.name
                            )}
                        />
                    </Grid>
                </Grid>
            </Outline>

            {checkRequired && (
                <Outline subtitle={step5Texts.deepExamination.label} gutterTop>
                    <DropzoneContainer
                        prefetchFiles={fetchUploadedFilesFor(id, [
                            'verification',
                            'inDepthCheck',
                            id,
                        ])}
                        keywords={['verification', 'inDepthCheck', id]}
                        linkedResource={id}
                        acceptedFileTypes={['.pdf']}
                        disabled={displayOnly}
                        onFilesChanged={handleFileChange}
                    />
                    <input
                        type="hidden"
                        ref={register({
                            validate: (value) =>
                                parseInt(value) > 0 ||
                                'Sie müssen mindestens eine Datei hochladen',
                        })}
                        name="fileCount"
                    />
                    {errors.fileCount && (
                        <Typography style={{ color: red[500] }}>
                            {errors.fileCount.message}
                        </Typography>
                    )}
                </Outline>
            )}

            <Outline subtitle={step5Texts.finalCheck.title} gutterTop>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CheckboxWithInfoText
                            label={step5Texts.finalCheck.correct.label}
                            name={step5Texts.finalCheck.correct.name}
                            control={control}
                            register={register}
                            defaultChecked={_.get(
                                formData,
                                step5Texts.finalCheck.correct.name
                            )}
                            ariaLabel={step5Texts.finalCheck.correct.label}
                            validation={step5Texts.finalCheck.correct.rules}
                            error={_.get(
                                errors,
                                step5Texts.finalCheck.correct.name
                            )}
                            disabled={displayOnly}
                        />
                    </Grid>
                </Grid>
            </Outline>

            {!displayOnly && (
                <FormFooter
                    displayText={Object.keys(errors).length > 0}
                    text={step5Texts.errorText}
                    gutterTop
                >
                    <Button
                        style={{ marginRight: 16 }}
                        variant="outlined"
                        color="primary"
                        disabled={isSaving}
                        onClick={handleBackButton}
                        disableElevation
                    >
                        Zurück
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        disableElevation
                    >
                        Speichern und weiter
                        {isSaving && (
                            <CircularProgress
                                style={{ marginLeft: 8 }}
                                size={'1rem'}
                            />
                        )}
                    </Button>
                </FormFooter>
            )}
        </form>
    );
};

Step5.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    refetch: PropTypes.func,
    displayOnly: PropTypes.bool,
    id: PropTypes.string,
    handleStepChange: PropTypes.func,
    onDirtyChange: PropTypes.func,
    formData: PropTypes.object,
};

Step5.defaultProps = {
    data: {},
};

export default Step5;
