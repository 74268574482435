import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

const ButtonWithLoader = (props) => {
    const { func, isLoading, icon } = props;

    return (
        <IconButton onClick={func} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : icon}
        </IconButton>
    );
};

ButtonWithLoader.propTypes = {
    func: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    icon: PropTypes.node,
};

ButtonWithLoader.defaultProps = {
    icon: <RefreshIcon />,
};

export default ButtonWithLoader;
