import create from 'zustand';
import { fetchUserData, removeToken } from '../lib/api/Auth/AuthActions';

const initial = {
    user: undefined,
    loading: true,
    error: undefined,
};

const buildInitial = (set) => ({
    ...initial,
    setUser: (user) =>
        set((state) => {
            if (user !== undefined) state.user = { ...user };
            else state.user = undefined;
            state.error = undefined;
        }),
    relog: async () => {
        try {
            const user = await fetchUserData();

            set((state) => {
                state.user = { ...user };
                state.loading = false;
                state.error = undefined;
            });
        } catch (e) {
            set((state) => {
                state.loading = false;
                state.error = undefined;
            });
        }
    },
    logout: async () => {
        try {
            await removeToken();
            set((state) => {
                state.user = undefined;
                state.error = undefined;
            });
            return Promise.resolve();
        } catch (e) {
            //TODO: Error message on logout
            set((state) => {
                state.error = e;
            });
            return Promise.reject();
        }
    },
});

export const useAuth = create(buildInitial);
