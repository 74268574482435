function transformBoolToString(data) {
    if (typeof data === 'boolean') {
        return data ? 'true' : 'false';
    }
    return data;
}

function transformStringToBool(data) {
    if (typeof data === 'boolean') {
        return data;
    }
    return data === 'true';
}

function transformNumberToString(data) {
    return data + '';
}

function transformStringToNumber(data) {
    return parseInt(data);
}

export const FORM_TRANSFORMER = {
    radioBool: {
        transformer: transformBoolToString,
        changeMutator: transformStringToBool,
    },
    radioNumber: {
        transformer: transformNumberToString,
        changeMutator: transformStringToNumber,
    },
};
