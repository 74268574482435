import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Display from './Display';
import Alert from '@material-ui/lab/Alert';
import TextBlock from '../../TextBlock/TextBlock';
import { texts as fpt } from '../../../texts/Forms/FinancingPlan2FormTexts';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

const Plan2Table = React.memo((props) => {
    const { defaultValues, className, register, moduleOne, moduleTwo } = props;

    const { total, fq, step1, min, step2, step3, maxDeminimis } = defaultValues;

    const renderMinimisMulti = () => {
        if (moduleOne && moduleTwo) {
            return (
                <Row
                    col1={<Typography>{fpt.step2.moduleOneTwo}</Typography>}
                    col3={<Display amount={fpt.step2.multi.string1} />}
                    noBorder
                />
            );
        }
        if (moduleOne) {
            return (
                <Row
                    col1={<Typography>{fpt.step2.moduleOne}</Typography>}
                    col3={<Display amount={fpt.step2.multi.string1} />}
                    noBorder
                />
            );
        }
        return (
            <Row
                col1={<Typography>{fpt.step2.moduleTwo}</Typography>}
                col3={<Display amount={fpt.step2.multi.string2} />}
                noBorder
            />
        );
    };
    const renderMinimisSingle = () => {
        if (moduleOne && moduleTwo) {
            return (
                <Row
                    col1={<Typography>{fpt.step2.moduleOneTwo}</Typography>}
                    col3={<Display amount={fpt.step2.single.string1} />}
                    noBorder
                />
            );
        }
        if (moduleOne) {
            return (
                <Row
                    col1={<Typography>{fpt.step2.moduleOne}</Typography>}
                    col3={<Display amount={fpt.step2.single.string1} />}
                    noBorder
                />
            );
        }
        return (
            <Row
                col1={<Typography>{fpt.step2.moduleTwo}</Typography>}
                col3={<Display amount={fpt.step2.single.string2} />}
                noBorder
            />
        );
    };
    const renderStep2Sum = () => {
        return (
            <>
                <Row
                    col1={<Typography>{fpt.step2.sum}</Typography>}
                    col3={<Display amount={step2} isMoney />}
                    color={step2 < min ? red[100] : yellow[100]}
                    noBorder={step2 < min}
                />
                {step2 < min && (
                    <Row
                        col1={<Alert severity="error">{fpt.step2.error}</Alert>}
                    />
                )}
            </>
        );
    };
    const renderStep3Sum = () => {
        return (
            <>
                <Row
                    col1={fpt.step3.dij}
                    col3={<Display amount={step3} isMoney />}
                    color={0 === step3 ? red[100] : green[100]}
                    noBorder={0 === step3}
                />
                {step2 < min && (
                    <Row
                        col1={
                            <Alert severity="error">{fpt.step3.error1}</Alert>
                        }
                    />
                )}
                {0 === step3 && (
                    <Row
                        col1={
                            <Alert severity="error">
                                <TextBlock
                                    textBlock={fpt.step3.error2(
                                        defaultValues.isRoadTransport
                                    )}
                                />
                            </Alert>
                        }
                    />
                )}
            </>
        );
    };
    const isInvalid = () => {
        return 0 === step3;
    };

    return (
        <Paper className={className} square elevation={1}>
            <input
                type="hidden"
                ref={register({
                    validate: (value) => value === 'false' || 'error',
                })}
                value={isInvalid()}
                name="isInvalid"
            />
            <TableContainer>
                <Table>
                    <TableBody>
                        <Row
                            col1={
                                <Typography variant="h5">
                                    {fpt.calculation.title}
                                </Typography>
                            }
                        />
                        <Row
                            col1={
                                <Typography>
                                    {fpt.calculation.invest}
                                </Typography>
                            }
                            col3={<Display amount={total} isMoney />}
                            noBorder
                        />
                        <Row
                            col1={
                                <Typography>{fpt.calculation.quota}</Typography>
                            }
                            col3={<Display amount={fq} format="%" />}
                            noBorder
                        />
                        <Row
                            col1={
                                <Alert severity="info">
                                    <TextBlock
                                        textBlock={fpt.parser.hintQuota}
                                    />
                                </Alert>
                            }
                        />
                        <Row
                            col1={
                                <Typography variant="h6">
                                    {fpt.step1.title}
                                </Typography>
                            }
                            col3={<Display amount={step1} isMoney />}
                            noBorder
                            color={yellow[100]}
                        />
                        <Row
                            col1={
                                <Alert severity="info">{fpt.step1.hint}</Alert>
                            }
                        />

                        <Row
                            col1={
                                <Typography variant="h6">
                                    {fpt.step2.title}
                                </Typography>
                            }
                            noBorder
                        />

                        {defaultValues.isValueChain
                            ? renderMinimisMulti()
                            : renderMinimisSingle()}

                        {renderStep2Sum()}

                        <Row
                            col1={
                                <Alert severity="info">{fpt.step2.hint}</Alert>
                            }
                        />

                        <Row
                            col1={
                                <Typography variant="h6">
                                    {fpt.step3.title}
                                </Typography>
                            }
                            noBorder
                        />

                        <Row
                            col1={<Typography>{fpt.step3.max}</Typography>}
                            col3={<Display amount={maxDeminimis} isMoney />}
                            noBorder
                        />

                        <Row
                            col1={<Typography>{fpt.step3.sum}</Typography>}
                            col3={<Display amount={step2} isMoney />}
                            noBorder
                        />

                        {renderStep3Sum()}
                        <Row
                            col1={
                                <Typography variant="h6">
                                    {fpt.step4.title}
                                </Typography>
                            }
                            noBorder
                        />
                        <Row
                            col1={<Typography>{fpt.step4.invest}</Typography>}
                            col3={<Display amount={total} isMoney />}
                            noBorder
                        />
                        <Row
                            col1={<Typography>{fpt.step4.dij}</Typography>}
                            col3={<Display amount={step3} isMoney />}
                            noBorder
                        />
                        <Row
                            col1={<Typography>{fpt.step4.self}</Typography>}
                            col3={<Display amount={total - step3} isMoney />}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
});

Plan2Table.propTypes = {
    defaultValues: PropTypes.any,
    calculatedSum: PropTypes.any,
    step3: PropTypes.any,
    register: PropTypes.func,
};

Plan2Table.defaultProps = {
    table: {
        total: 0,
        fq: 0,
        step1: 0,
        min: 0,
        step2: 0,
        step3: 0,
        maxDeminimis: 0,
    },
};

export default Plan2Table;
